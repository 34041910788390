import React, { Fragment, useEffect, useState } from "react";

import { Paper, FormControl, Select, Divider } from "@mui/material";
import { Link, useOutletContext } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import ReactSelect from "react-select";

import { api, common, envConfig } from "helpers";
import { findIsSubModule } from "helpers/components/roles";
import StatusBar from "elements/StatusBar";
import {
  ROLES,
  CRM_USERS,
  USER_ROLE_GROUPS,
  CRM_DEFAULT_GROUPS_1,
  CRM_DEFAULT_GROUPS_2,
  CRM_DEFAULT_GROUPS_3,
  CRM_DEFAULT_GROUPS_4,
} from "helpers/service";
let ModNameChange = envConfig.ModNameChange;

const EXTENDED_PERMISSIONS_ROLES = ["lead", "deal"];
const MODULE_OPTIONS = ["r", "a", "u", "d", "i", "e"];
const USER_ROLES_LIMIT = 1000;

const EMPLOYEE_PERMISSION_UPDATE_SUCCESS = ModNameChange
  ? "Uer permission updated successfully"
  : "Employee permission updated successfully";
const EMPLOYEE_PERMISSION_UPDATE_FAILED = ModNameChange
  ? "User permission updated failed"
  : "Employee permission update failed";

const PermissionsTap = (props) => {
  const userData = common.authInfo();
  const [userPermission] = useOutletContext();

  const { isLoading, selectedItem, setSelectedItem } = props;

  const [rolesList, setRolesList] = useState([]);
  const [roleItem, setRoleItem] = useState(null);

  const [expandedModules, setExpandedModules] = useState([0]);
  const [permissions, setPermissions] = useState({});
  const [currentPermission, setCurrentPermission] = useState({});
  const [isPermissionsLoading, setIsPermissionsLoading] = useState(false);
  const [disabledUpdateBtn, setDisabledUpdateBtn] = useState(true);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [isRoleSelectEnable, setIsRoleSelectEnable] = useState(false);

  useEffect(() => {
    if (selectedItem?.Email) {
      setIsPermissionsLoading(true);
      getUserIdByEmail();
    }
  }, [selectedItem]);

  const getUserIdByEmail = () => {
    const url = `${envConfig.BASE_API}${CRM_USERS}?fields=UserId,Email&q=TenantId=${userData.TenantId} AND UPPER(UserName)LIKE '*${selectedItem?.Email}*'`;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
      moreHead: { rfv: 2 },
      cType: 4,
    };

    api.call(
      data,
      (response) => {
        if (response && response.data?.items && response.data?.items[0]) {
          const userId = response.data?.items[0].UserId;
          getPermissionsList(userId);
        } else {
          setIsPermissionsLoading(false);
        }
      },
      (error) => {
        setIsPermissionsLoading(false);
      },
      (final) => {}
    );
  };

  const getPermissionsList = (userId) => {
    const url = `${envConfig.AUTH_REST_URL}${USER_ROLE_GROUPS}?q=UserId=${userId};&finder=OnlyParentGroups`;

    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "basic",
      cType: 4,
    };

    api.call(
      data,
      (response) => {
        if (response) {
          setIsPermissionsLoading(false);
          var userRoleList = response.data.items.filter((data) => {
            return (
              data.GroupDesc != CRM_DEFAULT_GROUPS_1 &&
              data.GroupDesc != CRM_DEFAULT_GROUPS_2 &&
              data.GroupDesc != CRM_DEFAULT_GROUPS_3 &&
              data.GroupDesc != CRM_DEFAULT_GROUPS_4
            );
          });
          if (userRoleList.length > 0) {
            const userRole = userRoleList[0];
            const userAccess = common.parseOnly(userRole.UserAccess);
            userRole.UserAccess = userAccess;
            setPermissions(userRole);
            setCurrentPermission(userRole);
            getUserRolesList(userRole);
          }
        }
      },
      (error) => {
        setIsPermissionsLoading(false);
      },
      (final) => {}
    );
  };

  const getUserRolesList = (oldRole) => {
    const url = `${envConfig.AUTH_REST_URL}${ROLES}?q=GroupDesc NOT IN ('${CRM_DEFAULT_GROUPS_1}','${CRM_DEFAULT_GROUPS_2}','${CRM_DEFAULT_GROUPS_3}','${CRM_DEFAULT_GROUPS_4}') AND TenantId=${userData.TenantId}&fields=GroupId,GroupDesc,TenantId,UserGroupId,UserAccess&limit=${USER_ROLES_LIMIT}&offset=0&finder=FindParentGroups&orderBy=CreatedOn:desc`;

    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "basic",
      cType: 4,
      moreHead: { rfv: 2 },
    };

    api.call(
      data,
      (response) => {
        if (response) {
          const responseList = response.data.items || [];
          setRolesList(responseList);
          selectPreviousRole(responseList, oldRole);
        }
      },
      (error) => {
        setIsPermissionsLoading(false);
      },
      (final) => {}
    );
  };

  const selectPreviousRole = (roleList, permissionItem) => {
    const oldItem = roleList.find(
      (item) => item.GroupId === permissionItem?.GroupId
    );
    if (oldItem) {
      setRoleItem({ label: oldItem.GroupDesc, value: oldItem.GroupId });
    }
  };

  const updatePermissions = () => {
    if (!isUpdateLoading && selectedItem && roleItem && permissions) {
      setIsUpdateLoading(true);

      let url = encodeURI(
        envConfig.AUTH_REST_URL +
          USER_ROLE_GROUPS +
          "/" +
          permissions.UserGroupId
      );

      let params = {
        GroupId: roleItem.value,
        GroupId1: roleItem.value,
        GroupDesc: roleItem.label,
        UserAccess: JSON.stringify(permissions.UserAccess),
      };

      let data = {
        url: url,
        type: "dynamic",
        method: "PATCH",
        auth: "basic",
        body: JSON.stringify(params),
        cType: 4,
      };

      api.call(
        data,
        (response) => {
          setIsUpdateLoading(false);
          if (response) {
            if (response.status === 201 || response.status === 200) {
              common.snack("S", EMPLOYEE_PERMISSION_UPDATE_SUCCESS);
              setDisabledUpdateBtn(true);
            }
          }
        },
        (error) => {
          common.snack("E", EMPLOYEE_PERMISSION_UPDATE_FAILED);
          setIsUpdateLoading(false);
        },
        (final) => {
          setIsUpdateLoading(false);
        }
      );
    }
  };

  const ToggleRoleChange = () => {
    setIsRoleSelectEnable(true);
  };

  const handleSelectModule = (index) => {
    let expandedModulesCopy = [...expandedModules];
    if (isModuleExpended(index)) {
      expandedModulesCopy = expandedModulesCopy?.filter((x) => x !== index);
    } else {
      expandedModulesCopy.push(index);
    }

    setExpandedModules(expandedModulesCopy);
  };

  const handleSelectChange = (event, key, option, row) => {
    const permissionsCopy = common.reParse(permissions);
    const userAccess = permissionsCopy.UserAccess;

    if (row) {
      userAccess[key][row][option] = event?.target?.value || "1";
    } else {
      userAccess[key][option] = event?.target?.value || "1";
    }
    permissionsCopy.UserAccess = userAccess;
    setPermissions(permissionsCopy);

    if (disabledUpdateBtn) {
      setDisabledUpdateBtn(false);
    }
  };

  const filterValueChange = (value) => {
    setRoleItem(value);

    if (currentPermission?.GroupId == value) {
      setPermissions(currentPermission);
    } else {
      value.UserGroupId = currentPermission?.UserGroupId;
      setPermissions(value);
    }

    if (disabledUpdateBtn) {
      setDisabledUpdateBtn(false);
    }
  };

  const labelValue = (data) => {
    return data
      ? data.map((item) => ({
          label: item.GroupDesc,
          value: item.GroupId,
          UserAccess: JSON.parse(item?.UserAccess),
        }))
      : "";
  };

  const getRolesList = (subModule) => {
    let list = envConfig.permissions;
    if (EXTENDED_PERMISSIONS_ROLES.includes(subModule?.toLowerCase())) {
      list = envConfig.allPermissions;
    }

    return list;
  };

  const isModuleExpended = (index) => {
    const rowIndex = expandedModules?.findIndex((x) => x === index);
    return rowIndex > -1;
  };

  return (
    <div>
      <div className="col-md-12">
        <div className="card-custom">
          <div className=" permission-table">
            <div className="card-body p-0">
              <div className="d-flex justify-content-between bottom-border-divider">
                <h5 className="right-section-title my-3">Permissions </h5>
                {!common.givenPermission(
                  userPermission,
                  "hrms:employee",
                  "update"
                ) &&
                userData?.EmpId !== props?.selectedItem?.EmployeeId &&
                userData?.role === "adminuser" ? (
                  <div className="d-flex justify-content-center align-items-center my-auto pe-4">
                    {isLoading || disabledUpdateBtn ? (
                      <Button
                        variant="contained"
                        disabled
                        className="disabled-btn me-4"
                      >
                        Save Changes
                      </Button>
                    ) : (
                      <Button
                        className="automation-footer-btn me-4"
                        type="button"
                        onClick={updatePermissions}
                      >
                        {isUpdateLoading ? "Processing" : "Update"}
                      </Button>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {/* <div className="settings-secondary" /> */}

          <TableContainer component={Paper} className="role-table">
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell component="th">Permission Name</TableCell>
                  <TableCell component="th">Active</TableCell>
                  <TableCell component="th">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isPermissionsLoading ? (
                  <TableRow>
                    <TableCell component="td">
                      {isRoleSelectEnable ? (
                        <ReactSelect
                          value={roleItem}
                          isClearable={true}
                          options={labelValue(rolesList)}
                          onChange={(data) => filterValueChange(data)}
                          menuPortalTarget={document.body}
                          className="common-select-border"
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                        />
                      ) : (
                        <>
                          <span>
                            {permissions ? `${permissions?.GroupDesc}` : ""}
                          </span>
                          {!common.givenPermission(
                            userPermission,
                            "hrms:employee",
                            "update"
                          ) &&
                          userData?.EmpId !== props?.selectedItem?.EmployeeId &&
                          userData?.role === "adminuser" ? (
                            <Link
                              to="#"
                              className="pl-3"
                              onClick={ToggleRoleChange}
                            >
                              Change
                            </Link>
                          ) : null}
                        </>
                      )}
                    </TableCell>
                    <TableCell component="td">
                      {selectedItem?.Active === "Y" ? "Active" : "In-Active"}
                    </TableCell>
                    <TableCell component="td"></TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="module-head" style={{ height: "50px" }} />
          <Divider />

          {userData?.role === "adminuser" ||
          userData?.EmpId === props?.selectedItem?.EmployeeId ? (
            <TableContainer
              component={Paper}
              className="role-table role-permission-items-table role-permission-opacity"
            >
              <div className="table-scroll-position-collapse">
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell component="th">Module</TableCell>
                      <TableCell component="th" align="center">
                        To Read
                      </TableCell>
                      <TableCell component="th" align="center">
                        To Add
                      </TableCell>
                      <TableCell component="th" align="center">
                        To Update
                      </TableCell>
                      <TableCell component="th" align="center">
                        To Delete
                      </TableCell>
                      <TableCell component="th" align="center">
                        To Import
                      </TableCell>
                      <TableCell component="th" align="center">
                        To Export
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {!isLoading &&
                  !isPermissionsLoading &&
                  permissions?.UserAccess ? (
                    <TableBody>
                      {common
                        .objectKeysArray(permissions.UserAccess)
                        ?.map((key, index) => {
                          if (key !== "VersionNumber") {
                            const isSubModule = findIsSubModule(
                              permissions.UserAccess,
                              key
                            );
                            const rowData = permissions.UserAccess[key];
                            return (
                              <Fragment key={`${key}_${index}`}>
                                <TableRow
                                  className="module-head"
                                  onClick={() => handleSelectModule(index)}
                                >
                                  <TableCell component="td" colSpan={7}>
                                    <span className="sticky-col pl-3">
                                      {isModuleExpended(index) ? (
                                        <img
                                          src={common.loadImg("minus-icon.svg")}
                                          className="me-2 pointer"
                                          alt="minus"
                                        />
                                      ) : (
                                        <img
                                          src={common.loadImg("plus-icon.svg")}
                                          className="me-2 pointer"
                                          alt="plus"
                                        />
                                      )}
                                      {key || ""}
                                    </span>
                                  </TableCell>
                                </TableRow>

                                {isModuleExpended(index) ? (
                                  isSubModule ? (
                                    common
                                      .objectKeysArray(rowData)
                                      .map((row) => {
                                        return (
                                          <TableRow
                                            key={row}
                                            sx={{ border: 0 }}
                                            className="emp-roles-row"
                                          >
                                            <TableCell
                                              component="td"
                                              scope="row"
                                              className="module-name"
                                            >
                                              {row}
                                            </TableCell>
                                            {MODULE_OPTIONS?.map((option) => (
                                              <TableCell
                                                key={`${row}_${option}`}
                                                align="center"
                                                className="permission-selectbox"
                                              >
                                                <FormControl
                                                  sx={{
                                                    m: 1,
                                                    minWidth: {
                                                      xs: 220,
                                                      lg: 120,
                                                    },
                                                  }}
                                                  size="small"
                                                  className="dropdown"
                                                >
                                                  <Select
                                                    className="permission-font"
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    label=""
                                                    name={option}
                                                    value={
                                                      rowData[row][option] ||
                                                      "2"
                                                    }
                                                    onChange={(event) =>
                                                      handleSelectChange(
                                                        event,
                                                        key,
                                                        option,
                                                        row
                                                      )
                                                    }
                                                  >
                                                    {getRolesList(row)?.map(
                                                      (item) => {
                                                        return (
                                                          <MenuItem
                                                            key={`${row}_${option}_${item.value}`}
                                                            value={item.value}
                                                          >
                                                            {item.label}
                                                          </MenuItem>
                                                        );
                                                      }
                                                    )}
                                                  </Select>
                                                </FormControl>
                                              </TableCell>
                                            ))}
                                          </TableRow>
                                        );
                                      })
                                  ) : (
                                    <TableRow>
                                      <TableCell
                                        component="td"
                                        scope="row"
                                        className="module-name permission-selectbox"
                                      >
                                        {" "}
                                      </TableCell>
                                      {MODULE_OPTIONS?.map((option) => (
                                        <TableCell
                                          key={option}
                                          align="center"
                                          className="permission-selectbox"
                                        >
                                          <FormControl
                                            sx={{
                                              m: 1,
                                              minWidth: {
                                                xs: 220,
                                                lg: 120,
                                              },
                                            }}
                                            size="small"
                                            className="dropdown"
                                          >
                                            <Select
                                              className="permission-font"
                                              labelId="demo-simple-select-label"
                                              id="demo-simple-select"
                                              label="Permissions"
                                              name={option}
                                              value={rowData[option] || "2"}
                                              onChange={(event) =>
                                                handleSelectChange(
                                                  event,
                                                  key,
                                                  option
                                                )
                                              }
                                            >
                                              {getRolesList()?.map((item) => {
                                                return (
                                                  <MenuItem
                                                    key={item.value}
                                                    value={item.value}
                                                  >
                                                    {item.label}
                                                  </MenuItem>
                                                );
                                              })}
                                            </Select>
                                          </FormControl>
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  )
                                ) : null}
                              </Fragment>
                            );
                          }
                        })}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow className="module-head roles-list-loading">
                        <TableCell colSpan={7}>
                          <StatusBar
                            status={
                              isPermissionsLoading || isLoading
                                ? "process"
                                : "empty"
                            }
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </div>
            </TableContainer>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default PermissionsTap;
