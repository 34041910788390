import React, { useState } from "react";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

import FieldSelect from "elements/FieldSelect";

import { Divider } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

import { api, common, envConfig } from "helpers";
import * as Services from "helpers/service";
let ModNameChange = envConfig.ModNameChange;

const ROLE_SAVE_SUCCESS = ModNameChange
  ? "Permissions added successfully."
  : "Role added successfully.";
const ROLE_SAVE_FAILED = ModNameChange
  ? "Permissions save failed."
  : "Role save failed.";

const useStyles = makeStyles({
  paperFullWidth: {
    overflowY: "visible",
  },
  dialogContentRoot: {
    overflowY: "visible",
  },
});

const AddRole = (props) => {
  const classes = useStyles();
  const [userInfo] = useState(common.authInfo());
  const [value, setValue] = useState(null);
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = (event) => {
    event?.preventDefault();
    if (!value) {
      setError("This field is required");
    } else {
      registerRole();
    }
  };

  const registerRole = () => {
    setLoading(true);
    let url = `${envConfig.BASE_API}${Services.CRM_ROLES_HDR}`;

    const payload = {
      Active: "Y",
      GroupId: value?.GroupId?.value,
      TenantId: userInfo?.TenantId,
    };
    api.call(
      {
        url: url,
        type: "dynamic",
        method: "POST",
        auth: "token",
        cType: 4,
        body: JSON.stringify(payload),
      },
      async (response) => {
        setLoading(false);
        if (response.status === 200 || response.status === 201) {
          handleClose();
          common.snack("S", ROLE_SAVE_SUCCESS);
        } else if (response.status === 500) {
          common.snack("E", "Group already exists!");
        } else {
          common.snack("E", ROLE_SAVE_FAILED);
        }
      },
      (error) => {
        setLoading(false);
        common.snack("E", ROLE_SAVE_FAILED);
      },
      (final) => {}
    );
    props.onReload();
  };

  const handleChange = (key, value) => {
    setValue((oldValue) => {
      return { ...oldValue, [key]: value };
    });
  };

  const { open, handleClose } = props;
  return (
    <div>
      <Dialog
        open={open || false}
        onClose={handleClose}
        fullWidth={true}
        classes={{
          paperFullWidth: classes.paperFullWidth,
        }}
        disableScrollLock
      >
        <div className="role-wrap">
          <DialogTitle>
            {ModNameChange ? "ADD PERMISSIONS" : "ADD ROLES"}
          </DialogTitle>
          <Divider />
          <DialogContent
            classes={{
              root: classes.dialogContentRoot,
            }}
          >
            <div className="row">
              <div className="col-md-12 mb-4 common-select-border mandatoryclass field-select-size">
                <FieldSelect
                  auth={true}
                  api="ROLES"
                  get="GroupDesc"
                  set="GroupId"
                  value={value ? value.GroupId : null}
                  query={`UserId=${userInfo.UserId} AND GroupDesc NOT IN ('${Services.CRM_DEFAULT_GROUPS_1}','${Services.CRM_DEFAULT_GROUPS_2}','${Services.CRM_DEFAULT_GROUPS_3}','${Services.CRM_DEFAULT_GROUPS_4}') AND TenantId=${userInfo.UserId}`}
                  tokenType={"basic"}
                  isMulti={false}
                  isRender={true}
                  onSelect={(data) => handleChange("GroupId", data)}
                  placeholder={
                    ModNameChange ? "Select Permissions" : "Select Role"
                  }
                />
              </div>

              {error ? <p className="error-txt">{error}</p> : null}
            </div>
          </DialogContent>
          <Divider />
          <DialogActions className="justify-content-start pt-4">
            <div className="botBtn pl-3">
              <div>
                <Button
                  className="automation-footer-btn"
                  type="submit"
                  onClick={(event) => !isLoading && handleSubmit(event)}
                >
                  {`${isLoading ? "Processing..." : "Submit"}`}
                </Button>
                <Button
                  className="automation-cancel-btn"
                  onClick={handleClose}
                  type="button"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default AddRole;
