import { AES, enc } from "crypto-js";
import moment from "moment";
import Papa from "papaparse";

import { config, envConfig, eduTechConfig } from "./config";
import SnackbarUtils from "helpers/snackbar";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const dateFormat = "MMMM DD, YYYY hh:mm:ss A";
const utcFormat = "DD-MM-YYYY HH:mm:ss";

export const common = {
  setStoragePagination(module, fData) {
    let pagination = {
      campaign: null,
      enquiry: null,
      lead: null,
      deal: null,
      enquiryMember: null,
      contacts: null,
      meeting: null,
      callLogs: null,
      contactsLink: null,
    };
    let sData = localStorage.getItem("page");
    if (sData) {
      pagination = JSON.parse(sData);
    }
    pagination[module] = fData;
    localStorage.setItem("page", JSON.stringify(pagination));
  },

  getPagination(module) {
    let pagination = localStorage.getItem("page");
    if (pagination) {
      pagination = JSON.parse(pagination);
      if (pagination[module]) {
        return pagination[module];
      }
    }
    return null;
  },

  clearPagination(module) {
    let pagination = localStorage.getItem("page");
    if (pagination) {
      pagination = JSON.parse(pagination);
      if (pagination[module]) {
        pagination[module] = null;
        localStorage.setItem("page", JSON.stringify(pagination));
      }
    }
  },

  setStorageFilter(module, fData) {
    let filter = {
      campaign: null,
      enquiry: null,
      lead: null,
      deal: null,
      enquiryMember: null,
      contacts: null,
      meeting: null,
      callLogs: null,
      contactsLink: null,
    };
    let sData = localStorage.getItem("filter");
    if (sData) {
      filter = JSON.parse(sData);
    }
    filter[module] = fData;
    localStorage.setItem("filter", JSON.stringify(filter));
  },

  getFilter(module) {
    let filter = localStorage.getItem("filter");
    if (filter) {
      filter = JSON.parse(filter);
      if (filter[module]) {
        return filter[module];
      }
    }
    return null;
  },

  clearFilter(module) {
    let filter = localStorage.getItem("filter");
    if (filter) {
      filter = JSON.parse(filter);
      if (filter[module]) {
        filter[module] = null;
        localStorage.setItem("filter", JSON.stringify(filter));
      }
    }
  },

  /**
   * Get base64 content
   */
  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      let base64Content = reader.result;
      let base64String = base64Content.replace("data:", "").replace(/^.+,/, "");
      cb(base64String);
    };
    reader.onerror = function (error) {};
  },

  formatDateAMPM(date, format, isFrmtAtString) {
    if (!format) format = "D MMM - h:mm a";
    let formatedDate = moment.utc(date).local().format(format);
    let localDate = new Date(moment.utc(date).local());

    var hours = localDate.getHours();
    var minutes = localDate.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = formatedDate + " | " + hours + ":" + minutes + " " + ampm;
    if (isFrmtAtString === true) {
      strTime = formatedDate + " at " + hours + ":" + minutes + " " + ampm;
    }
    return strTime;
  },

  /**
   * Confirm delete message
   */
  confirmDelete(isMultiple, handleDeleteBlock) {
    document.body.style.overflow = "visible";
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui pb-4">
            <h1 className="notify-title border-bottom px-4 py-2">
              Delete Confirmation
            </h1>
            {isMultiple && (
              <p className="px-4 py-2">
                Are you sure you want to delete the selected items?
              </p>
            )}
            {!isMultiple && <p>Are you sure you want to delete this item?</p>}
            <button className="automation-cancel-btn" onClick={onClose}>
              Cancel
            </button>
            <button
              className="automation-footer-btn"
              onClick={() => {
                handleDeleteBlock(true);
                onClose();
              }}
            >
              Delete
            </button>
          </div>
        );
      },
    });
  },

  /**
   * Get Object name by Page
   */
  getObjectNameByPage(page) {
    switch (page) {
      case "campaign":
        return "CRM_CAMPAIGNS";
      case "lead":
        return "CRM_LEADS";
      case "enquiry":
        return "CRM_ENQUIRY";
      case "deal":
        return "CRM_OPPORTUNITY";
      case "quote":
        return "CRM_QUOTE";
      case "order":
        return "CRM_ORDER";
      case "invoice":
        return "CRM_INVOICE";
      case "customer":
        return "CUSTOMER";
      case "party":
        return "PARTY";
    }
  },

  //Load Image
  loadImg(img) {
    let result = require("../assets/img/" + img);
    return result;
  },

  //Set local storage
  localSet(key, value) {
    if (typeof value === "object") {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.setItem(key, value);
    }
  },

  //Get local stroage
  localGet(key, json = false) {
    let value = localStorage.getItem(key);
    if (json) {
      value = JSON.parse(value);
    }
    return value;
  },

  //Remove the local stroage
  localRemove(key) {
    localStorage.removeItem(key);
  },

  //Encrypt
  crypt(str, json = false) {
    if (json) {
      str = JSON.stringify(str);
    }
    return AES.encrypt(str, config.cryptKey).toString();
  },

  //Decrypt
  deCrypt(str, json = false) {
    try {
      let bytes = AES.decrypt(str, config.cryptKey);
      let data = bytes.toString(enc.Utf8);
      if (data == "") {
        localStorage.clear();
        this.redirect("/");
      } else {
        if (json) {
          return JSON.parse(data);
        } else {
          return data;
        }
      }
    } catch (err) {
      localStorage.clear();
      this.redirect("/");
    }
  },

  //Validate the email
  isEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    } else {
      return false;
    }
  },

  //Get user info
  userInfo() {
    let userInfo = this.authInfo();
    let data = {
      pOrgId: userInfo.DefaultOrgId,
      pTenantId: userInfo.TenantId,
    };
    return data;
  },

  //Get auth info
  authInfo() {
    if (this.authCheck()) {
      let user = localStorage.getItem("userData");
      let userData = this.deCrypt(user);
      return JSON.parse(userData);
    }
  },

  isEdutechAcc() {
    if (this.authCheck()) {
      let user = localStorage.getItem("userData");
      let userData = this.deCrypt(user);
      userData = JSON.parse(userData);
      return envConfig.EDUTECH_TENANT.includes(parseInt(userData.TenantId));
    }
  },

  getMissingObject(data) {
    let result = data.reduce(function (r, a) {
      r[a.section] = r[a.section] || [];
      r[a.section].push(a.value);
      return r;
    }, Object.create(null));
    return result;
  },

  getMissingFields(mapField, isLead) {
    let mapFields = mapField.split(",");
    let missingFields = isLead
      ? eduTechConfig.missingFields
      : eduTechConfig.docMissingFields;
    let matches = missingFields.filter((obj) => mapFields.includes(obj.label));
    return matches;
  },

  enableLeadStatus(cStatus) {
    let enable = [];
    if (cStatus == "Unassigned Application") {
      enable = ["New Application"];
    }
    if (cStatus == "New Application") {
      enable = ["Potential Student"];
    }
    const newStatus = eduTechConfig.LeadStatus.map((p) =>
      enable.includes(p.label) ? { ...p, disabled: false } : p
    );
    return newStatus;
  },

  enableDealStatus(cStatus) {
    let enable = [];
    if (cStatus == "G2G") {
      enable = ["Submissions"];
    } else if (cStatus == "Submissions") {
      enable = ["Conditionals", "Unconditionals"];
    } else if (cStatus == "Conditionals") {
      enable = [];
    } else if (cStatus == "Unconditionals" || cStatus == "Fees Paid") {
      enable = [];
    } else if (cStatus == "Acceptance") {
      enable = ["Visa Applied", "Visa Granted", "Visa Rejected"];
    } else if (cStatus == "Visa Granted") {
      enable = ["Enrolled"];
    } else if (cStatus == "Enrolled") {
      enable = [];
    }
    const newStatus = eduTechConfig.DealStatus.map((p) =>
      enable.includes(p.label) ? { ...p, disabled: false } : p
    );
    return newStatus;
  },

  //Check authentication
  authCheck() {
    let token = localStorage.getItem("userToken") || null;
    let auth = true;
    if (token === null) {
      auth = false;
    }
    return auth;
  },

  // date format
  localNow() {
    return moment().format(dateFormat);
  },

  utcNow() {
    return moment().utc().format(utcFormat);
  },

  utcToLocal(date, format = dateFormat) {
    return moment.utc(date, utcFormat).local().format(format);
  },

  localToUtc(date) {
    return moment(date).utc().format(utcFormat);
  },

  currentDate() {
    return new Date();
  },

  // validate
  validateType(val, type = "s") {
    let result = val;
    if (type == "n") {
      result = this.onlyNumbers(val);
    } else if (type == "f") {
      result = this.onlyFloat(val);
    }
    return result;
  },

  validateMobile(number) {
    return number.replace(/\D/g, "");
  },

  // input
  onlyNumbers(str) {
    return str.replace(/[^0-9]+/gi, "");
  },

  onlyFloat(str) {
    return str.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1");
  },

  setEmpty(val) {
    if (val == "" || val == null) {
      return "";
    } else {
      return val;
    }
  },

  setDate(val, set = true) {
    if (val == "" || val == null) {
      if (set) {
        return this.utcNow();
      } else {
        return "";
      }
    } else {
      return val;
    }
  },

  // array
  ObjectSet(arr, key) {
    let result = {};
    arr.map((item) => {
      Object.assign(result, { [item[key]]: item });
    });
    return result;
  },

  ObjectAssign(arr) {
    let result = {};
    Object.entries(arr).forEach(([key, item]) => {
      Object.assign(result, { [key]: item });
    });
    return result;
  },

  ObjectEmpty(arr) {
    let result = {};
    Object.entries(arr).forEach(([key, item]) => {
      Object.assign(result, { [key]: "" });
    });
    return result;
  },

  randomString() {
    let length = 15;
    let chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  },

  csvFileParse(file, csvData) {
    Papa.parse(file, {
      complete: csvData,
      header: true,
    });
  },

  reParse(data) {
    return JSON.parse(JSON.stringify(data));
  },

  stringify(data) {
    if (data) {
      return JSON.stringify(data);
    }
    return "";
  },

  parseOnly(data) {
    if (data) {
      return JSON.parse(data);
    }
    return null;
  },

  evaluateEmailTemplates(fieldList, content, searchField, replaceField) {
    return new Promise(async (resolve, reject) => {
      if (fieldList) {
        content = await this.replaceTemplateContent(
          content,
          fieldList,
          searchField,
          replaceField
        );
      }
      resolve(content);
    });
  },

  replaceTemplateContent(content, fields, searchField, replaceField) {
    return new Promise(async (resolve, reject) => {
      let replacedContent = "";
      replacedContent = content.replace(
        /([\$(])([\{(])(.+?)([\})])/g,
        (match, p1, p2, p3, p4, offset, string) =>
          this.fieldStringReplacer(
            match,
            p1,
            p2,
            p3,
            p4,
            offset,
            string,
            fields,
            searchField,
            replaceField
          )
      );

      resolve(replacedContent);
    });
  },

  fieldStringReplacer(
    match,
    p1,
    p2,
    p3,
    p4,
    offset,
    string,
    fields,
    searchField,
    replaceField
  ) {
    let idx = fields.findIndex((f) => {
      return f[searchField] === match.replace("${", "").replace("}", "");
    });
    if (idx == -1) {
      idx = fields.findIndex((f) => {
        return (
          //f[searchField] + "Desc" === match.replace("${", "").replace("}", "")
          f[searchField] === match.replace("${", "").replace("}", "")
        );
      });
    }
    let fieldName = "";
    if (fields[idx] && fields[idx].attr.lookup) {
      if (searchField == "key" && fields[idx].attr.lookup !== "") {
        searchField = "key";
      }
      if (replaceField == "key" && fields[idx].attr.lookup !== "") {
        replaceField = "key";
      }
    }
    if (idx >= 0) {
      if (searchField !== "key" && fields[idx].attr.lookup !== "") {
        fieldName = fields[idx][replaceField]; // + "Desc";
      } else {
        fieldName = fields[idx][replaceField];
      }
    }
    return p1 + p2 + fieldName + p4;
  },

  checkPermission(permissions, module) {
    let modules = module.split(":");
    let allow = "2"; // deafult access denied
    let checkingConstant = permissions;
    try {
      if (permissions) {
        modules.map((m) => {
          if (checkingConstant[m]) {
            allow = checkingConstant[m]; //this.checkSetPermission(checkingConstant[m]);
            checkingConstant = checkingConstant[m];
          }
          return m;
        });
      }
      return allow;
    } catch (err) {
      this.snack("E", err.message);
      return allow;
    }
  },

  /**
   * Format date
   */
  formatDate(date, format, onlyMoment) {
    if (!format) format = "D MMM - h:mm a";
    if (onlyMoment) {
      return moment.utc(date).local();
    }
    let formatedDate = moment.utc(date).local().format(format);
    return formatedDate;
  },

  /**
   * Convert to UTC date
   */
  convertUTCDate(date) {
    if (date == null || date == "") return null;
    let formatDate = moment.utc(date).format();
    return formatDate;
  },

  convertDate(date, format) {
    if (date) {
      if (format) {
        return moment(date).format(format);
      }
      return moment(date);
    }
    return "";
  },

  /**
   * Get TransactionTypeId
   */
  getTransactionTypeId(type) {
    try {
      let decTransType = localStorage.getItem("tData")
        ? JSON.parse(this.deCrypt(localStorage.getItem("tData")))
        : null;
      if (decTransType && decTransType.length > 0) {
        let data = decTransType.filter((data) => {
          return data.code === type;
        });
        return data[0].id;
      }
      return null;
    } catch (e) {
      return null;
    }
  },

  /**
   * Form list of options data
   */
  getOptionsData(data, label, value, labelName = "") {
    let optionsData = [];
    optionsData.push({
      label: labelName ? "Select" + " " + labelName : "Select",
      value: "",
    });

    if (data && data.length) {
      data.map((obj) => {
        let options = {
          label: obj[label],
          value: obj[value],
        };
        optionsData.push(options);
      });
    }
    return optionsData;
  },

  getOptionsOnlyData(data, label, value, string = false, disable = []) {
    let optionsData = [];

    if (data && data.length) {
      data.map((obj) => {
        let options = {
          label: obj[label],
          value: string ? obj[value].toString() : obj[value],
          disabled: disable.includes(
            string ? obj[value].toString() : obj[value]
          ),
        };
        optionsData.push(options);
      });
    }
    return optionsData;
  },

  getParam(name) {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let view = params.get(name);
    if (view) {
      return view;
    } else {
      return "";
    }
  },
  // permission
  getPermission(permissions, type) {
    try {
      let value = envConfig.allPermissions[0].value;
      return {
        read: this.checkPermission(permissions, type + ":r") === value,
        add: this.checkPermission(permissions, type + ":a") === value,
        add: this.checkPermission(permissions, type + ":a") === value,
        update: this.checkPermission(permissions, type + ":u") === value,
        delete: this.checkPermission(permissions, type + ":d") === value,
        export: this.checkPermission(permissions, type + ":e") === value,
        import: this.checkPermission(permissions, type + ":i") === value,
        access: this.checkPermission(permissions, type + ":r") === value,
      };
    } catch (err) {
      this.snack("E", err.message);
      return {
        read: false,
        add: false,
        update: false,
        delete: false,
        export: false,
        access: false,
      };
    }
  },

  getPermissionData(permissions) {
    return {
      read: permissions.r || "2",
      add: permissions.a || "2",
      update: permissions.u || "2",
      delete: permissions.d || "2",
      import: permissions.i || "2",
      export: permissions.e || "2",
    };
  },
  getMonthList() {
    return [
      { label: "January", value: "JAN" },
      { label: "Febuary", value: "FEB" },
      { label: "March", value: "MAR" },
      { label: "April", value: "APR" },
      { label: "May", value: "MAY" },
      { label: "June", value: "JUN" },
      { label: "July", value: "JUL" },
      { label: "August", value: "AUG" },
      { label: "September", value: "SEP" },
      { label: "October", value: "OCT" },
      { label: "November", value: "NOV" },
      { label: "December", value: "DEC" },
    ];
  },
  getYearList(from) {
    let year = new Date().getFullYear();
    let result = [];
    for (let i = year - from; i <= year + 5; i++) {
      let obj = {
        label: i,
        value: i,
      };
      result.push(obj);
    }
    return result;
  },
  givenPermission(
    permission,
    jsonDecorator,
    checkFor = "basic",
    id = undefined
  ) {
    let _getPermission = this.getPermission(permission, jsonDecorator);

    let action = [
      "export",
      "import",
      "access",
      "update",
      "read",
      "add",
      "delete",
    ];
    if (checkFor.toLowerCase() === "basic") {
      if (!_getPermission.read) {
        return true;
      } else if (!_getPermission.add) {
        return true;
      } else if (!_getPermission.update && id !== undefined) {
        return true;
      } else {
        return false;
      }
    } else if (action.includes(checkFor.toLowerCase())) {
      if (!_getPermission[checkFor.toLowerCase()]) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  },

  ModToKey(modArg, raw = false) {
    if (raw) {
      let key = {
        campaign: "campaigns",
        enquiry: "enquiry",
        lead: "lead",
        deal: "deal",
      };
      return ["contacts"].includes(modArg) ? "contact" : key[modArg];
    } else {
      let key = {
        campaign: "crm:campaigns",
        enquiry: "crm:enquiry",
        lead: "crm:lead",
        deal: "crm:deal",
      };
      return ["contacts"].includes(modArg) ? "contact" : key[modArg];
    }
  },

  getAuth() {
    let auth = null;
    if (this.authCheck()) {
      auth = true;
    }
    return auth;
  },

  removeAuthStorage() {
    localStorage.clear();
    // history.push("/signin");
    window.location.href = this.url("/signin");
  },

  redirect(page, time) {
    setTimeout(
      () => {
        window.location.href = this.url(page);
      },
      !time ? 1000 : time * 1000
    );
    return false;
  },

  url(url) {
    return process.env.PUBLIC_URL + url;
  },

  evalPermissions(access, recordTerId, resourceId) {
    let userData = this.deCrypt(localStorage.getItem("userData"));
    let territory = userData["territory"];
    let isAdmin = userData["roleId"] === 0;

    let deptEmployess = userData["employeeList"];

    if (isAdmin) {
      return true;
    } else {
      if (access == "1") {
        return true;
      }
      if (access == "2") {
        return false;
      }
      if (access == "3") {
        //assigned records as personal records
        // console.log('recordTerId == resourceId :>> ', userData.EmpId === resourceId, userData.EmpId , resourceId);
        //return territory.includes(recordTerId);
        return userData.EmpId === resourceId;
      } else if (access == "4") {
        //personal + current department
        return (
          territory.includes(recordTerId) ||
          deptEmployess.active.includes(resourceId)
        );
      } else if (access == "5") {
        //personal + current department, its sub department
        return (
          deptEmployess.active.includes(resourceId) ||
          deptEmployess.sub.includes(resourceId)
        );
      }
    }
  },
  /**
   * Snackbar message
   */
  snack(type, msg = "") {
    switch (type) {
      case "S":
        SnackbarUtils.success(msg);
        break;
      case "E":
        SnackbarUtils.error(msg);
        break;
      case "W":
        SnackbarUtils.warning(msg);
        break;
      case "I":
        SnackbarUtils.info(msg);
        break;
      case "T":
        SnackbarUtils.toast(msg);
        break;
      case "C":
        SnackbarUtils.closeAll();
        break;
    }
  },
  selectVal() {
    return {
      label: "Select",
      value: "",
    };
  },

  numArray(num) {
    let array = [];
    for (let i = 0, j = 1; i < num; i++, j++) {
      array.push(j);
    }
    return array;
  },

  removeItem(array, val) {
    const index = array.indexOf(val);
    if (index > -1) {
      array.splice(index, 1);
    }
    return array;
  },

  assignItem(data) {
    let result = [];
    for (const [key, value] of Object.entries(data)) {
      result[key] = value;
    }
    return result;
  },

  uniqueKey() {
    return Math.floor(
      Math.pow(10, 10 - 1) + Math.random() * 9 * Math.pow(10, 10 - 1)
    );
  },

  // input
  ValidateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    } else {
      return false;
    }
  },

  validateDate(date) {
    if (date == null || date == "") {
      return null;
    } else {
      return moment(date).toDate();
    }
  },
  textCapitalize(text) {
    if (text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    }
    return "";
  },

  objectKeysArray(obj) {
    if (obj) {
      return Object.keys(obj).sort();
    }
    return [];
  },

  getJoinString(arr, key) {
    if (!arr || !arr?.length) return "";

    let textList = [];

    if (arr[0] && typeof arr[0] !== "object") {
      textList = arr;
    } else if (key) {
      arr.forEach((item) => {
        textList.push(item[key]);
      });
    }
    return textList.join(", ");
  },

  loadEduImage(img) {
    return envConfig.EDUTECH_S3_URL + img;
  },

  ArrayJsonFind(key, value, Array, getObject = false) {
    let hashValue = false;
    let Object = {};
    Array.map((item, index) => {
      if (item[key] === value) {
        hashValue = true;
        Object = item;
        Object.index = index;
      }
    });
    if (getObject) {
      return Object;
    } else {
      return hashValue;
    }
  },
  QueryParamsToUrl(urlParams) {
    let URL = "";
    urlParams.map((item, index) => {
      if (item?.value && item?.value !== undefined) {
        if (index === 0) {
          URL = URL + `?${item.key}=${item.value}`;
        } else {
          URL = URL + `&${item.key}=${item.value}`;
        }
      }
    });

    return URL;
  },
  isEduTech() {
    let userInfo = common.authInfo();
    let tId = parseInt(userInfo?.TenantId);
    return envConfig.EDUTECH_TENANT.includes(tId);
  },
  getMonthList() {
    return [
      { label: "Jan", value: "1" },
      { label: "Feb", value: "2" },
      { label: "Mar", value: "3" },
      { label: "Apr", value: "4" },
      { label: "May", value: "5" },
      { label: "June", value: "6" },
      { label: "July", value: "7" },
      { label: "Aug", value: "8" },
      { label: "Sep", value: "9" },
      { label: "Oct", value: "10" },
      { label: "Nov", value: "11" },
      { label: "Dec", value: "12" },
    ];
  },
};
