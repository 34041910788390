import React from "react";
import { common } from "helpers";
import { Container, Row, Col } from "react-bootstrap";

function Loader() {
  return (
    <Container
      fluid
      className="loader-wrap py-5 d-flex justify-content-center align-items-center"
    >
      <Row>
        <Col lg={12} className="text-center">
          <img
            src={common.loadImg("crm-loader_1.gif")}
            className="loader-img"
            alt="application-icn"
          />
          <h3 className="nodata-found-txt pt-2">Loading data please wait</h3>
        </Col>
      </Row>
    </Container>
  );
}
export default Loader;
