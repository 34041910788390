import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { Col, Row } from "react-bootstrap";
import {
  Link,
  useNavigate,
  useParams,
  useOutletContext,
} from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
  Checkbox,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import InputIcon from "react-multi-date-picker/components/input_icon";
import DatePicker from "react-multi-date-picker";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import AmlHashForm from "aml-hash-form";
import AccessDenied from "elements/AccessDenied";
import { CRM_LOCATIONS } from "helpers/service";
import FieldSelect from "elements/FieldSelect";
import StatusBar from "elements/StatusBar";
import { api, common, envConfig } from "helpers";

const SAVE_SUCCESS = "Location details saved successfully.";
const SAVE_FAILED = "Location save failed.";
const UPDATE_FAILED = "Location update failed.";
const BACK_URL = "/setting/vendor/location";

function AddLocation() {
  const { id } = useParams();
  const LocationId = id;
  const [permission] = useOutletContext();
  const navigate = useNavigate();
  const userData = common.userInfo();
  const [selectRender, setSelectRender] = useState({
    CountryCode: "yes",
    State: "",
    City: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(id ? "process" : "success");

  const formFields = {
    LocationCode: { validate: ["req#Location Code is required"] },
    LocationName: { validate: ["req#Location Name is required"] },
    Description: { validate: ["req#Description is required"] },
    Address1: { validate: [] },
    Address2: { validate: [] },
    Address3: { validate: [] },
    StartDate: { validate: ["req#Start Date is required"] },
    EndDate: { validate: [] },
    CountryCode: {
      validate: [],
      obj: "value",
    },
    State: {
      validate: [],
      obj: "value",
    },
    City: { validate: [], obj: "value" },
    RegionCode: "",
    PostalCode: "",
    TelNumber1: "",
    TelNumber2: "",
    Active: { value: false },
    IsBillToLocation: { value: false },
    IsHub: { value: false },
    IsLegalAddress: { value: false },
    IsShipToLocation: { value: false },
  };

  // Main Form
  const {
    fields,
    errors,
    handleChange,
    handleSubmit,
    setValue,
    setMultiValue,
  } = AmlHashForm(formFields);

  useEffect(() => {
    if (LocationId) {
      getLocationInfo();
    }
  }, []);

  const getLocationInfo = () => {
    let url = `${envConfig.BASE_API}${CRM_LOCATIONS}/${LocationId}`;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };

    api.call(
      data,
      (response) => {
        if (response.status === 200) {
          const result = response.data;
          if (result) {
            setMultiValue({
              LocationCode: result.LocationCode || "",
              LocationName: result.LocationName || "",
              Description: result.Description || "",
              Address1: result.AddressLine1 || "",
              Address2: result.AddressLine2 || "",
              Address3: result.AddressLine3 || "",
              StartDate: result.StartDate || "",
              EndDate: result.EndDate || "",
              CountryCode: result.CountryCode
                ? {
                    label: result.CountryCode,
                    value: result.CountryCode,
                  }
                : null,
              State: result.State
                ? {
                    label: result.State,
                    value: result.State,
                  }
                : null,
              City: result.City
                ? {
                    label: result.City,
                    value: result.City,
                  }
                : null,
              RegionCode: result.RegionCode || "",
              PostalCode: result.PostalCode || "",
              TelNumber1: result.TelNumber1 || "",
              TelNumber2: result.TelNumber2 || "",
              Active: result.Active === "Y",
              IsBillToLocation: result.IsBillToLocation === "Y",
              IsHub: result.IsHub === "Y",
              IsLegalAddress: result.IsLegalAddress === "Y",
              IsShipToLocation: result.IsShipToLocation === "Y",
            });
            // select
            setSelectRender({
              CountryCode: "yes",
              State: result.CountryCode ? "yes" : "",
              City: result.State ? "yes" : "",
            });
          }
        }
        setStatus("success");
      },
      (error) => {
        setStatus("error");
      },
      (final) => {}
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let isValid = handleSubmit();
    if (isValid) {
      save();
    }
  };

  const save = () => {
    setIsLoading(true);

    let params = {
      LocationCode: fields.LocationCode.toUpperCase(),
      LocationName: fields.LocationName,
      Description: fields.Description,
      RegionCode: fields.RegionCode,
      CountryCode: fields.CountryCode?.value,
      State: fields.State?.value,
      City: fields.City?.value,
      AddressLine1: fields.Address1,
      AddressLine2: fields.Address2,
      AddressLine3: fields.Address3,
      PostalCode: fields.PostalCode,
      TelNumber1: fields.TelNumber1,
      TelNumber2: fields.TelNumber2,
      IsShipToLocation: fields.IsShipToLocation ? "Y" : "N",
      IsBillToLocation: fields.IsBillToLocation ? "Y" : "N",
      IsLegalAddress: fields.IsLegalAddress ? "Y" : "N",
      IsHub: fields.IsHub ? "Y" : "N",
      Active: fields.Active ? "Y" : "N",
      StartDate: fields.StartDate
        ? common.formatDate(fields.StartDate, "YYYY-MM-DDTHH:mm:ssZ")
        : null,
      EndDate: fields.EndDate
        ? common.formatDate(fields.EndDate, "YYYY-MM-DDTHH:mm:ssZ")
        : null,
      TenantId: userData.pTenantId,
      // OrgId: userData.pOrgId,
    };

    let url = `${envConfig.BASE_API}${CRM_LOCATIONS}`;
    if (LocationId) {
      url = `${envConfig.BASE_API}${CRM_LOCATIONS}/${LocationId}`;
    }
    let data = {
      url: url,
      type: "dynamic",
      method: LocationId ? "PATCH" : "POST",
      body: JSON.stringify(params),
      auth: "token",
      cType: 4,
    };

    api.call(
      data,
      (res) => {
        if (res.data) {
          if (LocationId) {
            common.snack("S", SAVE_SUCCESS);
          } else {
            common.snack("S", SAVE_SUCCESS);
          }
          navigate(BACK_URL);
        } else if (
          res?.status === 500 ||
          res?.status === 400 ||
          res?.status === 404
        ) {
          const error = res?.data?.error;
          if (LocationId) {
            common.snack("E", error || UPDATE_FAILED);
          } else {
            common.snack("E", error || SAVE_FAILED);
          }
        }
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
        common.snack("E", SAVE_FAILED);
      },
      (final) => {
        setIsLoading(false);
      }
    );
  };
  return (
    <div style={{ padding: "20px" }}>
      {common.givenPermission(
        permission,
        "vendor:locations",
        "basic",
        LocationId
      ) ? (
        <AccessDenied />
      ) : (
        <Paper
          sx={{ width: "100%", mb: 2 }}
          className="container responsive-table p-0"
        >
          <Box>
            <Paper variant="contained dynamic-form-wrap" square>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item display="flex" alignItems="center" xs={5} className="detail-head">
                  <Grid py={1} px={1} className="icon-back icon-buton">
                    <Link to={BACK_URL}>
                      <img
                        src={common.loadImg("backArrowKanban.svg")}
                        alt="back"
                      />
                    </Link>
                  </Grid>
                  <Grid className="df-main-head">
                    <Typography className="detail-title">{`${
                      LocationId ? "Edit" : "Add"
                    } Location`}</Typography>
                  </Grid>
                </Grid>
                <Grid item className="safari-style">
                  <Box px={2} className="safari-style">
                    <Grid container alignItems="center" spacing={2} className="safari-style">
                      <Grid item>
                        <Box className="verical-divider">
                          <Divider orientation="vertical" />
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box>
                          {status == "success" && (
                            <Button
                              className="sign-in-btn"
                              fullWidth
                              disabled={isLoading}
                              onClick={(e) => onSubmit(e)}
                            >
                              {isLoading ? "Processing..." : "Save Changes"}
                            </Button>
                          )}
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box className="vertical-divider">
                          <Divider orientation="vertical" />
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box className="revert-close">
                          <Button className="save-note pe-0">
                            <Link to={BACK_URL}>
                              Revert and Close
                              <img
                                src={common.loadImg("closeKanban.svg")}
                                alt="close"
                              />
                            </Link>
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
              <Box>
                <Divider />
              </Box>
              {status !== "success" ? (
                <StatusBar status={status} />
              ) : (
                <Fragment>
                  <Box p={2} px={5} className="form-wrap">
                    <Typography className="info-title dynamic-form-title mt-3">
                      LOCATION INFORMATION
                    </Typography>
                    <Box mt={3}>
                      <Divider />
                    </Box>

                    <Box mt={2} className="camp-edit meet-table">
                      <Row className="m-0">
                        <Col
                          xs={6}
                          className="body-th common-input-style mandatoryclass"
                        >
                          <Box className="filled-box">
                            <TextField
                              id="filled-basic"
                              label="Location Code"
                              name="LocationCode"
                              variant="filled"
                              value={fields.LocationCode}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            />
                            {errors.LocationCode ? (
                              <p className="error-txt">{errors.LocationCode}</p>
                            ) : null}
                          </Box>
                        </Col>
                        <Col
                          xs={6}
                          className="body-th common-input-style mandatoryclass"
                        >
                          <Box className="filled-box">
                            <TextField
                              id="filled-basic"
                              label="Location Name"
                              name="LocationName"
                              variant="filled"
                              value={fields.LocationName}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            />
                            {errors.LocationName ? (
                              <p className="error-txt">
                                {errors.LocationName || ""}
                              </p>
                            ) : null}
                          </Box>
                        </Col>
                        <Col
                          xs={6}
                          className="body-th common-input-style mandatoryclass"
                        >
                          <Box className="" id="float-label">
                            <label
                              className={
                                fields.StartDate
                                  ? "Active text-label ml-4 camp-time-label"
                                  : "text-label ml-4 camp-time-label"
                              }
                            >
                              Start Date
                            </label>

                            <Box className="ml-1 input-date-picker">
                              <DatePicker
                                format="MM/DD/YYYY"
                                render={<InputIcon />}
                                name="StartDate"
                                placeholder="Start Date"
                                value={
                                  fields.StartDate
                                    ? moment(fields.StartDate).toDate()
                                    : ""
                                }
                                onChange={(e) => {
                                  setMultiValue({
                                    StartDate: e?.toDate() || "",
                                  });
                                }}
                              />
                            </Box>
                          </Box>
                          {errors.StartDate ? (
                            <p className="error-txt">
                              {errors.StartDate || ""}
                            </p>
                          ) : null}
                        </Col>
                        <Col xs={6} className="body-th common-input-style">
                          <Box className="" id="float-label1">
                            <label
                              className={
                                fields.EndDate
                                  ? "Active text-label ml-4 camp-time-label"
                                  : "text-label ml-4 camp-time-label"
                              }
                            >
                              End Date
                            </label>

                            <Box className="ml-1 input-date-picker">
                              <DatePicker
                                format="MM/DD/YYYY"
                                render={<InputIcon />}
                                placeholder="End Date*"
                                name="EndDate"
                                value={
                                  fields.EndDate
                                    ? moment(fields.EndDate).toDate()
                                    : ""
                                }
                                onChange={(e) => {
                                  setMultiValue({
                                    EndDate: e?.toDate() || "",
                                  });
                                }}
                              />
                            </Box>
                          </Box>
                        </Col>
                        <Col
                          xs={6}
                          className="body-th common-input-style mandatoryclass"
                        >
                          <Box>
                            <div className="form-floating">
                              <textarea
                                className="form-control textarea"
                                id="floatingTextarea"
                                name="Description"
                                value={fields.Description}
                                rows={2}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                              />
                              <label
                                htmlFor="floatingTextarea"
                                className="floating-label"
                              >
                                Description
                              </label>
                              {errors.Description ? (
                                <p className="error-txt">
                                  {errors.Description}
                                </p>
                              ) : null}
                            </div>
                          </Box>
                        </Col>

                        <Col xs={6} className="body-th common-input-style">
                          <FormControlLabel
                            className="dynamic-checkbox ms-0 checkbox-space"
                            control={<Checkbox />}
                            label="Active"
                            checked={fields.Active}
                            labelPlacement="end"
                            name="Active"
                            value=""
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                        </Col>
                      </Row>
                    </Box>
                  </Box>
                  <Box p={2} px={5} className="form-wrap">
                    <Typography className="info-title dynamic-form-title mt-3">
                      ADDRESS DETAILS
                    </Typography>
                    <Box mt={3}>
                      <Divider />
                    </Box>

                    <Box mt={2} className="camp-edit meet-table">
                      <Row className="m-0">
                        <Col xs={6} className="body-th common-input-style">
                          <Box className="filled-box">
                            <TextField
                              id="filled-basic"
                              label="Address Line 1"
                              name="Address1"
                              variant="filled"
                              value={fields.Address1}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            />
                          </Box>
                        </Col>
                        <Col xs={6} className="body-th common-input-style">
                          <Box className="filled-box">
                            <TextField
                              id="filled-basic"
                              label="Address Line 2"
                              name="Address2"
                              variant="filled"
                              value={fields.Address2}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            />
                          </Box>
                        </Col>
                        <Col xs={6} className="body-th common-input-style">
                          <Box className="filled-box">
                            <TextField
                              id="filled-basic"
                              label="Address Line 3"
                              name="Address3"
                              variant="filled"
                              value={fields.Address3}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            />
                          </Box>
                        </Col>
                        <Col xs={6} className="body-th common-input-style">
                          <Box className="">
                            <FormControl
                              fullWidth
                              className="select-float-label  common-select-border common-padding-zero"
                            >
                              <FieldSelect
                                api="CRM_COUNTRIES"
                                get="CountryName"
                                set="CountryId"
                                value={fields.CountryCode}
                                placeholder="Select the country"
                                isMulti={false}
                                isRender={selectRender.CountryCode}
                                onSelect={(data) => {
                                  setMultiValue({
                                    CountryCode: data,
                                    State: null,
                                    City: null,
                                  });
                                  setSelectRender((prev) => ({
                                    ...prev,
                                    State: data === null ? "empty" : new Date(),
                                    City: "empty",
                                  }));
                                }}
                              />
                            </FormControl>
                          </Box>
                        </Col>
                        <Col xs={6} className="body-th common-input-style">
                          <Box className="">
                            <FormControl
                              fullWidth
                              className="select-float-label  common-select-border common-padding-zero"
                            >
                              <FieldSelect
                                api="CRM_STATES"
                                query={`CountryId=${fields?.CountryCode?.value}`}
                                get="StateName"
                                set="StateId"
                                value={fields.State}
                                placeholder="Select the state"
                                isRender={selectRender.State}
                                onSelect={(data) => {
                                  setMultiValue({
                                    State: data,
                                    City: null,
                                  });
                                  setSelectRender((prev) => ({
                                    ...prev,
                                    City: data === null ? "empty" : new Date(),
                                  }));
                                }}
                              />
                            </FormControl>
                          </Box>
                        </Col>
                        <Col xs={6} className="body-th common-input-style">
                          <Box className="">
                            <FormControl
                              fullWidth
                              className="select-float-label  common-select-border common-padding-zero"
                            >
                              <FieldSelect
                                api="CRM_CITIES"
                                query={`StateId=${fields?.State?.value}`}
                                get="CityName"
                                set="CityId"
                                value={fields.City}
                                placeholder="Select the city"
                                isRender={selectRender.City}
                                onSelect={(data) => {
                                  setMultiValue({
                                    City: data,
                                  });
                                }}
                              />
                            </FormControl>
                          </Box>
                        </Col>
                        <Col xs={6} className="body-th common-input-style">
                          <Box className="filled-box">
                            <TextField
                              id="filled-basic"
                              label="Region"
                              variant="filled"
                              name="RegionCode"
                              value={fields.RegionCode}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            />
                          </Box>
                        </Col>
                        <Col xs={6} className="body-th common-input-style">
                          <Box className="filled-box">
                            <TextField
                              id="filled-basic"
                              label="Postal Code"
                              variant="filled"
                              name="PostalCode"
                              value={fields.PostalCode}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            />
                          </Box>
                        </Col>

                        <Col xs={6} className="body-th common-input-style">
                          <Box className="text-box">
                            <label className="text-label ml-3 mt-1">
                              Telephone No
                            </label>
                            {/* <PhoneInput
                            country={"lk"}
                            className="ml-3"
                            name="TelNumber1"
                            value={fields.TelNumber1}
                          
                          onChange={(e) => {
                              handleChange(e);
                            }} 
                         

                          />
                          */}
                            <PhoneInput
                              country={"lk"}
                              className="ml-3"
                              name="TelNumber1"
                              value={fields.TelNumber1}
                              onChange={(phone) =>
                                setValue("TelNumber1", phone)
                              }
                            />
                          </Box>
                        </Col>
                        <Col xs={6} className="body-th common-input-style">
                          <Box className="text-box">
                            <label className="text-label ml-3 mt-1">
                              Alternate Telephone No
                            </label>
                            {/*
                           <PhoneInput
                            country={"lk"}
                            className="ml-3"
                            name="TelNumber2"
                            value={fields.TelNumber2}
                          
                           onChange={(e) => {
                              handleChange(e);
                            }} 
                         
                          /> */}
                            <PhoneInput
                              country={"lk"}
                              className="ml-3"
                              name="TelNumber2"
                              value={fields.TelNumber2}
                              onChange={(phone) =>
                                setValue("TelNumber2", phone)
                              }
                            />
                          </Box>
                        </Col>
                        <Col xs={6} className="body-th common-input-style">
                          <FormControlLabel
                            className="dynamic-checkbox ms-0 checkbox-space"
                            control={<Checkbox />}
                            label="Bill to Location"
                            name="IsBillToLocation"
                            checked={fields.IsBillToLocation}
                            labelPlacement="end"
                            value=""
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                        </Col>
                        <Col xs={6} className="body-th common-input-style">
                          <FormControlLabel
                            className="dynamic-checkbox ms-0 checkbox-space"
                            control={<Checkbox />}
                            label="Hub?"
                            name="IsHub"
                            checked={fields.IsHub}
                            value=""
                            labelPlacement="end"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                        </Col>
                        <Col xs={6} className="body-th common-input-style">
                          <FormControlLabel
                            className="dynamic-checkbox ms-0 checkbox-space"
                            control={<Checkbox />}
                            label="Legal Address?"
                            name="IsLegalAddress"
                            checked={fields.IsLegalAddress}
                            labelPlacement="end"
                            value=""
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                        </Col>
                        <Col xs={6} className="body-th common-input-style">
                          <FormControlLabel
                            className="dynamic-checkbox ms-0 checkbox-space"
                            control={<Checkbox />}
                            label="Ship to Location?"
                            name="IsShipToLocation"
                            value=""
                            checked={fields.IsShipToLocation}
                            labelPlacement="end"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                        </Col>
                      </Row>
                    </Box>
                  </Box>
                </Fragment>
              )}
            </Paper>
          </Box>
        </Paper>
      )}
    </div>
  );
}

export default AddLocation;
