import TabContext from "@mui/lab/TabContext";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import AccessDenied from "elements/AccessDenied";
import CrmDetails from "elements/CrmDetails";
import DynamicForm from "elements/DynamicForm";
import ETDealForm from "elements/ETDealForm";

import ETLeadForm from "elements/ETLeadForm";
import KanbanView from "elements/KanbanView";
import ListView from "elements/ListView";
import CampaignsSubMenu from "elements/crmSubMenuView";
import { common } from "helpers";
import SubMenu from "layouts/subMenu";
import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";

function CrmLayout(props) {
  let { mod, type, id } = useParams();
  const [status, setStatus] = useState("process");
  const [permission] = useOutletContext();
  const navigate = useNavigate();
  const [module, setModule] = React.useState(mod || "campaign");
  const [viewType, setViewType] = React.useState("list");
  const [isForm, setIsForm] = React.useState(false);
  const [ids, setIds] = useState(id);
  const [showDetails, setShowDetails] = useState(false);
  const isEdutechAcc =
    common.isEduTech() && ["lead", "deal"].includes(mod) ? true : false;

  useEffect(() => {
    setModule(mod || "campaign");
    setStatus("process");
    setShowDetails(false);
    setIsForm(false);
    if (type && (type == "list" || type == "kanban")) {
      setIsForm(false);
      setShowDetails(false);
      let pageFilter = common.getPagination(mod);
      if (pageFilter && pageFilter.view) {
        setViewType(pageFilter.view);
        navigate("/crm/" + module + "/" + pageFilter.view);
      } else {
        setViewType(type);
      }
    } else if (type && (type == "add" || type == "edit")) {
      if (type == "add") {
        setIds("");
      }
      setIsForm(true);
      setViewType(type);
      setShowDetails(false);
    } else if (type && type == "details") {
      setShowDetails(true);
      setIsForm(false);
    }
    if (id) {
      setIds(id);
    }
    setTimeout(() => {
      setStatus("success");
    }, 100);
  }, [mod, type, id]);

  const changeViewType = (val) => {
    setStatus("process");
    setViewType(val);
    setIsForm(false);
    setModule(module);
    navigate("/crm/" + module + "/" + val);
    let pageFilter = common.getPagination(props.module);
    if (pageFilter) {
      pageFilter.view = val;
    } else {
      let fil = {
        paging: {
          page: 1,
          limit: 10,
        },
        sort: {},
        view: val,
      };
      pageFilter = Object.assign({}, fil);
    }

    common.setStoragePagination(mod, pageFilter);
  };

  const changeFormType = (val, type, item, id) => {
    setStatus("process");
    setIsForm(val);
    setShowDetails(false);
    if (val == true) {
      if (type == "add") {
        navigate("/crm/" + module + "/add");
        setIds(0);
      } else if (type == "edit") {
        navigate("/crm/" + module + "/edit/" + ids);
      }
    } else {
      if (type == "id" && id) {
        setIds(id);
        navigate("/crm/" + module + "/details/" + id);
        setShowDetails(true);
      } else {
        navigate("/crm/" + module + "/list");
      }
    }
  };

  const onChangeMenu = (newValue) => {
    setStatus("process");
    setShowDetails(false);
    setModule(newValue);
    navigate("/crm/" + newValue + "/list");
  };

  const onHandleDetails = (e, val, item) => {
    e.preventDefault();
    setShowDetails(val);
    setViewType("");
    let par =
      mod == "campaign"
        ? item.CampaignId
        : mod == "enquiry"
        ? item.ListId
        : mod == "lead"
        ? item.LeadId
        : mod == "deal"
        ? item.OpportunityId
        : item.ContactId;
    if (val == true) {
      navigate("/crm/" + module + "/details/" + par);
    } else {
      navigate("/crm/" + module + "/list");
    }
    setStatus("process");
  };

  const providePermission = (argMod, type) => {
    const action = {
      read: "r",
      update: "u",
      export: "e",
      import: "i",
      add: "a",
      delete: "d",
    };

    if (["lead", "deal"].includes(argMod)) {
      if (
        ["1", "2"].includes(
          permission?.crm?.[common.ModToKey(argMod, true)]?.[action[type]]
        )
      ) {
        return common.givenPermission(
          permission,
          common.ModToKey(argMod),
          type
        );
      } else {
        return false;
      }
    } else {
      return common.givenPermission(permission, common.ModToKey(argMod), type);
    }
  };

  return (
    <div className="default-bg">
      <Box
        sx={{ width: "100%", typography: "body1" }}
        className="primary-header-container table-view-width"
      ></Box>
      <TabContext value={module}>
        {/* Sub Menu */}
        <SubMenu menu={"crm"} selectedMenu={(val) => onChangeMenu(val)} />
        {providePermission(mod, "read") ? (
          <AccessDenied />
        ) : (
          <>
            {!showDetails && (
              <div style={{ padding: "20px" }}>
                {!isForm && viewType == "list" && (
                  <Paper
                    sx={{ width: "100%", mb: 2 }}
                    id="list-view-wrap"
                    className={`container responsive-table crm-table p-0 ${
                      !isForm ? "kanban-paper-cont" : ""
                    }`}
                  >
                    {/* Listview */}
                    <ListView
                      changeView={(val) => changeViewType(val)}
                      changeForm={(val) => changeFormType(val, "add")}
                      module={mod}
                      status={status}
                      data={
                        mod == "lead"
                          ? "OpportunityConverted,LeadName,Email"
                          : ["deal", "enquiryMember", "contacts"].includes(mod)
                          ? mod == "contacts"
                            ? "EmailAddress"
                            : "Email"
                          : ""
                      }
                      onHandleDetails={(e, val, item) =>
                        onHandleDetails(e, val, item)
                      }
                      // sort={mod != "deal" ? "CreatedOn:desc" : "CreationDate:desc"}
                      type={"menu"}
                      accAdd={!providePermission(mod, "add")}
                      accExport={!providePermission(mod, "export")}
                      accDelete={!providePermission(mod, "delete")}
                      accUpdate={!providePermission(mod, "update")}
                      accImport={!providePermission(mod, "import")}
                      isExpand={"tab"}
                      id={id}
                    />
                  </Paper>
                )}
                {!isForm && viewType == "kanban" && mod != "contacts" && (
                  // KanbanView
                  <KanbanView
                    changeForm={(val) => changeFormType(val, "add")}
                    changeView={(val) => changeViewType(val)}
                    module={mod}
                    onHandleDetails={(e, val, item) =>
                      onHandleDetails(e, val, item)
                    }
                    sort={mod != "deal" ? "CreatedOn:desc" : ""}
                  />
                )}

                {isForm && viewType && status == "success" && (
                  <div>
                    <>
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>Form</Accordion.Header>
                          <Accordion.Body>
                            {isEdutechAcc ? (
                              mod == "lead" ? ( // Edutech Lead form
                                <ETLeadForm
                                  data={{
                                    value1: ids > 0 ? ids : "",
                                  }}
                                  closeForm={(val, type, data, id) =>
                                    changeFormType(val, type, data, id)
                                  }
                                  accAdd={common.givenPermission(
                                    permission,
                                    common.ModToKey(mod),
                                    "add"
                                  )}
                                  accUpdate={common.givenPermission(
                                    permission,
                                    common.ModToKey(mod),
                                    "update"
                                  )}
                                />
                              ) : (
                                // Edutech Deal form
                                <ETDealForm
                                  data={{
                                    value1: ids > 0 ? ids : "",
                                  }}
                                  closeForm={(val, type, data, id) =>
                                    changeFormType(val, type, data, id)
                                  }
                                  accAdd={common.givenPermission(
                                    permission,
                                    common.ModToKey(mod),
                                    "add"
                                  )}
                                  accUpdate={common.givenPermission(
                                    permission,
                                    common.ModToKey(mod),
                                    "update"
                                  )}
                                />
                              )
                            ) : (
                              <DynamicForm
                                module={mod}
                                data={{
                                  value1: ids > 0 ? ids : "",
                                }}
                                closeForm={(val, viewType, data, id) =>
                                  changeFormType(val, viewType, data, id)
                                }
                                userPermissions={
                                  mod == "contacts"
                                    ? permission[common.ModToKey(mod, true)]
                                    : permission.crm[common.ModToKey(mod, true)]
                                }
                                accAdd={common.givenPermission(
                                  permission,
                                  common.ModToKey(mod),
                                  "add"
                                )}
                                accUpdate={common.givenPermission(
                                  permission,
                                  common.ModToKey(mod),
                                  "update"
                                )}
                              />
                            )}
                          </Accordion.Body>
                        </Accordion.Item>
                        {ids > 0 && mod !== "contacts" && (
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>Sub Menu</Accordion.Header>
                            <Accordion.Body>
                              <CampaignsSubMenu
                                id={ids}
                                // handleExpanded={handleExpanded}
                                // isExpand={isExpand}
                                module={mod}
                                status={status}
                              />
                            </Accordion.Body>
                          </Accordion.Item>
                        )}
                      </Accordion>
                    </>
                  </div>
                )}
              </div>
            )}

            {/* Crm Dynamic view form */}
            {showDetails && id > 0 && status == "success" && (
              <CrmDetails
                closeDetails={(e, val) => onHandleDetails(e, val, {})}
                changeForm={(val) => changeFormType(val, "edit")}
                id={id}
                status={status}
                module={mod}
                data={{
                  value1: ids > 0 ? ids : "",
                }}
                accRead={!providePermission(mod, "read")}
                accAdd={!providePermission(mod, "add")}
                accExport={!providePermission(mod, "export")}
                accDelete={!providePermission(mod, "delete")}
                accUpdate={!providePermission(mod, "update")}
                accImport={!providePermission(mod, "import")}
                userAccess={
                  mod == "contacts"
                    ? permission?.[common.ModToKey(mod, true)]
                    : permission?.crm?.[common.ModToKey(mod, true)]
                }
              />
            )}
          </>
        )}
      </TabContext>
    </div>
  );
}

export default CrmLayout;
