import React, { useState, Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import MuiSelect from "@material-ui/core/Select";
import ListItem from "@material-ui/core/ListItem";
import { Grid } from "@mui/material";

import InputLabel from "@material-ui/core/InputLabel";

import MuiButton from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
// dialog
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
// api
import { common } from "helpers";
// files
import lookupList from "assets/files/lookupList.json";
// icon
import DeleteIcon from "@material-ui/icons/Delete";
import MenuIcon from "@material-ui/icons/Menu";
// css
import "../FormSettings.css";

function FormProperties(props) {
  let isDrag = null;
  const [data, setData] = useState(props.data);
  const [bulk, setBulk] = useState(false);
  const [bulkData, setBulkData] = useState("");
  const [isDragMode, setDragMode] = useState(null);
  const [dragItem, setDragItem] = useState(null);
  const [dropItem, setDropItem] = useState(null);

  // Drag Option
  const onDragStartItem = (data) => {
    if (isDrag == null) {
      isDrag = "item";
      setDragMode("item");
      setDragItem(data);
    }
  };

  const handleDragEndItem = () => {
    if (dropItem != null) {
      setData((oldData) => {
        let newData = reParse(oldData);
        let option = newData.field.attr.option;
        let option1 = option[dragItem];
        option.splice(dragItem, 1);
        option.splice(dropItem, 0, option1);
        newData.field.attr.option = option;
        return newData;
      });
      setDropItem(null);
    }
  };

  const onDragOverItem = (item) => {
    setDropItem(item);
  };

  const onDragLeaveItem = () => {
    setTimeout(() => {
      setDropItem(null);
    }, 10);
  };

  const itemStyles = (key) => {
    if (
      isDragMode == "item" &&
      dropItem !== null &&
      dropItem == key &&
      dragItem != key
    ) {
      return "dragStart optionDD";
    } else if (isDragMode == "section") {
      return "dragStart";
    } else {
      return "";
    }
  };

  // handler
  const onChangeLabel = (e) => {
    fieldAttr("label", e.target.value);
  };

  const onChangeRequired = (e) => {
    fieldAttr("required", e.target.checked ? true : false);
    if (e.target.checked) {
      fieldAttr("quick", true);
    }
  };

  const onChangeQuick = (e) => {
    fieldAttr("quick", e.target.checked ? true : false);
  };

  const fieldAttr = (type, value) => {
    setData((oldData) => {
      let newData = reParse(oldData);
      newData.field[type] = value;
      return newData;
    });
  };

  const onChangeOption = (e, key) => {
    const { value } = e.target;
    setData((oldData) => {
      let newData = reParse(oldData);
      newData.field.attr.option[key] = value;
      return newData;
    });
  };

  const onDefaultOption = (e) => {
    const { value } = e.target;
    setData((oldData) => {
      let newData = reParse(oldData);
      newData.field.attr.optionDefault = value;
      return newData;
    });
  };

  const onDefinedOption = (e) => {
    const { value } = e.target;
    setData((oldData) => {
      let newData = reParse(oldData);
      newData.field.attr.optionDefault = value;
      return newData;
    });
  };

  const onMaxChar = (e) => {
    const { value } = e.target;
    setData((oldData) => {
      let newData = reParse(oldData);
      newData.field.attr.maxChar = value;
      return newData;
    });
  };

  const onChangeChecked = (e) => {
    let checked = e.target.checked ? true : false;
    setData((oldData) => {
      let newData = reParse(oldData);
      newData.field.attr.defaultChecked = checked;
      return newData;
    });
  };

  const onLookupSelect = (e) => {
    const { value } = e.target;
    setData((oldData) => {
      let newData = reParse(oldData);
      newData.field.attr.lookup = value;
      return newData;
    });
  };

  // submit handler
  const onComplete = () => {
    let error = "";
    if (data.field.label.trim() === "") {
      error = "Field name is required";
    }
    if (data.label.includes(data.field.label.trim().toUpperCase())) {
      error = "Field name already exist please use another name";
    } else if (["pickList", "multiSelect"].includes(data.field.type)) {
      if (
        data.field.attr.option.filter((item) => item.trim() === "").length > 0
      ) {
        error = "Options cannot be empty";
      }
    } else if (["lookup", "multiLookup"].includes(data.field.type)) {
      if (data.field.attr.lookup === "") {
        error = "Please select lookup";
      }
    }
    // show
    if (error) {
      common.snack("E", error);
    } else {
      props.onComplete(data);
    }
  };

  // option
  const addOption = () => {
    setData((oldData) => {
      let newData = reParse(oldData);
      newData.field.attr.option.push("");
      return newData;
    });
  };

  const removeOption = (key) => {
    setData((oldData) => {
      let newData = reParse(oldData);
      newData.field.attr.option.splice(key, 1);
      if (newData.field.attr.optionDefault == key) {
        newData.field.attr.optionDefault = "";
      }
      return newData;
    });
  };

  // bulk option
  const bulkAdd = () => {
    setBulkData("");
    setBulk(true);
  };

  const bulkCancel = () => {
    setBulkData("");
    setBulk(false);
  };

  const bulkSubmit = () => {
    if (bulkData.trim() === "") {
      common.snack("E", "Please add your choices");
    } else {
      let newChoices = bulkData
        .trim()
        .split("\n")
        .map((i) => i.trim());
      setData((oldData) => {
        let newData = reParse(oldData);
        let oldChoice = newData.field.attr.option;
        newData.field.attr.option = [...new Set([...oldChoice, ...newChoices])];
        return newData;
      });
      bulkCancel();
      common.snack("S", "Bulk list has been added");
    }
  };

  // support
  const reParse = (data) => {
    return JSON.parse(JSON.stringify(data));
  };

  // render
  return (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      className="dialog-popup"
      disableScrollLock
    >
      <DialogTitle id="form-dialog-title" className="cp p-1">
        <Grid
          container
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <ListItem className="notify-title">
              {data.field.label} Properties
            </ListItem>
          </Grid>
          <Grid item>
            <ListItem>
              <img
                src={common.loadImg("filterCloseIcon.svg")}
                alt="close"
                onClick={() => props.closeDialog()}
              />
            </ListItem>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <TextField
          label="Field Name"
          placeholder="Field Name"
          // helperText="The field is required"
          fullWidth
          margin="dense"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="mb-3 field-text"
          value={data.field.label}
          disabled={data.field.variable === "system"}
          onChange={(e) => onChangeLabel(e)}
        />

        {data.field.attr.maxChar !== null && (
          <FormControl className="fs-full-width form-settings-select mb-3">
            <InputLabel htmlFor="maxChar">
              Number of characters allowed
            </InputLabel>
            <MuiSelect
              labelId="maxChar"
              value={data.field.attr.maxChar}
              onChange={(e) => onMaxChar(e)}
            >
              {Array.from(Array(data.item.maxChar), (e, i) => {
                i++;
                return (
                  <MenuItem value={i} key={i}>
                    {i}
                  </MenuItem>
                );
              })}
            </MuiSelect>
          </FormControl>
        )}

        {/* Checkbox */}
        {data.field.type == "checkbox" && (
          <FormGroup>
            <FormControlLabel
              className="dynamic-checkbox ms-0 checkbox-space"
              value={true}
              control={<Checkbox color="primary" />}
              label="Enable By Default"
              labelPlacement="end"
              checked={data.field.attr.defaultChecked}
              onChange={(e) => onChangeChecked(e)}
            />
          </FormGroup>
        )}

        {["lookup", "multiLookup"].includes(data.field.type) && (
          <FormControl className="fs-full-width form-settings-select mb-3">
            <InputLabel htmlFor="lookup">Select Lookup</InputLabel>
            <MuiSelect
              labelId="lookup"
              value={data.field.attr.lookup}
              onChange={(e) => onLookupSelect(e)}
              disabled={data.field.variable === "system"}
            >
              {Object.keys(lookupList).map((item, key) => {
                let itemData = lookupList[item];
                if (
                  itemData.module.includes("all") ||
                  itemData.module.includes(data.code)
                ) {
                  return (
                    <MenuItem value={item} key={key}>
                      {itemData.label}
                    </MenuItem>
                  );
                }
              })}
            </MuiSelect>
          </FormControl>
        )}

        {/* Option */}
        {["pickList", "multiSelect"].includes(data.field.type) && (
          <Fragment>
            <Paper elevation={3} className="fs-pick-wrap mb-3">
              <Row>
                <Col sm={12}>
                  <h3>{bulk ? "Add Bulk Options" : "Add Options"}</h3>
                  {!bulk ? (
                    <Fragment>
                      <div className="fs-pick-list">
                        {data.field.attr.option.map((item, key) => (
                          <div
                            key={key}
                            draggable
                            onDragStart={() => onDragStartItem(key)}
                            onDragOver={
                              isDragMode == "item"
                                ? () => {
                                    onDragOverItem(key);
                                  }
                                : null
                            }
                            onDragEnd={() => handleDragEndItem()}
                            className="add-channel-options"
                          >
                            <Input
                              id="input-with-icon-adornment"
                              startAdornment={
                                data.field.attr.option.length > 1 && (
                                  <InputAdornment position="start">
                                    <MenuIcon
                                      className="fs-cp"
                                      onClick={() => removeOption(key)}
                                    />
                                  </InputAdornment>
                                )
                              }
                              endAdornment={
                                data.field.attr.option.length > 1 && (
                                  <InputAdornment position="end">
                                    <DeleteIcon
                                      className="fs-cp"
                                      onClick={() => removeOption(key)}
                                    />
                                  </InputAdornment>
                                )
                              }
                              value={item}
                              fullWidth
                              placeholder="Option"
                              onChange={(e) => onChangeOption(e, key)}
                              className={`${itemStyles(key)}`}
                            />
                          </div>
                        ))}
                      </div>
                      <div className="fs-pick-btn">
                        <MuiButton
                          color="primary"
                          variant="outlined"
                          size="small"
                          onClick={() => addOption()}
                          className="save-note"
                        >
                          Add New
                        </MuiButton>
                        <MuiButton
                          color="primary"
                          variant="outlined"
                          size="small"
                          onClick={() => bulkAdd()}
                          className="save-note"
                        >
                          Add Bulk Options
                        </MuiButton>
                      </div>
                    </Fragment>
                  ) : (
                    <div>
                      <textarea
                        rows={10}
                        placeholder="Please enter your values sequentially"
                        className="fs-textarea"
                        value={bulkData}
                        onChange={(e) => setBulkData(e.target.value)}
                      />
                      <div className="fs-pick-btn">
                        <MuiButton
                          color="primary"
                          variant="outlined"
                          size="small"
                          onClick={() => bulkSubmit()}
                          className="dialog-secondary-btn"
                        >
                          Add Choices
                        </MuiButton>
                        <MuiButton
                          color="secondary"
                          variant="outlined"
                          size="small"
                          onClick={() => bulkCancel()}
                          className="delete-btn"
                        >
                          Cancel
                        </MuiButton>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </Paper>
            {data.field.type == "pickList" && (
              <FormControl className="fs-full-width form-settings-select mb-3">
                <InputLabel htmlFor="optionDefault">
                  Select Default Option
                </InputLabel>
                <MuiSelect
                  labelId="optionDefault"
                  value={data.field.attr.optionDefault}
                  onChange={(e) => onDefaultOption(e)}
                >
                  <MenuItem value="">None</MenuItem>
                  {data.field.attr.option
                    .filter((i) => i != "")
                    .map((item, key) => (
                      <MenuItem value={item} key={key}>
                        {item}
                      </MenuItem>
                    ))}
                </MuiSelect>
              </FormControl>
            )}
          </Fragment>
        )}

        {/* Required */}
        <FormGroup className="fs-full-width">
          <FormControlLabel
            className="dynamic-checkbox ms-0 checkbox-space"
            value={true}
            control={<Checkbox color="primary" />}
            label="Required"
            labelPlacement="end"
            checked={data.field.required}
            onChange={(e) => onChangeRequired(e)}
          />
        </FormGroup>

        {/* Quick Create */}
        <FormGroup className="fs-full-width">
          <FormControlLabel
            className="dynamic-checkbox ms-0 checkbox-space"
            value={true}
            control={<Checkbox color="primary" />}
            label="Quick Create"
            labelPlacement="end"
            checked={data.field.quick}
            disabled={data.field.required}
            onChange={(e) => onChangeQuick(e)}
          />
        </FormGroup>
      </DialogContent>
      <DialogActions className="fs-dialog-margin">
        {props.isMode == "drag" ? (
          <MuiButton
            variant="outlined"
            color="secondary"
            size="small"
            onClick={() => props.onDontSave()}
            className="dialog-secondary-btn"
          >
            Don't save this field
          </MuiButton>
        ) : (
          <MuiButton
            variant="outlined"
            size="small"
            onClick={() => props.closeDialog()}
            className="delete-btn"
          >
            Cancel
          </MuiButton>
        )}
        <MuiButton
          color="primary"
          variant="outlined"
          size="small"
          onClick={() => onComplete()}
          className="save-note"
        >
          Done
        </MuiButton>
      </DialogActions>
    </Dialog>
  );
}

export default FormProperties;
