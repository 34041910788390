import React, { memo, useState } from "react";
import { Box, Grid, Paper } from "@mui/material";
import { common } from "helpers";
import FormControl from "@mui/material/FormControl";
import Select, { components } from "react-select";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Divider } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Chart from "react-apexcharts";
import QuickAdd from "elements/QuickAdd";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  TASKS_VIEW,
  CRM_CONTACTS,
  CRM_ENQUIRYS,
  CRM_LEADS,
} from "helpers/service";
import { api, envConfig } from "helpers";
import StatusBar from "elements/StatusBar";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#2A3543",
    color: "rgba(157, 170, 187, 0.5)",
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    padding: 35,
  },
}));
const HtmlTooltip1 = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#2A3543",
    color: "rgba(157, 170, 187, 0.5)",
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    padding: 10,
  },
}));
// Floating React select
const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};
const durationOptions = [
  { value: "0", label: "Today" },
  { value: "1", label: "Yesterday" },
  { value: "2", label: "Last One Week" },
  { value: "3", label: "Last One Month" },
  { value: "4", label: "Last One Year" },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const DrawerHeader1 = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  padding: theme.spacing(2, 3),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
const Dashboard1 = memo(() => {
  const [value, setValue] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const userData = common.authInfo();
  const todayDate = common.formatDate(new Date(), "YYYY-MM-DD");

  const [taskInfo, setTaskInfo] = useState([]);
  const [contactInfo, setContactInfo] = useState([]);
  const [status, setStatus] = useState("");
  const [totalCount, setTotalCount] = useState([]);
  const [contactCount, setContactCount] = useState("");
  const [enquiryInfo, setEnquiryInfo] = useState([]);
  const [enquiryCount, setEnquiryCount] = useState("");
  const [leadInfo, setLeadInfo] = useState("");
  const [selectedLeadInfo, setSelectedLeadInfo] = useState("");
  const [leadFilterInfo, setLeadFilterInfo] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const handleFilterSelect = (data) => {
  //   // e.preventDefault();
  //   setSelectedLeadInfo(data.value);
  //   console.log("filter", selectedLeadInfo);
  //   getLeadFilter();
  // };
  const [options, setOptions] = useState({
    chart: {
      id: "analytics-chart",
      toolbar: {
        show: false,
      },
      events: {
        mounted: (chart) => {
          chart.windowResizeHandler();
        },
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "35%",
        borderRadius: 10,
      },
    },
    title: {
      text: "Sales",
    },
    stroke: {
      lineCap: "round",
    },
    xaxis: {
      categories: ["150", "200", "400", "800", "1000", "1200", "1400", "1500"],
    },
  });
  const [series, setSeries] = useState([
    {
      name: "Analytics",
      data: [1950, 1000, 2400, 1230, 700, 1950],
    },
  ]);

  // Select box style
  const selectStyles = {
    container: (provided, state) => ({
      ...provided,
      marginTop: 0,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      overflow: "visible",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      position: "absolute",
      top: state.hasValue || state.selectProps.inputValue ? -6 : "17%",
      transition: "top 0.1s, font-size 0.1s",
      fontSize: state.hasValue || state.selectProps.inputValue ? 11 : 13,
      color: "#9daabb",
    }),
  };
  React.useEffect(() => {
    var isMobileVersion = document.getElementsByClassName("rotate-arrow");
    const fixedHeader = document.getElementById("fixed-header");
    if (isMobileVersion.length > 0) {
      fixedHeader.classList.add("menu-shrink");
      fixedHeader.classList.remove("menu-expand");
    } else {
      fixedHeader.classList.remove("menu-shrink");
      fixedHeader.classList.add("menu-expand");
    }
  });
  React.useEffect(() => {
    getTask();
    getContacts();
    getEnquiry();
    getLeadInfo();
  }, []);

  const getLeadInfo = () => {
    // setStatus("process");
    setIsLoading(true);
    let url = `${envConfig.BASE_API + CRM_LEADS}?q=OrgId=${
      userData.DefaultOrgId
    }%20AND%20TenantId=${
      userData.TenantId
    }&totalResults=true&limit=5&offset=0&orderBy=CreatedOn:desc`;

    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
      moreHead: { rfv: 2 },
    };

    api.call(
      data,
      (response) => {
        if (response) {
          setIsLoading(false);
          setStatus("success");
          let result = response.data.items;

          setLeadInfo(result);
        } else {
          setStatus("empty");
        }
      },
      (error) => {
        // setStatus("error");
      },
      (final) => {}
    );
  };

  // const getLeadFilter = () => {
  //   // setStatus("process");
  //   setIsLoading(true);
  //   let url = `${envConfig.BASE_API + CRM_LEADS}?q=OrgId=${
  //     userData.DefaultOrgId
  //   }%20AND%20TenantId=${
  //     userData.TenantId
  //   }&totalResults=true&limit=5&offset=0&orderBy=CreatedOn:desc`;

  //   let data = {
  //     url: url,
  //     type: "dynamic",
  //     method: "GET",
  //     auth: "token",
  //     moreHead: { rfv: 2 },
  //   };

  //   api.call(
  //     data,
  //     (response) => {
  //       if (response) {
  //         setIsLoading(false);
  //         setStatus("success");
  //         let result = response.data.items;

  //         setLeadFilterInfo(result);
  //       } else {
  //         setStatus("empty");
  //       }
  //     },
  //     (error) => {
  //       // setStatus("error");
  //     },
  //     (final) => {}
  //   );
  // };

  const getEnquiry = () => {
    // setStatus("process");
    setIsLoading(true);
    let url = `${envConfig.BASE_API + CRM_ENQUIRYS}?q=OrgId=${
      userData.DefaultOrgId
    }%20AND%20TenantId=${
      userData.TenantId
    }&totalResults=true&limit=5&offset=0&orderBy=CreatedOn:desc`;

    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
      moreHead: { rfv: 2 },
    };

    api.call(
      data,
      (response) => {
        if (response) {
          setIsLoading(false);
          setStatus("success");
          let result = response.data.items;

          setEnquiryInfo(result);
          setEnquiryCount(response.data.count);
        } else {
          setStatus("empty");
        }
      },
      (error) => {
        // setStatus("error");
      },
      (final) => {}
    );
  };
  const getContacts = () => {
    // setStatus("process");
    setIsLoading(true);
    let url = `${envConfig.BASE_API + CRM_CONTACTS}?q=OrgId=${
      userData.DefaultOrgId
    }%20AND%20TenantId=${
      userData.TenantId
    }&totalResults=true&limit=5&offset=0&orderBy=CreatedOn:desc`;

    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
      moreHead: { rfv: 2 },
    };

    api.call(
      data,
      (response) => {
        if (response) {
          setIsLoading(false);
          setStatus("success");
          let result = response.data.items;

          setContactInfo(result);
          setContactCount(response.data.count);
        } else {
          setStatus("empty");
        }
      },
      (error) => {
        // setStatus("error");
      },
      (final) => {}
    );
  };

  const getTask = () => {
    // setStatus("process");
    setIsLoading(true);
    let timeZone = (moment().format()).split("+")[1]
    let startDate = moment(`${todayDate}T00:00:00+${timeZone}`).utc().format("YYYY-MM-DDTHH:mm:ss")
    let endDate = moment(`${todayDate}T24:00:00+${timeZone}`).utc().format("YYYY-MM-DDTHH:mm:ss")
    let todayStDate = startDate;
    let todayEdDate = endDate;

    let url = `${envConfig.BASE_API + TASKS_VIEW}?q=EmployeeId=${
      userData.EmpId
    } and CreatedBy='${
      userData.UserName
    }' and EndDate BETWEEN '${todayStDate}' and '${todayEdDate}' &totalResults=true&limit=5&offset=0&orderBy=EndDate:desc`;

    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
      moreHead: { rfv: 2 },
    };

    api.call(
      data,
      (response) => {
        if (response) {
          setIsLoading(false);
          setStatus("success");
          let result = response.data.items;

          setTaskInfo(result);
          setTotalCount(response.data.count);

        } else {
          setStatus("empty");
        }
      },
      (error) => {
        // setStatus("error");
      },
      (final) => {}
    );
  };

  return (
    <>
      <Box>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            className="fixed-header"
            id="fixed-header"
          >
            <Paper variant="contained" square>
              <Box>
                <Grid container className="dashboard-head">
                  <Grid item xs={12} sm={12} md={5}>
                    <Box>
                      <span>
                        Dashboard
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <Typography
                                component={"span"}
                                color="inherit"
                                className="pop-title"
                              >
                                Help and Tips
                              </Typography>
                              <p>
                                <h6 className="pop-heading">
                                  {"This is your dashboard"}
                                </h6>
                              </p>{" "}
                              {
                                "Fabio vel iudice vincam, sunt in culpa qui officia. Curabitur est gravida et libero vitae dictum.Quam diu etiam furor iste tuus nos eludet?"
                              }
                            </React.Fragment>
                          }
                        >
                          <sub className="ms-2 cpoint">
                            <img src={common.loadImg("info-square.svg")} />
                          </sub>
                        </HtmlTooltip>
                      </span>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={7}>
                    <Box className="header-right-side">
                      <QuickAdd />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <DrawerHeader1 className="report-header" />
      <Box mt={1} px={5}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={8}>
            <Box mt={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Paper variant="contained" square className="box-wrap">
                    <label>Leads</label>
                    <p>Overall</p>
                    <div className="green-text">
                      20{" "}
                      <sup>
                        <img src={common.loadImg("green-uparrow.svg")} />
                      </sup>
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper variant="contained" square className="box-wrap">
                    <label>Deals</label>
                    <p>New this month</p>
                    <div className="orange-text">
                      08{" "}
                      <sup>
                        <img src={common.loadImg("down-arrow-orange.svg")} />
                      </sup>
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper variant="contained" square className="box-wrap">
                    <label>Target</label>
                    <p>First Quater</p>
                    <div className="blue-text">3000</div>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
            {false && <Box mt={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Paper
                    variant="contained"
                    square
                    className="box-container-wrap"
                  >
                    <Box>
                      <Grid container>
                        <Grid item xs={12} md={12} className="analytics-wrp">
                          <div className="col-md-6 analytics-flex">
                            <span className="me-4 ms-4 h4-class col-md-3">
                              Analytics
                            </span>
                            <div className="py-2 col-md-3">
                              <FormControl
                                fullWidth
                                sx={{ minWidth: 160, minHeight: 40 }}
                                className="select-float-label"
                              >
                                <Select
                                  className="text-box custom-react-select py-1"
                                  labelId="select"
                                  id="select"
                                  label="Month"
                                  menuPortalTarget={document.body}
                                  isClearable={true}
                                  options={durationOptions}
                                  components={{
                                    ValueContainer: CustomValueContainer,
                                  }}
                                  placeholder="Month"
                                  styles={selectStyles}
                                ></Select>
                              </FormControl>
                            </div>
                          </div>
                          <div className="list-wrap col-md-6">
                            <Tabs
                              value={value}
                              onChange={handleChange}
                              aria-label="basic tabs example"
                              className="tab-analytics"
                            >
                              <Tab label="Leads" {...a11yProps(0)} />
                              <Tab label="Campaigns" {...a11yProps(1)} />
                              <Tab label="Tasks" {...a11yProps(2)} />
                            </Tabs>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                    <TabPanel value={value} index={0}>
                      <Box>
                        <Grid container display="block">
                          <Grid item xs={12} md={12} lg={12}>
                            <Chart
                              options={options}
                              series={series}
                              type="area"
                              width="100%"
                              // height={300}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <Box>
                        <Grid container>
                          <Grid item xs={12} md={12} lg={12}>
                            <Chart
                              options={options}
                              series={series}
                              type="area"
                              width="100%"
                              // height={300}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      <Box>
                        <Grid container>
                          <Grid item xs={12} md={12} lg={12}>
                            <Chart
                              options={options}
                              series={series}
                              type="area"
                              width="100%"
                              // height={300}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </TabPanel>
                  </Paper>
                </Grid>
              </Grid>
            </Box>}
            <Box mt={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Paper
                    variant="contained"
                    square
                    className="box-container-wrap lead-container-wrap"
                  >
                    <Box>
                      <Grid container>
                        <Grid item xs={12} md={12} className="analytics-wrp">
                          <div className="pt-2 col-md-11 analytics-flex">
                            <span className="me-4 ms-4  h4-class leads-cls">
                              Your Leads
                              {/* Your Leads in Progress */}
                            </span>
                            <div className="py-2 col-md-2">
                              {/* <FormControl
                                fullWidth
                                sx={{ minWidth: 160, minHeight: 40 }}
                              >
                                <Select
                                  className="text-box custom-react-select p-1"
                                  labelId="select"
                                  id="select"
                                  label="View all"
                                  // value={(data) => data.value}
                                  onChange={(data) => handleFilterSelect(data)}
                                  menuPortalTarget={document.body}
                                  isClearable={false}
                                  options={durationOptions}
                                  components={{
                                    ValueContainer: CustomValueContainer,
                                  }}
                                  placeholder="View all"
                                  styles={selectStyles}
                                ></Select>
                              </FormControl> */}
                            </div>
                          </div>
                          <div className="list-wrap col-md-1">
                            <Link to="/crm/lead/list">
                              <img
                                src={common.loadImg("arrow-left-circle.svg")}
                                className="ms-1"
                              />
                            </Link>
                          </div>
                        </Grid>
                        {isLoading ? (
                          <StatusBar status="process" />
                        ) : (
                          <>
                            {leadInfo && leadInfo.length > 0 ? (
                              <Grid item xs={12} md={12} className="">
                                <TableContainer>
                                  <Table
                                    sx={{ minWidth: 650 }}
                                    aria-label="simple table"
                                    className="lead-table"
                                  >
                                    <TableHead className="task-wrap">
                                      <TableRow>
                                        <TableCell
                                          align="left"
                                          className="ps-4"
                                        >
                                          |v|
                                        </TableCell>
                                        <TableCell align="left">
                                          Lead Name
                                        </TableCell>
                                        <TableCell align="left">
                                          Last Updated
                                        </TableCell>
                                        <TableCell align="left">
                                          Status
                                        </TableCell>
                                        <TableCell align="left">
                                          Action
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>

                                    <TableBody>
                                      {Object.keys(leadInfo).map((item, i) => {
                                        const nameArr =
                                          leadInfo[item].LeadName?.split(" ") ||
                                          "";
                                        const initial =
                                          nameArr?.length > 1
                                            ? `${
                                                nameArr[0]
                                                  ? nameArr[0].slice(0, 1)
                                                  : ""
                                              }${
                                                nameArr[1]
                                                  ? nameArr[1].slice(0, 1)
                                                  : ""
                                              }`
                                            : leadInfo[item].LeadName?.slice(
                                                0,
                                                2
                                              );
                                        return (
                                          <TableRow
                                            className="task-wrap"
                                            key={"ts" + i}
                                          >
                                            <TableCell
                                              component="th"
                                              scope="row"
                                            >
                                              <span className="bg-circle">
                                                {(initial || "").toUpperCase()}
                                              </span>
                                            </TableCell>
                                            <TableCell align="left">
                                              <label>
                                                {leadInfo[item].LeadName}
                                              </label>
                                              <span>
                                                {"Created" +
                                                  " " +
                                                  common.formatDate(
                                                    leadInfo[item].CreatedOn,
                                                    "MMM-DD-YY"
                                                  )}
                                              </span>
                                            </TableCell>
                                            <TableCell align="left">
                                              <label>
                                                {" "}
                                                {common.formatDate(
                                                  leadInfo[item].ModifiedOn,
                                                  "DD-MMM-YY"
                                                )}
                                              </label>
                                            </TableCell>
                                            <TableCell align="left">
                                              {leadInfo[item].LeadStatus && <><img
                                                src={common.loadImg(
                                                  "rectangle-orange.svg"
                                                )}
                                                className="me-2"
                                              />
                                              <img
                                                src={common.loadImg(
                                                  "rectangle-orange.svg"
                                                )}
                                                className="me-2"
                                              />
                                              <span className="lead-status">
                                                {leadInfo[item].LeadStatus}
                                              </span>
                                              <img
                                                src={common.loadImg(
                                                  "rectangle-green.svg"
                                                )}
                                                className="mx-2"
                                              />
                                              <img
                                                src={common.loadImg(
                                                  "rectangle-green.svg"
                                                )}
                                                className="me-2"
                                              /></>}
                                            </TableCell>
                                            <TableCell align="center">
                                              <Link
                                                to={
                                                  "/crm/lead/details/" +
                                                  leadInfo[item].LeadId
                                                }
                                              >
                                                {" "}
                                                <div className="active index-active">
                                                  <img
                                                    src={common.loadImg(
                                                      "arrow-left-circle.svg"
                                                    )}
                                                  />
                                                </div>
                                              </Link>
                                            </TableCell>
                                          </TableRow>
                                        );
                                      })}

                                      {/* <TableRow className="task-wrap">
                                  <TableCell component="th" scope="row">
                                    <span className="bg-circle">JN</span>
                                  </TableCell>
                                  <TableCell align="left">
                                    <label>Janani silva</label>
                                    <span>Created 2 weeks ago</span>
                                  </TableCell>
                                  <TableCell align="left">
                                    <label>20 Aug</label>
                                  </TableCell>
                                  <TableCell align="left">
                                    <img
                                      src={common.loadImg(
                                        "rectangle-orange.svg"
                                      )}
                                      className="me-2"
                                    />
                                    <img
                                      src={common.loadImg(
                                        "rectangle-orange.svg"
                                      )}
                                      className="me-2"
                                    />
                                    <span className="lead-status">
                                      Registered
                                    </span>
                                    <img
                                      src={common.loadImg(
                                        "rectangle-green.svg"
                                      )}
                                      className="mx-2"
                                    />
                                    <img
                                      src={common.loadImg(
                                        "rectangle-green.svg"
                                      )}
                                      className="me-2"
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    <div className="active index-active">
                                      <img
                                        src={common.loadImg(
                                          "arrow-left-circle.svg"
                                        )}
                                      />
                                    </div>
                                  </TableCell>
                                </TableRow> */}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Grid>
                            ) : (
                              <div className="mx-auto my-3 nodata-found-position">
                                <p className="nodata-found-para">
                                  No Data Found!
                                </p>
                              </div>
                            )}
                          </>
                        )}
                      </Grid>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Box>

            <Box mt={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Paper
                    variant="contained"
                    square
                    className="box-container-wrap lead-container-wrap mb-4"
                  >
                    <Box>
                      <Grid container>
                        <Grid item xs={12} md={12} className="analytics-wrap">
                          <div>
                            <span className="me-4 ms-4  h4-class">
                              New Enquiries
                            </span>
                            <div className="py-3">
                              {enquiryCount != 0 ? (
                                <div className="tasks-count">
                                  {enquiryCount.length > 0
                                    ? enquiryCount
                                    : "0" + enquiryCount}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="list-wrap col-md-1 ps-2">
                            <Link to="/crm/enquiry/list">
                              <img
                                src={common.loadImg("arrow-left-circle.svg")}
                              />
                            </Link>
                          </div>
                        </Grid>
                        {isLoading ? (
                          <StatusBar status="process" />
                        ) : (
                          <>
                            {enquiryInfo && enquiryInfo.length > 0 ? (
                              <Grid item xs={12} md={12}>
                                <TableContainer>
                                  <Table
                                    sx={{ minWidth: 650 }}
                                    aria-label="simple table"
                                    className="lead-table"
                                  >
                                    <TableHead className="task-wrap">
                                      <TableRow>
                                        <TableCell align="left">Name</TableCell>
                                        <TableCell align="left">Date</TableCell>
                                        <TableCell align="left">
                                          Status
                                        </TableCell>
                                        <TableCell align="left">Type</TableCell>
                                        <TableCell align="left">
                                          Source
                                        </TableCell>
                                        <TableCell align="left">
                                          Action
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>

                                    <TableBody>
                                      {Object.keys(enquiryInfo).map(
                                        (item, i) => {
                                          return (
                                            <TableRow
                                              className="task-wrap"
                                              key={"task" + i}
                                            >
                                              <TableCell
                                                component="th"
                                                scope="row"
                                              >
                                                <label>
                                                  {enquiryInfo[item].ListName}
                                                </label>
                                              </TableCell>
                                              <TableCell align="left">
                                                <label>
                                                  {common.formatDate(
                                                    enquiryInfo[item]
                                                      .CreatedDate,
                                                    "MMM-DD-YY"
                                                  )}
                                                </label>
                                              </TableCell>
                                              <TableCell align="left">
                                                <span className="enq-status">
                                                  {enquiryInfo[item].ListStatus}
                                                </span>
                                              </TableCell>
                                              <TableCell align="left">
                                                <label>
                                                  {enquiryInfo[item].ListType}
                                                </label>
                                              </TableCell>
                                              <TableCell align="left">
                                                <label>
                                                  {enquiryInfo[item].Source}
                                                </label>
                                              </TableCell>
                                              <TableCell align="center">
                                                <div className="active index-active">
                                                  <Link
                                                    to={
                                                      "/crm/enquiry/details/" +
                                                      enquiryInfo[item].ListId
                                                    }
                                                  >
                                                    <img
                                                      src={common.loadImg(
                                                        "arrow-left-circle.svg"
                                                      )}
                                                    />
                                                  </Link>
                                                </div>
                                              </TableCell>
                                            </TableRow>
                                          );
                                        }
                                      )}

                                      {/* <TableRow className="task-wrap">
                                  <TableCell component="th" scope="row">
                                    <label>Amanda Defonseka</label>
                                  </TableCell>
                                  <TableCell align="left">
                                    <label>Aug 20</label>
                                  </TableCell>
                                  <TableCell align="left">
                                    <span className="enq-status">Call</span>
                                  </TableCell>
                                  <TableCell align="left">
                                    <label>Recruitment</label>
                                  </TableCell>
                                  <TableCell align="left">
                                    <label>Instagram</label>
                                  </TableCell>
                                  <TableCell align="center">
                                    <div className="active index-active">
                                      <Link to="#">
                                        <img
                                          src={common.loadImg(
                                            "arrow-left-circle.svg"
                                          )}
                                        />
                                      </Link>
                                    </div>
                                  </TableCell>
                                </TableRow> */}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Grid>
                            ) : (
                              <div className="mx-auto my-3 nodata-found-position">
                                <p className="nodata-found-para">
                                  No Data Found!
                                </p>
                              </div>
                            )}
                          </>
                        )}
                      </Grid>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Paper variant="contained" square className="main-wrap">
              <Box mt={3}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    className="right-content box-container-wrap analytics-wrap"
                  >
                    <div>
                      <span className="me-4 ms-4  h4-class">Today's Tasks</span>
                      <div className="py-3">
                        {totalCount != 0 ? (
                          <div className="tasks-count">
                            {totalCount.length > 0
                              ? totalCount
                              : "0" + totalCount}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="">
                      <Link to="/task/overview">
                        <img
                          src={common.loadImg("arrow-left-circle.svg")}
                          className="me-4"
                        />
                      </Link>
                    </div>
                  </Grid>
                  {isLoading ? (
                    <StatusBar status="process" />
                  ) : (
                    <>
                      {taskInfo && taskInfo.length > 0 ? (
                        <Grid item xs={12} md={12}>
                          <>
                            {Object.keys(taskInfo).map((item, i) => (
                              <div className="task-wrap" key={i}>
                                <div>
                                  <Link
                                    to={"/task/edit/" + taskInfo[item].TaskId}
                                  >
                                    {" "}
                                    <label className="pointer">
                                      {taskInfo[item].TaskName}

                                      {/* <u>Jane Doe</u> */}
                                    </label>{" "}
                                  </Link>{" "}
                                  <p>
                                    <span>Deadline: </span>
                                    <span className="deadline-span">
                                      {todayDate ==
                                      common.formatDate(
                                        taskInfo[item].EndDate,
                                        "YYYY-MM-DD"
                                      )
                                        ? "Today"
                                        : common.formatDate(
                                            taskInfo[item].EndDate,
                                            "MMM DD YYYY"
                                          )}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            ))}
                          </>

                          {/* <div className="task-wrap">
                      <div>
                        <label>
                          Call{" "}
                          <Link to="#">
                            <u>Jane Doe</u>
                          </Link>{" "}
                          to gather Documents
                        </label>
                        <p>
                          <span>Deadline: </span>
                          <span className="deadline-span">Today/anytime</span>
                        </p>
                      </div>
                    </div> */}
                        </Grid>
                      ) : (
                        <div className="mx-auto my-3 nodata-found-position ">
                          <p className="nodata-found-para">
                            No Data Found!
                          </p>
                        </div>
                      )}
                    </>
                  )}

                  {taskInfo && taskInfo.length > 0 ? (
                    <Grid
                      item
                      xs={12}
                      md={12}
                      className="d-flex justify-content-end add-camp-btn my-3 me-3"
                    >
                      <Link to="/task/add" className="text-none">
                        <Button variant="outlined">
                          {" "}
                          <div className="">
                            Add a task{" "}
                            <img
                              src={common.loadImg("light-plus.svg")}
                              alt="document"
                              className="ml-5"
                            />
                          </div>
                        </Button>{" "}
                      </Link>
                    </Grid>
                  ) : null}
                </Grid>
              </Box>
            </Paper>
            {/* <Box mt={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Paper
                    variant="contained"
                    square
                    className="box-container-wrap"
                  >
                    <Box>
                      <Grid container>
                        <Grid item xs={12} md={12} className="analytics-wrap">
                          <div>
                            <span className="me-4 ms-4  h4-class py-2">
                              Recent Reports
                            </span>
                          </div>
                          <div className="">
                            <Link to="#">
                              <img
                                src={common.loadImg("arrow-left-circle.svg")}
                                className="me-4"
                              />
                            </Link>
                          </div>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <div className="recent-report">
                            <div className="recent-content-wrap">
                              <label>Campaign Summary</label>
                              <span>Aug 2021</span>
                            </div>
                            <div className="download-arrow">
                              <img
                                src={common.loadImg("paper-download-icon.svg")}
                              />
                              <img
                                src={common.loadImg("download-hover.png")}
                                className="download-hover"
                              />
                            </div>
                          </div>
                          <div className="recent-report">
                            <div className="recent-content-wrap">
                              <label>Campaign Summary</label>
                              <span>Aug 2021</span>
                            </div>
                            <div className="download-arrow">
                              <img
                                src={common.loadImg("paper-download-icon.svg")}
                              />
                              <img
                                src={common.loadImg("download-hover.png")}
                                className="download-hover"
                              />
                            </div>
                          </div>
                          <div className="recent-report">
                            <div className="recent-content-wrap">
                              <label>Campaign Summary</label>
                              <span>Aug 2021</span>
                            </div>
                            <div className="download-arrow">
                              <img
                                src={common.loadImg("paper-download-icon.svg")}
                              />
                              <img
                                src={common.loadImg("download-hover.png")}
                                className="download-hover"
                              />
                            </div>
                          </div>
                          <div className="recent-report">
                            <div className="recent-content-wrap">
                              <label>Campaign Summary</label>
                              <span>Aug 2021</span>
                            </div>
                            <div className="download-arrow">
                              <img
                                src={common.loadImg("paper-download-icon.svg")}
                              />
                              <img
                                src={common.loadImg("download-hover.png")}
                                className="download-hover"
                              />
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Box> */}
            <Box mt={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Paper
                    variant="contained"
                    square
                    className="box-container-wrap mb-4 "
                  >
                    <Box>
                      <Grid container>
                        <Grid item xs={12} md={12} className="analytics-wrap">
                          <div>
                            <span className="me-4 ms-4  h4-class">
                              Recent Contacts
                            </span>
                            <div className="py-3">
                              {contactCount != 0 ? (
                                <div className="tasks-count">
                                  {contactCount.length > 0
                                    ? contactCount
                                    : "0" + contactCount}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div>
                            <Link to="/crm/contacts/list">
                              <img
                                src={common.loadImg("arrow-left-circle.svg")}
                                className="me-4"
                              />
                            </Link>
                          </div>
                        </Grid>

                        {isLoading ? (
                          <StatusBar status="process" />
                        ) : (
                          <>
                            {contactInfo && contactInfo.length > 0 ? (
                              <Grid
                                item
                                xs={12}
                                md={12}
                                className="db-Recent-contacts"
                              >
                                {Object.keys(contactInfo).map((item, i) => {
                                  const nameArr =
                                    contactInfo[item].FirstName +
                                      contactInfo[item].LastName?.split(" ") ||
                                    "";
                                  const initial =
                                    nameArr?.length > 1
                                      ? `${
                                          nameArr[0]
                                            ? nameArr[0].slice(0, 1)
                                            : ""
                                        }${
                                          nameArr[1]
                                            ? nameArr[1].slice(0, 1)
                                            : ""
                                        }`
                                      : contactInfo[item].FirstName +
                                        contactInfo[item].LastName?.slice(0, 2);

                                  return (
                                    <Grid item key={i}>
                                      <Box>
                                        <Grid container alignItems="center">
                                          
                                            <Grid item xs={2} className="pl-2">
                                              <Box px={1} py={2}>
                                              <Link
                                            to={
                                              "/crm/contacts/details/" +
                                              contactInfo[item].ContactId
                                            }
                                            className="text-none"
                                          >
                                                <Box className="img-box">
                                                
                                                  {" "}
                                                  {(
                                                    initial || ""
                                                  ).toUpperCase()}
                                                 
                                                </Box>
                                                </Link>
                                              </Box>
                                            </Grid>
                                          
                                          <Grid item xs={10}>
                                            <Box py={0.6} px={2}>
                                              <Grid
                                                container
                                                justifyContent="space-between"
                                                alignItems="center"
                                              >
                                                {" "}
                                                <Grid item className="pointer">
                                                  <Link
                                                    to={
                                                      "/crm/contacts/details/" +
                                                      contactInfo[item]
                                                        .ContactId
                                                    }
                                                    className="text-none"
                                                  >
                                                    {" "}
                                                    <div className="font-weight-bold">
                                                      <label className="setting-card-title fz-16 font-weight-bold pointer">
                                                        {contactInfo[item]
                                                          .FirstName +
                                                          " " +
                                                          contactInfo[item]
                                                            .LastName}
                                                      </label>
                                                      <p className="setting-card-content fz-14">
                                                        {
                                                          contactInfo[item]
                                                            .JobTitle
                                                        }
                                                      </p>
                                                    </div>{" "}
                                                  </Link>
                                                </Grid>
                                                {/* <div className="my-auto pe-1 ">
                                                  <HtmlTooltip1
                                                    title={
                                                      <Typography
                                                        component={"span"}
                                                        color="inherit"
                                                        className="pop-title"
                                                      >
                                                        Tooltip
                                                      </Typography>
                                                    }
                                                  >
                                                    <img
                                                      src={common.loadImg(
                                                        "info-square.svg"
                                                      )}
                                                    />
                                                  </HtmlTooltip1>
                                                </div> */}
                                              </Grid>
                                            </Box>
                                          </Grid>
                                        </Grid>
                                        <Box>
                                          <Divider />
                                        </Box>
                                      </Box>
                                    </Grid>
                                  );
                                })}

                                {/* <Grid item>
                            <Box>
                              <Grid container alignItems="center">
                                <Grid item xs={2} className="pl-2">
                                  <Box px={1} py={2}>
                                    <Box className="img-box">JD</Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={10}>
                                  <Box py={0.6} px={2}>
                                    <Grid
                                      container
                                      justifyContent="space-between"
                                      alignItems="center"
                                    >
                                      <Grid item>
                                        <div className="font-weight-bold">
                                          <label className="setting-card-title fz-16 font-weight-bold">
                                            John Doe
                                          </label>
                                          <p className="setting-card-content fz-14">
                                            Chief Executive Officer
                                          </p>
                                        </div>
                                      </Grid>
                                      <div className="my-auto pe-1 ">
                                        <HtmlTooltip1
                                          title={
                                            <Typography
                                              component={"span"}
                                              color="inherit"
                                              className="pop-title"
                                            >
                                              Tooltip
                                            </Typography>
                                          }
                                        >
                                          <img
                                            src={common.loadImg(
                                              "info-square.svg"
                                            )}
                                          />
                                        </HtmlTooltip1>
                                      </div>
                                    </Grid>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Box>
                                <Divider />
                              </Box>
                            </Box>
                          </Grid> */}
                              </Grid>
                            ) : (
                              <div className="mx-auto my-3 nodata-found-position">
                                <p className="nodata-found-para">
                                  No Data Found!
                                </p>
                              </div>
                            )}
                          </>
                        )}
                      </Grid>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
});

export default Dashboard1;
