import React, { useEffect, useState } from "react";
import { Box, Divider, Typography } from "@mui/material";

import StatusBar from "elements/StatusBar";
import { api, common, envConfig } from "helpers";
import { CRM_TERRITORY_TEAM } from "helpers/service";
let ModNameChange = envConfig.ModNameChange;

const DATE_FORMAT = "DD-MM-YYYY";
const PAGE = 0;
const LIST_LIMIT = 1000;

const EmpDetail = (props) => {
  const { isLoading, selectedItem } = props;
  let userData = common.authInfo();
  const [departmentList, setDepartmentList] = useState([]);

  useEffect(() => {
    if (selectedItem?.DepartmentId) {
      loadDepartmentName();
    }
  }, [selectedItem]);

  const loadDepartmentName = () => {
    let url = `${envConfig.BASE_API}${CRM_TERRITORY_TEAM}?q=ResourceId=${selectedItem.EmployeeId}&TenantId=${userData.TenantId}`;
    url = `${url}&totalResults=true&orderBy=CreatedOn:desc&offset=${PAGE}&limit=${LIST_LIMIT}`;

    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
      moreHead: { rfv: 2 },
      cType: 4,
    };

    api.call(
      data,
      (response) => {
        if (response) {
          let result = response.data.items;
          const selectedList = [];
          result?.forEach((item) => {
            selectedList.push(item.TerritoryName);
          });
          setDepartmentList(selectedList);
        }
      },
      (error) => {},
      (final) => {}
    );
  };
  return (
    <div
      className="row   employee-details-page"
      style={{ backgroundColor: "white" }}
    >
      {isLoading ? (
        <StatusBar status="process" />
      ) : selectedItem ? (
        <>
          <div className="col-md-6  bottom-border-divider ps-4 mt-1">
            <label className="camp-sub-name">
              {ModNameChange ? "User" : "Employee"} Number
            </label>
            <p className="camp-sub-desc ">
              {selectedItem?.EmployeeCode || "_"}
            </p>
          </div>
          <div className="col-md-6    bottom-border-divider bottom-left-side-divider  ps-4 mt-1">
            <label className="camp-sub-name ">Status</label>
            <p>
              {" "}
              <span
                style={
                  selectedItem?.WorkingStatus !== "RESIGNED"
                    ? { backgroundColor: "#B6E9C5", color: "#131b23" }
                    : {}
                }
                className="status-report sts-active  camp-sub-desc"
              >
                {`Working - ${
                  selectedItem?.WorkingStatus !== "RESIGNED"
                    ? "Active"
                    : "Resigned"
                }`}{" "}
              </span>
            </p>
          </div>
          <div className="col-md-6   bottom-border-divider  ps-4">
            <label className="camp-sub-name">First Name</label>
            <p className="camp-sub-desc ">
              {common.textCapitalize(selectedItem.FirstName) || "-"}
            </p>
          </div>
          <div className="col-md-6   bottom-border-divider bottom-left-side-divider  ps-4">
            <label className="camp-sub-name">Last Name</label>
            <p className="camp-sub-desc ">
              {common.textCapitalize(selectedItem.LastName) || "-"}
            </p>
          </div>
          <div className="col-md-6   bottom-border-divider ps-4">
            <label className="camp-sub-name">Gender</label>
            <p className="camp-sub-desc ">{selectedItem.Gender || "-"}</p>
          </div>
          <div className="col-md-6   bottom-border-divider bottom-left-side-divider ps-4">
            <label className="camp-sub-name">Email Address</label>
            <p className="camp-sub-desc ">
              <a href="#0" className="anchor-link">
                {" "}
                {selectedItem?.Email || "-"}
              </a>
            </p>
          </div>
          <div className="col-md-6  ps-4">
            <label className="camp-sub-name  ">Mobile Number</label>
            <p className=" camp-sub-desc ">
              {selectedItem?.MobileNo ? (
                <a href="#0" className="anchor-link">
                  {" "}
                  {selectedItem?.MobileNo}
                </a>
              ) : (
                "-"
              )}
            </p>
          </div>
          {/* <div className="col-md-6  pt-3 pb-3 bottom-border-divider bottom-left-side-divider ps-4">
            <label className="camp-sub-name">Joined on </label>
            <p className="camp-sub-desc mt-2">
              {common.convertDate(selectedItem?.DateOfJoin, DATE_FORMAT) || "-"}
            </p>
          </div> */}
          <Box>
            <Divider />
          </Box>
          <Typography className=" location-detail-title ps-4">DATES</Typography>
          <Box>
            <Divider />
          </Box>

          <div className="col-md-6  bottom-border-divider bottom-left-side-divider ps-4">
            <label className="camp-sub-name">Date Of Birth</label>
            <p className="camp-sub-desc ">
              {common.convertDate(selectedItem?.DateOfBirth, DATE_FORMAT) ||
                "-"}
            </p>
          </div>
          <div className="col-md-6  bottom-border-divider bottom-left-side-divider ps-4">
            <label className="camp-sub-name">Hire Date</label>
            <p className="camp-sub-desc">
              {common.convertDate(selectedItem?.DateOfJoin, DATE_FORMAT) || "-"}
            </p>
          </div>
          <div className="col-md-6   bottom-border-divider bottom-left-side-divider ps-4">
            <label className="camp-sub-name">Date Of Confirmation</label>
            <p className="camp-sub-desc ">
              {common.convertDate(selectedItem?.DateOfConfirm, DATE_FORMAT) ||
                "-"}
            </p>
          </div>
          <div className="col-md-6   ps-4">
            <label className="camp-sub-name">Date Of Notice</label>
            <p className="camp-sub-desc ">
              {common.convertDate(
                selectedItem?.JoinDateIfSelected,
                DATE_FORMAT
              ) || "-"}
            </p>
          </div>
          <Box>
            <Divider />
          </Box>
          <Typography className=" location-detail-title ps-4">
            GENERAL DETAILS
          </Typography>
          <Box>
            <Divider />
          </Box>

          <div className="col-md-6  bottom-border-divider bottom-left-side-divider ps-4">
            <label className="camp-sub-name">Marital Status</label>
            <p className="camp-sub-desc ">
              {selectedItem?.MaritalStatus || "-"}
            </p>
          </div>
          <div className="col-md-6  bottom-border-divider bottom-left-side-divider ps-4">
            <label className="camp-sub-name">Father's Name</label>
            <p className="camp-sub-desc ">
              {common.textCapitalize(selectedItem?.FatherName) || "-"}
            </p>
          </div>
          <div className="col-md-6   bottom-border-divider bottom-left-side-divider ps-4">
            <label className="camp-sub-name">Name Of Spouse</label>
            <p className="camp-sub-desc">
              {common.textCapitalize(selectedItem?.SpouseName) || "-"}
            </p>
          </div>
          {/* <div className="col-md-6  pt-3 pb-3 bottom-border-divider bottom-left-side-divider ps-4">
            <label className="camp-sub-name">Nationality</label>
            <p className="camp-sub-desc mt-2">
              {selectedItem?.NationalityId || "-"}
            </p>
          </div> */}
          {/* <div className="col-md-6  pt-3 pb-3 bottom-border-divider bottom-left-side-divider ps-4">
            <label className="camp-sub-name">Person Type</label>
            <p className="camp-sub-desc mt-2">
              {selectedItem?.PersonType || "-"}
            </p>
          </div> */}
          <div className="col-md-6    bottom-left-side-divider ps-4">
            <label className="camp-sub-name">Employee Type</label>
            <p className="camp-sub-desc ">
              {selectedItem?.EmployementStatus || "-"}
            </p>
          </div>
          <Box>
            <Divider />
          </Box>
          <Typography className=" location-detail-title ps-4">
            ASSIGNMENTS
          </Typography>
          <Box>
            <Divider />
          </Box>

          <div className="col-md-6 bottom-border-divider bottom-left-side-divider ps-4">
            <label className="camp-sub-name">Department Name</label>
            <p className="camp-sub-desc ">
              {common.getJoinString(departmentList) || "-"}
            </p>
          </div>
          {/* <div className="col-md-6  pt-3 pb-3 bottom-border-divider bottom-left-side-divider ps-4">
            <label className="camp-sub-name">Supervisor</label>
            <p className="camp-sub-desc mt-2">
              {selectedItem?.SupervisorId || "-"}
            </p>
          </div> */}
        </>
      ) : (
        <StatusBar status="empty" />
      )}
    </div>
  );
};

export default EmpDetail;
