const permissionOptions = ["r", "a", "u", "d", "e", "i"];

export const findIsSubModule = (obj, key) => {
  let isSubModule = true;
  const rowData = obj[key];
  isSubModule = Object.keys(rowData)?.some((x) =>
    permissionOptions.includes(x)
  );

  return !isSubModule;
};
