import {
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React, { memo } from "react";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useNavigate } from "react-router-dom";
import { common } from "helpers";
const CardsView = memo(() => {
  const navigate = useNavigate();

  const handleKanban = () => {
    navigate("/crm/view/5");
  };

  return (
    <div className="mt-2 tasks">
      <Box className="kanban-paper">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
            <Paper variant="contained">
              <Box
                p={1}
                px={2}
                display="flex"
                alignItems="center"
                justifyContent={"space-between"}
                style={{ borderBottom: "3px solid #e44949" }}
              >
                <Box display="flex" alignItems="center">
                  <Typography className="header-title">Pending</Typography>
                  &nbsp;
                  <span className="count">02</span>
                </Box>
                <Box>
                  <IconButton>
                    <img src={common.loadImg("addKanban.svg")} alt="add" />
                  </IconButton>
                </Box>
              </Box>
              <Box p={2}>
                <Grid container direction="column" spacing={2}>
                  {[1, 2, 3].map((_, i) => {
                    return (
                      <Grid item>
                        <Paper
                          variant="outlined"
                          onClick={handleKanban}
                          className="pointer"
                        >
                          <Box
                            px={2}
                            py={1}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="start"
                          >
                            <Box mt={1}>
                              <Typography className="variant-title">
                                Task
                              </Typography>
                              <Typography className="fb-title mt-2">
                                Contact the agency regarding the campaign
                              </Typography>
                            </Box>
                            <Box>
                              <IconButton className="more-icon">
                                <MoreHorizIcon />
                              </IconButton>
                            </Box>
                          </Box>
                          <Divider />
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            px={2}
                            py={2}
                          >
                            <Typography className="variant-title">
                              Assigned for
                            </Typography>
                            <Typography className="date-title">
                              Mon Aug 21
                            </Typography>
                            <Typography className="assignee">
                              janani silva
                            </Typography>
                          </Box>
                          <Divider />
                          <Box display="flex" px={1}>
                            <Grid container spacing={1} alignItems="center">
                              <Grid item>
                                <Typography className="variant-title">
                                  Priority
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Box className="sts-card-mid" px={2} py={0.5}>
                                  Mid
                                </Box>
                              </Grid>
                            </Grid>
                            <Box className="vertical-divider" px={2}>
                              <Divider orientation="vertical" />
                            </Box>

                            <Grid container spacing={2} alignItems="center">
                              <Grid item>
                                <Typography className="variant-title">
                                  Deadline
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Box className="date-title">Tomorrow</Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </Paper>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
            <Paper variant="contained">
              <Box
                p={1}
                px={2}
                display="flex"
                alignItems="center"
                justifyContent={"space-between"}
                style={{ borderBottom: "3px solid #ff9e1e" }}
              >
                <Box display="flex" alignItems="center">
                  <Typography className="header-title">In Progress</Typography>
                  &nbsp;
                  <span className="count">02</span>
                </Box>
                <Box>
                  <IconButton>
                    <img src={common.loadImg("addKanban.svg")} alt="add" />
                  </IconButton>
                </Box>
              </Box>
              <Box p={2}>
                <Grid container direction="column" spacing={2}>
                  {[1].map((_, i) => {
                    return (
                      <Grid item>
                        <Paper
                          variant="outlined"
                          onClick={handleKanban}
                          className="pointer"
                        >
                          <Box
                            px={2}
                            py={1}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="start"
                          >
                            <Box mt={1}>
                              <Typography className="variant-title">
                                Task
                              </Typography>
                              <Typography className="fb-title mt-2">
                                Contact the agency regarding the campaign
                              </Typography>
                            </Box>
                            <Box>
                              <IconButton className="more-icon">
                                <MoreHorizIcon />
                              </IconButton>
                            </Box>
                          </Box>
                          <Divider />
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            px={2}
                            py={2}
                          >
                            <Typography className="variant-title">
                              Assigned for
                            </Typography>
                            <Typography className="date-title">
                              Mon Aug 21
                            </Typography>
                            <Typography className="assignee">
                              janani silva
                            </Typography>
                          </Box>
                          <Divider />
                          <Box display="flex" px={1}>
                            <Grid container spacing={1} alignItems="center">
                              <Grid item>
                                <Typography className="variant-title">
                                  Priority
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Box className="sts-card-minor" px={2} py={0.5}>
                                  Minor
                                </Box>
                              </Grid>
                            </Grid>
                            <Box className="vertical-divider" px={2}>
                              <Divider orientation="vertical" />
                            </Box>

                            <Grid container spacing={2} alignItems="center">
                              <Grid item>
                                <Typography className="variant-title">
                                  Deadline
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Box className="date-title">Tomorrow</Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </Paper>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
            <Paper variant="contained">
              <Box
                p={1}
                px={2}
                display="flex"
                alignItems="center"
                justifyContent={"space-between"}
                style={{ borderBottom: "3px solid #605bd2" }}
              >
                <Box display="flex" alignItems="center">
                  <Typography className="header-title">Feedback</Typography>
                  &nbsp;
                  <span className="count">02</span>
                </Box>
                <Box>
                  <IconButton>
                    <img src={common.loadImg("addKanban.svg")} alt="add" />
                  </IconButton>
                </Box>
              </Box>
              <Box p={2}>
                <Grid container direction="column" spacing={2}>
                  {[1].map((_, i) => {
                    return (
                      <Grid item>
                        <Paper
                          variant="outlined"
                          onClick={handleKanban}
                          className="pointer"
                        >
                          <Box
                            px={2}
                            py={1}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="start"
                          >
                            <Box mt={1}>
                              <Typography className="variant-title">
                                Task
                              </Typography>
                              <Typography className="fb-title mt-2">
                                Contact the agency regarding the campaign
                              </Typography>
                            </Box>
                            <Box>
                              <IconButton className="more-icon">
                                <MoreHorizIcon />
                              </IconButton>
                            </Box>
                          </Box>
                          <Divider />
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            px={2}
                            py={2}
                          >
                            <Typography className="variant-title">
                              Assigned for
                            </Typography>
                            <Typography className="date-title">
                              Mon Aug 21
                            </Typography>
                            <Typography className="assignee">
                              janani silva
                            </Typography>
                          </Box>
                          <Divider />
                          <Box display="flex" px={1}>
                            <Grid container spacing={1} alignItems="center">
                              <Grid item>
                                <Typography className="variant-title">
                                  Priority
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Box className="sts-card-mid" px={2} py={0.5}>
                                  mid
                                </Box>
                              </Grid>
                            </Grid>
                            <Box className="vertical-divider" px={2}>
                              <Divider orientation="vertical" />
                            </Box>

                            <Grid container spacing={2} alignItems="center">
                              <Grid item>
                                <Typography className="variant-title">
                                  Deadline
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Box className="date-title">Tomorrow</Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </Paper>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
            <Paper variant="contained">
              <Box
                p={1}
                px={2}
                display="flex"
                alignItems="center"
                justifyContent={"space-between"}
                style={{ borderBottom: "3px solid #34c157" }}
              >
                <Box display="flex" alignItems="center">
                  <Typography className="header-title">Closed</Typography>&nbsp;
                  <span className="count">02</span>
                </Box>
                <Box>
                  <IconButton>
                    <img src={common.loadImg("addKanban.svg")} alt="add" />
                  </IconButton>
                </Box>
              </Box>
              <Box p={2}>
                <Grid container direction="column" spacing={2}>
                  {[1, 2, 3].map((_, i) => {
                    return (
                      <Grid item>
                        <Paper
                          variant="outlined"
                          onClick={handleKanban}
                          className="pointer"
                        >
                          <Box
                            px={2}
                            py={1}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="start"
                          >
                            <Box mt={1}>
                              <Typography className="variant-title">
                                Task
                              </Typography>
                              <Typography className="fb-title mt-2">
                                Contact the agency regarding the campaign
                              </Typography>
                            </Box>
                            <Box>
                              <IconButton className="more-icon">
                                <MoreHorizIcon />
                              </IconButton>
                            </Box>
                          </Box>
                          <Divider />
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            px={2}
                            py={2}
                          >
                            <Typography className="variant-title">
                              Assigned for
                            </Typography>
                            <Typography className="date-title">
                              Mon Aug 21
                            </Typography>
                            <Typography className="assignee">
                              janani silva
                            </Typography>
                          </Box>
                          <Divider />
                          <Box display="flex" px={1}>
                            <Grid container spacing={1} alignItems="center">
                              <Grid item>
                                <Typography className="variant-title">
                                  Priority
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Box className="sts-card-high" px={2} py={0.5}>
                                  High
                                </Box>
                              </Grid>
                            </Grid>
                            <Box className="vertical-divider" px={2}>
                              <Divider orientation="vertical" />
                            </Box>

                            <Grid container spacing={2} alignItems="center">
                              <Grid item>
                                <Typography className="variant-title">
                                  Deadline
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Box className="date-title">Tomorrow</Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </Paper>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
});

export default CardsView;
