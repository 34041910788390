import * as React from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { Grid, Popover, Typography } from "@mui/material";

import { Link } from "react-router-dom";
// api
import { common, envConfig } from "helpers";

export default function HeaderRight(props) {
  const userInfo = common.authInfo();
  const navigate = useNavigate();
  const [anchorEll, setAnchorEll] = React.useState(null);

  const handleProfile = (event) => {
    event.preventDefault();
    setAnchorEll(event.currentTarget);
  };

  const handleProfileClose = (e) => {
    e.preventDefault();
    setAnchorEll(null);
    navigate("/profile");
  };

  const openProfile = Boolean(anchorEll);
  const idProfile = openProfile ? "simple-popover" : undefined;
  // const usrInitial = userInfo?.FirstName[0] + userInfo?.LastName[0];
  return (
    <>
      <Grid item>
        <Box
          className="side-menu safari-style"
          display="flex"
          alignItems="center"
          px={4}
          onClick={(e) => handleProfile(e)}
        >
          <Grid
            container
            alignItems="center"
            spacing={3}
            className="pointer safari-style"
          >
            <Grid item>
              <Typography className="avatar-text">
                {props.userDetails &&
                  props.userDetails.FirstName &&
                  props.userDetails.FirstName}
              </Typography>
            </Grid>
            <Grid item>
              <img
                src={common.loadImg("ProfileDownArrow.svg")}
                alt="settings"
              />
            </Grid>
            <Grid item>
              {/* {!usrInitial ? ( */}
              <img
                src={
                  envConfig.IMG_SERVLET_URL +
                  "?imageId=" +
                  userInfo.UserId +
                  "&imageType=USER"
                }
                className="notes-imgs"
              />
              {/* ) : (
                <div className="notes-imgs">
                  <p className="initial">{usrInitial}</p>
                </div>
              )} */}
              {/* <img src={common.loadImg("header_profile.svg")} alt="settings" /> */}
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Popover
        disableScrollLock
        id={idProfile}
        open={openProfile}
        anchorEl={anchorEll}
        onClose={(e) => handleProfileClose(e)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        style={{ top: "44px", zIndex: "99999" }}
      >
        <Box className="header-menu">
          <Grid container direction="column">
            <Grid item>
              <Box mt={1}>
                <Grid
                  container
                  direction="column"
                  spacing={1}
                  className="profile-menu-wrap"
                >
                  <Grid item className="profile-link">
                    <Typography className="header-menu-item">
                      <Link
                        to="#"
                        //to="/profile"
                        onClick={(e) => handleProfileClose(e)}
                      >
                        My Profile
                      </Link>
                    </Typography>
                  </Grid>
                  <Grid item onClick={(e) => props.logout(e)}>
                    <Typography className="header-menu-item">Logout</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </>
  );
}
