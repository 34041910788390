import React, { Fragment, useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useOutletContext,
} from "react-router-dom";
import AmlHashForm from "aml-hash-form";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import InputIcon from "react-multi-date-picker/components/input_icon";
import DatePicker from "react-multi-date-picker";
import StatusBar from "elements/StatusBar";
import { api, common, envConfig } from "helpers";
import { TRANSACTION_STATUS } from "helpers/service";
import AccessDenied from "elements/AccessDenied";

const SAVE_SUCCESS = "Transaction status saved successfully.";
const SAVE_FAILED = "Transaction status save failed.";
const UPDATE_FAILED = "Transaction status update failed.";
const BACK_URL = "/setting/configure/formTransaction";

function AddFormTransaction() {
  const { tId, pId } = useParams();
  const [permission] = useOutletContext();
  const navigate = useNavigate();
  const userData = common.userInfo();
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(tId ? "process" : "success");

  const formFields = {
    OrderNo: { validate: ["req#Order No is required"] },
    // StatusCode: { validate: ["req#Status Code is required"] },
    StatusName: { validate: ["req#Status Name is required"] },
    Description: { validate: [] },
    StartDate: { validate: ["req#Start Date is required"] },
    EndDate: { validate: [] },
    Active: { value: false },
    StatusColor: { validate: ["req#Status Color is required"] },
  };
  // Main Form
  const { fields, errors, handleChange, handleSubmit, setMultiValue } =
    AmlHashForm(formFields);

  useEffect(() => {
    if (!pId) {
      navigate(BACK_URL);
    } else if (tId) {
      getInfo();
    }
  }, []);

  const getInfo = () => {
    let url = `${envConfig.BASE_API}${TRANSACTION_STATUS}/${tId}`;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };

    api.call(
      data,
      (response) => {
        if (response.status === 200) {
          const result = response.data;
          if (result) {
            setMultiValue({
              OrderNo: result.OrderNo || "",
              StatusCode: result.StatusName || "",
              StatusName: result.StatusName || "",
              Description: result.Description || "",
              StatusColor: result.StatusColor || "",
              StartDate: result.StartDate || "",
              EndDate: result.EndDate || "",
              Active: result.Active === "Y",
            });
          }
        }
        setStatus("success");
      },
      (error) => {
        setStatus("error");
      },
      (final) => {}
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let isValid = handleSubmit();
    if (isValid) {
      save();
    }
  };

  const save = () => {
    setIsLoading(true);
    let params = {
      OrderNo: fields.OrderNo,
      StatusCode: fields.StatusName.toUpperCase(),
      StatusName: fields.StatusName,
      Description: fields.Description,
      StatusColor: fields.StatusColor,
      StartDate: fields.StartDate
        ? common.formatDate(fields.StartDate, "YYYY-MM-DDTHH:mm:ssZ")
        : null,
      EndDate: fields.EndDate
        ? common.formatDate(fields.EndDate, "YYYY-MM-DDTHH:mm:ssZ")
        : null,
      Active: fields.Active ? "Y" : "N",
      TenantId: userData.pTenantId,
      OrgId: userData.pOrgId,
      TransactionTypeId: parseInt(pId),
    };

    let url = `${envConfig.BASE_API}${TRANSACTION_STATUS}`;
    if (tId) {
      url = `${envConfig.BASE_API}${TRANSACTION_STATUS}/${tId}`;
    }
    let data = {
      url: url,
      type: "dynamic",
      method: tId ? "PATCH" : "POST",
      body: JSON.stringify(params),
      auth: "token",
      cType: 4,
    };

    api.call(
      data,
      (res) => {
        if (res.data) {
          if (tId) {
            common.snack("S", SAVE_SUCCESS);
          } else {
            common.snack("S", SAVE_SUCCESS);
          }
          navigate(BACK_URL);
        } else if (
          res?.status === 500 ||
          res?.status === 400 ||
          res?.status === 404
        ) {
          const error = res?.data?.error;
          if (tId) {
            common.snack("E", error || UPDATE_FAILED);
          } else {
            common.snack("E", error || SAVE_FAILED);
          }
        }
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
        common.snack("E", SAVE_FAILED);
      },
      (final) => {
        setIsLoading(false);
      }
    );
  };

  // render
  return (
    <div style={{ padding: "20px" }}>
      {common.givenPermission(
        permission,
        "configure:form trasactions",
        "basic",
        tId
      ) ? (
        <AccessDenied />
      ) : (
        <Paper
          sx={{ width: "100%", mb: 2 }}
          className={`container responsive-table p-0`}
        >
          <Box>
            <Paper variant="contained dynamic-form-wrap" square>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item display="flex" alignItems="center" xs={5} className="detail-head">
                  <Grid py={1} px={1} className="icon-back icon-buton">
                    <Link to={BACK_URL}>
                      <img
                        src={common.loadImg("backArrowKanban.svg")}
                        alt="back"
                      />
                    </Link>
                  </Grid>
                  <Grid className="df-main-head">
                    <Typography className="detail-title">
                      {`${tId ? "Edit" : "Add"} Status Transaction`}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item  className="safari-style">
                  <Box px={2}  className="safari-style">
                    <Grid container alignItems="center" spacing={2}  className="safari-style">
                      <Grid item>
                        <Box className="verical-divider">
                          <Divider orientation="vertical" />
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box>
                          {status == "success" && (
                            <Button
                              className="save-note"
                              type="submit"
                              disabled={isLoading}
                              onClick={(e) => onSubmit(e)}
                            >
                              {isLoading ? "Processing..." : "Save Changes"}
                            </Button>
                          )}
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box className="vertical-divider">
                          <Divider orientation="vertical" />
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box className="revert-close">
                          <Button className="save-note pe-0">
                            <Link to={BACK_URL}>
                              Revert and Close
                              <img
                                src={common.loadImg("closeKanban.svg")}
                                alt="close"
                              />
                            </Link>
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
              <Box>
                <Divider />
              </Box>
              {status !== "success" ? (
                <StatusBar status={status} />
              ) : (
                <Fragment>
                  <Box p={2} px={5} className="form-wrap">
                    <Typography className="info-title dynamic-form-title mt-3">
                      STATUS INFORMATION
                    </Typography>
                    <Box mt={3}>
                      <Divider />
                    </Box>

                    <Box mt={2} className="camp-edit meet-table">
                      <Row className="m-0">
                        <Col
                          xs={6}
                          className="body-th common-input-style mandatoryclass"
                        >
                          <Box className="filled-box">
                            <TextField
                              id="filled-basic"
                              label="Order No"
                              name="OrderNo"
                              variant="filled"
                              value={fields.OrderNo}
                              type="number"
                              InputProps={{
                                inputProps: { min: 0 },
                              }}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            />
                            {errors.OrderNo ? (
                              <p className="error-txt">{errors.OrderNo}</p>
                            ) : null}
                          </Box>
                        </Col>
                        <Col
                          xs={6}
                          className="body-th common-input-style mandatoryclass"
                        >
                          <Box className="filled-box">
                            <TextField
                              id="filled-basic"
                              label="Status Name"
                              name="StatusName"
                              variant="filled"
                              value={fields.StatusName}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            />
                            {errors.StatusName ? (
                              <p className="error-txt">{errors.StatusName}</p>
                            ) : null}
                          </Box>
                        </Col>

                        <Col xs={6} className="body-th common-input-style">
                          <Box className="filled-box">
                            <div className="form-floating">
                              <textarea
                                className="form-control textarea"
                                id="floatingTextarea"
                                name="Description"
                                value={fields.Description}
                                maxLength={150}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                              />
                              <label
                                htmlFor="floatingTextarea"
                                className="floating-label"
                              >
                                Description
                              </label>
                            </div>
                          </Box>
                        </Col>

                        <Col
                          xs={6}
                          className="body-th common-input-style mandatoryclass"
                        >
                          <Box className="text-box" id="float-label">
                            <label
                              className={
                                fields.StartDate
                                  ? "Active text-label ml-4 camp-time-label"
                                  : "text-label ml-4 camp-time-label"
                              }
                            >
                              Start Date
                            </label>

                            <Box className="ml-1 input-date-picker cust-br">
                              <DatePicker
                                format="MM/DD/YYYY"
                                render={<InputIcon />}
                                name="StartDate"
                                placeholder="Start Date"
                                value={
                                  fields.StartDate
                                    ? moment(fields.StartDate).toDate()
                                    : ""
                                }
                                onChange={(e) => {
                                  setMultiValue({
                                    StartDate: e?.toDate() || "",
                                  });
                                }}
                              />
                            </Box>
                          </Box>
                          {errors.StartDate ? (
                            <p className="error-txt">
                              {errors.StartDate || ""}
                            </p>
                          ) : null}
                        </Col>
                        <Col xs={6} className="body-th common-input-style">
                          <Box className="text-box" id="float-label">
                            <label
                              className={
                                fields.EndDate
                                  ? "Active text-label ml-4 camp-time-label"
                                  : "text-label ml-4 camp-time-label"
                              }
                            >
                              End Date
                            </label>

                            <Box className="ml-1 input-date-picker cust-br">
                              <DatePicker
                                format="MM/DD/YYYY"
                                render={<InputIcon />}
                                name="EndDate"
                                placeholder="End Date"
                                value={
                                  fields.EndDate
                                    ? moment(fields.EndDate).toDate()
                                    : ""
                                }
                                onChange={(e) => {
                                  setMultiValue({
                                    EndDate: e?.toDate() || "",
                                  });
                                }}
                              />
                            </Box>
                          </Box>
                        </Col>
                        <Col xs={6} className="body-th">
                          <Box className="filled-box color-picker-input">
                            {/* <ColorPicker /> */}
                            <input
                              type="color"
                              name="StatusColor"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              value={
                                fields.StatusColor
                                  ? fields.StatusColor
                                  : "#000000"
                              }
                            ></input>
                            {errors.StatusColor ? (
                              <p className="error-txt">{errors.StatusColor}</p>
                            ) : null}
                          </Box>
                        </Col>

                        <Col xs={6} className="body-th common-input-style">
                          <FormControlLabel
                            className="dynamic-checkbox ms-0 checkbox-space"
                            control={<Checkbox />}
                            label="Active"
                            checked={fields.Active}
                            labelPlacement="end"
                            name="Active"
                            value=""
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                        </Col>
                      </Row>
                    </Box>
                  </Box>
                </Fragment>
              )}
            </Paper>
          </Box>
        </Paper>
      )}
    </div>
  );
}

export default AddFormTransaction;
