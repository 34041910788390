import { Box, Paper } from "@mui/material";
import React, { memo } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {Notes, History, Meeting, CallLog} from "poc/conversions/common";

const CampaignsSubMenu = memo(({ handleExpanded, isExpand }) => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box>
      <TabContext value={value}>
        <Paper variant="contained" square>
          <Box display="flex" justifyContent="space-between">
            <Box
              display="flex"
              alignItems="center"
              px={3}
              className="tabs-sub"
              style={{ overflow: "hidden" }}
            >
              <TabList onChange={handleChange}  variant="scrollable">
                <Tab label="Notes" value="1" />
                <Tab label="Call Logs" value="2" />
                <Tab label="Meetings" value="3" />
                <Tab label="Attachments" value="4" />
                <Tab label="Sales Order" value="5" />
                <Tab label="Invoices" value="6" />
                <Tab label="Contacts" value="7" />
                <Tab label="Contacts" value="8" />
                <Tab label="History" value="9" />
              </TabList>
            </Box>
          </Box>
          <TabPanel value="1">
            <Notes handleExpanded={handleExpanded} isExpand={isExpand} />
          </TabPanel>
          <TabPanel value="2" className="calllog-content-wrap">
            <CallLog handleExpanded={handleExpanded} isExpand={isExpand} />
          </TabPanel>
          <TabPanel value="3" className="meeting-content-wrap">
            <Meeting handleExpanded={handleExpanded} isExpand={isExpand} />
          </TabPanel>
          <TabPanel value="9">
            <History handleExpanded={handleExpanded} isExpand={isExpand} />
          </TabPanel>
        </Paper>
      </TabContext>
    </Box>
  );
});

export default CampaignsSubMenu;
