import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InputIcon from "react-multi-date-picker/components/input_icon";
import DatePicker from "react-multi-date-picker";
import moment from "moment";
import Button from "@material-ui/core/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Col, Row } from "react-bootstrap";
import FieldSelect from "elements/FieldSelect";
import { Divider } from "@mui/material";
import { common, envConfig, api, eduTechSerivces } from "helpers";
import * as Services from "helpers/service";

const Conversion = function (props) {
  const isEdutechAccount = common.isEdutechAcc();
  const navigate = useNavigate();
  const [leadData, setLeadData] = useState(props.leadData);
  const [formSubmit, setFormSubmit] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [convertData, setConvertData] = useState({
    OpportunityName: "",
    ClosingDate: "",
    ExpectedRevenue: "",
    CampaignId: "",
    AccountId: "",
  });
  const [statusCode, setStatusCode] = useState("G2G");

  let error = {
    OpportunityName: {
      error: "",
      isReq: "Please select the Deal Name",
    },
    // AccountId: {
    //   error: "",
    //   isReq: "Please select the Customer Account",
    // },
  };
  const [errors, setErrors] = useState(error);

  useEffect(() => {
    if (!isEdutechAccount) {
      getCRMStatus();
    }
  }, []);

  useEffect(() => {
    let oppFields = common.reParse(convertData);
    oppFields.TerritoryId = leadData.TerritoryId || 0;
    oppFields.CampaignId = leadData.CampaignId || 0;
    oppFields.OpportunityName = leadData.LeadName;
    oppFields.OpportunityDescription = leadData.Description || "";
    oppFields.MobileNumber = leadData.Mobile || "";
    oppFields.PhoneNumber = leadData.Phone || "";
    oppFields.Email = leadData.Email || "";
    oppFields.ClosingDate = "";
    oppFields.ExpectedRevenue = "";
    //oppFields.AccountId = leadData.CustAccountId;
    oppFields.ApprStatus = statusCode;
    setConvertData(oppFields);
  }, [props]);

  const getCRMStatus = () => {
    let userData = common.authInfo();
    let type = common.getTransactionTypeId(envConfig.DEAL_TRANS_TYPE_ID);
    let params =
      `?q=TransactionTypeId=${type};OrgId=` +
      `${userData.DefaultOrgId};TenantId=` +
      `${userData.TenantId};Active=Y` +
      `&limit=100&offset=0&orderBy=OrderNo:asc`;
    let url = envConfig.BASE_API + Services.TRANSACTION_STATUS + params;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };
    api.call(
      data,
      (response) => {
        if (response.status == 200) {
          if (response.data.items.length > 0) {
            setStatusCode(response.data.items[0].StatusCode);
          }
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  const dealConversion = (res) => {
    setIsSubmit(true);
    let leadData = {
      Nattr10: res?.ContactId,
    };
    let data = {
      url: `${envConfig.BASE_API}${Services.CRM_LEADS}/${props.leadData.LeadId}`,
      type: "dynamic",
      method: "PATCH",
      auth: "token",
      body: JSON.stringify(leadData),
      cType: 4,
    };
    api.call(
      data,
      (response) => {
        if (response.status >= 200 && response.status <= 300) {
          let userData = common.authInfo();
          var createParams = {
            name: "convertLeadToOpportunity",
            parameters: [
              { P_LEAD_ID: props.leadData.LeadId.toString() },
              { P_OPPORTUNITY_NAME: convertData.OpportunityName },
              // { P_OPPORTUNITY_TYPE: "" },
              // { P_PRIORITY: "" },
              {
                P_SALES_PERSON_ID:
                  props.leadData.SalespersonId?.value || userData.EmpId,
              },
              { P_PHONE_NUMBER: convertData.PhoneNumber || "" },
              { P_MOBILE_NUMBER: convertData.MobileNumber || "" },
              { P_OPPORTUNITY_DESC: convertData.OpportunityDescription || "" },
              { P_EMAIL: convertData.Email || "" },
              { P_APPR_STATUS: convertData.ApprStatus },
              {
                P_TERRITORY_ID:
                  convertData.TerritoryId || userData.territory[0],
              },
              {
                P_CAMPAIGN_ID: convertData.CampaignId
                  ? convertData.CampaignId.value > 0
                    ? parseInt(convertData.CampaignId.value)
                    : ""
                  : "",
              },
              {
                P_EXPECTED_REVENUE: convertData.ExpectedRevenue || "",
              },
              {
                P_CUSTOMER_ID:
                  res && res?.CustomerId
                    ? res?.CustomerId
                    : convertData?.AccountId?.value
                    ? parseInt(convertData.AccountId.value)
                    : "",
              },
            ],
          };

          if (convertData.ClosingDate) {
            createParams.parameters.push({
              P_CLOSING_DATE: common.formatDate(
                convertData.ClosingDate,
                "YYYY-MM-DDTHH:mm:ssZ"
              ),
            });
          }

          let url = encodeURI(
            envConfig.BASE_API + Services.CHOLA_CUSTOM_CONVERSION
          );
          let data = {
            url: url,
            type: "dynamic",
            method: "POST",
            auth: "token",
            body: JSON.stringify(createParams),
            cType: 3,
          };
          api.call(
            data,
            (response) => {
              if (response.status >= 200 && response.status <= 300) {
                common.snack("S", "Successfully Converted as Deal");
                // if (isEdutechAccount) {
                let url =
                  envConfig.BASE_API +
                  Services.CRM_DEAL_OPPORTUNITY +
                  "?q=LeadId=" +
                  props.leadData.LeadId;
                let data = {
                  url: url,
                  type: "dynamic",
                  method: "GET",
                  auth: "token",
                };
                api.call(
                  data,
                  (response) => {
                    if (response.status == 200) {
                      let dealId = response.data.items[0].OpportunityId;
                      if (isEdutechAccount) {
                        eduTechSerivces.updateApplicationInfo(leadData.Cattr1, {
                          dealId: dealId,
                        });
                        props.successUpdate();
                        props.closeModal();
                        setIsSubmit(false);
                        navigate(
                          "/crm/deal/details/" + dealId + "?convert=true"
                        );
                      } else {
                        let leadData = {
                          Nattr9: props.newDeal ? dealId : null, //deal with conversion
                          Nattr10: res?.ContactId,
                          LeadPicPath: null,
                          CustAccountId:
                            res?.CustomerId ||
                            (convertData?.AccountId?.value
                              ? parseInt(convertData.AccountId.value)
                              : null),
                        };
                        let data = {
                          url: `${envConfig.BASE_API}${Services.CRM_LEADS}/${props.leadData.LeadId}`,
                          type: "dynamic",
                          method: "PATCH",
                          auth: "token",
                          body: JSON.stringify(leadData),
                          cType: 4,
                        };
                        api.call(
                          data,
                          (response) => {
                            let dealData = {
                              Nattr10: res?.ContactId,
                            };
                            let data = {
                              url: `${envConfig.BASE_API}${Services.CRM_DEAL_OPPORTUNITY}/${dealId}`,
                              type: "dynamic",
                              method: "PATCH",
                              auth: "token",
                              body: JSON.stringify(dealData),
                              cType: 4,
                            };
                            api.call(
                              data,
                              (response) => {
                                props.successUpdate();
                                props.closeModal();
                                setIsSubmit(false);
                                localStorage.setItem("dpHdr", null);
                                navigate(
                                  "/crm/deal/details/" +
                                    dealId +
                                    "?convert=true"
                                );
                              },
                              (error) => {},
                              (final) => {}
                            );
                          },
                          (error) => {},
                          (final) => {}
                        );
                      }
                    }
                  },
                  (error) => {},
                  (final) => {}
                );
                // } else {
                //   props.successUpdate();
                //   props.closeModal();
                // }
              }
            },
            (error) => {
              common.snack("E", error);
              setIsSubmit(false);
            },
            (final) => {}
          );
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  const convertToDeal = async (e) => {
    e.preventDefault();
    setFormSubmit(true);
    let isValid = validateAll();
    if (isValid) {
      if (!isEdutechAccount && (props.newCustomer || props.newDeal)) {
        setIsSubmit(true);
        let customerRes = await props.createNewCustomer();
        if (customerRes) {
          dealConversion(customerRes);
        }
      } else {
        dealConversion(null);
      }
    }
  };

  const onDateChange = (date, name) => {
    setConvertData({ ...convertData, [name]: date?.toDate() });
  };

  const onHandleSelect = async (data, name) => {
    setConvertData({ ...convertData, [name]: data });
    if (name == "AccountId") {
      if (data) {
        errors[name].error = "";
        setErrors(errors);
      } else {
        errors[name].error = errors[name].isReq;
        setErrors(errors);
      }
    }
  };

  const validateAll = () => {
    let isValid = true;
    for (var key of Object.keys(errors)) {
      if (convertData[key] == "" || convertData[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      } else {
        errors[key].error = "";
      }
    }
    setErrors(errors);
    return isValid;
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    if (convertData.hasOwnProperty(name)) {
      setConvertData({ ...convertData, [name]: value });
    }

    if (name == "OpportunityName") {
      if (value) {
        errors[name].error = "";
        setErrors(errors);
      } else {
        errors[name].error = errors[name].isReq;
        setErrors(errors);
      }
    }
  };

  const closeForm = () => {
    props.closeModal();
  };
  return (
    <div>
      <div className="popup-input-wrap px-4">
        <div className="list-row">
          <Row>
            <Col sm={6} className="body-th common-input-style mandatoryclass">
              <Form.Group id="float-label">
                {/* <Form.Label>
                  {"Enter Deal Name"} <span className="mf-required">*</span>
                </Form.Label> */}
                <Form.Label
                  className={
                    convertData.OpportunityName
                      ? "Active text-label ml-3"
                      : "text-label ml-3 camp-time-label"
                  }
                >
                  Enter Deal Name
                </Form.Label>
                <Form.Control
                  type="text"
                  className="convert-border"
                  placeholder={` `}
                  maxLength={150}
                  name="OpportunityName"
                  value={convertData.OpportunityName}
                  onChange={(e) => handleOnChange(e)}
                />

                {errors.OpportunityName.error !== "" && formSubmit === true && (
                  <p className="error-txt">{errors.OpportunityName.error}</p>
                )}
              </Form.Group>
            </Col>
            <Col sm={6} className="body-th common-input-style">
              <Form.Group id="float-label">
                <Form.Label
                  className={
                    convertData.ExpectedRevenue
                      ? "Active text-label ml-3"
                      : "text-label ml-3 camp-time-label"
                  }
                >
                  Amount
                </Form.Label>
                <Form.Control
                  type="number"
                  min={0}
                  name="ExpectedRevenue"
                  value={convertData.ExpectedRevenue}
                  onChange={(e) => handleOnChange(e)}
                  className="convert-border"
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col
              sm={6}
              className="body-th common-input-style popup-date-picker"
            >
              <Form.Group className="" id="float-label1">
                <Form.Label
                  className={
                    convertData.ClosingDate
                      ? "Active text-label ml-3"
                      : "text-label ml-3 camp-time-label"
                  }
                >
                  Closing Date
                </Form.Label>

                <DatePicker
                  name="ClosingDate"
                  value={
                    convertData.ClosingDate
                      ? moment(convertData.ClosingDate).toDate()
                      : ""
                  }
                  minDate={moment().toDate()}
                  onChange={(date) => onDateChange(date, "ClosingDate")}
                  format="DD/MM/YYYY"
                  render={<InputIcon />}
                />
              </Form.Group>
            </Col>
            <Col
              sm={6}
              className="body-th common-input-style common-select-border"
            >
              <Form.Group>
                <FieldSelect
                  api="CRM_CAMPAIGNS"
                  get="CampaignName"
                  set="CampaignId"
                  value={convertData.CampaignId}
                  isMulti={false}
                  isRender={true}
                  onSelect={(data) => onHandleSelect(data, "CampaignId")}
                  placeholder="Campaign"
                />
              </Form.Group>
            </Col>
          </Row>
          {/* <Row>
            <Col
              sm={6}
              className="body-th common-input-style common-select-border mandatoryclass"
            >
              <Form.Group>
                <FieldSelect
                  api="CRM_CUSTOMER"
                  get="CustomerName"
                  set="CustomerId"
                  value={convertData.AccountId}
                  isMulti={false}
                  isRender={true}
                  onSelect={(data) => onHandleSelect(data, "AccountId")}
                  placeholder="Customer Account"
                />
                {errors.AccountId.error !== "" && formSubmit === true && (
                  <p className="error-txt">{errors.AccountId.error}</p>
                )}
              </Form.Group>
            </Col>
            <Col sm={6}></Col>
          </Row> */}
        </div>
      </div>
      <Divider />
      <Row className="botBtn mt-3">
        <Col sm={12}>
          <Button
            className="saveBtn automation-footer-btn me-2"
            onClick={(e) => convertToDeal(e)}
            disabled={isSubmit}
          >
            {isSubmit ? "Processing..." : "Convert"}
          </Button>
          <Button
            onClick={() => closeForm()}
            className="cancelBtn btn btn-primary automation-cancel-btn"
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </div>
  );
};
export default Conversion;
