import React, { memo } from "react";
import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import { common } from "helpers";

const Notes = memo(({ handleExpanded, isExpand }) => {
  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography className="notes-header">Your Notes</Typography>
        {isExpand === "detail" ? (
          <Typography
            className="expand pointer"
            onClick={() => handleExpanded("tab")}
          >
            Expand <img src={common.loadImg("expandkanban.svg")} alt="expand" />
          </Typography>
        ) : (
          <Typography
            className="expand pointer"
            onClick={() => handleExpanded("detail")}
          >
            Collapse{" "}
            <img src={common.loadImg("expandkanban.svg")} alt="Collapse" />
          </Typography>
        )}
      </Box>
      <Box>
        <Divider />
      </Box>
      <Box mt={2}>
        <textarea
          placeholder="Type Your Notes Here..."
          rows={6}
          className="notes-textarea"
        />
      </Box>
      <Box mt={2}>
        <Button className="save-note">
          Save note <img src={common.loadImg("light-plus.svg")} alt="plus" />
        </Button>
      </Box>
      <Box mt={2}>
        <Divider />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={2}
      >
        <Typography className="notes-header">Saved Notes</Typography>
      </Box>
      <Box mt={2}>
        <Divider />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={2}
      >
        <Box>
          <Typography className="meeting-notes">
            Meeting Notes - Intitial
          </Typography>
          <Typography className="meeting-time">Aug 2021 10:00pm</Typography>
        </Box>
        <Box>
          <IconButton>
            <img src={common.loadImg("downloadkanban.svg")} alt="download" />
          </IconButton>
        </Box>
      </Box>
    </div>
  );
});

export { Notes };
