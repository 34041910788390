import React from "react";
import IconButton from "@mui/material/IconButton";
import {
  Divider,
  Grid,
  Hidden,
  Paper,
  Popover,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { common } from "helpers";
import serviceList from "assets/files/serviceList.json";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
const Demo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));
export default function QuickAdd() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const isEduTech = common.isEduTech();

  const handleClickExport = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const openNotification = Boolean(anchorEl);
  const id = openNotification ? "simple-popover" : undefined;
  const [dense, setDense] = React.useState(false);
  return (
    <Box
      className="plus"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: { xs: "center", lg: "start" },
      }}
    >
      <Box px={4} py={1}>
        <IconButton size="large" onClick={handleClickExport}>
          <img src={common.loadImg("plus.svg")} alt="plus" />
        </IconButton>
        <Popover
          disableScrollLock
          id={id}
          open={openNotification}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          className="quick-add-pop"
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Box className="notify-box" p={1} pl={2}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs={10}>
                <Typography
                  component="div"
                  className="flex align-items-center quick-add pl-2"
                >
                  Quick Add
                </Typography>
              </Grid>
              <Grid item onClick={handleClose}>
                <IconButton onClick={handleClose}>
                  <img
                    src={common.loadImg("dateClosekanban.svg")}
                    alt="close"
                  />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Divider />
          </Box>
          <Box sx={{ flexGrow: 1, maxWidth: 752 }}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Demo>
                  <List dense={dense} className="quick-add-main-list">
                    <Link
                      to="/crm/campaign/add"
                      className="text-none"
                      onClick={handleClose}
                    >
                      <ListItem className="quick-add-list">
                        <ListItemIcon className="quick-icon">
                          <img src={common.loadImg("quickAdd.svg")} alt="add" />
                        </ListItemIcon>
                        <ListItemText
                          primary="Add New Campaign"
                          className="quick-add-list-text"
                        />
                      </ListItem>
                    </Link>
                    <Box>
                      <Divider />
                    </Box>
                    <Link
                      to="/crm/enquiry/add"
                      className="text-none"
                      onClick={handleClose}
                    >
                      <ListItem className="quick-add-list">
                        <ListItemIcon className="quick-icon">
                          <img src={common.loadImg("quickAdd.svg")} alt="add" />
                        </ListItemIcon>
                        <ListItemText
                          primary="Add New Enquiry"
                          className="quick-add-list-text"
                        />
                      </ListItem>
                    </Link>
                    <Box>
                      <Divider />
                    </Box>
                    {!isEduTech && (
                      <Link
                        to="/crm/lead/add"
                        className="text-none"
                        onClick={handleClose}
                      >
                        <ListItem className="quick-add-list">
                          <ListItemIcon className="quick-icon">
                            <img
                              src={common.loadImg("quickAdd.svg")}
                              alt="add"
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Add New Lead"
                            className="quick-add-list-text"
                          />
                        </ListItem>
                      </Link>
                    )}
                    {!isEduTech && (
                      <Box>
                        <Divider />
                      </Box>
                    )}

                    {!isEduTech && (
                      <Link
                        to="/crm/deal/add"
                        className="text-none"
                        onClick={handleClose}
                      >
                        <ListItem className="quick-add-list">
                          <ListItemIcon className="quick-icon">
                            <img
                              src={common.loadImg("quickAdd.svg")}
                              alt="add"
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Add New Deal"
                            className="quick-add-list-text"
                          />
                        </ListItem>
                      </Link>
                    )}
                    {!isEduTech && (
                      <Box>
                        <Divider />
                      </Box>
                    )}
                    <Link
                      to="/crm/contacts/add"
                      className="text-none"
                      onClick={handleClose}
                    >
                      <ListItem className="quick-add-list">
                        <ListItemIcon className="quick-icon">
                          <img src={common.loadImg("quickAdd.svg")} alt="add" />
                        </ListItemIcon>
                        <ListItemText
                          primary="Add New Contact"
                          className="quick-add-list-text"
                        />
                      </ListItem>
                    </Link>
                    <Box>
                      <Divider />
                    </Box>
                  </List>
                </Demo>
              </Grid>
            </Grid>
          </Box>
        </Popover>
      </Box>
    </Box>
  );
}
