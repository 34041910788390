import { AES, enc } from "crypto-js";
import Papa from "papaparse";
import { common } from "helpers";
import moment from "moment";
const dateFormat = "MMMM DD, YYYY hh:mm:ss A";
const utcFormat = "DD-MM-YYYY HH:mm:ss";
export const tools = {
  getMonth(val) {
    let mth = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "AUG",
      "NOV",
      "DEC",
    ];

    let result = "";

    mth.map((item, key) => {
      if (typeof val == "string" && item == val) {
        result = key;
      } else if (typeof val == "number" && key == val) {
        result = item;
      }
    });
    return result;
  },

  // encode decode
  cryptCode() {
    return "#cErLyPpKt#";
  },

  crypt(data) {
    var ciphertext = AES.encrypt(
      JSON.stringify(data),
      this.cryptCode().toString()
    );
    return ciphertext;
  },

  deCrypt(encryptedData) {
    try {
      var bytes = AES.decrypt(encryptedData, this.cryptCode().toString());
      var decryptedData = JSON.parse(bytes.toString(enc.Utf8));

      if (!decryptedData) {
        common.removeAuthStorage();
      }
      return decryptedData;
    } catch (err) {
      common.removeAuthStorage();
    }
  },
  // date
  localNow() {
    return moment().format(dateFormat);
  },

  utcNow() {
    return moment().utc().format(utcFormat);
  },

  utcToLocal(date, format = dateFormat) {
    return moment.utc(date, utcFormat).local().format(format);
  },

  localToUtc(date) {
    return moment(date).utc().format(utcFormat);
  },

  // validate
  validateType(val, type = "s") {
    let result = val;
    if (type == "n") {
      result = tools.onlyNumbers(val);
    } else if (type == "f") {
      result = tools.onlyFloat(val);
    }
    return result;
  },

  ValidateEmail(mail) {
    let regExp =
      /^[A-Za-z0-9-+&_]+(\.[A-Za-z0-9-+]+)*(\+[A-Za-z0-9-&]+)?@[A-Za-z0-9-.]+(\.\w{2,10})+$/; // allow +&
    if (regExp.test(mail)) {
      return true;
    } else {
      return false;
    }
  },

  validateMobile(number) {
    return number.replace(/\D/g, "");
  },

  // input
  onlyNumbers(str) {
    return str.replace(/[^0-9]+/gi, "");
  },

  onlyFloat(str) {
    return str.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1");
  },

  setEmpty(val) {
    if (val == "" || val == null) {
      return "";
    } else {
      return val;
    }
  },

  setDate(val, set = true) {
    if (val == "" || val == null) {
      if (set) {
        return this.utcNow();
      } else {
        return "";
      }
    } else {
      return val;
    }
  },

  // array
  ObjectSet(arr, key) {
    let result = {};
    arr.map((item) => {
      Object.assign(result, { [item[key]]: item });
    });
    return result;
  },

  ObjectAssign(arr) {
    let result = {};
    Object.entries(arr).forEach(([key, item]) => {
      Object.assign(result, { [key]: item });
    });
    return result;
  },

  ObjectEmpty(arr) {
    let result = {};
    Object.entries(arr).forEach(([key, item]) => {
      Object.assign(result, { [key]: "" });
    });
    return result;
  },

  // dom
  scrollEnd(cls = "") {
    if (cls == "") {
      window.scrollTo(0, document.body.scrollHeight);
    } else {
      let ele = document.getElementsByClassName(cls);
      ele.scrollTo(0, ele.scrollHeight);
    }
  },

  randomString() {
    let length = 15;
    let chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  },

  csvFileParse(file, csvData) {
    Papa.parse(file, {
      complete: csvData,
      header: true,
    });
  },

  reParse(data) {
    return JSON.parse(JSON.stringify(data));
  },

  hideEmail(email) {
    return email.replace(/(.{2})(.*)(?=@)/, function (gp1, gp2, gp3) {
      for (let i = 0; i < gp3.length; i++) {
        gp2 += "*";
      }
      return gp2;
    });
  },

  hideMobile(phone) {
    if (phone.length > 5) {
      let prefixLength = 2;
      let suffixLength = 3;

      let prefix = phone.substring(0, prefixLength);
      let suffix = phone.slice(-suffixLength);
      let nbStars = phone.length - (prefixLength + suffixLength);

      let formattedPhone = prefix + "*".repeat(nbStars) + suffix;
      return formattedPhone;
    } else {
      return phone;
    }
  },
};

export const formatNumber = (count) => {
  if (typeof count === "number") {
    if (count > 0 && count < 9) {
      return `0${count}`;
    }
    return count;
  }
  return 0;
};

export const formatInitialText = (str) => {
  if (str) {
    const nameArr = str.split(" ");
    const initial =
      nameArr?.length > 1
        ? `${nameArr[0] ? nameArr[0].slice(0, 1) : ""}${
            nameArr[1] ? nameArr[1].slice(0, 1) : ""
          }`
        : str?.slice(0, 2);

    return initial;
  }
  return "";
};
