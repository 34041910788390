import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { common } from "helpers";
import React, { memo } from "react";
import DynamicForm from "elements/DynamicForm";

const LeadEdit = memo(() => {
  const authInfo = common.authInfo();
  const authData = () => {
    return { label: authInfo.EmployeeName, value: authInfo.EmpId.toString() };
  };
  const navigate = useNavigate();
  const [age, setAge] = React.useState("");
  const [isDateModal, setIsDateModal] = React.useState(false);

  const handleDate = () => {
    setIsDateModal(!isDateModal);
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  //Goto previous page
  const goto = (e) => {
    e.preventDefault();
    navigate("/crm/view/5");
  };

  function createData(type, venue) {
    return { type, venue };
  }

  const rows = [
    createData(
      <Box className="text-box">
        <label className="text-label ml-4">Campaign Name</label>
        <TextField id="name" fullWidth placeholder="Campaign Name" />
      </Box>,
      <Box className="text-box">
        <label className="text-label ml-4">Campaign Status</label>
        <FormControl
          sx={{ minWidth: "100%" }}
          size="small"
          className="dropdown-edit"
        >
          <Select value={age} displayEmpty onChange={handleChange}>
            <MenuItem disabled value="" className="active-item">
              <em>View all</em>
            </MenuItem>
            <MenuItem value={10}>New</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </FormControl>
      </Box>
    ),
    createData(
      <Box className="text-box">
        <label className="text-label ml-4">Campaign Name</label>
        <TextField id="name" fullWidth placeholder="Campaign Name" />
      </Box>,
      <Box className="text-box">
        <label className="text-label ml-4">Campaign Status</label>
        <FormControl
          sx={{ minWidth: "100%" }}
          size="small"
          className="dropdown-edit"
        >
          <Select value={age} displayEmpty onChange={handleChange}>
            <MenuItem disabled value="" className="active-item">
              <em>View all</em>
            </MenuItem>
            <MenuItem value={10}>New</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </FormControl>
      </Box>
    ),
  ];
  const dateRows = [
    createData(
      <Box className="text-box pointer" py={1} onClick={handleDate}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <label className="text-label ml-4">Planned Start Date</label>
          </Grid>
          <Grid item>
            <span className="edit-date ml-4">Aug 20 2021</span>
          </Grid>
        </Grid>
      </Box>,
      <Box className="text-box pointer" onClick={handleDate}>
        <label className="text-label ml-4">Planned End Date</label>
        <p className="edit-date ml-4">Aug 20 2021</p>
      </Box>
    ),
    createData(
      <Box className="text-box pointer" onClick={handleDate}>
        <label className="text-label ml-4">Actual End Date</label>
        <p className="edit-date ml-4">Aug 20 2021</p>
      </Box>
    ),
  ];
  return (
      <Box px={5} py={4}>
        <Paper variant="contained" square>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <Box py={1} px={1} className="icon-back">
                    <IconButton>
                      <img
                        src={common.loadImg("backArrowKanban.svg")}
                        alt="back"
                      />
                    </IconButton>
                  </Box>
                </Grid>
                <Grid item>
                  <Typography className="detail-title">
                    Facebook Campaign 2021
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Box px={2}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    <Box className="verical-divider">
                      <Divider orientation="vertical" />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box>
                      <Button className="save-note" onClick={(e) => goto(e)}>
                        Save Changes
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box className="verical-divider">
                      <Divider orientation="vertical" />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box className="revert-close">
                      <Button className="save-note" onClick={(e) => goto(e)}>
                        Revert and Close
                        <img
                          src={common.loadImg("closeKanban.svg")}
                          alt="close"
                        />
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Box>
            <Divider />
          </Box>
          <Box p={2} px={5}>
            <DynamicForm
              module="lead"
              data={{
                value1: 13312,
              }}
              default={{
                SalespersonId: authData(),
              }}
            />
          </Box>
        </Paper>
      </Box>
  );
});

export default LeadEdit;
