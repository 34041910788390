import React, { memo } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Divider,
  FormControl,
  Grid,
  Hidden,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Typography,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { common } from "helpers";

// import Plus from "../../assets/img/kanban/plus.svg";

import CampaignReports from "./campaignReports";
import MyReports from "./myReports";
import TeamReports from "./teamReports";
import PersonalisedReports from "./personalisedReports";
import DeletedReports from "./deletedReports";
import AddReports from "./addReports";
import QuickAdd from "elements/QuickAdd";
import { styled } from "@mui/material/styles";

const DrawerHeader1 = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  padding: theme.spacing(2, 3),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Reports = memo(() => {
  const [value, setValue] = React.useState("1");
  const [age, setAge] = React.useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeItem = (event) => {
    setAge(event.target.value);
  };
  React.useEffect(() => {
    var isMobileVersion = document.getElementsByClassName("rotate-arrow");
    const fixedHeader = document.getElementById("fixed-header");
    if(isMobileVersion.length > 0){
      
    //  document.getElementById("fixed-header").style.width = "86%";
    // fixedHeader.className = "MuiPaper-root MuiPaper-container fixed-header css-108pjeo-MuiPaper-root menu-shrink";
    fixedHeader.classList.add("menu-shrink");
    fixedHeader.classList.remove("menu-expand");
     const span = document.querySelectorAll(".Report-tab-header");
     // const result1 = span.classList.contains(".report-width-content");
    
     
     for(const spn of span){
       spn.classList.remove("report-width-content");
     }
   
    }else{
    //  document.getElementById("fixed-header").style.width = "94%";
    // fixedHeader.className = "MuiPaper-root MuiPaper-container fixed-header css-108pjeo-MuiPaper-root menu-expand";
    fixedHeader.classList.remove("menu-shrink");
    fixedHeader.classList.add("menu-expand");
    
     const span = document.querySelectorAll(".Report-tab-header");
      for(const spn of span){
        spn.classList.add("report-width-content");
      }

    }
  });
  return (
    <div className="default-bg mt-0">
      <Box
        sx={{ width: "100%", typography: "body1" }}
        className="primary-header-container"
      >
        <TabContext value={value}>
          <Paper variant="container" square className="fixed-header" id="fixed-header">
          {/*<Paper variant="container" square> */}
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", lg: "row" },

                itemsCenter: { xs: "center" },
                py: { xs: 5, lg: 0 },
              }}
              px={3}
              className="d-flex justify-content-between"
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", lg: "row" },
                  //   justifyContent: { xs: "center", lg: "space-between" },
                  itemsCenter: { xs: "center" },
                }}
                alignItems="center"
                // px={3}
                className="tabs"
              >
                <Box display="flex" alignItems="center">
                  <Box>
                    <img
                      src={common.loadImg("outlineCRM-chart.svg")}
                      alt="img"
                    />
                  </Box>
                  &nbsp;&nbsp;
                  <Typography className="header-crm">Reports</Typography>
                  <Hidden smDown>
                    <Box className="vertical-divider-report-subhead ml-4">
                      <Divider orientation="vertical" />
                    </Box>
                  </Hidden>
                </Box>
                <TabList
                  onChange={handleChange}
                  className=" tabs-list-ph delete-tab"
                >
                  <Tab label="Campaign Reports" value="1" className="Report-tab-header" />
                  <Tab label="Team Reports" value="2" className="Report-tab-header" />
                  <Tab label="My Reports" value="3" className="Report-tab-header" />
                  <Tab label="Personalised Reports" value="4" className="Report-tab-header" />
                  <Hidden smDown>
                    <Box className="vertical-divider-report-subhead">
                      <Divider orientation="vertical" />
                    </Box>
                  </Hidden>
                  <Tab
                    label={
                      <img
                        src={common.loadImg("blue-delete-icon.svg")}
                        alt="delete"
                      />
                    }
                    value="5"
                    className="delete-report-icon"
                  />
                  <Hidden smDown>
                    <Box className="vertical-divider-report-subhead">
                      <Divider orientation="vertical" />
                    </Box>
                  </Hidden>
                </TabList>
                <Box className="filter-reports ">
                  <FormControl sx={{ width: "100%" }}>
                    <OutlinedInput
                      disableunderline="true"
                      className="task filter-reports mt-1"
                      id="filter-reports"
                      placeholder={"Search Report"}
                      endAdornment={
                        <InputAdornment position="end">
                          <img
                            src={common.loadImg("search-blue-icon.svg")}
                            alt="search"
                          />
                        </InputAdornment>
                      }
                      aria-describedby="outlined-weight-helper-text"
                    />
                  </FormControl>
                </Box>
              </Box>

              <QuickAdd />
            </Box>
          </Paper>
          <DrawerHeader1 className="report-header" />
          <TabPanel value="1">
            <CampaignReports />
          </TabPanel>
          <TabPanel value="2">
            <TeamReports />
          </TabPanel>
          <TabPanel value="3">
            <MyReports />
          </TabPanel>
          <TabPanel value="4">
            <PersonalisedReports />
          </TabPanel>
          <TabPanel value="5">
            <DeletedReports />
          </TabPanel>
          <TabPanel value="6">
            <AddReports />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
});

export default Reports;
