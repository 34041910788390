import React, { useEffect, useCallback, Fragment } from "react";

import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";

import { api, common, envConfig } from "helpers";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { Link,useOutletContext } from "react-router-dom";
import Typography from "@mui/material/Typography";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import Popover from "@mui/material/Popover";
import {
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  OutlinedInput,
  Paper,
} from "@mui/material";
import { CRM_LOCATIONS } from "helpers/service";
import Loader from "elements/Loader";
import AccessDenied from "elements/AccessDenied";
import StatusBar from "elements/StatusBar";
import { formatNumber } from "helpers/tools";
import { useDebounce } from "helpers/debounce";

const DATE_FORMAT = "DD-MM-YYYY";
const LIST_LIMIT = 10;
const TIMER_INTERVAL = 1000;

const ActiveSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    backgroundColor: "#F3F4F6 !important",
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
  },
  "& .Mui-checked": {
    boxShadow: "none",
    color: "#29C745 !important",
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
    backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
      theme.palette.getContrastText(theme.palette.primary.main)
    )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
  },
}));

function Location() {
  const [permission] = useOutletContext();
  const [rightAnchorEl, setRightAnchorEl] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [resultList, setResultList] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [hasMore, setHasMore] = React.useState(null);
  const [fullPageLoading, setFullPageLoading] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [searchWord, setSearchWord] = React.useState("");

  const detailsMoreClick = (event) => {
    setRightAnchorEl(event.currentTarget);
  };
  const detailsCloseClick = () => {
    setRightAnchorEl(null);
  };

  useEffect(() => {
    setFullPageLoading(true);
  }, []);

  const loadLocationList = useCallback((pageNo, searchText) => {
    setPage(pageNo);
    let userData = common.authInfo();
    let url = `${envConfig.BASE_API}${CRM_LOCATIONS}?q=TenantId=${userData.TenantId}`;

    if (searchText) {
      url = `${url} AND UPPER(LocationName) LIKE '*${searchText}*'`;
    }
    url = `${url}&totalResults=true&orderBy=CreatedOn:desc&offset=${
      pageNo * LIST_LIMIT
    }&limit=${pageNo * LIST_LIMIT + LIST_LIMIT}`;

    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
      moreHead: { rfv: 2 },
      cType: 4,
    };

    api.call(
      data,
      (response) => {
        if (response) {
          let result = response.data;
          setResultList((oldData) => {
            if (pageNo === 0) {
              return result.items;
            } else {
              return [...new Set([...oldData, ...result.items])];
            }
          });

          if (result.items?.length > 0) {
            if (pageNo === 0) {
              setSelectedItem(result.items[0]);
            }
          } else {
            setSelectedItem(null);
          }
          setTotal(result.totalResults);
          setHasMore(result.hasMore);
        }

        setFullPageLoading(false);
        setIsLoading(false);
      },
      (error) => {
        setFullPageLoading(false);
        setIsLoading(false);
      },
      (final) => {
        setFullPageLoading(false);
        setIsLoading(false);
      }
    );
  }, []);

  const debouncedSearchTerm = useDebounce(searchWord, TIMER_INTERVAL);
  useEffect(() => {
    setIsLoading(true);
    loadLocationList(0, debouncedSearchTerm);
  }, [loadLocationList, debouncedSearchTerm]);

  const handleSearchChange = (event) => {
    setSearchWord(event.target.value);
    setPage(1);
  };

  const handleSelect = (item, index) => {
    setSelectedItem(item);
    setSelectedIndex(index);
  };

  const convertDate = (date) => {
    if (date) {
      return moment(date).format(DATE_FORMAT);
    }
    return "";
  };

  const rightMoreOpen = Boolean(rightAnchorEl);
  return (
    <React.Fragment>
      {common.givenPermission(permission,'vendor:locations','read') ? <AccessDenied /> :  <Box className="template-box-border p-4">
      <div className="row">
        <div className="col-md-4">
          <div className="card border-color-F2F9FD">
            <div className="card-body p-0">
              <Box display="flex" alignItems="center" justifyContent="center">
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  className="p-2"
                >
                  <Grid item>
                    <h5 className="d-flex align-items-center title">
                      <Link to="/setting/view/vendor">
                        <img
                          alt={"back"}
                          className="mx-2"
                          src={common.loadImg(
                            "iconly-light-arrow-down-circle.svg"
                          )}
                          width={30}
                          height={30}
                        />
                      </Link>
                      <label className="d-flex align-items-center location-detail-title">
                        Locations{" "}
                        {!fullPageLoading && (
                          <span className="location-count-label">
                            {formatNumber(total || 0)}
                          </span>
                        )}
                      </label>
                    </h5>
                  </Grid>
                  <Grid item>
                    {!common.givenPermission(permission,'vendor:locations','read') ? <Box className="flex" alignItems="center">
                      <Link
                        to="/setting/vendor/location/add"
                        className="text-none pointer"
                      >
                        <span className="mx-2 add-new">Add New</span>
                      </Link>

                      <div className="add-new-button d-flex align-items-center justify-content-center">
                        <Link
                          to="/setting/vendor/location/add"
                          className="text-none pointer"
                        >
                          <span className="d-flex align-items-center plus-icon">
                            +
                          </span>
                        </Link>
                      </div>
                    </Box> : null}
                    
                  </Grid>
                </Grid>
              </Box>
              {fullPageLoading ? (
                <StatusBar status="process" />
              ) : (
                <Fragment>
                  <div className="d-flex bg-F2F9FD ">
                    <Box className="filter-search" width="100%">
                      <FormControl sx={{ width: "100%" }}>
                        <OutlinedInput
                          id="search"
                          value={searchWord}
                          onChange={(event) => handleSearchChange(event)}
                          placeholder={"Search"}
                          endAdornment={
                            <InputAdornment position="end">
                              <img
                                src={common.loadImg("common-search.svg")}
                                alt="search"
                              />
                            </InputAdornment>
                          }
                          aria-describedby="outlined-weight-helper-text"
                        />
                      </FormControl>
                    </Box>
                  </div>

                  <div className="bg-white location-user-list">
                    {/* Profile */}
                    <Grid container direction="column">
                      <InfiniteScroll
                        scrollThreshold={0.6}
                        next={() => loadLocationList(page + 1, searchWord)}
                        hasMore={hasMore}
                        loader={!isLoading ? <Loader /> : null}
                        dataLength={resultList?.length || 0}
                      >
                        {isLoading ? (
                          <StatusBar status="process" />
                        ) : resultList?.length ? (
                          resultList.map((item, index) => {
                            const nameArr = item.LocationName.split(" ") || "";
                            const initial =
                              nameArr?.length > 1
                                ? `${nameArr[0] ? nameArr[0].slice(0, 1) : ""}${
                                    nameArr[1] ? nameArr[1].slice(0, 1) : ""
                                  }`
                                : item.LocationName?.slice(0, 2);

                            return (
                              <Grid item key={item.LocationId}>
                                <Box
                                  onClick={() => handleSelect(item, index)}
                                  className="pointer  color-divider"
                                >
                                  <Grid container alignItems="center">
                                    <Grid item xs={2} mt={0.5}>
                                      <Box px={2} py={1}>
                                        <Box className="img-box">
                                          {(initial || "").toUpperCase()}
                                        </Box>
                                      </Box>
                                      {index === selectedIndex ? (
                                        <div className="active active-location "></div>
                                      ) : null}
                                    </Grid>

                                    {/* <Grid item xs={1}>
                                      <Box
                                        display="flex"
                                        justifyContent="center"
                                      >
                                        <Box >
                                          <Divider orientation="vertical" height="76px"/>
                                        </Box>
                                      </Box>
                                    </Grid> */}
                                    <Grid item xs={9} className="divider-color">
                                      {/* <Box > */}
                                      {/* <Grid
                                        container
                                        justifyContent="space-between"
                                        alignItems="center"
                                      > */}
                                      <ul className="list-name-location mb-0">
                                        <li
                                          className={
                                            index === selectedIndex
                                              ? "location-names-active "
                                              : "location-names "
                                          }
                                        >
                                          {common.textCapitalize(
                                            item.LocationName
                                          ) || "_"}
                                        </li>
                                        <li className="setting-card-content mt-1">
                                          {item.LocationCode || ""}
                                        </li>
                                      </ul>
                                      {/* </Grid> */}
                                      {/* </Box> */}
                                    </Grid>
                                  </Grid>
                                  {/* <Box>
                                    <Divider />
                                  </Box> */}
                                </Box>
                              </Grid>
                            );
                          })
                        ) : (
                          <StatusBar status="empty" />
                        )}
                      </InfiniteScroll>
                    </Grid>
                  </div>
                </Fragment>
              )}
              {!fullPageLoading ? (
                <Box
                  sx={{ width: 1 }}
                  px={1}
                  py={1}
                  className="location-footer"
                >
                  {"Showing" +
                    " " +
                    `${resultList?.length ? 1 : 0} to ${
                      resultList?.length || 0
                    } of ${total || 0}`}
                </Box>
              ) : null}
            </div>
          </div>
        </div>

        <div className="col-md-8 location-user-details card-custom ">
          {!isLoading && selectedItem ? (
            <>
              <div className="row location-paper">
                <div className="">
                  <div className="card-body p-0">
                    <div className="d-flex justify-content-between p-3">
                      <label className="location-detail-title">
                        Location Information{" "}
                      </label>

                      {!common.givenPermission(permission,'vendor:locations','update',1) ? <div className="my-auto pe-4">
                        <MoreHorizOutlinedIcon
                          className="pointer"
                          aria-describedby={"right-more-popover"}
                          onClick={detailsMoreClick}
                        />

                        <Popover
                          disableScrollLock
                          id={"right-more-popover"}
                          open={rightMoreOpen}
                          anchorEl={rightAnchorEl}
                          onClose={detailsCloseClick}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          {["Edit"].map((menuItem) => (
                            <Typography
                              key={menuItem}
                              sx={{ p: 2 }}
                              className="text-capitalize pointer"
                            >
                              <Link
                                to={`/setting/vendor/location/edit/${selectedItem.LocationId}`}
                                className="text-none pointer"
                                style={{ color: "#000" }}
                              >
                                {menuItem}
                              </Link>
                            </Typography>
                          ))}
                        </Popover>
                      </div> : null}
                      
                    </div>
                  </div>
                </div>
                <Box p={0}>
                  <Divider />
                </Box>
                <div className="settings-secondary" />
                <div></div>
                <div className=" bg-F2F9FD"></div>
                <Paper
                  variant="contained"
                  style={{ padding: 0, boxShadow: "none !important" }}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      className="border-dynamic border-dynamic-right"
                    >
                      <Box px={3} pt={3}>
                        <label className="location-label-font">
                          Location Code
                        </label>
                        <p className="location-code-label mt-2 ">
                          {selectedItem?.LocationCode || "-"}
                        </p>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      className="border-dynamic"
                    >
                      <Box px={4} pt={3}>
                        <h6 className="location-label-font">Location Name</h6>
                        <p className="location-code-label  mt-2">
                          {common.textCapitalize(selectedItem?.LocationName) ||
                            "-"}
                        </p>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      className="border-dynamic border-dynamic-right"
                    >
                      <Box px={3} pt={3}>
                        <h6 className="location-label-font">Start Date</h6>
                        <p className="location-code-label  mt-2">
                          {convertDate(selectedItem?.StartDate) || "-"}
                        </p>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      className="border-dynamic"
                    >
                      <Box px={4} pt={3}>
                        <label className="location-label-font">End Date</label>
                        <p className="location-code-label  mt-2">
                          {convertDate(selectedItem?.EndDate) || "NA"}
                        </p>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      className={`border-dynamic border-dynamic-right customer-tab-li`}
                    >
                      <Box px={3} pt={3}>
                        <label className="location-label-font">
                          Description
                        </label>
                        <p className="location-code-label mt-2">
                          {selectedItem?.Description || "-"}
                        </p>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      className={`border-dynamic customer-tab-li`}
                    >
                      <Box px={4} pt={3}>
                        <label className="location-label-font">Active</label>{" "}
                        <p>
                          {" "}
                          <ActiveSwitch
                            checked={selectedItem?.Active === "Y"}
                          />
                        </p>
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </div>

              <div className="row location-paper">
                <div className="">
                  <div className="card-body p-0">
                    <div className="d-flex justify-content-between p-3">
                      <label className="location-detail-title">
                        Address Details{" "}
                      </label>
                    </div>
                  </div>
                </div>
                <Box p={0}>
                  <Divider />
                </Box>
                <div className="settings-secondary" />
                <div></div>
                <div className=" bg-F2F9FD"></div>
                <Paper
                  variant="contained"
                  style={{ padding: 0, boxShadow: "none !important" }}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      className="border-dynamic border-dynamic-right "
                    >
                      <Box px={3} pt={3}>
                        <label className="location-label-font">Address 1</label>
                        <p className="location-code-label  mt-2">
                          {common.textCapitalize(selectedItem?.AddressLine1) ||
                            "-"}
                        </p>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      className="border-dynamic"
                    >
                      <Box px={4} pt={3}>
                        <label className="location-label-font">Address 2</label>
                        <p className="location-code-label  mt-2">
                          {common.textCapitalize(selectedItem?.AddressLine2) ||
                            "-"}
                        </p>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      className="border-dynamic border-dynamic-right"
                    >
                      <Box px={3} pt={3}>
                        <label className="location-label-font">Address 3</label>
                        <p className="location-code-label  mt-2">
                          {common.textCapitalize(selectedItem?.AddressLine3) ||
                            "-"}
                        </p>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      className="border-dynamic"
                    >
                      <Box px={4} pt={3}>
                        <label className="location-label-font">
                          Country Code
                        </label>
                        <p className="location-code-label  mt-2">
                          {selectedItem?.CountryCode || "-"}
                        </p>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      className={`border-dynamic border-dynamic-right customer-tab-li`}
                    >
                      <Box px={3} pt={3}>
                        <label className="location-label-font">State</label>
                        <p className="location-code-label  mt-2">
                          {selectedItem?.State || "-"}
                        </p>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      className="border-dynamic"
                    >
                      <Box px={4} pt={3}>
                        <label className="location-label-font">City</label>
                        <p className="location-code-label  mt-2">
                          {common.textCapitalize(selectedItem?.City) || "-"}
                        </p>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      className="border-dynamic border-dynamic-right"
                    >
                      <Box px={3} pt={3}>
                        <label className="location-label-font">
                          Region Code
                        </label>
                        <p className="location-code-label  mt-2">
                          {common.textCapitalize(selectedItem?.RegionCode) ||
                            "-"}
                        </p>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      className="border-dynamic"
                    >
                      <Box px={4} pt={3}>
                        <label className="location-label-font">
                          Postal Code
                        </label>
                        <p className="location-code-label mt-2">
                          {common.textCapitalize(selectedItem?.PostalCode) ||
                            "-"}
                        </p>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      className="border-dynamic border-dynamic-right"
                    >
                      <Box px={3} pt={3}>
                        <label className="location-label-font">
                          Telephone No
                        </label>
                        <p className="location-code-label  mt-2">
                          {selectedItem?.TelNumber1 || "-"}
                        </p>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      className="border-dynamic"
                    >
                      <Box px={4} pt={3}>
                        <label className="location-label-font">
                          Alternative Telephone No
                        </label>
                        <p className="location-code-label  mt-2">
                          {selectedItem?.TelNumber2 || "-"}
                        </p>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      className={`border-dynamic border-dynamic-right customer-tab-li`}
                    >
                      <Box px={3} pt={3}>
                        <label className="location-label-font">
                          Bill to location
                        </label>
                        <p className="location-code-label  mt-2">
                          {" "}
                          <ActiveSwitch
                            checked={selectedItem?.IsBillToLocation === "Y"}
                          />
                        </p>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      className={`border-dynamic customer-tab-li`}
                    >
                      <Box px={4} pt={3}>
                        <label className="location-label-font">Hub</label>
                        <p>
                          {" "}
                          <ActiveSwitch checked={selectedItem?.IsHub === "Y"} />
                        </p>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      className={`border-dynamic border-dynamic-right customer-tab-li`}
                    >
                      <Box px={3} pt={3}>
                        <label className="location-label-font">
                          Legal Address
                        </label>
                        <p className="location-label-font mt-2">
                          {" "}
                          <ActiveSwitch
                            checked={selectedItem?.IsLegalAddress === "Y"}
                          />
                        </p>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      className={`border-dynamic customer-tab-li`}
                    >
                      <Box px={4} pt={3}>
                        <label className="location-label-font">
                          Ship to Location
                        </label>
                        <p className="location-label-font mt-2">
                          {" "}
                          <ActiveSwitch
                            checked={selectedItem?.IsShipToLocation === "Y"}
                          />
                        </p>
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            </>
          ) : (
            <StatusBar status={isLoading ? "process" : "empty"} />
          )}
        </div>
      </div>
    </Box>}
    </React.Fragment>
   
  );
}

export { Location };
