import { Box, Grid, Paper } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { common } from "helpers";

const VendorTab = () => {
  return (
    <>
      <Box mt={5} px={5}>
        <Grid container spacing={2}>
          <Grid item  xs={12} sm={12} md={6}>
            <Paper variant="contained" square className="main-content-wrap">
              <Link to="/setting/location" className="link-text">
                <Box className="contents-padding activate-arrow ">
                  <Grid container>
                    <Grid item  xs={11}>
                      <Box>
                        <label className="setting-config-tab-titles">
                          Locations
                        </label>
                        <p className="setting-card-content">
                          Add or remove locations related to your business or
                          company
                        </p>
                      </Box>
                    </Grid>
                    <Grid item  xs={1}  className="padding-zero changeArrow">
                          <Link to="/setting/location">
                            <img
                              alt="next"
                              src={common.loadImg(
                                "iconly-light-arrow-next-circle.svg"
                              )}
                            />
                            <img
                              alt="next"
                              src={common.loadImg("next-activeArrow.svg")}
                              className="arrowActive"
                            />
                          </Link>
                        </Grid>
                      
                  </Grid>
                </Box>
              </Link>
            </Paper>
          </Grid>
          <Grid item  xs={12} sm={12} md={6}>
            <Paper variant="contained" square className="main-content-wrap">
              <Link to="#" className="link-text">
                <Box className="contents-padding activate-arrow">
                  <Grid container>
                    <Grid item xs={11}>
                      <Box>
                        <label className="setting-config-tab-titles">
                          Parties
                        </label>
                        <p className="setting-card-content">
                          Add or remove parties related to your business or
                          company
                        </p>
                      </Box>
                    </Grid>
                    <Grid item xs={1}  className="padding-zero changeArrow">
                          <Link to="#">
                            <img
                              alt="next"
                              src={common.loadImg(
                                "iconly-light-arrow-next-circle.svg"
                              )}
                            />
                            <img
                              alt="next"
                              src={common.loadImg("next-activeArrow.svg")}
                              className="arrowActive"
                            />
                          </Link>
                        </Grid>
                      
                  </Grid>
                </Box>
              </Link>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Paper variant="contained" square  className="main-content-wrap">
              <Link to="/setting/customer" className="link-text">
                <Box className="contents-padding activate-arrow ">
                  <Grid container>
                    <Grid item xs={11}>
                      <Box>
                        <label className="setting-config-tab-titles">
                          Customer
                        </label>
                        <p className="setting-card-content">
                          Add or remove customers related to your business or
                          company
                        </p>
                      </Box>
                    </Grid>
                    <Grid item xs={1}  className="padding-zero changeArrow">
                          <Link to="/setting/customer">
                            <img
                              alt="next"
                              src={common.loadImg(
                                "iconly-light-arrow-next-circle.svg"
                              )}
                            />
                            <img
                              alt="next"
                              src={common.loadImg("next-activeArrow.svg")}
                              className="arrowActive"
                            />
                          </Link>
                        </Grid>
                    </Grid>
                </Box>
              </Link>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Paper variant="contained" square className="main-content-wrap">
              <Link to="#" className="link-text">
                <Box className="contents-padding activate-arrow">
                  <Grid container>
                    <Grid item xs={11}>
                      <Box>
                        <label className="setting-config-tab-titles">
                          Product & Services
                        </label>
                        <p className="setting-card-content">
                          Add or remove Products & Services related to your
                          business or company
                        </p>
                      </Box>
                    </Grid>
                    <Grid item xs={1}  className="padding-zero changeArrow">
                          <Link to="/setting/customer">
                            <img
                              alt="next"
                              src={common.loadImg(
                                "iconly-light-arrow-next-circle.svg"
                              )}
                            />
                            <img
                              alt="next"
                              src={common.loadImg("next-activeArrow.svg")}
                              className="arrowActive"
                            />
                          </Link>
                        </Grid>
                      
                  </Grid>
                </Box>
              </Link>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default VendorTab;
