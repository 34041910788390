import React, { memo } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Divider,
  FormControl,
  Grid,
  Hidden,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { common } from "helpers";

// import Plus from "../../assets/img/kanban/plus.svg";
import CardsView from "./cardsView";
import OverView from "./overView";
import ListView from "./listView";
import TaskAdd from "./add";
import QuickAdd from "elements/QuickAdd";
import { styled } from "@mui/material/styles";

const DrawerHeader1 = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  padding: theme.spacing(2, 3),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const TasksView = memo(() => {
  const [value, setValue] = React.useState("1");
  const [age, setAge] = React.useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeItem = (event) => {
    setAge(event.target.value);
  };
  return (
    <div className="default-bg">
      <Box
        sx={{ width: "100%", typography: "body1" }}
        className="primary-header-container"
      >
        <TabContext value={value}>
          <Paper variant="container" square className="fixed-header" id="fixed-header">
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", lg: "row" },
                justifyContent: { xs: "center", lg: "space-between" },
                itemsCenter: { xs: "center" },
                py: { xs: 5, lg: 0 },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", lg: "row" },
                  justifyContent: { xs: "center", lg: "space-between" },
                  itemsCenter: { xs: "center" },
                }}
                alignItems="center"
                px={3}
                className="tabs"
              >
                <Box display="flex" alignItems="center">
                  <Box>
                    <img
                      src={common.loadImg("outlineCRM-chart.svg")}
                      alt="img"
                    />
                  </Box>
                  &nbsp;&nbsp;
                  <Typography className="header-crm">Tasks</Typography>
                  <Hidden smDown>
                    <Box className="vertical-divider ml-4">
                      <Divider orientation="vertical" />
                    </Box>
                  </Hidden>
                </Box>
                <TabList onChange={handleChange} className="ml-5 tabs-list-ph">
                  <Tab label="Overview" value="1" />
                  <Tab label="Cards View" value="2" />
                  <Tab label="List View" value="3" />
                </TabList>
              </Box>
              <Box>
                <Grid
                  container
                  sx={{
                    justifyContent: { xs: "center" },
                  }}
                >
                  <Grid item>
                    <FormControl
                      sx={{ m: 1, minWidth: { xs: 220, lg: 120 } }}
                      size="small"
                      className="dropdown"
                    >
                      <Select
                        value={age}
                        displayEmpty
                        onChange={handleChangeItem}
                      >
                        <MenuItem disabled value="" className="active-item">
                          <em className="filter-text">View all</em>
                        </MenuItem>
                        <MenuItem value={10} className="filter-text">
                          Ten
                        </MenuItem>
                        <MenuItem value={20} className="filter-text">
                          Twenty
                        </MenuItem>
                        <MenuItem value={30} className="filter-text">
                          Thirty
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl
                      sx={{ m: 1, minWidth: 220 }}
                      size="small"
                      className="dropdown"
                    >
                      <Select value={age} displayEmpty onChange={handleChange}>
                        <MenuItem disabled value="">
                          <em className="filter-text">Sort by date</em>
                        </MenuItem>
                        <MenuItem value={10} className="filter-text">
                          Ten
                        </MenuItem>
                        <MenuItem value={20} className="filter-text">
                          Twenty
                        </MenuItem>
                        <MenuItem value={30} className="filter-text">
                          Thirty
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              {/*<Box
                className="plus"
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", lg: "start" },
                }}
                py={1}
                px={4}
              >
                <IconButton size="large" onClick={(e) => handleChange(e, "4")}>
                  <img src={common.loadImg("plus.svg")} alt="plus" />
                </IconButton>
              </Box> */ }
                <QuickAdd />
            </Box>
          </Paper>
          <DrawerHeader1 className="report-header"/>
          <TabPanel value="1">
            <Box px={2}>
              <OverView />
            </Box>
          </TabPanel>
          <TabPanel value="2">
            <Box px={2}>
              <CardsView />
            </Box>
          </TabPanel>
          <TabPanel value="3">
            <ListView />
          </TabPanel>
          <TabPanel value="4">
            <TaskAdd />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
});

export default TasksView;
