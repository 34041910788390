import React, { useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment";
import { api, common, envConfig } from "helpers";
import { CRM_CONTACTS, CRM_DEAL_OPPORTUNITY } from "helpers/service";
import StatusBar from "elements/StatusBar";

export default function DealList(props) {
  let userData = common.authInfo();
  const [isDealLoading, setIsDealLoading] = useState(true);
  const [isContactLoading, setIsContactLoading] = useState(true);
  const [dealList, setDealList] = useState([]);
  const [contactList, setContactList] = useState([]);

  React.useEffect(() => {
    if (props?.module == "Account") {
      //Deal
      setIsDealLoading(true);
      let deal_url = `${envConfig.BASE_API}${CRM_DEAL_OPPORTUNITY}?q=TenantId=${userData.TenantId} AND AccountId=${props.id}&limit=100&offset=0`;
      let data = {
        url: deal_url,
        type: "dynamic",
        method: "GET",
        auth: "token",
        moreHead: { rfv: 2 },
        cType: 4,
      };

      api.call(
        data,
        (response) => {
          if (response) {
            let result = response.data;
            setIsDealLoading(false);
            setDealList(result.items);
          }
        },
        (error) => {},
        (final) => {}
      );
      //contact
      setIsContactLoading(true);
      let contact_url = `${envConfig.BASE_API}${CRM_CONTACTS}?q=TenantId=${userData.TenantId} AND Nattr10=${props.id}&limit=100&offset=0`;
      let data1 = {
        url: contact_url,
        type: "dynamic",
        method: "GET",
        auth: "token",
        moreHead: { rfv: 2 },
        cType: 4,
      };

      api.call(
        data1,
        (response) => {
          if (response) {
            let result = response.data;
            setIsContactLoading(false);
            setContactList(result.items);
          }
        },
        (error) => {},
        (final) => {}
      );
    } else if (props?.module == "Contact") {
      //Deal
      setIsDealLoading(true);
      let deal_url = `${envConfig.BASE_API}${CRM_DEAL_OPPORTUNITY}?q=TenantId=${userData.TenantId} AND Nattr10=${props.id}&limit=100&offset=0`;
      let data = {
        url: deal_url,
        type: "dynamic",
        method: "GET",
        auth: "token",
        moreHead: { rfv: 2 },
        cType: 4,
      };

      api.call(
        data,
        (response) => {
          if (response) {
            let result = response.data;
            setIsDealLoading(false);
            setDealList(result.items);
          }
        },
        (error) => {},
        (final) => {}
      );
    }
  }, [props]);

  return (
    <TableContainer className="relative-position">
      {props?.module == "Account" && (
        <div className="card-custom mt-2">
          <h5 className="right-section-title my-1 ms-3">Contacts</h5>
          {isContactLoading ? (
            <StatusBar status={isContactLoading ? "process" : "empty"} />
          ) : (
            <Table
              stickyHeader
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"medium"}
              className="osmo-crm-table "
            >
              <TableHead>
                <TableRow>
                  <TableCell align={"left"} className="crm-table-header">
                    <Grid container spacing={2}>
                      <Grid item>
                        <Box className="table-head">
                          <div className="sorting-cell">
                            <div>First Name</div>
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell align={"left"} className="crm-table-header">
                    <Grid container spacing={2}>
                      <Grid item>
                        <Box className="table-head">
                          <div className="sorting-cell">
                            <div>Last Name</div>
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell align={"left"} className="crm-table-header">
                    <Grid container spacing={2}>
                      <Grid item>
                        <Box className="table-head">
                          <div className="sorting-cell">
                            <div>Email</div>
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell align={"left"} className="crm-table-header">
                    <Grid container spacing={2}>
                      <Grid item>
                        <Box className="table-head">
                          <div className="sorting-cell">
                            <div>Mobile</div>
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell align={"left"} className="crm-table-header">
                    <Grid container spacing={2}>
                      <Grid item>
                        <Box className="table-head">
                          <div className="sorting-cell">
                            <div>CreatedOn</div>
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contactList &&
                  contactList.length > 0 &&
                  contactList.map((contact) => {
                    return (
                      <TableRow>
                        <TableCell>
                          <Link
                            to={"/crm/contacts/details/" + contact.ContactId}
                            target="_blank"
                          >
                            {contact.FirstName}
                          </Link>
                        </TableCell>
                        <TableCell>{contact.LastName}</TableCell>
                        <TableCell>{contact.Email}</TableCell>
                        <TableCell>{contact.Mobile}</TableCell>
                        <TableCell>
                          {moment(contact.CreatedOn).format("DD-MM_YYYY")}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {contactList && contactList.length == 0 && (
                  <TableRow>
                    <TableCell colSpan={4}>No records</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </div>
      )}
      <div className="card-custom mt-2">
        <h5 className="right-section-title my-1 ms-3">Deals</h5>
        {isDealLoading ? (
          <StatusBar status={isDealLoading ? "process" : "empty"} />
        ) : (
          <Table
            stickyHeader
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
            className="osmo-crm-table "
          >
            <TableHead>
              <TableRow>
                <TableCell align={"left"} className="crm-table-header">
                  <Grid container spacing={2}>
                    <Grid item>
                      <Box className="table-head">
                        <div className="sorting-cell">
                          <div>Deal Name</div>
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align={"left"} className="crm-table-header">
                  <Grid container spacing={2}>
                    <Grid item>
                      <Box className="table-head">
                        <div className="sorting-cell">
                          <div>Deal Status</div>
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align={"left"} className="crm-table-header">
                  <Grid container spacing={2}>
                    <Grid item>
                      <Box className="table-head">
                        <div className="sorting-cell">
                          <div>Responsible Person</div>
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align={"left"} className="crm-table-header">
                  <Grid container spacing={2}>
                    <Grid item>
                      <Box className="table-head">
                        <div className="sorting-cell">
                          <div>Account Name</div>
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align={"left"} className="crm-table-header">
                  <Grid container spacing={2}>
                    <Grid item>
                      <Box className="table-head">
                        <div className="sorting-cell">
                          <div>CreatedOn</div>
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dealList &&
                dealList.length > 0 &&
                dealList.map((deal) => {
                  return (
                    <TableRow>
                      <TableCell>
                        <Link
                          to={"/crm/deal/details/" + deal.OpportunityId}
                          target="_blank"
                        >
                          {deal.OpportunityName}
                        </Link>
                      </TableCell>
                      <TableCell>{deal.ApprStatus}</TableCell>
                      <TableCell>{deal.SalesPersonIdDesc}</TableCell>
                      <TableCell>{deal.AccountIdDesc}</TableCell>
                      <TableCell>
                        {moment(deal.CreationDate).format("DD-MM_YYYY")}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {dealList && dealList.length == 0 && (
                <TableRow>
                  <TableCell colSpan={4}>No records</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </div>
    </TableContainer>
  );
}
