import React, { useEffect, useState } from 'react';
import { Form, Button } from "react-bootstrap";
import AmlHashForm from "aml-hash-form";
import Select from 'react-select';
import FieldSelect from "elements/FieldSelect";
// material
import {
  Checkbox,
} from "@mui/material";
import FormControlLabel from '@material-ui/core/FormControlLabel';
// confirm
import "react-confirm-alert/src/react-confirm-alert.css";
// includes
import { common } from "helpers";
// dialog
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

function TextModal(props) {
  const [status, setStatus] = useState("process");
  // form
  const formFields = {
    description: { validate: ["req"] },
    templateId: { validate: ["objValReq#Please select the field"], obj: "value" },
    // toUser: { validate: ["objValReq#Please select the field"], obj: "value" },
    toUser: { validate: [] },
    alterToUserField: { validate: [] },
    scheduled: { validate: [] },
    scheduledNo: { validate: ["req", "gte:0"], parent: { field: "scheduled", value: "Y" } },
    scheduledType: { validate: ["req"], parent: { field: "scheduled", value: "Y" } },
  }
  const { fields, errors, setValue, setMultiValue, handleChange, handleSubmit } = AmlHashForm(formFields);

  // effect
  useEffect(() => {
    setMultiValue(props.data);
    setStatus("completed");
  }, [])

  // handler
  const onSubmit = () => {
    let isValid = handleSubmit();
    if (isValid) {
      props.onSuccess(common.reParse(fields));
    }
  }

  return (
    <div className="target-wrap" style={{ minHeight: "800px" }}>
      {status === "completed" && (
        <div>
          <Dialog
            open={true}
            aria-labelledby="responsive-dialog-title"
            maxWidth={"sm"}
            fullWidth={true}
            className="target-modal-wrap add-action-popup"
          //  disableBackdropClick={true}
            disableScrollLock
          >
            <DialogTitle id="responsive-dialog-title" className="popup-title">Text Notification</DialogTitle>
            <DialogContent>
              <div>
                <div className="popup-form-wrap automation-dropdown-form-wrap">
                  <div className="row">
                    <div className="col-md-12">
                      <label>Name<span className="mandtory-class">*</span></label>
                      <input
                        type="text"
                        name="description"
                        className="form-control"
                        placeholder="Enter the name"
                        value={fields.description}
                        onChange={handleChange}
                      />
                      <Form.Text className="error error-txt">{errors.description}</Form.Text>
                    </div>
                  </div>

                  {/* <div className="row">
                    <div className="col-md-12 mb-4">
                      <label>To Users</label>
                      <FieldSelect
                        api="CRM_EMPLOYEES"
                        get="EmployeeName"
                        set="MobileNo"
                        query="MobileNo > 0"
                        value={fields.toUser}
                        isMulti={true}
                        isRender={true}
                        onSelect={(data) => setValue("toUser", data)}
                      />
                      <Form.Text className="error">{errors.toUser}</Form.Text>
                    </div>
                  </div> */}

                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <label>To User</label>
                      <Select
                        isMulti
                        closeMenuOnSelect={false}
                        options={props.columns}
                        value={fields.alterToUserField}
                        onChange={(data) => setValue("alterToUserField", data)}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12  common-select-border">
                      <label>Text Template<span className="mandtory-class">*</span></label>
                      <FieldSelect
                        api="CHOLA_EMAIL_TEMPLATE"
                        get="TemplateName"
                        set="EmailTemplateId"
                        query={`TemplateType='TEXT' AND Category='${props.module}'`}
                        value={fields.templateId}
                        isMulti={false}
                        isRender={true}
                        onSelect={(data) => setValue("templateId", data)}
                        placeholder="Text Template"
                      />
                      <Form.Text className="error error-txt">{errors.templateId}</Form.Text>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 mb-2">
                      <FormControlLabel
                        name="scheduled"
                        className="m-0"
                        value="Y#N"
                        control={<Checkbox color="primary" />}
                        label={"Scheduled"}
                        labelPlacement="end"
                        checked={fields.scheduled === "Y" ? true : false}
                        onClick={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-2">
                      <label>Scheduled Value</label>
                      <input
                        name="scheduledNo"
                        type="number"
                        className="form-control"
                        value={fields.scheduledNo}
                        onChange={handleChange}
                      />
                      <Form.Text className="error">{errors.scheduledNo}</Form.Text>
                    </div>
                    <div className="col-md-6 mb-2">
                      <label>Scheduled Type</label>
                      <select
                        name="scheduledType"
                        className="form-control default-select"
                        value={fields.scheduledType}
                        onChange={handleChange}
                      >
                        <option value="">None</option>
                        <option value="MIA">Minute(s)</option>
                        <option value="HA">Hour(s)</option>
                        <option value="DA">Day(s)</option>
                      </select>
                      <Form.Text className="error error-txt form-text">{errors.scheduledType}</Form.Text>
                    </div>
                  </div>
                </div>
              </div>
            </DialogContent>
            <DialogActions className="popup-footer justify-content-start">
              <div className="botBtn">
                <div>
                  <Button
                    className="automation-footer-btn"
                    onClick={onSubmit}
                    type="submit"
                  >Save</Button>
                  <Button
                    className="automation-cancel-btn"
                    onClick={() => props.onCancel()}
                    type="button"
                  >Cancel</Button>
                </div>
              </div>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </div>
  )
}

export default TextModal;