import React, { memo, useEffect, useRef, useState } from "react";
import { common, envConfig, api } from "helpers";
import { useParams, useOutletContext } from "react-router-dom";
import * as Services from "helpers/service";
import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import StatusBar from "./StatusBar";
import PopupLoader from "./PopupLoader";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { DialogTitle } from "@material-ui/core";
import { IconButton } from "@mui/material";

const Attachment = memo(({ handleExpanded, isExpand }) => {
  let { mod, id } = useParams();
  const [permission] = useOutletContext();
  const accAdd = true; //!common.givenPermission(
  //   permission,
  //   common.ModToKey(mod),
  //   "add"
  // );
  const accDelete = true; //!common.givenPermission(
  //   permission,
  //   common.ModToKey(mod),
  //   "delete"
  // );
  const accExport = true; //!common.givenPermission(
  //   permission,
  //   common.ModToKey(mod),
  //   "export"
  // );
  const fileUploadRef = useRef(null);
  const listInnerRef = useRef();
  const userInfo = common.userInfo();
  const [fields, setFields] = useState({
    ObjectId: "",
    ObjectType: "",
    FileName: "",
    FileType: "",
    FileSize: null,
    TenantId: userInfo.pTenantId,
    OrgId: userInfo.pOrgId,
  });
  const [listResponse, setListResponse] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageLimit, setPageLimit] = useState(5);
  const [orderBy, setOrderBy] = useState("CreatedOn");
  const [status, setStatus] = useState("");
  const [uploadProgress, setUploadProgress] = useState(false);
  const [previewImgFile, setPreviewImgFile] = useState("");
  const [imgPreview, setImgPreview] = useState(false);
  const [imgBlob, setImgBlob] = useState({});
  const [imgLoading, setImgLoading] = useState(false);
  const [hasMore, setHasMore] = useState(null);
  const [isFileLoading, setIsFileLoading] = useState(false);

  useEffect(() => {
    fields["ObjectId"] = id;
    fields["ObjectType"] = mod;
    setFields(fields);
    getFiles(0, "init");
  }, []);

  useEffect(() => {
    if (listResponse.length > 0) {
      fetchImage();
    }
  }, [listResponse]);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight && hasMore) {
        getFiles(currentPage + 1, "scroll");
      }
    }
  };

  /**
   * Get all files from DB
   */
  const getFiles = (pageNo, type) => {
    setCurrentPage(pageNo);
    if (type && type == "scroll") {
      setIsFileLoading("process");
    } else {
      setStatus("process");
    }
    let filter = `?q=TenantId=${userInfo.pTenantId} AND OrgId=${
      userInfo.pOrgId
    } AND ObjectId=${
      fields["ObjectId"]
    }&totalResults=true&orderBy=${orderBy}:desc&limit=${pageLimit}&offset=${
      pageNo * pageLimit
    }`;

    let url = encodeURI(envConfig.BASE_API + Services.CRM_FILES + filter);
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
      moreHead: { rfv: 2 },
      cType: 4,
    };
    api.call(
      data,
      (response) => {
        if (response.status == 200) {
          setHasMore(response.data.hasMore);
          if (type && type == "scroll") {
            setListResponse((oldData) => {
              return [...new Set([...oldData, ...response.data.items])];
            });
          } else {
            setListResponse(response.data.items);
          }
          if (response.data.items && response.data.items.length > 0) {
            setStatus("success");
            setIsFileLoading("success");
          } else {
            setStatus("empty");
          }
        }
      },
      (error) => {
        setStatus("error");
      },
      (final) => {}
    );
  };

  const fetchImage = () => {
    let filteredLists =
      listResponse &&
      listResponse.length > 0 &&
      listResponse.filter((data) => {
        return data.FileType.includes("image");
      });

    filteredLists &&
      filteredLists.length > 0 &&
      filteredLists.map((data, index) => {
        let url = envConfig.SERVLET_URL + "?imagePath=" + data.FilePath;
        let val = {
          url: url,
          type: "dynamic",
          method: "GET",
          auth: "token",
        };

        api.fileCall(
          val,
          (response) => {
            let url = window.URL.createObjectURL(response);
            imgBlob[data.AttachmentFileId] = url;
          },
          (error) => {},
          (final) => {}
        );
      });
    setImgBlob(imgBlob);
  };

  const downloadFile = (e, fileURL, fileName, fileType) => {
    e.preventDefault();
    let token = localStorage.getItem("userToken");

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      envConfig.DOWNLOAD_URL + fileURL + "&download=Y",
      requestOptions
    ).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
      });
    });
  };

  /**
   * Delete uploaded files
   */
  const deleteFile = (id) => {
    common.confirmDelete(true, (response) => {
      // setStatus("process");
      handleDeleteBlock(id);
    });
  };

  /**
   * Delete API call
   */
  const handleDeleteBlock = (id) => {
    let url = envConfig.BASE_API + Services.CRM_FILES + "/" + id;

    let data = {
      url: url,
      type: "dynamic",
      method: "DELETE",
      auth: "token",
    };
    api.call(
      data,
      (response) => {
        if (response.status == 204) {
          common.snack("S", "Selected item has been deleted successfully.");
          let responseList = listResponse;
          let updateData = [];
          if (responseList != null && responseList != null) {
            updateData = responseList.filter((file) => {
              return id !== file.AttachmentFileId;
            });
            responseList = updateData;
          }
          setListResponse(responseList);
          if (responseList.length == 0) {
            setStatus("empty");
          }
          // getFiles(0, "delete");
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  const handleFileUpload = (e) => {
    if (fileUploadRef?.current) {
      fileUploadRef?.current?.click();
    }
  };

  const onFileChange = (event) => {
    const file = event.target.files[0];
    fields.FileName = file.name;
    fields.FileType = file.type;
    fields.FileSize = file.size;
    setFields(fields);
    if (file.size > 0 && file.size < 1e7) {
      //1e+7 - Max 10MB file allowed
      common.getBase64(file, (result) => {
        // setFileBase64Content(result);
        setUploadProgress(true);
        uploadFile(result);
      });
    } else {
      common.snack("E", "Limited file size 10 MB allowed");
    }
  };

  /**
   * Upload files to server
   */
  const uploadFile = (base64) => {
    let url = envConfig.BASE_API + Services.CRM_FILES;
    let data = {
      url: url,
      type: "dynamic",
      method: "POST",
      auth: "token",
      body: JSON.stringify(fields),
      cType: 4,
    };
    // return
    api.call(
      data,
      (response) => {
        if (response.status == 201) {
          common.snack("S", "File uploaded successfully");
          if (response.data.AttachmentFileId != null && base64 != null) {
            let updateUrl =
              envConfig.BASE_API +
              Services.CRM_FILES +
              "/" +
              response.data.AttachmentFileId;

            let updateData;

            updateData = {
              FileImageBlob: base64,
            };
            let data = {
              url: updateUrl,
              type: "dynamic",
              method: "PATCH",
              auth: "token",
              body: JSON.stringify(updateData),
              cType: 4,
            };
            // return
            api.call(
              data,
              (response) => {
                if (response.status == 200) {
                  getFiles(0, "init");
                  setUploadProgress(false);
                  // getFiles(0, "uploading");
                }
              },
              (error) => {
                common.snack("E", "Error in file uploading");
                setUploadProgress(false);
              },
              (final) => {}
            );
          }
        }
      },
      (error) => {
        common.snack("E", "Error in file uploading");
        setUploadProgress(false);
      },
      (final) => {}
    );
  };

  const previewImage = (e, fileId) => {
    e.preventDefault();
    setImgPreview(true);
    setPreviewImgFile(fileId);
  };

  const handleClose = () => {
    setImgPreview(false);
    setPreviewImgFile("");
  };

  return (
    <Box className="row" style={{ backgroundColor: "white" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box className="crm-submenu-wrap">
          <Grid
            container
            spacing={2}
            alignItems="center"
            className="subheader-elements"
          >
            <Grid item>
              {isExpand === "detail" ? (
                <Typography
                  className="expand pointer"
                  onClick={() => handleExpanded("tab")}
                >
                  Expand{" "}
                  <img src={common.loadImg("expandkanban.svg")} alt="expand" />
                </Typography>
              ) : (
                <Typography
                  className="expand pointer"
                  onClick={() => handleExpanded("detail")}
                >
                  Collapse{" "}
                  <img
                    src={common.loadImg("expandkanban.svg")}
                    alt="Collapse"
                  />
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
      {accAdd && (
        <>
          <div className="upload-section-box mt-4 mb-2">
            <div
              className="upload-section"
              onClick={(e) => handleFileUpload(e)}
            >
              <div className="d-flex justify-content-between">
                <div>
                  <div className="upload-or-attach-file">
                    Upload or Attach a File
                  </div>
                  <div className="mt-1 drag-nd-drop-file">
                    Click here to upload
                  </div>
                </div>

                <img
                  alt={"back"}
                  className="mx-1"
                  src={common.loadImg("attachement-upload.svg")}
                  width={30}
                  height={30}
                />
              </div>
            </div>

            <input
              ref={fileUploadRef}
              type="file"
              className="d-none"
              onChange={(e) => onFileChange(e)}
              accept="image/*,.pdf,.doc,.docx,.xls,.xlsx"
            />
          </div>

          <div className="file-size-txt">
            <p className="error-txt show">Maximum File size is 10MB</p>
          </div>
        </>
      )}
      <div className="d-flex align-items-center justify-content-center bottom-border-divider pb-3">
        <label className="customer-file-header">
          <b>Files</b>{" "}
          {listResponse && listResponse.length > 0 && (
            <span className="location-count-label">{listResponse.length}</span>
          )}
        </label>
      </div>
      <StatusBar status={status} />
      <div
        className="attachment-wrap-list"
        onScroll={onScroll}
        ref={listInnerRef}
      >
        {status == "success" &&
          listResponse &&
          listResponse.length > 0 &&
          listResponse.map((item, index) => {
            return (
              <div
                key={index}
                className="d-flex align-items-center justify-content-center file-list bottom-border-divider mt-4 pb-4"
              >
                <div className="customer-file-header">
                  <Grid container direction="row" spacing={1}>
                    <Grid item xs={6}>
                      <Box display="flex" flexDirection="column">
                        <u className="file-name-border">
                          <span className="file-name">{item.FileName}</span>
                        </u>
                        <span className="file-timeline">
                          {common.formatDateAMPM(item.CreatedOn, "LL", false)}
                        </span>
                      </Box>
                    </Grid>
                    <Grid item xs={6} className="attch-actions">
                      {item.FileType.includes("image") && (
                        <div
                          className="d-flex align-items-center crm-cp"
                          onClick={(e) =>
                            previewImage(e, item.AttachmentFileId)
                          }
                        >
                          <div className="view-file-text">View</div>
                          <img
                            alt={"view"}
                            className="mx-1 pointer"
                            src={common.loadImg("view-file-img.svg")}
                            width={23}
                            height={20}
                          />
                        </div>
                      )}
                      {accDelete && (
                        <div
                          className="d-flex align-items-center crm-cp"
                          onClick={(e) => deleteFile(item.AttachmentFileId)}
                        >
                          <div className="view-file-text">Delete</div>
                          <img
                            alt={"view"}
                            className="mx-1 pointer"
                            src={common.loadImg("blue-delete-icon.svg")}
                            width={23}
                            height={20}
                          />
                        </div>
                      )}
                      {accExport && item.FilePath != null && (
                        <div
                          className="d-flex align-items-center crm-cp"
                          onClick={(e) =>
                            downloadFile(
                              e,
                              item.FilePath,
                              item.FileName,
                              item.FileType
                            )
                          }
                        >
                          <div className="view-file-text">Download</div>
                          <img
                            alt={"download"}
                            className="mx-1 pointer"
                            src={common.loadImg("attachment-download-img.svg")}
                            width={23}
                            height={20}
                          />
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </div>
              </div>
            );
          })}
        <StatusBar status={isFileLoading} />
      </div>
      {imgPreview && (
        <Dialog
          open={imgPreview}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          maxWidth={"sm"}
          fullWidth={true}
          className="target-modal-wrap add-action-popup attachment-popup"
          disableScrollLock
        >
          <DialogTitle
            id="responsive-dialog-title"
            className="popup-title py-2"
          >
            <div className="attach-popup-title">
              <div>Preview</div>
              <IconButton onClick={handleClose}>
                <img src={common.loadImg("filterCloseIcon.svg")} />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="popup-form-wrap imgDlgContent">
              <div className="list-row text-center">
                {imgLoading ? (
                  <StatusBar status={"process"} />
                ) : (
                  <img
                    src={imgBlob[previewImgFile] || null}
                    alt="image"
                    className="w-100"
                  />
                )}
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
      {uploadProgress && <PopupLoader />}
    </Box>
  );
});

export { Attachment };
