import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as Services from "helpers/service";

import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
  FormControl,
  TextField,
  getImageListItemBarUtilityClass,
} from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { common, envConfig, api, tools } from "helpers";
import { CRM_USERS } from "helpers/service";
import FieldLookup from "elements/FieldLookup";

import AmlHashForm from "aml-hash-form";
import { CropIt } from "./CropIt";
import { WindowSharp } from "@mui/icons-material";

function Profile() {
  let userData = common.authInfo();
  const userInfo = common.userInfo();
  const UserId = userData.UserId;
  const UserName = userData.UserName;
  const EmployeeName = userData.EmployeeName;
  const EmployeeId = userData.EmpId;
  const formFields = {
    firstName: {
      validate: ["req#First Name is required!"],
    },
    lastName: {
      validate: ["req#Last Name is required!"],
    },
    gender: {
      validate: ["req#Gender is required!"],
    },
    country: {
      validate: [],
    },
    mobile: {
      validate: [],
    },
    language: {
      validate: [],
    },
    timezone: {
      validate: ["req#Timezone is required!"],
    },
    UserFileType: "",
    UserFileName: "",
    UserPicPath: "",
    Nattr1: "",
  };
  const {
    fields,
    errors,
    setValue,
    setMultiValue,
    handleChange,
    handleSubmit,
  } = AmlHashForm(formFields);
  const [isLoader, setIsLoader] = useState(false);
  const [showImage, setShowImage] = useState(false);

  const [imgfields, setImgFields] = useState({
    ObjectId: userData.UserId,
    ObjectType: "user_pics",
    FileName: "",
    FileType: "",
    FileSize: null,
    TenantId: userInfo.pTenantId,
    OrgId: userInfo.pOrgId,
  });

  useEffect(() => {
    getUserInfo();
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    let isValid = handleSubmit();
    if (isValid) {
      setIsLoader(true);
      let params = JSON.stringify({
        FirstName: fields.firstName,
        LastName: fields.lastName,
        Gender: fields.gender.label || "",
        MobileNo: fields.mobile,
        DefaultLanguage: fields.language?.label || "",
        DefaultTimezone: fields.timezone?.label || "",
        DefaultLocation: fields.country?.label || "",
        UserFileName: fields.UserFileName || "",
        UserFileType: fields.UserFileType || "",
        UserPicPath: fields.UserPicPath || "",
        Nattr1: fields.Nattr1 || "",
      });
      let url = `${envConfig.BASE_API}${CRM_USERS}/${userData.UserId}`;
      let data = {
        url: url,
        type: "dynamic",
        method: "PATCH",
        auth: "token",
        cType: 4,
        body: params,
      };

      api.call(
        data,
        (res) => {
          if (res.status === 200) {
            let userData = tools.deCrypt(localStorage.getItem("userData"));
            if (userData != null) {
              userData["EmployeeName"] =
                res.data.FirstName + " " + res.data.LastName;
              userData["FirstName"] = res.data.FirstName;
              userData["LastName"] = res.data.LastName;
              userData["DefaultTimezone"] = res.data.DefaultTimezone;
              // userData["AlternativeEmailAddress1"] = response.AlternativeEmailAddress1;
              // userData["AlternativeEmailAddress2"] = response.AlternativeEmailAddress2;
              userData["ProfileImg"] =
                envConfig.IMG_SERVLET_URL +
                "?imageId=" +
                res.data.UserId +
                "&imageType=USER";
            }
            localStorage.setItem("userData", tools.crypt(userData));

            employeeProfileUpdate();

            common.snack("S", "Profile has been successfully updated");

            setTimeout(() => {
              window.location.href = "/profile";
            }, 500);
          } else {
            common.snack("E", "Profile not updated please check");
          }
          setCroppedImage(croppedImage);
        },
        (error) => {
          common.snack("E", "Profile not updated please check");
          setIsLoader(false);
        },
        (final) => {
          setIsLoader(false);
        }
      );
    }
  };

  /**
   * Get User details using user ID
   */
  const getUserInfo = () => {
    let url = `${envConfig.BASE_API}${CRM_USERS}/${userData.UserId}`;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };
    api.call(
      data,
      (response) => {
        if (response) {
          setMultiValue({
            firstName: response.data?.FirstName || "",
            lastName: response.data?.LastName || "",
            gender: response.data.Gender
              ? { label: response.data.Gender, value: response.data.Gender }
              : null,
            country: response.data.DefaultLocation
              ? {
                  label: response.data.DefaultLocation,
                  value: response.data.DefaultLocation,
                }
              : null,
            mobile: response.data.MobileNo || "",
            language: response.data.DefaultLanguage
              ? {
                  label: response.data.DefaultLanguage,
                  value: response.data.DefaultLanguage,
                }
              : null,
            timezone: response.data.DefaultTimezone
              ? {
                  label: response.data.DefaultTimezone,
                  value: response.data.DefaultTimezone,
                }
              : null,
            UserFileType: response.data.UserFileType || "",
            UserFileName: response.data.UserFileName || "",
            UserPicPath: response.data.UserPicPath || "",
            Nattr1: response.data.Nattr1 || "",
          });
        } else {
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  const [isImageUpload, setIsImageUpload] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [fileBase64Content, setFileBase64Content] = useState("");
  //Upload image
  let channelImageCroper = React.useRef(null);
  let channelImageFile = React.useRef(null);

  const handleClick = (event) => {
    channelImageFile.current.click();
  };

  // Crop and Upload functions
  const cropItUpload = (e, type) => {
    let cropSize = {};
    cropSize = { width: 200, height: 210 };
    channelImageCroper.onFileChange(e, cropSize);
    const file = e.target.files[0];
    imgfields.FileName = file.name;
    imgfields.FileType = file.type;
    imgfields.FileSize = file.size;
    setImgFields(imgfields);
    common.getBase64(file, (result) => {
      setFileBase64Content(result);
    });
  };

  const cropComplete = (data, type) => {
    if (data.status == "success") {
      //tempary
      setIsImageUpload(true);
      setCroppedImage(data.croppedImage);
      var getimagepath = data.croppedImage.split(",");

      //  uploadFile(fileBase64Content);
      uploadFile(getimagepath[1]);
      channelImageFile.current.value = "";
      //
    }
  };

  /**
   * Upload files to server
   */
  const uploadFile = (base64img) => {
    let url = envConfig.BASE_API + Services.CRM_FILES;
    let data = {
      url: url,
      type: "dynamic",
      method: "POST",
      auth: "token",
      body: JSON.stringify(imgfields),
      cType: 4,
    };
    // return
    api.call(
      data,
      (response) => {
        if (response.status == 201) {
          // common.snack("S", "File uploaded successfully");
          if (response.data.AttachmentFileId != null && base64img != null) {
            fields.Nattr1 = response.data.AttachmentFileId;
            let updateUrl =
              envConfig.BASE_API + Services.CRM_FILES + "/" + fields.Nattr1;

            let updateData;

            updateData = {
              FileImageBlob: base64img,
            };
            let data = {
              url: updateUrl,
              type: "dynamic",
              method: "PATCH",
              auth: "token",
              body: JSON.stringify(updateData),
              cType: 4,
            };
            // return
            api.call(
              data,
              (response) => {
                if (response.status == 200) {
                  // common.snack("S", "uploaded successfully");
                  fields.UserFileName = response.data.FileName;
                  fields.UserFileType = response.data.FileType;
                  fields.UserPicPath = response.data.FilePath;

                  // getshowImage(img.data);

                  // getFiles(0, "init");
                  // setUploadProgress(false);
                  // // getFiles(0, "uploading");
                }
              },
              (error) => {
                common.snack("E", "Error in file uploading");
                // setUploadProgress(false);
              },
              (final) => {}
            );
          }
        }
      },
      (error) => {
        common.snack("E", "Error in file uploading");
        // setUploadProgress(false);
      },
      (final) => {}
    );
  };

  // Employee update
  const employeeProfileUpdate = () => {
    let url = encodeURI(
      envConfig.BASE_API + Services.CRM_PAY_EMPLOYEE + "/" + EmployeeId
    );
    let params = {
      ProfilePicPath: fields.UserPicPath,
      ProfileFileType: fields.UserFileType,
      ProfileFileName: fields.UserFileName,
      FirstName: fields.firstName,
      LastName: fields.lastName,
      MobileNo: fields.mobileNo,
      Gender: fields.gender,
      Country: fields.country,
    };
    let data = {
      url: url,
      type: "dynamic",
      method: "PATCH",
      auth: "token",
      body: JSON.stringify(params),
      cType: 4,
    };
    api.call(
      data,
      (response) => {
        if (response.status == 200) {
          console.log("sucess");
        }
      },
      (error) => {},
      (final) => {}
    );
  };
  return (
    <div style={{ padding: "20px" }}>
      <Paper
        sx={{ width: "100%", mb: 2 }}
        className={`container responsive-table p-0`}
      >
        <Box>
          <Paper variant="contained dynamic-form-wrap" square>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item className="ps-5">
                    <Typography className="detail-title">
                      Profile Information
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item className="safari-style">
                <Box px={2} className="safari-style">
                  <Grid
                    container
                    alignItems="center"
                    spacing={2}
                    className="safari-style"
                  >
                    <Grid item>
                      <Box className="verical-divider">
                        <Divider orientation="vertical" />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box>
                        <Button
                          className="save-note"
                          fullWidth
                          onClick={(e) => onSubmit(e)}
                        >
                          {isLoader ? "Processing" : "Save Changes"}
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box className="vertical-divider">
                        <Divider orientation="vertical" />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box className="revert-close">
                        <Button className="save-note pe-0">
                          <Link to="#">
                            Revert and Close
                            <img
                              src={common.loadImg("closeKanban.svg")}
                              alt="close"
                            />
                          </Link>
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Box>
              <Divider />
            </Box>
            <Box mt={2} className="camp-edit meet-table">
              <Row className="m-0">
                <Col xs={6} className="body-th common-input-style">
                  <Box className="filled-box">UAMS Admin System</Box>
                </Col>
                <Col xs={6} className="body-th common-input-style">
                  <Box className="filled-box textright revert-close">
                    <Link to="change/password" className="change-password-link">
                      Change Password
                    </Link>
                  </Box>
                </Col>
                <Col
                  xs={6}
                  className="body-th common-input-style mandatoryclass"
                >
                  <Box className="filled-box">
                    <TextField
                      id="filled-basic"
                      label="First Name"
                      variant="filled"
                      name="firstName"
                      onChange={handleChange}
                      value={fields.firstName}
                    />
                  </Box>
                  <p className="error-txt show form-text">{errors.firstName}</p>
                </Col>
                <Col
                  xs={6}
                  className="body-th common-input-style mandatoryclass"
                >
                  <Box className="filled-box">
                    <TextField
                      id="filled-basic"
                      label="Last Name"
                      variant="filled"
                      name="lastName"
                      onChange={handleChange}
                      value={fields.lastName}
                    />
                  </Box>
                  <p className="error-txt show form-text">{errors.lastName}</p>
                </Col>
                <Col
                  xs={6}
                  className="body-th common-input-style common-select-border mandatoryclass"
                >
                  <Box className="filled-box">
                    <FormControl fullWidth className="select-float-label">
                      <FieldLookup
                        isRender={true}
                        name="gender"
                        type="GENDER"
                        lookup={true}
                        value={fields.gender}
                        onSelect={(data) => setValue("gender", data)}
                        placeholder="Gender"
                      />
                    </FormControl>
                  </Box>
                  <p className="error-txt show form-text">{errors.gender}</p>
                </Col>
                <Col xs={6} className="body-th common-input-style">
                  <Box className="filled-box">
                    <FormControl
                      fullWidth
                      className="select-float-label common-select-border common-padding-zero-select"
                    >
                      <FieldLookup
                        isRender={true}
                        name="country"
                        type="COUNTRY_CODE"
                        lookup={true}
                        value={fields.country}
                        onSelect={(data) => setValue("country", data)}
                        placeholder="Country/Region"
                      />
                    </FormControl>
                  </Box>
                  <p className="error-txt show form-text">{errors.country}</p>
                </Col>
                <Col xs={6} className="body-th common-input-style">
                  <Box className="filled-box">
                    <div className="template-name-border flag-pic py-1 px-2">
                      <label className="template-label-font template-label-padding">
                        Mobile
                      </label>
                      <PhoneInput
                        country={"in"}
                        value={String(fields.mobile)}
                        name={"mobile"}
                        onChange={(phone) => setValue("mobile", phone)}
                      />
                    </div>
                  </Box>
                  <p className="error-txt show form-text">{errors.mobile}</p>
                </Col>
                <Col xs={6} className="body-th common-input-style">
                  <Box className="filled-box">
                    <FormControl
                      fullWidth
                      className="select-float-label common-select-border common-padding-zero-select"
                    >
                      <FieldLookup
                        isRender={true}
                        name="language"
                        type="LANGUAGES"
                        lookup={true}
                        value={fields.language}
                        onSelect={(data) => setValue("language", data)}
                        placeholder="Language"
                      />
                    </FormControl>
                  </Box>
                  <p className="error-txt show form-text">{errors.language}</p>
                </Col>
                <Col xs={6} className="body-th common-input-style">
                  <Box className="filled-box">
                    <FormControl
                      fullWidth
                      className="select-float-label common-select-border common-padding-zero-select"
                    >
                      <FieldLookup
                        isRender={true}
                        name="timezone"
                        type="TIMEZONES"
                        lookup={true}
                        value={fields.timezone}
                        onSelect={(data) => setValue("timezone", data)}
                        placeholder="Timezone"
                      />
                    </FormControl>
                  </Box>
                  <p className="error-txt show form-text">{errors.timezone}</p>
                </Col>
                <Col xs={6} className="body-th common-input-style">
                  <Box className="filled-box">
                    <input
                      type="file"
                      ref={channelImageFile}
                      onChange={cropItUpload}
                      accept="image/*"
                      style={{ display: "none" }}
                    />
                    <CropIt
                      ref={(instance) => {
                        channelImageCroper = instance;
                      }}
                      onComplete={(data) => cropComplete(data, "profileImage")}
                      shape={"round"}
                    />
                    {croppedImage ? (
                      <img
                        className="cropped-image me-2"
                        src={croppedImage}
                        alt="cropped"
                      />
                    ) : (
                      <img
                        src={
                          envConfig.IMG_SERVLET_URL +
                          "?imageId=" +
                          userData.UserId +
                          "&imageType=USER"
                        }
                        className="cropped-image me-2"
                      />
                    )}
                    <button
                      className="btn upload-btn"
                      onClick={(event) => handleClick(event)}
                    >
                      <FileUploadIcon className="me-2" />
                      Upload a picture
                    </button>
                  </Box>
                </Col>
              </Row>
            </Box>
          </Paper>
        </Box>
      </Paper>
    </div>
  );
}
export default Profile;
