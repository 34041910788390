import React, { useEffect, useState } from "react";
import moment from "moment";
import { Form, Button } from "react-bootstrap";
import Select from "react-select";
import InputIcon from "react-multi-date-picker/components/input_icon";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePicker from "react-multi-date-picker";
import AmlHashForm from "aml-hash-form";
// material
import { Checkbox, Box } from "@mui/material";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// confirm
import "react-confirm-alert/src/react-confirm-alert.css";
// includes
import { common } from "helpers";
// pages
import AutomationSelect from "elements/AutomationSelect";
// dialog
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

function FieldUpdateModal(props) {
  const [status, setStatus] = useState("process");
  // form
  const formFields = {
    description: { validate: ["req"] },
    actionAttribute: { validate: ["req"] },
    actionUpdateValue: { validate: ["req"] },
    scheduled: { validate: [] },
    scheduledNo: {
      validate: ["req", "gte:0"],
      parent: { field: "scheduled", value: "Y" },
    },
    scheduledType: {
      validate: ["req"],
      parent: { field: "scheduled", value: "Y" },
    },
  };
  const {
    fields,
    isField,
    errors,
    setValue,
    setMultiValue,
    handleChange,
    handleSubmit,
  } = AmlHashForm(formFields);
  const [fieldTypes] = useState({
    default: [
      "singleLine",
      "multiLine",
      "email",
      "phone",
      "number",
      "decimal",
      "currency",
      "url",
    ],
    bool: ["checkbox"],
    date: ["date", "dateTime"],
    singleSelect: ["pickList", "lookup"],
    multipleSelect: ["multiSelect", "multiLookup"],
    file: ["imageUpload", "fileUpload"],
  });
  // effect
  useEffect(() => {
    setMultiValue(props.data);
    setStatus("completed");
  }, []);

  useEffect(() => {
    if (isField === "actionAttribute") {
      setValue("actionUpdateValue", "");
    }
  }, [fields]);

  // handler
  const handleAttrChange = (e) => {
    handleChange(e);
  };

  const onFieldChange = (e) => {
    let type = props.columns[fields.actionAttribute].type;
    let value = "";
    if (fieldTypes.default.includes(type)) {
      value = e.target.value;
    } else if (fieldTypes.bool.includes(type)) {
      value = e.target.value;
    } else if (fieldTypes.date.includes(type)) {
      value = e.toDate();
    } else if (fieldTypes.singleSelect.includes(type)) {
      value = e ? e : "";
    } else if (fieldTypes.multipleSelect.includes(type)) {
      value = e ? e : "";
    }
    value = validateType(type, value);
    setValue("actionUpdateValue", value);
  };

  // submit handler
  const onSubmit = () => {
    let isValid = handleSubmit();
    if (isValid) {
      props.onSuccess(common.reParse(fields));
    }
  };

  // validate
  const validateType = (type, value) => {
    if (type === "number" || type === "phone") {
      return common.onlyNumbers(value);
    } else if (type === "decimal") {
      return common.onlyFloat(value);
    } else if (type == "currency") {
      return common.onlyFloat(value).replace(/(\d)(?=(\d{3})+\b)/g, "$1,");
    }
    return value;
  };

  // support
  const isObject = (val) => {
    return typeof val === "object" && typeof val !== null;
  };

  const labelValue = (data) => {
    if (isObject(data)) {
      return data ? data.map((item) => ({ label: item, value: item })) : "";
    } else {
      return data ? { label: data, value: data } : "";
    }
  };

  return (
    <div className="target-wrap" style={{ minHeight: "800px" }}>
      {status === "completed" && (
        <div>
          <Dialog
            open={true}
            aria-labelledby="responsive-dialog-title"
            maxWidth={"sm"}
            fullWidth={true}
            className="target-modal-wrap add-action-popup"
          //  disableBackdropClick={true}
            disableScrollLock
          >
            <DialogTitle id="responsive-dialog-title" className="popup-title">
              Field Update
            </DialogTitle>
            <DialogContent>
              <div>
                <div className="popup-form-wrap">
                  <div className="row">
                    <div className="col-md-12">
                      <label>Title<span className="mandtory-class">*</span></label>
                      <input
                        type="text"
                        name="description"
                        className="form-control"
                        placeholder="Enter the name"
                        value={fields.description}
                        onChange={handleChange}
                      />
                      <Form.Text className="error error-txt">
                        {errors.description}
                      </Form.Text>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <label>Field<span className="mandtory-class">*</span></label>
                      <select
                        name="actionAttribute"
                        className="form-control default-select"
                        value={fields.actionAttribute}
                        onChange={handleAttrChange}
                      >
                        <option value="">Select Field</option>
                        {Object.entries(props.columns)
                          .filter(([key, item]) => item.fieldUpdate)
                          .map(([key, item]) => (
                            <option key={key} value={item.key}>
                              {item.label}
                            </option>
                          ))}
                      </select>
                      <Form.Text className="error error-txt">
                        {errors.actionAttribute}
                      </Form.Text>
                    </div>
                  </div>

                  {fields.actionAttribute &&
                    [0].map(() => {
                      let fItem = props.columns[fields.actionAttribute];
                      return (
                        <div className="row" key={0}>
                          <div className="col-md-12">
                            <label>Value<span className="mandtory-class">*</span></label>
                            {fItem.type === "singleLine" ? (
                              <Form.Control
                                type="text"
                                placeholder={`Enter ${fItem.label}`}
                                maxLength={fItem.attr.maxChar}
                                value={fields.actionUpdateValue}
                                onChange={(e) => onFieldChange(e)}
                              />
                            ) : fItem.type === "multiLine" ? (
                              <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder={`Enter ${fItem.label}`}
                                maxLength={fItem.attr.maxChar}
                                value={fields.actionUpdateValue}
                                onChange={(e) => onFieldChange(e)}
                              />
                            ) : fItem.type === "email" ? (
                              <Form.Control
                                type="text"
                                placeholder={`Enter ${fItem.label}`}
                                maxLength={fItem.attr.maxChar}
                                value={fields.actionUpdateValue}
                                onChange={(e) => onFieldChange(e)}
                              />
                            ) : fItem.type === "phone" ? (
                              <Form.Control
                                type="text"
                                placeholder={`Enter ${fItem.label}`}
                                maxLength={fItem.attr.maxChar}
                                value={fields.actionUpdateValue}
                                onChange={(e) => onFieldChange(e)}
                              />
                            ) : fItem.type === "pickList" ? (
                              <Select
                                value={fields.actionUpdateValue}
                                isClearable={true}
                                options={labelValue(fItem.attr.option)}
                                onChange={(data) => onFieldChange(data)}
                              />
                            ) : fItem.type === "multiSelect" ? (
                              <Select
                                isMulti={true}
                                isClearable={true}
                                value={fields.actionUpdateValue}
                                options={labelValue(fItem.attr.option)}
                                onChange={(data) => onFieldChange(data)}
                              />
                            ) : fItem.type === "date" ? (
                              <Box className="ml-1 input-date-picker FUM-datepicker">
                                <DatePicker
                                  isClearable={true}
                                  format="MMMM D, YYYY"
                                  render={<InputIcon />}
                                  placeholder="Select Date"
                                  value={
                                    fields.actionUpdateValue
                                      ? moment(
                                        fields.actionUpdateValue
                                      ).toDate()
                                      : ""
                                  }
                                  fixMainPosition={true}
                                  calendarPosition="bottom-left"
                                  onChange={(date) => onFieldChange(date)}
                                  
                                />
                              </Box>
                            ) : // <DatePicker
                              //   isClearable={true}
                              //   peekNextMonth
                              //   showMonthDropdown
                              //   showYearDropdown
                              //   dropdownMode="select"
                              //   dateFormat="MMMM d, yyyy"
                              //   placeholderText="Select Date"
                              //   selected={fields.actionUpdateValue ? moment(fields.actionUpdateValue).toDate() : null}
                              //   onChange={(date) => onFieldChange(date)}
                              // />
                              fItem.type === "dateTime" ? (
                                <Box className="ml-1 input-date-picker FUM-datepicker">
                                  <DatePicker
                                    isClearable={true}
                                    format="MM/DD/YYYY hh:mm A"
                                    plugins={[
                                      <TimePicker
                                        hideSeconds
                                        position="bottom"
                                        className="ml-3"
                                      />,
                                    ]}
                                    render={<InputIcon />}
                                    placeholder="Select Date and Time"
                                    value={
                                      fields.actionUpdateValue
                                        ? moment(
                                          fields.actionUpdateValue
                                        ).toDate()
                                        : ""
                                    }
                                    onChange={(date) => onFieldChange(date)}
                                    fixMainPosition={true}
                                    calendarPosition="bottom-left"
                                  />
                                </Box>
                              ) : // <DatePicker
                                //   isClearable={true}
                                //   peekNextMonth
                                //   showMonthDropdown
                                //   showYearDropdown
                                //   dropdownMode="select"
                                //   showTimeSelect
                                //   timeIntervals={1}
                                //   timeCaption="Time"
                                //   dateFormat="MMMM d, yyyy hh:mm aa"
                                //   placeholderText="Select Date and Time"
                                //   selected={fields.actionUpdateValue ? moment(fields.actionUpdateValue).toDate() : null}
                                //   onChange={(date) => onFieldChange(date)}
                                // />
                                fItem.type === "number" ? (
                                  <Form.Control
                                    type="text"
                                    placeholder={`Enter ${fItem.label}`}
                                    maxLength={fItem.attr.maxChar}
                                    value={fields.actionUpdateValue}
                                    onChange={(e) => onFieldChange(e)}
                                  />
                                ) : fItem.type === "decimal" ? (
                                  <Form.Control
                                    type="text"
                                    placeholder={`Enter ${fItem.label}`}
                                    maxLength={fItem.attr.maxChar}
                                    value={fields.actionUpdateValue}
                                    onChange={(e) => onFieldChange(e)}
                                  />
                                ) : fItem.type === "currency" ? (
                                  <Form.Control
                                    type="text"
                                    placeholder={`Enter ${fItem.label}`}
                                    maxLength={fItem.attr.maxChar}
                                    value={fields.actionUpdateValue}
                                    onChange={(e) => onFieldChange(e)}
                                  />
                                ) : fItem.type === "checkbox" ? (
                                  <select
                                    className="form-control default-select"
                                    value={fields.actionUpdateValue}
                                    onChange={(e) => onFieldChange(e)}
                                  >
                                    <option value="">None</option>
                                    <option value="Y">Yes</option>
                                    <option value="N">No</option>
                                  </select>
                                ) : fItem.type === "url" ? (
                                  <Form.Control
                                    type="text"
                                    placeholder={`Enter ${fItem.label}`}
                                    maxLength={fItem.attr.maxChar}
                                    value={fields.actionUpdateValue}
                                    onChange={(e) => onFieldChange(e)}
                                  />
                                ) : fItem.type === "lookup" ? (
                                  <AutomationSelect
                                    isRender={true}
                                    field={fItem}
                                    value={fields.actionUpdateValue}
                                    onSelect={(data) => onFieldChange(data)}
                                  />
                                ) : fItem.type === "multiLookup" ? (
                                  <AutomationSelect
                                    isRender={true}
                                    field={fItem}
                                    value={fields.actionUpdateValue}
                                    onSelect={(data) => onFieldChange(data)}
                                  />
                                ) : null}
                            <Form.Text className="error error-txt">
                              {errors.actionUpdateValue}
                            </Form.Text>
                          </div>
                        </div>
                      );
                    })}
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <FormControlLabel
                        name="scheduled"
                        className="m-0"
                        value="Y#N"
                        control={<Checkbox color="primary" />}
                        label={"Scheduled"}
                        labelPlacement="end"
                        checked={fields.scheduled === "Y" ? true : false}
                        onClick={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-2">
                      <label>Scheduled Value</label>
                      <input
                        name="scheduledNo"
                        type="number"
                        className="form-control"
                        value={fields.scheduledNo}
                        onChange={handleChange}
                      />
                      <Form.Text className="error error-txt">
                        {errors.scheduledNo}
                      </Form.Text>
                    </div>
                    <div className="col-md-6 mb-2">
                      <label>Scheduled Type</label>
                      <select
                        name="scheduledType"
                        className="form-control default-select"
                        value={fields.scheduledType}
                        onChange={handleChange}
                      >
                        <option value="">None</option>
                        <option value="MIA">Minute(s)</option>
                        <option value="HA">Hour(s)</option>
                        <option value="DA">Day(s)</option>
                      </select>
                      <Form.Text className="error error-txt">
                        {errors.scheduledType}
                      </Form.Text>
                    </div>
                  </div>
                </div>
              </div>
            </DialogContent>

            <DialogActions className="popup-footer justify-content-start">
              <div className="botBtn">
                <div>
                  <Button
                    className="automation-footer-btn"
                    onClick={onSubmit}
                    type="submit"
                  >
                    Save
                  </Button>
                  <Button
                    className="automation-cancel-btn"
                    onClick={() => props.onCancel()}
                    type="button"
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </div>
  );
}

export default FieldUpdateModal;
