import React, { useEffect, useState } from 'react';
import moment from "moment";
import InputIcon from "react-multi-date-picker/components/input_icon";
import DatePicker from "react-multi-date-picker";
import AmlHashForm from "aml-hash-form";
import { Form, Button } from "react-bootstrap";
// material
import {
  Checkbox, Box
} from "@mui/material";
import FormControlLabel from '@material-ui/core/FormControlLabel';
// confirm
import "react-confirm-alert/src/react-confirm-alert.css";
// includes
import { common } from "helpers";
// pages
import FieldSelect from "elements/FieldSelect";
import FieldLookup from "elements/FieldLookup";
// dialog
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

function TaskModal(props) {
  const [status, setStatus] = useState("process");
  // form
  const formFields = {
    actionName: { validate: ["req"] },
    actionSubject: { validate: ["req"] },
    description: { validate: [] },
    toUser: { validate: ["objValReq#Please select the field"], obj: "value" },
    taskStatus: { validate: ["objValReq#Please select the field"], obj: "value" },
    priority: { validate: ["objValReq#Please select the field"], obj: "value" },
    startDate: { validate: ["req"] },
    endDate: { validate: ["req"] },
    scheduled: { validate: [] },
    scheduledNo: { validate: ["req", "gte:0"], parent: { field: "scheduled", value: "Y" } },
    scheduledType: { validate: ["req"], parent: { field: "scheduled", value: "Y" } },
  }
  const { fields, errors, setValue, setMultiValue, handleChange, handleSubmit } = AmlHashForm(formFields);

  // effect
  useEffect(() => {
    setMultiValue(props.data);
    setStatus("completed");
  }, [])

  // handler
  const onSubmit = () => {
    let isValid = handleSubmit();
    if (isValid) {
      props.onSuccess(common.reParse(fields));
    }
  }
console.log('task ' )
  return (
    <div className="target-wrap" style={{ minHeight: "800px" }}>
      {status === "completed" && (
        <div>
          <Dialog
            open={true}
            aria-labelledby="responsive-dialog-title"
            maxWidth={"sm"}
            fullWidth={true}
            className="target-modal-wrap add-action-popup"
           // disableBackdropClick={true}
            disableScrollLock
          >
            <DialogTitle id="responsive-dialog-title" className="popup-title">Task</DialogTitle>
            <DialogContent>
              <div>
                <div className="popup-form-wrap task-modal-wrap">
                  <div className="row">
                    <div className="col-md-6">
                      <label>Action Name<span className="mandtory-class">*</span></label>
                      <input
                        type="text"
                        name="actionName"
                        className="form-control"
                        placeholder="Enter the action Name"
                        value={fields.actionName}
                        onChange={handleChange}
                      />
                      <Form.Text className="error error-txt">{errors.actionName}</Form.Text>
                    </div>
                    <div className="col-md-6">
                      <label>Action Subject<span className="mandtory-class">*</span></label>
                      <input
                        type="text"
                        name="actionSubject"
                        className="form-control"
                        placeholder="Enter the action Subject"
                        value={fields.actionSubject}
                        onChange={handleChange}
                      />
                      <Form.Text className="error error-txt">{errors.actionSubject}</Form.Text>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <label>Description</label>
                      <Form.Control
                        as="textarea"
                        rows="3"
                        maxLength={150}
                        placeholder="Enter the description"
                        name="description"
                        value={fields.description}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <label>From User</label>
                      <input type="text" className="form-control" value={`${fields.fromUser?.label} (${fields.fromUser?.value})`} disabled={true} />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 common-select-border">
                    <label>To User(s)<span className="mandtory-class">*</span></label>
                      <FieldSelect
                        api="CRM_EMPLOYEES"
                        get="EmployeeName"
                        set="Email"
                        value={fields.toUser}
                        isMulti={true}
                        isRender={true}
                        onSelect={(data) => setValue("toUser", data)}
                        placeholder="To User(s)"
                      />
                      <Form.Text className="error error-txt">{errors.toUser}</Form.Text>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 common-select-border">
                      <label>Task Status<span className="mandtory-class">*</span></label>
                      <FieldLookup
                        isRender={true}
                        name="taskStatus"
                        type="TASK_STATUS"
                        lookup={true}
                        value={fields.taskStatus}
                        onSelect={(data) => setValue("taskStatus", data)}
                        placeholder="Task Status"
                      />
                      <Form.Text className="error error-txt">{errors.taskStatus}</Form.Text>
                    </div>
                    <div className="col-md-6 common-select-border">
                      <label>Task Priority<span className="mandtory-class">*</span></label>
                      <FieldLookup
                        isRender={true}
                        name="priority"
                        type="TASK_PRIORITY"
                        lookup={true}
                        value={fields.priority}
                        onSelect={(data) => setValue("priority", data)}
                        placeholder="Task Priority"
                      />
                      <Form.Text className="error error-txt">{errors.priority}</Form.Text>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label>Start Date<span className="mandtory-class">*</span></label>
                      <Box className="ml-1 input-date-picker">
                        <DatePicker
                          isClearable={true}
                          format="MMMM D, YYYY"
                          render={<InputIcon />}
                          placeholder="Select Date"
                          value={
                            fields.startDate ? moment(fields.startDate).toDate() : ""
                          }
                          onChange={(date) => setValue("startDate", date?.toDate() ?? "")}
                        />
                      </Box>
                      {/* <DatePicker
                        isClearable={true}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        dateFormat="MMMM d, yyyy"
                        placeholderText="Select Date"
                        selected={fields.startDate ? moment(fields.startDate).toDate() : null}
                        onChange={(date) => setValue("startDate", date ?? "")}
                      /> */}
                      <Form.Text className="error error-txt">{errors.startDate}</Form.Text>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label>End Date<span className="mandtory-class">*</span></label>
                      <Box className="ml-1 input-date-picker">
                        <DatePicker
                          isClearable={true}
                          format="MMMM D, YYYY"
                          render={<InputIcon />}
                          placeholder="Select Date"
                          value={
                            fields.endDate ? moment(fields.endDate).toDate() : ""
                          }
                          onChange={(date) => setValue("endDate", date?.toDate() ?? "")}
                        />
                      </Box>
                      {/* <DatePicker
                        isClearable={true}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        dateFormat="MMMM d, yyyy"
                        placeholderText="Select Date"
                        selected={fields.endDate ? moment(fields.endDate).toDate() : null}
                        onChange={(date) => setValue("endDate", date ?? "")}
                      /> */}
                      <Form.Text className="error error-txt">{errors.endDate}</Form.Text>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <FormControlLabel
                        name="scheduled"
                        className="m-0"
                        value="Y#N"
                        control={<Checkbox color="primary" />}
                        label={"Scheduled"}
                        labelPlacement="end"
                        checked={fields.scheduled === "Y" ? true : false}
                        onClick={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label>Scheduled Value</label>
                      <input
                        name="scheduledNo"
                        type="number"
                        className="form-control"
                        value={fields.scheduledNo}
                        onChange={handleChange}
                      />
                      <Form.Text className="error error-txt">{errors.scheduledNo}</Form.Text>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label>Scheduled Type</label>
                      <select
                        name="scheduledType"
                        className="form-control default-select"
                        value={fields.scheduledType}
                        onChange={handleChange}
                      >
                        <option value="">None</option>
                        <option value="MIA">Minute(s)</option>
                        <option value="HA">Hour(s)</option>
                        <option value="DA">Day(s)</option>
                      </select>
                      <Form.Text className="error error-txt">{errors.scheduledType}</Form.Text>
                    </div>
                  </div>
                </div>
              </div>
            </DialogContent>
            <DialogActions className="popup-footer justify-content-start">
              <div className="botBtn">
                <div>
                  <Button
                    className="automation-footer-btn"
                    onClick={onSubmit}
                    type="submit"
                  >Save</Button>
                  <Button
                    className="automation-cancel-btn"
                    onClick={() => props.onCancel()}
                    type="button"
                  >Cancel</Button>
                </div>
              </div>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </div>
  )
}

export default TaskModal;