import React from "react";
import Paper from "@mui/material/Paper";
import { useOutletContext } from "react-router-dom";
import StaticTable from "elements/staticTable";
import AccessDenied from "elements/AccessDenied";
import { common } from "helpers";
import transactionStatus from "assets/files/static/transactionStatus.json";

function FormTransaction() {
  const [permission] = useOutletContext();

  return (
    <div className="default-bg">
      <div style={{ padding: "20px" }}>
        {common.givenPermission(
          permission,
          "configure:form trasactions",
          "read"
        ) ? (
          <AccessDenied />
        ) : (
          <Paper
            sx={{ width: "100%", mb: 2 }}
            className={`container responsive-table p-0`}
          >
            <StaticTable
              module={transactionStatus}
              add={
                !common.givenPermission(
                  permission,
                  "configure:form trasactions",
                  "add"
                )
              }
              update={
                !common.givenPermission(
                  permission,
                  "configure:form trasactions",
                  "update"
                )
              }
              delete={
                !common.givenPermission(
                  permission,
                  "configure:form trasactions",
                  "delete"
                )
              }
              safariclassName={"safari-style"}
            />
          </Paper>
        )}
      </div>
    </div>
  );
}

export default FormTransaction;
