import React, { useState, useEffect } from "react";
import { api, common, envConfig } from "helpers";
import {
  Divider,
  Hidden,
  Paper,
  Typography,
  FormControl,
  Button,
  IconButton,
} from "@mui/material";
import PropTypes from "prop-types";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import QuickAdd from "elements/QuickAdd";
import Select, { components } from "react-select";
import Popover from "@mui/material/Popover";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@mui/material/TextField";
import FieldSelect from "elements/FieldSelect";
import { Link } from "react-router-dom";
import AmlHashForm from "aml-hash-form";
import Loader from "elements/Loader";
import RecordNotFound from "elements/NotFound";
import ModalConfirm from "elements/ModalConfirm";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useOutletContext } from "react-router-dom";
import StaticTable from "elements/staticTable";
import target from "assets/files/static/target.json";
import AccessDenied from "elements/AccessDenied";
import { CONFIGURE_TARGET, EMPLOYEE_TARGET } from "helpers/service";

// Floating React select
const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

// Select box style
const selectStyles = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  container: (provided, state) => ({
    ...provided,
    marginTop: 0,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    overflow: "visible",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    position: "absolute",
    top: state.hasValue || state.selectProps.inputValue ? -6 : "17%",
    transition: "top 0.1s, font-size 0.1s",
    fontSize: state.hasValue || state.selectProps.inputValue ? 11 : 13,
    color: "#9daabb",
  }),
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  React.useEffect(() => {
    var isMobileVersion = document.getElementsByClassName("rotate-arrow");
    const fixedHeader = document.getElementById("fixed-header");
    if (isMobileVersion.length > 0) {
      fixedHeader.classList.add("menu-shrink");
      fixedHeader.classList.remove("menu-expand");
    } else {
      fixedHeader.classList.remove("menu-shrink");
      fixedHeader.classList.add("menu-expand");
    }
  });
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Target() {
  const [targetPopup, setTargetPopup] = React.useState(false);
  const userData = common.authInfo();
  const [init, setInit] = useState(true);
  const [targetList, setTargetList] = useState([]);
  const [targetType, setTargetType] = useState([]);
  const [targetId, setTargetId] = useState(null);
  const [targetEmp, setTargetEmp] = useState([]);
  const [universalLoader, setUniversalLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [disableEmployee, setDisableEmployee] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [modalLoading, setModalLoading] = useState(false);
  const [reload, setReload] = useState(null);

  const [permission] = useOutletContext();
  const [formInputFields, setFormInputFields] = useState([
    {
      EmployeeId: null,
      number: 0,
    },
  ]);

  const [configure] = useState({
    type: [
      { label: "Month", value: "month" },
      { label: "Quarter", value: "quarter" },
      { label: "Year", value: "year" },
    ],
    quarter: [
      { label: "Q1", value: 1 },
      { label: "Q2", value: 2 },
      { label: "Q3", value: 3 },
      { label: "Q4", value: 4 },
    ],
    month: common.getMonthList(),
    year: common.getYearList(3),
  });

  const showTargetPopup = (item, id) => {
    setTargetPopup(true);
    getTargetList(id);

    if (id) {
      let filterTargetPeriod = configure.type.filter(
        (i, idx) => i.value === item.TargetUom
      );

      let filterLabel = configure.quarter.filter(
        (i, idx) => i.value == item.TargetQuarter
      );

      setMultiValue({
        TargetRscUom: item.TargetUom
          ? {
              label: filterTargetPeriod[0].label,
              value: item.TargetUom,
            }
          : null,
        TargetRscMonth: item.TargetMonth
          ? {
              label: item.TargetMonth,
              value: item.TargetMonth,
            }
          : null,
        TargetQuarter: item.TargetQuarter
          ? {
              label: filterLabel[0]?.label,
              value: item.TargetQuarter,
            }
          : null,
        TargetRscYear: item.TargetYear
          ? {
              label: item.TargetYear,
              value: item.TargetYear,
            }
          : null,
        TargetType: item.TargetType
          ? {
              label: item.TargetTypeName,
              value: item.TargetType,
            }
          : null,
      });
    }
  };
  const closeTargetPopup = () => {
    setTargetPopup(false);
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [rightAnchorEl, setRightAnchorEl] = useState(null);

  const detailsMoreClick = (event) => {
    setRightAnchorEl(event.currentTarget);
  };
  const detailsCloseClick = () => {
    setRightAnchorEl(null);
  };

  const rightMoreOpen = Boolean(rightAnchorEl);

  const addFormFields = () => {
    setFormInputFields((oldState) => [
      ...oldState,
      { EmployeeId: null, number: 0 },
    ]);
  };

  let handleChangeForm = (i, e) => {
    let newFormValues = [...formInputFields];
    newFormValues[i][e.target.name] = e.target.value;
    setFormInputFields(newFormValues);
  };

  const HandleReplaceArray = (index, value) => {
    let copyEmp = [...targetEmp];
    copyEmp[index]["TargetRscValue"] = value;
    setTargetEmp(copyEmp);
  };

  let handleChangeSelector = (i, data) => {
    let newFormValues = [...formInputFields];
    newFormValues[i]["EmployeeId"] = data;
    let newEmployee = [...disableEmployee];
    newEmployee[i] = data.value;
    setDisableEmployee(newEmployee);
    setFormInputFields(newFormValues);
  };

  const removeFormFields = (i) => {
    let formValues = formInputFields;
    let employee = disableEmployee;
    formValues.splice(i, 1);
    employee.splice(i, 1);
    setFormInputFields((oldState) => [...formValues]);
    setDisableEmployee((oldState) => [...employee]);
  };

  const formFields = {};

  const {
    fields,
    setValue: setValueA,
    setMultiValue,
    handleChange: handleChangeA,
  } = AmlHashForm(formFields);

  useEffect(() => {
    if (init) {
      setInit(false);
      getConfigureTarget();
      setMultiValue({ TargetRscUom: { label: "Month", value: "month" } });
    }

    getTargetList();
  }, [targetId]);

  useEffect(() => {
    getTargetListByType();
  }, [fields]);

  const getTargetListByType = () => {
    let filterQry = `TargetUom=${fields?.TargetRscUom?.value};TargetType=${fields?.TargetType?.value}`;
    if (fields?.TargetRscUom?.value == "month") {
      filterQry += `;TargetMonth=${fields?.TargetRscMonth?.value};TargetYear=${fields?.TargetRscYear?.value}`;
    } else if (fields?.TargetRscUom?.value == "quarter") {
      filterQry += `;TargetQuarter=${fields?.targetQuarter?.value}`;
    }
    if (fields?.TargetRscYear?.value == "year") {
      filterQry += `;targetYear=${fields?.TargetRscYear?.value}`;
    }
    let query = `?q=${filterQry}&totalResults=true&limit=1&offset=0`;
    let url = `${envConfig.BASE_API}${CONFIGURE_TARGET}${query}`;

    api.call(
      {
        url,
        type: "dynamic",
        method: "GET",
        auth: "token",
        cType: 4,
      },
      (response) => {
        if (response.status === 200) {
          if (response.data.items.length) {
            getTargetList(response?.data?.items[0]?.CrmTargetId);
          } else {
            setTargetEmp([]);
          }
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  const getEmpParam = (id, item) => {
    let params = {
      CrmTargetId: id,
      CrmRscTargetName: `${item?.EmployeeId?.label}`,
      CrmRscTargetDesc: `${item?.EmployeeId?.label}`,
      TargetRscUom: fields.TargetRscUom.value,
      TargetRscMonth: fields?.TargetRscMonth?.value || "JAN",
      TargetRscQuarter: fields?.TargetQuarter?.value || 1,
      TargetRscYear: fields?.TargetRscYear?.value || 1970,
      TargetRscType: fields.TargetType?.value,
      TargetRscValue: Number(item.number),
      Active: "Y",
      EffStartDate: null,
      EffEndDate: null,
      EmpId: Number(item?.EmployeeId?.value),
      OrgId: userData?.DefaultOrgId,
      TenantId: userData?.TenantId,
    };
    return params;
  };

  const onCloseModel = () => {
    setMultiValue({
      TargetRscMonth: null,
      TargetRscUom: null,
      TargetRscYear: null,
      TargetType: null,
    });
    setTargetEmp(Array.from([]));
    setFormInputFields([
      {
        EmployeeId: null,
        number: 0,
      },
    ]);
    setDisableEmployee([]);
    closeTargetPopup();
  };

  const onSubmit = () => {
    //

    if (
      fields?.TargetRscUom?.value &&
      fields?.TargetType?.value &&
      fields?.TargetRscYear?.value
    ) {
      let valid = false;
      if (
        fields?.TargetRscUom?.value === "month" &&
        fields?.TargetRscMonth?.value
      ) {
        valid = true;
      } else if (
        fields?.TargetRscUom?.value === "quarter" &&
        fields?.TargetQuarter?.value
      ) {
        valid = true;
      } else if (
        fields?.TargetRscUom?.value === "year" &&
        fields?.TargetRscYear?.value
      ) {
        valid = true;
      }
      //update
      if (valid) {
        //setTargetId.map()

        let targetAlreadyPresent = false;
        let targetGetId = null;
        if (targetEmp.length) {
          targetAlreadyPresent = true;
        } else {
          targetType?.map((item) => {
            console.log(
              `${fields?.TargetRscYear?.value} ${fields?.TargetRscMonth?.value}(${fields?.TargetType?.label})`,
              item.label ===
                `${fields?.TargetRscYear?.value} ${fields?.TargetRscMonth?.value}(${fields?.TargetType?.label})`
            );
            if (
              item.label ===
              `${fields?.TargetRscYear?.value} ${fields?.TargetRscMonth?.value}(${fields?.TargetType?.label})`
            ) {
              targetGetId = item.value;
              targetAlreadyPresent = true;
            }
          });
        }

        if (targetAlreadyPresent) {
          if (targetEmp.length) {
            saveTargetEmployee(targetEmp[0].CrmTargetId);
          } else {
            saveTargetEmployee(targetGetId);
          }
        } else {
          createTarget();
        }
      } else {
        //error

        common.snack("E", "please select the fields");
      }
    } else {
      //error

      common.snack("E", "please select the fields");
    }
  };

  const DeleteEmployee = (Eid) => {
    setModalLoading(true);
    api.call(
      {
        url: `${envConfig.BASE_API}${EMPLOYEE_TARGET}/${Eid}`,
        type: "dynamic",
        method: "DELETE",
        auth: "token",
        cType: 4,
      },
      (response) => {
        if (response.status === 201 || response.status === 204) {
          console.log({ reload });
          getTargetList(reload);
          setDeleteId(null);
          setReload(null);
          setModalLoading(false);
          detailsCloseClick();
        } else {
          setModalLoading(false);
          detailsCloseClick();
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  const createTarget = () => {
    let payload = {
      CrmTargetName: `${fields.TargetRscUom.value}-${fields.TargetType.value}`,
      CrmTargetDesc: `${fields.TargetRscUom.value}-${fields.TargetType.value}`,
      TargetUom: fields.TargetRscUom.value,
      TargetMonth: fields?.TargetRscMonth?.value || "JAN",
      TargetQuarter: fields?.TargetQuarter?.value || "-",
      TargetYear: fields?.TargetRscYear?.value || "-",
      TargetType: fields.TargetType?.value,

      TargetValue: 0,
      Active: "Y",
      EffStartDate: null,
      EffEndDate: null,
      OrgId: userData?.DefaultOrgId,
      TenantId: userData?.TenantId,
    };

    if (formInputFields[0].EmployeeId) {
      setProcessing(true);

      api.call(
        {
          url: `${envConfig.BASE_API}${CONFIGURE_TARGET}`,
          type: "dynamic",
          method: "POST",
          auth: "token",
          cType: 4,
          body: JSON.stringify(payload),
        },
        (response) => {
          if (response.status === 200 || response.status === 201) {
            saveTargetEmployee(response?.data?.CrmTargetId);
            getConfigureTarget();
          }
        },
        (error) => {},
        (final) => {}
      );
    } else {
      common.snack("E", "please select the Employee");
    }
  };

  const saveTargetEmployee = (CrmTargetId) => {
    //update

    if (targetEmp?.length) {
      let payload = [];
      targetEmp.map(async (item, index) => {
        item.EmployeeId = {
          label: item?.CrmRscTargetName,
          value: Number(item?.EmpId),
        };
        item.number = item.TargetRscValue;
        payload.push({
          id: `employee${index}`,
          path: `/CholaCrmRscTargets/${item?.CrmRscTargetId}`,
          operation: "update",
          payload: getEmpParam(item.CrmTargetId, item),
        });

        api.call(
          {
            url: `${envConfig.BASE_API}`,
            type: "dynamic",
            method: "POST",
            auth: "token",
            cType: 5,
            body: JSON.stringify({ parts: payload }),
          },
          (response) => {
            if (response.status === 200 || response.status === 201) {
              console.log("save");
            }
          },
          (error) => {},
          (final) => {}
        );

        if (!formInputFields.length || !formInputFields[0]?.EmployeeId) {
          onCloseModel();
          common.snack("S", "updated successfully ");
        }
      });
    }

    //new
    if (formInputFields?.length) {
      if (formInputFields[0].EmployeeId) {
        formInputFields.map(async (item, index) => {
          let payload = getEmpParam(CrmTargetId, item);
          let close = formInputFields.length;
          api.call(
            {
              url: `${envConfig.BASE_API}${EMPLOYEE_TARGET}`,
              type: "dynamic",
              method: "POST",
              auth: "token",
              cType: 4,
              body: JSON.stringify(payload),
            },
            (response) => {
              if (response.status === 200 || response.status === 201) {
                console.log("save");
              }
            },
            (error) => {},
            (final) => {}
          );
          if (close - 1 < index + 1) {
            onCloseModel();
            common.snack("S", "saved successfully ");
          }
        });
      } else {
        //throw error
      }
    }

    setTimeout(() => {
      getTargetList();
      setProcessing(false);
    }, 2000);
  };

  //Api
  const getTargetList = (target = null) => {
    if (target) {
      setTargetEmp([]);

      setLoading(true);
    } else {
      setUniversalLoader(true);
      setTargetList([]);
    }

    let _targetValue = target ? target : targetId?.value;
    let url = `${envConfig.BASE_API}${EMPLOYEE_TARGET}?q=CrmTargetId=${_targetValue}&orderBy=CreatedOn:desc&totalResults=true&limit=100&offset=0`;
    if (targetId !== null) {
      api.call(
        {
          url,
          type: "dynamic",
          method: "GET",
          auth: "token",
          cType: 4,
        },
        (response) => {
          if (response.status === 200) {
            if (response?.data?.items?.length) {
              let formData = [];
              response?.data?.items?.map((items) => {
                let pValue =
                  items.TargetRscValue > 0
                    ? (
                        (items.ActualValue / items.TargetRscValue) *
                        100
                      ).toFixed(0)
                    : 0;
                items.targetReport = {
                  progressBar: pValue > 100 ? 100 : pValue,
                  target: items.TargetRscValue || 0,
                  completed: items.ActualValue,
                  remaining:
                    items.TargetRscValue - items.ActualValue > 0
                      ? items.TargetRscValue - items.ActualValue
                      : 0,
                  progress: pValue,
                };
                formData.push(items);
              });

              if (target) {
                setDisableEmployee([]);
                setFormInputFields([
                  {
                    EmployeeId: null,
                    number: 0,
                  },
                ]);
                setTargetEmp(formData);

                formData.map((item) =>
                  setDisableEmployee((oldState) => [
                    ...oldState,
                    String(item?.EmpId),
                  ])
                );

                setLoading(false);
              } else {
                setTargetList(formData);

                setUniversalLoader(false);
              }
            } else {
              setLoading(false);
              setUniversalLoader(false);
            }
          } else {
            setUniversalLoader(false);
            setLoading(false);
          }
        },
        (error) => {},
        (final) => {}
      );
    }
  };

  console.log({ targetList });

  // api calls
  const getConfigureTarget = () => {
    let url = `${envConfig.BASE_API}${CONFIGURE_TARGET}?orderBy=TargetYear:desc&totalResults=true&limit=10000&offset=0`;
    setTargetType([]);
    api.call(
      {
        url,
        type: "dynamic",
        method: "GET",
        auth: "token",
        cType: 4,
      },
      (response) => {
        if (response.status === 200) {
          setTargetId(
            response.data.items.length
              ? {
                  label: `${response.data.items[0].TargetYear} ${response.data.items[0].TargetMonth}(${response.data.items[0].TargetTypeName})`,
                  value: response.data.items[0].CrmTargetId,
                }
              : null
          );

          response.data.items.map((item) =>
            setTargetType((oldItem) => [
              ...oldItem,
              {
                label: `${item.TargetYear} ${item.TargetMonth}(${item.TargetTypeName})`,
                value: item.CrmTargetId,
              },
            ])
          );
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  return (
    <div className="default-bg">
      <Box>
        <div>
          <Box
            sx={{ width: "100%", typography: "body1" }}
            className="primary-header-container"
          >
            <Paper
              variant="container"
              square
              className="fixed-header"
              id="fixed-header"
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", lg: "row" },
                  justifyContent: { xs: "center", lg: "space-between" },
                  itemsCenter: { xs: "center" },
                  py: { xs: 5, lg: 0 },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", lg: "row" },
                    justifyContent: { xs: "center", lg: "space-between" },
                    itemsCenter: { xs: "center" },
                  }}
                  alignItems="center"
                  px={3}
                  className="tabs"
                >
                  <Box display="flex" alignItems="center">
                    <Box>
                      <img
                        src={common.loadImg("outlineCRM-chart.svg")}
                        alt="img"
                      />
                    </Box>
                    &nbsp;&nbsp;
                    <div className="header-crm">Targets</div>
                    <Hidden smDown>
                      <Box className="vertical-divider ml-4">
                        <Divider orientation="vertical" />
                      </Box>
                    </Hidden>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab label="Overview" {...a11yProps(0)} />
                      <Tab label="Listview" {...a11yProps(1)} />
                    </Tabs>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    className="add-camp-btn cust-add-btn me-4"
                    onClick={showTargetPopup}
                  >
                    <Button variant="outlined">
                      Create New Target
                      <img
                        src={common.loadImg("light-plus.svg")}
                        alt="document"
                        className="ml-5"
                      />
                    </Button>
                  </Box>
                  <QuickAdd />
                </Box>
              </Box>
              <TabPanel value={value} index={0} className="tab-content-wrap">
                <div className="target-page-title-wrap container pe-0">
                  <div className="row w-100">
                    <div className="col-md-6 px-0 d-flex align-items-center">
                      <p className="camp-title mb-0 me-3">Employee</p>
                      <span className="count target-emp-count">{targetList.length}</span>
                    </div>
                    <div className="col-md-6 px-0 d-flex align-items-center justify-content-end">
                      <label className="pe-3">Target Type</label>

                      <FormControl
                        // fullWidth
                        sx={{ minWidth: 300, minHeight: 40 }}
                      >
                        <Select
                          className="text-box custom-react-select p-1"
                          labelid="select"
                          id="select"
                          label="Target Type"
                          menuPortalTarget={document.body}
                          isClearable={false}
                          options={targetType}
                          value={targetId}
                          components={{
                            ValueContainer: CustomValueContainer,
                          }}
                          onChange={(data) => setTargetId(data)}
                          placeholder="Target Type"
                          styles={selectStyles}
                        ></Select>
                      </FormControl>
                      {/* </Box> */}
                    </div>
                  </div>
                </div>
                {universalLoader ? (
                  <Loader />
                ) : (
                  <div className="employee-list-wrap">
                    <div className="container mt-5">
                      {targetList.length ? (
                        <>
                          {targetList.map((items, idx) => (
                            <div className="row employee-list mt-3" key={idx}>
                              <div className="col-md-1 py-3">
                                <div className="emp-logo-wrap">
                                  <p className="emp-logo-txt">AB</p>
                                </div>
                              </div>
                              <div className="col-md-3 py-3">
                                <p className="emp-list-name">
                                  {items?.CrmRscTargetName}
                                </p>
                                <p className="emp-list-label">
                                  {items?.CrmRscTargetDesc}
                                </p>
                              </div>
                              <div className="col-md-2 py-3 br-left text-center">
                                <p className="emp-list-name">Completed</p>
                                <p className="emp-list-label">
                                  {items?.targetReport?.completed}
                                </p>
                              </div>
                              <div className="col-md-2 py-3 br-left text-center">
                                <p className="emp-list-name">Remaining</p>
                                <p className="emp-list-label">
                                  {items?.targetReport?.remaining} /{" "}
                                  {items?.targetReport?.target}
                                </p>
                              </div>
                              <div className="col-md-3 py-3 br-left">
                                <p className="emp-list-name">Total Progress</p>
                                {/* <div className="progress mt-2">
                                  <div
                                    className={`progress-bar target-progressbar w-${items?.targetReport?.progressBar}`}
                                    role="progressbar"
                                    aria-valuenow={
                                      items?.targetReport?.progressBar
                                    }
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  >
                                    {items?.targetReport?.progress}%
                                  </div>
                                </div> */}
                                <div className="target-progress">
                                  <ProgressBar
                                    className="mt-2"
                                    now={items?.targetReport?.progressBar}
                                    label={`${items?.targetReport?.progressBar}%`}
                                  />
                                </div>
                              </div>
                              {/* <div className="col-md-1 py-3 br-left d-flex align-items-center justify-content-center">
                                <div className="my-auto mx-auto ">
                                  <MoreHorizOutlinedIcon
                                    className="pointer"
                                    aria-describedby={"right-more-popover"}
                                    onClick={detailsMoreClick}
                                  />

                                  <Popover
                                    id={"right-more-popover"}
                                    open={rightMoreOpen}
                                    anchorEl={rightAnchorEl}
                                    onClose={detailsCloseClick}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "right",
                                    }}
                                    disableScrollLock
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "right",
                                    }}
                                    onClick={(e) =>
                                      setDeleteId(items?.CrmRscTargetId)
                                    }
                                  >
                                    <div
                                      sx={{ p: 2 }}
                                      className="text-capitalize menu-item crm-cp"
                                    >
                                      Delete
                                    </div>
                                  </Popover>
                                </div>
                              </div> */}
                            </div>
                          ))}
                        </>
                      ) : (
                        <RecordNotFound />
                      )}
                    </div>
                  </div>
                )}
              </TabPanel>
              <TabPanel value={value} index={1} className="tab-content-wrap">
                <Box px={2} className="list-view">
                  {common.givenPermission(permission, "target", "read") ? (
                    <AccessDenied />
                  ) : (
                    <Paper
                      sx={{ width: "100%", mb: 2 }}
                      className={`container responsive-table p-0 `}
                      id="task-list-view-wrap"
                    >
                      {universalLoader ? (
                        <Loader />
                      ) : (
                        <StaticTable
                          showTargetPopup={showTargetPopup}
                          closeTargetPopup={closeTargetPopup}
                          module={target}
                          add={
                            !common.givenPermission(permission, "target", "add")
                          }
                          update={
                            !common.givenPermission(
                              permission,
                              "target",
                              "update"
                            )
                          }
                          // delete={
                          //   !common.givenPermission(
                          //     permission,
                          //     "target",
                          //     "delete"
                          //   )
                          // }
                        />
                      )}
                    </Paper>
                  )}
                </Box>
              </TabPanel>
            </Paper>
          </Box>
        </div>
      </Box>
      <Dialog
        open={targetPopup}
        onClose={closeTargetPopup}
        className="description-popup target-popup"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableScrollLock
      >
        <DialogTitle id="alert-dialog-title" className="border-bottom">
          <div className="attach-popup-title">
            <div className="notify-title">Target</div>
            <IconButton onClick={onCloseModel}>
              <img src={common.loadImg("filterCloseIcon.svg")} />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="container">
            <div className="row">
              <div className="col">
                <FormControl fullWidth sx={{ minWidth: 200, minHeight: 40 }}>
                  <Select
                    className="text-box custom-react-select p-1"
                    labelid="select"
                    id="select"
                    label="Target Period"
                    menuPortalTarget={document.body}
                    isClearable={true}
                    options={configure.type}
                    components={{
                      ValueContainer: CustomValueContainer,
                    }}
                    value={fields.TargetRscUom}
                    onChange={(data) => {
                      {
                        setMultiValue({
                          ...fields,
                          TargetRscUom: data,
                          TargetQuarter: null,
                          TargetRscMonth: null,
                        });
                      }
                    }}
                    placeholder="Target Period"
                    styles={selectStyles}
                  ></Select>
                </FormControl>
              </div>
              {fields?.TargetRscUom?.value !== "year" ? (
                <div className="col">
                  <FormControl fullWidth sx={{ minWidth: 200, minHeight: 40 }}>
                    {fields?.TargetRscUom?.value === "quarter" ? (
                      <Select
                        className="text-box custom-react-select p-1"
                        labelid="select"
                        id="select"
                        label="Quarter"
                        menuPortalTarget={document.body}
                        isClearable={true}
                        options={configure.quarter}
                        components={{
                          ValueContainer: CustomValueContainer,
                        }}
                        value={fields.TargetQuarter}
                        onChange={(data) => setValueA("TargetQuarter", data)}
                        placeholder="Quarter"
                        styles={selectStyles}
                      ></Select>
                    ) : (
                      <Select
                        className="text-box custom-react-select p-1"
                        labelid="select"
                        id="select"
                        label="Month"
                        menuPortalTarget={document.body}
                        isClearable={true}
                        options={configure.month}
                        components={{
                          ValueContainer: CustomValueContainer,
                        }}
                        value={fields.TargetRscMonth}
                        onChange={(data) => setValueA("TargetRscMonth", data)}
                        placeholder="Month"
                        styles={selectStyles}
                      ></Select>
                    )}
                  </FormControl>
                </div>
              ) : null}

              <div className="col">
                <FormControl fullWidth sx={{ minWidth: 200, minHeight: 40 }}>
                  <Select
                    className="text-box custom-react-select p-1"
                    labelid="select"
                    id="select"
                    label="Year"
                    menuPortalTarget={document.body}
                    isClearable={true}
                    options={configure.year}
                    components={{
                      ValueContainer: CustomValueContainer,
                    }}
                    value={fields.TargetRscYear}
                    onChange={(data) => setValueA("TargetRscYear", data)}
                    placeholder="Year"
                    styles={selectStyles}
                  ></Select>
                </FormControl>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <FormControl fullWidth sx={{ minWidth: 200, minHeight: 40 }}>
                  <Box className=" filled-box input-border-style common-select-border common-padding-zero">
                    <FieldSelect
                      api="LOOKUPS_LOV"
                      get="Name"
                      set="Code"
                      params={
                        "&finder=FindLookupVals;pLookupCode=CHOLA_CRM_TARGET_TYPES&totalResults=true"
                      }
                      isMulti={false}
                      isRender={true}
                      placeholder="Target Type"
                      value={fields.TargetType}
                      onSelect={(data) => setValueA("TargetType", data)}
                    />
                  </Box>
                </FormControl>
              </div>
            </div>

            {loading ? (
              <Loader />
            ) : (
              <>
                {targetEmp.map((items, index) => (
                  <div className="row employee-list mx-0 mt-4" key={index}>
                    <div className="col-md-1 py-3">
                      <div className="emp-logo-wrap">
                        <p className="emp-logo-txt">AB</p>
                      </div>
                    </div>
                    <div className="col-md-5 ps-4 py-3">
                      <p className="emp-list-name">{items?.CrmRscTargetName}</p>
                      <p className="emp-list-label">
                        {items?.CrmRscTargetDesc}
                      </p>
                    </div>
                    <div className="col-md-4 py-3 d-flex align-items-center justify-content-center">
                      <FormControl fullWidth sx={{ minHeight: 40 }}>
                        <Box className="filled-box">
                          <TextField
                            id="filled-basic"
                            label="Number"
                            labelid="number"
                            variant="filled"
                            value={items?.TargetRscValue}
                            onChange={(e) =>
                              HandleReplaceArray(index, e.target.value)
                            }

                            // placeholder="Number"
                          />
                        </Box>
                      </FormControl>
                    </div>
                    <div className="col-md-2 py-3 d-flex align-items-center justify-content-center">
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReload(items?.CrmTargetId);
                          setDeleteId(items?.CrmRscTargetId);
                        }}
                        className="amlAddRuleLink amlRemoveRule"
                      >
                        -
                      </Link>
                    </div>
                  </div>
                ))}
                {formInputFields.map((item, index) => (
                  <div className="row employee-list mx-0 mt-4" key={index}>
                    <div className="col-md-6 py-3">
                      <Box className=" filled-box input-border-style common-select-border common-padding-zero">
                        <FieldSelect
                          api="CRM_PAY_EMPLOYEE"
                          get="EmployeeName"
                          set="EmployeeId"
                          params={`& AND WorkingStatus='${envConfig.WorkingStatus}' AND UPPER(EmployeeName)`}
                          isMulti={false}
                          isRender={true}
                          placeholder="Select Employee"
                          value={item.EmployeeId}
                          selectedFiled={disableEmployee}
                          onSelect={(data) => handleChangeSelector(index, data)}
                        />
                      </Box>
                    </div>
                    <div className="col-md-4 py-3 d-flex align-items-center justify-content-center">
                      <FormControl fullWidth sx={{ minHeight: 40 }}>
                        <Box className="filled-box">
                          <TextField
                            id="filled-basic"
                            label="Target"
                            labelid="number"
                            variant="filled"
                            name="number"
                            value={item.number}
                            onChange={(e) => handleChangeForm(index, e)}
                          />
                        </Box>
                      </FormControl>
                    </div>
                    <div className="col-md-2 py-3 d-flex align-items-center justify-content-center">
                      {index || formInputFields.length !== 1 ? (
                        <a
                          onClick={() => removeFormFields(index)}
                          className="amlAddRuleLink amlRemoveRule"
                        >
                          -
                        </a>
                      ) : null}

                      {index == formInputFields.length - 1 ? (
                        <a
                          onClick={addFormFields}
                          className="amlAddRuleLink amlAddRule"
                        >
                          +
                        </a>
                      ) : null}
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="amlFilterButtons">
            <Button
              variant="outlined"
              size="small"
              className="automation-footer-btn"
              onClick={onSubmit}
              disabled={processing}
            >
              {processing ? "processing..." : "Save"}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              className="automation-cancel-btn"
              onClick={onCloseModel}
            >
              Cancel
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {deleteId !== null && (
        <ModalConfirm
          loading={modalLoading}
          onYes={() => DeleteEmployee(deleteId)}
          onNo={() => {
            setDeleteId(null);
            detailsCloseClick();
          }}
        />
      )}
    </div>
  );
}

export default Target;
