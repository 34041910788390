import React, { memo, useState } from "react";
import { Box, Grid, Paper } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { components } from "react-select";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Chart from "react-apexcharts";
import ReactSpeedometer from "react-d3-speedometer";
import MenuItem from "@mui/material/MenuItem";
import { Link ,useOutletContext} from "react-router-dom";
import { common } from "helpers";
import QuickAdd from "elements/QuickAdd";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#2A3543",
    color: "rgba(157, 170, 187, 0.5)",
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    padding: 35,
  },
}));
// Floating React select
const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};
const options123 = [
  { value: "JAN", label: "JAN" },
  { value: "FEB", label: "FEB" },
  { value: "MAR", label: "MAR" },
];
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const DrawerHeader1 = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  padding: theme.spacing(2, 3),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
const Dashboard = memo(() => {
  //Example
  const [ permission ] = useOutletContext()

  const [options, setOptions] = useState({
    chart: {
      id: "analytics-chart",
      toolbar: {
        show: false,
      },
      events: {
        mounted: (chart) => {
          chart.windowResizeHandler();
        },
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "35%",
        borderRadius: 10,
      },
    },
    title: {
      text: "Sales",
    },
    stroke: {
      lineCap: "round",
    },
    xaxis: {
      categories: ["JAN", "FEB", "MAR", "APR", "MAY", "JUN"],
    },
  });

  const [series, setSeries] = useState([
    {
      name: "Analytics",
      data: [1950, 1000, 2400, 1230, 700, 1950],
    },
  ]);

  const [donutOptions, setDonutOptions] = useState({
    labels: ["By University", "By Agent", "By Online"],
    legend: {
      show: false,
    },
  });
  const donutSeries = [20, 10, 70];

  const [clientsOptions, setClientOptions] = useState({
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      events: {
        mounted: (chart) => {
          chart.windowResizeHandler();
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    grid: {
      show: false,
    },
    xaxis: {
      categories: ["Jun", "Jul", "Aug", "Sep"],
    },
    yaxis: {
      show: false,
    },
  });
  const [clientSeries, setClientSeries] = useState([
    {
      name: "Clients",
      data: [10, 49, 91, 148],
    },
  ]);

  const [opportunitiesOptions, setOpportunitiesOptions] = useState({
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
      events: {
        mounted: (chart) => {
          chart.windowResizeHandler();
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: "12px",
        colors: ["#fff"],
      },
    },
    legend: {
      show: false,
    },
    grid: {
      show: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#fff"],
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    xaxis: {
      categories: ["01", "02", "03", "04"],
    },
  });
  const opportunitiesSeries = [
    {
      data: [40, 34, 30, 48],
    },
    { data: [30, 48, 17, 40] },
  ];

  const [tasksOptions, setTasksOptions] = useState({
    chart: {
      type: "radialBar",
      width: "100%",
      events: {
        mounted: (chart) => {
          chart.windowResizeHandler();
        },
      },
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "60%",
        },
      },
    },
    stroke: {
      lineCap: "round",
    },
    labels: ["This week"],

    // });
  });
  const tasksSeries = [75];
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [selectedOption, setSelectedOption] = useState(null);
// Select box style
const selectStyles = {
  container: (provided, state) => ({
    ...provided,
    marginTop: 0,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    overflow: "visible",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    position: "absolute",
    top: state.hasValue || state.selectProps.inputValue ? -6 : "17%",
    transition: "top 0.1s, font-size 0.1s",
    fontSize: state.hasValue || state.selectProps.inputValue ? 11 : 13,
    color: "#9daabb",
  }),
};
React.useEffect(() => {
  var isMobileVersion = document.getElementsByClassName("rotate-arrow");
  const fixedHeader = document.getElementById("fixed-header");
  if(isMobileVersion.length > 0){
  fixedHeader.classList.add("menu-shrink");
  fixedHeader.classList.remove("menu-expand");
  }else{
  fixedHeader.classList.remove("menu-shrink");
  fixedHeader.classList.add("menu-expand");
  }
});
  return (
    <>
      <Box>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} className="fixed-header" id="fixed-header">
            <Paper variant="contained" square>
              <Box>
                <Grid container className="dashboard-head">
                  <Grid item xs={12} sm={12} md={5}>
                    <Box>
                      <span>
                        <img
                          src={common.loadImg("chart-icon.svg")}
                          className="me-3"
                        />{" "}
                        Dashboard
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <Typography
                                component={"span"}
                                color="inherit"
                                className="pop-title"
                              >
                                Help and Tips
                              </Typography>
                              <p>
                                <h6 className="pop-heading">
                                  {"This is your dashboard"}
                                </h6>
                              </p>{" "}
                              {
                                "Fabio vel iudice vincam, sunt in culpa qui officia. Curabitur est gravida et libero vitae dictum.Quam diu etiam furor iste tuus nos eludet?"
                              }
                            </React.Fragment>
                          }
                        >
                          
                            <sub className="ms-2 cpoint">
                              <img
                                src={common.loadImg("info-circle-icon.svg")}
                                
                              />
                            </sub>
                         
                        </HtmlTooltip>
                      </span>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={7}>
                    <Box className="head-right-side">
                      <span>
                        <Link to="#">
                          <img
                            src={common.loadImg("tooltip-icon.svg")}
                            className="me-2"
                          />{" "}
                          Get Help{" "}
                        </Link>
                      </span>
                      <span>
                        <Link to="#">
                          <img
                            src={common.loadImg("refresh-icon.svg")}
                            className="me-2 ms-2"
                          />{" "}
                          Refresh{" "}
                        </Link>
                      </span>
                      <span>
                        <Link to="#">
                          <img
                            src={common.loadImg("configure-icon.svg")}
                            className="me-2 ms-2"
                          />{" "}
                          Configure{" "}
                        </Link>
                      </span>

                      <QuickAdd />
                      {/* <span>
                        <img src={common.loadImg("rounded-plus-icon.png")} />{" "}
                      </span> */}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <DrawerHeader1 className="report-header" />
      <Box mt={1} px={5} className="index-page">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={8}>
            <Box mt={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Paper variant="contained" square className="box-wrap">
                    <label>Successful Campaigns</label>
                    <p>Overall</p>
                    <div className="green-text">
                      20{" "}
                      <sup>
                        <img src={common.loadImg("green-uparrow.svg")} />
                      </sup>
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper variant="contained" square className="box-wrap">
                    <label>Leads</label>
                    <p>New this month</p>
                    <div className="orange-text">
                      08{" "}
                      <sup>
                        <img src={common.loadImg("down-arrow-orange.svg")} />
                      </sup>
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper variant="contained" square className="box-wrap">
                    <label>Target</label>
                    <p>First Quater</p>
                    <div className="blue-text">4.8ml</div>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
            <Box mt={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Paper
                    variant="contained"
                    square
                    className="box-container-wrap"
                  >
                    <Box>
                      <Grid container>
                        <Grid item xs={12} md={12} className="analytics-wrp">
                          <div className="col-md-6 analytics-flex">
                            <span className="me-4 ms-4 h4-class col-md-3">
                              Analytics
                            </span>
                            <div className="py-2 col-md-3">
                              <FormControl
                                fullWidth
                                sx={{ minWidth: 160, minHeight: 40 }}
                              >
                                <Select
                                  className="text-box custom-react-select p-1"
                                  labelId="select"
                                  id="select"
                                  label="Month"
                                  menuPortalTarget={document.body}
                                  isClearable={true}
                                  options={options123}
                                  components={{
                                    ValueContainer: CustomValueContainer,
                                  }}
                                  placeholder="Month"
                                  styles={selectStyles}
                                ></Select>
                              </FormControl>
                            </div>
                          </div>
                          <div className="list-wrap  col-md-6">
                            <Tabs
                              value={value}
                              onChange={handleChange}
                              aria-label="basic tabs example"
                            >
                              <Tab label="Leads" {...a11yProps(0)} />
                              <Tab label="Campaigns" {...a11yProps(1)} />
                              <Tab label="Tasks" {...a11yProps(2)} />
                            </Tabs>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                    <TabPanel value={value} index={0}>
                      <Box>
                        <Grid container  display="block">
                          <Grid item xs={12} md={12} lg={12}>
                          <Chart
                              options={options}
                              series={series}
                              type="area"
                              width="100%"
                              // height={300}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <Box>
                        <Grid container>
                          <Grid item  xs={12} md={12} lg={12}>
                            <Chart
                              options={options}
                              series={series}
                              type="area"
                              width="100%"
                              // height={300}
                            />
                            </Grid>
                        </Grid>
                      </Box>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      <Box>
                        <Grid container>
                          <Grid item xs={12} md={12} lg={12}>
                            <Chart
                              options={options}
                              series={series}
                              type="area"
                              width="100%"
                              // height={300}
                            />
                            </Grid>
                        </Grid>
                      </Box>
                    </TabPanel>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
            <Box mt={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Paper
                    variant="contained"
                    square
                    className="box-container-wrap"
                  >
                    <Box>
                      <Grid container>
                        <Grid item xs={12} md={12} className="analytics-wrp">
                          <div className="pt-2 col-md-11 analytics-flex">
                            <span className="me-4 ms-4  h4-class leads-cls">
                              Leads Performance
                            </span>
                            <div className="py-2 col-md-2">
                              <FormControl
                                fullWidth
                                sx={{ minWidth: 160, minHeight: 40 }}
                              >
                                
                                <Select
                                  className="text-box custom-react-select p-1"
                                  labelId="select"
                                  id="select"
                                  label="Year"
                                  menuPortalTarget={document.body}
                                  isClearable={true}
                                  options={options123}
                                  components={{
                                    ValueContainer: CustomValueContainer,
                                  }}
                                  placeholder="Year"
                                  styles={selectStyles}
                                ></Select>
                              </FormControl>
                            </div>
                          </div>
                          <div className="list-wrap col-md-1">
                            <Link to="#">
                              <img
                                src={common.loadImg("arrow-left-circle.svg")}
                                className="me-4"
                              />
                            </Link>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box>
                      <Grid container>
                        <Grid item style={{ width: "100%" }}>
                          <Chart
                            options={options}
                            series={series}
                            type="bar"
                            // width={620}
                            // height={300}
                          />
                          <div className="leads-perform ms-4">
                            <p>Last Completed task 30min ago</p>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Paper variant="contained" square className="main-wrap">
              <Box mt={3}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    className="right-content box-container-wrap"
                  >
                    <div className="py-2 fy-title">
                      <span className="ms-4  h4-class">
                        Current Financial Year Placement
                      </span>
                    </div>
                    <div className="fy-content ms-4 my-3">
                      <label>Report Duration</label>
                      <div>JAN - DECEMBER 2021</div>
                    </div>
                    <div className="chart-content pt-4 px-3">
                      <ReactSpeedometer
                        value={5.5}
                        currentValueText=""
                        width={360}
                        ringWidth={40}
                        needleColor="#f29b05"
                        needleTransitionDuration={4000}
                        needleTransition="easeElastic"
                        needleHeightRatio={0.5}
                        maxSegmentLabels={3}
                        minValue={0}
                        maxValue={10}
                        segments={200}
                      />
                    </div>
                    <div className="fy-sub-head">
                      <label className="py-2">
                        Financial Year Placement Progressed by 4%...{" "}
                        <img src={common.loadImg("info-circle-icon.svg")} />
                      </label>
                      <div className="pb-2">Last Updated 23 sec ago</div>
                    </div>
                    <div className="fy-view-report mt-5">
                      <p>
                        <Link to="#">
                          View Report{" "}
                          <img src={common.loadImg("report-icon.svg")} />
                        </Link>
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
            <Box mt={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Paper
                    variant="contained"
                    square
                    className="box-container-wrap"
                  >
                    <Box>
                      <Grid container>
                        <Grid item xs={12} md={12} className="analytics-wrap">
                          <div>
                            <span className="me-4 ms-4  h4-class">Tasks</span>
                            <div className="py-3">
                              <div className="tasks-count">08</div>
                            </div>
                          </div>
                          <div className="list-wrap">
                            <Link to="#">
                              <img
                                src={common.loadImg("arrow-left-circle.svg")}
                                className="me-4"
                              />
                            </Link>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box>
                      <Grid container>
                        <Grid item style={{ width: "100%" }}>
                          <div className="fy-content ms-4 my-3">
                            <label>Today</label>
                            <div>WEDNESDAY</div>
                            <div>12TH 2021</div>
                          </div>
                          <Chart
                            options={tasksOptions}
                            series={tasksSeries}
                            type="radialBar"
                            // width="400"
                          />
                          <div className="fy-sub-head">
                            <label className="py-2">
                              Your latest Task was to Send a Report to...{" "}
                              <img
                                src={common.loadImg("info-circle-icon.svg")}
                              />
                            </label>
                            <div className="pb-4">
                              Last Completed task 30 min ago
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Box mt={1} mb={4}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Box>
                    <Paper
                      variant="contained"
                      square
                      className="box-container-wrap"
                    >
                      <Box>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            className="analytics-wrap py-2"
                          >
                            <div>
                              <span className="me-4 ms-4  h4-class">
                                Placement
                              </span>
                            </div>
                            <div className="list-wrap">
                              <Link to="#">
                                <img
                                  src={common.loadImg("arrow-left-circle.svg")}
                                  className="me-4"
                                />
                              </Link>
                            </div>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box>
                        <Grid container>
                          <Grid item style={{ width: "100%" }}>
                            <div className="fy-content ms-4 my-3">
                              <label>Report Duration</label>
                              <div>JAN - DECEMBER 2021</div>
                            </div>
                            <Chart
                              options={donutOptions}
                              series={donutSeries}
                              type="donut"
                              // width="400"
                            />
                            <div className="fy-sub-head">
                              <label className="py-2">
                                Your latest Task was to Send a Report to...{" "}
                                <img
                                  src={common.loadImg("info-circle-icon.svg")}
                                />
                              </label>
                              <div className="pb-4">
                                Last Completed task 30 min ago
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Box>
                    </Paper>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box>
                    <Paper
                      variant="contained"
                      square
                      className="box-container-wrap"
                    >
                      <Box>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            className="analytics-wrap py-2"
                          >
                            <div>
                              <span className="me-4 ms-4  h4-class">
                                Clients
                              </span>
                            </div>
                            <div className="list-wrap">
                              <Link to="#">
                                <img
                                  src={common.loadImg("arrow-left-circle.svg")}
                                  className="me-4"
                                />
                              </Link>
                            </div>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box>
                        <Grid container>
                          <Grid item style={{ width: "100%" }}>
                            <div className="client-content ms-4 my-3">
                              <div>Annual Target Progress</div>
                              <div className="green-text">
                                20{" "}
                                <sup>
                                  <img
                                    src={common.loadImg("green-uparrow.svg")}
                                  />
                                </sup>
                              </div>
                            </div>
                            <Chart
                              options={clientsOptions}
                              series={clientSeries}
                              type="line"
                              // width={400}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <div className="client-count">4</div>
                            <div className="client-visit">Today</div>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <div className="client-count">12</div>
                            <div className="client-visit">This Week</div>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <div className="client-count">48</div>
                            <div className="client-visit">This Month</div>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <div className="fy-sub-head">
                              <div className="pt-4 pb-3">
                                Last Completed task 30 min ago
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Box>
                    </Paper>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box>
                    <Paper
                      variant="contained"
                      square
                      className="box-container-wrap"
                    >
                      <Box>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            className="analytics-wrap py-2"
                          >
                            <div>
                              <span className="me-4 ms-4  h4-class">
                                Open Opportunities
                              </span>
                            </div>
                            <div className="list-wrap">
                              <Link to="#">
                                <img
                                  src={common.loadImg("arrow-left-circle.svg")}
                                  className="me-4"
                                />
                              </Link>
                            </div>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box>
                        <Grid container>
                          <Grid item style={{ width: "100%" }}>
                            <div className="client-content ms-4 my-3">
                              <div>New Opportunities</div>
                              <div className="green-text">
                                20{" "}
                                <sup>
                                  <img
                                    src={common.loadImg("green-uparrow.svg")}
                                  />
                                </sup>
                              </div>
                            </div>
                            <Chart
                              options={opportunitiesOptions}
                              series={opportunitiesSeries}
                              type="bar"
                              // width={400}
                              // height={300}
                            />
                            <div className="fy-view-report mt-5">
                              <p>
                                <Link to="#">
                                  View Report{" "}
                                  <img
                                    src={common.loadImg("report-icon.svg")}
                                  />
                                </Link>
                              </p>
                            </div>
                          </Grid>
                        </Grid>
                      </Box>
                    </Paper>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
});

export default Dashboard;
