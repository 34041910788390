import React, { useState, useEffect, forwardRef } from "react";
import { Form } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import Select, { components } from "react-select";
// api
import { common, envConfig, api } from "helpers";
import * as Services from "helpers/service";

const USER_ROLES_LIMIT = 1000;

const FieldSelect = forwardRef((props, ref) => {
  const [mount, setMount] = useState(false);
  const [userInfo] = useState(common.userInfo());
  const [isLoading, setIsLoading] = useState(props.isRender);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [isRender, setIsRender] = useState(props.isRender);
  const [resultList, setResultList] = useState([]);
  const [search, setSearch] = useState("");
  const [hasMore, setHasMore] = useState(false);
  const [limit] = useState(15);
  const [offset, setOffset] = useState(0);
  var delayTimer;


  // effect
  useEffect(() => {
    if (props.isRender == "empty") {
      setIsLoading(false);
      setResultList([]);
    } else if (props.isRender) {
      setIsLoading(true);
      loadData();
    }
  }, [props.isRender]);

  useEffect(() => {
    if (mount) {
      loadData();
    }
  }, [offset]);

  // support
  const getUrl = (type) => {
    let url = "";
    if (props?.auth) {
      url = `${envConfig.AUTH_REST_URL}${Services[props.api]}`;
      let fields = `?fields=${props.get},${props.set}`;
      let transaction = `&q=${
        props.api == "TRANSACTION_STATUS"
          ? `TransactionTypeId="" AND Active='Y' AND `
          : ""
      }`;
      let vTenant = `TenantId=${userInfo.pTenantId}`;
      let query = props.query ? `&${props.query}` : "";
      let search = ` AND UPPER(${props.get}) LIKE '*{SEARCH}*'`;
      

      let orderByLimit = `&orderBy=${props.get}:asc&limit=${USER_ROLES_LIMIT}&offset=${
        type === "search" ? 0 : offset * limit
      }&totalResults=true`;

      return (
        url  +fields + transaction + vTenant + query + search  + orderByLimit
      );
    } else {
      url = `${envConfig.BASE_API}${Services[props.api]}`;
      let fields = `?fields=${props.get},${props.set}`;
      let transaction = `&q=${
        props.api == "TRANSACTION_STATUS"
          ? `TransactionTypeId="" AND Active='Y' AND `
          : ""
      }`;
      let vTenant = `TenantId=${userInfo.pTenantId}`;
      let params = props.params ? props.params : "";
      let search = ` AND UPPER(${props.get}) LIKE '*{SEARCH}*'`;
      let query = props.query ? ` AND ${props.query}` : "";

      let orderByLimit = `&orderBy=${props.get}:asc&limit=${limit}&offset=${
        type === "search" ? 0 : offset * limit
      }&totalResults=true`;

      return (
        url  +fields + params + transaction + vTenant + search + query + orderByLimit
      );
    }
    // url = `${envConfig.AUTH_REST_URL : envConfig.BASE_API}${Services[props.api]}`;
  };

  // api
  const loadData = () => {
    let url = getUrl("load").replace("{SEARCH}", "");

    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: props?.tokenType ? props?.tokenType : "token",
      moreHead: { rfv: 2 },
      cType: 4,
    };
    api.call(
      data,
      (response) => {
        if (response) {
          let data = common.getOptionsOnlyData(
            response.data.items,
            props.get,
            props.set,
            true,
            props?.selectedFiled ? props?.selectedFiled : []
          );
          setResultList((oldData) => {
            return [...new Set([...oldData, ...data])];
          });
          setMount(true);
          setIsLoadingMore(false);
          setHasMore(response.data.hasMore);
          if (!props.isRender) {
            setIsMenuOpen(true);
            setIsFocus(true);
          }
        }
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  // load
  const loadOptions = async (inputValue) => {
    var url = getUrl("search").replace("{SEARCH}", inputValue);
    let result = await new Promise((resolve, reject) => {
      clearTimeout(delayTimer);
      delayTimer = setTimeout(() => {
        setSearch(inputValue);
        let data = {
          url: url,
          type: "dynamic",
          method: "GET",
          auth: props?.tokenType ? props?.tokenType : "token",
          moreHead: { rfv: 2 },
          cType: 4,
        };
        api.call(
          data,
          (response) => {
            if (response) {
              let data = common.getOptionsOnlyData(
                response.data.items,
                props.get,
                props.set,
                true,
                props?.selectedFiled ? props?.selectedFiled : []
              );
              resolve(data);
            } else {
              resolve([]);
            }
          },
          (error) => {
            resolve([]);
          },
          (final) => {}
        );
      }, 1000);
    });
    return result;
  };

  // handler
  const onChange = (value) => {
    props.onSelect(value, props.name);
  };


  const onInputChange = (value) => {
    if (value === "") {
      setSearch("");
    }
  };

  const onFocus = () => {
    if (!isRender) {
      setIsLoading(true);
      setIsRender(true);
    }
  };

  const loadMore = () => {
    if (hasMore && !search && !isLoadingMore) {
      setIsLoadingMore(true);
      setOffset((prev) => ++prev);
    }
  };
  // Floating React select
  const { ValueContainer, Placeholder } = components;

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };
  return isLoading ? (
    <Form.Control
      type="text"
      defaultValue={"Loading..."}
      readOnly={true}
      className="loading-text-form-control"
    />
  ) : (
    <AsyncSelect
      ref={ref}
      isMulti={props.isMulti}
      isClearable={true}
      value={props.value}
      defaultOptions={resultList}
      loadOptions={loadOptions}
      onChange={onChange}
      onFocus={onFocus}
      menuIsOpen={isMenuOpen}
      onInputChange={onInputChange}
      onMenuOpen={() => {
        setIsMenuOpen(true);
      }}
      isOptionDisabled={(option) => option.disabled}
      onMenuClose={() => {
        setIsMenuOpen(false);
      }}
      autoFocus={isFocus}
      onMenuScrollToBottom={() => loadMore()}
      isLoading={isLoadingMore}
      menuPlacement="auto"
      label={props.placeholder}
      placeholder={props.placeholder}
      menuPortalTarget={document.querySelector("body")}
      components={{
        ValueContainer: CustomValueContainer,
      }}
      styles={{
        container: (base, state) => ({
          ...base,
          marginTop: 0,
        }),
        valueContainer: (base, state) => ({
          ...base,
          overflow: "visible",
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        placeholder: (base, state) => ({
          ...base,
          position: "absolute",
          top: state.hasValue || state.selectProps.inputValue ? -15 : "10%",
          transition: "top 0.1s, font-size 0.1s",
          fontSize: state.hasValue || state.selectProps.inputValue ? 11 : 13,
          color: "#9daabb",
        }),
      }}
    />
  );
});

export default FieldSelect;
