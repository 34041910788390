import React, { memo, useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  IconButton,
  Grid,
  Typography,
  Divider,
} from "@mui/material";
import { common } from "helpers";
import Select from "react-select";
import FormControl from "@mui/material/FormControl";
import DisabledByDefaultOutlinedIcon from "@mui/icons-material/DisabledByDefaultOutlined";
const setting = [{ value: "Lead to mapping", label: "Lead to mapping" }];

const studentOptions = [{ value: "Amount Payed", label: "Amount Payed" }];

const ConversionMapping = memo(() => {
  const [settingOption, setSettingOption] = useState(setting[0]);
  const [studentAmount, setStudentAmount] = useState(studentOptions[0]);

  const onSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <Box px={5} mt={3} className="template-box-border">
      <div className="row">
        <div className="col-12 mt-2 ">
          <div className="card border-color-F2F9FD">
            <div className="card-body header-border-padding  p-0">
              <Box alignItems="center" justifyContent="center" className="">
                <Box py={3}>
                  <Grid
                    container
                    spacing={3}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item>
                          <Grid container alignItems="center">
                            <Grid item>
                              <Link to="/setting/view/configure">
                                <img
                                  alt={"back"}
                                  className="mx-2"
                                  src={common.loadImg(
                                    "iconly-light-arrow-down-circle.svg"
                                  )}
                                />
                              </Link>
                            </Grid>
                            <Grid item>
                              <Typography className="page-title">
                                Conversion Mappings
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <FormControl
                            sx={{ m: 1, minWidth: { xs: 220, lg: 120 } }}
                            m-0
                            className="dropdown w-100"
                          >
                            <Select
                              className="conversion-mapping-setting w-100 p-100"
                              defaultValue={settingOption}
                              onChange={setSettingOption}
                              options={setting}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item className="close-icn-border ">
                      <Box className="revert-close revert-close1">
                        <Button className="save-note" type="button">
                          Revert and Close
                          <Link to="/setting/view/configure">
                            {" "}
                            <img
                              src={common.loadImg("closeKanban.svg")}
                              alt="close"
                            />
                          </Link>
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Divider />
                </Box>

                <div className="row p-4">
                  <div className="col-lg-6">
                    <FormControl
                      sx={{ m: 1, minWidth: { xs: 220, lg: 120 } }}
                      m-0
                      className="dropdown w-100"
                    >
                      <label className="template-label-font template-label-position">
                        Amount Received from Student
                      </label>
                      <Select
                        className="module-list w-100 p-100"
                        defaultValue={studentAmount}
                        onChange={setStudentAmount}
                        options={studentOptions}
                      />
                    </FormControl>
                  </div>
                  <div className="col-lg-6">
                    <FormControl
                      sx={{ m: 1, minWidth: { xs: 220, lg: 120 } }}
                      m-0
                      className="dropdown w-100"
                    >
                      <Select
                        className="conversion-mapping-list w-100 p-100"
                        defaultValue={studentAmount}
                        onChange={setStudentAmount}
                        options={studentOptions}
                      />
                    </FormControl>
                  </div>
                  <div className="col-lg-6">
                    <FormControl
                      sx={{ m: 1, minWidth: { xs: 220, lg: 120 } }}
                      m-0
                      className="dropdown w-100"
                    >
                      <Select
                        className="conversion-mapping-list w-100 p-100"
                        defaultValue={studentAmount}
                        onChange={setStudentAmount}
                        options={studentOptions}
                      />
                    </FormControl>
                  </div>
                  <div className="col-lg-6">
                    <FormControl
                      sx={{ m: 1, minWidth: { xs: 220, lg: 120 } }}
                      m-0
                      className="dropdown w-100"
                    >
                      <Select
                        className="conversion-mapping-list w-100 p-100"
                        defaultValue={studentAmount}
                        onChange={setStudentAmount}
                        options={studentOptions}
                      />
                    </FormControl>
                  </div>
                  <div className="col-lg-6">
                    <FormControl
                      sx={{ m: 1, minWidth: { xs: 220, lg: 120 } }}
                      m-0
                      className="dropdown w-100"
                    >
                      <Select
                        className="conversion-mapping-list w-100 p-100"
                        defaultValue={studentAmount}
                        onChange={setStudentAmount}
                        options={studentOptions}
                      />
                    </FormControl>
                  </div>
                  <div className="col-lg-6">
                    <FormControl
                      sx={{ m: 1, minWidth: { xs: 220, lg: 120 } }}
                      m-0
                      className="dropdown w-100"
                    >
                      <Select
                        className="conversion-mapping-list w-100 p-100"
                        defaultValue={studentAmount}
                        onChange={setStudentAmount}
                        options={studentOptions}
                      />
                    </FormControl>
                  </div>
                  <div className="col-lg-6">
                    <FormControl
                      sx={{ m: 1, minWidth: { xs: 220, lg: 120 } }}
                      m-0
                      className="dropdown w-100"
                    >
                      <Select
                        className="conversion-mapping-list w-100 p-100"
                        defaultValue={studentAmount}
                        onChange={setStudentAmount}
                        options={studentOptions}
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="row p-4">
                  <div className="col-lg-12 text-center">
                    <Button
                      className="sign-in-btn "
                      onClick={(e) => onSubmit(e)}
                    >
                      Update and Save
                    </Button>
                  </div>
                </div>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
});

export default ConversionMapping;
