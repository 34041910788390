import React from "react";

//import Popover from "@mui/material/Popover";
import Popper from '@mui/material/Popper';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Typography from "@mui/material/Typography";

export const OptionPopover = (props) => {
  const {
    item,
    rowIndex,
    editMoreOpen,
    editAnchorEl,
    handleClickOpen,
    handleRoleEditClose,
    clickAwayHandler
  } = props;
  
  return (
    
    <ClickAwayListener onClickAway={clickAwayHandler}>
    <Popper
      className="notify-pop"
      id={`role-edit-popover_${rowIndex}`}
      open={editMoreOpen}
      anchorEl={editAnchorEl}
      onClose={handleRoleEditClose}
      
    >
      <Typography
        id={item.GroupId}
        sx={{ p: 2 }}
        className="text-capitalize pointer bg-style-wrap menu-item crm-cp"
        onClick={(event) => {
          event.stopPropagation();
          handleRoleEditClose();
          handleClickOpen(true);
        }}
      >
        Edit
      </Typography>
    </Popper>
    </ClickAwayListener>
    
  );
};
