import React, { memo } from "react";
import Paper from "@mui/material/Paper";
import { useOutletContext } from "react-router-dom";
import StaticTale from "elements/staticTable";
import product from "assets/files/static/product.json";
import AccessDenied from "elements/AccessDenied";
import { common } from "helpers";

const ProductServices = memo(() => {
  const [permission] = useOutletContext();
  return (
    <div className="default-bg">
      <div style={{ padding: "20px" }}>
        {common.givenPermission(permission,'vendor:products','read') ? <AccessDenied /> : <Paper
          sx={{ width: "100%", mb: 2 }}
          className={`container responsive-table p-0`}
        >
          <StaticTale module={product}
            add={!common.givenPermission(permission,'vendor:products','add')} 
            update={!common.givenPermission(permission,'vendor:products','update')}
            delete={!common.givenPermission(permission,'vendor:products','delete')}
            safariclassName={"safari-style"}
            />
        </Paper>}
       
      </div>
    </div>
  );
});

export { ProductServices };
