import React, { memo, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import { common } from "helpers";
import ListView from "elements/ListView";
import DynamicForm from "./DynamicForm";

const EnquiryMembers = memo(({ handleExpanded, isExpand, id }) => {
  let { mod } = useParams();
  const [isForm, setIsForm] = useState(false);
  const [listId, setListId] = useState(0);
  const [enquiryId, setEnquiryId] = useState(id);
  const [reload, setReload] = useState(new Date());
  const [module, setModule] = useState("");
  const [formType, setFormType] = useState("add");

  useEffect(() => {
    setEnquiryId(id);
  }, [listId]);

  const changeFormType = (val, type, data, ids) => {
    if (data) {
      setListId(data.ListDetailId);
    } else {
      setListId(0);
    }
    setModule("enquiryMember");
    setIsForm(val);
    setFormType(type);
  };

  return (
    <Box className="template-box-border">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box className="crm-submenu-wrap">
          <Grid
            container
            spacing={2}
            alignItems="center"
            className="subheader-elements"
          >
            <Grid item>
              {isExpand === "detail" ? (
                <Typography
                  className="expand pointer"
                  onClick={() => handleExpanded("tab")}
                >
                  Expand{" "}
                  <img src={common.loadImg("expandkanban.svg")} alt="expand" />
                </Typography>
              ) : (
                <Typography
                  className="expand pointer"
                  onClick={() => handleExpanded("detail")}
                >
                  Collapse{" "}
                  <img
                    src={common.loadImg("expandkanban.svg")}
                    alt="Collapse"
                  />
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
      {!isForm && (
        <div className="row">
          <div className={"col-md-12"}>
            <Grid item>
              <Box>
                <div className="role-border permission-table">
                  {enquiryId > 0 && (
                    <ListView
                      module={"enquiryMember"}
                      pModule={mod}
                      changeForm={(val, type, item) =>
                        changeFormType(val, type, item)
                      }
                      query={{ ListId: enquiryId }}
                      sort="CreatedOn:desc"
                      reload={reload}
                      type={"subMenu"}
                      data="LeadConverted,FirstName,LastName,CompanyName,Email"
                      className={"table-scroll-position-members"}
                      isExpand={isExpand}
                      id={id}
                    />
                  )}
                </div>
              </Box>
            </Grid>
          </div>
        </div>
      )}
      {isForm && (
        <DynamicForm
          module={"enquiryMember"}
          data={{
            value1: listId > 0 ? listId.toString() : "",
            value2: enquiryId > 0 ? enquiryId.toString() : "",
          }}
          disabled={{ LeadConverted: true }}
          closeForm={(val, type, data, id) =>
            changeFormType(val, type, data, id)
          }
        />
      )}
    </Box>
  );
});
export { EnquiryMembers };
