import React from "react"
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
    FormControl,
    Button,
    Grid,
    Popover,
    Box,
    Divider,
    MenuItem,
    Select,
  } from "@mui/material";

const ModalConfirm = (props) => {

    return ( <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEnforceFocus
        disableScrollLock
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Confirmation"}
        </DialogTitle>
        <Box>
          <Divider />
        </Box>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this record(s)?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props?.onNo}
            color="primary"
            className="automation-cancel-btn"
          >
            No
          </Button>
          <Button
            onClick={props?.onYes}
            color="primary"
            className="automation-footer-btn"
            autoFocus
            disabled={props?.loading ?? false}
          >
            {props?.loading ? "Wait...." : "Yes"}
          </Button>
        </DialogActions>
      </Dialog>)
}

export default ModalConfirm