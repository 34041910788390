import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import FieldSelect from "elements/FieldSelect";
import StatusBar from "elements/StatusBar";
import {
  api,
  common,
  eduTechConfig,
  eduTechSerivces,
  envConfig,
  tools,
} from "helpers";
import * as Services from "helpers/service";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import DatePicker from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import "react-phone-input-2/lib/style.css";
import { Link, useNavigate } from "react-router-dom";
import Select, { components } from "react-select";
import DeleteIcon from "../assets/img/actionDelete.svg";
import { CRM_PAY_EMPLOYEE } from "helpers/service";

const selectDefault = { label: "", value: "" };
const reviewStatus = [
  { value: "missing", label: "Missing" },
  { value: "under_review", label: "Under Review" },
  { value: "reviewed", label: "Reviewed" },
];
const canApprStatus = [
  { value: "accepted", label: "Accepted" },
  { value: "rejected", label: "Rejected" },
];
const directPayVerify = [
  { value: "paid", label: "Verified" },
  { value: "invalid", label: "Invalid" },
];
const interviewStatus = [
  { value: "Scheduled", label: "Scheduled" },
  { value: "Attended", label: "Attended" },
  { value: "Not Attended", label: "Not Attended" },
];

function ETDealForm(props) {
  const navigate = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false);
  const [status, setStatus] = useState("process");
  const [paymentStatus, setPaymentStatus] = useState(true);
  const [imgPreview, setImgPreview] = useState(false);
  const [cancelDA, setCancelDA] = useState(false);
  const [imgPreviewSrc, setImgPreviewSrc] = useState("");
  const [withdrawOption, setWithdrawOption] = useState([
    { value: "Not withdrawn", label: "Not withdrawn" },
    { value: "Withdrawn by user", label: "Withdrawn by user" },
    { value: "Withdrawn by admin", label: "Withdrawn by admin" },
  ]);
  const [fields, setFields] = useState({}); //crm deal response
  const [fileA, setFileA] = useState([]);
  const [fileB, setFileB] = useState([]);
  const [fileC, setFileC] = useState([]);
  const [dealStatus, setDealStatus] = useState(eduTechConfig.DealStatus);
  const [mSel, setMSel] = useState([]);
  const initialMFields = {
    field: "",
    desc: "",
    status: "missing",
  };
  const [mFields, setMFields] = useState([initialMFields]);
  const [openEMail, setOpenEMail] = useState(false);
  const [isEmailSubmit, setIsEmailSubmit] = useState(false);
  const [emailHistory, setEmailHistory] = useState([]);
  const [appInfo, setAppInfo] = useState(null);
  const intakeFields = {
    intakeDiffer: false,
    intakeMonthYear: null,
    intakeReason: "",
  };
  const [intake, setIntake] = useState(intakeFields);
  const [courseIntMonthYear, setCourseIntMonthYear] = useState([]);
  const [files, setFiles] = useState([]);
  const [salesPerson, setSalesPerson] = useState({});

  //effect
  useEffect(() => {
    if (props.data.value1 == "") {
      navigate("/crm/deal/list");
    } else {
      getEditData();
    }
  }, []);

  // Select box style
  const selectStyles = {
    container: (provided, state) => ({
      ...provided,
      marginTop: 0,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      overflow: "visible",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      position: "absolute",
      top: state.hasValue || state.selectProps.inputValue ? -15 : "10%",
      transition: "top 0.1s, font-size 0.1s",
      fontSize: state.hasValue || state.selectProps.inputValue ? 11 : 13,
      color: "#9daabb",
    }),
  };

  // edit
  const getEditData = () => {
    let url = envConfig.BASE_API + Services.CRM_DEALS + "/" + props.data.value1;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };
    api.call(
      data,
      async (response) => {
        if (response.status == 200) {
          // set value
          setCRMDealData(response.data);
          //get email history
          let result = await eduTechSerivces.getAppEmailHistory(
            response.data.Cattr2
          );
          setEmailHistory(result.data || []);

          let res = await eduTechSerivces.getApplicationInfo(
            response.data.Cattr2
          );
          setAppInfo(res);
          let courseIntake = [];
          res?.course?.intake?.map((yObj) => {
            yObj.month?.map((mObj) => {
              courseIntake.push({
                label: yObj.year + " " + common.getMonthList()[mObj - 1].label,
                value: yObj.year + " " + mObj,
              });
            });
          });
          setCourseIntMonthYear(courseIntake);
        }
      },
      (error) => {
        setStatus("error");
      },
      (final) => {}
    );
  };
  const setCRMDealData = (data) => {
    let newFields = common.reParse(data);
    let mValidStatus = true;

    // get sales person details
    getEmpByEmpId(data.SalesPersonId);

    newFields.SalesPersonId = {
      label: data.SalesPersonIdDesc,
      value: data.SalesPersonId,
    }; //sales person
    setDealStatus(common.enableDealStatus(data.ApprStatus));
    newFields.Cattr39 = data.Cattr39 || "N";
    newFields.Cattr40 = data.Cattr40 || "N";
    newFields.Cattr28 = { label: data.Cattr28, value: data.Cattr28 };
    newFields.ApprStatus = { label: data.ApprStatus, value: data.ApprStatus }; //deal status
    newFields.Cattr16 = { label: data.Cattr16, value: data.Cattr16 }; //direct payemnt status
    newFields.Cattr20 = { label: data.Cattr20, value: data.Cattr20 }; //canRequest acceptance status
    newFields.Cattr26 = { label: data.Cattr26, value: data.Cattr26 }; //interview status
    newFields.Cattr30 = { label: data.Cattr30, value: data.Cattr30 }; //pre cas interview status
    if (data.Clattr6 && data.Cattr6) {
      let mField = JSON.parse(data.Clattr6);
      setMFields(mField);
      setMSel(data.Cattr6.split(","));
      mField.map((mobj) => {
        if (mobj.status != "reviewed") {
          mValidStatus = false;
        }
      });
    }
    newFields.emailContent = "";
    setFields(newFields);
    if (fields?.Cattr20?.value === "accepted") {
      setCancelDA(true);
    }

    if (data.Cattr23 == "Direct" && data.ApprStatus == "Unconditionals") {
      let dStatus = eduTechConfig.DealStatus;
      dStatus.map((obj) => {
        if (obj.value == "Fees Paid") {
          obj.disabled = false;
        }
      });
      setDealStatus(dStatus);
    }

    if (data.ApprStatus === "Conditionals") {
      let dStatus = eduTechConfig.DealStatus;
      if ((mValidStatus && data.Cattr40 == "Y") || data.Cattr40 == "N") {
        dStatus.map((obj) => {
          if (obj.value == "Unconditionals") {
            obj.disabled = false;
          }
        });
      } else {
        dStatus.map((obj) => {
          if (obj.value == "Unconditionals") {
            obj.disabled = true;
          }
        });
      }

      setDealStatus(dStatus);
    }

    if (data.ApprStatus === "Fees Paid") {
      let dStatus = eduTechConfig.DealStatus;
      dStatus.map((obj) => {
        if (obj.value == "Acceptance") {
          obj.disabled = false;
        }
      });
      setDealStatus(dStatus);
    }

    if (data.ApprStatus === "Unconditionals") {
      setPaymentStatus(false);
    }

    if (
      ["Visa Applied", "Visa Granted", "Visa Rejected"].includes(
        data.ApprStatus
      )
    ) {
      let dStatus = eduTechConfig.DealStatus;
      dStatus.map((obj) => {
        if (obj.value == "Visa Applied") {
          obj.disabled = false;
        }
        if (obj.value == "Visa Granted") {
          obj.disabled = false;
        }
        if (obj.value == "Visa Rejected") {
          obj.disabled = false;
        }
      });
      setDealStatus(dStatus);
    }

    if (data.ApprStatus === "Visa Granted") {
      let dStatus = eduTechConfig.DealStatus;
      dStatus.map((obj) => {
        if (obj.value == "Enrolled") {
          obj.disabled = false;
        }
      });
      setDealStatus(dStatus);
    }

    if (data.ApprStatus === "Enrolled") {
      let dStatus = eduTechConfig.DealStatus;
      dStatus.map((obj) => {
        if (obj.value == "Enrolled") {
          obj.disabled = false;
        }
      });
      setDealStatus(dStatus);
    }
    setStatus("success");
  };

  // Missing Select handler
  const onMissingSelect = (data, name, idx) => {
    setMFields((oldData) => {
      let newData = common.reParse(oldData);
      newData[idx][name] = name == "desc" ? data : data.value;
      return newData;
    });
    if (name == "field" && data.value && !mSel.includes(data.label)) {
      let selected = mFields.map((a) => a.field);
      selected.push(data.value);
      setMSel(selected);
    }
  };

  const addMRow = (e) => {
    e.preventDefault();
    setMFields((oldData) => {
      let newData = common.reParse(oldData);
      newData.push({
        field: "",
        desc: "",
        status: "missing",
      });
      return newData;
    });
  };

  const removeMRow = (e, idx) => {
    e.preventDefault();
    setMFields((oldData) => {
      let newData = common.reParse(oldData);
      let removeLabel = newData[idx].field;
      newData.splice(idx, 1);
      return newData;
    });
  };

  const intakeChange = (e, name) => {
    let obj = common.reParse(intake);
    if (name == "intakeDiffer") {
      obj[name] = e.target.checked ? "Y" : "N";
      setIntake(obj);
    } else if (["intakeMonthYear"].includes(name)) {
      obj[name] = e;
      setIntake(obj);
    } else {
      obj[name] = e.target.value;
      setIntake(obj);
    }
  };

  const onFieldChange = (e, name) => {
    let newFields = common.reParse(fields);
    newFields[name] = e.target.checked ? "Y" : "N";
    setFields(newFields);
  };

  // Select handler
  const onSelectHandler = (data, name) => {
    let newFields = common.reParse(fields);
    newFields[name] = data;
    setFields(newFields);
  };

  //textarea, text, , datepicker handler
  const onChange = (e) => {
    let newFields = common.reParse(fields);
    if (
      e &&
      e.target &&
      (e.target.type == "text" || e.target.type == "textarea")
    ) {
      const { name, value } = e.target;
      newFields[name] = value;
    } else {
      newFields["Dattr1"] = e;
    }
    newFields.isUpdate = true;
    setFields(newFields);
  };

  const onDateChange = (name, value) => {
    setFields((prev) => ({ ...prev, [name]: value, isUpdate: true }));
  };

  // file handler
  const onFileChangeA = (e) => {
    const files = e.target.files;
    setFileA((prev) => {
      let nData = [...files];
      let result = [];
      if (nData.length > 3) {
        result = prev;
        common.notify("E", "Allowed only 3 files");
      } else {
        result = nData;
      }
      return result;
    });
  };

  const onFileChangeB = (e) => {
    const files = e.target.files;
    setFileB((prev) => {
      let nData = [...files];
      let result = [];
      if (nData.length > 3) {
        result = prev;
        common.notify("E", "Allowed only 3 files");
      } else {
        result = nData;
      }

      return result;
    });
  };

  const onFileChangeC = (e) => {
    const files = e.target.files;
    setFileC((prev) => {
      let nData = [...files];
      let result = [];
      if (nData.length > 3) {
        result = prev;
        common.notify("E", "Allowed only 3 files");
      } else {
        result = nData;
      }

      return result;
    });
  };

  const delImage = (e, src) => {
    e.preventDefault();
    let newFields = common.reParse(fields);
    if (src == "fileA") {
      setFileA([]);
      newFields.Cattr8 = "";
    } else if (src == "fileB") {
      setFileB([]);
      newFields.Cattr12 = "";
    } else if (src == "fileC") {
      setFileC([]);
      newFields.Cattr21 = "";
    }
    setFields(newFields);
  };

  const uploadFile = async (idx) => {
    var FormDataObj = new FormData();
    if (idx == "fileA") {
      fileA.forEach((i, k) => {
        FormDataObj.append("file", i);
      });
    } else if (idx == "fileB") {
      fileB.forEach((i, k) => {
        FormDataObj.append("file", i);
      });
    } else if (idx == "fileC") {
      fileC.forEach((i, k) => {
        FormDataObj.append("file", i);
      });
    }

    let data = {
      url: "common_upload_direct",
      method: "POST",
      auth: "config",
      body: FormDataObj,
      ssr: true,
      cType: 6,
    };
    let resultData = await eduTechSerivces.call(data);
    return resultData;
  };

  const previewImage = (e, file) => {
    e.preventDefault();
    setImgPreview(true);
    setImgPreviewSrc(file);
  };

  const handleClose = () => {
    setImgPreview(false);
    setImgPreviewSrc("");
  };

  const onSubmit = async () => {
    setIsSubmit(true);
    let aResult = {};
    let bResult = {};
    let cResult = {};
    let error = "";
    if (fields.ApprStatus.value == "Conditionals") {
      // upload 1
      if (fileA?.length) {
        aResult = await uploadFile("fileA");
        if (aResult.status !== 200) {
          error = aResult.data.error;
        }
      }
    } else if (fields.ApprStatus.value == "Unconditionals") {
      // upload 2
      if (!error && fileB?.length) {
        bResult = await uploadFile("fileB");
        if (bResult.status !== 200) {
          error = bResult.data.error;
        }
      }
    } else if (fields.ApprStatus.value == "Acceptance") {
      // upload 3
      if (!error && fileC?.length) {
        cResult = await uploadFile("fileC");
        if (cResult.status !== 200) {
          error = cResult.data.error;
        }
      }
    }
    if (error) {
      common.snack("E", error);
      setIsSubmit(false);
    } else {
      let inputs = await formInputs(aResult, bResult, cResult);
      if (inputs.valid == true && inputs.dealData && inputs.appData) {
        let url =
          envConfig.BASE_API +
          Services.CRM_DEAL_OPPORTUNITY +
          "/" +
          props.data.value1;

        let data = {
          url: url,
          type: "dynamic",
          method: "PATCH",
          body: JSON.stringify(inputs.dealData),
          auth: "token",
          cType: 4,
        };
        api.call(
          data,
          async (response) => {
            window.scrollTo(0, 0);
            setIsSubmit(false);
            if (Object.keys(inputs.appData).length > 0) {
              await eduTechSerivces.updateApplicationInfo(
                fields.Cattr2,
                inputs.appData
              );
            }
            setDealStatus(common.enableDealStatus(response.data.ApprStatus));
            // if (typeof props.onSave !== "undefined") {
            //   props.onSave(response);
            setFileA([]);
            setFileB([]);
            setFileC([]);
            setCRMDealData(response.data);
            setIntake(intakeFields);
            // }
            common.snack("S", "Successfully Updated");
          },
          (error) => {
            setIsSubmit(false);
          },
          (final) => {}
        );
      } else {
        setIsSubmit(false);
      }
    }
  };

  const formInputs = async (aResult, bResult, cResult) => {
    let appData = {}; //Edutech app fields
    let uFields = {}; //CRM Deal update fields
    let valid = true;

    if (
      intake.intakeDiffer === "Y" &&
      (!intake.intakeMonthYear.value || !intake.intakeReason)
    ) {
      valid = false;
      common.snack(
        "E",
        "You have selected intake differ, please fill all intake fields"
      );
    }

    if (
      intake.intakeDiffer === "Y" &&
      intake.intakeMonthYear.value &&
      intake.intakeReason
    ) {
      let intakes = intake.intakeMonthYear.value.split(" ");
      appData.intake = {
        intakeYear: intakes[0],
        intakeMonth: intakes[1],
        intakeReason: intake.intakeReason,
      };
      appData.type = "review";
      appData.category = "intake_diff";
    }

    //Deal status
    if (fields.ApprStatus.value) uFields.ApprStatus = fields.ApprStatus.value;
    if (fields.ApprStatus.value == "Submissions") {
      appData.status = "submitted_to_university";
    }
    if (fields.ApprStatus.value == "Conditionals") {
      uFields.Cattr39 = fields.Cattr39;
      uFields.Cattr40 = fields.Cattr40;
      let validCndOffer = true;
      if (aResult.data?.link?.length || fields.Cattr8) {
        let url = aResult.data?.link[0] || fields.Cattr8;
        appData.status = "conditional_offer";
        appData.offerLetter = url; //offerletter url
        uFields.Cattr8 = url; //offerletter url
        appData.missingInfo = []; //clear missing info
      } else {
        validCndOffer = false;
        valid = false;
        common.snack("E", "Please upload conditional offer letter");
        setIsSubmit(false);
      }
      if (validCndOffer) {
        if (fields.Cattr39 == "Y" || fields.Cattr40 == "Y") {
          if (fields.Cattr39 == "Y") {
            if (fields.Cattr5 && fields.Dattr1) {
              appData.interViewLocation = fields.Cattr5; //interview loc
              appData.interviewDate = common.formatDate(
                fields.Dattr1,
                "YYYY-MM-DDTHH:mm:ssZ"
              );
              appData.interviewStatus = fields.Cattr26?.value || "Scheduled";
              uFields.Cattr26 = fields.Cattr26?.value || "Scheduled";

              uFields.Cattr5 = fields.Cattr5;
              uFields.Dattr1 = common.formatDate(
                fields.Dattr1,
                "YYYY-MM-DDTHH:mm:ssZ"
              );
            } else {
              valid = false;
              common.snack("E", "Please fill the interview location and date");
              setIsSubmit(false);
            }
          }
          if (fields.Cattr40 == "Y") {
            if (mFields.length > 0) {
              let mData = [];
              mFields.map((obj) => {
                if (obj.field && obj.desc && obj.status) {
                  mData.push({
                    field: obj.field,
                    description: obj.desc,
                    status: obj.status,
                  });
                } else {
                  valid = false;
                }
              });
              if (valid == false) {
                valid = false;
                common.snack("E", "please fill the missing information");
                setIsSubmit(false);
              } else {
                appData.missingInfo = mData;

                var filtered = mSel.filter(Boolean);
                uFields.Cattr6 = filtered.join(",");
                uFields.Clattr6 = JSON.stringify(mFields);
              }
            }
          }
        } else {
          valid = false;
          common.snack("E", "Please select at least one conditional offer");
          setIsSubmit(false);
        }
      }
    } else if (fields.ApprStatus.value == "Unconditionals") {
      if (bResult.data?.link?.length) {
        let url = bResult.data?.link[0];
        appData.status = "unconditional_offer";
        appData.offerLetter = url; //offerletter url

        uFields.Cattr12 = url;
        //clear missing fields
        uFields.Cattr6 = "";
        uFields.Clattr6 = null;
        if (fields?.Cattr32 === "Y") {
          uFields.Cattr32 = fields?.Cattr32;
          appData.status = "course_payment";
        }
      } else if (fields.Cattr12 == null) {
        valid = false;
        common.snack("E", "Please upload unconditional offer letter");
        setIsSubmit(false);
      } else {
        if (fields?.Cattr32 === "Y") {
          uFields.Cattr32 = fields?.Cattr32;
          appData.status = "course_payment";

          if (fields.Cattr23 == "Direct") {
            if (fields.Cattr16.value == "paid") {
              fields.ApprStatus = {
                label: "Fees Paid",
                value: "Fees Paid",
                disabled: true,
              };
              uFields.ApprStatus = "Fees Paid";
            }
            appData.action = fields.Cattr16.value;
            uFields.Cattr16 = fields.Cattr16.value;
          }
        }
      }
    } else if (
      fields.ApprStatus.value == "Ready for Payment" &&
      fields.Cattr23 == "Direct"
    ) {
      appData.status = "course_payment";
      appData.action = fields.Cattr16.value;
      uFields.Cattr16 = fields.Cattr16.value;
      if (fields.Cattr16.value == "paid") {
        fields.ApprStatus = {
          label: "Fees Paid",
          value: "Fees Paid",
          disabled: true,
        };
        uFields.ApprStatus = "Fees Paid";
      }
    } else if (fields.ApprStatus.value == "Ready for Payment") {
      appData.status = "course_payment";
    } else if (fields.ApprStatus.value == "Fees Paid") {
      //after course fee pay processing the interview
      appData.status = "course_payment";
      appData.interViewLocation = fields.Cattr29; //interview loc
      appData.interviewDate = common.formatDate(
        fields.Dattr4,
        "YYYY-MM-DDTHH:mm:ssZ"
      );
      appData.interviewStatus = fields.Cattr30?.value || "Scheduled";
      uFields.Cattr30 = fields.Cattr30?.value || "Scheduled";
      uFields.Cattr29 = fields.Cattr29;
      uFields.Dattr4 = common.formatDate(fields.Dattr4, "YYYY-MM-DDTHH:mm:ssZ");
    } else if (fields.ApprStatus.value == "Acceptance") {
      if (cResult.data?.link?.length || fields.Cattr21) {
        let url = cResult.data?.link[0] || fields.Cattr21;
        appData.status = "accepted";
        appData.casDocs = url; //acceptance url

        uFields.Cattr21 = url;
      } else {
        valid = false;
        common.snack("E", "Please upload acceptance letter");
        setIsSubmit(false);
      }
    } else if (fields.ApprStatus.value == "Visa Applied") {
      appData.status = "visa";
      appData.action = "applied";
      appData.description = "";
    } else if (fields.ApprStatus.value == "Visa Rejected") {
      appData.status = "visa";
      appData.action = "rejected";
      appData.description = fields.Cattr18;
      uFields.Cattr18 = fields.Cattr18;
    } else if (fields.ApprStatus.value == "Visa Granted") {
      appData.status = "visa";
      appData.action = "approved";
      appData.description = "";
    } else if (fields.ApprStatus.value == "Enrolled") {
      appData.status = "enrolled";
      appData.description = "Course Enrolled Successfully";
    }

    //cancelled request
    if (fields?.Cattr28?.value !== "Not withdrawn") {
      if (fields?.Cattr28?.value !== "Withdrawn by admin") {
        if (fields.Cattr20.value == "accepted") {
          appData.status = "cancelled";
          appData.action = "accepted";
          uFields.Cattr24 = fields.Cattr24;
          uFields.Cattr20 = fields.Cattr20.value;
        } else {
          appData.status = "cancelled";
          appData.action = "rejected";
          appData.description = fields.Cattr24;
          uFields.Cattr24 = fields.Cattr24;
          uFields.Cattr20 = fields.Cattr20?.value;
        }
      } else {
        if (fields.Cattr20.value == "accepted") {
          appData.status = "cancelled";
          appData.action = "accepted";
          appData.description = fields.Cattr24;
          uFields.Cattr20 = fields.Cattr20?.value;
          uFields.Cattr24 = fields.Cattr24;
        } else {
          appData.status = "cancelled";
          appData.action = "rejected";
          appData.description = fields.Cattr24;
          uFields.Cattr24 = fields.Cattr24;
          uFields.Cattr20 = fields.Cattr20?.value;
        }
      }
    }

    // if (fields.Cattr28 == "Y") {
    //   if (fields.Cattr19) {
    //     uFields.Cattr28 = fields.Cattr28;
    //     uFields.Cattr19 = fields.Cattr19;
    //     //Todo check with prasad
    //   } else {
    //     valid = false;
    //     common.snack("E", "Please enter application withdraw request");
    //   }
    // }

    if (fields.Cattr9 == "Y") {
      if (fields.Cattr17) {
        appData.status = "rejected";
        appData.description = fields.Cattr17;
        uFields.Cattr17 = fields.Cattr17;
        uFields.Cattr9 = fields.Cattr9;
      } else {
        valid = false;
        common.snack("E", "Please enter application rejected reason");
      }
    }

    appData.dealId = props.data.value1;
    uFields.Cattr28 = fields?.Cattr28?.value;
    //Responsible person
    if (fields.SalesPersonId.value) {
      uFields.SalesPersonId = fields.SalesPersonId.value;
      appData.salesPersonId = fields.SalesPersonId.value;
      // appData.responsiblePersonEmail = fields.responsiblePersonEmail;
    }

    if (appData.intake?.intakeMonth) {
      if (appData.status) {
        appData.type = appData.status;
      } else {
        appData.type = appInfo?.currentStatus;
      }
    }

    return {
      valid: valid,
      dealData: uFields,
      appData: appData,
    };
  };

  const renderAttachment = (docs) => {
    if (docs !== undefined || docs !== null) {
      const imgArr = docs.filter((e) => {
        if (e !== null) {
          return ["jpg", "jpeg", "png"].includes(e.split(".").pop());
        }
      });
      const pdf = docs.filter((e) => {
        if (e !== null) {
          return !["jpg", "jpeg", "png"].includes(e.split(".").pop());
        }
      });
      return (
        <div>
          <div className="attached-img-wrap">
            {imgArr.map((image, idx) => {
              return (
                <img
                  key={idx}
                  src={common.loadEduImage(image)}
                  onClick={(e) => previewImage(e, image)}
                />
              );
            })}
          </div>
          {pdf.map((image, idx) => {
            return (
              <div className="attach-link" key={idx}>
                <a href={common.loadEduImage(image)} download target="_blank">
                  <img
                    src={common.loadImg("external-link-icn.svg")}
                    className="me-2"
                  />
                  {image.split("/").pop()}
                </a>
              </div>
            );
          })}
        </div>
      );
    } else {
      return "--";
    }
  };

  const closeForm = (id, data) => {
    let ids = id ? id : props.data.value1;
    props.closeForm(false, "id", data, ids);
  };

  // Floating React select
  const { ValueContainer, Placeholder } = components;

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

  const openEmail = (e) => {
    e.preventDefault();
    setOpenEMail(true);
  };

  const closeEmail = (e) => {
    e.preventDefault();
    setOpenEMail(false);
  };

  // const sendEmail = async (e) => {
  //   e.preventDefault();
  //   const authInfo = common.authInfo();
  //   if (fields.emailContent) {
  //     setIsEmailSubmit(true);
  //     //post request & refresh
  //     let response = await eduTechSerivces.sendApplicationEmail(fields.Cattr2, {
  //       content: fields.emailContent,
  //       salesPerson: authInfo.UserName,
  //     });
  //     if (response.status == 200) {
  //       //add new msg to history
  //       let logs = common.reParse(emailHistory);
  //       logs.push({
  //         content: fields.emailContent,
  //         status: "sended",
  //         createdAt: new Date(),
  //       });
  //       setEmailHistory(logs);

  //       //reset text area
  //       let newFields = common.reParse(fields);
  //       newFields.emailContent = "";
  //       setFields(newFields);
  //     } else {
  //       common.snack("E", "Sending message failed");
  //     }
  //     setIsEmailSubmit(false);
  //   } else {
  //     common.snack("W", "Please enter email content");
  //   }
  // };

  //get Employee
  const getEmpByEmpId = (EmpId) => {
    let url = `${envConfig.BASE_API}${CRM_PAY_EMPLOYEE}/${EmpId}`;
    api.call(
      { url: url, type: "dynamic", method: "GET", auth: "token" },
      (response) => {
        if (response.status === 200) {
          setSalesPerson(response.data);
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  const uploadAttachFile = async () => {
    var FormDataObj = new FormData();
    files.forEach((i, k) => {
      FormDataObj.append("file", i);
    });
    let data = {
      url: "common_upload_direct",
      method: "POST",
      auth: "config",
      body: FormDataObj,
      ssr: true,
      cType: 6,
    };
    let resultData = await eduTechSerivces.call(data);
    return resultData;
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    const authInfo = common.authInfo();
    let uploadFiles = [];
    let isTrue = true;
    let formData = new FormData();
    if (fields.emailContent) {
      setIsEmailSubmit(true);
      if (files?.length > 0) {
        for (let i = 0; i < files.length; i++) {
          formData.append("attachment", files[i]);
        }
        isTrue = false;
        uploadFiles = await uploadAttachFile();
        if (uploadFiles) {
          isTrue = true;
        }
      }
      //post request & refresh
      if (isTrue) {
        formData.append("salesPersonEmail", salesPerson.Email);
        formData.append("content", fields.emailContent);
        // formData.append("salesPerson", authInfo.UserName);
        formData.append("salesPerson", salesPerson.EmployeeName);
        formData.append("documents", JSON.stringify(uploadFiles?.data?.url));

        // let resultData = await eduTechSerivces.sendApplicationEmail(fields.Cattr1, formData);
        let data = {
          url: "send_email",
          method: "POST",
          auth: "config",
          query: `/${fields.Cattr2}`,
          body: formData,
          ssr: true,
          cType: 6,
        };
        let resultData = await eduTechSerivces.call(data);
        if (resultData.status == 200) {
          //add new msg to history
          let logs = common.reParse(emailHistory);
          logs.push({
            content: fields.emailContent,
            status: "sended",
            createdAt: new Date(),
          });
          setEmailHistory(logs);

          //reset text area
          let newFields = common.reParse(fields);
          newFields.emailContent = "";
          setFields(newFields);
          setFiles([]);
          common.snack("S", "Email sended successfully");
        } else {
          common.snack("E", "Sending message failed");
        }
        setIsEmailSubmit(false);
      }
    } else {
      common.snack("W", "Please enter email content");
    }
  };

  // file handler
  const onFileChange = (e) => {
    const files = e.target.files;
    setFiles((prev) => {
      let nData = [...prev, ...files];
      return nData;
    });
  };

  const delImages = (e, i) => {
    e.preventDefault();
    setFiles((oldData) => {
      let newData = [...oldData];
      newData.splice(i, 1);
      return newData;
    });
  };

  // render
  return (
    <Box>
      <Paper variant="contained dynamic-form-wrap" square>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Box py={1} px={1} className="icon-back icon-buton">
                  <IconButton onClick={(e) => closeForm()}>
                    <img
                      src={common.loadImg("backArrowKanban.svg")}
                      alt="back"
                    />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item className="df-main-head">
                <div className="detail-title">
                  {props.data.value1 ? "Update Form" : "Add Form"}
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Box px={2}>
              <Grid container alignItems="center" spacing={2}>
                {appInfo && appInfo.leadId && (
                  <Grid item>
                    <Link
                      to={"/crm/lead/details/" + appInfo.leadId}
                      target="_blank"
                    >
                      View Application Info
                    </Link>
                  </Grid>
                )}
                <Grid item>
                  <Box className="vertical-divider">
                    <Divider orientation="vertical" />
                  </Box>
                </Grid>
                <Grid item>
                  <IconButton
                    onClick={(e) => {
                      setStatus("process");
                      getEditData();
                    }}
                  >
                    <img
                      src={common.loadImg("refresh-icon.svg")}
                      alt="document"
                    />
                  </IconButton>
                </Grid>
                {/* <Grid item>
                  <Box className="verical-divider">
                    <Divider orientation="vertical" />
                  </Box>
                </Grid>
                <Grid item>
                  <Box>
                    <Button
                      className="save-note"
                      type="submit"
                      onClick={() => onSubmit()}
                      disabled={isSubmit ? true : false}
                    >
                      {isSubmit
                        ? "Processing..."
                        : props.data.value1
                        ? "Update Changes"
                        : "Save Changes"}
                    </Button>
                  </Box>
                </Grid>
                <Grid item>
                  <Box className="vertical-divider">
                    <Divider orientation="vertical" />
                  </Box>
                </Grid>
                <Grid item>
                  <Box className="revert-close">
                    <Button
                      className="save-note pe-0"
                      onClick={(e) => closeForm()}
                    >
                      Revert and Close
                      <img
                        src={common.loadImg("closeKanban.svg")}
                        alt="close"
                      />
                    </Button>
                  </Box>
                </Grid> */}
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Box>
          <Divider />
        </Box>
        <StatusBar status={status} />
        {status == "success" && (
          <>
            <Box p={2} px={5} className="form-wrap">
              <div className="info-title dynamic-form-title send-mail-sec mt-3">
                <span>Deal Information</span>
                <Link
                  to="#"
                  onClick={(e) => {
                    openEmail(e);
                  }}
                >
                  Send Email
                </Link>
              </div>
              <Box mt={3}>
                <Divider />
              </Box>

              <Box mt={2} className="camp-edit meet-table">
                <Row className="m-0">
                  <Col xs={6} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">First Name</div>
                          <div className="camp-sub-des mt-2">
                            {fields.Cattr10 || "-"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={6} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">Last Name</div>
                          <div className="camp-sub-des mt-2">
                            {fields.Cattr11 || "-"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={6} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">Email</div>
                          <div className="camp-sub-des mt-2">
                            {fields.Email ? (
                              <a
                                href={"mailto:" + fields.Email}
                                target="_blank"
                              >
                                {tools.hideEmail(fields.Email)}
                              </a>
                            ) : (
                              "-"
                            )}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={6} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">Mobile</div>
                          <div className="camp-sub-des mt-2">
                            {fields.MobileNumber ? (
                              <a href={"tel:" + fields.MobileNumber}>
                                {tools.hideMobile(fields.MobileNumber)}
                              </a>
                            ) : (
                              "-"
                            )}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={6} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">Application ID</div>
                          <div className="camp-sub-des mt-2">
                            {fields.Cattr2 || "-"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={6} className="body-th common-input-style">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">Agent Email</div>
                          <div className="camp-sub-des mt-2">
                            {fields.Cattr1 && fields.Cattr4
                              ? fields.Cattr1 + " & " + fields.Cattr4
                              : "-"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  {fields.Eattr2 && (
                    <Col xs={6} className="body-th common-input-style">
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Box>
                            <div className="camp-sub-name">Applied Course</div>
                            <div className="camp-sub-des mt-2">
                              {fields.Eattr2 || "-"}
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Col>
                  )}
                  {fields.Eattr1 && (
                    <Col xs={6} className="body-th common-input-style">
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Box>
                            <div className="camp-sub-name">University</div>
                            <div className="camp-sub-des mt-2">
                              {fields.Eattr1 || "-"}
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Col>
                  )}
                  {fields.Eattr5 && (
                    <Col xs={6} className="body-th common-input-style">
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Box>
                            <div className="camp-sub-name">Intake</div>
                            <div className="camp-sub-des mt-2">
                              {fields.Eattr5} {fields.Eattr6 || "-"}
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Col>
                  )}
                </Row>
              </Box>
            </Box>
            <Box p={2} px={5} className="form-wrap">
              <div className="info-title dynamic-form-title mt-3">
                Application Status
              </div>
              <Box mt={3}>
                <Divider />
              </Box>

              <Box mt={2} className="camp-edit meet-table">
                <Row className="m-0">
                  <Col
                    xs={6}
                    className="body-th common-input-style common-select-border"
                  >
                    <Box className="">
                      <FormControl fullWidth className="select-float-label">
                        <FieldSelect
                          api="CRM_EMPLOYEES"
                          get="EmployeeName"
                          set="EmployeeId"
                          value={fields.SalesPersonId}
                          isMulti={false}
                          isRender={true}
                          onSelect={(data) =>
                            onSelectHandler(data, "SalesPersonId")
                          }
                          placeholder="Responsible Person*"
                        />
                      </FormControl>
                    </Box>
                  </Col>
                  <Col xs={6} className="body-th common-input-style">
                    <Box className="">
                      <FormControl
                        fullWidth
                        className="select-float-label common-padding-zero-select"
                      >
                        <Select
                          className="text-box custom-react-select"
                          labelId="select"
                          id="select"
                          label="Deal Status"
                          menuPortalTarget={document.body}
                          menuPlacement="auto"
                          onChange={(data) =>
                            onSelectHandler(data, "ApprStatus")
                          }
                          options={dealStatus}
                          isOptionDisabled={(option) => option.disabled}
                          isDisabled={
                            fields.Cattr9 === "Y" ||
                            fields?.Cattr20?.value === "accepted"
                          }
                          defaultValue={fields["ApprStatus"]}
                          components={{
                            ValueContainer: CustomValueContainer,
                          }}
                          placeholder="Deal Status"
                          styles={selectStyles}
                        ></Select>
                      </FormControl>
                    </Box>
                  </Col>
                  {fields.ApprStatus.value == "Conditionals" && (
                    <Col xs={6} className="body-th common-input-style">
                      <FormControlLabel
                        className="dynamic-checkbox ms-0 checkbox-space"
                        control={<Checkbox />}
                        value={fields.Cattr39}
                        label={"Interview Scheduled?"}
                        labelPlacement="end"
                        checked={fields.Cattr39 === "Y" ? true : false}
                        onChange={(e) => onFieldChange(e, "Cattr39")}
                      />
                      <FormControlLabel
                        className="dynamic-checkbox ms-0 checkbox-space"
                        control={<Checkbox />}
                        value={fields.Cattr40}
                        label={"Is Missing Information?"}
                        labelPlacement="end"
                        checked={fields.Cattr40 === "Y" ? true : false}
                        onChange={(e) => onFieldChange(e, "Cattr40")}
                      />
                    </Col>
                  )}

                  {fields.ApprStatus.value == "Visa Rejected" && (
                    <Col xs={6} className="body-th common-input-style">
                      <Box className="filled-box">
                        <div className="form-floating">
                          <textarea
                            className="form-control textarea"
                            id="floatingTextarea"
                            name="Cattr18"
                            rows={2}
                            value={fields.Cattr18}
                            maxLength={150}
                            onChange={(e) => onChange(e)}
                          ></textarea>
                          <label
                            htmlFor="floatingTextarea"
                            className="floating-label"
                          >
                            Visa Rejected Reason
                          </label>
                        </div>
                      </Box>
                    </Col>
                  )}
                </Row>
              </Box>
            </Box>

            {fields.ApprStatus.value == "Conditionals" &&
              fields.Cattr39 == "Y" && (
                <Box p={2} px={5} className="form-wrap">
                  <div className="info-title dynamic-form-title mt-3">
                    Interview
                  </div>
                  <Box mt={3}>
                    <Divider />
                  </Box>
                  <Box mt={2} className="camp-edit meet-table">
                    <Row className="m-0">
                      <Col xs={6} className="body-th common-input-style">
                        <Box className="ml-1 input-date-picker">
                          <DatePicker
                            format="MMMM DD, YYYY hh:mm A"
                            plugins={[
                              <TimePicker
                                hideSeconds
                                position="bottom"
                                className="ml-3"
                              />,
                            ]}
                            render={<InputIcon />}
                            placeholderText="Select Date and Time"
                            value={
                              fields.Dattr1
                                ? moment(fields.Dattr1).toDate()
                                : null
                            }
                            selected={
                              fields.Dattr1
                                ? moment(fields.Dattr1).toDate()
                                : null
                            }
                            onChange={(date) => onChange(date)}
                          />
                        </Box>
                      </Col>
                      <Col
                        xs={6}
                        className="body-th common-input-style mandatoryclass"
                      >
                        <Box className="filled-box">
                          <TextField
                            id="filled-basic"
                            label="Interview Location"
                            variant="filled"
                            inputprops={{ maxLength: 150 }}
                            name="Cattr5"
                            onChange={(e) => onChange(e)}
                            value={fields.Cattr5 || ""}
                          />
                        </Box>
                      </Col>
                      <Col
                        xs={6}
                        className="body-th common-input-style mandatoryclass"
                      >
                        <Box className="filled-box">
                          <Select
                            className="text-box custom-react-select"
                            labelId="select"
                            label="Interview Status"
                            menuPortalTarget={document.body}
                            value={interviewStatus.find(
                              (o) => o.value == fields.Cattr26.value
                            )}
                            isMulti={false}
                            onChange={(data) =>
                              onSelectHandler(data, "Cattr26")
                            }
                            options={interviewStatus}
                            components={{
                              ValueContainer: CustomValueContainer,
                            }}
                            placeholder="Interview Status"
                            styles={{
                              container: (provided, state) => ({
                                ...provided,
                                marginTop: 0,
                              }),
                              valueContainer: (provided, state) => ({
                                ...provided,
                                overflow: "visible",
                              }),
                              placeholder: (provided, state) => ({
                                ...provided,
                                position: "absolute",
                                top:
                                  state.hasValue || state.selectProps.inputValue
                                    ? -15
                                    : "10%",
                                transition: "top 0.1s, font-size 0.1s",
                                fontSize:
                                  state.hasValue || state.selectProps.inputValue
                                    ? 11
                                    : 13,
                                color: "#9daabb",
                              }),
                            }}
                          ></Select>
                        </Box>
                      </Col>
                    </Row>
                  </Box>
                </Box>
              )}

            {fields.ApprStatus.value == "Conditionals" &&
              fields.Cattr40 == "Y" && (
                <Box p={2} px={5} className="form-wrap">
                  <div className="info-title dynamic-form-title mt-3">
                    Missing Information
                  </div>
                  <Box mt={3}>
                    <Divider />
                  </Box>

                  <Box mt={2} className="camp-edit meet-table">
                    {mFields.map((mObj, idx) => {
                      let clCount = idx;
                      return (
                        <Row className="m-0" key={clCount}>
                          <Col xs={3} className="body-th common-input-style">
                            <Box className="">
                              <FormControl
                                fullWidth
                                className="select-float-label common-padding-zero-select"
                              >
                                <Select
                                  className="text-box custom-react-select"
                                  labelId="select"
                                  label="Missing Field"
                                  menuPortalTarget={document.body}
                                  id={clCount}
                                  value={eduTechConfig.docMissingFields.find(
                                    (o) => o.value == mObj.field
                                  )}
                                  isMulti={false}
                                  onChange={(data) =>
                                    onMissingSelect(data, "field", clCount)
                                  }
                                  options={eduTechConfig.docMissingFields.filter(
                                    (obj) => !mSel.includes(obj.value)
                                  )}
                                  components={{
                                    ValueContainer: CustomValueContainer,
                                  }}
                                  placeholder="Missing Field"
                                  styles={{
                                    container: (provided, state) => ({
                                      ...provided,
                                      marginTop: 0,
                                    }),
                                    valueContainer: (provided, state) => ({
                                      ...provided,
                                      overflow: "visible",
                                    }),
                                    placeholder: (provided, state) => ({
                                      ...provided,
                                      position: "absolute",
                                      top:
                                        state.hasValue ||
                                        state.selectProps.inputValue
                                          ? -15
                                          : "10%",
                                      transition: "top 0.1s, font-size 0.1s",
                                      fontSize:
                                        state.hasValue ||
                                        state.selectProps.inputValue
                                          ? 11
                                          : 13,
                                      color: "#9daabb",
                                    }),
                                  }}
                                ></Select>
                              </FormControl>
                            </Box>
                          </Col>
                          <Col xs={3} className="body-th common-input-style">
                            <Box>
                              <div className="form-floating">
                                <textarea
                                  className="form-control textarea"
                                  id={clCount}
                                  value={mObj.desc}
                                  onChange={(e) =>
                                    onMissingSelect(
                                      e.target.value,
                                      "desc",
                                      clCount
                                    )
                                  }
                                  maxLength={150}
                                ></textarea>
                                <label
                                  htmlFor="floatingTextarea"
                                  className="floating-label"
                                >
                                  Missing Field Note
                                </label>
                              </div>
                            </Box>
                          </Col>
                          <Col xs={3} className="body-th common-input-style">
                            <Box className="">
                              <FormControl
                                fullWidth
                                className="select-float-label common-padding-zero-select"
                              >
                                <Select
                                  className="text-box custom-react-select"
                                  labelId="select"
                                  label="Review Status"
                                  menuPortalTarget={document.body}
                                  id={clCount}
                                  value={reviewStatus.find(
                                    (o) => o.value == mObj.status
                                  )}
                                  isMulti={false}
                                  onChange={(data) =>
                                    onMissingSelect(data, "status", idx)
                                  }
                                  options={reviewStatus}
                                  components={{
                                    ValueContainer: CustomValueContainer,
                                  }}
                                  placeholder="Review Status"
                                  styles={{
                                    container: (provided, state) => ({
                                      ...provided,
                                      marginTop: 0,
                                    }),
                                    valueContainer: (provided, state) => ({
                                      ...provided,
                                      overflow: "visible",
                                    }),
                                    placeholder: (provided, state) => ({
                                      ...provided,
                                      position: "absolute",
                                      top:
                                        state.hasValue ||
                                        state.selectProps.inputValue
                                          ? -15
                                          : "10%",
                                      transition: "top 0.1s, font-size 0.1s",
                                      fontSize:
                                        state.hasValue ||
                                        state.selectProps.inputValue
                                          ? 11
                                          : 13,
                                      color: "#9daabb",
                                    }),
                                  }}
                                ></Select>
                              </FormControl>
                            </Box>
                          </Col>
                          <Col
                            xs={3}
                            className="body-th common-input-style v-align-center"
                          >
                            <div className="form-group ">
                              {mFields.length === 1 && idx === 0 ? (
                                <Link
                                  to="#"
                                  className="add-rule-link add-rule"
                                  onClick={(e) => {
                                    addMRow(e);
                                  }}
                                >
                                  +
                                </Link>
                              ) : (
                                idx === 0 && (
                                  <Link
                                    to="#"
                                    className="add-rule-link remove-rule"
                                    onClick={(e) => {
                                      removeMRow(e, clCount);
                                    }}
                                  >
                                    -
                                  </Link>
                                )
                              )}
                              {idx > 0 && (
                                <React.Fragment>
                                  <Link
                                    to="#"
                                    className="add-rule-link remove-rule"
                                    onClick={(e) => removeMRow(e, clCount)}
                                  >
                                    -
                                  </Link>
                                  {mFields.length - 1 == idx && (
                                    <Link
                                      to="#"
                                      className="add-rule-link add-rule"
                                      onClick={(e) => addMRow(e)}
                                    >
                                      +
                                    </Link>
                                  )}
                                </React.Fragment>
                              )}
                            </div>
                          </Col>
                        </Row>
                      );
                    })}
                  </Box>
                </Box>
              )}

            {fields.ApprStatus.value == "Conditionals" && (
              <Box p={2} px={5} className="form-wrap">
                <div className="info-title dynamic-form-title mt-3">
                  Conditional Offer Letter
                </div>
                <Box mt={3}>
                  <Divider />
                </Box>

                <Box mt={2} className="camp-edit meet-table">
                  <Row className="m-0">
                    <Col xs={6} className="body-th common-input-style">
                      {fileA.length == 0 && !fields.Cattr8 && (
                        <div className="fileUpload">
                          <label className="upload">
                            <input
                              type="file"
                              id="fileA"
                              name="offerLetter1"
                              accept=".pdf,.jpg,.png,.doc"
                              value=""
                              multiple={false}
                              onChange={(e) => onFileChangeA(e)}
                            />
                            Upload
                          </label>
                        </div>
                      )}
                      <br />
                      {fileA.map((i, k) => (
                        <p className="file-list ps-4" key={k}>
                          {i.name}
                          <img
                            src={DeleteIcon}
                            className="del-icon ms-2"
                            alt="Delete"
                            onClick={(e) => delImage(e, "fileA")}
                          />
                        </p>
                      ))}
                      {fields.Cattr8 &&
                        renderAttachment([fields.Cattr8], "fileA")}
                    </Col>
                  </Row>
                </Box>
              </Box>
            )}

            {fields.ApprStatus.value == "Unconditionals" && (
              <Box p={2} px={5} className="form-wrap">
                <div className="info-title dynamic-form-title mt-3">
                  Unconditional Offer Letter
                </div>
                <Box mt={3}>
                  <Divider />
                </Box>

                <Box mt={2} className="camp-edit meet-table">
                  <Row className="m-0">
                    <Col xs={6} className="body-th common-input-style">
                      {fileB.length == 0 && !fields.Cattr12 && (
                        <div className="fileUpload">
                          <label className="upload">
                            <input
                              type="file"
                              id="fileB"
                              name="offerLetter2"
                              placeholder="Upload"
                              accept=".pdf,.jpg,.png,.doc"
                              value=""
                              multiple={false}
                              onChange={(e) => onFileChangeB(e)}
                            />
                            Upload
                          </label>
                        </div>
                      )}

                      <br />
                      {fileB.map((i, k) => (
                        <p key={k} className="ps-4">
                          {i.name}
                          <img
                            src={DeleteIcon}
                            className="del-icon ms-2"
                            alt="Delete"
                            onClick={(e) => delImage(e, "fileB")}
                          />
                        </p>
                      ))}
                      {fields.Cattr12 &&
                        renderAttachment([fields.Cattr12], "fileB")}
                    </Col>
                  </Row>
                </Box>
              </Box>
            )}

            {fields.ApprStatus.value == "Acceptance" && (
              <Box p={2} px={5} className="form-wrap">
                <div className="info-title dynamic-form-title mt-3">
                  Acceptance
                </div>
                <Box mt={3}>
                  <Divider />
                </Box>

                <Box mt={2} className="camp-edit meet-table">
                  <Row className="m-0">
                    <Col xs={6} className="body-th common-input-style">
                      {fileC.length == 0 && !fields.Cattr21 && (
                        <div className="fileUpload">
                          <label className="upload">
                            <input
                              type="file"
                              id="fileC"
                              accept=".pdf,.jpg,.png,.doc"
                              name="acceptanceLetter"
                              multiple={false}
                              value=""
                              placeholder="Upload"
                              onChange={(e) => onFileChangeC(e)}
                            />
                            Upload
                          </label>
                        </div>
                      )}

                      {fileC.map((i, k) => (
                        <p key={k} className="ps-4">
                          {i.name}
                          <img
                            src={DeleteIcon}
                            className="del-icon ms-2"
                            alt="Delete"
                            onClick={(e) => delImage(e, "fileC")}
                          />
                        </p>
                      ))}
                      <br />
                      {fields.Cattr21 &&
                        renderAttachment([fields.Cattr21], "fileC")}
                    </Col>
                  </Row>
                </Box>
              </Box>
            )}

            {(fields.Cattr8 || fields.Cattr12 || fields.Cattr21) &&
              !["Conditionals", "Unconditionals"].includes(
                fields.ApprStatus.value
              ) && (
                <Box p={2} px={5} className="form-wrap">
                  <div className="info-title dynamic-form-title mt-3">
                    Offer Documents
                  </div>
                  <Box mt={3}>
                    <Divider />
                  </Box>

                  <Box mt={2} className="camp-edit meet-table">
                    <Row className="m-0">
                      {fields.Cattr8 && (
                        <Col xs={6} className="body-th">
                          <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                              <Box>
                                <div className="camp-sub-name">
                                  Conditional Offer Letter
                                </div>
                                <div className="camp-sub-des mt-2">
                                  {fields.Cattr8 &&
                                    renderAttachment([fields.Cattr8])}
                                </div>
                              </Box>
                            </Grid>
                          </Grid>
                        </Col>
                      )}
                      {fields.Cattr12 && (
                        <Col xs={6} className="body-th">
                          <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                              <Box>
                                <div className="camp-sub-name">
                                  Unconditional Offer Letter
                                </div>
                                <div className="camp-sub-des mt-2">
                                  {fields.Cattr12 &&
                                    renderAttachment([fields.Cattr12])}
                                </div>
                              </Box>
                            </Grid>
                          </Grid>
                        </Col>
                      )}
                      {fields.Cattr21 && (
                        <Col xs={6} className="body-th">
                          <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                              <Box>
                                <div className="camp-sub-name">Acceptance</div>
                                <div className="camp-sub-des mt-2">
                                  {fields.Cattr21 &&
                                    renderAttachment([fields.Cattr21])}
                                </div>
                              </Box>
                            </Grid>
                          </Grid>
                        </Col>
                      )}
                    </Row>
                  </Box>
                </Box>
              )}
            {!["G2G", "Submissions", "Conditionals"].includes(
              fields.ApprStatus.value
            ) && (
              <Box p={2} px={5} className="form-wrap">
                <div className="info-title dynamic-form-title mt-3">
                  Fee Payment
                </div>
                <Box mt={3}>
                  <Divider />
                </Box>

                <Box mt={2} className="camp-edit meet-table">
                  <Row className="m-0">
                    <Col xs={4} className="body-th common-input-style">
                      <FormControlLabel
                        className="dynamic-checkbox ms-0 checkbox-space"
                        control={<Checkbox />}
                        label={"Is Ready for Payment?"}
                        value={fields.Cattr32}
                        labelPlacement="end"
                        disabled={
                          paymentStatus ||
                          fields.ApprStatus.value !== "Unconditionals" ||
                          fields.Cattr23 == "Direct"
                        }
                        checked={fields.Cattr32 === "Y" ? true : false}
                        onChange={(e) => onFieldChange(e, "Cattr32")}
                      />
                    </Col>
                    {fields.Cattr23 && (
                      <>
                        <Col xs={4} className="body-th">
                          <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                              <Box>
                                <div className="camp-sub-name">
                                  Payment Mode
                                </div>
                                <div className="camp-sub-des mt-2">
                                  {fields.Cattr23 || "-"}
                                </div>
                              </Box>
                            </Grid>
                          </Grid>
                        </Col>
                        <Col xs={4} className="body-th">
                          <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                              <Box>
                                <div className="camp-sub-name">
                                  Payment Date
                                </div>
                                <div className="camp-sub-des mt-2">
                                  {fields.Dattr2
                                    ? common.formatDate(
                                        fields.Dattr2,
                                        "DD-MM-YYYY HH:MM a"
                                      )
                                    : "-"}
                                </div>
                              </Box>
                            </Grid>
                          </Grid>
                        </Col>
                        <Col xs={4} className="body-th">
                          <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                              <Box>
                                <div className="camp-sub-name">
                                  Payment Amount
                                </div>
                                <div className="camp-sub-des mt-2">
                                  {fields.Cattr15 || "-"}
                                </div>
                              </Box>
                            </Grid>
                          </Grid>
                        </Col>

                        {fields.Cattr23 == "Direct" ? (
                          <>
                            <Col xs={4} className="body-th">
                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                >
                                  <Box>
                                    <div className="camp-sub-name">
                                      Payment Document
                                    </div>
                                    <div className="camp-sub-des mt-2">
                                      {renderAttachment([fields.Cattr13])}
                                    </div>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Col>
                            {/* <Col xs={4} className="body-th">
                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                >
                                  <Box>
                                    <div className="camp-sub-name">
                                      Bank Statement
                                    </div>
                                    <div className="camp-sub-des mt-2">
                                      {renderAttachment([fields.Cattr31])}
                                    </div>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Col> */}
                            {fields.ApprStatus.value == "Unconditionals" && (
                              <Col xs={4} className="body-th">
                                <Box className="">
                                  <FormControl
                                    fullWidth
                                    className="select-float-label common-padding-zero-select"
                                  >
                                    <Select
                                      className="text-box custom-react-select"
                                      labelId="select"
                                      label="Direct Payment Verification"
                                      menuPortalTarget={document.body}
                                      value={directPayVerify.find(
                                        (o) => o.value == fields.Cattr16.value
                                      )}
                                      isMulti={false}
                                      onChange={(data) =>
                                        onSelectHandler(data, "Cattr16")
                                      }
                                      options={directPayVerify}
                                      components={{
                                        ValueContainer: CustomValueContainer,
                                      }}
                                      placeholder="Direct Payment Verification"
                                      styles={{
                                        container: (provided, state) => ({
                                          ...provided,
                                          marginTop: 0,
                                        }),
                                        valueContainer: (provided, state) => ({
                                          ...provided,
                                          overflow: "visible",
                                        }),
                                        placeholder: (provided, state) => ({
                                          ...provided,
                                          position: "absolute",
                                          top:
                                            state.hasValue ||
                                            state.selectProps.inputValue
                                              ? -15
                                              : "10%",
                                          transition:
                                            "top 0.1s, font-size 0.1s",
                                          fontSize:
                                            state.hasValue ||
                                            state.selectProps.inputValue
                                              ? 11
                                              : 13,
                                          color: "#9daabb",
                                        }),
                                      }}
                                    ></Select>
                                  </FormControl>
                                </Box>
                              </Col>
                            )}
                          </>
                        ) : (
                          <Col xs={4} className="body-th">
                            <Grid container>
                              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Box>
                                  <div className="camp-sub-name">
                                    Payment Transaction
                                  </div>
                                  <div className="camp-sub-des mt-2">
                                    {fields.Cattr7 || "-"}
                                  </div>
                                </Box>
                              </Grid>
                            </Grid>
                          </Col>
                        )}
                      </>
                    )}
                    {fields.ApprStatus.value == "Fees Paid" ? (
                      <>
                        <Col xs={6} className="body-th common-input-style">
                          <Box className="ml-1 input-date-picker">
                            <DatePicker
                              format="MMMM DD, YYYY hh:mm A"
                              plugins={[
                                <TimePicker
                                  hideSeconds
                                  position="bottom"
                                  className="ml-3"
                                />,
                              ]}
                              render={<InputIcon />}
                              placeholderText="Pre CAS Interview Date and Time"
                              value={
                                fields.Dattr4
                                  ? moment(fields.Dattr4).toDate()
                                  : null
                              }
                              selected={
                                fields.Dattr4
                                  ? moment(fields.Dattr4).toDate()
                                  : null
                              }
                              onChange={(date) => onDateChange("Dattr4", date)}
                            />
                          </Box>
                        </Col>
                        <Col
                          xs={6}
                          className="body-th common-input-style mandatoryclass"
                        >
                          <Box className="filled-box">
                            <TextField
                              id="filled-basic"
                              label="Pre CAS Interview Location"
                              variant="filled"
                              inputprops={{ maxLength: 150 }}
                              name="Cattr29"
                              onChange={(e) => onChange(e)}
                              value={fields.Cattr29 || ""}
                            />
                          </Box>
                        </Col>
                        <Col
                          xs={6}
                          className="body-th common-input-style mandatoryclass"
                        >
                          <Box className="filled-box">
                            <Select
                              className="text-box custom-react-select"
                              labelId="select"
                              label="Pre CAS Interview Status"
                              menuPortalTarget={document.body}
                              value={interviewStatus.find(
                                (o) => o.value == fields.Cattr30.value
                              )}
                              isMulti={false}
                              onChange={(data) =>
                                onSelectHandler(data, "Cattr30")
                              }
                              options={interviewStatus}
                              components={{
                                ValueContainer: CustomValueContainer,
                              }}
                              placeholder="Interview Status"
                              styles={{
                                container: (provided, state) => ({
                                  ...provided,
                                  marginTop: 0,
                                }),
                                valueContainer: (provided, state) => ({
                                  ...provided,
                                  overflow: "visible",
                                }),
                                placeholder: (provided, state) => ({
                                  ...provided,
                                  position: "absolute",
                                  top:
                                    state.hasValue ||
                                    state.selectProps.inputValue
                                      ? -15
                                      : "10%",
                                  transition: "top 0.1s, font-size 0.1s",
                                  fontSize:
                                    state.hasValue ||
                                    state.selectProps.inputValue
                                      ? 11
                                      : 13,
                                  color: "#9daabb",
                                }),
                              }}
                            ></Select>
                          </Box>
                        </Col>
                        <Col xs={6} className="body-th">
                          <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Box>
                                <div className="camp-sub-name">
                                  Bank Statement
                                </div>
                                <div className="camp-sub-des mt-2">
                                  {renderAttachment([fields.Cattr31])}
                                </div>
                              </Box>
                            </Grid>
                          </Grid>
                        </Col>
                      </>
                    ) : (
                      <>
                        {[
                          "Acceptance",
                          "Visa Applied",
                          "Visa Granted",
                          "Visa Rejected",
                          "Enrolled",
                        ].includes(fields.ApprStatus.value) ? (
                          <>
                            <Col xs={4} className="body-th">
                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={6}
                                  xl={6}
                                >
                                  <Box>
                                    <div className="camp-sub-name">
                                      Pre CAS Interview Date
                                    </div>
                                    <div className="camp-sub-des mt-2">
                                      {(fields.Dattr4 &&
                                        moment(fields.Dattr4).format(
                                          "DD-MM-YYYY HH:MM a"
                                        )) ||
                                        "-"}
                                    </div>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Col>
                            <Col xs={4} className="body-th">
                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={6}
                                  xl={6}
                                >
                                  <Box>
                                    <div className="camp-sub-name">
                                      Pre CAS Interview Location
                                    </div>
                                    <div className="camp-sub-des mt-2">
                                      {fields.Cattr29 || "-"}
                                    </div>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Col>
                            <Col xs={4} className="body-th">
                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={6}
                                  xl={6}
                                >
                                  <Box>
                                    <div className="camp-sub-name">
                                      Pre CAS Interview Status
                                    </div>
                                    <div className="camp-sub-des mt-2">
                                      {fields?.Cattr30?.value || "-"}
                                    </div>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Col>
                            <Col xs={4} className="body-th">
                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={6}
                                  xl={6}
                                >
                                  <Box>
                                    <div className="camp-sub-name">
                                      Bank Statement
                                    </div>
                                    <div className="camp-sub-des mt-2">
                                      {renderAttachment([fields.Cattr13])}
                                    </div>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Col>
                          </>
                        ) : null}
                      </>
                    )}
                  </Row>
                </Box>
              </Box>
            )}
            {fields.ApprStatus.value !== "Enrolled" && (
              <>
                <Box p={2} px={5} className="form-wrap">
                  <div className="info-title dynamic-form-title mt-3">
                    Application Rejection
                  </div>
                  <Box mt={3}>
                    <Divider />
                  </Box>
                  <Box mt={2} className="camp-edit meet-table">
                    <Row className="m-0">
                      <Col xs={6} className="body-th common-input-style">
                        <FormControlLabel
                          className="dynamic-checkbox ms-0 checkbox-space"
                          control={<Checkbox />}
                          label={"Application Rejected?"}
                          value={fields.Cattr9}
                          labelPlacement="end"
                          disabled={fields.Cattr28?.value !== "Not withdrawn"}
                          checked={fields.Cattr9 === "Y" ? true : false}
                          onChange={(e) => onFieldChange(e, "Cattr9")}
                        />
                      </Col>
                      {fields.Cattr9 === "Y" && (
                        <Col xs={6} className="body-th common-input-style">
                          <Box className="filled-box">
                            <div className="form-floating">
                              <textarea
                                className="form-control textarea"
                                id="floatingTextarea"
                                maxLength={150}
                                rows={2}
                                name="Cattr17"
                                value={fields.Cattr17}
                                onChange={(e) => onChange(e)}
                              ></textarea>
                              <label
                                htmlFor="floatingTextarea"
                                className="floating-label"
                              >
                                Rejected Reason
                              </label>
                            </div>
                          </Box>
                        </Col>
                      )}
                    </Row>
                  </Box>
                </Box>

                <Box p={2} px={5} className="form-wrap">
                  <div className="info-title dynamic-form-title mt-3">
                    Application Withdrawn Request
                  </div>
                  <Box mt={3}>
                    <Divider />
                  </Box>

                  <Box mt={2} className="camp-edit meet-table">
                    <Row className="m-0">
                      <Col xs={6} className="body-th common-input-style">
                        {/* <FormControlLabel
                          className="dynamic-checkbox ms-0 checkbox-space"
                          control={<Checkbox />}
                          label={
                            "Is AE Withdrawn Application as per User request?"
                          }
                          value={fields.Cattr28}
                          labelPlacement="end"
                          checked={fields.Cattr28 === "Y" ? true : false}
                          onChange={(e) => onFieldChange(e, "Cattr28")}
                          disabled={fields.Cattr9 == "Y"}
                        /> */}
                        <Select
                          className="text-box custom-react-select"
                          labelId="select"
                          id="select"
                          label="Application Withdraw"
                          menuPortalTarget={document.body}
                          value={fields.Cattr28}
                          isMulti={false}
                          onChange={(data) => onSelectHandler(data, "Cattr28")}
                          options={withdrawOption}
                          isDisabled={fields.Cattr9 === "Y" || cancelDA}
                          components={{
                            ValueContainer: CustomValueContainer,
                          }}
                          isOptionDisabled={(option) => option.disabled}
                          placeholder="Application withdrawn category"
                          styles={{
                            container: (provided, state) => ({
                              ...provided,
                              marginTop: 0,
                            }),
                            valueContainer: (provided, state) => ({
                              ...provided,
                              overflow: "visible",
                            }),
                            placeholder: (provided, state) => ({
                              ...provided,
                              position: "absolute",
                              top:
                                state.hasValue || state.selectProps.inputValue
                                  ? -15
                                  : "10%",
                              transition: "top 0.1s, font-size 0.1s",
                              fontSize:
                                state.hasValue || state.selectProps.inputValue
                                  ? 11
                                  : 13,
                              color: "#9daabb",
                            }),
                          }}
                        ></Select>
                      </Col>

                      {fields?.Cattr28?.value !== "Not withdrawn" &&
                        fields?.Cattr28?.value !== "Withdrawn by admin" && (
                          <Col xs={6} className="body-th common-input-style">
                            <Box className="filled-box">
                              <div className="camp-sub-name">
                                Withdrawn Request Message
                              </div>
                              <div className="camp-sub-des mt-2">
                                {fields.Cattr19 || "-"}
                              </div>
                            </Box>
                          </Col>
                        )}

                      {fields.Cattr19 ||
                        (fields?.Cattr28?.value == "Withdrawn by admin" && (
                          <Col xs={6} className="body-th common-input-style">
                            <Box className="">
                              <FormControl
                                fullWidth
                                className="select-float-label common-padding-zero-select"
                              >
                                <Select
                                  className="text-box custom-react-select"
                                  labelId="select"
                                  id="select"
                                  label="Application Rejected category"
                                  menuPortalTarget={document.body}
                                  value={fields.Cattr20}
                                  isMulti={false}
                                  onChange={(data) =>
                                    onSelectHandler(data, "Cattr20")
                                  }
                                  isDisabled={cancelDA}
                                  options={canApprStatus}
                                  components={{
                                    ValueContainer: CustomValueContainer,
                                  }}
                                  placeholder="Application Rejected category"
                                  styles={{
                                    container: (provided, state) => ({
                                      ...provided,
                                      marginTop: 0,
                                    }),
                                    valueContainer: (provided, state) => ({
                                      ...provided,
                                      overflow: "visible",
                                    }),
                                    placeholder: (provided, state) => ({
                                      ...provided,
                                      position: "absolute",
                                      top:
                                        state.hasValue ||
                                        state.selectProps.inputValue
                                          ? -15
                                          : "10%",
                                      transition: "top 0.1s, font-size 0.1s",
                                      fontSize:
                                        state.hasValue ||
                                        state.selectProps.inputValue
                                          ? 11
                                          : 13,
                                      color: "#9daabb",
                                    }),
                                  }}
                                ></Select>
                              </FormControl>
                            </Box>
                          </Col>
                        ))}
                      {fields?.Cattr28?.value == "Withdrawn by admin" ||
                      (fields.Cattr19 && fields.Cattr20.value == "rejected") ? (
                        <Col xs={6} className="body-th">
                          <Box>
                            <div className="form-floating">
                              <textarea
                                className="form-control textarea"
                                name="Cattr24"
                                rows={2}
                                value={fields.Cattr24}
                                onChange={(e) => onChange(e)}
                                maxLength={150}
                                disabled={cancelDA}
                              ></textarea>
                              <label
                                htmlFor="floatingTextarea"
                                className="floating-label"
                              >
                                Request Rejected Reason
                              </label>
                            </div>
                          </Box>
                        </Col>
                      ) : null}
                    </Row>
                  </Box>
                </Box>

                <Box p={2} px={5} className="form-wrap">
                  <div className="info-title dynamic-form-title mt-3">
                    Intake Differ
                  </div>
                  <Box mt={3}>
                    <Divider />
                  </Box>
                  <Box mt={2} className="camp-edit meet-table">
                    <Row className="m-0">
                      <Col xs={6} className="body-th common-input-style">
                        <FormControlLabel
                          className="dynamic-checkbox ms-0 checkbox-space"
                          control={<Checkbox />}
                          label={"Intake Differ?"}
                          value={intake.intakeDiffer}
                          labelPlacement="end"
                          disabled={
                            fields.Cattr9 === "Y" ||
                            fields?.Cattr28?.value !== "Not withdrawn"
                          }
                          checked={intake.intakeDiffer == "Y" ? true : false}
                          onChange={(e) => intakeChange(e, "intakeDiffer")}
                        />
                      </Col>
                      {intake.intakeDiffer === "Y" && (
                        <Col xs={6} className="body-th common-input-style">
                          <Box className="">
                            <FormControl
                              fullWidth
                              className="select-float-label common-padding-zero-select"
                            >
                              <Select
                                className="text-box custom-react-select"
                                labelId="select"
                                id="select"
                                label="Intake Month Year"
                                menuPortalTarget={document.body}
                                value={intake.intakeMonthYear}
                                isMulti={false}
                                onChange={(data) =>
                                  intakeChange(data, "intakeMonthYear")
                                }
                                options={courseIntMonthYear}
                                components={{
                                  ValueContainer: CustomValueContainer,
                                }}
                                placeholder="Intake Month Year"
                                styles={{
                                  container: (provided, state) => ({
                                    ...provided,
                                    marginTop: 0,
                                  }),
                                  valueContainer: (provided, state) => ({
                                    ...provided,
                                    overflow: "visible",
                                  }),
                                  placeholder: (provided, state) => ({
                                    ...provided,
                                    position: "absolute",
                                    top:
                                      state.hasValue ||
                                      state.selectProps.inputValue
                                        ? -15
                                        : "10%",
                                    transition: "top 0.1s, font-size 0.1s",
                                    fontSize:
                                      state.hasValue ||
                                      state.selectProps.inputValue
                                        ? 11
                                        : 13,
                                    color: "#9daabb",
                                  }),
                                }}
                              ></Select>
                            </FormControl>
                          </Box>
                        </Col>
                      )}

                      {intake.intakeDiffer === "Y" && (
                        <Col xs={6} className="body-th common-input-style">
                          <Box className="filled-box">
                            <div className="form-floating">
                              <textarea
                                className="form-control textarea"
                                id="floatingTextarea"
                                maxLength={150}
                                rows={2}
                                name="intakeReason"
                                value={intake.intakeReason}
                                onChange={(e) =>
                                  intakeChange(e, "intakeReason")
                                }
                              ></textarea>
                              <label
                                htmlFor="floatingTextarea"
                                className="floating-label"
                              >
                                Intake Differ Reason
                              </label>
                            </div>
                          </Box>
                        </Col>
                      )}
                      <Col xs={6} className="body-th common-input-style"></Col>
                    </Row>
                  </Box>
                  {appInfo?.intakeDiffer?.length > 0 && (
                    <Box mt={2} className="camp-edit meet-table">
                      <Row className="m-0">
                        <Col xs={3} className="body-th">
                          <div className="camp-sub-name">Intake Month</div>
                        </Col>
                        <Col xs={3} className="body-th">
                          <div className="camp-sub-name">Intake Year</div>
                        </Col>
                        <Col xs={4} className="body-th">
                          <div className="camp-sub-name">Intake Reason</div>
                        </Col>
                        <Col xs={6} className="body-th">
                          <div className="camp-sub-name">Intake Date</div>
                        </Col>
                      </Row>
                      {appInfo?.intakeDiffer?.map((obj, idx) => {
                        return (
                          <Row className="m-0" key={idx}>
                            <Col xs={3} className="body-th">
                              {obj.intakeMonth}
                            </Col>
                            <Col xs={3} className="body-th">
                              {obj.intakeYear}
                            </Col>
                            <Col xs={4} className="body-th">
                              {obj.intakeReason}
                            </Col>
                            <Col xs={2} className="body-th">
                              {common.formatDate(
                                obj.createdAt,
                                "DD-MM-YYYY hh:mm A"
                              )}
                            </Col>
                          </Row>
                        );
                      })}
                    </Box>
                  )}
                </Box>
              </>
            )}

            {fields.Cattr14 ||
              (fields.Cattr25 && (
                <Box p={2} px={5} className="form-wrap">
                  <div className="info-title dynamic-form-title mt-3">
                    Visa Information
                  </div>
                  <Box mt={3}>
                    <Divider />
                  </Box>

                  <Box mt={2} className="camp-edit meet-table">
                    <Row className="m-0">
                      <Col xs={3} className="body-th">
                        <Grid container>
                          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Box>
                              <div className="camp-sub-name">
                                Visa Documents
                              </div>
                              <div className="camp-sub-des mt-2">
                                {fields.Cattr25
                                  ? renderAttachment([fields.Cattr25])
                                  : "-"}
                              </div>
                            </Box>
                          </Grid>
                        </Grid>
                      </Col>
                      <Col xs={3} className="body-th">
                        <Grid container>
                          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Box>
                              <div className="camp-sub-name">
                                Visa - Connected Agent
                              </div>
                              <div className="camp-sub-des mt-2">
                                {fields.Cattr14 || "-"}
                              </div>
                            </Box>
                          </Grid>
                        </Grid>
                      </Col>
                    </Row>
                  </Box>
                </Box>
              ))}
            {status == "success" && fields && (
              <Box p={2} px={5} className="form-wrap">
                <div className="info-title dynamic-form-title mt-3">
                  Document Section
                </div>
                <Box mt={3}>
                  <Divider />
                </Box>
                <Box mt={2} className="camp-edit meet-table">
                  <Row className="m-0">
                    <Col xs={12} className="body-th p-0">
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Box className="college-title">
                            <div className="camp-sub-des">Documents</div>
                          </Box>
                          <Box mt={2} className="camp-edit meet-table">
                            <Row className="m-0">
                              <Col xs={6} className="body-th">
                                <div className="camp-sub-name">
                                  Conditional Offer Letter
                                </div>
                                <div className="camp-sub-des my-2">
                                  {fields.Cattr8
                                    ? renderAttachment([fields.Cattr8])
                                    : "-"}
                                </div>
                              </Col>
                              <Col xs={6} className="body-th">
                                <div className="camp-sub-name">
                                  Unconditional Offer Letter
                                </div>
                                <div className="camp-sub-des my-2">
                                  {fields.Cattr12
                                    ? renderAttachment([fields.Cattr12])
                                    : "-"}
                                </div>
                              </Col>
                              <Col xs={6} className="body-th">
                                <div className="camp-sub-name">
                                  Acceptance Letter
                                </div>
                                <div className="camp-sub-des my-2">
                                  {fields.Cattr21
                                    ? renderAttachment([fields.Cattr21])
                                    : "-"}
                                </div>
                              </Col>
                              <Col xs={6} className="body-th">
                                <div className="camp-sub-name">
                                  Payment Document
                                </div>
                                <div className="camp-sub-des my-2">
                                  {fields.Cattr13
                                    ? renderAttachment([fields.Cattr13])
                                    : "-"}
                                </div>
                              </Col>
                              <Col xs={6} className="body-th">
                                <div className="camp-sub-name">
                                  Bank Statement
                                </div>
                                <div className="camp-sub-des my-2">
                                  {fields.Cattr31
                                    ? renderAttachment([fields.Cattr31])
                                    : "-"}
                                </div>
                              </Col>
                              <Col xs={6} className="body-th">
                                <div className="camp-sub-name">
                                  Visa Statement
                                </div>
                                <div className="camp-sub-des my-2">
                                  {fields.Cattr25
                                    ? renderAttachment([fields.Cattr25])
                                    : "-"}
                                </div>
                              </Col>
                            </Row>
                          </Box>
                        </Grid>
                      </Grid>
                    </Col>
                  </Row>
                </Box>
              </Box>
            )}
          </>
        )}

        {imgPreview && (
          <Dialog
            open={imgPreview}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            maxWidth={"sm"}
            fullWidth={true}
            className="target-modal-wrap add-action-popup attachment-popup"
            disableScrollLock
          >
            <DialogTitle
              id="responsive-dialog-title"
              className="popup-title py-2"
            >
              <div className="attach-popup-title">
                <div>
                  <a
                    href={common.loadEduImage(imgPreviewSrc)}
                    download
                    target="_blank"
                    className="cust-link"
                  >
                    <img
                      src={common.loadImg("external-link-icn.svg")}
                      className="me-2"
                    />
                    View
                  </a>
                </div>
                <IconButton onClick={handleClose}>
                  <img src={common.loadImg("filterCloseIcon.svg")} />
                </IconButton>
              </div>
            </DialogTitle>
            <DialogContent>
              <div className="popup-form-wrap imgDlgContent">
                <div className="list-row text-center">
                  <img
                    src={common.loadEduImage(imgPreviewSrc) || null}
                    alt="image"
                    className="w-100"
                  />
                </div>
              </div>
            </DialogContent>
          </Dialog>
        )}

        {/* EMail communication */}
        <Dialog
          open={openEMail}
          onClose={closeEmail}
          aria-labelledby="responsive-dialog-title"
          maxWidth={"sm"}
          fullWidth={true}
          className="target-modal-wrap add-action-popup attachment-popup"
          disableScrollLock
        >
          <DialogTitle
            id="responsive-dialog-title"
            className="popup-title py-2"
          >
            <div className="d-flex align-items-center justify-content-between">
              <span>Send Email</span>
              <span onClick={closeEmail} className="crm-cp">
                X
              </span>
            </div>
          </DialogTitle>
          <DialogContent>
            <div>
              <div className="popup-form-wrap">
                <div className="row">
                  <div className="col-md-12">
                    <label className="msg-history">
                      Message<span className="mandtory-class">*</span>
                    </label>
                    <textarea
                      className="form-control textarea"
                      rows={3}
                      value={fields.emailContent}
                      name={"emailContent"}
                      onChange={(e) => onChange(e)}
                      maxLength={2000}
                      placeholder="Enter your message here to send"
                    ></textarea>
                  </div>
                  <Box p={2} px={5} className="form-wrap">
                    <div className="info-title dynamic-form-title mt-3">
                      Attachment
                    </div>
                    <Box mt={3}>
                      <Divider />
                    </Box>

                    <Box mt={2} className="camp-edit meet-table">
                      <Row className="m-0">
                        <Col xs={6} className="body-th common-input-style">
                          <div className="fileUpload">
                            <label className="upload">
                              <input
                                type="file"
                                id="fileA"
                                name="offerLetter1"
                                accept=".pdf,.jpg,.png,.doc"
                                value=""
                                multiple={true}
                                onChange={(e) => onFileChange(e)}
                              />
                              Upload
                            </label>
                          </div>
                          <br />
                          {files?.map((i, k) => (
                            <p className="file-list ps-4" key={k}>
                              {i.name}
                              <img
                                src={DeleteIcon}
                                className="del-icon ms-2"
                                alt="Delete"
                                onClick={(e) => delImage(e, k)}
                              />
                            </p>
                          ))}
                        </Col>
                      </Row>
                    </Box>
                  </Box>
                  <div className="col-md-12 pt-2 d-flex justify-content-end">
                    <Button
                      className="save-note send-mail-btn"
                      type="button"
                      onClick={(e) => sendEmail(e)}
                      disabled={isEmailSubmit ? true : false}
                    >
                      {isEmailSubmit ? "Processing..." : "Send"}
                    </Button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    {emailHistory.length > 0 && (
                      <label className="msg-history">Message History</label>
                    )}
                    {emailHistory.map((msg, idx) => {
                      return (
                        <div className="msg-history-list" key={idx}>
                          <p className="msg-content">{msg.content}</p>
                          <p className="d-flex align-item-center justify-content-between mb-0">
                            <span className="msg-send-date">
                              {common.formatDate(
                                msg.createdAt,
                                "DD-MM-YYYY hh:mm A"
                              )}
                            </span>
                            <span className="msg-deliver-status">
                              {msg.status == "sended"
                                ? "Delivered"
                                : "Not Delivered"}
                            </span>
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </Paper>
      {status == "success" && (
        <div className="fixed-foot-wrap pt-3">
          <Box className="verical-divider">
            <Divider orientation="vertical" />
          </Box>
          <Box>
            <Button
              className="save-note"
              type="submit"
              onClick={() => onSubmit()}
              disabled={isSubmit ? true : false}
            >
              {isSubmit
                ? "Processing..."
                : props.data.value1
                ? "Update Changes"
                : "Save Changes"}
            </Button>
          </Box>
          <Box className="revert-close">
            <Button className="save-note pe-0" onClick={(e) => closeForm()}>
              Revert and Close
              <img src={common.loadImg("closeKanban.svg")} alt="close" />
            </Button>
          </Box>
        </div>
      )}
    </Box>
  );
}

export default ETDealForm;
