import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { common } from "helpers";
import React, { memo } from "react";
import CampaignDatePicker from "./campaignDatePicker";

const CampaignEdit = memo(() => {
  const navigate = useNavigate();
  const [isDateModal, setIsDateModal] = React.useState(false);
  const [startDate, setStartDate] = React.useState(new Date());

  const handleDate = () => {
    setIsDateModal(!isDateModal);
  };



    //Goto previous page
    const goto = (e) => {
      e.preventDefault();
      navigate("/crm/view/5");
    };

  function createData(type, venue) {
    return { type, venue };
  }

  const rows = [
    createData(
      <Box className="text-box">
        <label className="text-label ml-3">Campaign Name</label>
        <TextField id="name" fullWidth placeholder="Facebook Campaign 2021" />
      </Box>,
      <Box className="text-box">
        <label className="text-label ml-3">Campaign Status</label>
        <TextField id="status" fullWidth placeholder="New" />
        
      </Box>
    ),
    createData(
      <Box className="text-box">
        <label className="text-label ml-3">Campaign Type</label>
        <TextField id="type" fullWidth placeholder="Social Media" />
      </Box>,
      <Box className="text-box">
        <label className="text-label ml-3">Campaign Channel</label>
        <TextField id="channel" fullWidth placeholder="Facebook" />
      </Box>
    ),
  ];
  const dateRows = [
    createData(
      <Box className="text-box pointer date-field" onClick={handleDate}>
        <label className="text-label ml-3">Planned Start Date</label>
        <TextField id="start-date" fullWidth placeholder="Aug 20 2021" />
        <div className="calendar-icon"><img src={common.loadImg("calendar-icon.svg")} /></div>
      </Box>,
      <Box className="text-box pointer date-field" onClick={handleDate}>
        <label className="text-label ml-3">Planned End Date</label>
        <TextField id="end-date" fullWidth placeholder="Jul 04 2022" />
        <div className="calendar-icon"><img src={common.loadImg("calendar-icon.svg")} /></div>
      </Box>
    ),
    createData(
      <Box className="text-box pointer date-field" onClick={handleDate}>
        <label className="text-label ml-3">Actual End Date</label>
        <TextField id="actual-date" fullWidth placeholder="Nov 04 2022"  />
        <div className="calendar-icon"><img src={common.loadImg("calendar-icon.svg")} /></div>
      </Box>
    ),
  ];
  return (
      <Box px={5} py={4}>
        <Paper variant="contained" square className="container-wrap">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <Box py={1} px={1} className="icon-back icon-back-noborder">
                    <IconButton>
                      <img
                        src={common.loadImg("backArrowKanban.svg")}
                        alt="back"
                      />
                    </IconButton>
                  </Box>
                </Grid>
                <Grid item className="ps-0">
                  <Typography className="detail-title">
                    Facebook Campaign 2021
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Box px={2}>
                <Grid container alignItems="center" spacing={2}>
                  
                  <Grid item>
                    <Box>
                      <Button className="save-note" onClick={(e) => goto(e)}>Save Changes</Button>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box className="verical-divider">
                      <Divider orientation="vertical" />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box className="revert-close">
                      <Button className="save-note pe-2" onClick={(e) => goto(e)}>
                        Revert and Close
                        <img
                          src={common.loadImg("closeKanban.svg")}
                          alt="close"
                        />
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Box>
            <Divider  className="border-color" />
          </Box>
          <Box>
            <Typography className="info-title camp-info-title mt-4">
              Campaign Information
            </Typography>
            <Box mt={3}>
              <Divider className="border-color"/>
            </Box>
            <Box className="camp-edit">
              <TableContainer component={Paper} className="camp-edit-noshadow">
                <Table aria-label="simple table">
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow
                        key={row.type}
                        sx={{ "&:last-child, &:last-child": { border: 0 } }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className="body-th bordr-right"
                          style={{ width: "50%" }}
                        >
                          {row.type}
                        </TableCell>
                        <TableCell className="body-th bordr-bottom" style={{ width: "50%" }}>
                          {row.venue}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
          <Box>
            <Typography className="info-title camp-info-title mt-4">Dates</Typography>
            <Box mt={3}>
              <Divider />
            </Box>
            <Box className="camp-edit">
              <TableContainer component={Paper} className="camp-edit-noshadow">
                <Table aria-label="simple table">
                  <TableBody>
                    {dateRows.map((row) => (
                      <TableRow
                        key={row.type}
                        sx={{ "&:last-child, &:last-child": { border: 0 } }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className="body-th bordr-right"
                          sx={{
                            width: { xs: "50%", lg: "50%" },
                          }}
                        >
                          {row.type}
                        </TableCell>
                        <TableCell
                          className="body-th bordr-bottom"
                          sx={{
                            width: { xs: "50%", lg: "50%" },
                          }}
                        >
                          {row.venue}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Paper>
        {isDateModal && (
          <CampaignDatePicker
            open={isDateModal}
            handleClose={handleDate}
            startDate={startDate}
            setStartDate={setStartDate}
          />
        )}
      </Box>
  );
});

export default CampaignEdit;
