import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React, { memo } from "react";
import ReactDatePicker from "react-datepicker";
import { common } from "helpers";

const CampaignDatePicker = memo(
  ({ open, handleClose, startDate, setStartDate }) => {
    return (
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="edit-date-picker"
        fullWidth
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Planned End Date
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <img src={common.loadImg("dateClosekanban.svg")} alt="close" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box pt={3}>
            <ReactDatePicker
              inline
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Box className="select-date-box">
            <Button onClick={handleClose}>Select Date</Button>
          </Box>
        </DialogActions>
      </Dialog>
    );
  }
);

export default CampaignDatePicker;
