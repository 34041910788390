import { config } from "./config";
import { common, tools } from "helpers";

const services = {
  // LOGIN API
  login: config.api.auth_api + "restauth/v1",
  cholaUsers: config.api.base_api + "CholaUsers",
  cholaCreateUser: config.api.auth_rest_api + "CholaCreateUser"
};

// api call
export const api = {
  call(data = {}, response, error, final) {
    getData(data);
    fetch(data.url, getOptions(data))
      .then((response) =>
        response.text().then((data) => ({
          status: response.status,
          data: data,
        }))
      )
      .then((result) => {
        if (validateData(result)) {
          try {
            result.data = JSON.parse(result.data);
          } catch (e) {
            result.data = result.data; //ToDo. Test all the exception scenarios
          }
          response(result);
        }
      })
      .catch((err) => {
        error(err);
      })
      .finally(() => {
        final();
      });
  },
  fileCall(data = {}, response, error, final) {
    getData(data);
    fetch(data.url, getOptions(data))
      .then((response) => {
        return response.blob();
      })
      .then((result) => {
        if (result) {
          response(result);
        } else {
          error("Something Went Wrong");
        }
      })
      .catch((err) => {
        error(err);
      })
      .finally(() => {
        final();
      });
  },
};

// support
function getData(data) {
  data.repeat = data.repeat || false;
  if (!data.repeat) {
    data.auth = data.auth || "token";
    data.type = data.type || "standard";
    data.method = data.method || "POST";
    data.cType = data.cType || 1;
    data.query = data.query || "";
    data.body = data.body || "";
    data.keyQuery = data.keyQuery || "";
    // set url
    if (data.type === "dynamic") {
      data.url = data.url || "";
    } else if (data.type === "third") {
      data.url = data.url + data.query;
    } else {
      data.url = services[data.url] + data.query;
      if (data.keyQuery !== "") {
        for (var key in data.keyQuery) {
          data.url = data.url.replace(`:${key}`, data.keyQuery[key]);
        }
      }
    }
    // set body
    if (data.body) {
      if (data.cType === 1) {
        data.body = data.body ? JSON.stringify(data.body) : "";
      } else if (data.cType === 2) {
        let bodyParam = [];
        for (var property in data.body) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(data.body[property]);
          bodyParam.push(encodedKey + "=" + encodedValue);
        }
        data.body = bodyParam.join("&");
      }
    }
  }
}

function getOptions(data) {
  let reqOptions = {
    method: data.method,
    headers: getHeaders(data),
  };
  if (data.body) {
    // reqOptions.body = JSON.stringify(data.body);
    reqOptions.body = data.body;
  }
  return reqOptions;
}

function getHeaders(data) {
  // default
  let headers = {};
  headers["Access-Control-Allow-Origin"] = "*";
  headers["Access-Control-Allow-Headers"] = "Content-Type";
  headers["Access-Control-Allow-Methods"] =
    "GET, POST, PATCH, PUT, DELETE, OPTIONS";

  // content types
  let contentTypes = {
    1: "application/json",
    2: "application/x-www-form-urlencoded",
    3: "application/vnd.oracle.adf.action+json",
    4: "application/vnd.oracle.adf.resourceitem+json",
    5: "application/vnd.oracle.adf.batch+json",
  };
  headers["Content-Type"] = contentTypes[data.cType];
  // extra content types
  let moreHeaderList = {
    rfv: "REST-Framework-Version",
  };
  if (data.moreHead) {
    for (var item in data.moreHead) {
      headers[moreHeaderList[item]] = data.moreHead[item];
    }
  }
  // authentication
  if (data.auth === "token") {
    // let authData = common.getAuth();
    let authData = common.localGet("userToken");
    headers.Authorization = "Bearer " + authData; //.token;
  } else if (data.auth === "temp") {
    headers.Authorization = "Bearer " + localStorage.getItem("tempToken");
  } else if (data.auth === "basic") {
    if (!data.url.includes("restauth/v1")) {
      let user = localStorage.getItem("userData");
      let userData = tools.deCrypt(user);

      headers.Authorization = "Basic " + userData.basicToken;
    } else {
      headers.Authorization = "Basic " + data.credentials;
    }
  }
  return headers;
}

function validateData(result) {
  let rememberMe = common.localGet("orm");
  let loginInfo = common.localGet("oui");
  if (config.api.isExpired !== "" && result.status === config.api.isExpired) {
    if (rememberMe) {
      localStorage.clear();
      common.localSet("orm", rememberMe);
      common.localSet("oui", loginInfo);
    } else {
      localStorage.clear();
    }
    window.location.href = common.url("/signin");
    return false;
  } else {
    return true;
  }
}
