import React, { useCallback, useEffect, useState } from "react";

import InfiniteScroll from "react-infinite-scroll-component";
import {
  Link,
  useNavigate,
  useLocation,
  useOutletContext,
} from "react-router-dom";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import Popover from "@mui/material/Popover";
import {
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";

import Loader from "elements/Loader";
import StatusBar from "elements/StatusBar";
import EmpDetail from "./empDetail";
import Permissions from "./permissions";

import { api, common, envConfig } from "helpers";

import { CRM_EMPLOYEES } from "helpers/service";
import { formatNumber } from "helpers/tools";
import AccessDenied from "elements/AccessDenied";

let ModNameChange = envConfig.ModNameChange;

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index ? (
        <Box sx={{ p: 3 }} className="p-2 pt-0">
          {children}
        </Box>
      ) : null}
    </div>
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const LIST_LIMIT = 10;

const Employees = () => {
  const navigate = useNavigate();
  const [permission] = useOutletContext();
  const userInfo = common.authInfo();

  const search = useLocation().search;
  const queryPage = new URLSearchParams(search).get("page");
  const querySearch = new URLSearchParams(search).get("search");
  const queryTab = new URLSearchParams(search).get("tab");
  const queryEmployeeId = new URLSearchParams(search).get("eid");

  const [page, setPage] = useState(0);
  const [resultList, setResultList] = useState([]);
  const [total, setTotal] = useState(0);
  const [hasMore, setHasMore] = useState(null);
  const [fullPageLoading, setFullPageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchWord, setSearchWord] = useState("");
  const [expand, setExpand] = useState(false);

  const [value, setValue] = useState(0);
  const [rightAnchorEl, setRightAnchorEl] = useState(null);

  const loadEmployeesList = useCallback(
    (pageNo, searchText, employeeId, queryPageNo, listLimit = LIST_LIMIT) => {
      setPage(employeeId ? parseInt(queryPageNo) : pageNo);
      let userData = common.authInfo();
      let url = `${envConfig.BASE_API}${CRM_EMPLOYEES}?q=TenantId=${userData.TenantId}`;

      if (searchText) {
        if (userInfo?.role === "user") {
          url = `${url} AND CreatedBy NOT IN ('CHOLAN') AND UPPER(EmployeeName) LIKE '*${searchText}*'`;
        } else {
          url = `${url} AND UPPER(EmployeeName) LIKE '*${searchText}*'`;
        }
      }
      url = `${url}&totalResults=true&orderBy=CreatedOn:desc&offset=${
        pageNo * listLimit
      }&limit=${pageNo * listLimit + listLimit}`;

      let data = {
        url: url,
        type: "dynamic",
        method: "GET",
        auth: "token",
        moreHead: { rfv: 2 },
        cType: 4,
      };

      api.call(
        data,
        (response) => {
          if (response) {
            let result = response.data;
            setResultList((oldData) => {
              if (pageNo === 0) {
                return result.items;
              } else {
                return [...new Set([...oldData, ...result.items])];
              }
            });

            if (result.items?.length > 0) {
              if (pageNo === 0) {
                setSelectedItem(result.items[0]);
              }
            } else {
              setSelectedItem(null);
            }

            if (employeeId) {
              const item = result.items?.find(
                (item) => item?.EmployeeId === parseInt(employeeId)
              );
              if (item) {
                setSelectedItem(item);
              }
            }

            setTotal(result.totalResults);
            setHasMore(result.hasMore);
          }

          setFullPageLoading(false);
          setIsLoading(false);
        },
        (error) => {
          setFullPageLoading(false);
          setIsLoading(false);
        },
        (final) => {
          setFullPageLoading(false);
          setIsLoading(false);
        }
      );
    },
    []
  );

  useEffect(() => {
    setIsLoading(true);
    const totalLimit = queryPage * LIST_LIMIT + LIST_LIMIT;
    loadEmployeesList(
      0,
      querySearch || "",
      queryEmployeeId,
      queryPage,
      totalLimit
    );
    setFullPageLoading(true);
    setValue(parseInt(queryTab) || 0);
    if (querySearch) {
      setSearchWord(querySearch);
    }
  }, [loadEmployeesList, queryEmployeeId, queryPage, querySearch, queryTab]);

  const handleSearchChange = (event) => {
    setSearchWord(event.target.value);
    loadEmployeesList(0, searchWord);
  };

  const handleSelect = (item) => {
    setSelectedItem(item);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleExpand = () => {
    setExpand(!expand);
  };

  const detailsMoreClick = (event) => {
    setRightAnchorEl(event.currentTarget);
  };
  const detailsCloseClick = () => {
    setRightAnchorEl(null);
  };

  const rightMoreOpen = Boolean(rightAnchorEl);

  return (
    <React.Fragment>
      {common.givenPermission(permission, "hrms:employee", "read") ? (
        <AccessDenied />
      ) : (
        <Box className="template-box-border p-4">
          <div className="row">
            {!expand && (
              <div className="col-md-4 mt-2 ">
                <div className="card border-color-F2F9FD">
                  <div className="card-body p-0">
                    <div className="row p-2">
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <div className="d-flex align-items-center title mb-0">
                            <Link to="/setting/view/hrms">
                              <img
                                alt={"back"}
                                className="mx-1"
                                src={common.loadImg(
                                  "iconly-light-arrow-down-circle.svg"
                                )}
                              />
                            </Link>
                            <label className="d-flex align-items-center location-detail-title ms-0">
                              {ModNameChange ? "Users" : "Employees"}
                            </label>
                            {!isLoading && total ? (
                              <span className="location-count-label count-employee">
                                {formatNumber(total || 0)}
                              </span>
                            ) : null}
                          </div>
                        </Grid>
                        <Grid item>
                          <Typography className="location-detail-title"></Typography>
                        </Grid>
                        <Grid item></Grid>
                        <Grid item>
                          <Box display="flex" alignItems="center">
                            {!common.givenPermission(
                              permission,
                              "hrms:employee",
                              "add"
                            ) ? (
                              <Link
                                to="/setting/hrms/employee/add"
                                className="text-none"
                              >
                                <span className="mx-2 add-new">Add New</span>
                              </Link>
                            ) : null}

                            <div className="add-new-button d-flex align-items-center justify-content-center">
                              <Link
                                to="/setting/hrms/employee/add"
                                className="text-none"
                              >
                                <span className="d-flex align-items-center plus-icon">
                                  +
                                </span>
                              </Link>
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                    </div>

                    <div className="d-flex bg-F2F9FD ">
                      <Box className="filter-search" width="100%">
                        <FormControl sx={{ width: "100%" }}>
                          <OutlinedInput
                            id="search"
                            value={searchWord}
                            onChange={(event) => handleSearchChange(event)}
                            placeholder={"Search"}
                            endAdornment={
                              <InputAdornment position="end">
                                <img
                                  src={common.loadImg("common-search.svg")}
                                  alt="search"
                                />
                              </InputAdornment>
                            }
                            aria-describedby="outlined-weight-helper-text"
                          />
                        </FormControl>
                      </Box>
                    </div>

                    <div className="bg-white employee-user-list">
                      {fullPageLoading ? (
                        <StatusBar status="process" />
                      ) : (
                        <Grid
                          container
                          direction="column"
                          id="infinity-list-div"
                        >
                          <InfiniteScroll
                            scrollThreshold={0.6}
                            next={() => {
                              loadEmployeesList(page + 1, searchWord);
                            }}
                            hasMore={hasMore}
                            loader={!isLoading ? <Loader /> : null}
                            dataLength={resultList?.length || 0}
                          >
                            {isLoading ? (
                              <StatusBar status="process" />
                            ) : resultList?.length ? (
                              resultList?.map((item, index) => {
                                const nameArr =
                                  item.EmployeeName?.split(" ") || "";
                                const initial =
                                  nameArr?.length > 1
                                    ? `${
                                        nameArr[0] ? nameArr[0].slice(0, 1) : ""
                                      }${
                                        nameArr[1] ? nameArr[1].slice(0, 1) : ""
                                      }`
                                    : item.EmployeeName?.slice(0, 2);

                                return (
                                  <Grid
                                    id={`row_item_${item?.EmployeeId}`}
                                    item
                                    key={index}
                                  >
                                    <Box
                                      className="pointer color-divider"
                                      onClick={() => handleSelect(item)}
                                    >
                                      <Grid container alignItems="center">
                                        <Grid item xs={2} mt={0.5}>
                                          <Box px={2} py={1}>
                                            <Box className="img-box">
                                              {(initial || "").toUpperCase()}
                                            </Box>
                                          </Box>
                                          {item.EmployeeId ===
                                          selectedItem?.EmployeeId ? (
                                            <div className="active active-location"></div>
                                          ) : null}
                                        </Grid>

                                        <Grid
                                          item
                                          xs={9}
                                          className="divider-color"
                                        >
                                          <Grid
                                            container
                                            justifyContent="space-between"
                                            alignItems="center"
                                          >
                                            <Grid item>
                                              <ul className=" list-name-location mb-0">
                                                <li
                                                  className={
                                                    item.EmployeeId ===
                                                    selectedItem?.EmployeeId
                                                      ? "location-names-active "
                                                      : "location-names "
                                                  }
                                                >
                                                  {common.textCapitalize(
                                                    item.EmployeeName
                                                  )}
                                                </li>

                                                <li className="setting-card-content mt-1">
                                                  {common.textCapitalize(
                                                    item.EmployeeCode
                                                  )}
                                                  <span
                                                    className={
                                                      item.WorkingStatus ===
                                                      "ACTIVE"
                                                        ? "user-badge-active"
                                                        : "user-badge-deactive"
                                                    }
                                                  >
                                                    {item.WorkingStatus}
                                                  </span>
                                                </li>
                                              </ul>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </Grid>
                                );
                              })
                            ) : (
                              <StatusBar status="empty" />
                            )}
                          </InfiniteScroll>
                        </Grid>
                      )}
                    </div>
                  </div>
                  {!fullPageLoading ? (
                    <Box
                      sx={{ width: 1 }}
                      px={1}
                      py={1}
                      className="location-footer"
                    >
                      {!isLoading && total
                        ? "Showing" +
                          " " +
                          `${resultList?.length ? 1 : 0} to ${
                            resultList?.length || 0
                          } of ${total || 0}`
                        : null}
                    </Box>
                  ) : null}
                </div>
              </div>
            )}

            {true && (
              <div className={`${expand ? "col-md-12" : "col-md-8"} mt-2`}>
                <div className="card border-color-F2F9FD">
                  <div>
                    <Box p={2}>
                      <Grid container justifyContent="space-between">
                        <Grid item>
                          <Typography className="single-emp-title pre-text">
                            {selectedItem
                              ? `${selectedItem?.EmployeeName}’s Settings`
                              : "Settings"}{" "}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <div className="my-auto pe-4">
                            {!isLoading && selectedItem ? (
                              <Grid container>
                                {expand ? (
                                  <Typography
                                    className="expand pointer"
                                    onClick={handleExpand}
                                  >
                                    Collapse{" "}
                                    <img
                                      src={common.loadImg("expandkanban.svg")}
                                      alt="expand"
                                      className="me-3"
                                    />
                                  </Typography>
                                ) : (
                                  <Typography
                                    className="expand pointer"
                                    onClick={handleExpand}
                                  >
                                    Expand{" "}
                                    <img
                                      src={common.loadImg("expandkanban.svg")}
                                      alt="expand"
                                      className="me-3"
                                    />
                                  </Typography>
                                )}

                                <MoreHorizOutlinedIcon
                                  className="pointer"
                                  aria-describedby={"right-more-popover"}
                                  onClick={detailsMoreClick}
                                />
                              </Grid>
                            ) : null}

                            {!common.givenPermission(
                              permission,
                              "hrms:employee",
                              "update"
                            ) ? (
                              <Popover
                                disableScrollLock
                                id={"right-more-popover"}
                                open={rightMoreOpen}
                                anchorEl={rightAnchorEl}
                                onClose={detailsCloseClick}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                              >
                                <Typography
                                  sx={{ px: 3, py: 1 }}
                                  className="text-capitalize pointer"
                                  onClick={() => {
                                    // navigate(`/setting/hrms/employee/edit/${selectedItem?.EmployeeId}`)
                                    if (selectedItem) {
                                      navigate(
                                        `/setting/hrms/employee/edit/${selectedItem?.EmployeeId}?page=${page}&search=${searchWord}&tab=${value}`
                                      );
                                    }
                                  }}
                                >
                                  Edit
                                </Typography>
                              </Popover>
                            ) : null}
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box>
                      <Divider />
                    </Box>

                    <Box sx={{ width: "100%" }} className="pb-0 emp-tabs">
                      <Box>
                        <Tabs
                          TabIndicatorProps={{
                            style: {
                              objectFit: "contain",
                              boxShadow:
                                "-2px 2px 6px 0 rgba(54, 172, 227, 0.47)",
                              backgroundColor: "#36ace3",
                              fontWeight: "bold",
                              height: "4px",
                              marginBottom: 0,
                            },
                          }}
                          value={value}
                          onChange={handleChange}
                          aria-label="basic tabs example "
                        >
                          <Tab
                            label="Details"
                            disabled={isLoading}
                            {...a11yProps(0)}
                            className={"emp-tab-text"}
                          />
                          <Tab
                            label="Permission"
                            disabled={isLoading}
                            {...a11yProps(1)}
                            className={"emp-tab-text"}
                          />
                        </Tabs>
                      </Box>
                    </Box>
                  </div>
                </div>

                <div className="settings-secondary" />
                <TabPanel value={value} index={0}>
                  <EmpDetail
                    isLoading={isLoading}
                    selectedItem={selectedItem}
                  />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  {!(
                    userInfo?.role === "adminuser" &&
                    selectedItem?.EmployeeId === userInfo?.EmpId
                  ) ? (
                    <Permissions
                      isLoading={isLoading}
                      selectedItem={selectedItem}
                      setSelectedItem={setSelectedItem}
                    />
                  ) : null}
                </TabPanel>
              </div>
            )}
          </div>
        </Box>
      )}
    </React.Fragment>
  );
};

export { Employees };
