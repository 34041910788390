import { Box, Paper } from "@mui/material";
import React, { memo, useEffect } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { History } from "elements/history";
import { CallLog } from "elements/callLog";
import { Meeting } from "elements/meeting";
import { EnquiryList } from "elements/enquiryList";
import { ChildCampaigns } from "elements/childCampaign";
import { CampaignLeadList } from "elements/campaignLeadList";
import { useParams } from "react-router-dom";
import { Notes } from "elements/notes";
import { Attachment } from "./Attachment";
import { Report } from "./Report";
import { ContactLinks } from "./contactLinks";
import { EnquiryMembers } from "./enquiryMembers";
import { ProductServices } from "./productSevice";

const CampaignsSubMenu = memo(({ handleExpanded, isExpand, id }) => {
  let { mod } = useParams();
  const [value, setValue] = React.useState(
    mod == "enquiry" ? "11" : mod != "campaign" ? "1" : "2"
  );

  useEffect(() => {
    setValue(mod == "enquiry" ? "11" : mod != "campaign" ? "1" : "2");
  }, [id]);

  const handleChange = (event, newValue) => {
    event.preventDefault();
    setValue(newValue);
  };
  return (
    <Box>
      <TabContext value={value}>
        <Paper variant="contained" square className="crmsubmenu-tabs-spacing">
          <Box
            display="flex"
            justifyContent="space-between"
            className="crmsubmenu-tabs-head"
          >
            <Box
              display="flex"
              alignItems="center"
              px={3}
              className="tabs-sub"
              style={{ overflow: "hidden" }}
            >
              <TabList onChange={handleChange} variant="scrollable">
                {mod == "enquiry" && <Tab label="Members" value="11" />}
                {mod != "campaign" && <Tab label="Call Logs" value="1" />}
                <Tab label="Meetings" value="2" />
                <Tab label="Notes" value="3" />
                <Tab label="Attachments" value="4" />
                <Tab label="History" value="5" />
                {mod != "enquiry" && <Tab label="Contacts" value="6" />}
                {mod == "campaign" && <Tab label="Enquiry List" value="7" />}
                {mod == "campaign" && <Tab label="Child Campaigns" value="8" />}
                {mod == "campaign" && <Tab label="Lead List" value="9" />}
                {mod == "campaign" && <Tab label="Report" value="10" />}
                {/* {(mod == "lead" || mod == "deal") && (
                  <Tab label="Products" value="12" />
                )} */}
              </TabList>
            </Box>
          </Box>
          <TabPanel value="11">
            <EnquiryMembers
              handleExpanded={handleExpanded}
              isExpand={isExpand}
              id={id}
            />
          </TabPanel>
          <TabPanel value="1" className="calllog-content-wrap">
            <CallLog
              handleExpanded={handleExpanded}
              isExpand={isExpand}
              id={id}
            />
          </TabPanel>
          <TabPanel value="2" className="meeting-content-wrap">
            <Meeting
              handleExpanded={handleExpanded}
              isExpand={isExpand}
              id={id}
            />
          </TabPanel>
          <TabPanel value="3">
            <Notes handleExpanded={handleExpanded} isExpand={isExpand} />
          </TabPanel>
          <TabPanel value="4">
            <Attachment handleExpanded={handleExpanded} isExpand={isExpand} />
          </TabPanel>
          <TabPanel value="5">
            <History handleExpanded={handleExpanded} isExpand={isExpand} />
          </TabPanel>
          <TabPanel value="6" className="contact-content-wrap">
            <ContactLinks
              handleExpanded={handleExpanded}
              isExpand={isExpand}
              id={id}
            />
          </TabPanel>
          <TabPanel value="7">
            <EnquiryList
              handleExpanded={handleExpanded}
              isExpand={isExpand}
              id={id}
            />
          </TabPanel>
          <TabPanel value="8">
            <ChildCampaigns
              handleExpanded={handleExpanded}
              isExpand={isExpand}
              id={id}
            />
          </TabPanel>
          <TabPanel value="9">
            <CampaignLeadList
              handleExpanded={handleExpanded}
              isExpand={isExpand}
              id={id}
            />
          </TabPanel>
          <TabPanel value="10">
            <Report handleExpanded={handleExpanded} isExpand={isExpand} />
          </TabPanel>
          {/* <TabPanel value="12">
            <ProductServices
              handleExpanded={handleExpanded}
              isExpand={isExpand}
              id={id}
            />
          </TabPanel> */}
        </Paper>
      </TabContext>
    </Box>
  );
});

export default CampaignsSubMenu;
