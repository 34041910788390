import React from "react";
import Paper from "@mui/material/Paper";
import AccessDenied from "elements/AccessDenied";
import { common } from "helpers";
import { useOutletContext } from "react-router-dom";
import StaticTale from "elements/staticTable";
import template from "assets/files/static/template.json";

function Template() {
  const [permission] = useOutletContext();
  return (
    <div className="default-bg">
      <div style={{ padding: "20px" }}>
      {common.givenPermission(permission,'configure:template','read') ? <AccessDenied /> :  <Paper
          sx={{ width: "100%", mb: 2 }}
          className={`container responsive-table p-0`}
        >
          <StaticTale module={template}
          add={!common.givenPermission(permission,'configure:template','add')} 
          update={!common.givenPermission(permission,'configure:template','update')}
          delete={!common.givenPermission(permission,'configure:template','delete')}
          safariclassName={"safari-style"}
          />
        </Paper> } 
       
      </div>
    </div>
  );
}

export default Template;
