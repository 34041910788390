import React, { memo, useEffect, useState } from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import Popover from "@mui/material/Popover";
import ListView from "elements/ListView";
import { Link, useParams, useOutletContext } from "react-router-dom";
import DynamicForm from "./DynamicForm";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import * as Services from "helpers/service";
import { common, envConfig, api } from "helpers";

const localizer = momentLocalizer(moment);

const Meeting = memo(({ handleExpanded, isExpand, id }) => {
  let { mod } = useParams();
  const [isForm, setIsForm] = useState(false);
  const [objectId, setObjectId] = useState(id);
  const [eventId, setEventId] = useState(0);
  const [objectType, setObjectType] = useState(mod);
  const [isView, setIsView] = useState("list");
  const [calEventList, setCalEventList] = useState([]);
  const [pageLimit, setPageLimit] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [orderBy, setOrderBy] = useState("CreatedOn:desc");
  const [selectedEvent, setSelectedEvent] = useState({});
  const [permission] = useOutletContext();

  useEffect(() => {
    if (id) {
      setObjectId(id);
      setObjectType(mod);
    }
  }, [eventId]);

  const changeFormType = (val, type, data, ids) => {
    if (data) {
      setEventId(data.EventId);
    } else {
      setEventId(0);
    }
    setIsForm(val);
  };

  const changeCalView = (data) => {
    setEventId(data.id);
    setIsForm(true);
    setIsView("list");
    setAnchorEl(false);
  };

  /**
   * Get all meetings from DB
   */
  const getAllMeetings = () => {
    let filter = `?q=ObjectId=${objectId}&totalResults=true&orderBy=${orderBy}&limit=${pageLimit}&offset=${
      (currentPage - 1) * pageLimit
    }`;
    let url = encodeURI(envConfig.BASE_API + Services.CRM_MEETINGS + filter);
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
      moreHead: { rfv: 2 },
      cType: 4,
    };
    api.call(
      data,
      (response) => {
        if (response.status == 200) {
          if (response.data.items != null && response.data.items.length > 0) {
            let list = [];
            for (const item of response.data.items) {
              list.push({
                id: item.EventId,
                title: item.title,
                start: moment.utc(item.startTime).toDate(),
                end: moment.utc(item.endTime).toDate(),
                location: item.EventLocation,
              });
            }
            setCalEventList(list);
          } else {
          }
        } else {
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  const changeView = (type) => {
    setIsView(type);
    getAllMeetings();
  };

  const [anchorEl, setAnchorEl] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(true);
    setSelectedEvent(event);
  };

  const handleClose = () => {
    setAnchorEl(false);
  };

  const ModToKey = (modArg) => {
    let key = {
      campaign: "crm:campaigns",
      enquiry: "crm:enquiry",
      lead: "crm:lead",
      deal: "crm:deal",
    };
    return ["contacts"].includes(modArg) ? "contact" : key[modArg];
  };

  return (
    <div className="relative pb-3">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box className="crm-submenu-wrap safari-style">
          <Grid
            container
            spacing={2}
            alignItems="center"
            className="subheader-meeting-element safari-style"
          >
            <Grid item>
              {isView == "list" ? (
                <IconButton onClick={() => changeView("date")}>
                  <img
                    src={common.loadImg("meetingkanban.svg")}
                    alt="meeting"
                  />
                </IconButton>
              ) : (
                <IconButton onClick={() => changeView("list")}>
                  <img
                    src={common.loadImg("documentkanban.svg")}
                    alt="document"
                  />
                </IconButton>
              )}
            </Grid>

            <Grid item>
              {isExpand === "detail" ? (
                <Typography
                  className="expand pointer"
                  onClick={() => handleExpanded("tab")}
                >
                  Expand{" "}
                  <img src={common.loadImg("expandkanban.svg")} alt="expand" />
                </Typography>
              ) : (
                <Typography
                  className="expand pointer"
                  onClick={() => handleExpanded("detail")}
                >
                  Collapse{" "}
                  <img
                    src={common.loadImg("expandkanban.svg")}
                    alt="Collapse"
                  />
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
      {!isForm && isView == "list" && (
        <ListView
          module={"meeting"}
          changeForm={(val, type, item) => changeFormType(val, type, item)}
          query={{
            ObjectId: objectId,
            ObjectType: common.getObjectNameByPage(objectType),
          }}
          sort="CreatedOn:desc"
          type={"subMenu"}
          className={"table-scroll-position-collapse"}
          isExpand={isExpand}
          id={id}
          accAdd={true}
          accExport={true}
          accDelete={true}
          accUpdate={true}
          accImport={true}
          // accAdd={!common.givenPermission(permission, ModToKey(mod), "add")}
          // accExport={
          //   !common.givenPermission(permission, ModToKey(mod), "export")
          // }
          // accDelete={
          //   !common.givenPermission(permission, ModToKey(mod), "delete")
          // }
          // accUpdate={
          //   !common.givenPermission(permission, ModToKey(mod), "update")
          // }
          // accImport={
          //   !common.givenPermission(permission, ModToKey(mod), "import")
          // }
        />
      )}
      {isForm && isView == "list" && (
        <DynamicForm
          module={"meeting"}
          data={{
            value1: eventId > 0 ? eventId : "",
            value2: objectId,
            value3: common.getObjectNameByPage(objectType),
          }}
          closeForm={(val, type, data, id) =>
            changeFormType(val, type, data, id)
          }
          accAdd={false}
          accUpdate={false}
          // accAdd={common.givenPermission(permission, ModToKey(mod), "add")}
          // accUpdate={common.givenPermission(
          //   permission,
          //   ModToKey(mod),
          //   "update"
          // )}
        />
      )}

      {isView == "date" && (
        <Calendar
          localizer={localizer}
          events={calEventList}
          startAccessor="start"
          endAccessor="end"
          defaultView="month"
          views={["month", "week", "day"]}
          // views={""}
          scrollToTime={new Date(1970, 1, 1, 6)}
          // onSelectEvent={(event) => alert(event.title)}
          onSelectEvent={(event) => handleClick(event)}
          style={{ height: 500, padding: 4 }}
        />
      )}
      <Popover
        id={id}
        open={anchorEl}
        onClose={handleClose}
        disableScrollLock
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <Box minWidth={400}>
          <Grid container justifyContent="flex-end" className="border-bottom">
            <Grid item>
              <IconButton onClick={handleClose}>
                <img src={common.loadImg("filterCloseIcon.svg")} alt="close" />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-start" className="border-bottom">
            <Grid item px={2} py={1}>
              <Typography className="event-popup-label">Title</Typography>
              <h3 className="event-popup-title">
                {selectedEvent.title ? selectedEvent.title : "-"}
              </h3>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-start" className="border-bottom">
            <Grid item px={2} py={1}>
              <Typography className="event-popup-label">
                Date and Time
              </Typography>
              <h3 className="event-popup-datetime">
                {selectedEvent.start
                  ? moment(selectedEvent.start).format("MMMM Do YYYY, h:mm a")
                  : "-"}
              </h3>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-start" className="border-bottom">
            <Grid item px={2} py={1}>
              <Typography className="event-popup-label">Venue</Typography>
              <h3 className="event-popup-datetime">
                {selectedEvent.location ? selectedEvent.location : "-"}
              </h3>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-start" className="border-bottom">
            <Grid
              item
              xs={12}
              px={2}
              py={1}
              display="flex"
              justifyContent="space-between"
              onClick={() => changeCalView(selectedEvent)}
            >
              <Link to="#" className="event-popup-link">
                Edit Event
                <img
                  src={common.loadImg("edit-icon.svg")}
                  alt="close"
                  className="ps-2"
                />
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </div>
  );
});

export { Meeting };
