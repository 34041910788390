import {
  Box,
  Divider,
  Grid,
  Hidden,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { common } from "helpers";
import CampaignsSubMenu from "./crmSubMenuView";
import React, { memo, useState } from "react";
// import MainLayout from "layouts/mainLayout";
import CrmGeneralView from "./crmGeneralView";

const campaignDetail = memo(() => {
  const [isExpand, setIsExpanded] = useState("detail");
  const handleExpanded = (expandSection) => {
    setIsExpanded(expandSection);
  };
  return (

    // <MainLayout activeTab={4}>
    <Box px={4} mb={10}>
      <Box
        py={2}
        sx={{
          mt: { xs: 3, lg: 0 },
        }}
      >
        <Paper variant="contained" square>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <Box py={1} px={1} className="icon-back">
                    <IconButton>
                      <img
                        src={common.loadImg("backArrowKanban.svg")}
                        alt="back"
                      />
                    </IconButton>
                  </Box>
                </Grid>
                <Grid item>
                  <Typography className="detail-title">
                    Facebook Campaign 2021
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Box px={2}>
                <Grid
                  container
                  alignItems="center"
                  spacing={2}
                  sx={{
                    justifyContent: { xs: "center", lg: "start" },
                  }}
                >
                  <Grid item>
                    <Hidden smDown>
                      <Box className="verical-divider">
                        <Divider orientation="vertical" />
                      </Box>
                    </Hidden>
                  </Grid>
                  <Grid item>
                    <Box className="disable-campaign-box">
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        className="pointer"
                      >
                        <Grid item>
                          <img
                            src={common.loadImg("arrowBackKanban.svg")}
                            alt="back"
                          />
                        </Grid>
                        <Grid item>
                          <Typography className="">Prev Campaign</Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Hidden smDown>
                      <Box className="verical-divider">
                        <Divider orientation="vertical" />
                      </Box>
                    </Hidden>
                  </Grid>
                  <Grid item>
                    <Box className="active-campaign-box">
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        className="pointer"
                      >
                        <Grid item>
                          <Typography>Next Campaign</Typography>
                        </Grid>
                        <Grid item>
                          <img
                            src={common.loadImg("arrowForwardKanban.svg")}
                            alt="back"
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Hidden smDown>
                      <Box className="verical-divider">
                        <Divider orientation="vertical" />
                      </Box>
                    </Hidden>
                  </Grid>
                  <Grid item>
                    <a href="/crm/edit/5" style={{ textDecoration: "none" }}>
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        className="pointer"
                      >
                        <Grid item>
                          <Typography className="edit-campaign">
                            Edit
                          </Typography>
                        </Grid>
                        <Grid item>
                          <img
                            src={common.loadImg("editIconkanban.svg")}
                            alt="back"
                          />
                        </Grid>
                      </Grid>
                    </a>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>

      <div className="scrollmenu">
        <div className="arrow-pointer">
          <Grid container spacing={1} className="inner-box">
            <Grid item>
              <Box className="active-count">01</Box>
            </Grid>
            <Grid item>
              <Box>
                <Typography className="active-count-text">
                  Welcome{" "}
                  <Typography className="active-count-text">Email</Typography>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
        <div className="arrow-pointer-clip">
          <Grid container spacing={1} className="inner-box" justify="center">
            <Grid item>
              <Box className="active-count">02</Box>
            </Grid>
            <Grid item>
              <Box>
                <Typography className="active-count-text">
                  Not{" "}
                  <Typography className="active-count-text">
                    Contacted
                  </Typography>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
        <div className="arrow-pointer-clip">
          <Grid container spacing={1} className="inner-box" justify="center">
            <Grid item>
              <Box className="active-count">03</Box>
            </Grid>
            <Grid item>
              <Box>
                <Typography className="active-count-text">
                  Attempted{" "}
                  <Typography className="active-count-text">
                    to call D1
                  </Typography>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
        <div className="arrow-pointer-clip">
          <Grid container spacing={1} className="inner-box" justify="center">
            <Grid item>
              <Box className="active-count">04</Box>
            </Grid>
            <Grid item>
              <Box>
                <Typography className="active-count-text">
                  Attempted{" "}
                  <Typography className="active-count-text">
                    to call D2
                  </Typography>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
        <div className="arrow-pointer-clip">
          <Grid container spacing={1} className="inner-box" justify="center">
            <Grid item>
              <Box className="active-count">05</Box>
            </Grid>
            <Grid item>
              <Box>
                <Typography className="active-count-text">
                  Call{" "}
                  <Typography className="active-count-text">
                    In Future
                  </Typography>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
        <div className="arrow-pointer-clip">
          <Grid container spacing={1} className="inner-box" justify="center">
            <Grid item>
              <Box className="active-count">06</Box>
            </Grid>
            <Grid item>
              <Box>
                <Typography className="active-count-text">
                  Documents{" "}
                  <Typography className="active-count-text">
                    Requested
                  </Typography>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
        <div className="arrow-pointer-clip">
          <Grid container spacing={1} className="inner-box" justify="center">
            <Grid item>
              <Box className="active-count">07</Box>
            </Grid>
            <Grid item>
              <Box>
                <Typography className="active-count-text">
                  Documents{" "}
                  <Typography className="active-count-text">
                    Received
                  </Typography>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>

        <div className="arrow-pointer-clip-left">
          <Grid container spacing={1} className="inner-box" justify="center">
            <Grid item>
              <Box className="active-count">08</Box>
            </Grid>
            <Grid item>
              <Box>
                <Typography className="active-count-text">
                  Complete{" "}
                  <Typography className="active-count-text">
                    Campaign
                  </Typography>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>

      {true && (
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={isExpand === "detail" ? 8 : 1}
            xl={isExpand === "detail" ? 8 : 1}
          >
            {isExpand === "detail" ? (
              <CrmGeneralView
                handleExpanded={handleExpanded}
                isExpand={isExpand}
              />
            ) : (
              <Paper
                variant="contained"
                className="collapse-paper pointer"
                onClick={() => handleExpanded("detail")}
                style={{ height: "100vh" }}
              >
                <Box className="collapse-camp">Campaign Information</Box>
                <img
                  src={common.loadImg("arrowForwardKanban.svg")}
                  alt="arrow"
                />
              </Paper>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={isExpand === "tab" ? 11 : 4}
            xl={isExpand === "tab" ? 11 : 4}
          >
            {true && (
              <CampaignsSubMenu
                handleExpanded={handleExpanded}
                isExpand={isExpand}
              />
            )}
          </Grid>
        </Grid>
      )}
    </Box>
    // </MainLayout>
  );
});

export default campaignDetail;
