import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Select, { components } from "react-select";
import { common } from "helpers";

import { Link } from "react-router-dom";

import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
  Checkbox,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import InputIcon from "react-multi-date-picker/components/input_icon";
import DatePicker from "react-multi-date-picker";

const options = [
  { value: "option1", label: "option1" },
  { value: "option2", label: "option2" },
  { value: "option3", label: "option3" },
];
function AddFormTransition(props) {
  // render
  const [disabled, setDisabled] = React.useState(true);
  const [isActive, setIsActive] = useState(false);
  const [isActive1, setIsActive1] = useState(false);

  const [selectedOption, setSelectedOption] = useState(null);
  const { ValueContainer, Placeholder } = components;

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };
  return (
    <div style={{ padding: "20px" }}>
      <Paper
        sx={{ width: "100%", mb: 2 }}
        className={`container responsive-table p-0`}
      >
        <Box>
          <Paper variant="contained dynamic-form-wrap" square>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    <Box py={1} px={1} className="icon-back icon-buton">
                      <IconButton>
                        <Link to="/setting/vendor/location">
                          <img
                            src={common.loadImg("backArrowKanban.svg")}
                            alt="back"
                          />
                        </Link>
                      </IconButton>
                    </Box>
                  </Grid>
                  <Grid item className="df-main-head">
                    <Typography className="detail-title">
                      Add Status Transition
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Box px={2}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                      <Box className="verical-divider">
                        <Divider orientation="vertical" />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box>
                        <Button
                          className="save-note"
                          type="submit"
                          disabled={false}
                        >
                          Save Changes
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box className="vertical-divider">
                        <Divider orientation="vertical" />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box className="revert-close">
                        <Button className="save-note pe-0">
                          <Link to="/setting/vendor/location">
                            Revert and Close
                            <img
                              src={common.loadImg("closeKanban.svg")}
                              alt="close"
                            />
                          </Link>
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Box>
              <Divider />
            </Box>
            <Box p={2} px={5} className="form-wrap">
              <Typography className="info-title dynamic-form-title mt-3">
                STATUS TRANSITION INFORMATION
              </Typography>
              <Box mt={3}>
                <Divider />
              </Box>
              <Box mt={2} className="camp-edit meet-table">
                <Row className="m-0">
                  <Col xs={6} className="body-th common-input-style">
                    <Box className="filled-box">
                      <TextField
                        id="filled-basic"
                        label="Order No*"
                        variant="filled"
                      />
                    </Box>
                  </Col>
                  <Col xs={6} className="body-th common-input-style">
                    <Box className="filled-box">
                      <TextField
                        id="filled-basic"
                        label="Transition Name*"
                        variant="filled"
                      />
                    </Box>
                  </Col>

                  <Col xs={6} className="body-th common-input-style">
                    <Box className="">
                      <FormControl fullWidth className="select-float-label">
                        <Select
                          className="text-box custom-react-select"
                          labelId="select"
                          id="select"
                          label="From Status*"
                          placeholder="From Status*"
                          isClearable={true}
                          options={options}
                          components={{
                            ValueContainer: CustomValueContainer,
                          }}
                          styles={{
                            container: (provided, state) => ({
                              ...provided,
                              marginTop: 0,
                            }),
                            valueContainer: (provided, state) => ({
                              ...provided,
                              overflow: "visible",
                            }),
                            placeholder: (provided, state) => ({
                              ...provided,
                              position: "absolute",
                              top:
                                state.hasValue || state.selectProps.inputValue
                                  ? -15
                                  : "10%",
                              transition: "top 0.1s, font-size 0.1s",
                              fontSize:
                                state.hasValue || state.selectProps.inputValue
                                  ? 11
                                  : 13,
                              color: "#9daabb",
                            }),
                            
                          }}
                        ></Select>
                      </FormControl>
                    </Box>
                  </Col>
                  <Col xs={6} className="body-th common-input-style">
                    <Box className="">
                      <FormControl fullWidth className="select-float-label">
                        <Select
                          className="text-box custom-react-select"
                          labelId="select"
                          id="select"
                          label="To Status*"
                          placeholder="To Status*"
                          isClearable={true}
                          options={options}
                          menuPortalTarget={document.body}
                          components={{
                            ValueContainer: CustomValueContainer,
                          }}
                          styles={{
                            container: (provided, state) => ({
                              ...provided,
                              marginTop: 0,
                            }),
                            valueContainer: (provided, state) => ({
                              ...provided,
                              overflow: "visible",
                            }),
                            placeholder: (provided, state) => ({
                              ...provided,
                              position: "absolute",
                              top:
                                state.hasValue || state.selectProps.inputValue
                                  ? -15
                                  : "10%",
                              transition: "top 0.1s, font-size 0.1s",
                              fontSize:
                                state.hasValue || state.selectProps.inputValue
                                  ? 11
                                  : 13,
                              color: "#9daabb",
                            }),
                           
                          }}
                        ></Select>
                      </FormControl>
                    </Box>
                  </Col>
                  <Col xs={6} className="body-th common-input-style">
                    <Box className="filled-box">
                      <TextField
                        id="filled-basic"
                        label="Tag"
                        variant="filled"
                      />
                    </Box>
                  </Col>

                  <Col xs={6} className="body-th common-input-style">
                    <Box className="">
                      <FormControl fullWidth className="select-float-label">
                        <Select
                          className="text-box custom-react-select"
                          labelId="select"
                          id="select"
                          label="Security Roles"
                          placeholder="Security Roles"
                          isClearable={true}
                          options={options}
                          components={{
                            ValueContainer: CustomValueContainer,
                          }}
                          styles={{
                            container: (provided, state) => ({
                              ...provided,
                              marginTop: 0,
                            }),
                            valueContainer: (provided, state) => ({
                              ...provided,
                              overflow: "visible",
                            }),
                            placeholder: (provided, state) => ({
                              ...provided,
                              position: "absolute",
                              top:
                                state.hasValue || state.selectProps.inputValue
                                  ? -15
                                  : "10%",
                              transition: "top 0.1s, font-size 0.1s",
                              fontSize:
                                state.hasValue || state.selectProps.inputValue
                                  ? 11
                                  : 13,
                              color: "#9daabb",
                            }),
                            
                          }}
                        ></Select>
                      </FormControl>
                    </Box>
                  </Col>
                  <Col xs={6} className="body-th common-input-style">
                    <Box className="text-box" id="float-label">
                      <label
                        className={
                          isActive
                            ? "Active text-label ml-4 camp-time-label"
                            : "text-label ml-4 camp-time-label"
                        }
                      >
                        Start Date
                      </label>

                      <Box className="ml-1 input-date-picker">
                        <DatePicker
                          format="MM/DD/YYYY"
                          render={<InputIcon />}
                          placeholder="Start Date"
                          value=""
                          onChange={setIsActive}
                        />
                      </Box>
                    </Box>
                  </Col>
                  <Col xs={6} className="body-th common-input-style">
                    <Box className="text-box" id="float-label1">
                      <label
                        className={
                          isActive1
                            ? "Active text-label ml-4 camp-time-label"
                            : "text-label ml-4 camp-time-label"
                        }
                      >
                        End Date
                      </label>

                      <Box className="ml-1 input-date-picker">
                        <DatePicker
                          format="MM/DD/YYYY"
                          render={<InputIcon />}
                          placeholder="End Date"
                          value=""
                          onChange={setIsActive1}
                        />
                      </Box>
                    </Box>
                  </Col>
                  <Col xs={6} className="body-th common-input-style">
                    <FormControlLabel
                      className="dynamic-checkbox ms-0 checkbox-space"
                      control={<Checkbox />}
                      label="Active"
                      value=""
                      labelPlacement="end"
                    />
                  </Col>
                </Row>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Paper>
    </div>
  );
}

export default AddFormTransition;
