import React from "react";
// Import Dialod from Material UI
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import LoaderImg from "assets/img/loader.gif";

class PopupLoader extends React.Component {
  render() {
    return (
      <Dialog
        aria-labelledby="responsive-dialog-title"
        maxWidth={"sm"}
        open={true}
        disableScrollLock
      >
        <DialogContent>
          <div className="signup-loader-wrap text-center">
            <img src={LoaderImg} alt="Loading..." />
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}
export default PopupLoader;
