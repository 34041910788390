import { useEffect, useState } from "react";

import { api, envConfig } from "helpers";
import { CRM_TERRITORIES } from "helpers/service";
import { Tree, TreeNode } from "react-organizational-chart";
import { splitTreeNode } from "helpers/treeChart";

import StatusBar from "elements/StatusBar";

import ParentCard from "./parentCard";
import ChildCard from "./childCard";

const TreeChart = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [departmentList, setDepartmentList] = useState([]);
  const [isCollapsedView, setIsCollapsedView] = useState(false);

  useEffect(() => {
    loadDepartmentList();
  }, []);

  const loadDepartmentList = () => {
    setIsLoading(true);
    let url = `${envConfig.BASE_API}${CRM_TERRITORIES}?expand=CholaCrmTerritoryTeamView&limit=10000&offset=0`;

    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
      moreHead: { rfv: 2 },
      cType: 4,
    };

    api.call(
      data,
      (response) => {
        if (response) {
          const result = response.data;
          setDepartmentList(result?.items);
        }

        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
      },
      (final) => {
        setIsLoading(false);
      }
    );
  };

  const handleCollapse = () => {
    setIsCollapsedView(!isCollapsedView);
  };

  const getCollapsedList = () => {
    const collapsedList = [...departmentList];
    if (isCollapsedView) {
      return departmentList?.slice(0, 1);
    }

    return splitTreeNode(collapsedList);
  };

  const renderTreeNode = (childTree) => {
    return childTree?.child?.map((node, index) => (
      <TreeNode key={index} label={<ChildCard data={node} />}>
        {renderTreeNode(node)}
      </TreeNode>
    ));
  };

  const { zoomLevel } = props;
  return (
    <div style={{ zoom: `${zoomLevel}%` }}>
      {isLoading ? (
        <StatusBar status="process" />
      ) : (
        getCollapsedList()?.map((element, index) => (
          <Tree
            key={`${element?.label}_${index}`}
            lineWidth={"1px"}
            lineColor={"#979797"}
            lineBorderRadius={"10px"}
            label={
              <ParentCard
                data={element}
                handleCollapse={() => handleCollapse()}
              />
            }
          >
            {renderTreeNode(element)}
          </Tree>
        ))
      )}

      {!isLoading && departmentList?.length === 0 ? (
        <StatusBar status="empty" />
      ) : null}
    </div>
  );
};

export default TreeChart;
