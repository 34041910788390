import React, { memo, useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import Grid from "@material-ui/core/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@material-ui/core/Paper";
import serviceList from "assets/files/serviceList.json";
import { common, tools, envConfig, api } from "helpers";
import { useParams, useNavigate } from "react-router-dom";
import * as Services from "helpers/service";
import StatusBar from "./StatusBar";

const Report = memo(({handleExpanded, isExpand}) => {
  let { mod, type, id } = useParams();
  let service = serviceList[mod];
  const [data, setData] = useState({});
  const [report, setReport] = useState({});
  const [status, setStatus] = useState("process");

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    let url = `${envConfig.BASE_API}${Services[service.api]}/${id}`;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
      moreHead: { rfv: 2 },
      cType: 4,
    };
    api.call(
      data,
      (response) => {
        console.log("=========data", response);
        if (response.status == 200) {
          setData(response.data);
          getCampaignReport();
        } else {
          setStatus("error");
        }
      },
      (error) => {
        setStatus("error");
      },
      (final) => {}
    );
  };

  /**
   * Get Campaign Reports by ID
   */
  const getCampaignReport = () => {
    let url = envConfig.BASE_API + Services.CAMPAIGN_REPORT + "/" + id;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
      moreHead: { rfv: 2 },
      cType: 4,
    };
    api.call(
      data,
      (response) => {
        console.log("=========report", response);
        if (response.status == 200) {
          setReport(response.data);
          setStatus("success");
        } else {
          setStatus("error");
        }
      },
      (error) => {
        setData({});
        setReport({});
        setStatus("error");
      },
      (final) => {}
    );
  };

  return (
    <div className="report-wrap">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box className="crm-submenu-wrap">
          <Grid
            container
            spacing={2}
            alignItems="center"
            className="subheader-elements"
          >
            <Grid item>
              {isExpand === "detail" ? (
                <Typography
                  className="expand pointer"
                  onClick={() => handleExpanded("tab")}
                >
                  Expand{" "}
                  <img src={common.loadImg("expandkanban.svg")} alt="expand" />
                </Typography>
              ) : (
                <Typography
                  className="expand pointer"
                  onClick={() => handleExpanded("detail")}
                >
                  Collapse{" "}
                  <img
                    src={common.loadImg("expandkanban.svg")}
                    alt="Collapse"
                  />
                </Typography>
              )}
            </Grid>
           </Grid>
        </Box>
      </Box>
      <StatusBar status={status} />
      {status == "success" && (
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell colSpan="2">
                  <div className="report-pointer">Reports</div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="center">Targets</TableCell>
                <TableCell align="center">Actuals</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Lead Count</TableCell>
                <TableCell>
                  <span className="report-bg">
                    {data.TargetLeadCount ? data.TargetLeadCount : 0}
                  </span>
                </TableCell>
                <TableCell>
                  <span className="report-bg">
                    {report.LeadsCount ? report.LeadsCount : 0}
                  </span>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Deal Count</TableCell>
                <TableCell>
                  <span className="report-bg">
                    {data.TargetOpportunityCount
                      ? data.TargetOpportunityCount
                      : 0}
                  </span>
                </TableCell>
                <TableCell>
                  <span className="report-bg">
                    {report.OpportunityCount ? report.OpportunityCount : 0}
                  </span>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Quote Count</TableCell>
                <TableCell>
                  <span className="report-bg">
                    {data.TargetQuoteCount ? data.TargetQuoteCount : 0}
                  </span>
                </TableCell>
                <TableCell>
                  <span className="report-bg">
                    {report.QuoteCount ? report.QuoteCount : 0}
                  </span>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Leads Revenue</TableCell>
                <TableCell>
                  <span className="report-bg">
                    {data.TargetLeadRevenue ? data.TargetLeadRevenue : 0}
                  </span>
                </TableCell>
                <TableCell>
                  <span className="report-bg">
                    {report.LeadsRevenue ? report.LeadsRevenue : 0}
                  </span>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Opportunity Revenue</TableCell>
                <TableCell>
                  <span className="report-bg">
                    {data.TargetOpportunityRevenue
                      ? data.TargetOpportunityRevenue
                      : 0}
                  </span>
                </TableCell>
                <TableCell>
                  <span className="report-bg">
                    {report.OpportunityRevenue ? report.OpportunityRevenue : 0}
                  </span>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Quote Revenue</TableCell>
                <TableCell>
                  <span className="report-bg">
                    {data.TargetQuoteRevenue ? data.TargetQuoteRevenue : 0}
                  </span>
                </TableCell>
                <TableCell>
                  <span className="report-bg">
                    {report.QuoteRevenue ? report.QuoteRevenue : 0}
                  </span>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
});
export { Report };
