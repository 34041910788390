import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
  TextField,
  IconButton,
} from "@mui/material";

import AmlHashForm from "aml-hash-form";
import { api, common, envConfig } from "helpers";

import { CRM_CREATE_USER } from "helpers/service";

const BACK_URL = "/profile";
function ChangePassword() {
  const formFields = {
    password: {
      validate: [
        "req#Password is required!",
        "min:6#Password should be minimum 6 Digits",
        "max:15#Password should be maximum 15 Digits",
      ],
    },
    confirmPassword: {
      validate: [
        "req#Confirm Password is required!",
        "min:6#Password should be minimum 6 Digits",
        "max:15#Password should be maximum 15 Digits",
        "sameAsField:password#Your password is not match",
      ],
    },
  };
  const { fields, errors, handleChange, handleSubmit } =
    AmlHashForm(formFields);
  const [isLoader, setIsLoader] = useState(false);
  let userData = common.authInfo();

  const authString = `${userData.UserName}:${fields.password}`;

  const onSubmit = (e) => {
    e.preventDefault();
    let isValid = handleSubmit();
    if (isValid) {
      setIsLoader(true);
      let params = JSON.stringify({
        name: "updatePassword",
        parameters: [
          {
            username: userData.UserName,
          },
          {
            Password: fields.password,
          },
        ],
      });
      let url = `${envConfig.AUTH_REST_URL}${CRM_CREATE_USER}`;
      let data = {
        url: url,
        type: "dynamic",
        method: "POST",
        auth: "basic",
        cType: 3,
        body: params,
      };

      api.call(
        data,
        (res) => {
          console.log("res", res);
          if (res.status === 200) {
            updateBasicToken();
            setTimeout(() => {
              common.snack("S", "Password has been successfully changed");
            }, 500);
          } else {
            common.snack("E", "Password not updated please check");
          }
        },
        (error) => {
          common.snack("E", "Password not updated please check");
          setIsLoader(false);
        },
        (final) => {
          setIsLoader(false);
        }
      );
    }
  };

  /**
   * Update Basic Token and save it in localstorage after update the password
   */
  const updateBasicToken = () => {
    let user = common.localGet("userData");
    let luData = JSON.parse(common.deCrypt(user));
    luData.basicToken = btoa(authString);
    common.localSet("userData", common.crypt(luData, true));
  };
  
  return (
    <div style={{ padding: "20px" }}>
      <Paper
        sx={{ width: "100%", mb: 2 }}
        className={`container responsive-table p-0`}
      >
        <Box>
          <Paper variant="contained dynamic-form-wrap" square>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item className="detail-head">
                <Grid container alignItems="center" spacing={2} className="detail-head">
                  <Grid item>
                    <Box py={1} px={1} className="icon-back icon-buton">
                      <IconButton>
                        <Link to={BACK_URL}>
                          <img
                            src={common.loadImg("backArrowKanban.svg")}
                            alt="back"
                          />
                        </Link>
                      </IconButton>
                    </Box>
                  </Grid>
                  <Grid item className="df-main-head">
                    <Typography className="detail-title mt-2">
                      Change Password
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item className="safari-style">
                <Box px={2} className="safari-style">
                  <Grid container alignItems="center" spacing={2} className="safari-style">
                    <Grid item>
                      <Box className="verical-divider">
                        <Divider orientation="vertical" />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box>
                        <Button
                          className="save-note"
                          fullWidth
                          disabled={isLoader}
                          onClick={(e) => onSubmit(e)}
                        >
                          {isLoader ? "Processing" : "Save Changes"}
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box className="vertical-divider">
                        <Divider orientation="vertical" />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box className="revert-close">
                        <Button className="save-note pe-0">
                          <Link to={BACK_URL}>
                            Revert and Close
                            <img
                              src={common.loadImg("closeKanban.svg")}
                              alt="close"
                            />
                          </Link>
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Box>
              <Divider />
            </Box>
            <Box mt={2} className="camp-edit meet-table">
              <Row className="m-0">
                {/* <Col xs={6} className="body-th common-input-style mandatoryclass">
                  <Box className="filled-box">
                    <TextField
                      id="filled-basic"
                      label="Current Password"
                      variant="filled"
                      name="currentpassword"
                      type="password"
                      />
                    </Box>
                </Col> */}
                <Col
                  xs={6}
                  className="body-th common-input-style mandatoryclass"
                >
                  <Box className="filled-box">
                    <TextField
                      id="password"
                      name="password"
                      label="New Password"
                      variant="filled"
                      type="password"
                      onChange={handleChange}
                      value={fields.password}
                    />
                  </Box>
                  <p className="error-txt show form-text">{errors.password}</p>
                </Col>
                <Col
                  xs={6}
                  className="body-th common-input-style mandatoryclass"
                >
                  <Box className="filled-box">
                    <TextField
                      id="confirmPassword"
                      name="confirmPassword"
                      label="Confirm Password"
                      variant="filled"
                      type="password"
                      onChange={handleChange}
                      value={fields.confirmPassword}
                    />
                  </Box>
                  <p className="error-txt show form-text">
                    {errors.confirmPassword}
                  </p>
                </Col>
              </Row>
            </Box>
          </Paper>
        </Box>
      </Paper>
    </div>
  );
}
export default ChangePassword;
