import { Box } from "@mui/material";
import React, { memo } from "react";
import { useParams } from "react-router-dom";

import HrmsTab from "./hrmsTab";
import ConfigureTab from "./configureTab";
import VendorTab from "./vendorTab";

const Setting = memo(() => {
    /***   for subheader fixed */
    React.useEffect(() => {
      var isMobileVersion = document.getElementsByClassName("rotate-arrow");
      const fixedHeader = document.getElementById("fixed-header");
      if(isMobileVersion.length > 0){
      //  document.getElementById("fixed-header").style.width = "86%";
      // fixedHeader.className = "MuiPaper-root MuiPaper-container fixed-header css-108pjeo-MuiPaper-root menu-shrink";
      fixedHeader.classList.add("menu-shrink");
      fixedHeader.classList.remove("menu-expand");
      }else{
      //  document.getElementById("fixed-header").style.width = "94%";
      // fixedHeader.className = "MuiPaper-root MuiPaper-container fixed-header css-108pjeo-MuiPaper-root menu-expand";
      fixedHeader.classList.remove("menu-shrink");
      fixedHeader.classList.add("menu-expand");
      }
    });
    /***   for subheader fixed */
  let { tab } = useParams();
  return (
    <div>
      <Box className="setting">
        {tab == "hrms" ? (
          <HrmsTab />
        ) : tab == "configure" ? (
          <ConfigureTab />
        ) : (
          <VendorTab />
        )}
      </Box>
    </div>
  );
});

export default Setting;
