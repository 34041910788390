import React, { memo, useState } from "react";
import { Link } from "react-router-dom";

import FormControl from "@mui/material/FormControl";
import { Box, Button, Grid, Typography, Divider } from "@mui/material";
import { common } from "helpers";
import Select from "react-select";

const setting = [{ value: "Lead to mapping", label: "Lead to mapping" }];

const selectModule = [{ value: "leads", label: "Leads" }];
const Template = memo(() => {
  const [Module, setModule] = useState(selectModule[0]);

  const onSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="default-bg">
      <Box px={5} mt={3} className="template-box-border">
        <div className="row">
          <div className="col-12 mt-2 ">
            <div className="card border-color-F2F9FD">
              <div className="card-body header-border-padding p-0">
                <Box alignItems="center" justifyContent="center">
                  <Box py={3}>
                    <Grid
                      container
                      spacing={3}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Grid container spacing={3} alignItems="center">
                          <Grid item>
                            <Grid container alignItems="center">
                              <Grid item px={2}>
                                <Link to="/setting/view/configure">
                                  <img
                                    alt={"back"}
                                    className="mx-2"
                                    src={common.loadImg(
                                      "iconly-light-arrow-down-circle.svg"
                                    )}
                                  />
                                </Link>
                              </Grid>
                              <Grid item>
                                <Typography className="page-title">
                                  Edit Template
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item className="close-icn-border ">
                        <Box className="revert-close revert-close1">
                          <Button className="save-note " type="button">
                            Revert and Close
                            <Link to="/setting/view/configure">
                              {" "}
                              <img
                                src={common.loadImg("closeKanban.svg")}
                                alt="close"
                              />
                            </Link>
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box>
                    <Divider />
                  </Box>

                  <div className="row p-4">
                    <div className="col-lg-6">
                      <FormControl
                        sx={{ m: 1, minWidth: { xs: 220, lg: 120 } }}
                        m-0
                        className="dropdown w-100"
                      >
                        <label className="template-label-font template-label-position">
                          Select Module
                        </label>
                        <Select
                          className="module-list w-100 p-100"
                          defaultValue={selectModule}
                          onChange={setModule}
                          options={selectModule}
                        />
                      </FormControl>
                    </div>
                    <div className="col-lg-6 ">
                      <FormControl
                        sx={{ m: 1, minWidth: { xs: 220, lg: 120 } }}
                        m-0
                        className="template-name-border w-100"
                      >
                        <div className="template-name-border">
                          <label className="template-label-font template-label-padding">
                            Template Name
                          </label>
                          <input
                            type="text"
                            className="template-name w-100 p-100"
                            value="Appointment Email"
                          ></input>
                        </div>
                      </FormControl>
                    </div>
                  </div>

                  <Box>
                    <Divider />
                  </Box>

                  <Grid item>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item>
                        <Typography className="page-title mx-4 px-1 mt-3">
                          Design Your Template
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography className=" edit-desc mx-4 px-1">
                      To merge copy paste the required field from below
                    </Typography>
                  </Grid>
                  <Box className="design-template-border ">
                    <Grid container spacing={2} justifyContent="space-between">
                      <Grid item>
                        <Grid item className="html-background m-0">
                          <Grid container spacing={3}>
                            <Grid item>
                              <Grid item className="html-design-background">
                                <div className="move-position">
                                  <div className="outer-html-border">
                                    <Typography className="design-template-cardinner m-1">
                                      HTML
                                    </Typography>
                                  </div>
                                  <div className="move-img">
                                    <img src={common.loadImg("move.svg")}></img>
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Box mt={0}>
                          <Grid container spacing={1}>
                            <Grid item mt={2}>
                              {/* Active border className="image-border1" for buttons */}
                              <div className="image-border1 ">
                                <img
                                  src={common.loadImg("Shape.svg")}
                                  alt="columns"
                                  className="px-3 py-2"
                                ></img>
                                <div className="image-desc">Columns</div>
                              </div>
                            </Grid>
                            <Grid item mt={2}>
                              <div className="image-border">
                                <img
                                  src={common.loadImg("arrow-right-circle.svg")}
                                  alt="arrow"
                                  className="px-3 py-2"
                                ></img>
                                <div className="image-desc">Button</div>
                              </div>
                            </Grid>
                            <Grid item mt={2}>
                              <div className="image-border">
                                <img
                                  src={common.loadImg("divide.svg")}
                                  alt="divide"
                                  className="px-3 py-2"
                                ></img>
                                <div className="image-desc">Divider</div>
                              </div>
                            </Grid>
                          </Grid>
                          <Grid container spacing={1}>
                            <Grid item mt={2}>
                              <div className="image-border">
                                <img
                                  src={common.loadImg("align-center.svg")}
                                  alt="align"
                                  className="px-3 py-2"
                                ></img>
                                <div className="image-desc">Headings</div>
                              </div>
                            </Grid>
                            <Grid item mt={2}>
                              <div className="image-border">
                                <img
                                  src={common.loadImg("code.svg")}
                                  alt="html"
                                  className="px-3 py-2"
                                ></img>
                                <div className="image-desc">HTML</div>
                              </div>
                            </Grid>
                            <Grid item mt={2}>
                              <div className="image-border">
                                <img
                                  src={common.loadImg("image.svg")}
                                  alt="image"
                                  className="px-3 py-1"
                                ></img>
                                <div className="image-desc">Image</div>
                              </div>
                            </Grid>
                          </Grid>
                          <Grid container spacing={1}>
                            <Grid item mt={2}>
                              <div className="image-border">
                                <img
                                  src={common.loadImg("menu.svg")}
                                  alt="menu"
                                  className="px-3 py-2"
                                ></img>
                                <div className="image-desc">Menu</div>
                              </div>
                            </Grid>
                            <Grid item mt={2}>
                              <div className="image-border">
                                <img
                                  src={common.loadImg("type.svg")}
                                  alt="text"
                                  className="px-3 py-1"
                                ></img>
                                <div className="image-desc">Text</div>
                              </div>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>

                      <Grid item>
                        <Box mt={0}>
                          <Grid container>
                            <Grid item mt={2} className="side-content-setting ">
                              <Grid
                                item
                                className="side-content-padding   side-template-border"
                              >
                                <div className="img-side-menu ">
                                  {/* pointer class while menu is active className="pointer-img" */}
                                  <div className="pointer-img">
                                    <img
                                      src={common.loadImg("combined-shape.svg")}
                                      alt="pointer"
                                    ></img>
                                  </div>
                                  <img
                                    src={common.loadImg(
                                      "iconly-light-paper.svg"
                                    )}
                                    alt="columns"
                                    className="py-2"
                                  ></img>
                                </div>
                                <div className="side-img-desc ">Content</div>
                              </Grid>

                              <Grid
                                item
                                mt={1}
                                py={1}
                                className="side-template-border"
                              >
                                <div className="img-side-menu">
                                  <img
                                    src={common.loadImg(
                                      "iconly-light-category.svg"
                                    )}
                                    alt="columns"
                                    className="py-2"
                                  ></img>
                                </div>
                                <div className="side-img-desc">Blocks</div>
                              </Grid>
                              <Grid
                                item
                                mt={1}
                                className="side-content-padding2"
                              >
                                <div className="img-side-menu">
                                  {" "}
                                  <img
                                    src={common.loadImg(
                                      "iconly-light-paper-fail.svg"
                                    )}
                                    alt="columns"
                                    className="py-2"
                                  ></img>
                                </div>
                                <div className="side-img-desc">Body</div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <div className="row p-4">
                    <div className="col-lg-12 text-center">
                      <Button
                        className="sign-in-btn "
                        onClick={(e) => onSubmit(e)}
                      >
                        Update and Save
                      </Button>
                    </div>
                  </div>
                  <Box>
                    <Divider />
                  </Box>

                  <Grid item>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item>
                        <Typography className="page-title mx-4 px-1 mt-3">
                          Fields
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography className=" edit-desc mx-4 px-1">
                      To merge copy paste the required field from below
                    </Typography>
                  </Grid>
                  <Box mt={3}>
                    <Grid item className="mx-4 px-1 ">
                      <Grid container spacing={3} alignItems="center">
                        <Grid item>
                          <div className="fields-border"> Account Name </div>
                        </Grid>
                        <Grid item>
                          {" "}
                          <div className="fields-border">University Link</div>
                        </Grid>
                        <Grid item>
                          {" "}
                          <div className="fields-border">Address 1</div>
                        </Grid>
                        <Grid item>
                          {" "}
                          <div className="fields-border">Address 2</div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3} alignItems="center" mt={1}>
                        <Grid item>
                          <div className="fields-border"> Account Name </div>
                        </Grid>
                        <Grid item>
                          {" "}
                          <div className="fields-border">University Link</div>
                        </Grid>
                        <Grid item>
                          {" "}
                          <div className="fields-border">Address 1</div>
                        </Grid>
                        <Grid item>
                          {" "}
                          <div className="fields-border">Address 2</div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3} alignItems="center" mt={1}>
                        <Grid item>
                          <div className="fields-border"> Account Name </div>
                        </Grid>
                        <Grid item>
                          {" "}
                          <div className="fields-border">University Link</div>
                        </Grid>
                        <Grid item>
                          {" "}
                          <div className="fields-border">Address 1</div>
                        </Grid>
                        <Grid item>
                          {" "}
                          <div className="fields-border">Address 2</div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3} alignItems="center" mt={1}>
                        <Grid item>
                          <div className="fields-border"> Account Name </div>
                        </Grid>
                        <Grid item>
                          {" "}
                          <div className="fields-border">University Link</div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
});

export default Template;
