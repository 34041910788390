import {
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Typography,
    Checkbox,
    FormControlLabel,
  } from "@mui/material";
  import { useNavigate } from "react-router-dom";
  import PhoneInput from "react-phone-input-2";
  import DatePicker from "react-multi-date-picker";
  import TimePicker from "react-multi-date-picker/plugins/time_picker";
  import Select from "react-select";
  import { common } from "helpers";
  import React from "react";
  
  function TaskAdd() {
    const navigate = useNavigate();
    //Goto previous page
    const goto = (e) => {
      e.preventDefault();
      navigate("/crm/view/5");
    };
    return (
      <Box px={5} py={4}>
        <Paper variant="contained" square>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <Box py={1} px={1} className="icon-back">
                    <IconButton>
                      <img
                        src={common.loadImg("backArrowKanban.svg")}
                        alt="back"
                      />
                    </IconButton>
                  </Box>
                </Grid>
                <Grid item>
                  <Typography className="detail-title">Add Task</Typography>
                </Grid>
              </Grid>
            </Grid>
  
            <Grid item>
              <Box px={2}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    <Box className="verical-divider">
                      <Divider orientation="vertical" />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box>
                      <Button className="save-note" onClick={(e) => goto(e)}>
                        Save Changes
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box className="verical-divider">
                      <Divider orientation="vertical" />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box className="revert-close">
                      <Button className="save-note" onClick={(e) => goto(e)}>
                        Revert and Close
                        <img
                          src={common.loadImg("closeKanban.svg")}
                          alt="close"
                        />
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Box>
            <Divider />
          </Box>
          <Box p={2} px={5}>
            <Typography className="info-title mt-3">
              Campaign Information
            </Typography>
            <Box mt={3}>
              <Divider />
            </Box>
            <Box mt={2} className="camp-edit meet-table">
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableBody>
                    <TableRow
                      sx={{ "&:last-child, &:last-child": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className="body-th"
                        style={{ width: "50%" }}
                      >
                        <Box className="text-box">
                          <label className="text-label ml-4">Campaign Name</label>
                          <TextField
                            id="name"
                            fullWidth
                            placeholder="Campaign Name"
                          />
                        </Box>
                      </TableCell>
                      <TableCell className="body-th" style={{ width: "50%" }}>
                        <Box className="text-box">
                          <label className="text-label ml-4">
                            Campaign Description
                          </label>
                          <TextField
                            id="name"
                            rows={3}
                            fullWidth
                            placeholder="Campaign Description"
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child, &:last-child": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className="body-th"
                        style={{ width: "50%" }}
                      >
                        <FormControlLabel
                          className="dynamic-checkbox"
                          value="female"
                          control={<Checkbox />}
                          label="Keep me signed in"
                        />
                      </TableCell>
                      <TableCell className="body-th" style={{ width: "50%" }}>
                        {/* <DynamicSelect
                          isMulti={true}
                          isRender={true}
                          field={fItem}
                          fieldIndex={fieldIndex}
                          data={data}
                          params={props}
                          userPermissions={userPermissions}
                          onSelect={(data) => onFieldChange(data, fItem.index)}
                          ref={(el) => (myRefs.current[fItem.index] = el)}
                        /> */}
                        <Box className="text-box">
                          <label className="text-label ml-4">
                            Campaign Description
                          </label>
                          <textarea
                            rows={4}
                            className="text-area ml-4"
                            placeholder="Enter Description"
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
          <Box p={2} px={5}>
            <Typography className="info-title mt-3">Dates</Typography>
            <Box mt={3}>
              <Divider />
            </Box>
            <Box mt={2} className="camp-edit meet-table">
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableBody>
                    <TableRow
                      sx={{ "&:last-child, &:last-child": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className="body-th"
                        sx={{ width: { xs: "50%", lg: "50%" } }}
                      >
                        <Box className="text-box">
                          <label className="text-label ml-4">Campaign Date</label>
                          <Box className="ml-4">
                            <DatePicker
                              value="05/18/2020 02:20:36"
                              format="MM/DD/YYYY"
                              placeholder="Select Date"
                            />
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell
                        className="body-th"
                        sx={{ width: { xs: "50%", lg: "50%" } }}
                      >
                        <Box className="text-box">
                          <label className="text-label ml-4">Campaign Time</label>
                          <Box className="ml-4">
                            <DatePicker
                              value="05/18/2020 14:20:36"
                              format="MM/DD/YYYY HH:mm A"
                              plugins={[
                                <TimePicker
                                  hideSeconds
                                  position="bottom"
                                  className="ml-4"
                                />,
                              ]}
                            />
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child, &:last-child": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className="body-th"
                        sx={{ width: { xs: "50%", lg: "50%" } }}
                      >
                        <Box className="text-box">
                          <label className="text-label ml-4">
                            Campaign Country
                          </label>
                          <PhoneInput country={"lk"} className="ml-4" />
                        </Box>
                      </TableCell>
                      <TableCell
                        className="body-th"
                        sx={{ width: { xs: "50%", lg: "50%" } }}
                      >
                        <Box className="text-box">
                          <label className="text-label ml-4">
                            Campaign Option
                          </label>
                          <Select
                            isMulti={true}
                            isClearable={true}
                            options={[
                              { label: "option1", value: "1" },
                              { label: "option2", value: "2" },
                            ]}
                            className="ml-4"
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Paper>
      </Box>
    );
  }
  
  export default TaskAdd;
  