import React, { memo, useState } from "react";
import { Link } from "react-router-dom";

import {
  Box,
  Button,
  IconButton,
  Grid,
  Typography,
  Divider,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { common } from "helpers";
import Select from "react-select";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CircleChecked from "@material-ui/icons/CheckCircleOutline";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import { blue } from "@material-ui/core/colors";

// import Radio from "@mui/material/Radio";
// import RadioGroup from "@mui/material/RadioGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";

// import FormLabel from "@mui/material/FormLabel";

const AddReports = memo(() => {
  const onSubmit = (e) => {
    e.preventDefault();
  };

  const [checked, setChecked] = useState(true);
  const handleRadio = () => {
    setChecked(false);
  };

  return (
    <Box px={5} mt={3} className="template-box-border">
      <div className="row">
        <div className="col-12 mt-2 ">
          <div className="card border-color-F2F9FD">
            <div className="card-body header-border-padding  p-0">
              <Box alignItems="center" justifyContent="center" className="">
                <Box py={3}>
                  <Grid
                    container
                    spacing={3}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item>
                          <Typography className="page-title add-report-title mx-3">
                            Create New Report
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item marginRight={5}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                      >
                        <Grid item className="report-count-active-font">
                          <Grid container spacing={4}>
                            <Grid item className="">
                              <span className=" report-count-active"> 01 </span>
                            </Grid>
                            <Grid item>
                              <span>02 </span>
                            </Grid>
                            <Grid item className="">
                              {" "}
                              <span className="">03</span>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item className="close-icn-border ">
                      <Box className="revert-close revert-close1">
                        <Button className="save-note" type="button">
                          Close{" "}
                          <img
                            src={common.loadImg("closeKanban.svg")}
                            alt="close"
                          />
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Divider />
                </Box>
                <Box py={3}>
                  <Grid
                    container
                    spacing={3}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography className="module-report-title mt-3 mb-3 mx-3">
                        Module Information
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Divider />
                </Box>
                <Box px={2} mt={3}>
                  <div className="row">
                    <div className="col-md-6 mt-2 report-card-border mx-auto">
                      <div className="card ">
                        <div className="card-body card-report-height p-0">
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            className="px-3"
                          >
                            <Grid
                              container
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Grid item>
                                <Typography className="avatar-sub-text">
                                  Select module
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                          <div className="d-flex bg-F2F9FD ">
                            <Box className="filter-search2" width="100%">
                              <FormControl sx={{ width: "100%" }}>
                                <OutlinedInput
                                  id="search"
                                  placeholder={"Search for Module"}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <img
                                        src={common.loadImg(
                                          "common-search.svg"
                                        )}
                                        alt="search"
                                      />
                                    </InputAdornment>
                                  }
                                  aria-describedby="outlined-weight-helper-text"
                                />
                              </FormControl>
                            </Box>
                          </div>

                          <Box
                            px={2}
                            mt={2}
                            mb={2}
                            className="report-module-select"
                          >
                            <Grid item py={1}>
                              <input
                                type="radio"
                                className="radio-button ml-4"
                                id="campaign"
                                value="campaign"
                                name="module"
                              />

                              <label
                                className="radio-label px-3"
                                htmlFor="campaign"
                              >
                                Campaign
                              </label>
                            </Grid>
                            <Grid item py={1}>
                              <input
                                type="radio"
                                className="radio-button ml-4"
                                id="leads"
                                value="leads"
                                name="module"
                              />
                              <label
                                className="radio-label px-3"
                                htmlFor="leads"
                              >
                                Leads
                              </label>
                            </Grid>
                            <Grid item py={1}>
                              <input
                                type="radio"
                                className="radio-button ml-4"
                                id="tasks"
                                value="tasks"
                                name="module"
                              />
                              <label
                                className="radio-label px-3"
                                htmlFor="tasks"
                              >
                                Tasks
                              </label>
                            </Grid>
                            <Grid item py={1}>
                              <input
                                type="radio"
                                className="radio-button ml-4"
                                id="reports"
                                value="reports"
                                name="module"
                              />
                              <label
                                className="radio-label px-3"
                                htmlFor="reports"
                              >
                                Reports
                              </label>
                            </Grid>
                            <Grid item py={1}>
                              <input
                                type="radio"
                                className="radio-button ml-4"
                                id="meetings"
                                value="meetings"
                                name="module"
                              />
                              <label
                                className="radio-label px-3"
                                htmlFor="meetings"
                              >
                                Meetings
                              </label>
                            </Grid>
                          </Box>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mt-2 report-card-border mx-auto">
                      <div className="card ">
                        <div className="card-body card-report-height p-0">
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            className="px-3"
                          >
                            <Grid
                              container
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Grid item>
                                <Typography className="avatar-sub-text">
                                  Select module
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                          <Box>
                            <Divider />
                          </Box>
                          <Box
                            px={2}
                            mt={2}
                            mb={2}
                            className="report-module-select"
                          >
                            <Grid item>
                              <label className="custom-checkbox radio-label">
                                <input type="checkbox" id="lead" />
                                <span className="checkmark"></span>
                              </label>
                              <label
                                className="radio-label px-4 ml-3"
                                htmlFor="lead"
                              >
                                Leads
                              </label>
                            </Grid>
                            <Grid item>
                              <label className="custom-checkbox">
                                <input type="checkbox" id="appointment" />
                                <span className="checkmark"></span>
                              </label>
                              <label
                                className="radio-label px-4 ml-3"
                                htmlFor="appointment"
                              >
                                TSA Appointments
                              </label>
                            </Grid>
                            <Grid item>
                              <label className="custom-checkbox">
                                <input type="checkbox" id="audience" />
                                <span className="checkmark"></span>
                              </label>
                              <label
                                className="radio-label px-4 ml-3"
                                htmlFor="audience"
                              >
                                Audience
                              </label>
                            </Grid>
                            <Grid item>
                              <label className="custom-checkbox">
                                <input type="checkbox" id="task" />
                                <span className="checkmark"></span>
                              </label>
                              <label
                                className="radio-label px-4 ml-3"
                                htmlFor="task"
                              >
                                Tasks
                              </label>
                            </Grid>
                            <Grid item>
                              <label className="custom-checkbox">
                                <input type="checkbox" id="meeting" />
                                <span className="checkmark"></span>
                              </label>
                              <label
                                className="radio-label px-4 ml-3"
                                htmlFor="meeting"
                              >
                                Meetings
                              </label>
                            </Grid>
                            <Grid item>
                              <label className="custom-checkbox">
                                <input type="checkbox" id="people" />
                                <span className="checkmark"></span>
                              </label>
                              <label
                                className="radio-label px-4 ml-3"
                                htmlFor="people"
                              >
                                People
                              </label>
                            </Grid>
                            <Grid item>
                              <label className="custom-checkbox">
                                <input type="checkbox" id="contact" />
                                <span className="checkmark"></span>
                              </label>
                              <label
                                className="radio-label px-4 ml-3"
                                htmlFor="contact"
                              >
                                Contact
                              </label>
                            </Grid>
                            <Grid item>
                              <label className="custom-checkbox">
                                <input type="checkbox" id="status" />
                                <span className="checkmark"></span>
                              </label>
                              <label
                                className="radio-label px-4 ml-3"
                                htmlFor="status"
                              >
                                Status point
                              </label>
                            </Grid>
                          </Box>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
                <Box mt={6}>
                  <Grid
                    container
                    spacing={3}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography className="module-report-title m-3">
                        Report Type
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Divider />
                </Box>
                <div className="bg-F2F9FD type-card-wrap">
                  <Box px={2} py={3}>
                    <div className="row">
                      <div className="col-md-4 mt-2 ">
                        <div className="card type-card ">
                          <input
                            name="gender"
                            id="tabular"
                            type="radio"
                            className="inp-none"
                          />
                          <label htmlFor="tabular" className="chk-wrap">
                          
                            <div className="card-body border-notactive  p-0">
                              <img
                                src={common.loadImg("whiteTick.svg")}
                                alt="tick"
                                className="tick-mark-white"
                              ></img>
                              <Box py={3}>
                                <Grid
                                  container
                                  spacing={3}
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Grid item>
                                    <Grid
                                      container
                                      spacing={3}
                                      alignItems="center"
                                    >
                                      <Grid item mt={2}>
                                        <Typography className="camp-sub-des mx-3">
                                          Tabular Report
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Box py={1} px={2} mt={2}>
                                  <Grid item>
                                    <img
                                      src={common.loadImg("tabularReport.png")}
                                    ></img>
                                  </Grid>
                                </Box>
                                <Box py={1} px={2} mb={2}>
                                  <Grid item>
                                    <Typography className="report-desc">
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing ipsum dolor sit amet,
                                      consectetur adipiscing sit amet,
                                      adipiscing ipsum dolor sit amet,
                                      consectetur
                                    </Typography>
                                  </Grid>
                                </Box>
                              </Box>
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4 mt-2 ">
                        <div className="card type-card ">
                          <input
                            name="gender"
                            id="female"
                            type="radio"
                            className="inp-none"
                          />
                          <label htmlFor="female" className="chk-wrap">
                         
                            <div className="card-body  border-notactive  p-0">
                              <img
                                src={common.loadImg("whiteTick.svg")}
                                alt="tick"
                                className="tick-mark-white"
                              ></img>
                              <Box py={3}>
                                <Grid
                                  container
                                  spacing={3}
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Grid item>
                                    <Grid
                                      container
                                      spacing={3}
                                      alignItems="center"
                                    >
                                      <Grid item mt={2}>
                                        <Typography className="camp-sub-des mx-3 ">
                                          Summary Report
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Box py={1} px={2} mt={2}>
                                  <Grid item>
                                    <img
                                      src={common.loadImg("summary.png")}
                                    ></img>
                                  </Grid>
                                </Box>
                                <Box py={1} px={2} mb={2}>
                                  <Grid item>
                                    <Typography className="report-desc">
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing ipsum dolor sit amet,
                                      consectetur adipiscing sit amet,
                                      adipiscing ipsum dolor sit amet,
                                      consectetur
                                    </Typography>
                                  </Grid>
                                </Box>
                              </Box>
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4 mt-2 ">
                        <div className="card type-card  ">
                          <input
                            name="gender"
                            id="male"
                            type="radio"
                            className="inp-none"
                          />

                          <label htmlFor="male" className="chk-wrap">
                          
                            <div className="card-body border-notactive p-0">
                              <img
                                src={common.loadImg("whiteTick.svg")}
                                alt="tick"
                                className="tick-mark-white"
                              ></img>
                              <Box py={3}>
                                <Grid
                                  container
                                  spacing={3}
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Grid item>
                                    <Grid
                                      container
                                      spacing={3}
                                      alignItems="center"
                                    >
                                      <Grid item mt={2}>
                                        <Typography className="camp-sub-des mx-3 ">
                                          Matrix Report
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Box py={1} px={2} mt={2}>
                                  <Grid item>
                                    <img
                                      src={common.loadImg("matrixReport.png")}
                                    ></img>
                                  </Grid>
                                </Box>
                                <Box py={1} px={2} mb={2}>
                                  <Grid item>
                                    <Typography className="report-desc">
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing ipsum dolor sit amet,
                                      consectetur adipiscing sit amet,
                                      adipiscing ipsum dolor sit amet,
                                      consectetur
                                    </Typography>
                                  </Grid>
                                </Box>
                              </Box>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </Box>
                </div>
                <Box>
                  <Divider />
                </Box>
                <Box py={3} px={3}>
                  <Grid
                    container
                    spacing={3}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item>
                          <Grid container alignItems="center">
                            <Grid item>
                              <Link to="/report/index">
                                <img
                                  alt={"back"}
                                  className="mx-2"
                                  src={common.loadImg(
                                    "iconly-light-arrow-down-circle.svg"
                                  )}
                                />
                              </Link>
                            </Grid>
                            <Grid item>
                              <Typography className="camp-sub-name ">
                                Back
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Box>
                        <Link
                          to="/report/representation"
                          className="text-none"
                        >
                          <Button className="sign-in-btn mt-3 ">Next</Button>
                        </Link>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
});

export default AddReports;
