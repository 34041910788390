const splitChildNodes = (treeArray, TerritoryId) => {
  let childArray = [];
  if (treeArray && Array.isArray(treeArray)) {
    childArray = treeArray.filter((item) => item.ParentId === TerritoryId);
  }

  return childArray;
};

const subTreeLoop = (treeArray, nodeList) => {
  const subTreeArray = [];
  nodeList?.forEach((node) => {
    const subChildArray = splitChildNodes(treeArray, node.TerritoryId);

    node.child = subTreeLoop(treeArray, subChildArray);
    subTreeArray.push(node);
  });

  return subTreeArray;
};

const splitTreeNode = (treeArray) => {
  const treeArrayCopy = [...JSON.parse(JSON.stringify(treeArray))];
  let formatArray = [];

  if (treeArrayCopy && Array.isArray(treeArrayCopy)) {
    const parentId = null;

    const parentNode = splitChildNodes(treeArrayCopy, parentId);
    formatArray = subTreeLoop(treeArrayCopy, parentNode);
  }

  return formatArray;
};

export { splitTreeNode };
