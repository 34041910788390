import { Box, Grid, Paper, Item } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";
import { common } from "helpers";

const HrmsTab = () => {
  return (
    <>
      <Box mt={5} px={5}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <Paper variant="contained" square className="main-content-wrap">
              <Link to="/setting/department" className="link-text">
                <Box className="contents-padding activate-arrow ">
                  <Grid container>
                    <Grid item xs={11}>
                      <label className="setting-config-tab-titles">
                        Department
                      </label>
                      <p className="setting-card-content">
                        Add or remove departments related to your business or
                        company
                      </p>
                    </Grid>
                    <Grid item xs={1} className="padding-zero changeArrow">
                      <Link to="/setting/department">
                        <img
                          alt="next"
                          src={common.loadImg(
                            "iconly-light-arrow-next-circle.svg"
                          )}
                        />
                        <img
                          alt="next"
                          src={common.loadImg("next-activeArrow.svg")}
                          className="arrowActive"
                        />
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </Link>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Paper variant="contained" square className="main-content-wrap">
              <Link to="/setting/employee" className="link-text">
                <Box className="contents-padding activate-arrow">
                  <Grid container>
                    <Grid item xs={11}>
                      <Box>
                        <label className="setting-config-tab-titles">
                          Employees
                        </label>
                        <p className="setting-card-content">
                          Add or remove Employees related to your business or
                          company
                        </p>
                      </Box>
                    </Grid>
                    <Grid item xs={1} className="padding-zero changeArrow">
                      <Link to="/setting/employee">
                        <img
                          alt="next"
                          src={common.loadImg(
                            "iconly-light-arrow-next-circle.svg"
                          )}
                        />
                        <img
                          alt="next"
                          src={common.loadImg("next-activeArrow.svg")}
                          className="arrowActive"
                        />
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </Link>
            </Paper>
          </Grid>
          {/* <Grid item>
            <Paper variant="contained" square>
              <Box px={2}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    justifyContent: { xs: "center", lg: "space-between" },
                  }}
                  alignItems="center"
                >
                  <Grid item>
                    <Box pl={3}>
                      <h5 className="setting-card-title">Leave Calendar</h5>
                      <p className="setting-card-content">
                        Add or remove departments related to your business or
                        company
                      </p>
                    </Box>
                  </Grid>
                  <Grid item>
                    <a href="#0">
                      <img
                        alt="next"
                        src={common.loadImg("next-active.png")}
                        height={50}
                      />
                    </a>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
          <Grid item>
            <Paper variant="contained" square>
              <Box px={2}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    justifyContent: { xs: "center", lg: "space-between" },
                  }}
                  alignItems="center"
                >
                  <Grid item>
                    <Box pl={3}>
                      <h5 className="setting-card-title">Leave Settings</h5>
                      <p className="setting-card-content">
                        Add or remove departments related to your business or
                        company
                      </p>
                    </Box>
                  </Grid>
                  <Grid item>
                    <a href="#0">
                      <img
                        alt="next"
                        src={common.loadImg("next-active.png")}
                        height={50}
                      />
                    </a>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid> */}
          <Grid item xs={12} sm={12} md={6}>
            <Paper variant="contained" square className="main-content-wrap">
              <Link to="/setting/rolesResponsiblity" className="link-text">
                <Box className="contents-padding activate-arrow">
                  <Grid container>
                    <Grid item xs={11}>
                      <Box>
                        <label className="setting-config-tab-titles">
                          Leave Calendar
                        </label>
                        <p className="setting-card-content">
                          Add or remove Employees from your departments
                        </p>
                      </Box>
                    </Grid>
                    <Grid item xs={1} className="padding-zero changeArrow">
                      <Link to="/setting/rolesResponsiblity">
                        <img
                          alt="next"
                          src={common.loadImg(
                            "iconly-light-arrow-next-circle.svg"
                          )}
                        />
                        <img
                          alt="next"
                          src={common.loadImg("next-activeArrow.svg")}
                          className="arrowActive"
                        />
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </Link>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Paper variant="contained" square className="main-content-wrap">
              <Link to="/setting/rolesResponsiblity" className="link-text">
                <Box className="contents-padding activate-arrow">
                  <Grid container>
                    <Grid item xs={11}>
                      <Box>
                        <label className="setting-config-tab-titles">
                          Leave Settings
                        </label>
                        <p className="setting-card-content">
                          Add or remove Employees from your departments
                        </p>
                      </Box>
                    </Grid>
                    <Grid item xs={1} className="padding-zero changeArrow">
                      <Link to="/setting/rolesResponsiblity">
                        <img
                          alt="next"
                          src={common.loadImg(
                            "iconly-light-arrow-next-circle.svg"
                          )}
                        />
                        <img
                          alt="next"
                          src={common.loadImg("next-activeArrow.svg")}
                          className="arrowActive"
                        />
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </Link>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Paper variant="contained" square className="main-content-wrap">
              <Link to="/setting/rolesResponsiblity" className="link-text">
                <Box className="contents-padding activate-arrow">
                  <Grid container>
                    <Grid item xs={11}>
                      <Box>
                        <label className="setting-config-tab-titles">
                          Roles and Responsibilities
                        </label>
                        <p className="setting-card-content">
                          Add or remove employees related to your business or
                          company
                        </p>
                      </Box>
                    </Grid>
                    <Grid item xs={1} className="padding-zero changeArrow">
                      <Link to="/setting/rolesResponsiblity">
                        <img
                          alt="next"
                          src={common.loadImg(
                            "iconly-light-arrow-next-circle.svg"
                          )}
                        />
                        <img
                          alt="next"
                          src={common.loadImg("next-activeArrow.svg")}
                          className="arrowActive"
                        />
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </Link>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default HrmsTab;
