import * as React from "react";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import SubMenu from "./subMenu";
import { useNavigate } from "react-router-dom";
import StatusBar from "elements/StatusBar";
import Loader from "elements/Loader";
import Autocomplete from "@mui/material/Autocomplete";
import SearchIcon from "@mui/icons-material/Search";
import { autocompleteClasses } from "@mui/material/Autocomplete";

//include
import adminPermission from "assets/files/admin.json";
import jsonPermission from "assets/files/permission.json";

import {
  Badge,
  Divider,
  Grid,
  Hidden,
  Popover,
  Popper,
  TextField,
  Typography,
  Paper,
  // Select,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

import QuickForm from "../elements/QuickForm";

import Footer from "./footer";
import { Link, NavLink } from "react-router-dom";
// api
import { common, tools, envConfig, api } from "helpers";
import * as Services from "helpers/service";
import { CHOLA_NOTIFICATIONS } from "helpers/service";
import HeaderRight from "./headerRight";
import { WindowSharp } from "@mui/icons-material";

const drawerWidth = 210;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  padding: theme.spacing(2, 3),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBarLg = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
  }),
  ...(!open && {
    marginLeft: 96,
    width: `calc(100% - ${96}px)`,
  }),
}));
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  padding: "10px",
  ...(open && {
    marginLeft: 0,
    width: `calc(100% - ${0}px)`,
  }),
  ...(!open && {
    marginLeft: 0,
    width: `calc(100% - ${0}px)`,
  }),
}));

const DrawerLg = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: open && drawerWidth,
  marginLeft: open ? 0 : 96,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MainLayout(props) {
  // const pathname = window.location.pathname;
  const navigate = useNavigate();
  // const [currentUrl, setCurrentUrl] = React.useState(pathname);

  const [open, setOpen] = React.useState(false);
  const [openSearch, setOpenSearch] = React.useState(false);
  const [currentTab, setCurrentTab] = React.useState(1);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEll, setAnchorEll] = React.useState(null);
  const [isMobDrawer, SetIsMobDrawer] = React.useState(false);
  const [userDetails, setUserDetails] = React.useState();
  const [permission, setPermission] = React.useState(null);

  const [filterItems, setFilterItems] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState([]);

  const [openQuickCreate, setOpenQuickCreate] = React.useState(false);

  const [notificationList, setNotificationList] = React.useState([]);
  const onClickOpenQuickCreate = () => {
    setOpenQuickCreate(true);
  };

  const closeQuickCreate = () => {
    setOpenQuickCreate(false);
  };

  const module = {
    CAMPAIGNS: "campaign",
    LEADS: "lead",
    DEALS: "deal",
    CONTACTS: "contact",
    LISTS: "enquiry",
  };

  const isEduTech = common.isEduTech();

  React.useEffect(() => {
    setUserDetails(common.authInfo());
    getAllPermissions();
    getTransTypeIds();
    getEmpDepartments();
    getUserCustomFilter();
    getAllNotifications();
    getTeamMembersByEmpId();
  }, []);

  React.useEffect(() => {
    const appHeader1 = document.querySelector(".kanban-appbar-header");
    const drawerHeader1 = document.querySelector(".custom-header");
    const primaryHeader1 = document.querySelector(".kanban-primary-header");
    const crmHeader1 = document.querySelector(".crm-header-wrap");
    const crmFooter1 = document.querySelector(".footer");
    if (appHeader1) {
      appHeader1.classList?.remove("hide-onscroll");
    }
    if (drawerHeader1) {
      drawerHeader1.classList?.remove("hide-onscroll");
    }
    if (primaryHeader1) {
      primaryHeader1.classList?.remove("hide-onscroll");
    }
    if (crmHeader1) {
      crmHeader1.classList?.remove("hide-onscroll");
    }
    if (crmFooter1) {
      crmFooter1.classList?.remove("hide-footer");
    }
  });

  React.useEffect(() => {
    const timeOutId = setTimeout(() => {
      commonSearch();
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [searchValue]);

  // Get unique Transaction IDs by code for tenant
  const getTransTypeIds = () => {
    let filter =
      `?offset=0&q=TransactionTypeCode='${envConfig.LIST_TRANS_TYPE_ID}' OR ` +
      `TransactionTypeCode='${envConfig.LEAD_TRANS_TYPE_ID}' OR ` +
      `TransactionTypeCode='${envConfig.CAMPAIGN_TRANS_TYPE_ID}' OR ` +
      `TransactionTypeCode='${envConfig.DEAL_TRANS_TYPE_ID}' OR ` +
      `TransactionTypeCode='${envConfig.QUOTE_TRANS_TYPE_ID}' OR ` +
      `TransactionTypeCode='${envConfig.SALESORDER_TRANS_TYPE_ID}' OR ` +
      `TransactionTypeCode='${envConfig.INVOICE_TRANS_TYPE_ID}'&limit=10`;
    let url = encodeURI(
      envConfig.BASE_API + Services.CHOLA_TRANS_TYPE + filter
    );
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
      moreHead: { rfv: 2 },
      cType: 4,
    };
    api.call(
      data,
      (response) => {
        if (response && response.data.items && response.data.items.length > 0) {
          let tData = [];
          response.data.items.map((item) => {
            tData.push({
              code: item.TransactionTypeCode,
              id: item.TransactionTypeId,
            });
          });
          let encTData = tools.crypt(tData);
          localStorage.setItem("tData", encTData);
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  const getUserCustomFilter = () => {
    let userInfo = common.authInfo();
    let url = encodeURI(
      envConfig.BASE_API +
        Services.CHOLA_CUSTOM_FILTER +
        `?q=UserId=${userInfo.UserId}`
    );
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
      moreHead: { rfv: 2 },
      cType: 4,
    };
    api.call(
      data,
      (response) => {
        if (response.status == 200) {
          let fData = null;
          if (response.data.items.length > 0) {
            fData = response.data.items[0];
          }
          let userData = tools.deCrypt(localStorage.getItem("userData"));

          userData["UserCustomFilter"] = fData;
          localStorage.setItem("userData", tools.crypt(userData));
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  // Get user territories by user ID
  const getEmpDepartments = () => {
    let userInfo = common.authInfo();
    let territories = [];
    let terList = [];

    let url = encodeURI(
      envConfig.BASE_API +
        Services.CRM_TERRITORY_TEAM +
        `?q=ResourceId=${userInfo.EmpId}&limit=1000&offset=0`
    );
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
      moreHead: { rfv: 2 },
      cType: 4,
    };
    api.call(
      data,
      (response) => {
        if (response && response.data.items && response.data.items.length > 0) {
          response.data.items.map((item) => {
            territories.push(item.TerritoryId);
            terList.push({
              label: item.TerritoryName,
              value: item.TerritoryId,
            });
          });
          let userData = tools.deCrypt(localStorage.getItem("userData"));
          console.log("territories", territories);
          localStorage.setItem("ter", territories);
          userData["territory"] = territories;
          userData["terList"] = terList;
          localStorage.setItem("userData", tools.crypt(userData));
          let leadFilter = {
            direct: [
              {
                logic: "AND",
                column: "OpportunityConverted",
                operator: "=",
                value: { label: "N", value: "N" },
              },
            ],
            list: "UPPER(OpportunityConverted)='N' AND ",
          };
          common.setStorageFilter("lead", leadFilter);
        }
      },
      (error) => {},
      (final) => {}
    );
  };
  //Search api

  /**
   * Get All Permission
   */
  const getAllPermissions = () => {
    let userInfo = common.authInfo();
    let url = `${envConfig.AUTH_REST_URL}${Services.USER_ROLE_GROUPS}?q=UserId=${userInfo.UserId} AND GroupDesc NOT IN ('${Services.CRM_DEFAULT_GROUPS_1}','${Services.CRM_DEFAULT_GROUPS_3}','${Services.CRM_DEFAULT_GROUPS_4}') AND TenantId=${userInfo.TenantId} AND UPPER(GroupDesc) LIKE '**'&finder=OnlyParentGroups&limit=1000&offset=0&orderBy=CreatedOn:desc&totalResults=true`;
    api.call(
      {
        url: url,
        type: "dynamic",
        method: "GET",
        auth: "basic",
        cType: 4,
        moreHead: { rfv: 2 },
      },
      (response) => {
        if (response.status === 200) {
          if (response.data.items.length) {
            let AdminUser = response.data.items.find(
              (o) => o.GroupDesc === Services.CRM_DEFAULT_GROUPS_2
            );

            //if its admin user
            if (AdminUser) {
              userInfo["roleId"] = 0;
              userInfo["role"] = Services.CRM_DEFAULT_GROUPS_2;
              common.localSet("userData", common.crypt(userInfo, true));
              setPermission(adminPermission);
            } else {
              userInfo["roleId"] = response.data.items[0].GroupId; //TODO - will update for field level permissions
              userInfo["role"] = "user";
              common.localSet("userData", common.crypt(userInfo, true));
              let userPer = JSON.parse(response.data.items[0].UserAccess);
              if (userPer?.contacts && !userPer?.contact) {
                userPer.contact = userPer?.contacts;
              }
              setPermission(
                response.data.items[0].UserAccess ? userPer : jsonPermission
              );
              console.log("userPer", userPer);
            }
          } else {
            //no data present
            localStorage.clear();
            navigate("/signin");
          }
        } else {
          //Error
          localStorage.clear();
          navigate("/signin");
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  const commonSearch = () => {
    setIsLoading(true);
    let url = encodeURI(
      envConfig.BASE_API +
        Services.CHOLA_COMMON_SEARCH +
        `?finder=Search;pSearch=${searchValue}&limit=100&offset=0&totalResults=true`
    );
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
      moreHead: { rfv: 2 },
      cType: 4,
    };

    api.call(
      data,
      (response) => {
        // if (response && response.data.items && response.data.items.length > 0) {
        let item = response.data.items;
        setFilterItems(item);
        setIsLoading(false);
        // }
      },
      (error) => {},
      (final) => {}
    );
  };
  const handleMobDrawer = () => {
    SetIsMobDrawer(!isMobDrawer);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = (event) => {
    event.preventDefault();
    // setAnchorEll(event.currentTarget);
  };

  const handleProfileClose = (e) => {
    e.preventDefault();
    setAnchorEll(null);
  };

  const openNotification = Boolean(anchorEl);
  const id = openNotification ? "simple-popover" : undefined;

  const openProfile = Boolean(anchorEll);
  const idProfile = openProfile ? "simple-popover" : undefined;

  const handleTab = (activeTab) => {
    if (currentTab !== activeTab) {
      setCurrentTab(activeTab);
    }
  };

  const handleDrawer = () => {
    setOpen(!open);
    const element = document.querySelector("#rarrow");
    const result = element.classList.contains("rotate-arrow");

    if (result == false) {
      const fixedHeader = document.getElementById("fixed-header");
      if (fixedHeader) {
        fixedHeader.classList.add("menu-shrink");
        fixedHeader.classList.remove("menu-expand");
      }

      const crmListView = document.getElementById("list-view-wrap");
      if (crmListView) {
        crmListView.classList.add("sm-listview-wrap");
        crmListView.classList.remove("lg-listview-wrap");
      }
      const taskListView = document.getElementById("task-list-view-wrap");
      if (taskListView) {
        taskListView.classList.add("sm-tasklistview-wrap");
        taskListView.classList.remove("lg-tasklistview-wrap");
      }

      const scrollMenuWrap = document.getElementById("scroll-menu-wrap");
      if (scrollMenuWrap) {
        scrollMenuWrap.classList.add("sm-scrollmenu-wrap");
        scrollMenuWrap.classList.remove("lg-scrollmenu-wrap");
      }

      const kanbanWrap = document.getElementById("kanban-column-wrap");
      if (kanbanWrap) {
        kanbanWrap.classList.add("sm-kanban-wrap");
        kanbanWrap.classList.remove("lg-kanban-wrap");
      }
      const automationWrap = document.getElementById("automation-list-wrap");
      if (automationWrap) {
        automationWrap.classList.add("sm-automation-wrap");
        automationWrap.classList.remove("lg-automation-wrap");
      }

      const spann = document.querySelectorAll(".Report-tab-header");
      for (const spnn of spann) {
        spnn.classList.remove("report-width-content");
      }
    } else {
      const fixedHeader = document.getElementById("fixed-header");
      if (fixedHeader) {
        fixedHeader.classList.add("menu-expand");
        fixedHeader.classList.remove("menu-shrink");
      }

      const crmListView = document.getElementById("list-view-wrap");
      if (crmListView) {
        crmListView.classList.remove("sm-listview-wrap");
        crmListView.classList.add("lg-listview-wrap");
      }
      const taskListView = document.getElementById("task-list-view-wrap");
      if (taskListView) {
        taskListView.classList.remove("sm-tasklistview-wrap");
        taskListView.classList.add("lg-tasklistview-wrap");
      }
      const scrollMenuWrap = document.getElementById("scroll-menu-wrap");
      if (scrollMenuWrap) {
        scrollMenuWrap.classList.remove("sm-scrollmenu-wrap");
        scrollMenuWrap.classList.add("lg-scrollmenu-wrap");
      }

      const kanbanWrap = document.getElementById("kanban-column-wrap");
      if (kanbanWrap) {
        kanbanWrap.classList.remove("sm-kanban-wrap");
        kanbanWrap.classList.add("lg-kanban-wrap");
      }
      const automationWrap = document.getElementById("automation-list-wrap");
      if (automationWrap) {
        automationWrap.classList.remove("sm-automation-wrap");
        automationWrap.classList.add("lg-automation-wrap");
      }
      const spant = document.querySelectorAll(".Report-tab-header");
      for (const spn of spant) {
        spn.classList.add("report-width-content");
      }
    }
  };

  // React.useEffect(
  //   () => {
  //     setCurrentUrl(pathname);
  //     // setCurrentTab(props.activeTab);
  //     if (currentUrl === "/crm/campaign/list") {
  //       setCurrentTab(4);
  //     }
  //   },
  //   [props.activeTab],
  //   [pathname, currentUrl]
  // );
  React.useEffect(() => {
    // setCurrentTab(props.activeTab);
    setCurrentTab(4);
  }, [props.activeTab]);

  const logout = (e) => {
    e.preventDefault();
    common.removeAuthStorage();
  };

  const options = filterItems.map((option, idx) => {
    const viewType = option.ViewType;
    const name = option.Name;
    const filterid = option.Id;

    return {
      viewType: "CRM:" + " " + viewType,
      itemId: filterid,

      ...option,
    };
  });

  const onHandleNavigate = (e, dispView, dispId) => {
    e.preventDefault();
    if (dispView == "CAMPAIGNS") {
      setOpenSearch(false);
      navigate(
        "/crm/" + module.CAMPAIGNS + "/details/" + dispId + "?search=true"
      );
    } else if (dispView == "LEADS") {
      setOpenSearch(false);
      navigate("/crm/" + module.LEADS + "/details/" + dispId + "?search=true");
    } else if (dispView == "DEALS") {
      setOpenSearch(false);
      navigate("/crm/" + module.DEALS + "/details/" + dispId + "?search=true");
    } else if (dispView == "CONTACTS") {
      setOpenSearch(false);
      navigate(
        "/crm/" + module.CONTACTS + "/details/" + dispId + "?search=true"
      );
    } else if (dispView == "LISTS") {
      setOpenSearch(false);
      navigate("/crm/" + module.LISTS + "/details/" + dispId + "?search=true");
    }
  };

  const handleInput = (event, nospace) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.

    setSearchValue(nospace);
  };

  const StyledPopper = styled(Popper)(({ theme }) => ({
    "& .MuiAutocomplete-groupLabel": {
      fontFamily: "montserrat",
      fontSize: "17",
      fontWeight: "800",
      fontStyle: "normal",
      color: "#2a3543",
      borderBottom: "1px solid rgba(224, 224, 224, 0.5)",
    },
  }));

  const getAllNotifications = () => {
    let url = `${envConfig.BASE_API}${CHOLA_NOTIFICATIONS}?totalResults=true&orderBy=CreatedOn:desc&limit=10&offset=0&q=Category IS NULL`;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };

    api.call(
      data,
      (response) => {
        if (response) {
          let res = [];
          response.data.items.filter((i) => {
            if (i.Read !== "Y") {
              res.push(i);
            }
          });
          setNotificationList(res);
        }
      },
      (error) => {
        // setStatus("error");
      },
      (final) => {}
    );
  };

  const clearNotifications = () => {
    let result = [];
    notificationList.map((item, key) => {
      result.push({
        id: tools.randomString() + "_" + item.Id,
        path: "/" + CHOLA_NOTIFICATIONS + "/" + item.Id,
        operation: "update",
        payload: {
          Read: "Y",
        },
      });
    });

    // api call
    let formAttr = { parts: result };
    let url = encodeURI(envConfig.BASE_API);
    let data = {
      url: url,
      type: "dynamic",
      method: "POST",
      auth: "token",
      body: JSON.stringify(formAttr),
      cType: 5,
    };

    api.call(
      data,
      (response) => {
        if (response) {
          setNotificationList([]);
        }
      },
      (error) => {
        common.snack("E", error);
      },
      (final) => {}
    );
  };

  const getTeamMembersByEmpId = () => {
    let userInfo = common.authInfo();

    let url =
      envConfig.BASE_API +
      Services.CRM_TERRITORY_EMPLOYEES +
      "?finder=FindEmployees;pResource=" +
      userInfo.EmpId +
      "&limit=100&offset=0";
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };

    api.call(
      data,
      (response) => {
        if (response) {
          if (response?.data?.items.length > 0) {
            let employeeList = {
              active: [],
              sub: [],
            };
            response?.data?.items.map((item) => {
              if (item.Bindtype == "PARENT") {
                employeeList.active.push(item.ResourceId);
              } else if (item.Bindtype == "CHILD") {
                employeeList.sub.push(item.ResourceId);
              }
            });
            employeeList.sub.push(userInfo.EmpId);
            console.log("my team List :>> ", employeeList);
            let userData = tools.deCrypt(localStorage.getItem("userData"));

            userData["employeeList"] = employeeList;
            localStorage.setItem("userData", tools.crypt(userData));
          }
        }
      },
      (error) => {
        // setStatus("error");
      },
      (final) => {}
    );
  };

  return (
    <Box>
      <Box sx={{ display: "flex" }} className="app-header app-container">
        <CssBaseline />
        {/* desktop appbar view */}
        <Hidden mdDown>
          <AppBarLg
            position="fixed"
            open={open}
            className="kanban-appbar-header"
          >
            <Grid container direction="row" justifyContent="space-between">
              <Grid item>
                <Grid container>
                  {open ? null : (
                    <Grid item>
                      <Box
                        px={4}
                        display="flex"
                        className="side-menu icon-padding"
                        alignItems="center"
                      >
                        <IconButton
                          color="inherit"
                          aria-label="open drawer"
                          onClick={handleDrawer}
                          edge="start"
                          sx={{
                            ...(open && { display: "none" }),
                          }}
                        >
                          <img
                            src={common.loadImg("header_category.svg")}
                            alt="logo"
                          />
                        </IconButton>
                      </Box>
                    </Grid>
                  )}
                  <Grid item>
                    <Box className="header-input header-search" display="flex">
                      <div className="search-autosuggestion">
                        <div className="search-wrapp">
                          <Autocomplete
                            clearOnBlur={false}
                            value={searchValue}
                            className="search-header disable-droparrow"
                            noOptionsText={"No Search Results"}
                            popupIcon={<SearchIcon />}
                            filterOptions={(options) => options}
                            open={openSearch}
                            isOptionEqualToValue={(option, value) =>
                              value === undefined ||
                              value === "" ||
                              option.id === value.id
                            }
                            onOpen={() => {
                              // only open when in focus and searchValue is not empty
                              if (
                                searchValue.length &&
                                searchValue.length >= 2
                              ) {
                                setOpenSearch(true);
                              } else {
                                setOpenSearch(false);
                              }
                            }}
                            onInputChange={(event, newValue) => {
                              let nospace = newValue.trim();

                              handleInput(event, nospace);
                            }}
                            onClose={() => setOpenSearch(false)}
                            id="controllable-states-demo"
                            options={options}
                            // options={options.sort(
                            //   (a, b) => -b.viewType.localeCompare(a.viewType)
                            // )}
                            groupBy={(option) =>
                              option.Name == null || option.Name == ""
                                ? ""
                                : option.viewType
                            }
                            getOptionLabel={(option) =>
                              typeof option === "string" ||
                              option instanceof String
                                ? option
                                : ""
                            }
                            PopperComponent={StyledPopper}
                            PaperComponent={({ children }) => (
                              <Paper style={{ width: 500 }}>
                                {isLoading ? (
                                  <StatusBar status="process" size={10} />
                                ) : (
                                  <>
                                    {children}
                                    {/* <div className="search-wrap">
                                      <div className="search-header ml-2">
                                        Search
                                      </div>
                                      <Divider />
                                      <div className="category-name mt-3">
                                        <ul>
                                          <li>Leads</li>
                                          <li>Deals</li>
                                          <li>Invoices</li>
                                          <li>Contacts</li>
                                          <li>Companies</li>
                                          <li>Quotes</li>
                                          <li>My activities</li>
                                          <li>Tasks</li>
                                          <li>Calendar</li>
                                          <li>Feed</li>
                                          <li>Sites</li>
                                        </ul>
                                      </div>
                                    </div> */}
                                  </>
                                )}
                              </Paper>
                            )}
                            sx={{
                              width: 270,

                              [`& .${autocompleteClasses.popupIndicator}`]: {
                                position: "absolute",
                                transform: "none",
                                right: 40,
                              },
                            }}
                            ListboxProps={{
                              style: {
                                maxHeight: "450px",
                              },
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Search on Osmo"
                                inputProps={{
                                  ...params.inputProps,

                                  onKeyDown: (e) => {
                                    let nospace = e.target.value;
                                    let newVal = nospace.trim();
                                    if (
                                      e.key === "Enter" &&
                                      newVal.length > 2
                                    ) {
                                      setOpenSearch(true);
                                    } else {
                                      setOpenSearch(false);
                                    }
                                  },
                                }}
                              />
                            )}
                            renderOption={(props, option, nIx) => {
                              const dispView = option.ViewType;
                              const dispId = option.Id;

                              return (
                                <Box
                                  key={option.Id}
                                  sx={{
                                    display: "inline-fex",
                                    width: "200",
                                  }}
                                  className="search-result mt-3"
                                >
                                  {option.Name === null ||
                                  option.Name === " " ? (
                                    ""
                                  ) : (
                                    <Link
                                      to="#"
                                      onClick={(e, option) =>
                                        onHandleNavigate(e, dispView, dispId)
                                      }
                                      className="text-none"
                                    >
                                      {" "}
                                      <ul>
                                        <li key={option.Id}>{option.Name}</li>
                                      </ul>
                                    </Link>
                                  )}
                                </Box>
                              );
                            }}
                          />
                        </div>
                        {/* <div className="search-icon">
                          <img
                            src={common.loadImg("header_search.svg")}
                            alt="search"
                            className="pe-3"
                          />
                        </div> */}
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item>
                    <Box px={3} py={2} className="settings-border pointer">
                      <img
                        src={common.loadImg("header_setting.svg")}
                        alt="settings"
                        onClick={(e) => {
                          handleTab(6);
                          navigate("/setting/view/hrms");
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box px={3} py={2} className="pointer notify-container">
                      <Badge
                        color="success"
                        variant={notificationList.length > 0 ? "dot" : ""}
                        className="badge-icon"
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        onClick={handleClick}
                      >
                        <img
                          src={common.loadImg("header_notification.svg")}
                          alt="settings"
                        />
                      </Badge>
                      <Popover
                        id={id}
                        open={openNotification}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        className="notify-pop"
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                        disableScrollLock
                      >
                        <Box className="notify-box" p={1}>
                          <Grid
                            container
                            justify="space-between"
                            alignItems="center"
                          >
                            <Grid item xs={10}>
                              <div className="notify-title pl-2">
                                Notifications
                              </div>
                            </Grid>
                            <Grid item>
                              <IconButton onClick={handleClose}>
                                <img
                                  src={common.loadImg("dateClosekanban.svg")}
                                  alt="close"
                                />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Box>
                        <Box>
                          <Divider />
                        </Box>
                        <Box pb={10}>
                          {notificationList.length > 0 ? (
                            <Grid
                              container
                              className="notification-list"
                              direction="column"
                            >
                              {notificationList?.map((item, idx) => {
                                if (item.Read === "N") {
                                  return (
                                    <Grid item key={idx}>
                                      <Box p={2}>
                                        <Grid
                                          container
                                          direction="column"
                                          spacing={1}
                                        >
                                          <Grid item>
                                            <Typography className="task-assign">
                                              {item.Subject}
                                            </Typography>
                                          </Grid>
                                          <Grid item>
                                            <Typography className="task-date">
                                              {common.formatDate(
                                                item.CreatedOn
                                              )}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Box>
                                      <Box>
                                        <Divider />
                                      </Box>
                                    </Grid>
                                  );
                                }
                              })}
                              <Box className="clear-box" p={2}>
                                <Typography
                                  className="task-clear pointer"
                                  onClick={() => clearNotifications()}
                                >
                                  Clear Notifications
                                </Typography>
                              </Box>
                            </Grid>
                          ) : (
                            <Grid container direction="column">
                              <Grid item>
                                <Box p={2}>
                                  <Grid item>
                                    <Typography className="task-assign text-center pt-5">
                                      No notification Available!
                                    </Typography>
                                  </Grid>
                                </Box>
                              </Grid>
                            </Grid>
                          )}
                        </Box>
                      </Popover>
                    </Box>
                  </Grid>
                  <HeaderRight userDetails={userDetails} logout={logout} />
                </Grid>
              </Grid>
            </Grid>
          </AppBarLg>
        </Hidden>

        {/* mobile appbar view */}
        <Hidden mdUp>
          <AppBar position="fixed" open={open} className="">
            <Box px={2} display="flex" justifyContent="space-between">
              <img
                src={
                  isEduTech
                    ? common.loadImg("pickauni-logo-dark.png")
                    : common.loadImg("logo.svg")
                }
                // src={common.loadImg("logo.svg")}
                alt="logo"
                className={`pointer ` + "main-logo"}
              />
              {isMobDrawer ? (
                <IconButton onClick={handleMobDrawer}>
                  <CloseIcon style={{ color: "#2a3543", fontSize: "40px" }} />
                </IconButton>
              ) : (
                <IconButton onClick={handleMobDrawer}>
                  <MenuIcon style={{ color: "#2a3543", fontSize: "40px" }} />
                </IconButton>
              )}
            </Box>
          </AppBar>
        </Hidden>

        {/* desktop view */}
        <Hidden smDown>
          <DrawerLg
            variant="permanent"
            className="side-menu relative"
            open={open}
          >
            <Box
              className={`extra-arrow pointer ${open ? "rotate-arrow" : ""}`}
              onClick={handleDrawer}
              id="rarrow"
            >
              <ArrowForwardIosIcon />
            </Box>
            <DrawerHeader>
              <Box display="flex" justifyContent="space-between" width="100%">
                <img
                  src={
                    isEduTech
                      ? common.loadImg("pickauni-logo-dark.png")
                      : common.loadImg("logo.svg")
                  }
                  // src={common.loadImg("logo.svg")}
                  alt="logo"
                  className={`pointer ` + "main-logo"}
                  onClick={handleDrawer}
                />
                {open ? (
                  <IconButton
                    aria-label="open drawer"
                    onClick={handleDrawer}
                    edge="start"
                  >
                    <img
                      src={common.loadImg("header_category.svg")}
                      alt="logo"
                    />
                  </IconButton>
                ) : null}
              </Box>
            </DrawerHeader>
            <Box mt={3}>
              <NavLink to="/dashboard/index">
                <Box
                  py={2}
                  px={3}
                  className={`menu pointer list ${
                    currentTab === 1 ? "activeTab" : "ml-0"
                  }`}
                  onClick={() => handleTab(1)}
                >
                  <Grid container spacing={1} alignItems="center">
                    <Grid item className="relative">
                      <img
                        src={common.loadImg("menu_home.svg")}
                        alt="HeaderProfile"
                      />
                      {currentTab === 1 ? <p className="active-oval" /> : null}
                    </Grid>
                    <Grid item>{open ? <span>Dashboard</span> : null}</Grid>
                  </Grid>
                </Box>
              </NavLink>
              {/* <NavLink to="/target/index">
                <Box
                  py={2}
                  px={3}
                  className={`menu pointer list ${
                    currentTab === 3 ? "activeTab ml-1" : ""
                  }`}
                  onClick={() => handleTab(3)}
                >
                  <Grid container spacing={1} alignItems="center">
                    <Grid item className="relative">
                      <img
                        src={common.loadImg("menu_target.svg")}
                        alt="TargetMenu"
                      />
                      {currentTab === 3 ? <p className="active-oval" /> : null}
                    </Grid>
                    <Grid item>{open ? <span>Target</span> : null}</Grid>
                  </Grid>
                </Box>
              </NavLink> */}
              <NavLink to="/crm/lead/list">
                <Box
                  py={2}
                  px={3}
                  className={`menu pointer list ${
                    currentTab === 4 ? "activeTab ml-1" : ""
                  }`}
                  onClick={() => handleTab(4)}
                >
                  <Grid container spacing={1} alignItems="center">
                    <Grid item className="relative">
                      <img src={common.loadImg("menu_crm.svg")} alt="CrmMenu" />
                      {currentTab === 4 ? <p className="active-oval" /> : null}
                    </Grid>
                    <Grid item>{open ? <span>CRM</span> : null}</Grid>
                  </Grid>
                </Box>
              </NavLink>
              <NavLink to="/task/overview">
                <Box
                  py={2}
                  px={3}
                  className={`menu pointer list ${
                    currentTab === 5 ? "activeTab ml-1" : ""
                  }`}
                  onClick={() => handleTab(5)}
                >
                  <Grid container spacing={1} alignItems="center">
                    <Grid item className="relative">
                      <img
                        src={common.loadImg("menu_task.svg")}
                        alt="TaskMenu"
                      />
                      {currentTab === 5 ? <p className="active-oval" /> : null}
                    </Grid>
                    <Grid item>{open ? <span>Tasks</span> : null}</Grid>
                  </Grid>
                </Box>
              </NavLink>
              <NavLink to="/setting/view/hrms">
                <Box
                  py={2}
                  px={3}
                  className={`menu pointer list ${
                    currentTab === 6 ? "activeTab ml-1" : ""
                  }`}
                  onClick={() => handleTab(6)}
                >
                  <Grid container spacing={1} alignItems="center">
                    <Grid item className="relative">
                      <img
                        src={common.loadImg("menu_setup.svg")}
                        alt="SetupMenu"
                      />
                      {currentTab === 6 ? <p className="active-oval" /> : null}
                    </Grid>
                    <Grid item>{open ? <span>Setup</span> : null}</Grid>
                  </Grid>
                </Box>
              </NavLink>
              {/* <Box
                py={2}
                px={3}
                className={`menu pointer list ${
                  currentTab === 7 ? "activeTab ml-1" : ""
                }`}
                onClick={() => handleTab(7)}
              >
                <Grid container spacing={1} alignItems="center">
                  <Grid item className="relative">
                    <img
                      src={common.loadImg("menu_tools.svg")}
                      alt="ToolsMenu"
                    />
                    {currentTab === 7 ? <p className="active-oval" /> : null}
                  </Grid>
                  <Grid item>{open ? <span>Tools</span> : null}</Grid>
                </Grid>
              </Box> */}
              {/* <Box
                py={2}
                px={3}
                className={`menu pointer list ${
                  currentTab === 8 ? "activeTab ml-1" : ""
                }`}
                onClick={() => handleTab(8)}
              >
                <Grid
                  container
                  spacing={1}
                  alignItems="center"
                  onClick={onClickOpenQuickCreate}
                >
                  <Grid item className="relative">
                    <img
                      src={common.loadImg("menu_quickcreate.svg")}
                      alt="QuickCreateMenu"
                    />
                    {currentTab === 8 ? <p className="active-oval" /> : null}
                  </Grid>
                  <Grid item>{open ? <span>Quick Create</span> : null}</Grid>
                </Grid>
              </Box>
              <NavLink to="/report/index">
                <Box
                  py={2}
                  px={3}
                  className={`menu pointer list ${
                    currentTab === 2 ? "activeTab" : ""
                  }`}
                  onClick={() => handleTab(2)}
                >
                  <Grid container spacing={1} alignItems="center">
                    <Grid item className="relative">
                      <img
                        src={common.loadImg("menu_reports.svg")}
                        alt="ReportsMenu"
                      />
                      {currentTab === 2 ? <p className="active-oval" /> : null}
                    </Grid>
                    <Grid item>{open ? <span>Reports</span> : null}</Grid>
                  </Grid>
                </Box>
              </NavLink> */}
              {/* <Box
                py={2}
                px={3}
                className={`menu pointer list ${
                  currentTab === 9 ? "activeTab ml-1" : ""
                }`}
                onClick={() => handleTab(9)}
              >
                <Grid container spacing={1} alignItems="center">
                  <Grid item className="relative">
                    <img src={common.loadImg("menu_mail.svg")} alt="MailMenu" />
                    {currentTab === 9 ? <p className="active-oval" /> : null}
                  </Grid>
                  <Grid item>{open ? <span>Mail</span> : null}</Grid>
                </Grid>
              </Box> */}
              {/* <Box
                py={2}
                px={3}
                // mt={6}
                className={`menu pointer list ${
                  currentTab === 10 ? "activeTab ml-1" : ""
                }`}
                onClick={() => handleTab(10)}
              >
                <Grid container spacing={1} alignItems="center">
                  <Grid item className="relative">
                    <img
                      src={common.loadImg("menu_moreinfo.svg")}
                      alt="MoreInfoMenu"
                    />
                    {currentTab === 10 ? <p className="active-oval" /> : null}
                  </Grid>
                  <Grid item>{open ? <span>More Info</span> : null}</Grid>
                </Grid>
              </Box> */}
            </Box>
          </DrawerLg>
        </Hidden>

        <Box component="main" sx={{ flexGrow: 1 }}>
          <DrawerHeader className="custom-header" />
          <Box
          // sx={{
          //   mb: { xs: 20, lg: 10 },
          // }}
          // mb={10}
          >
            {props.menu == "setting" && <SubMenu menu={props.menu} />}
            {permission ? (
              <Outlet {...props} context={[permission]} />
            ) : (
              <Loader />
            )}
            {/* <Outlet {...props} context={[permission]} /> */}
          </Box>
        </Box>
        <MuiDrawer
          className="mobile-drawer"
          elevation={0}
          anchor={"left"}
          open={isMobDrawer}
        >
          <Box my={12}>
            <Grid
              container
              direction="column"
              spacing={2}
              style={{ marginLeft: "15px" }}
            >
              <Grid item>
                <Link to="/dashboard/index" className="mobile-drawer-tab">
                  Dashboard
                </Link>
              </Grid>
              {/* <Grid item>
                <Link to="/target/index" className="mobile-drawer-tab">
                  Target
                </Link>
              </Grid> */}
              <Grid item>
                <Link to="/crm/kanban/view" className="mobile-drawer-tab">
                  Crm
                </Link>
              </Grid>
              <Grid item>
                <Link to="/task/overview" className="mobile-drawer-tab">
                  Tasks
                </Link>
              </Grid>
              <Grid item>
                <Link to="/setting/view/hrms" className="mobile-drawer-tab">
                  Setup
                </Link>
              </Grid>
              {/* <Grid item>
                <Link to="#" className="mobile-drawer-tab">
                  Tools
                </Link>
              </Grid> */}
              {/* <Grid item>
                <Link to="#" className="mobile-drawer-tab">
                  Quick Create
                </Link>
              </Grid>
              <Grid item>
                <Link to="/report/index" className="mobile-drawer-tab">
                  Reports
                </Link>
              </Grid> */}
              {/* <Grid item>
                <Link to="#" className="mobile-drawer-tab">
                  Mail
                </Link>
              </Grid>
              <Grid item>
                <Link to="#" className="mobile-drawer-tab">
                  More Info
                </Link>
              </Grid> */}
            </Grid>
          </Box>
        </MuiDrawer>
      </Box>
      <QuickForm
        openQuickCreate={openQuickCreate}
        closeQuickCreate={closeQuickCreate}
        permission={permission}
      />
      <Footer open={open} />
    </Box>
  );
}
