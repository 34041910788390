import React, { useEffect, useState, Fragment } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { Accordion, Card } from "react-bootstrap";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
// material
import { FormControl, Select, Divider } from "@mui/material";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Input from "@material-ui/core/Input";
import MuiButton from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import { Grid } from "@mui/material";
import AccessDenied from "elements/AccessDenied";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

// dialog
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
// api
import * as Services from "helpers/service";
import { common, tools, envConfig, api } from "helpers";
// pages
import FormProperties from "./includes/FormProperties";
// files
import formFields from "assets/files/formFields.json";
import serviceList from "assets/files/serviceList.json";
import lookupList from "assets/files/lookupList.json";
// css
import "./FormSettings.css";
// icon
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import StatusBar from "elements/StatusBar";

function FormSettings(props) {
  // const userPermissions = common.getPermission(
  //   props,
  //   "configure:form settings"
  // );
  // const
  const [permission] = useOutletContext();
  const navigate = useNavigate();
  const formDefault = { sKey: null, fKey: null, type: null };
  const userInfo = common.userInfo();
  // system
  const [mount, setMount] = useState(false);
  const [status, setStatus] = useState("process");
  const [isSubmit, setIsSubmit] = useState(false);
  const [serviceId, setServiceId] = useState(0);
  const [serviceCode, setServiceCode] = useState(
    localStorage.getItem("ServiceCode") || Object.keys(serviceList)[0]
  );
  const [data, setData] = useState([]);
  const [entityData, setEntityData] = useState([]);
  const [isDialog, setDialog] = useState(null);
  const [isMode, setMode] = useState(null);
  const [formData, setFormData] = useState(formDefault);
  const [unUsed, setUnUsed] = useState(null);
  const [deleteData, setDeleteData] = useState([]);
  const [isEduTech, setIsEduTech] = useState(
    common.isEduTech() & ["lead", "deal"].includes(serviceCode)
  );
  const [roleList, setRoleList] = useState([]);
  const [showPermission, setShowPermission] = useState(false);
  const [permissionField, setPermissionField] = useState(null);
  let userData = common.authInfo();
  const fieldAccess = [
    { label: "READ AND WRITE", value: "rw" },
    { label: "READ ONLY", value: "r" },
    { label: "DON'T SHOW", value: "h" },
  ];
  const restrictAccessFields = {
    campaign: ["CampaignName", "CampaignOwnerId", "CampaignType"],
    lead: ["LeadName", "SalespersonId", "CustAccountId"],
    deal: ["OpportunityName", "AccountId", "SalesPersonId", "ApprStatus"],
    enquiry: ["ListName"],
    enquiryMember: ["FirstName"],
    contacts: ["FirstName"],
    meeting: ["title", "EventType", "StartDateTime"],
    callLogs: ["CallType", "CallSummary", "ContactId"],
  };

  // effect
  useEffect(() => {
    setShowPermission(false);
    getEntityData();
    getRoles();
  }, [serviceCode]);

  // #Drag & drop#
  let isDrag = null;
  const [isDragMode, setDragMode] = useState(null);
  const [dragItem, setDragItem] = useState(null);
  const [dropItem, setDropItem] = useState(null);
  const [dragSection, setDragSection] = useState(null);
  const [dropSection, setDropSection] = useState(null);

  // Drag Item
  const onDragStartItem = (e, data) => {
    if (isDrag == null) {
      isDrag = "item";
      setDragMode("item");
      setDragItem(data);
    }
  };

  const handleClose = () => {
    setDialog(null);
  };
  const handleDragEndItem = () => {
    if (dropItem != null) {
      let sId = getSectionInfo(dropItem.sKey).id;
      let key = generateAttr(dragItem);
      if (key) {
        setData((oldData) => {
          let newData = reParse(oldData);
          let fields = getFieldList(dropItem.sKey, "et");
          let nonFields = getFieldList(dropItem.sKey, "net");
          fields.splice(dropItem.fKey, 0, generateField(sId, dragItem, key));
          newData.fields = [...new Set([...fields, ...nonFields])];
          return newData;
        });
        // set property
        setFormData({
          sKey: dropItem.sKey,
          fKey: dropItem.fKey,
          type: "property",
        });
        setMode("drag");
        setDialog("property");
      }
    }

    setTimeout(() => {
      isDrag = null;
      setDragItem(null);
      setDropItem(null);
      setDragMode(null);
    }, 10);
  };

  // Drag UnUsed
  const onDragStartUnUsed = (e, data) => {
    if (isDrag == null) {
      isDrag = "unUsed";
      setDragMode("unUsed");
      setDragItem(data);
    }
  };

  const handleDragEndUnUsed = () => {
    if (dropItem != null) {
      let sId = getSectionInfo(dropItem.sKey).id;
      let item = reParse(dragItem);
      item.layout = sId;
      item.status = "use";
      setData((oldData) => {
        let newData = reParse(oldData);
        let fields = getFieldList(dropItem.sKey, "et");
        let nonFields = getFieldList(dropItem.sKey, "net");
        nonFields = nonFields.filter((i) => i.key !== item.key);
        fields.splice(dropItem.fKey, 0, item);
        newData.fields = [...new Set([...fields, ...nonFields])];
        return newData;
      });
    }

    setTimeout(() => {
      isDrag = null;
      setDragItem(null);
      setDropItem(null);
      setDragMode(null);
    }, 10);
  };

  // Drag field
  const onDragStartField = (e, data) => {
    if (isDrag == null) {
      isDrag = "field";
      setDragMode("field");
      setDragItem(data);
    }
  };

  const onDragOverField = (item) => {
    if (dropItem === null) {
      setDropItem(item);
    }
  };

  const onDragLeaveField = () => {
    if (dropItem !== null) {
      setTimeout(() => {
        setDropItem(null);
      }, 1000);
    }
  };

  const handleDragEndField = () => {
    if (dropItem != null) {
      let fromSid = getSectionInfo(dragItem.sKey).id;
      let toSid = getSectionInfo(dropItem.sKey).id;

      setData((oldData) => {
        let newData = reParse(oldData);
        let fromFields = getFieldList(dragItem.sKey, "et");
        let toFields = getFieldList(dropItem.sKey, "et");
        let nonFields = getFieldList([fromSid, toSid], "notIn");

        if (dragItem.sKey === dropItem.sKey) {
          fromFields.splice(dragItem.fKey, 1);
          fromFields.splice(dropItem.fKey, 0, dragItem.field);
          newData.fields = [...new Set([...fromFields, ...nonFields])];
        } else {
          fromFields.splice(dragItem.fKey, 1);
          toFields.splice(dropItem.fKey, 0, {
            ...dragItem.field,
            layout: toSid,
          });
          newData.fields = [
            ...new Set([...fromFields, ...toFields, ...nonFields]),
          ];
        }
        return newData;
      });
    }
    setTimeout(() => {
      isDrag = null;
      setDragItem(null);
      setDropItem(null);
      setDragMode(null);
    }, 10);
  };

  // Drag Section
  const onDragStartSection = (e, data) => {
    if (isDrag == null) {
      isDrag = "section";
      setDragMode("section");
      setDragSection(data);
    }
  };

  const onDragOverSection = (item) => {
    setDropSection(item);
  };

  const onDragLeaveSection = () => {
    setTimeout(() => {
      setDropSection(null);
    }, 1000);
  };

  const handleDragEndSection = () => {
    if (dropSection != null) {
      if (dragSection == "new") {
        setData((oldData) => {
          let newData = reParse(oldData);
          newData.sections.splice(dropSection, 0, generateSection());
          return newData;
        });
      } else {
        setData((oldData) => {
          let newData = reParse(oldData);
          let section = newData.sections[dragSection];
          newData.sections.splice(dragSection, 1);
          newData.sections.splice(dropSection, 0, section);
          return newData;
        });
      }
    }
    setTimeout(() => {
      setDragMode(null);
    }, 10);
  };

  // style
  const fieldStyles = (item) => {
    if (
      isDragMode !== null &&
      isDragMode !== "section" &&
      dropItem !== null &&
      dropItem.sKey == item.sKey &&
      dropItem.fKey == item.fKey &&
      dropItem.type == item.type
    ) {
      return "dragStart itemDD";
    } else if (isDragMode !== null && isDragMode !== "section") {
      return "dragStart";
    } else {
      return "";
    }
  };

  const sectionStyles = (sKey) => {
    if (
      isDragMode == "section" &&
      dropSection !== null &&
      dropSection == sKey &&
      dragSection != sKey
    ) {
      return " dragStart sectionDD";
    } else if (isDragMode == "section") {
      return " dragStart";
    } else {
      return "";
    }
  };

  const getItemClass = (item) => {
    let result = "";
    if (item.required) {
      result += "fs-required ";
    }
    if (item.variable === "system") {
      result += "fs-system ";
    }
    return result;
  };

  // Section Activity
  const sectionSettings = (sKey) => {
    setFormData({ sKey, fKey: null, type: "section" });
    setDialog("section");
  };

  const sectionLayout = (layout) => {
    setData((data) => {
      let newData = reParse(data);
      newData.sections[formData.sKey].layout = layout;
      return newData;
    });
    closeDialog();
  };

  const sectionTitle = (sKey, value) => {
    setData((data) => {
      let newData = reParse(data);
      newData.sections[sKey].label = value;
      return newData;
    });
    closeDialog();
  };

  const sectionDelete = (type) => {
    let fields = getFieldList(formData.sKey, "et");
    let nonFields = getFieldList(formData.sKey, "net");
    if (type === "fully") {
      fields = [];
    } else if (type === "unUse") {
      fields.map((item) => {
        item.layout = "";
        item.status = "unUse";
      });
    } else if (type === "permanent") {
      fields = [];
      sectionPermantDelete();
    }
    setData((oldData) => {
      let newData = reParse(oldData);
      newData.sections.splice(formData.sKey, 1);
      newData.fields = [...new Set([...fields, ...nonFields])];
      return newData;
    });
    setDialog(null);
  };

  const sectionPermantDelete = () => {
    let sId = data.sections[formData.sKey].id;
    let fields = data.fields.filter(
      (item) =>
        item.layout === sId && item.variable !== "system" && !item.latest
    );
    setDeleteData((oldData) => {
      let newData = reParse(oldData);
      newData = [...new Set([...fields, ...newData])];
      return newData;
    });
  };

  // Field Activity
  const fieldAction = (sKey, fKey, type) => {
    if (type === "edit") {
      setFormData({ sKey, fKey, type: "property" });
      setMode("edit");
      setDialog("property");
    } else if (type === "delete") {
      setFormData({ sKey, fKey, type: "field" });
      setDialog("delete");
    }
  };

  const fieldDelete = (type) => {
    setData((oldData) => {
      let newData = reParse(oldData);
      let fields = getFieldList(formData.sKey, "et");
      let nonFields = getFieldList(formData.sKey, "net");
      let field = fields[formData.fKey];
      if (type === "permanent") {
        fields.splice(formData.fKey, 1);
        if (!field.latest) {
          setDeleteData((oldData) => {
            let newData = reParse(oldData);
            newData.push(field);
            return newData;
          });
        }
      } else if (type === "unUse") {
        field.layout = "";
        field.status = "unUse";
      }
      newData.fields = [...new Set([...fields, ...nonFields])];
      return newData;
    });
    setDialog(null);
  };

  // Unuse activity
  const unUseAction = (key, index) => {
    setUnUsed({ key, index });
    setDialog("unUseDelete");
  };

  const unUseDelete = () => {
    let fields = reParse(getUnusedList());
    let field = fields[unUsed.index];
    // removed from data
    setData((oldData) => {
      let newData = reParse(oldData);
      newData.fields = newData.fields.filter((item) => item.key !== unUsed.key);
      return newData;
    });
    // move to delete
    if (!field.latest) {
      setDeleteData((oldData) => {
        let newData = reParse(oldData);
        newData.push(field);
        return newData;
      });
    }
    setDialog(null);
  };

  // #Api#
  const getEntityData = () => {
    if (mount) {
      setStatus("process");
    }
    let filter = `?q=EntityTypeValue=${
      Services[serviceList[serviceCode].api]
    }&expand=CholaEntityDtlsView&limit=1000&offset=0`;
    let url = envConfig.BASE_API + Services.CHOLA_ENTITIES + filter;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };
    api.call(
      data,
      (response) => {
        if (response) {
          let result = response.data.items[0].CholaEntityDtlsView;
          let entityList = {};
          result.map((item) => {
            entityList[item.DtlKeyValue] = item.EntityDtlId;
          });
          setEntityData(entityList);
          getColumnsData();
        }
      },
      (error) => {
        //console.log(error);
      },
      (final) => {}
    );
  };

  const getRoles = () => {
    let filter = `?q=GroupDesc NOT IN ('${Services.CRM_DEFAULT_GROUPS_1}','${Services.CRM_DEFAULT_GROUPS_2}','${Services.CRM_DEFAULT_GROUPS_3}','${Services.CRM_DEFAULT_GROUPS_4}') AND TenantId=${userData.TenantId} &fields=GroupId,GroupDesc&limit=100&offset=0&finder=FindParentGroups&orderBy=CreatedOn:desc&totalResults=true`;

    let url = encodeURI(envConfig.AUTH_REST_URL + Services.ROLES + filter);

    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "basic",
      moreHead: { rfv: 2 },
      cType: 4,
    };

    api.call(
      data,
      (response) => {
        if (response) {
          let result = response?.data?.items;
          setRoleList(result);
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  const onCheckboxChange = (event, groupId) => {
    let pData = reParse(permissionField);
    pData.pspecific = true;
    if (pData.permission && pData.permission.length > 0) {
      let fIdx = pData.permission.findIndex((e) => {
        return e.groupId == groupId;
      });
      if (fIdx > -1) {
        pData.permission[fIdx].access = event.target.value;
      } else {
        pData.permission.push({ groupId: groupId, access: event.target.value });
      }
    } else {
      let permissionArr = [];
      permissionArr.push({ groupId: groupId, access: event.target.value });
      pData.permission = permissionArr;
    }
    setPermissionField(pData);
  };

  const enablePermission = (e, field) => {
    e.preventDefault();
    let pData = reParse(field);
    console.log("pData", pData);
    if (pData.ptype == undefined && pData.permission == undefined) {
      pData.ptype = "rw";
      pData.pspecific = false;
      pData.permission = [];
    }
    setPermissionField(pData);
    setShowPermission(true);
  };

  const oneAccessOnly = async (event, access) => {
    event.preventDefault();
    let pData = reParse(permissionField);
    pData.ptype = access;
    pData.pspecific = false;
    pData.permission = [];
    setPermissionField(pData);
  };

  const updatePermission = (e) => {
    e.preventDefault();
    setData((data) => {
      let newData = reParse(data);
      let idx = newData.fields.findIndex((f) => {
        return f.key === permissionField.key;
      });
      if (idx > -1) {
        newData.fields[idx].ptype = permissionField.ptype;
        newData.fields[idx].pspecific = permissionField.pspecific;
        newData.fields[idx].permission = permissionField.permission;
      }
      return newData;
    });
    setShowPermission(false);
    setPermissionField(null);
  };

  const closePermission = (e) => {
    e.preventDefault();
    setShowPermission(false);
    setPermissionField(null);
  };

  const checkAccess = (currentAccess, groupId) => {
    let ptype = permissionField.ptype;
    let specific = permissionField.pspecific;
    if (ptype) {
      if (specific == true && permissionField?.permission.length > 0) {
        let fAccess = permissionField.permission.filter(
          (e) => e.groupId == groupId
        );
        return fAccess.length > 0
          ? fAccess[0].access === currentAccess
          : ptype === currentAccess;
      } else {
        return ptype === currentAccess;
      }
    } else {
      return currentAccess === "rw";
    }
  };

  const getColumnsData = () => {
    let filter = `?q=EserviceCode=${serviceList[serviceCode].code}`;
    let url = envConfig.BASE_API + Services.CRM_ESERVICE_HDR + filter;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };
    api.call(
      data,
      (response) => {
        if (response) {
          let fields = JSON.parse(response.data.items[0].FormFields);
          setData(fields.form);
          setServiceId(response.data.items[0].EserviceId);
          setStatus("completed");
          setMount(true);
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  // #Handler#
  const onModuleSelect = (e) => {
    let value = e.target.value;
    localStorage.setItem("ServiceCode", value);
    let isEduTech = common.isEduTech() & ["lead", "deal"].includes(value);
    setIsEduTech(isEduTech);
    setServiceCode(value);
  };

  // #Submit Handler#
  const onSubmit = (e) => {
    e.preventDefault();

    // empty attributes
    if (deleteData.length > 0) {
      let attrList = [];
      deleteData.map((item) => {
        attrList.push(item.key);
      });
      let deleteParam = {
        name: "nullifyAttribute",
        parameters: [
          {
            AttributeName: attrList.join(","),
          },
          {
            ObjectName: Services[serviceList[serviceCode].api],
          },
          {
            TenantId: userInfo.pTenantId,
          },
          {
            OrgId: userInfo.pOrgId,
          },
        ],
      };
      let url = envConfig.BASE_API + Services.CUSTOM_ATTR;
      let data = {
        url: url,
        type: "dynamic",
        method: "POST",
        auth: "token",
        body: JSON.stringify(deleteParam),
        cType: 4,
      };
      api.call(
        data,
        (response) => {},
        (error) => {},
        (final) => {}
      );
    }
    setIsSubmit(true);
    let filter = `?q=EserviceCode=${serviceList[serviceCode].code}`;
    let url = envConfig.BASE_API + Services.CRM_ESERVICE_HDR + filter;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };
    api.call(
      data,
      (response) => {
        if (response) {
          let fields = JSON.parse(response.data.items[0].FormFields);
          if (fields === null) {
            fields = {};
            fields.form = getData();
          } else {
            fields.form = getData();
          }
          let apiData = {
            FormFields: JSON.stringify(fields),
          };
          let url =
            envConfig.BASE_API + Services.CRM_ESERVICE_HDR + "/" + serviceId;
          let data = {
            url: url,
            type: "dynamic",
            method: "PATCH",
            auth: "token",
            body: JSON.stringify(apiData),
            cType: 4,
          };
          api.call(
            data,
            (response) => {
              let enData = generateEntity();
              if (enData.length > 0) {
                // update entity
                var formAttr = { parts: enData };
                url = encodeURI(envConfig.BASE_API);
                let data = {
                  url: url,
                  type: "dynamic",
                  method: "POST",
                  auth: "token",
                  body: JSON.stringify(formAttr),
                  cType: 5,
                };
                api.call(
                  data,
                  (response) => {
                    if (response != null) {
                      setIsSubmit(false);
                      common.snack("S", "Form updated successfully");
                      window.scrollTo(0, 0);
                    }
                  },
                  (error) => {
                    common.snack("E", error);
                  },
                  (final) => {}
                );
              } else {
                setIsSubmit(false);
                common.snack("S", "Form updated successfully");
                window.scrollTo(0, 0);
              }
            },
            (error) => {},
            (final) => {}
          );
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  const onCancel = () => {
    navigate("/setting/view/configure");
  };

  // #Property Props#
  const propertyData = () => {
    const { sKey, fKey } = formData;
    return {
      sKey,
      fKey,
      section: getSectionInfo(sKey),
      field: getFieldInfo(sKey, fKey),
      item: getItemInfo(sKey, fKey),
      label: getLabelInfo(sKey, fKey),
      code: serviceCode,
    };
  };

  const propertyComplete = (data) => {
    setData((oldData) => {
      let newData = reParse(oldData);
      let fields = getFieldList(formData.sKey, "et");
      let nonFields = getFieldList(formData.sKey, "net");
      fields[formData.fKey] = data.field;
      newData.fields = [...new Set([...fields, ...nonFields])];
      return newData;
    });
    setFormData(formDefault);
    setDialog(null);
  };

  // #Support#
  const generateSection = () => {
    return {
      id: tools.randomString(),
      label: `Section ${data.sections.length + 1}`,
      layout: "double",
      fixed: false,
    };
  };

  const generateField = (sId, type, key) => {
    let item = formFields[type];
    let fields = data.fields.filter(
      (item) => item.type === type && item.variable !== "system"
    );
    return {
      index: "",
      key: key,
      layout: sId,
      label: `${item.label} ${fields.length + 1}`,
      type: type,
      variable: "custom",
      required: false,
      quick: false,
      automation: true,
      fieldUpdate: true,
      latest: true,
      status: "new",
      fixed: false,
      visibility: true,
      store: true,
      edit: true,
      delete: true,
      disabled: false,
      props: "",
      value: "",
      error: "",
      touched: false,
      render: false,
      attr: {
        option: ["Option 1"],
        optionDefault: "",
        maxChar: item.maxChar,
        defaultChecked: false,
        lookup: "",
        lookupEntity: "",
        entity: "",
        default: "",
        dependent: null,
        fill: null,
        data1: "",
      },
    };
  };

  const generateAttr = (type) => {
    let field = formFields[type];
    let attrList = { Cattr: 41, Nattr: 21, Dattr: 12 };
    let attrNo = [];
    data.fields.map((item) => {
      let attr = item.key.replace(/[0-9]/g, "");
      if (attr === field.attr) {
        attrNo.push(Number(item.key.replace(/[^0-9]/g, "")));
      }
    });
    if (attrNo.length === 0 || !attrNo.includes(1)) {
      return field.attr + 1;
    } else if (attrNo.length !== attrList[field.attr]) {
      attrNo.push(attrList[field.attr]);
    }
    let sNo =
      attrNo.sort((a, b) => a - b).find((x, i) => attrNo[i + 1] - x > 1) + 1;
    if (isNaN(sNo)) {
      common.snack("I", `Allowed only ${attrList[field.attr] - 1} fields`);
      return "";
    } else {
      return field.attr + sNo;
    }
  };

  const generateEntity = () => {
    let result = [];
    deleteData.map((item) => {
      result.push({
        id: tools.randomString() + "_" + item.key,
        path: "/" + Services.CHOLA_ENTITY_DETAILS + "/" + entityData[item.key],
        operation: "update",
        payload: {
          DtlKeyLabel: null,
          DtlMappedApi: null,
          DtlMappedKey: null,
          DtlMappedValue: null,
        },
      });
    });

    data.fields.map((item) => {
      let entityItem = entityData[item.attr.entity] || entityData[item.key];
      if (item.store && entityItem) {
        let payLoad = {
          DtlKeyLabel: item.label,
          DtlMappedApi: null,
          DtlMappedKey: null,
          DtlMappedValue: null,
        };
        if (item.attr.lookup) {
          let lookup = lookupList[item.attr.lookupEntity || item.attr.lookup];
          payLoad.DtlMappedApi = Services[lookup.name];
          payLoad.DtlMappedKey = lookup.set;
          payLoad.DtlMappedValue = lookup.get;
        }
        result.push({
          id: tools.randomString() + "_" + item.key,
          path: "/" + Services.CHOLA_ENTITY_DETAILS + "/" + entityItem,
          operation: "update",
          payload: payLoad,
        });
      }
    });
    return result;
  };

  const getData = () => {
    let inc = 0;
    let newData = reParse(data);
    let newFields = [];
    // section fields
    newData.sections.map((sItem, sKey) => {
      let fields = getFieldList(sKey, "et");
      fields.map((fItem) => {
        fItem.index = inc;
        fItem.latest = false;
        if (fItem.status === "new") {
          fItem.status = "use";
        }
        newFields.push(fItem);
        inc++;
      });
    });
    // non section fields
    newData.fields
      .filter((i) => !i.layout)
      .map((fItem) => {
        fItem.index = inc;
        newFields.push(fItem);
        inc++;
      });
    newData.fields = newFields;
    return newData;
  };

  const getSectionInfo = (key) => {
    return data.sections[key];
  };

  const getFieldInfo = (sKey, fKey) => {
    let sId = data.sections[sKey].id;
    let fields = data.fields.filter((item) => item.layout === sId);
    return fields[fKey];
  };

  const getFieldList = (sKey, type) => {
    let nData = reParse(data);
    if (type == "notIn") {
      return nData.fields.filter((item) => !sKey.includes(item.layout));
    } else {
      let sId = data.sections[sKey].id;
      if (type == "et") {
        return nData.fields.filter((item) => item.layout === sId);
      } else if (type == "net") {
        return nData.fields.filter((item) => item.layout !== sId);
      }
    }
  };

  const getFormFieldInfo = () => {
    let sId = data.sections[formData.sKey].id;
    let fields = data.fields.filter((item) => item.layout === sId);
    return fields[formData.fKey];
  };

  const getFieldTypeList = (type, value) => {
    let sId = data.sections[formData.sKey].id;
    let fields = data.fields.filter(
      (item) => item.layout === sId && item[type] === value
    );
    return fields;
  };

  const getItemInfo = (sKey, fKey) => {
    let fields = getFieldInfo(sKey, fKey);
    return formFields[fields.type];
  };

  const getLabelInfo = (sKey, fKey) => {
    let field = getFieldInfo(sKey, fKey);
    return data.fields
      .filter((i) => i.key !== field.key)
      .map((i) => i.label.toUpperCase());
  };

  const getUnusedCount = () => {
    return data.fields.filter((item) => item.status == "unUse").length;
  };

  const getUnusedList = () => {
    return data.fields.filter((item) => item.status == "unUse");
  };

  const getLeftCount = () => {
    return (
      80 -
      Number(data.fields.filter((item) => item.variable === "custom").length)
    );
  };

  // support
  const closeDialog = () => {
    setFormData(formDefault);
    setDialog(null);
  };

  const reParse = (data) => {
    return JSON.parse(JSON.stringify(data));
  };
  // render
  // return !userPermissions.access ? (
  //   <AccessDenied />
  // ) : (
  return (
    <React.Fragment>
      {common.givenPermission(
        permission,
        "configure:form settings",
        "update"
      ) ? (
        <AccessDenied />
      ) : (
        <div className="template-box-border p-4">
          {status == "process" ? (
            // <Loader />
            <div className="loader-wrap">
              <StatusBar status={status} />
            </div>
          ) : (
            <div className="fs-wrap form-settings-content-wrap">
              <div className="d-flex">
                <Col sm={4}>
                  {/* <div className="fixed-sidebar">
                
              </div> */}
                  <div className="fs-sidebar-title">
                    <div className="d-flex align-items-center">
                      <Link to="/setting/view/configure">
                        <img
                          alt={"back"}
                          className="me-2"
                          src={common.loadImg(
                            "iconly-light-arrow-down-circle.svg"
                          )}
                        />
                      </Link>
                      <h2 className="page-title mb-0">Form Settings</h2>
                    </div>
                  </div>
                  <div className="fs-sidebar-wrap">
                    <div className="sidebar-heading">
                      <label className="sidebar-label">Form Assets</label>
                      <p className="description">
                        click and Drag a form asset to add
                      </p>
                    </div>

                    <Accordion defaultActiveKey="0" className="fs-accordion">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header as={Card.Header}>
                          New Fields
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="row">
                            {Object.keys(formFields).map((item, key) => {
                              let itemData = formFields[item];
                              if (itemData.show) {
                                return (
                                  <div
                                    key={key}
                                    draggable
                                    onDragStart={(e) =>
                                      onDragStartItem(e, item)
                                    }
                                    onDragEnd={() => handleDragEndItem()}
                                    className={`${
                                      itemData.size == "small"
                                        ? "col-md-6"
                                        : "col-md-12"
                                    } mb-3`}
                                  >
                                    <div
                                      className={`field-box ${itemData.icon}`}
                                    >
                                      <span>{`${itemData.label}`}</span>
                                    </div>
                                  </div>
                                );
                              }
                            })}
                            <div className="col-md-12">
                              <div
                                className="field-box field-new-section"
                                draggable
                                onDragStart={(e) =>
                                  onDragStartSection(e, "new")
                                }
                                onDragEnd={() => handleDragEndSection()}
                              >
                                <span>New Section</span>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1" className="border-rad-zero">
                        <Accordion.Header as={Card.Header}>
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="mb-0">Unused Items</p>
                            <span className="unused-count">
                              {getUnusedCount()}
                            </span>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          {getUnusedCount() > 0 ? (
                            <div className="row">
                              {getUnusedList().map((item, key) => (
                                <div
                                  key={key}
                                  draggable
                                  onDragStart={(e) =>
                                    onDragStartUnUsed(e, item)
                                  }
                                  onDragEnd={() => handleDragEndUnUsed()}
                                  className="col-md-12 mb-3"
                                >
                                  <div className="fs-un-used field-box field-new-section">
                                    <span>{item.label}</span>
                                    {item.variable === "system" ? (
                                      <DeleteIcon
                                        fontSize="small"
                                        color="disabled"
                                        className="fs-disable-icon"
                                      />
                                    ) : (
                                      !isEduTech && (
                                        <DeleteIcon
                                          fontSize="small"
                                          color="action"
                                          className="fs-cp fs-active-icon"
                                          onClick={(e) =>
                                            unUseAction(item.key, key, "delete")
                                          }
                                        />
                                      )
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="unused-items-wrap">
                              <p>All fields are in use.</p>
                            </div>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <div className="field-left-count">
                      Custom Fields Left: {getLeftCount()}
                    </div>
                  </div>
                </Col>
                <Col sm={8}>
                  <div className="fs-settings-box">
                    <div className="fs-filter-wrap">
                      <div className="form-row fs-select-wrap">
                        <div className="d-flex align-items-center">
                          <span className="me-3">Select a Form</span>
                          <div className="text-box custom-select">
                            <FormControl sx={{ minWidth: 160 }}>
                              <Select
                                inputProps={{
                                  MenuProps: { disableScrollLock: true },
                                }}
                                label="Form"
                                className="filter-label px-2"
                                value={serviceCode}
                                onChange={(e) => onModuleSelect(e)}
                              >
                                {Object.keys(serviceList)
                                  .filter((i) => serviceList[i].dynamic)
                                  .map((item, key) => (
                                    <MenuItem value={item} key={key}>
                                      {serviceList[item].label}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      </div>

                      <div className="fs-btns-wrap">
                        <div className="fs-savebtn">
                          {!isEduTech && (
                            <Button
                              className="saveBtn save-note"
                              type="submit"
                              onClick={(e) => onSubmit(e)}
                              disabled={isSubmit}
                            >
                              {isSubmit ? "Processing..." : "Save Changes"}
                            </Button>
                          )}
                        </div>
                        <Divider
                          orientation="vertical"
                          flexItem
                          className="fs-vertical-divid ms-3 me-3"
                        />
                        <div className="revert-close">
                          <Button
                            className="cancelBtn save-note"
                            type="button"
                            onClick={() => onCancel()}
                            disabled={isSubmit}
                          >
                            Revert and Close
                            <img
                              src={common.loadImg("closeKanban.svg")}
                              alt="close"
                            />
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="form-template-wrap">
                      <div className="sidebar-heading border-none">
                        <label className="sidebar-label">
                          {serviceList[serviceCode].label} Form Format
                        </label>
                        <p className="description">
                          Below is the {serviceList[serviceCode].label} format
                          to the details
                        </p>
                      </div>
                      <div className="fs-form-tab-section position-r">
                        <div>
                          {data.sections.map((sItem, sKey) => {
                            let fields = getFieldList(sKey, "et");
                            let dropData = {
                              type: "drop",
                              sKey,
                              fKey: fields.length,
                            };
                            return (
                              <div
                                key={sKey}
                                draggable={!sItem.fixed}
                                onDragStart={(e) => onDragStartSection(e, sKey)}
                                onDragOver={
                                  isDragMode == "section" && !sItem.fixed
                                    ? (e) => {
                                        onDragOverSection(sKey);
                                      }
                                    : null
                                }
                                onDragLeave={
                                  isDragMode == "section" && !sItem.fixed
                                    ? (e) => {
                                        onDragLeaveSection();
                                      }
                                    : null
                                }
                                onDragEnd={
                                  !sItem.fixed
                                    ? () => handleDragEndSection()
                                    : null
                                }
                                className={`fs-form${
                                  sItem.fixed ? "-fixed" : ""
                                }-wrap mb-3${sectionStyles(sKey)}`}
                              >
                                <div className="fs-title-wrap d-flex justify-content-between align-items-center mb-3">
                                  <Input
                                    placeholder="Section Title"
                                    value={sItem.label}
                                    onChange={(e) =>
                                      sectionTitle(sKey, e.target.value)
                                    }
                                    disabled={sItem.fixed}
                                    className="title-section"
                                  />
                                  {!sItem.fixed && (
                                    <div className="d-flex align-item-center justify-content-between">
                                      <div
                                        className="form-section-settings-wrap input-settings-cntrl fs-pointer"
                                        onClick={(e) => sectionSettings(sKey)}
                                      >
                                        <img
                                          src={common.loadImg(
                                            "settingsIcon.svg"
                                          )}
                                          alt=""
                                        />
                                      </div>
                                      <div>
                                        <ManageAccountsIcon
                                          fontSize="small"
                                          className={`fs-cp fs-active-icon ms-2`}
                                          // onClick={(e) =>
                                          //   enablePermission(e, fItem)
                                          // }
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <Row>
                                  {fields.map((fItem, fKey) => {
                                    let dragData = {
                                      type: "inner",
                                      sKey,
                                      fKey,
                                      field: fItem,
                                    };
                                    return (
                                      <Col
                                        key={fKey}
                                        draggable={!sItem.fixed}
                                        onDragStart={(e) =>
                                          onDragStartField(e, dragData)
                                        }
                                        onDragOver={
                                          !sItem.fixed &&
                                          isDragMode !== null &&
                                          isDragMode !== "section"
                                            ? (e) => {
                                                onDragOverField(dragData);
                                              }
                                            : null
                                        }
                                        onDragLeave={
                                          !sItem.fixed &&
                                          isDragMode !== null &&
                                          isDragMode !== "section"
                                            ? (e) => {
                                                onDragLeaveField();
                                              }
                                            : null
                                        }
                                        onDragEnd={
                                          !sItem.fixed
                                            ? () => handleDragEndField()
                                            : null
                                        }
                                        className={`${fieldStyles(dragData)}`}
                                        sm={sItem.layout == "single" ? 12 : 6}
                                      >
                                        <div
                                          className={`${getItemClass(
                                            fItem
                                          )}fs-field-box d-flex justify-content-between align-items-center mb-3`}
                                        >
                                          <label className="fs-field-name">
                                            {fItem.label}
                                          </label>
                                          <span className="fs-field-type">{`${
                                            formFields[fItem.type].label
                                          }`}</span>
                                          <div>
                                            {!isEduTech &&
                                              !fItem.fixed &&
                                              fItem.edit && (
                                                <EditIcon
                                                  fontSize="small"
                                                  className={`fs-cp fs-active-icon`}
                                                  onClick={(e) =>
                                                    fieldAction(
                                                      sKey,
                                                      fKey,
                                                      "edit"
                                                    )
                                                  }
                                                />
                                              )}
                                            {!isEduTech &&
                                              !fItem.fixed &&
                                              fItem.delete &&
                                              !fItem.required && (
                                                <DeleteIcon
                                                  fontSize="small"
                                                  className={`fs-cp fs-active-icon`}
                                                  onClick={(e) =>
                                                    fieldAction(
                                                      sKey,
                                                      fKey,
                                                      "delete"
                                                    )
                                                  }
                                                />
                                              )}
                                            {!isEduTech &&
                                              !restrictAccessFields[
                                                serviceCode
                                              ].includes(fItem.key) && (
                                                <ManageAccountsIcon
                                                  fontSize="small"
                                                  className={`fs-cp fs-active-icon`}
                                                  onClick={(e) =>
                                                    enablePermission(e, fItem)
                                                  }
                                                />
                                              )}
                                          </div>

                                          {/* {fItem.fixed && (
                                        <div>
                                          <img
                                            src={common.loadImg(
                                              "extra-dots.svg"
                                            )}
                                          />
                                        </div>
                                      )} */}
                                        </div>
                                      </Col>
                                    );
                                  })}
                                  {!sItem.fixed && (
                                    <Col
                                      sm={
                                        sItem.layout == "single" ||
                                        fields.length === 0
                                          ? 12
                                          : 6
                                      }
                                      onDragOver={
                                        isDragMode !== null &&
                                        isDragMode !== "section"
                                          ? (e) => {
                                              onDragOverField(dropData);
                                            }
                                          : null
                                      }
                                      onDragLeave={
                                        isDragMode !== null &&
                                        isDragMode !== "section"
                                          ? (e) => {
                                              onDragLeaveField();
                                            }
                                          : null
                                      }
                                      className={`${fieldStyles(dropData)}`}
                                    >
                                      <div className="fs-drop-field d-flex justify-content-center align-items-center mb-3">
                                        <label className="fs-field-name">
                                          Drop field{" "}
                                          <img
                                            src={common.loadImg(
                                              "fs-plus-icon.svg"
                                            )}
                                            className="ms-3"
                                          />
                                        </label>
                                      </div>
                                    </Col>
                                  )}
                                </Row>
                              </div>
                            );
                          })}

                          <div
                            onDragOver={
                              isDragMode == "section"
                                ? (e) => {
                                    onDragOverSection(data.sections.length);
                                  }
                                : null
                            }
                            onDragLeave={
                              isDragMode == "section"
                                ? (e) => {
                                    onDragLeaveSection();
                                  }
                                : null
                            }
                            className={`fs-drop-section mb-3 ${sectionStyles(
                              data.sections.length
                            )}`}
                          >
                            <div className="fs-title-wrap d-flex justify-content-center align-items-center">
                              <p className="sectionTitle">Drop section here</p>
                            </div>
                          </div>
                        </div>
                        {/* <Tab eventKey="quickCreate" title="Quick Create">
                    <div className="fs-form-wrap mb-3">
                      <div className="fs-field-box d-flex justify-content-between align-items-center mb-3">
                        <label className="fs-field-name">Lfirstname</label>
                        <span className="fs-field-type">Single Line</span>
                        <div>
                          <span>&nbsp;</span>
                        </div>
                      </div>

                      <div className="fs-field-box d-flex justify-content-between align-items-center mb-3">
                        <label className="fs-field-name">LDateTime</label>
                        <span className="fs-field-type">Date/Time</span>
                        <div>
                          <span>&nbsp;</span>
                        </div>
                      </div>

                      <div className="fs-field-box d-flex justify-content-between align-items-center mb-3">
                        <label className="fs-field-name">LNum</label>
                        <span className="fs-field-type">Number</span>
                        <div>
                          <span>&nbsp;</span>
                        </div>
                      </div>

                      <div className="fs-field-box d-flex justify-content-between align-items-center mb-3">
                        <label className="fs-field-name">LUser</label>
                        <span className="fs-field-type">Multi User</span>
                        <div>
                          <span>&nbsp;</span>
                        </div>
                      </div>
                    </div>
                  </Tab> */}
                      </div>
                    </div>
                  </div>
                </Col>
              </div>
            </div>
          )}
          {/* Dialog */}
          {/* Property Dialog */}
          {isDialog == "property" && (
            <FormProperties
              isMode={isMode}
              data={propertyData()}
              closeDialog={() => setDialog(null)}
              onComplete={propertyComplete}
              onDontSave={() => fieldDelete("permanent")}
            />
          )}
          {/* Section Dialog */}
          {isDialog == "section" && (
            <Dialog
              open={true}
              onClose={() => closeDialog()}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth
              maxWidth="xs"
              className="dialog-fonts"
              disableScrollLock
            >
              {formData.type == "section" &&
                (() => {
                  let sData = data.sections[formData.sKey];
                  return (
                    <Fragment>
                      <List component="nav" aria-label="mailbox folders">
                        <Grid
                          container
                          display="flex"
                          justifyContent="space-between"
                          className="cp"
                        >
                          <Grid item>
                            <ListItem className="notify-title">
                              Layout Properties
                            </ListItem>
                          </Grid>
                          <Grid item>
                            <ListItem>
                              <img
                                src={common.loadImg("filterCloseIcon.svg")}
                                alt="close"
                                onClick={handleClose}
                              />
                            </ListItem>
                          </Grid>
                        </Grid>

                        <ListSubheader>Section Layout</ListSubheader>

                        <ListItem
                          button
                          selected={sData.layout == "single" ? true : false}
                          onClick={() => sectionLayout("single")}
                        >
                          <ListItemText primary="Single Column" />
                        </ListItem>
                        <ListItem
                          button
                          selected={sData.layout == "double" ? true : false}
                          onClick={() => sectionLayout("double")}
                        >
                          <ListItemText primary="Double Column" />
                        </ListItem>
                        <Divider />
                        {!isEduTech && <ListSubheader>Delete</ListSubheader>}
                        {!isEduTech && (
                          <ListItem
                            button
                            onClick={() => setDialog("sectionDelete")}
                          >
                            <ListItemText primary="Delete Section" />
                          </ListItem>
                        )}
                      </List>
                    </Fragment>
                  );
                })()}
            </Dialog>
          )}
          {/* Section Delete */}
          {isDialog == "sectionDelete" &&
            (() => {
              let systemFields = getFieldTypeList("variable", "system");
              let requiredFields = getFieldTypeList("required", true);
              let useFields = getFieldTypeList("status", "use");
              let newFields = getFieldTypeList("status", "new");
              return (
                <Dialog
                  open={true}
                  onClose={() => closeDialog()}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  className="dialog-fonts delete-sec-popup"
                  disableScrollLock
                >
                  <DialogTitle id="alert-dialog-title" className="cp p-1">
                    <Grid
                      container
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <ListItem className="notify-title">
                          Section Delete
                        </ListItem>
                      </Grid>
                      <Grid item>
                        <ListItem>
                          <img
                            src={common.loadImg("filterCloseIcon.svg")}
                            alt="close"
                            onClick={handleClose}
                          />
                        </ListItem>
                      </Grid>
                    </Grid>
                  </DialogTitle>
                  <DialogContent className="delete-sec-popup-cont">
                    {systemFields.length > 0 ? (
                      <DialogContentText
                        id="alert-dialog-description"
                        className="mb-4"
                      >
                        Below systems field are currently in use so you cannot
                        delete this section.
                      </DialogContentText>
                    ) : requiredFields.length > 0 ? (
                      <DialogContentText
                        id="alert-dialog-description"
                        className="mb-4"
                      >
                        Below field are mandatory one so you cannot delete this
                        section.
                      </DialogContentText>
                    ) : useFields.length > 0 ? (
                      <DialogContentText
                        id="alert-dialog-description"
                        className="mb-4"
                      >
                        Below fields are already in use are you sure you want to
                        do this action?
                      </DialogContentText>
                    ) : (
                      <DialogContentText
                        id="alert-dialog-description"
                        className="mb-4"
                      >
                        Are you sure you want to proceed?
                      </DialogContentText>
                    )}
                    {systemFields.length > 0 ? (
                      <div className="mb-4">
                        <p className="fs-dialog-title">System Fields</p>
                        <List component="nav" aria-label="mailbox folders">
                          {systemFields.map((item, key) => (
                            <Fragment key={key}>
                              <ListItem button>
                                <ListItemText primary={item.label} />
                              </ListItem>
                              <Divider />
                            </Fragment>
                          ))}
                        </List>
                      </div>
                    ) : requiredFields.length > 0 ? (
                      <div className="mb-4">
                        <p className="fs-dialog-title">Required Fields</p>
                        <List component="nav" aria-label="mailbox folders">
                          {requiredFields.map((item, key) => (
                            <Fragment key={key}>
                              <ListItem button>
                                <ListItemText primary={item.label} />
                              </ListItem>
                              <Divider />
                            </Fragment>
                          ))}
                        </List>
                      </div>
                    ) : (
                      useFields.length > 0 && (
                        <div className="mb-4">
                          <p className="fs-dialog-title">
                            Fields already in use
                          </p>
                          <List component="nav" aria-label="mailbox folders">
                            {useFields.map((item, key) => (
                              <Fragment key={key}>
                                <ListItem button>
                                  <ListItemText primary={item.label} />
                                </ListItem>
                                <Divider />
                              </Fragment>
                            ))}
                          </List>
                        </div>
                      )
                    )}

                    {systemFields.length == 0 &&
                      requiredFields.length == 0 &&
                      newFields.length > 0 && (
                        <div className="mb-4">
                          <p className="fs-dialog-title">New fields</p>
                          <List component="nav" aria-label="mailbox folders">
                            {newFields.map((item, key) => (
                              <Fragment key={key}>
                                <ListItem button>
                                  <ListItemText primary={item.label} />
                                </ListItem>
                                <Divider />
                              </Fragment>
                            ))}
                          </List>
                        </div>
                      )}
                  </DialogContent>
                  <DialogActions className="fs-dialog-margin border-top m-0 px-4">
                    {systemFields.length > 0 || requiredFields.length > 0 ? (
                      <MuiButton
                        className="save-note"
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => closeDialog()}
                      >
                        Got it!
                      </MuiButton>
                    ) : useFields.length > 0 || newFields.length > 0 ? (
                      <Fragment>
                        <MuiButton
                          className="dialog-secondary-btn"
                          variant="outlined"
                          color="secondary"
                          size="small"
                          onClick={() => sectionDelete("permanent")}
                        >
                          Delete Permanently
                        </MuiButton>
                        <MuiButton
                          className="dialog-secondary-btn"
                          variant="outlined"
                          color="primary"
                          size="small"
                          onClick={() => sectionDelete("unUse")}
                        >
                          Move to Unused Fields
                        </MuiButton>
                        <MuiButton
                          className="save-note"
                          variant="outlined"
                          size="small"
                          onClick={() => closeDialog()}
                        >
                          Cancel
                        </MuiButton>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <MuiButton
                          className="save-note"
                          variant="outlined"
                          color="secondary"
                          size="small"
                          onClick={() => closeDialog()}
                        >
                          No
                        </MuiButton>
                        <MuiButton
                          className="save-note"
                          variant="outlined"
                          color="primary"
                          size="small"
                          onClick={() => sectionDelete("fully")}
                        >
                          Yes
                        </MuiButton>
                      </Fragment>
                    )}
                  </DialogActions>
                </Dialog>
              );
            })()}

          {/* Delete Field Dialog */}
          {isDialog == "delete" && (
            <Dialog
              open={true}
              onClose={() => closeDialog()}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="dialog-fonts"
              disableScrollLock
            >
              <DialogTitle id="alert-dialog-title" className="cp">
                {/* {"Delete Confirmation"} */}
                <Grid container display="flex" justifyContent="space-between">
                  <Grid item className="notify-title">
                    {"Delete Confirmation"}
                  </Grid>
                  <Grid item>
                    <img
                      src={common.loadImg("filterCloseIcon.svg")}
                      alt="close"
                      onClick={handleClose}
                    />
                  </Grid>
                </Grid>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to do this action?
                </DialogContentText>
              </DialogContent>
              <DialogActions className="fs-dialog-margin">
                <MuiButton
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => fieldDelete("permanent")}
                  disabled={getFormFieldInfo().variable === "system"}
                  className="delete-btn"
                >
                  Delete Permanently
                </MuiButton>
                <MuiButton
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => fieldDelete("unUse")}
                  className="save-note"
                >
                  Move to Unused Fields
                </MuiButton>
                <MuiButton
                  variant="outlined"
                  size="small"
                  onClick={() => closeDialog()}
                  className="delete-btn"
                >
                  Cancel
                </MuiButton>
              </DialogActions>
            </Dialog>
          )}

          {/* Delete Field Dialog */}
          {isDialog == "unUseDelete" && (
            <Dialog
              open={true}
              onClose={() => closeDialog()}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              disableScrollLock
            >
              <DialogTitle id="alert-dialog-title">
                {"Permanent Delete Confirmation"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to permanently delete this field?
                </DialogContentText>
              </DialogContent>
              <DialogActions className="fs-dialog-margin">
                <MuiButton
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => unUseDelete()}
                >
                  Yes
                </MuiButton>
                <MuiButton
                  variant="outlined"
                  size="small"
                  onClick={() => closeDialog()}
                >
                  Cancel
                </MuiButton>
              </DialogActions>
            </Dialog>
          )}

          {showPermission && (
            <Dialog
              open={true}
              onClose={() => closeDialog()}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              disableScrollLock
              className="fm-setting-popup form-setting-permission-popup"
            >
              <div className="role-wrap">
                <DialogTitle id="alert-dialog-title">
                  Set Permission - {permissionField.label}
                </DialogTitle>
                <Divider />
                <DialogContent>
                  <div className="fm-setting-popup-tbody">
                    <Table aria-label="simple table" stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            component="th"
                            align="left"
                            className="thead-th role-name-wrap"
                          >
                            Role
                          </TableCell>
                          <TableCell
                            component="th"
                            align="left"
                            className="thead-th"
                          >
                            Read and Write{" "}
                            <Link
                              to="#"
                              onClick={(e) =>
                                oneAccessOnly(e, fieldAccess[0].value)
                              }
                            >
                              All
                            </Link>
                          </TableCell>
                          <TableCell
                            component="th"
                            align="left"
                            className="thead-th"
                          >
                            Read Only{" "}
                            <Link
                              to="#"
                              onClick={(e) =>
                                oneAccessOnly(e, fieldAccess[1].value)
                              }
                            >
                              All
                            </Link>
                          </TableCell>
                          <TableCell
                            component="th"
                            align="left"
                            className="thead-th"
                          >
                            Dont Show{" "}
                            <Link
                              to="#"
                              onClick={(e) =>
                                oneAccessOnly(e, fieldAccess[2].value)
                              }
                            >
                              All
                            </Link>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {roleList.length > 0 &&
                          roleList.map((role, idx) => (
                            <TableRow className="FS-module-head" key={idx}>
                              <TableCell
                                component="td"
                                className="role-name-wrap"
                              >
                                {role.GroupDesc}
                              </TableCell>
                              <TableCell component="td">
                                <input
                                  type="radio"
                                  className="radio-button ml-5"
                                  name={"access_" + idx}
                                  value={fieldAccess[0].value}
                                  checked={checkAccess(
                                    fieldAccess[0].value,
                                    role.GroupId
                                  )}
                                  onChange={(e) =>
                                    onCheckboxChange(e, role.GroupId)
                                  }
                                />
                              </TableCell>
                              <TableCell component="td">
                                <input
                                  type="radio"
                                  className="radio-button ml-5"
                                  name={"access_" + idx}
                                  value={fieldAccess[1].value}
                                  checked={checkAccess(
                                    fieldAccess[1].value,
                                    role.GroupId
                                  )}
                                  onChange={(e) =>
                                    onCheckboxChange(e, role.GroupId)
                                  }
                                />
                              </TableCell>
                              <TableCell component="td">
                                <input
                                  type="radio"
                                  className="radio-button ml-5"
                                  name={"access_" + idx}
                                  value={fieldAccess[2].value}
                                  checked={checkAccess(
                                    fieldAccess[2].value,
                                    role.GroupId
                                  )}
                                  onChange={(e) =>
                                    onCheckboxChange(e, role.GroupId)
                                  }
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </div>
                </DialogContent>
                <Divider />
                <DialogActions className="fs-dialog-margin justify-content-start py-2">
                  <div className="botBtn pl-3">
                    <div>
                      <Button
                        className="automation-footer-btn"
                        type="submit"
                        onClick={(e) => updatePermission(e)}
                      >
                        Done
                      </Button>
                      <Button
                        className="automation-cancel-btn"
                        type="button"
                        onClick={(e) => closePermission(e)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </DialogActions>
              </div>
            </Dialog>
          )}
        </div>
      )}
    </React.Fragment>
  );
}

export { FormSettings };
