import React, { useEffect, useState, Fragment } from "react";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import { Form, Col, Row, Modal } from "react-bootstrap";
import Select, { components } from "react-select";
import InputIcon from "react-multi-date-picker/components/input_icon";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePicker from "react-multi-date-picker";
import { useNavigate, useOutletContext } from "react-router-dom";
import RcTimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
// material
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Typography,
  Divider,
  TextField,
  InputLabel,
} from "@mui/material";
import MuiSelect from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import AccessDenied from "elements/AccessDenied";
// confirm
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
// api
import { common, tools, envConfig, api } from "helpers";
import * as Services from "helpers/service";
// pages
import EmailModal from "./includes/EmailModal";
import TextModal from "./includes/TextModal";
import TaskModal from "./includes/TaskModal";
import FieldUpdateModal from "./includes/FieldUpdateModal";
import Loader from "elements/Loader";
import AutomationSelect from "elements/AutomationSelect";
// files
import formFields from "assets/files/formFields.json";
import serviceList from "assets/files/serviceList.json";
import lookupList from "assets/files/lookupList.json";
// css
import "./css/Workflow.css";
import StatusBar from "elements/StatusBar";

const format = "hh:mm A";

const now = moment().hour(0).minute(0);

function onChange(value) {
  console.log(value && value.format(format));
}

function WorkflowCrud(props) {
  const [permission] = useOutletContext();
  const navigate = useNavigate();
  const params = useParams();
  const userInfo = common.userInfo();
  const authInfo = common.authInfo();
  const [status, setStatus] = useState("process"); // completed
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1); // 2
  const [columns, setColumns] = useState(null);
  const [emailColumns, setEmailColumns] = useState([]);
  const [phoneColumns, setPhoneColumns] = useState([]);
  const [actionModal, setActionModal] = useState(false);
  const [subEMailList, setSubEMailList] = useState([]);

  const [fields, setFields] = useState({
    module: "", // s.as "campaign"
    ruleName: "", // s.as "Rule 1"
    ruleDescription: "", // s.as "Rule 1 Desc"
    active: "Y",
    execute: "record", // dateTime
    record: {
      when: "",
      status: "next", // completed
    },
    dateTime: {
      basedOnField: "",
      executionDateType: "ON",
      executionDateValue: "1",
      executionTimeType: "SAME",
      executionTime: moment().format("DD/MMM/yyyy 08:00 AM"),
      executionOnTime: "1",
      executionOnType: "MIA",
      executionTimeZone: "Asia/Kolkata",
      recur: "ONCE",
      status: "next",
    },
  });

  const [conditions, setConditions] = useState([
    {
      // id
      cgId: "",
      cId: [],
      eaId: [],
      maId: [],
      taId: [],
      uaId: [],
      // data
      type: "next", // completed
      column: [""],
      dataType: [""],
      operator: [""],
      inputValue1: [""],
      inputValue2: [""],
      logical: ["AND"],
      emailAction: [],
      textAction: [],
      taskAction: [],
      updateAction: [],
    },
  ]);

  const [operator, setOperator] = useState({
    operator: {
      string: "CHOLA_AUTOMATION_OPERATOR_S",
      number: "CHOLA_AUTOMATION_OPERATOR_N",
      date: "CHOLA_AUTOMATION_OPERATOR_D",
      timestamp: "CHOLA_AUTOMATION_OPERATOR_D",
    },
    // types
    string: {},
    number: {},
    date: {},
    timestamp: {},
    api: {
      E: "Equals",
      DNE: "Does not equal",
      C: "Contains",
      DNC: "Does not contain",
      IB: "Is blank",
      INB: "Is not blank",
    },
  });

  const [fieldTypes] = useState({
    default: [
      "singleLine",
      "multiLine",
      "email",
      "phone",
      "number",
      "decimal",
      "currency",
      "url",
    ],
    bool: ["checkbox"],
    date: ["date", "dateTime"],
    singleSelect: ["pickList", "lookup"],
    multipleSelect: ["multiSelect", "multiLookup"],
    file: ["imageUpload", "fileUpload"],
  });

  const [action, setAction] = useState({
    coKey: "",
    clKey: "",
    data: "",
    type: "",
  });

  const [ruleEdit, setRuleEdit] = useState({
    cgDelete: [],
    cUpdate: [],
    cDelete: [],
    eaUpdate: [],
    eaDelete: [],
    maUpdate: [],
    maDelete: [],
    taUpdate: [],
    taDelete: [],
    uaUpdate: [],
    uaDelete: [],
  });

  const [abbreviation] = useState({
    schedule: {
      ON: {
        short: "On",
        long: "On",
        max: 0,
      },
      MIA: {
        short: "Minute(s)",
        long: "Minute(s) After",
        max: 59,
      },
      MIB: {
        short: "Minute(s)",
        long: "Minute(s) Before",
        max: 59,
      },
      HA: {
        short: "Hour(s)",
        long: "Hour(s) After",
        max: 23,
      },
      HB: {
        short: "Hour(s)",
        long: "Hour(s) Before",
        max: 23,
      },
      DA: {
        short: "Days(s)",
        long: "Days(s) After",
        max: 999,
      },
      DB: {
        short: "Days(s)",
        long: "Days(s) Before",
        max: 999,
      },
      WA: {
        short: "Week(s)",
        long: "Week(s) After",
        max: 100,
      },
      WB: {
        short: "Week(s)",
        long: "Week(s) Before",
        max: 100,
      },
      MA: {
        short: "Month(s)",
        long: "Month(s) After",
        max: 24,
      },
      MB: {
        short: "Month(s)",
        long: "Month(s) Before",
        max: 24,
      },
      YA: {
        short: "Year(s)",
        long: "Year(s) After",
        max: 1,
      },
      YA: {
        short: "Year(s)",
        long: "Year(s) Before",
        max: 1,
      },
    },
    recur: {
      ONCE: "Once",
      EM: "Every Month",
      EY: "Every Year",
    },
  });

  const [nameData, setNameData] = useState(null);
  const [conditionData, setConditionData] = useState(null);

  // effect
  useEffect(() => {
    // getColumnsData();
    getOperatorList();
    getTenantEmailList();
  }, []);

  useEffect(() => {
    if (columns !== null) {
      if (params.ruleId) {
        getRuleConditions();
      } else {
        setLoading(false);
        setStep(2);
      }
    }
  }, [columns]);

  useEffect(() => {
    if (nameData !== null) {
      applyRuleConditions();
    }
  }, [nameData]);

  useEffect(() => {
    if (params.ruleId && fields.module) {
      getColumnsData();
    }
  }, [fields.module]);

  // Api
  const getRuleById = () => {
    let filter = `?q=RuleId=${params.ruleId}`;
    let url = envConfig.BASE_API + Services.CHOLA_AUTOMATION + filter;

    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };
    api.call(
      data,
      (response) => {
        if (response) {
          if (response.data.count === 0) {
            common.snack("W", "Sorry no data found");
            navigate("/setting/configure/automation");
          } else {
            const ruleData = response.data.items[0];
            setFields((oldData) => {
              let newData = reParse(oldData);
              newData.module = getModuleType(ruleData.RuleObject);
              newData.ruleName = ruleData.RuleName;
              newData.ruleDescription = ruleData.RuleDescription;
              newData.active = ruleData.Active;

              if (ruleData.RuleType !== "SCHEDULED") {
                newData.execute = "record";
                newData.record = {
                  when: ruleData.RuleType,
                  status: "completed",
                };
              } else {
                newData.execute = "dateTime";
                newData.dateTime = {
                  basedOnField: ruleData.ExecutionBasedOn,
                  executionDateType: ruleData.CustomExecutionType ?? "ON",
                  executionDateValue: ruleData.CustomDays ?? 1,
                  executionTimeType: getTimeType(ruleData.ExecutionTime),
                  executionTime: getTimeFormat(ruleData.ExecutionTime),
                  executionOnTime: ruleData.CustomExecutionNos ?? "1",
                  executionOnType: getTimeAppr(ruleData.ExecutionTime),
                  executionTimeZone:
                    ruleData.ExecutionTimezone ?? "Asia/Kolkata",
                  recur: ruleData.Recurrence,
                  status: "completed",
                };
              }
              return newData;
            });
          }
        }
      },
      (error) => {
        setStatus("error");
      },
      (final) => {}
    );
  };

  const getColumnUrl = (lookup, value) => {
    if (value.includes(",")) {
      let values = value.split(",");
      let newStr = values.join("','");
      value = `'${newStr}'`;
    }
    let url = `${envConfig.BASE_API}${Services[lookup.name]}`;
    let fields = `?fields=${lookup.get},${lookup.set}`;
    let query = `&q=${lookup.set} IN (${value})&`;
    return url + fields + query + getTenantOrg();
  };

  const getRuleConditions = () => {
    let filter = `?q=RuleId=${params.ruleId}&expand=CholaAutomationCndnView,CholaAutomationActnView&limit=100&offset=0`;
    let url =
      envConfig.BASE_API + Services.CHOLA_AUTOMATION_CONDITION_GROUPS + filter;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };
    api.call(
      data,
      (response) => {
        if (response) {
          let count = 0;
          let done = 0;
          let columnApis = {};
          let templateIds = [];
          let usersEmails = [];
          let usersPhones = [];
          let taskPriority = [];
          let nameList = {};
          setConditionData(response.data.items);
          // assign
          response.data.items.map((item, key) => {
            item.CholaAutomationCndnView.map((cItem) => {
              let column = columns[cItem.CriteriaAttribute];
              if (
                column.type === "lookup" &&
                column.attr.lookup !== "transactionStatus" &&
                !["IB", "INB"].includes(cItem.CriteriaOperator)
              ) {
                if (!(column.attr.lookup in columnApis)) {
                  columnApis[column.attr.lookup] = [];
                }
                columnApis[column.attr.lookup].push(cItem.CriteriaValue);
              }
            });
            item.CholaAutomationActnView.map((aItem) => {
              if (aItem.ActionType == "EMAIL") {
                templateIds.push(aItem.ActionTemplateId);
                usersEmails.push(aItem.FromUser);
                usersEmails.push(aItem.ToUser);
              } else if (aItem.ActionType == "TEXT") {
                templateIds.push(aItem.ActionTemplateId);
                usersPhones.push(aItem.ToUserMobile);
              } else if (aItem.ActionType == "TASK") {
                taskPriority.push(1);
                usersEmails.push(aItem.FromUser);
                usersEmails.push(aItem.ToUser);
              } else if (aItem.ActionType == "UPDATE") {
                let column = columns[aItem.ActionAttribute];
                if (
                  column.type === "lookup" &&
                  column.attr.lookup !== "transactionStatus"
                ) {
                  if (!(column.attr.lookup in columnApis)) {
                    columnApis[column.attr.lookup] = [];
                  }
                  columnApis[column.attr.lookup].push(aItem.ActionUpdateValue);
                }
              }
            });
          });

          // count
          count += Object.keys(columnApis).length;
          count += [...new Set(templateIds)].length > 0 ? 1 : 0;
          count += [...new Set(usersEmails)].length > 0 ? 1 : 0;
          count += [...new Set(usersPhones)].length > 0 ? 1 : 0;
          count += taskPriority.length > 0 ? 2 : 0;

          if (count === 0) {
            setNameData(nameList);
          } else {
            Object.entries(columnApis).map(([key, item]) => {
              let lookup = lookupList[key];
              let ids = [...new Set(item)].join(",");
              let url = getColumnUrl(lookup, ids);
              let data = {
                url: url,
                type: "dynamic",
                method: "GET",
                auth: "token",
                moreHead: { rfv: 2 },
                cType: 4,
              };
              api.call(
                data,
                async (response) => {
                  if (response) {
                    nameList[key] = {};
                    response.data.items.map((rItem) => {
                      nameList[key][rItem[lookup.set]] = rItem[lookup.get];
                    });
                    // done
                    ++done;
                    if (count === done) {
                      setNameData(nameList);
                    }
                  }
                },
                (error) => {},
                (final) => {}
              );
            });

            if (templateIds.length > 0) {
              let ids = [...new Set(templateIds)].join(",");
              let url = `${envConfig.BASE_API}${
                Services["CHOLA_EMAIL_TEMPLATE"]
              }?fields=EmailTemplateId,TemplateName&q=EmailTemplateId IN(${ids})&${getTenantOrg()}`;
              let data = {
                url: url,
                type: "dynamic",
                method: "GET",
                auth: "token",
                moreHead: { rfv: 2 },
                cType: 4,
              };
              api.call(
                data,
                async (response) => {
                  if (response) {
                    nameList["actionTemplate"] = {};
                    response.data.items.map((rItem) => {
                      nameList["actionTemplate"][rItem.EmailTemplateId] =
                        rItem.TemplateName;
                    });
                    // done
                    ++done;
                    if (count === done) {
                      setNameData(nameList);
                    }
                  }
                },
                (error) => {},
                (final) => {}
              );
            }

            if (usersEmails.length > 0) {
              let ids = [...new Set(usersEmails.join(",").split(","))].join(
                "','"
              );
              let url = `${envConfig.BASE_API}${
                Services["CRM_EMPLOYEES"]
              }?fields=EmployeeName,Email&q=Email IN('${ids}')&${getTenantOrg()}`;
              let data = {
                url: url,
                type: "dynamic",
                method: "GET",
                auth: "token",
                moreHead: { rfv: 2 },
                cType: 4,
              };
              api.call(
                data,
                async (response) => {
                  if (response.data.items.length > 0) {
                    nameList["actionEmail"] = {};
                    response.data.items.map((rItem) => {
                      nameList["actionEmail"][rItem.Email] = rItem.EmployeeName;
                      if (authInfo.role != Services.CRM_DEFAULT_GROUPS_2) {
                        subEMailList.push({
                          label: rItem.EmployeeName + " (" + rItem.Email + ")",
                          value: rItem.Email,
                        });
                      }
                    });
                    if (authInfo.role != Services.CRM_DEFAULT_GROUPS_2) {
                      setSubEMailList(subEMailList);
                    }
                    // done
                    ++done;
                    if (count === done) {
                      setNameData(nameList);
                    }
                  } else {
                    nameList["actionEmail"] = {};
                    let subFromEmail = subEMailList.filter((obj) => {
                      return usersEmails[0] === obj.value;
                    });

                    console.log(
                      "subFromEmail :>> ",
                      subFromEmail,
                      subEMailList,
                      ids
                    );
                    if (subFromEmail.length > 0) {
                      nameList["actionEmail"][subFromEmail[0].value] =
                        subFromEmail[0].label;
                      // done
                      ++done;
                      if (count === done) {
                        setNameData(nameList);
                      }
                    }
                  }
                },
                (error) => {},
                (final) => {}
              );
            }

            if (usersPhones.length > 0) {
              let ids = [...new Set(usersPhones.join(",").split(","))].join(
                "','"
              );
              let url = `${envConfig.BASE_API}${
                Services["CRM_EMPLOYEES"]
              }?fields=EmployeeName,MobileNo&q=MobileNo IN('${ids}')&${getTenantOrg()}`;
              let data = {
                url: url,
                type: "dynamic",
                method: "GET",
                auth: "token",
                moreHead: { rfv: 2 },
                cType: 4,
              };
              api.call(
                data,
                async (response) => {
                  if (response) {
                    nameList["actionPhone"] = {};
                    response.data.items.map((rItem) => {
                      nameList["actionPhone"][rItem.MobileNo] =
                        rItem.EmployeeName;
                    });
                    // done
                    ++done;
                    if (count === done) {
                      setNameData(nameList);
                    }
                  } else {
                  }
                },
                (error) => {},
                (final) => {}
              );
            }

            // task status & priority
            if (taskPriority.length > 0) {
              ["TASK_STATUS", "TASK_PRIORITY"].map((i) => {
                let url = `${envConfig.BASE_API}${Services.LOOKUPS_LOV}?fields=Code,Name&finder=FindLookupVals;pLookupCode=${i}`;
                let data = {
                  url: url,
                  type: "dynamic",
                  method: "GET",
                  auth: "token",
                  moreHead: { rfv: 2 },
                  cType: 4,
                };
                api.call(
                  data,
                  async (response) => {
                    if (response) {
                      nameList[`FINDER_${i}`] = {};
                      response.data.items.map((rItem) => {
                        nameList[`FINDER_${i}`][rItem.Code] = rItem.Name;
                      });
                      // done
                      ++done;
                      if (count === done) {
                        setNameData(nameList);
                      }
                    }
                  },
                  (error) => {},
                  (final) => {}
                );
              });
            }
          }
        }
      },
      (error) => {
        setStatus("error");
      },
      (final) => {}
    );
  };

  const applyRuleConditions = () => {
    // apply
    let conditions = [];
    conditionData.map((item, key) => {
      let condition = {
        // id
        cgId: item.ConditionGrpId,
        cId: [],
        eaId: [],
        maId: [],
        taId: [],
        uaId: [],
        // data
        type: "completed",
        column: [],
        dataType: [],
        operator: [],
        inputValue1: [],
        inputValue2: [],
        logical: [],
        emailAction: [],
        textAction: [],
        taskAction: [],
        updateAction: [],
      };
      // conditions
      item.CholaAutomationCndnView.map((cItem, cKey) => {
        let column = columns[cItem.CriteriaAttribute];
        condition.cId.push(cItem.ConditionId);
        condition.column.push(cItem.CriteriaAttribute);
        condition.dataType.push(cItem.CriteriaType.toLowerCase());
        condition.operator.push(cItem.CriteriaOperator);
        condition.logical.push(cItem.CriteriaConjunction);
        condition.inputValue2.push(cItem.CriteriaValue1);
        if (
          column.type === "lookup" &&
          column.attr.lookup !== "transactionStatus" &&
          !["IB", "INB"].includes(cItem.CriteriaOperator)
        ) {
          condition.inputValue1.push({
            label: nameData[column.attr.lookup][cItem.CriteriaValue],
            value: cItem.CriteriaValue,
          });
        } else if (
          column.type === "pickList" ||
          column.attr.lookup === "transactionStatus"
        ) {
          condition.inputValue1.push({
            label: cItem.CriteriaValue,
            value: cItem.CriteriaValue,
          });
        } else {
          condition.inputValue1.push(cItem.CriteriaValue);
        }
      });

      // actions
      item.CholaAutomationActnView.map((aItem, aKey) => {
        if (aItem.ActionType == "EMAIL") {
          condition.eaId.push(aItem.ActionId);
          condition.emailAction.push({
            templateId: setTemplateId(aItem.ActionTemplateId),
            fromUser: setFromUser(aItem.FromUser),
            toUser: setToUser(aItem.ToUser),
            alterToUserField: setAlterToUserField(aItem.AlterToUserField),
            description: aItem.Description,
            scheduled: aItem.ScheduledAction,
            scheduledNo: aItem.ScheduledActionNos ?? 0,
            scheduledType: aItem.ScheduledActionType ?? "",
          });
        } else if (aItem.ActionType == "TEXT") {
          condition.maId.push(aItem.ActionId);
          condition.textAction.push({
            templateId: setTemplateId(aItem.ActionTemplateId),
            fromUser: null,
            toUser: setToUserPhone(aItem.ToUserMobile),
            alterToUserField: setAlterToUserField(aItem.AlterToUserMobileField),
            description: aItem.Description,
            scheduled: aItem.ScheduledAction,
            scheduledNo: aItem.ScheduledActionNos ?? 0,
            scheduledType: aItem.ScheduledActionType ?? "",
          });
        } else if (aItem.ActionType == "TASK") {
          condition.taId.push(aItem.ActionId);
          condition.taskAction.push({
            actionName: aItem.ActionName,
            actionSubject: aItem.ActionSubject,
            description: aItem.Description,
            actionModule: aItem.ActionModule,
            fromUser: setFromUser(aItem.FromUser),
            toUser: setToUser(aItem.ToUser),
            taskStatus: setTaskStatus(aItem.ActionStatus),
            priority: setPriority(aItem.Priority),
            startDate: aItem.ActionStartDate,
            endDate: aItem.ActionEndDate,
            scheduled: aItem.ScheduledAction,
            scheduledNo: aItem.ScheduledActionNos ?? 0,
            scheduledType: aItem.ScheduledActionType ?? "",
          });
        } else if (aItem.ActionType == "UPDATE") {
          let column = columns[aItem.ActionAttribute];
          let value = aItem.ActionUpdateValue;
          if (
            column.type === "lookup" &&
            column.attr.lookup !== "transactionStatus"
          ) {
            value = {
              label: nameData[column.attr.lookup][aItem.ActionUpdateValue],
              value: aItem.ActionUpdateValue,
            };
          } else if (
            column.type === "pickList" ||
            column.attr.lookup === "transactionStatus"
          ) {
            value = {
              label: aItem.ActionUpdateValue,
              value: aItem.ActionUpdateValue,
            };
          }
          condition.uaId.push(aItem.ActionId);
          condition.updateAction.push({
            description: aItem.Description,
            actionAttribute: aItem.ActionAttribute,
            actionUpdateValue: value,
            scheduled: aItem.ScheduledAction,
            scheduledNo: aItem.ScheduledActionNos ?? 0,
            scheduledType: aItem.ScheduledActionType ?? "",
          });
        }
      });
      // push to condition
      conditions.push(condition);
    });

    setConditions(conditions);
    setStatus("completed");
    setLoading(false);
  };

  const getOperatorList = () => {
    Object.keys(operator.operator).map((item, key) => {
      let filter = `?fields=Name,Code&finder=FindLookupVals;pLookupCode=${operator.operator[item]},pTenantId=${userInfo.pTenantId}&totalResults=true&limit=500&offset=0`;
      let url = envConfig.BASE_API + Services.LOOKUPS_LOV + filter;
      let data = {
        url: url,
        type: "dynamic",
        method: "GET",
        auth: "token",
        moreHead: { rfv: 2 },
        cType: 4,
      };
      api.call(
        data,
        (response) => {
          if (response) {
            setOperator((oldData) => {
              let newData = reParse(oldData);
              let operator = {};
              response.data.items.map((i) => {
                operator[i.Code] = i.Name;
              });
              newData[item] = operator;
              return newData;
            });
            if (key === 2) {
              if (params.ruleId) {
                getRuleById();
              } else {
                setStatus("completed");
              }
            }
          }
        },
        (error) => {
          setStatus("error");
        },
        (final) => {}
      );
    });
  };

  const getTenantEmailList = () => {
    let url =
      envConfig.BASE_API + Services.CHOLA_TENANTS + "/" + userInfo.pTenantId;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };
    api.call(
      data,
      (response) => {
        if (response) {
          let emailList = [
            {
              label: authInfo.EmployeeName + " (" + authInfo.UserName + ")",
              value: authInfo.UserName,
            },
          ];
          if (response.data.AlternativeEmailAddress1) {
            emailList.push({
              label:
                response.data.TenantName +
                " (" +
                response.data.AlternativeEmailAddress1 +
                ")",
              value: response.data.AlternativeEmailAddress1,
            });
          }
          if (response.data.AlternativeEmailAddress2) {
            emailList.push({
              label:
                response.data.TenantName +
                " (" +
                response.data.AlternativeEmailAddress2 +
                ")",
              value: response.data.AlternativeEmailAddress2,
            });
          }

          setSubEMailList(emailList);
        }
      },
      (error) => {
        setStatus("error");
      },
      (final) => {}
    );
  };

  const getColumnsData = () => {
    let filter = `?q=EserviceCode=${serviceList[fields.module].code}`;
    let url = envConfig.BASE_API + Services.CRM_ESERVICE_HDR + filter;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };
    api.call(
      data,
      (response) => {
        if (response) {
          let formFields = JSON.parse(response.data.items[0].FormFields);
          let columnsList = {};
          let emailList = [];
          let phoneList = [];
          formFields.form.fields.filter((item) => {
            if (item.automation) {
              columnsList[item.key] = item;
              if (item.type === "email") {
                emailList.push({ label: item.label, value: item.key });
              }
              if (item.type === "phone") {
                phoneList.push({ label: item.label, value: item.key });
              }
            }
          });
          setColumns(columnsList);
          setEmailColumns(emailList);
          setPhoneColumns(phoneList);
        }
      },
      (error) => {
        setStatus("error");
      },
      (final) => {}
    );
  };

  // api support
  const getExecuteRule = () => {
    let result = {
      RuleType: null,
      ExecutionBasedOn: null,
      ExecutionDate: null,
      CustomExecutionType: null, // null ? DB,MB etc...
      CustomDays: null, // if custom ? 0,1 etc... : null
      ExecutionTime: "SAME", // SAME ? HA,MIA ? 08:00
      CustomExecutionNos: null, // if HA,MIA ? 0,1 : null
      ExecutionTimezone: null,
      Recurrence: null,
    };

    let item = fields[fields.execute];
    if (fields.execute === "record") {
      result.RuleType = item.when;
    } else {
      result.RuleType = "SCHEDULED";
      result.ExecutionBasedOn = item.basedOnField;
      result.Recurrence = item.recur;
      // date
      if (item.executionDateType !== "ON") {
        result.ExecutionDate = "CUSTOM";
        result.CustomExecutionType = item.executionDateType;
        result.CustomDays = item.executionDateValue;
      }
      // time
      if (item.executionTimeType === "FIELD") {
        result.ExecutionTime = item.executionOnType;
        result.CustomExecutionNos = item.executionOnTime;
      } else if (item.executionTimeType === "TIME") {
        result.ExecutionTime = moment(item.executionTime).format("hh:mm A");
        result.ExecutionTimezone = item.executionTimeZone;
      }
    }
    return result;
  };

  const getSnippet = () => {
    let field = fields.dateTime;
    let column = columns[field.basedOnField].label;
    let timeAt =
      field.executionTimeType === "TIME"
        ? ` at ${moment(field.executionTime).format("hh:mm A")}`
        : ``;
    let fieldAt =
      field.executionTimeType === "FIELD"
        ? `${field.executionOnTime} ${
            abbreviation.schedule[field.executionOnType].long
          }`
        : `on`;
    let result = "";
    if (field.executionDateType === "ON") {
      result = `This rule will be executed ${fieldAt} ${column}${timeAt}`;
    } else {
      result = `This rule will be executed ${field.executionDateValue} ${
        abbreviation.schedule[field.executionDateType].long
      } ${column}${timeAt}`;
    }
    return result;
  };

  const getTimeType = (i) => {
    return i === "SAME" ? i : i in abbreviation.schedule ? "FIELD" : "TIME";
  };

  const getTimeFormat = (i) => {
    return i === "SAME" || i in abbreviation.schedule
      ? moment().format("DD/MMM/yyyy 08:00 AM")
      : moment().format(`DD/MMM/yyyy ${i}`);
  };

  const getTimeAppr = (i) => {
    return i in abbreviation.schedule ? i : "MIA";
  };

  const setTemplateId = (i) => {
    let result = null;
    if (i) {
      result = { label: nameData.actionTemplate[i], value: i.toString() };
    }
    return result;
  };

  const setFromUser = (i) => {
    let result = null;
    if (i) {
      console.log("nameData.actionEmail[i] :>> ", nameData.actionEmail);
      result = { label: nameData.actionEmail[i], value: i };
    }
    return result;
  };

  const setToUser = (i) => {
    let result = null;
    if (i) {
      result = i
        .split(",")
        .map((j) => ({ label: nameData.actionEmail[j] ?? j, value: j }));
    }
    return result;
  };

  const setAlterToUserField = (i) => {
    let result = null;
    if (i) {
      result = i.split(",").map((j) => ({ label: columns[j].label, value: j }));
    }
    return result;
  };

  const setToUserPhone = (i) => {
    let result = null;
    if (i) {
      result = i
        .split(",")
        .map((j) => ({ label: nameData.actionPhone[j] ?? j, value: j }));
    }
    return result;
  };

  const setTaskStatus = (i) => {
    let result = null;
    if (i) {
      result = { label: nameData.FINDER_TASK_STATUS[i], value: i };
    }
    return result;
  };

  const setPriority = (i) => {
    let result = null;
    if (i) {
      result = { label: nameData.FINDER_TASK_PRIORITY[i], value: i };
    }
    return result;
  };

  const getFromUser = (i) => {
    let result = null;
    if (i) {
      result = i.value;
    }
    return result;
  };

  const getToUser = (i) => {
    let result = null;
    if (i) {
      result = i.map((j) => j.value).join(",");
    }
    return result;
  };

  const getAlterToUserField = (i) => {
    let result = null;
    if (i) {
      result = i.map((j) => j.value).join(",");
    }
    return result;
  };

  const getFieldUpdateValue = (i) => {
    let result = i;
    if (isObject(i)) {
      result = i.value;
    }
    return result;
  };

  // handler
  const onModuleSelect = (e) => {
    const { value } = e.target;
    setFields((oldData) => {
      let newData = reParse(oldData);
      newData.module = value;
      return newData;
    });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setFields((oldData) => {
      let newData = reParse(oldData);
      newData[name] = value;
      return newData;
    });
  };

  const onActiveCheck = (e) => {
    const { name, checked } = e.target;
    setFields((oldData) => {
      let newData = reParse(oldData);
      newData[name] = checked ? "Y" : "N";
      return newData;
    });
  };

  const onRecordCheck = (e) => {
    const { value } = e.target;
    setFields((oldData) => {
      let newData = reParse(oldData);
      newData.record.when = value;
      return newData;
    });
  };

  const onDateTimeChange = (e) => {
    const { name, value } = e.target;
    setFields((oldData) => {
      let newData = reParse(oldData);
      if (name === "basedOnField") {
        if (["CreatedOn", "ModifiedOn"].includes(value)) {
          if (
            ["MIB", "HB", "DB", "WB", "MB", "YB"].includes(
              newData.dateTime.executionDateType
            )
          ) {
            newData.dateTime.executionDateType = "ON";
          }
          if (
            ["MIB", "HB", "DB", "WB", "MB", "YB"].includes(
              newData.dateTime.executionOnType
            )
          ) {
            newData.dateTime.executionOnType = "MIA";
          }
        }
        newData.dateTime[name] = value;
      } else if (name === "executionDateValue") {
        newData.dateTime[name] = tools.onlyFloat(value);
      } else if (name === "executionOnTime") {
        newData.dateTime[name] = tools.onlyFloat(value);
      } else {
        newData.dateTime[name] = value;
      }
      return newData;
    });

    if (name === "executionDateType") {
      onDateTimeBlur("executionDateValue", value);
      if (value !== "ON") {
        setFields((oldData) => {
          let newData = reParse(oldData);
          if (newData.dateTime.executionTimeType === "FIELD") {
            newData.dateTime.executionTimeType = "SAME";
          }
          return newData;
        });
      }
    } else if (name === "executionOnType") {
      onDateTimeBlur("executionOnTime", value);
    }
  };

  const onDateTimeBlur = (key, type) => {
    let maxAllow = abbreviation.schedule[type].max;
    setFields((oldData) => {
      let newData = reParse(oldData);
      let field = newData.dateTime[key];
      if (field <= 0 || field === "") {
        newData.dateTime[key] = 1;
      } else if (field > maxAllow) {
        newData.dateTime[key] = maxAllow;
      }
      return newData;
    });
  };

  const onDateTimeDateChange = (value) => {
    setFields((oldData) => {
      let newData = reParse(oldData);
      newData.dateTime.executionTime = value
        ? value
        : moment().format("DD/MMM/yyyy 08:00 AM");
      return newData;
    });
  };

  const onColumnSelect = (e, coKey, clKey) => {
    const { value } = e.target;
    setConditions((oldData) => {
      let newData = reParse(oldData);
      newData[coKey].column[clKey] = value;
      newData[coKey].dataType[clKey] = getOperatorType(value);
      newData[coKey].operator[clKey] = "";
      newData[coKey].inputValue1[clKey] = "";
      newData[coKey].inputValue2[clKey] = "";
      return newData;
    });
    workFlowEdit("cUpdate", coKey, clKey);
  };

  const onOperatorSelect = (e, coKey, clKey) => {
    const { value } = e.target;
    setConditions((oldData) => {
      let newData = reParse(oldData);
      newData[coKey].operator[clKey] = value;
      if (["IB", "INB"].includes(value) || value === "") {
        newData[coKey].inputValue1[clKey] = "";
        newData[coKey].inputValue2[clKey] = "";
      }
      if (!["BW", "NBW"].includes(value)) {
        newData[coKey].inputValue2[clKey] = "";
      }
      return newData;
    });
    workFlowEdit("cUpdate", coKey, clKey);
  };

  const onFieldChange = (coKey, clKey, e, input = 1) => {
    let newData = reParse(conditions);
    let field = newData[coKey].column[clKey];
    let type = columns[field].type;
    let value = "";
    if (fieldTypes.default.includes(type)) {
      value = e.target.value;
    } else if (fieldTypes.bool.includes(type)) {
      value = e.target.value;
    } else if (fieldTypes.date.includes(type)) {
      value = e.toDate();
    } else if (fieldTypes.singleSelect.includes(type)) {
      value = e ? e : "";
    } else if (fieldTypes.multipleSelect.includes(type)) {
      value = e ? e : "";
    }
    value = validateType(type, value);
    newData[coKey]["inputValue" + input][clKey] = value;
    setConditions(newData);
    workFlowEdit("cUpdate", coKey, clKey);
  };

  // validate
  const validateType = (type, value) => {
    if (type === "number" || type === "phone") {
      return tools.onlyNumbers(value);
    } else if (type === "decimal") {
      return tools.onlyFloat(value);
    } else if (type == "currency") {
      return tools.onlyFloat(value).replace(/(\d)(?=(\d{3})+\b)/g, "$1,");
    }
    return value;
  };

  const workFlowEdit = (type, coKey, clKey) => {
    if (params.ruleId) {
      let id = "";
      if (type == "cgDelete") {
        id = conditions[coKey].cgId;
      } else if (type == "cUpdate" || type == "cDelete") {
        id = conditions[coKey].cId[clKey];
      } else if (type == "eaUpdate" || type == "eaDelete") {
        id = conditions[coKey].eaId[clKey];
      } else if (type == "maUpdate" || type == "maDelete") {
        id = conditions[coKey].maId[clKey];
      } else if (type == "taUpdate" || type == "taDelete") {
        id = conditions[coKey].taId[clKey];
      } else if (type == "uaUpdate" || type == "uaDelete") {
        id = conditions[coKey].uaId[clKey];
      }
      if (id != "" && !ruleEdit[type].includes(id)) {
        setRuleEdit((oldData) => {
          let newData = reParse(oldData);
          newData[type].push(id);
          return newData;
        });
      }
    }
  };

  // email action
  const emailAction = (e, coKey, clKey) => {
    e.preventDefault();
    let fields = getStatic("emailAction");
    if (clKey !== "") {
      fields = conditions[coKey].emailAction[clKey];
    }
    setAction({ coKey, clKey, fields, type: "email" });
  };

  const onEmailAction = (data) => {
    setConditions((oldData) => {
      let newData = reParse(oldData);
      if (action.clKey === "") {
        newData[action.coKey].emailAction.push(data);
        newData[action.coKey].eaId.push("");
      } else {
        newData[action.coKey].emailAction[action.clKey] = data;
        workFlowEdit("eaUpdate", action.coKey, action.clKey);
      }
      return newData;
    });
    actionCancel();
  };

  // text action
  const textAction = (e, coKey, clKey) => {
    e.preventDefault();
    let fields = getStatic("textAction");
    if (clKey !== "") {
      fields = conditions[coKey].textAction[clKey];
    }
    setAction({ coKey, clKey, fields, type: "text" });
  };

  const onTextAction = (data) => {
    setConditions((oldData) => {
      let newData = reParse(oldData);
      if (action.clKey === "") {
        newData[action.coKey].textAction.push(data);
        newData[action.coKey].maId.push("");
      } else {
        newData[action.coKey].textAction[action.clKey] = data;
        workFlowEdit("maUpdate", action.coKey, action.clKey);
      }
      return newData;
    });
    actionCancel();
  };

  // task action
  const taskAction = (e, coKey, clKey) => {
    e.preventDefault();
    let fields = getStatic("taskAction");
    if (clKey !== "") {
      fields = conditions[coKey].taskAction[clKey];
    }
    setAction({ coKey, clKey, fields, type: "task" });
  };

  const onTaskAction = (data) => {
    setConditions((oldData) => {
      let newData = reParse(oldData);
      if (action.clKey === "") {
        newData[action.coKey].taskAction.push(data);
        newData[action.coKey].taId.push("");
      } else {
        newData[action.coKey].taskAction[action.clKey] = data;
        workFlowEdit("taUpdate", action.coKey, action.clKey);
      }
      return newData;
    });
    actionCancel();
  };

  // field update action
  const updateAction = (e, coKey, clKey) => {
    e.preventDefault();
    let fields = getStatic("fieldUpdate");
    if (clKey !== "") {
      fields = conditions[coKey].updateAction[clKey];
    }
    setAction({ coKey, clKey, fields, type: "fieldUpdate" });
  };

  const onUpdateAction = (data) => {
    setConditions((oldData) => {
      let newData = reParse(oldData);
      if (action.clKey === "") {
        newData[action.coKey].updateAction.push(data);
        newData[action.coKey].uaId.push("");
      } else {
        newData[action.coKey].updateAction[action.clKey] = data;
        workFlowEdit("uaUpdate", action.coKey, action.clKey);
      }
      return newData;
    });
    actionCancel();
  };

  // common action
  const actionRemove = (e, coKey, clKey, type) => {
    document.body.style.overflow = "visible";
    e.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1 className="alert-title">Delete Confirmation</h1>
            <div className="px-4 pt-2 pb-4">
              <p>Are you sure you want to remove this action?</p>
              <button className="automation-cancel-btn" onClick={onClose}>
                No
              </button>
              <button
                onClick={() => {
                  setConditions((oldData) => {
                    let newData = reParse(oldData);
                    newData[coKey][type].splice(clKey, 1);
                    return newData;
                  });
                  workFlowEdit(
                    type == "emailAction"
                      ? "eaDelete"
                      : type == "taskAction"
                      ? "taDelete"
                      : type == "updateAction"
                      ? "uaDelete"
                      : "maDelete",
                    coKey,
                    clKey
                  );
                  onClose();
                }}
                className="automation-footer-btn"
              >
                Yes
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const actionCancel = () => {
    setAction({
      coKey: "",
      clKey: "",
      fields: "",
      type: "",
    });
  };

  const getStatic = (type) => {
    if (type == "emailAction") {
      return {
        templateId: "",
        fromUser: getAuthInfo(),
        toUser: "",
        alterToUserField: "",
        description: "",
        scheduled: "N",
        scheduledNo: 0,
        scheduledType: "",
      };
    }
    if (type == "textAction") {
      return {
        templateId: "",
        fromUser: null,
        toUser: "",
        alterToUserField: "",
        description: "",
        scheduled: "N",
        scheduledNo: 0,
        scheduledType: "",
      };
    } else if (type == "taskAction") {
      return {
        actionName: "",
        actionSubject: "",
        description: "",
        actionModule: "",
        fromUser: getAuthInfo(),
        toUser: "",
        taskStatus: "",
        priority: "",
        startDate: "",
        endDate: "",
        scheduled: "N",
        scheduledNo: 0,
        scheduledType: "",
      };
    } else if (type == "fieldUpdate") {
      return {
        description: "",
        actionAttribute: "",
        actionUpdateValue: "",
        scheduled: "N",
        scheduledNo: 0,
        scheduledType: "",
      };
    }
  };

  // submit handler
  const onModuleSubmit = () => {
    setSubmit(true);
    let isValid = true;
    if (fields.module === "" || fields.ruleName === "") {
      isValid = false;
    }
    if (isValid) {
      if (params.ruleId) {
        setStep(2);
      } else {
        setLoading(true);
        getColumnsData();
      }
    }
  };

  const onWhenSubmit = () => {
    let errors = [];
    if (fields.execute === "record" && fields.record.when == "") {
      errors.push("Please choose when do you want to execute this rule?");
    } else if (
      fields.execute === "dateTime" &&
      fields.dateTime.basedOnField === ""
    ) {
      errors.push("Please select the Based on field");
    }
    if (errors.length > 0) {
      common.snack("E", errors[0]);
    } else {
      setFields((oldData) => {
        let newData = reParse(oldData);
        newData[fields.execute].status = "completed";
        if (fields.execute === "dateTime") {
          if (newData.dateTime.executionDateType === "ON") {
            newData.dateTime.executionDateValue = "";
          }
        }
        return newData;
      });
    }
  };

  const conditionSubmit = (coKey) => {
    const { column, operator, inputValue1, inputValue2 } = conditions[coKey];
    let errors = [];
    column.map((item, key) => {
      let inputType = getInputType(coKey, key);
      let count = key;
      ++count;
      // check column
      if (item == "") {
        errors.push(`Please select a valid field label in the ${count} row.`);
      } else if (operator[key] == "") {
        errors.push(`Please select the valid operator in the ${count} row.`);
      } else if (!inputType.disabled) {
        if (inputValue1[key] == "") {
          errors.push(
            `Please enter a valid ${columns[item].label} in the ${count} row.`
          );
        } else if (inputType.dual.count === 2 && inputValue2[key] == "") {
          errors.push(
            `Please enter a valid ${columns[item].label} in the ${count} row.`
          );
        }
      }
    });
    if (errors.length > 0) {
      common.snack("E", errors[0]);
    } else {
      setConditions((oldData) => {
        let newData = reParse(oldData);
        newData[coKey].type = "completed";
        return newData;
      });
    }
  };

  const onSubmit = () => {
    // validate all
    let errors = [];
    let errorsAction = [];
    // on record
    if (fields.execute === "record" && fields.record.when == "") {
      errors.push("Please choose when do you want to execute this rule?");
    } else if (
      fields.execute === "dateTime" &&
      fields.dateTime.basedOnField === ""
    ) {
      errors.push("Please select the Based on field");
    }
    // condition validate
    if (errors.length === 0) {
      conditions.map((item, coKey) => {
        const { column, operator, inputValue1, inputValue2 } =
          conditions[coKey];
        let coCount = coKey;
        ++coCount;
        column.map((item, clKey) => {
          let inputType = getInputType(coKey, clKey);
          let clCount = clKey;
          ++clCount;
          // check column
          if (item == "") {
            errors.push(
              `Please select a valid field label in the ${clCount} row in condition ${coCount}.`
            );
          } else if (operator[clKey] == "") {
            errors.push(
              `Please select the valid operator in the ${clCount} row in condition ${coCount}.`
            );
          } else if (!inputType.disabled) {
            if (inputValue1[clKey] == "") {
              errors.push(
                `Please enter a valid ${columns[item].label} in the ${clCount} row.`
              );
            } else if (
              inputType.dual.count === 2 &&
              inputValue2[inputType] == ""
            ) {
              errors.push(
                `Please enter a valid ${columns[item].label} in the ${clCount} row.`
              );
            }
          }
        });
        // action
        if (
          item.emailAction.length === 0 &&
          item.textAction.length === 0 &&
          item.taskAction.length === 0 &&
          item.updateAction.length === 0
        ) {
          errorsAction.push(1);
        }
      });
    }
    if (errors.length > 0) {
      common.snack("E", errors[0]);
    } else if (errorsAction.length > 0) {
      setActionModal(true);
    } else {
      rulesCrud();
    }
  };

  const rulesCrud = async () => {
    setLoading(true);
    const {
      cgDelete,
      cUpdate,
      cDelete,
      eaUpdate,
      eaDelete,
      maUpdate,
      maDelete,
      taUpdate,
      taDelete,
      uaUpdate,
      uaDelete,
    } = ruleEdit;
    if (params.ruleId) {
      let ruleBatch = [];
      // update rule
      ruleBatch.push({
        id: "rule_" + common.uniqueKey(),
        path: "/CholaAutomationRules/" + params.ruleId,
        operation: "update",
        payload: {
          RuleName: fields.ruleName,
          RuleDescription: fields.ruleDescription,
          Cattr1: serviceList[fields.module].label,
          Active: fields.active,
          ...getExecuteRule(),
        },
      });

      // console.log("ruleBatch");
      // console.log(ruleBatch);
      // return false;

      conditions.map((item, coKey) => {
        const {
          cgId,
          cId,
          eaId,
          maId,
          taId,
          uaId,
          column,
          dataType,
          operator,
          inputValue1,
          inputValue2,
          logical,
        } = item;
        let cholaCondition = [];
        let cholaAction = [];
        // conditions
        column.map((item, clKey) => {
          let ruleParams = {
            CriteriaConjunction: logical[clKey],
            CriteriaAttribute: item,
            CriteriaType: dataType[clKey].toUpperCase(),
            CriteriaOperator: operator[clKey],
            CriteriaValue: isObject(inputValue1[clKey])
              ? inputValue1[clKey]?.value
              : inputValue1[clKey],
            CriteriaValue1: inputValue2[clKey],
            TenantId: userInfo.pTenantId,
            OrgId: userInfo.pOrgId,
          };
          if (cgId) {
            if (cId[clKey] == "") {
              ruleParams.ConditionGrpId = cgId;
              ruleBatch.push({
                id: "conditionGroup_" + common.uniqueKey(),
                path: "/CholaAutomationConditions",
                operation: "create",
                payload: ruleParams,
              });
            } else if (cUpdate.includes(cId[clKey])) {
              ruleParams.ConditionId = cId[clKey];
              ruleBatch.push({
                id: "conditionGroup_" + common.uniqueKey(),
                path: "/CholaAutomationConditions/" + cId[clKey],
                operation: "update",
                payload: ruleParams,
              });
            }
          }
          cholaCondition.push(ruleParams);
        });

        // actions: email
        item.emailAction.map((item, clKey) => {
          let ruleParams = {
            ActionType: "EMAIL",
            ActionTemplateId: item.templateId.value,
            FromUser: getFromUser(item.fromUser),
            ToUser: getToUser(item.toUser),
            AlterToUserField: getAlterToUserField(item.alterToUserField),
            Description: item.description,
            ScheduledAction: item.scheduled,
            ScheduledActionNos: item.scheduled === "Y" ? item.scheduledNo : "",
            ScheduledActionType:
              item.scheduled === "Y" ? item.scheduledType : "",
            TenantId: userInfo.pTenantId,
            OrgId: userInfo.pOrgId,
          };

          if (cgId) {
            if (eaId[clKey] == "") {
              ruleParams.ConditionGrpId = cgId;
              ruleBatch.push({
                id: "action_" + common.uniqueKey(),
                path: "/CholaAutomationActions",
                operation: "create",
                payload: ruleParams,
              });
            } else if (eaUpdate.includes(eaId[clKey])) {
              ruleParams.ConditionGrpId = cgId;
              ruleParams.ActionId = eaId[clKey];
              ruleBatch.push({
                id: "action_" + common.uniqueKey(),
                path: "/CholaAutomationActions/" + eaId[clKey],
                operation: "update",
                payload: ruleParams,
              });
            }
          }
          cholaAction.push(ruleParams);
        });

        // actions: text
        item.textAction.map((item, clKey) => {
          let ruleParams = {
            ActionType: "TEXT",
            ActionTemplateId: item.templateId.value,
            FromUser: null,
            ToUserMobile: getToUser(item.toUser),
            AlterToUserMobileField: getAlterToUserField(item.alterToUserField),
            Description: item.description,
            ScheduledAction: item.scheduled,
            ScheduledActionNos: item.scheduled === "Y" ? item.scheduledNo : "",
            ScheduledActionType:
              item.scheduled === "Y" ? item.scheduledType : "",
            TenantId: userInfo.pTenantId,
            OrgId: userInfo.pOrgId,
          };

          if (cgId) {
            if (maId[clKey] == "") {
              ruleParams.ConditionGrpId = cgId;
              ruleBatch.push({
                id: "action_" + common.uniqueKey(),
                path: "/CholaAutomationActions",
                operation: "create",
                payload: ruleParams,
              });
            } else if (maUpdate.includes(maId[clKey])) {
              ruleParams.ConditionGrpId = cgId;
              ruleParams.ActionId = maId[clKey];
              ruleBatch.push({
                id: "action_" + common.uniqueKey(),
                path: "/CholaAutomationActions/" + maId[clKey],
                operation: "update",
                payload: ruleParams,
              });
            }
          }
          cholaAction.push(ruleParams);
        });

        // actions: task
        item.taskAction.map((item, clKey) => {
          let ruleParams = {
            ActionType: "TASK",
            ActionName: item.actionName,
            ActionSubject: item.actionSubject,
            Description: item.description,
            ActionStatus: item.taskStatus.value,
            Priority: item.priority.value,
            FromUser: item.fromUser.value,
            ToUser: getToUser(item.toUser),
            ActionStartDate: item.startDate,
            ActionEndDate: item.endDate,
            ActionModule: serviceList[fields.module].automationObject,
            ActionTemplateId: "",
            AlterToUserField: null,
            ScheduledAction: item.scheduled,
            ScheduledActionNos: item.scheduled === "Y" ? item.scheduledNo : "",
            ScheduledActionType:
              item.scheduled === "Y" ? item.scheduledType : "",
            TenantId: userInfo.pTenantId,
            OrgId: userInfo.pOrgId,
          };
          if (cgId) {
            if (taId[clKey] == "") {
              ruleParams.ConditionGrpId = cgId;
              ruleBatch.push({
                id: "action_" + common.uniqueKey(),
                path: "/CholaAutomationActions",
                operation: "create",
                payload: ruleParams,
              });
            } else if (taUpdate.includes(taId[clKey])) {
              ruleParams.ConditionGrpId = cgId;
              ruleParams.ActionId = taId[clKey];
              ruleBatch.push({
                id: "action_" + common.uniqueKey(),
                path: "/CholaAutomationActions/" + taId[clKey],
                operation: "update",
                payload: ruleParams,
              });
            }
          }
          cholaAction.push(ruleParams);
        });

        // actions: field update
        item.updateAction.map((item, clKey) => {
          let ruleParams = {
            ActionType: "UPDATE",
            Description: item.description,
            ActionAttribute: item.actionAttribute,
            ActionUpdateValue: getFieldUpdateValue(item.actionUpdateValue),
            ScheduledAction: item.scheduled,
            ScheduledActionNos: item.scheduled === "Y" ? item.scheduledNo : "",
            ScheduledActionType:
              item.scheduled === "Y" ? item.scheduledType : "",
            TenantId: userInfo.pTenantId,
            OrgId: userInfo.pOrgId,
          };
          if (cgId) {
            if (uaId[clKey] == "") {
              ruleParams.ConditionGrpId = cgId;
              ruleBatch.push({
                id: "action_" + common.uniqueKey(),
                path: "/CholaAutomationActions",
                operation: "create",
                payload: ruleParams,
              });
            } else if (uaUpdate.includes(uaId[clKey])) {
              ruleParams.ConditionGrpId = cgId;
              ruleParams.ActionId = uaId[clKey];
              ruleBatch.push({
                id: "action_" + common.uniqueKey(),
                path: "/CholaAutomationActions/" + uaId[clKey],
                operation: "update",
                payload: ruleParams,
              });
            }
          }
          cholaAction.push(ruleParams);
        });

        if (!cgId) {
          let conditionList = {
            RuleId: params.ruleId,
            ConditionGrpCode: `condition_grp_code_${common.uniqueKey()}`,
            ConditionGrpName: `condition_grp_name_${common.uniqueKey()}`,
            Active: "Y",
            TenantId: userInfo.pTenantId,
            OrgId: userInfo.pOrgId,
            CholaAutomationCndnView: cholaCondition,
            CholaAutomationActnView: cholaAction,
          };
          ruleBatch.push({
            id: "conditionGroup_" + common.uniqueKey(),
            path: "/CholaAutomationConditionGroups",
            operation: "create",
            payload: conditionList,
          });
        }
      });

      // rule group
      // condition group delete
      cgDelete.map((id, key) => {
        ruleBatch.push({
          id: "conditionGroup" + common.uniqueKey(),
          path: "/CholaAutomationConditionGroups/" + id,
          operation: "delete",
        });
      });
      // condition delete
      cDelete.map((id, key) => {
        ruleBatch.push({
          id: "condition" + common.uniqueKey(),
          path: "/CholaAutomationConditions/" + id,
          operation: "delete",
        });
      });

      // email action delete
      eaDelete.map((id, key) => {
        ruleBatch.push({
          id: "action" + common.uniqueKey(),
          path: "/CholaAutomationActions/" + id,
          operation: "delete",
        });
      });

      // text action delete
      maDelete.map((id, key) => {
        ruleBatch.push({
          id: "action" + common.uniqueKey(),
          path: "/CholaAutomationActions/" + id,
          operation: "delete",
        });
      });

      // task action delete
      taDelete.map((id, key) => {
        ruleBatch.push({
          id: "action" + common.uniqueKey(),
          path: "/CholaAutomationActions/" + id,
          operation: "delete",
        });
      });

      // task action delete
      uaDelete.map((id, key) => {
        ruleBatch.push({
          id: "action" + common.uniqueKey(),
          path: "/CholaAutomationActions/" + id,
          operation: "delete",
        });
      });

      // console.log(ruleBatch);
      // setLoading(false);
      // return false;

      // batch request
      if (ruleBatch != "") {
        let formAttr = { parts: ruleBatch };
        let url = encodeURI(envConfig.BASE_API);
        let data = {
          url: url,
          type: "dynamic",
          method: "POST",
          auth: "token",
          body: JSON.stringify(formAttr),
          cType: 5,
        };
        api.call(
          data,
          (response) => {
            if (response) {
              common.snack("S", "Workflow rule has been updated");
              navigate("/setting/configure/automation");
            }
            setLoading(false);
          },
          (error) => {
            common.snack("E", error);
            setLoading(false);
          },
          (final) => {}
        );
      }
    } else {
      // rule fields
      let ruleFields = {
        RuleCode: `rule_${common.uniqueKey()}`,
        RuleName: fields.ruleName,
        RuleDescription: fields.ruleDescription,
        Active: fields.active,
        Cattr1: serviceList[fields.module].label,
        RuleObject: serviceList[fields.module].automationObject,
        ...getExecuteRule(),
        TenantId: userInfo.pTenantId,
        OrgId: userInfo.pOrgId,
        CholaAutomationCndnGrpView: [],
      };

      conditions.map((item, coKey) => {
        const {
          column,
          dataType,
          operator,
          inputValue1,
          inputValue2,
          logical,
        } = conditions[coKey];
        // conditions
        let conditionList = {
          ConditionGrpCode: `condition_grp_code_${common.uniqueKey()}`,
          ConditionGrpName: `condition_grp_name_${common.uniqueKey()}`,
          Active: "Y",
          TenantId: userInfo.pTenantId,
          OrgId: userInfo.pOrgId,
          CholaAutomationCndnView: [],
          CholaAutomationActnView: [],
        };

        // columns
        column.map((item, clKey) => {
          conditionList.CholaAutomationCndnView.push({
            CriteriaConjunction: logical[clKey],
            CriteriaAttribute: item,
            CriteriaType: dataType[clKey].toUpperCase(),
            CriteriaOperator: operator[clKey].split("#")[0],
            CriteriaValue: isObject(inputValue1[clKey])
              ? inputValue1[clKey].value
              : inputValue1[clKey],
            CriteriaValue1: inputValue2[clKey],
            TenantId: userInfo.pTenantId,
            OrgId: userInfo.pOrgId,
          });
        });

        // actions: email
        item.emailAction.map((item, clKey) => {
          conditionList.CholaAutomationActnView.push({
            ActionType: "EMAIL",
            ActionTemplateId: item.templateId.value,
            FromUser: item.fromUser.value,
            ToUser: getToUser(item.toUser),
            AlterToUserField: getAlterToUserField(item.alterToUserField),
            Description: item.description,
            ScheduledAction: item.scheduled,
            ScheduledActionNos: item.scheduled === "Y" ? item.scheduledNo : "",
            ScheduledActionType:
              item.scheduled === "Y" ? item.scheduledType : "",
            TenantId: userInfo.pTenantId,
            OrgId: userInfo.pOrgId,
          });
        });

        // actions: text
        item.textAction.map((item, clKey) => {
          conditionList.CholaAutomationActnView.push({
            ActionType: "TEXT",
            ActionTemplateId: item.templateId.value,
            FromUser: null,
            ToUserMobile: getToUser(item.toUser),
            AlterToUserMobileField: getAlterToUserField(item.alterToUserField),
            Description: item.description,
            ScheduledAction: item.scheduled,
            ScheduledActionNos: item.scheduled === "Y" ? item.scheduledNo : "",
            ScheduledActionType:
              item.scheduled === "Y" ? item.scheduledType : "",
            TenantId: userInfo.pTenantId,
            OrgId: userInfo.pOrgId,
          });
        });

        // actions: task
        item.taskAction.map((item, clKey) => {
          conditionList.CholaAutomationActnView.push({
            ActionType: "TASK",
            ActionName: item.actionName,
            ActionSubject: item.actionSubject,
            Description: item.description,
            ActionStatus: item.taskStatus.value,
            Priority: item.priority.value,
            FromUser: getFromUser(item.fromUser),
            ToUser: getToUser(item.toUser),
            ActionStartDate: item.startDate,
            ActionEndDate: item.endDate,
            ActionModule: serviceList[fields.module].automationObject,
            ActionTemplateId: "",
            AlterToUserField: null,
            ScheduledAction: item.scheduled,
            ScheduledActionNos: item.scheduled === "Y" ? item.scheduledNo : "",
            ScheduledActionType:
              item.scheduled === "Y" ? item.scheduledType : "",
            TenantId: userInfo.pTenantId,
            OrgId: userInfo.pOrgId,
          });
        });

        // actions: field update
        item.updateAction.map((item, clKey) => {
          conditionList.CholaAutomationActnView.push({
            ActionType: "UPDATE",
            Description: item.description,
            ActionAttribute: item.actionAttribute,
            ActionUpdateValue: getFieldUpdateValue(item.actionUpdateValue),
            ScheduledAction: item.scheduled,
            ScheduledActionNos: item.scheduled === "Y" ? item.scheduledNo : "",
            ScheduledActionType:
              item.scheduled === "Y" ? item.scheduledType : "",
            TenantId: userInfo.pTenantId,
            OrgId: userInfo.pOrgId,
          });
        });

        // push to rules
        ruleFields.CholaAutomationCndnGrpView.push(conditionList);
      });

      // api call
      let url = envConfig.BASE_API + Services.CHOLA_AUTOMATION;
      // let data = ruleFields;

      let data = {
        url: url,
        type: "dynamic",
        method: "POST",
        auth: "token",
        body: JSON.stringify(ruleFields),
        cType: 4,
      };
      // return
      api.call(
        data,
        (response) => {
          if (response) {
            common.snack("S", "Workflow rule has been created");
            navigate("/setting/configure/automation");
          }
          setLoading(false);
        },
        (error) => {
          setLoading(false);
        },
        (final) => {}
      );
    }
  };

  // # when, conditions and actions #
  const addColumn = (e, coKey) => {
    e.preventDefault();
    setConditions((oldData) => {
      let newData = reParse(oldData);
      newData[coKey].cId.push("");
      newData[coKey].column.push("");
      newData[coKey].dataType.push("");
      newData[coKey].operator.push("");
      newData[coKey].inputValue1.push("");
      newData[coKey].inputValue2.push("");
      newData[coKey].logical.push("AND");
      return newData;
    });
  };

  const removeColumn = (e, coKey, clKey) => {
    e.preventDefault();
    setConditions((oldData) => {
      let newData = reParse(oldData);
      newData[coKey].cId.splice(clKey, 1);
      newData[coKey].column.splice(clKey, 1);
      newData[coKey].dataType.splice(clKey, 1);
      newData[coKey].operator.splice(clKey, 1);
      newData[coKey].inputValue1.splice(clKey, 1);
      newData[coKey].inputValue2.splice(clKey, 1);
      newData[coKey].logical.splice(clKey, 1);
      return newData;
    });
    workFlowEdit("cDelete", coKey, clKey);
  };

  const changeLogical = (e, coKey, clKey) => {
    e.preventDefault();
    setConditions((oldData) => {
      let newData = reParse(oldData);
      let logic = newData[coKey].logical[clKey];
      newData[coKey].logical[clKey] = logic == "AND" ? "OR" : "AND";
      return newData;
    });
    workFlowEdit("cUpdate", coKey, clKey);
  };

  const onWhenEdit = () => {
    setFields((oldData) => {
      let newData = reParse(oldData);
      newData[fields.execute].status = "edit";
      return newData;
    });
  };

  const conditionAdd = (e) => {
    e.preventDefault();
    setConditions((oldData) => {
      let newData = reParse(oldData);
      newData.push({
        // id
        cgId: "",
        cId: [""],
        eaId: [],
        maId: [],
        taId: [],
        uaId: [],
        // data
        type: "next",
        column: [""],
        dataType: [""],
        operator: [""],
        inputValue1: [""],
        inputValue2: [""],
        logical: ["AND"],
        emailAction: [],
        textAction: [],
        taskAction: [],
        updateAction: [],
      });
      return newData;
    });
  };

  const conditionEdit = (e, coKey) => {
    e.preventDefault();
    setConditions((oldData) => {
      let newData = reParse(oldData);
      newData[coKey].type = "edit";
      return newData;
    });
  };

  const conditionDelete = (e, coKey) => {
    document.body.style.overflow = "visible";
    e.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Delete Confirmation</h1>
            <p>Are you sure you want to remove this condition?</p>
            <button className="noBtn" onClick={onClose}>
              No
            </button>
            <button
              onClick={() => {
                setConditions((oldData) => {
                  let newData = reParse(oldData);
                  newData.splice(coKey, 1);
                  return newData;
                });
                workFlowEdit("cgDelete", coKey, "");
                onClose();
              }}
              className="yesBtn"
            >
              Yes
            </button>
          </div>
        );
      },
    });
  };

  const conditionCancel = (coKey) => {
    setConditions((oldData) => {
      let newData = reParse(oldData);
      newData.splice(coKey, 1);
      return newData;
    });
  };

  // support
  const isObject = (val) => {
    return typeof val === "object" && val !== null;
  };

  const reParse = (data) => {
    return JSON.parse(JSON.stringify(data));
  };

  const labelValue = (data) => {
    if (isObject(data)) {
      return data ? data.map((item) => ({ label: item, value: item })) : "";
    } else {
      return data ? { label: data, value: data } : "";
    }
  };

  const getLabel = (data) => {
    return isObject(data) ? data.label : data;
  };

  const getValue = (item, clKey) => {
    let val1 = item.inputValue1[clKey] || "";
    let val2 = item.inputValue2[clKey] || "";
    let column = columns[item.column[clKey]].type;
    if (isObject(val1)) {
      val1 = val1.label;
    }
    if (isObject(val2)) {
      val2 = val2.label;
    }
    if (column == "date") {
      val1 = val1 ? moment(val1).format("DD/MMM/yyyy") : "";
      val2 = val2 ? moment(val2).format("DD/MMM/yyyy") : "";
    } else if (column == "dateTime") {
      val1 = val1 ? tools.utcToLocal(val1) : "";
      val2 = val2 ? tools.utcToLocal(val2) : "";
    } else if (column == "checkbox") {
      val1 = val1 ? (val1 == "Y" ? "Yes" : "No") : "";
    }
    return `${val1} ${val2 ? ` to ${val2}` : ``}`;
  };

  const getRuleType = () => {
    let result = "";
    if (fields.record.when == "CREATE") {
      result = "created";
    } else if (fields.record.when == "EDIT") {
      result = "Edit";
    } else if (fields.record.when == "CREATE_EDIT") {
      result = "created or is edited";
    }
    return result;
  };

  const getOperatorType = (key) => {
    if (key) {
      return formFields[columns[key].type].autoType.toLowerCase();
    }
    return "";
  };

  const getInputType = (coKey, clKey) => {
    const { operator } = conditions[coKey];
    let result = {
      allow: operator[clKey] ? true : false,
      dual: ["BW", "NBW"].includes(operator[clKey]) ? [1, 2] : [1],
      disabled: ["IB", "INB"].includes(operator[clKey]),
    };
    return result;
  };

  const getModuleType = (type) => {
    let result = "";
    Object.entries(serviceList).map(([key, item]) => {
      if (item.automationObject === type) {
        result = key;
      }
    });
    return result;
  };

  const getAuthInfo = () => {
    return { label: authInfo.EmployeeName, value: authInfo.UserName };
  };

  const getTenantOrg = () => {
    return `TenantId=${userInfo.pTenantId}&OrgId=${userInfo.pOrgId}`;
  };

  const { ValueContainer, Placeholder } = components;

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

  // render
  return (
    <React.Fragment>
      {common.givenPermission(
        permission,
        "configure:automation",
        "basic",
        params.ruleId
      ) ? (
        <AccessDenied />
      ) : (
        <div>
          {status == "process" ? (
            // <Loader />
            <div className="automation-loader-wrap">
              <StatusBar status={status} />
            </div>
          ) : (
            <>
              {step === 1 ? (
                <Box className="template-box-border p-4">
                  <div className="row">
                    <div className="col-12">
                      <div className="card border-color-F2F9FD">
                        <div className="card-body header-border-padding p-0">
                          <Box
                            alignItems="center"
                            justifyContent="center"
                            className=""
                          >
                            <Box pt={4} pb={1}>
                              <Grid
                                container
                                spacing={3}
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Grid item className="detail-head">
                                  <Grid
                                    container
                                    spacing={4}
                                    alignItems="center"
                                    className="detail-head"
                                  >
                                    <Grid item className="detail-head">
                                      <Grid
                                        container
                                        alignItems="center"
                                        className="detail-head"
                                      >
                                        <Grid item>
                                          <Link to="/setting/configure/automation">
                                            <img
                                              alt={"back"}
                                              className="mx-2"
                                              src={common.loadImg(
                                                "iconly-light-arrow-down-circle.svg"
                                              )}
                                            />
                                          </Link>
                                        </Grid>
                                        <Grid item>
                                          <Typography className="page-title">
                                            Create New Rule
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                {/* ----------- Aumation Submit and cancel button in header ------------ */}
                                <Grid item p={0} className="safari-style">
                                  {step === 1 ? (
                                    <Button
                                      className="automation-footer-btn"
                                      onClick={onModuleSubmit}
                                      disabled={loading}
                                    >
                                      {loading ? "Processing..." : "Continue"}
                                    </Button>
                                  ) : (
                                    <Button
                                      className="automation-footer-btn"
                                      disabled={loading}
                                    >
                                      Submit
                                    </Button>
                                  )}
                                  <Link
                                    className="automation-cancel-btn"
                                    to="/setting/configure/automation"
                                  >
                                    Cancel
                                  </Link>
                                </Grid>
                              </Grid>
                            </Box>
                            <Box>
                              <Divider />
                            </Box>
                            <div className="row p-4">
                              <div className="col-lg-12 chart-wrap">
                                <Row>
                                  <Col sm={5}>
                                    <Form.Group>
                                      {/* <Form.Label>Module*</Form.Label> */}
                                      <div className="select-control mandatoryclass">
                                        <InputLabel
                                          id="select"
                                          className="floating-label automation-mui-select-label"
                                        >
                                          Module
                                        </InputLabel>
                                        <MuiSelect
                                          displayEmpty
                                          fullWidth={true}
                                          value={fields.module}
                                          onChange={onModuleSelect}
                                          disabled={
                                            params.ruleId ? true : false
                                          }
                                          className="automation-mui-select"
                                        >
                                          <MenuItem value="">None</MenuItem>
                                          {Object.keys(serviceList).map(
                                            (item, key) => {
                                              let itemInfo = serviceList[item];
                                              if (itemInfo.automation) {
                                                return (
                                                  <MenuItem
                                                    value={item}
                                                    key={key}
                                                  >
                                                    {itemInfo.label}
                                                  </MenuItem>
                                                );
                                              }
                                            }
                                          )}
                                        </MuiSelect>
                                      </div>
                                      {submit && fields.module === "" && (
                                        <Form.Text className="error error-txt">
                                          Please select the module
                                        </Form.Text>
                                      )}
                                    </Form.Group>
                                    {/* <Box className="">
                                  <div
                                    className="select-float-label"
                                  >
                                    <Select
                                      className="text-box custom-react-select"
                                      labelId="select"
                                      id="select"
                                      label="Module*"
                                      menuPortalTarget={document.body}
                                      value={fields.module}
                                      isClearable={true}
                                      options={Object.keys(serviceList).map(
                                        (item, key) => {
                                          let itemInfo = serviceList[item];
                                          if (itemInfo.automation) {
                                            return (
                                              <MenuItem value={item} key={key}>
                                                {itemInfo.label}
                                              </MenuItem>
                                            );
                                          }
                                        }
                                      )}
                                      isSearchable={false}
                                      onChange={onModuleSelect}

                                      components={{
                                        ValueContainer: CustomValueContainer,
                                      }}
                                      placeholder="Module*"
                                      styles={{
                                        container: (provided, state) => ({
                                          ...provided,
                                          marginTop: 0,
                                          
                                        }),
                                        valueContainer: (provided, state) => ({
                                          ...provided,
                                          overflow: "visible",
                                          
                                          
                                        }),
                                        placeholder: (provided, state) => ({
                                          ...provided,
                                          position: "absolute",
                                          top:
                                            state.hasValue ||
                                            state.selectProps.inputValue
                                              ? -15
                                              : "10%",
                                          transition: "top 0.1s, font-size 0.1s",
                                          fontSize:
                                            state.hasValue ||
                                            state.selectProps.inputValue
                                              ? 11
                                              : 13,
                                          color: "#9daabb",
                                          
                                        }),
                                        option:(provided, state) => ({
                                          ...provided,
                                          '&:hover':{
                                              backgroundColor: state.isFocused ? "#36ACE3" : "",
                                              color:state.isFocused ? "white" : "black",
                                          },
                                          backgroundColor: state.isSelected ? "#36ACE3" : "",
                                          
                                        }),
                                        
                                      }}
                                    ></Select>
                                  </div>
                                </Box> */}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm={5} className="common-input-style">
                                    {/* <Form.Group>
                                  <Form.Label>Rule Name*</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="ruleName"
                                    value={fields.ruleName}
                                    onChange={onChange}
                                  />
                                  {submit && fields.ruleName === "" && (
                                    <Form.Text className="error">
                                      Rule name is required
                                    </Form.Text>
                                  )}
                                </Form.Group> */}
                                    <Box
                                      className="filled-box mandatoryclass"
                                      pt={2}
                                    >
                                      <TextField
                                        id="filled-basic"
                                        label="Rule Name"
                                        name="ruleName"
                                        variant="filled"
                                        className="automation-txt-field"
                                        value={fields.ruleName}
                                        onChange={onChange}
                                      />
                                      {submit && fields.ruleName === "" && (
                                        <Form.Text className="error error-txt">
                                          Rule name is required
                                        </Form.Text>
                                      )}
                                    </Box>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col sm={5}>
                                    {/* <Box pt={2}>
                                <Form.Group>
                                  <Form.Label>Description</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="ruleDescription"
                                    value={fields.ruleDescription}
                                    onChange={onChange}
                                    maxLength={2000}
                                  />
                                </Form.Group>
                                </Box> */}
                                    <Box pt={2}>
                                      <div className="form-floating">
                                        <Form.Control
                                          as="textarea"
                                          rows={3}
                                          name="ruleDescription"
                                          value={fields.ruleDescription}
                                          onChange={onChange}
                                          maxLength={2000}
                                          className="automation-textarea"
                                        />
                                        <label
                                          htmlFor="floatingTextarea"
                                          className="floating-label"
                                        >
                                          Description
                                        </label>
                                      </div>
                                    </Box>
                                    {/* <Box pt={2}>
                                  <div className="form-floating">
                                    <textarea
                                      className="form-control textarea automation-txtarea"
                                      id="floatingTextarea"
                                      maxLength={2000}
                                      value={fields.ruleDescription}
                                      onChange={onChange}
                                    ></textarea>
                                    <label
                                      htmlFor="floatingTextarea"
                                      className="floating-label"
                                    >
                                      Description
                                    </label>
                                  </div>
                                </Box> */}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm={5}>
                                    <Form.Group className="mb-3">
                                      <FormControlLabel
                                        name="active"
                                        className="dynamic-checkbox checkbox-space"
                                        value={fields.active}
                                        control={<Checkbox color="primary" />}
                                        label={"Active"}
                                        labelPlacement="end"
                                        checked={
                                          fields.active == "Y" ? true : false
                                        }
                                        onClick={onActiveCheck}
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Box>
                        </div>
                        {/* <div className="automation-footer-wrap">
                      {step === 1 ? (
                        <Button
                          className="automation-footer-btn"
                          onClick={onModuleSubmit}
                          disabled={loading}
                        >
                          {loading ? "Processing..." : "Continue"}
                        </Button>
                      ) : ( */}
                        {/* // <button
                        //   type="submit"
                        //   className="saveBtn btn btn-primary"
                        //   onClick={onModuleSubmit}
                        //   disabled={loading}
                        // >
                        //   {loading ? "Processing..." : "Continue"}
                        // </button>
                        // <button
                        //   type="submit"
                        //   className="saveBtn btn btn-primary"
                        //   onClick={onSubmit}
                        //   disabled={loading}
                        // >
                        //   {loading ? "Processing" : "Submit"}
                        // </button> */}
                        {/* //     <Button
                    //       className="automation-footer-btn"
                    //       onClick={onSubmit}
                    //       disabled={loading}
                    //     >
                    //       {loading ? "Processing..." : "Submit"}
                    //     </Button>
                    //   )}
                    //   <Link
                    //     className="automation-cancel-btn"
                    //     to="/setting/configure/automation"
                    //   >
                    //     Cancel
                    //   </Link>
                    // </div> */}
                      </div>
                    </div>
                  </div>
                </Box>
              ) : (
                <Box className="template-box-border p-4">
                  <div className="row">
                    <div className="col-12">
                      <div className="card border-color-F2F9FD">
                        <div className="card-body header-border-padding p-0">
                          <Box
                            alignItems="center"
                            justifyContent="center"
                            className=""
                          >
                            <Box pt={4} pb={1}>
                              <Grid
                                container
                                spacing={3}
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Grid item className="detail-head">
                                  <Grid
                                    container
                                    spacing={4}
                                    alignItems="center"
                                    className="detail-head"
                                  >
                                    <Grid item className="detail-head">
                                      <Grid
                                        container
                                        alignItems="center"
                                        className="detail-head"
                                      >
                                        <Grid item>
                                          <Link to="/setting/configure/automation">
                                            <img
                                              alt={"back"}
                                              className="mx-2"
                                              src={common.loadImg(
                                                "iconly-light-arrow-down-circle.svg"
                                              )}
                                            />
                                          </Link>
                                        </Grid>
                                        <Grid item>
                                          <Typography
                                            className="page-title"
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {fields.module}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Box>
                            <Box>
                              <Divider />
                            </Box>
                            <div className="row p-4">
                              <div className="col-lg-12 chart-wrap">
                                <div className="workflow-wrap">
                                  <ul className="rules-list">
                                    <li className="clear">
                                      <div className="wfr-shape circle">
                                        <span>When</span>
                                      </div>
                                      <span
                                        className={`wfr-set-line${
                                          fields[fields.execute].status !=
                                          "next"
                                            ? " wfr-next-step"
                                            : ""
                                        }`}
                                      ></span>
                                      <div className="wfr-content-box">
                                        <div className="wfr-content mt-4">
                                          {fields[fields.execute].status ===
                                          "completed" ? (
                                            <div className="wfr-select-rule">
                                              {fields.execute === "record" ? (
                                                <p>
                                                  This rule will be executed
                                                  when a {fields.module} is{" "}
                                                  {getRuleType()} to meet the
                                                  condition (if any).
                                                </p>
                                              ) : (
                                                <Fragment>
                                                  <p className="mb-3">
                                                    {getSnippet()}
                                                  </p>
                                                  <p>
                                                    Recurrence{" "}
                                                    <strong>
                                                      {
                                                        abbreviation.recur[
                                                          fields.dateTime.recur
                                                        ]
                                                      }
                                                    </strong>
                                                  </p>
                                                </Fragment>
                                              )}
                                              <Link
                                                to="#"
                                                onClick={onWhenEdit}
                                                className="edit-icn"
                                              >
                                                {" "}
                                                <img
                                                  src={common.loadImg(
                                                    "editIconkanban.svg"
                                                  )}
                                                  alt="Edit"
                                                />
                                              </Link>
                                            </div>
                                          ) : (
                                            <div className="wfr-excute-rule">
                                              <p>
                                                When do you want to execute this
                                                rule?
                                              </p>
                                              <div className="wfr-excute-rule-option mb-3 displayFlex border-bottom">
                                                <label className="wfr-form-row mr-3">
                                                  <input
                                                    type="radio"
                                                    className="automation-radio"
                                                    checked={
                                                      fields.execute ===
                                                      "record"
                                                    }
                                                    onChange={() =>
                                                      setFields((prev) => ({
                                                        ...prev,
                                                        execute: "record",
                                                      }))
                                                    }
                                                  />
                                                  <span>
                                                    On a record action
                                                  </span>
                                                </label>
                                                <label className="wfr-form-row mr-3">
                                                  <input
                                                    type="radio"
                                                    className="automation-radio"
                                                    checked={
                                                      fields.execute ===
                                                      "dateTime"
                                                    }
                                                    onChange={() =>
                                                      setFields((prev) => ({
                                                        ...prev,
                                                        execute: "dateTime",
                                                      }))
                                                    }
                                                  />
                                                  <span>On a Date/Time</span>
                                                </label>
                                              </div>
                                              {fields.execute === "record" ? (
                                                <Fragment>
                                                  <div className="on-record-action-form">
                                                    <label className="wfr-form-row mb-3">
                                                      <input
                                                        type="radio"
                                                        className="automation-radio"
                                                        name="when"
                                                        value="CREATE"
                                                        checked={
                                                          fields.record.when ==
                                                          "CREATE"
                                                        }
                                                        onChange={onRecordCheck}
                                                      />
                                                      <span>Create</span>
                                                    </label>

                                                    <label className="wfr-form-row mb-3">
                                                      <input
                                                        type="radio"
                                                        className="automation-radio"
                                                        name="when"
                                                        value="CREATE_EDIT"
                                                        checked={
                                                          fields.record.when ==
                                                          "CREATE_EDIT"
                                                        }
                                                        onChange={onRecordCheck}
                                                      />
                                                      <span>
                                                        Create or Edit
                                                      </span>
                                                    </label>

                                                    <label className="wfr-form-row mb-3">
                                                      <input
                                                        type="radio"
                                                        className="automation-radio"
                                                        name="when"
                                                        value="EDIT"
                                                        checked={
                                                          fields.record.when ==
                                                          "EDIT"
                                                        }
                                                        onChange={onRecordCheck}
                                                      />
                                                      <span>Edit</span>
                                                    </label>
                                                  </div>
                                                </Fragment>
                                              ) : (
                                                <div className="mb-3">
                                                  <div>
                                                    <p className="wf-dt-head">
                                                      Based on which Date/Time
                                                      field this rule should
                                                      execute
                                                    </p>
                                                    <select
                                                      name="basedOnField"
                                                      className="form-control custom-react-select default-select"
                                                      value={
                                                        fields.dateTime
                                                          .basedOnField
                                                      }
                                                      onChange={
                                                        onDateTimeChange
                                                      }
                                                    >
                                                      <option value="">
                                                        None
                                                      </option>
                                                      {Object.entries(columns)
                                                        .filter(
                                                          ([key, item]) =>
                                                            item.type ===
                                                            "dateTime"
                                                        )
                                                        .map(([key, item]) => (
                                                          <option
                                                            key={key}
                                                            value={item.key}
                                                          >
                                                            {item.label}
                                                          </option>
                                                        ))}
                                                    </select>
                                                  </div>
                                                  {fields.dateTime
                                                    .basedOnField !== "" && (
                                                    <Fragment>
                                                      <div>
                                                        <p className="wf-dt-head">
                                                          Define execution date
                                                        </p>
                                                        <div className="wf-dt-exe">
                                                          <div className="row align-item-center d-flex">
                                                            {fields.dateTime
                                                              .executionDateType !==
                                                              "ON" && (
                                                              <div className="col">
                                                                <Form.Control
                                                                  type="text"
                                                                  name="executionDateValue"
                                                                  className="wf-dt-exe-text mr-2"
                                                                  maxLength={4}
                                                                  value={
                                                                    fields
                                                                      .dateTime
                                                                      .executionDateValue
                                                                  }
                                                                  onChange={
                                                                    onDateTimeChange
                                                                  }
                                                                  onBlur={() =>
                                                                    onDateTimeBlur(
                                                                      "executionDateValue",
                                                                      fields
                                                                        .dateTime
                                                                        .executionDateType
                                                                    )
                                                                  }
                                                                />
                                                              </div>
                                                            )}
                                                            <div className="col">
                                                              <select
                                                                name="executionDateType"
                                                                className="form-control wf-dt-exe-select default-select mr-2"
                                                                value={
                                                                  fields
                                                                    .dateTime
                                                                    .executionDateType
                                                                }
                                                                onChange={
                                                                  onDateTimeChange
                                                                }
                                                              >
                                                                <option value="ON">
                                                                  On
                                                                </option>
                                                                {![
                                                                  "CreatedOn",
                                                                  "ModifiedOn",
                                                                ].includes(
                                                                  fields
                                                                    .dateTime
                                                                    .basedOnField
                                                                ) && (
                                                                  <Fragment>
                                                                    <option
                                                                      value=""
                                                                      disabled={
                                                                        true
                                                                      }
                                                                    >
                                                                      ----------
                                                                    </option>
                                                                    <option value="DB">
                                                                      Day(s)
                                                                      Before
                                                                    </option>
                                                                    <option value="WB">
                                                                      Week(s)
                                                                      Before
                                                                    </option>
                                                                    <option value="MB">
                                                                      Month(s)
                                                                      Before
                                                                    </option>
                                                                    <option
                                                                      value=""
                                                                      disabled={
                                                                        true
                                                                      }
                                                                    >
                                                                      ----------
                                                                    </option>
                                                                  </Fragment>
                                                                )}
                                                                <option value="DA">
                                                                  Day(s) After
                                                                </option>
                                                                <option value="WA">
                                                                  Week(s) After
                                                                </option>
                                                                <option value="MA">
                                                                  Month(s) After
                                                                </option>
                                                              </select>
                                                            </div>
                                                            <div className="col">
                                                              <span>
                                                                the date in{" "}
                                                                {
                                                                  columns[
                                                                    fields
                                                                      .dateTime
                                                                      .basedOnField
                                                                  ].label
                                                                }{" "}
                                                                Time field
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div>
                                                        <p className="wf-dt-head">
                                                          Execution time
                                                        </p>
                                                        <div className="row mb-2">
                                                          <div className="col d-flex align-item-center col-gap">
                                                            <input
                                                              type="radio"
                                                              className="automation-radio"
                                                              name="executionTimeType"
                                                              value="SAME"
                                                              checked={
                                                                fields.dateTime
                                                                  .executionTimeType ===
                                                                "SAME"
                                                              }
                                                              onChange={
                                                                onDateTimeChange
                                                              }
                                                            />
                                                            <span className="">
                                                              Same as the time
                                                              in{" "}
                                                              {
                                                                columns[
                                                                  fields
                                                                    .dateTime
                                                                    .basedOnField
                                                                ].label
                                                              }{" "}
                                                              field
                                                            </span>
                                                          </div>
                                                        </div>

                                                        {fields.dateTime
                                                          .executionDateType ===
                                                          "ON" && (
                                                          <div className="row mb-2">
                                                            <div className="col d-flex align-item-center col-gap">
                                                              <input
                                                                type="radio"
                                                                className="automation-radio mr-2"
                                                                name="executionTimeType"
                                                                value="FIELD"
                                                                checked={
                                                                  fields
                                                                    .dateTime
                                                                    .executionTimeType ===
                                                                  "FIELD"
                                                                }
                                                                onChange={
                                                                  onDateTimeChange
                                                                }
                                                              />
                                                              <Form.Control
                                                                type="text"
                                                                name="executionOnTime"
                                                                className="wf-dt-exe-text mr-2"
                                                                maxLength={4}
                                                                value={
                                                                  fields
                                                                    .dateTime
                                                                    .executionOnTime
                                                                }
                                                                onChange={
                                                                  onDateTimeChange
                                                                }
                                                                onBlur={() =>
                                                                  onDateTimeBlur(
                                                                    "executionOnTime",
                                                                    fields
                                                                      .dateTime
                                                                      .executionOnType
                                                                  )
                                                                }
                                                              />
                                                              <select
                                                                name="executionOnType"
                                                                className="form-control wf-dt-exe-select default-select mr-2"
                                                                value={
                                                                  fields
                                                                    .dateTime
                                                                    .executionOnType
                                                                }
                                                                onChange={
                                                                  onDateTimeChange
                                                                }
                                                              >
                                                                {![
                                                                  "CreatedOn",
                                                                  "ModifiedOn",
                                                                ].includes(
                                                                  fields
                                                                    .dateTime
                                                                    .basedOnField
                                                                ) && (
                                                                  <Fragment>
                                                                    <option value="MIB">
                                                                      Minutes(s)
                                                                      Before
                                                                    </option>
                                                                    <option value="HB">
                                                                      Hour(s)
                                                                      Before
                                                                    </option>
                                                                    <option
                                                                      value=""
                                                                      disabled={
                                                                        true
                                                                      }
                                                                    >
                                                                      ----------
                                                                    </option>
                                                                  </Fragment>
                                                                )}
                                                                <option value="MIA">
                                                                  Minutes(s)
                                                                  After
                                                                </option>
                                                                <option value="HA">
                                                                  Hour(s) After
                                                                </option>
                                                              </select>
                                                              <span className="ml-3">
                                                                the time in{" "}
                                                                {
                                                                  columns[
                                                                    fields
                                                                      .dateTime
                                                                      .basedOnField
                                                                  ].label
                                                                }{" "}
                                                                field
                                                              </span>
                                                            </div>
                                                          </div>
                                                        )}
                                                        <div className="row">
                                                          <div className="col d-flex align-item-center col-gap custom-react-datepicker">
                                                            <input
                                                              type="radio"
                                                              name="executionTimeType"
                                                              className="automation-radio mr-2"
                                                              value="TIME"
                                                              checked={
                                                                fields.dateTime
                                                                  .executionTimeType ===
                                                                "TIME"
                                                              }
                                                              onChange={
                                                                onDateTimeChange
                                                              }
                                                            />
                                                            {/* <DatePicker
                                                          placeholderText="Select Time"
                                                          className="wf-dt-exe-select mr-2"
                                                          showTimeSelect
                                                          showTimeSelectOnly
                                                          timeIntervals={5}
                                                          timeCaption="Time"
                                                          dateFormat="hh:mm aa"
                                                          selected={
                                                            fields.dateTime
                                                              .executionTime
                                                              ? moment(
                                                                  fields
                                                                    .dateTime
                                                                    .executionTime
                                                                ).toDate()
                                                              : null
                                                          }
                                                          onChange={(date) =>
                                                            onDateTimeDateChange(
                                                              date
                                                            )
                                                          }
                                                        /> */}
                                                            <RcTimePicker
                                                              showSecond={false}
                                                              defaultValue={now}
                                                              className="wf-dt-exe-select"
                                                              onChange={
                                                                onChange
                                                              }
                                                              format={format}
                                                              use12Hours
                                                              inputReadOnly
                                                            />
                                                            <span className="ml-2">
                                                              Asia/Kolkata
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div>
                                                        <p className="wf-dt-head">
                                                          Recurrence
                                                        </p>
                                                        <select
                                                          name="recur"
                                                          className="form-control default-select"
                                                          value={
                                                            fields.dateTime
                                                              .recur
                                                          }
                                                          onChange={
                                                            onDateTimeChange
                                                          }
                                                        >
                                                          <option value="ONCE">
                                                            Once
                                                          </option>
                                                          <option value="EM">
                                                            Every Month
                                                          </option>
                                                          <option value="EY">
                                                            Every Year
                                                          </option>
                                                        </select>
                                                      </div>
                                                    </Fragment>
                                                  )}
                                                </div>
                                              )}
                                              <div className="wfr-btn-row">
                                                <button
                                                  className="automation-footer-btn m-0"
                                                  onClick={onWhenSubmit}
                                                >
                                                  {fields[fields.execute]
                                                    .status == "next"
                                                    ? "Next"
                                                    : "Done"}
                                                </button>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </li>

                                    {/* Conditions */}
                                    {fields[fields.execute].status != "next" &&
                                      conditions.map((item, coKey) => {
                                        let coCount = coKey;
                                        return (
                                          <li
                                            className="clear mt-5"
                                            key={coKey}
                                          >
                                            <div className="wfr-shape cube">
                                              <span>Condition {++coCount}</span>
                                            </div>
                                            <span
                                              className={`wfr-set-line cube-line${
                                                conditions.length != coCount
                                                  ? " wfr-next-step"
                                                  : ""
                                              }`}
                                            ></span>
                                            <div className="wfr-content-box">
                                              <div
                                                className={`wfr-content wfr-conditions-content cond-list`}
                                              >
                                                <div className="wfr-excute-rule">
                                                  {item.type == "completed" ? (
                                                    <div>
                                                      <ul className="conditions-list">
                                                        {item.column.map(
                                                          (cItem, clKey) => {
                                                            let clCount = clKey;
                                                            return (
                                                              <li
                                                                className="mb-3"
                                                                key={clKey}
                                                              >
                                                                <div className="row">
                                                                  <div className="col-1 align-self-center text-center">
                                                                    <span className="step-count">
                                                                      {
                                                                        ++clCount
                                                                      }
                                                                    </span>
                                                                  </div>
                                                                  <div className="col align-self-center">
                                                                    <p className="mb-0">
                                                                      {`${
                                                                        columns[
                                                                          cItem
                                                                        ].label
                                                                      } ${
                                                                        operator[
                                                                          item
                                                                            .dataType[
                                                                            clKey
                                                                          ]
                                                                        ][
                                                                          item
                                                                            .operator[
                                                                            clKey
                                                                          ]
                                                                        ]
                                                                      } ${getValue(
                                                                        item,
                                                                        clKey
                                                                      )}`}
                                                                    </p>
                                                                  </div>
                                                                </div>
                                                              </li>
                                                            );
                                                          }
                                                        )}
                                                      </ul>
                                                      <Link
                                                        to="#"
                                                        onClick={(e) =>
                                                          conditionEdit(
                                                            e,
                                                            coKey
                                                          )
                                                        }
                                                        className="edit-icn"
                                                      >
                                                        <img
                                                          src={common.loadImg(
                                                            "editIconkanban.svg"
                                                          )}
                                                          alt="Edit"
                                                        />
                                                      </Link>
                                                      {conditions.length >
                                                        1 && (
                                                        <Link
                                                          to="#"
                                                          onClick={(e) =>
                                                            conditionDelete(
                                                              e,
                                                              coKey
                                                            )
                                                          }
                                                          className="delete-icn"
                                                        >
                                                          <img
                                                            src={common.loadImg(
                                                              "delete-icn.svg"
                                                            )}
                                                            alt="Delete"
                                                          />
                                                        </Link>
                                                      )}
                                                    </div>
                                                  ) : (
                                                    <div>
                                                      <p>
                                                        Apply your conditions
                                                      </p>
                                                      <ul className="conditions-list">
                                                        {item.column.map(
                                                          (cItem, clKey) => {
                                                            let clCount = clKey;
                                                            let field = item
                                                              .column[clKey]
                                                              ? columns[
                                                                  item.column[
                                                                    clKey
                                                                  ]
                                                                ]
                                                              : "";
                                                            let value1 =
                                                              item.inputValue1[
                                                                clKey
                                                              ];
                                                            let inputType =
                                                              getInputType(
                                                                coKey,
                                                                clKey
                                                              );
                                                            return (
                                                              <li
                                                                className="mb-3"
                                                                key={clCount}
                                                              >
                                                                <div className="row">
                                                                  <div className="col-1 step-count-wrap align-self-center text-center">
                                                                    <span className="step-count">
                                                                      {
                                                                        ++clCount
                                                                      }
                                                                    </span>
                                                                    {item.column
                                                                      .length !=
                                                                      clCount && (
                                                                      <Link
                                                                        to="#"
                                                                        className="step-logical"
                                                                        onClick={(
                                                                          e
                                                                        ) =>
                                                                          changeLogical(
                                                                            e,
                                                                            coKey,
                                                                            clKey
                                                                          )
                                                                        }
                                                                      >
                                                                        {
                                                                          item
                                                                            .logical[
                                                                            clKey
                                                                          ]
                                                                        }
                                                                      </Link>
                                                                    )}
                                                                  </div>
                                                                  <div className="col condition-first-col">
                                                                    <select
                                                                      className="form-control default-select"
                                                                      value={
                                                                        item
                                                                          .column[
                                                                          clKey
                                                                        ]
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        onColumnSelect(
                                                                          e,
                                                                          coKey,
                                                                          clKey
                                                                        )
                                                                      }
                                                                    >
                                                                      <option value="">
                                                                        None
                                                                      </option>
                                                                      {Object.keys(
                                                                        columns
                                                                      ).map(
                                                                        (
                                                                          item,
                                                                          key
                                                                        ) => (
                                                                          <option
                                                                            key={
                                                                              key
                                                                            }
                                                                            value={
                                                                              item
                                                                            }
                                                                          >
                                                                            {
                                                                              columns[
                                                                                item
                                                                              ]
                                                                                .label
                                                                            }
                                                                          </option>
                                                                        )
                                                                      )}
                                                                    </select>
                                                                  </div>
                                                                  <div className="col">
                                                                    <select
                                                                      className="form-control default-select"
                                                                      value={
                                                                        item
                                                                          .operator[
                                                                          clKey
                                                                        ]
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        onOperatorSelect(
                                                                          e,
                                                                          coKey,
                                                                          clKey
                                                                        )
                                                                      }
                                                                    >
                                                                      <option value="">
                                                                        Select
                                                                        Operator
                                                                      </option>
                                                                      {item
                                                                        .dataType[
                                                                        clKey
                                                                      ]
                                                                        ? Object.keys(
                                                                            operator[
                                                                              item
                                                                                .dataType[
                                                                                clKey
                                                                              ]
                                                                            ]
                                                                          ).map(
                                                                            (
                                                                              cItem,
                                                                              cKey
                                                                            ) => (
                                                                              <option
                                                                                key={
                                                                                  cKey
                                                                                }
                                                                                value={
                                                                                  cItem
                                                                                }
                                                                              >
                                                                                {
                                                                                  operator[
                                                                                    item
                                                                                      .dataType[
                                                                                      clKey
                                                                                    ]
                                                                                  ][
                                                                                    cItem
                                                                                  ]
                                                                                }
                                                                              </option>
                                                                            )
                                                                          )
                                                                        : null}
                                                                    </select>
                                                                  </div>

                                                                  {inputType.allow ? (
                                                                    field.type ==
                                                                    "pickList" ? (
                                                                      <div className="col automation-condition-select">
                                                                        <Select
                                                                          value={
                                                                            value1
                                                                          }
                                                                          isClearable={
                                                                            true
                                                                          }
                                                                          options={labelValue(
                                                                            field
                                                                              .attr
                                                                              .option
                                                                          )}
                                                                          onChange={(
                                                                            data
                                                                          ) =>
                                                                            onFieldChange(
                                                                              coKey,
                                                                              clKey,
                                                                              data
                                                                            )
                                                                          }
                                                                          disabled={
                                                                            inputType.disabled
                                                                          }
                                                                        />
                                                                      </div>
                                                                    ) : field.type ==
                                                                      "date" ? (
                                                                      inputType.dual.map(
                                                                        (
                                                                          i,
                                                                          k
                                                                        ) => (
                                                                          <div className="col">
                                                                            <Box className="ml-1 input-date-picker">
                                                                              <DatePicker
                                                                                isClearable={
                                                                                  true
                                                                                }
                                                                                format="MMMM D, YYYY"
                                                                                render={
                                                                                  <InputIcon />
                                                                                }
                                                                                placeholder="Select Date"
                                                                                value={
                                                                                  item[
                                                                                    `inputValue${i}`
                                                                                  ][
                                                                                    clKey
                                                                                  ]
                                                                                    ? moment(
                                                                                        item[
                                                                                          `inputValue${i}`
                                                                                        ][
                                                                                          clKey
                                                                                        ]
                                                                                      ).toDate()
                                                                                    : ""
                                                                                }
                                                                                onChange={(
                                                                                  date
                                                                                ) =>
                                                                                  onFieldChange(
                                                                                    coKey,
                                                                                    clKey,
                                                                                    date,
                                                                                    i
                                                                                  )
                                                                                }
                                                                                disabled={
                                                                                  inputType.disabled
                                                                                }
                                                                              />
                                                                            </Box>
                                                                            {/* <DatePicker
                                                                          key={k}
                                                                          isClearable={true}
                                                                          peekNextMonth
                                                                          showMonthDropdown
                                                                          showYearDropdown
                                                                          dropdownMode="select"
                                                                          dateFormat="MMMM d, yyyy"
                                                                          placeholderText="Select Date"
                                                                          selected={
                                                                            item[`inputValue${i}`][clKey]
                                                                              ? moment(item[`inputValue${i}`][clKey]).toDate()
                                                                              : null
                                                                          }
                                                                          onChange={(date) => onFieldChange(coKey, clKey, date, i)}
                                                                          disabled={inputType.disabled}
                                                                        />; */}
                                                                          </div>
                                                                        )
                                                                      )
                                                                    ) : field.type ==
                                                                      "dateTime" ? (
                                                                      inputType.dual.map(
                                                                        (
                                                                          i,
                                                                          k
                                                                        ) => (
                                                                          <div
                                                                            className="col"
                                                                            key={
                                                                              k
                                                                            }
                                                                          >
                                                                            <Box className="ml-1 input-date-picker">
                                                                              <DatePicker
                                                                                isClearable={
                                                                                  true
                                                                                }
                                                                                format="MMMM D, YYYY hh:mm A"
                                                                                plugins={[
                                                                                  <TimePicker
                                                                                    hideSeconds
                                                                                    position="bottom"
                                                                                    className="ml-3"
                                                                                  />,
                                                                                ]}
                                                                                render={
                                                                                  <InputIcon />
                                                                                }
                                                                                placeholder="Select Date and Time"
                                                                                value={
                                                                                  item[
                                                                                    `inputValue${i}`
                                                                                  ][
                                                                                    clKey
                                                                                  ]
                                                                                    ? moment(
                                                                                        item[
                                                                                          `inputValue${i}`
                                                                                        ][
                                                                                          clKey
                                                                                        ]
                                                                                      ).toDate()
                                                                                    : ""
                                                                                }
                                                                                onChange={(
                                                                                  date
                                                                                ) =>
                                                                                  onFieldChange(
                                                                                    coKey,
                                                                                    clKey,
                                                                                    date,
                                                                                    i
                                                                                  )
                                                                                }
                                                                                disabled={
                                                                                  inputType.disabled
                                                                                }
                                                                              />
                                                                            </Box>
                                                                            {/* <DatePicker
                                                                          key={k}
                                                                          isClearable={true}
                                                                          peekNextMonth
                                                                          showMonthDropdown
                                                                          showYearDropdown
                                                                          dropdownMode="select"
                                                                          showTimeSelect
                                                                          timeIntervals={1}
                                                                          timeCaption="Time"
                                                                          dateFormat="MMMM d, yyyy hh:mm aa"
                                                                          placeholderText="Select Date and Time"
                                                                          selected={
                                                                            item[`inputValue${i}`][clKey]
                                                                              ? moment(item[`inputValue${i}`][clKey]).toDate()
                                                                              : null
                                                                          }
                                                                          onChange={(date) => onFieldChange(coKey, clKey, date, i)}
                                                                          disabled={inputType.disabled}
                                                                        />; */}
                                                                          </div>
                                                                        )
                                                                      )
                                                                    ) : field.type ==
                                                                      "checkbox" ? (
                                                                      <div className="col">
                                                                        <select
                                                                          className="form-control default-select"
                                                                          value={
                                                                            value1
                                                                          }
                                                                          onChange={(
                                                                            e
                                                                          ) =>
                                                                            onFieldChange(
                                                                              coKey,
                                                                              clKey,
                                                                              e
                                                                            )
                                                                          }
                                                                          disabled={
                                                                            inputType.disabled
                                                                          }
                                                                        >
                                                                          <option value="">
                                                                            None
                                                                          </option>
                                                                          <option value="Y">
                                                                            Yes
                                                                          </option>
                                                                          <option value="N">
                                                                            No
                                                                          </option>
                                                                        </select>
                                                                      </div>
                                                                    ) : field.type ==
                                                                      "lookup" ? (
                                                                      <div className="col automation-condition-select automation-padding">
                                                                        <AutomationSelect
                                                                          isRender={
                                                                            true
                                                                          }
                                                                          field={
                                                                            field
                                                                          }
                                                                          value={
                                                                            value1
                                                                          }
                                                                          onSelect={(
                                                                            data
                                                                          ) =>
                                                                            onFieldChange(
                                                                              coKey,
                                                                              clKey,
                                                                              data
                                                                            )
                                                                          }
                                                                          disabled={
                                                                            inputType.disabled
                                                                          }
                                                                        />
                                                                      </div>
                                                                    ) : (
                                                                      inputType.dual.map(
                                                                        (
                                                                          i,
                                                                          k
                                                                        ) => (
                                                                          <div
                                                                            className="col"
                                                                            key={
                                                                              k
                                                                            }
                                                                          >
                                                                            <Form.Control
                                                                              type="text"
                                                                              placeholder={`Enter ${field.label}`}
                                                                              maxLength={
                                                                                field
                                                                                  .attr
                                                                                  .maxChar
                                                                              }
                                                                              value={
                                                                                item[
                                                                                  `inputValue${i}`
                                                                                ][
                                                                                  clKey
                                                                                ]
                                                                              }
                                                                              onChange={(
                                                                                e
                                                                              ) =>
                                                                                onFieldChange(
                                                                                  coKey,
                                                                                  clKey,
                                                                                  e,
                                                                                  i
                                                                                )
                                                                              }
                                                                              disabled={
                                                                                inputType.disabled
                                                                              }
                                                                            />
                                                                          </div>
                                                                        )
                                                                      )
                                                                    )
                                                                  ) : (
                                                                    <div className="col">
                                                                      <Form.Control
                                                                        type="text"
                                                                        value=""
                                                                        disabled={
                                                                          true
                                                                        }
                                                                      />
                                                                    </div>
                                                                  )}
                                                                  <div className="col-1 plus-minus-col">
                                                                    <div className="col condition-action-wrap">
                                                                      {item
                                                                        .column
                                                                        .length ===
                                                                        1 &&
                                                                      clKey ===
                                                                        0 ? (
                                                                        <Link
                                                                          to="#"
                                                                          className="add-rule-link add-rule"
                                                                          onClick={(
                                                                            e
                                                                          ) =>
                                                                            addColumn(
                                                                              e,
                                                                              coKey
                                                                            )
                                                                          }
                                                                        >
                                                                          {" "}
                                                                          +{" "}
                                                                        </Link>
                                                                      ) : (
                                                                        clKey ===
                                                                          0 && (
                                                                          <Link
                                                                            to="#"
                                                                            className="add-rule-link remove-rule"
                                                                            onClick={(
                                                                              e
                                                                            ) =>
                                                                              removeColumn(
                                                                                e,
                                                                                coKey,
                                                                                clKey
                                                                              )
                                                                            }
                                                                          >
                                                                            {" "}
                                                                            -{" "}
                                                                          </Link>
                                                                        )
                                                                      )}
                                                                      {clKey >
                                                                        0 && (
                                                                        <React.Fragment>
                                                                          <Link
                                                                            to="#"
                                                                            className="add-rule-link remove-rule"
                                                                            onClick={(
                                                                              e
                                                                            ) =>
                                                                              removeColumn(
                                                                                e,
                                                                                coKey,
                                                                                clKey
                                                                              )
                                                                            }
                                                                          >
                                                                            {" "}
                                                                            -{" "}
                                                                          </Link>
                                                                          {item
                                                                            .column
                                                                            .length ==
                                                                            clCount && (
                                                                            <Link
                                                                              to="#"
                                                                              className="add-rule-link add-rule"
                                                                              onClick={(
                                                                                e
                                                                              ) =>
                                                                                addColumn(
                                                                                  e,
                                                                                  coKey
                                                                                )
                                                                              }
                                                                            >
                                                                              {" "}
                                                                              +{" "}
                                                                            </Link>
                                                                          )}
                                                                        </React.Fragment>
                                                                      )}
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </li>
                                                            );
                                                          }
                                                        )}
                                                      </ul>
                                                    </div>
                                                  )}
                                                  <div className="wfr-btn-row">
                                                    {coKey > 0 &&
                                                      item.type == "next" && (
                                                        <button
                                                          className="automation-cancel-btn"
                                                          onClick={() =>
                                                            conditionCancel(
                                                              coKey
                                                            )
                                                          }
                                                        >
                                                          Cancel
                                                        </button>
                                                      )}
                                                    {item.type !=
                                                      "completed" && (
                                                      <button
                                                        className="automation-footer-btn"
                                                        onClick={() =>
                                                          conditionSubmit(coKey)
                                                        }
                                                      >
                                                        {item.type == "next"
                                                          ? "Next"
                                                          : "Done"}
                                                      </button>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="clear"></div>
                                              {item.type != "next" && (
                                                <div>
                                                  {conditions.length ==
                                                    coCount && (
                                                    <Link
                                                      to="#"
                                                      className="addCondition"
                                                      onClick={(e) =>
                                                        conditionAdd(e)
                                                      }
                                                    >
                                                      Add New Condition
                                                    </Link>
                                                  )}
                                                  <div className="row action-wrap-container">
                                                    <div className="action-wrap">
                                                      <h3>Actions</h3>
                                                      <ul className="actions-list">
                                                        <li>
                                                          <p className="action-list-title">
                                                            <span className="action-head">
                                                              Email Notification
                                                            </span>{" "}
                                                            <Link
                                                              to="#"
                                                              className="add-rule-link add-plus-icon"
                                                              onClick={(e) =>
                                                                emailAction(
                                                                  e,
                                                                  coKey,
                                                                  ""
                                                                )
                                                              }
                                                            >
                                                              +
                                                            </Link>
                                                          </p>
                                                          <TableContainer>
                                                            <Table aria-label="simple table action-table">
                                                              <TableHead>
                                                                <TableRow>
                                                                  <TableCell>
                                                                    Title
                                                                  </TableCell>
                                                                  <TableCell>
                                                                    Scheduled
                                                                  </TableCell>
                                                                  <TableCell>
                                                                    Action
                                                                  </TableCell>
                                                                </TableRow>
                                                              </TableHead>
                                                              <TableBody className="action-body">
                                                                {conditions[
                                                                  coKey
                                                                ].emailAction.map(
                                                                  (
                                                                    item,
                                                                    key
                                                                  ) => (
                                                                    <TableRow
                                                                      key={key}
                                                                    >
                                                                      <TableCell>
                                                                        <Link
                                                                          to="#"
                                                                          onClick={(
                                                                            e
                                                                          ) =>
                                                                            emailAction(
                                                                              e,
                                                                              coKey,
                                                                              key
                                                                            )
                                                                          }
                                                                        >
                                                                          {
                                                                            item.description
                                                                          }
                                                                        </Link>
                                                                      </TableCell>
                                                                      <TableCell>
                                                                        <span>
                                                                          {item.scheduled ===
                                                                          "Y"
                                                                            ? `Execute ${
                                                                                item.scheduledNo
                                                                              } ${
                                                                                abbreviation
                                                                                  .schedule[
                                                                                  item
                                                                                    .scheduledType
                                                                                ]
                                                                                  .short
                                                                              } AfterRule Trigger Time`
                                                                            : "-"}
                                                                        </span>
                                                                      </TableCell>
                                                                      <TableCell>
                                                                        <Link
                                                                          to="#"
                                                                          onClick={(
                                                                            e
                                                                          ) =>
                                                                            actionRemove(
                                                                              e,
                                                                              coKey,
                                                                              key,
                                                                              "emailAction"
                                                                            )
                                                                          }
                                                                          className="action-delete-icn"
                                                                        >
                                                                          <img
                                                                            src={common.loadImg(
                                                                              "delete-icn.svg"
                                                                            )}
                                                                            alt="Delete"
                                                                          />
                                                                        </Link>
                                                                      </TableCell>
                                                                    </TableRow>
                                                                  )
                                                                )}
                                                                {conditions[
                                                                  coKey
                                                                ].emailAction
                                                                  .length ===
                                                                  0 && (
                                                                  <TableRow>
                                                                    <TableCell
                                                                      colSpan={
                                                                        3
                                                                      }
                                                                    >
                                                                      No Action
                                                                      Found
                                                                    </TableCell>
                                                                  </TableRow>
                                                                )}
                                                              </TableBody>
                                                            </Table>
                                                          </TableContainer>
                                                        </li>
                                                        <li>
                                                          <p className="action-list-title">
                                                            <span className="action-head">
                                                              Text Notification
                                                            </span>{" "}
                                                            <Link
                                                              to="#"
                                                              className="add-rule-link add-plus-icon"
                                                              onClick={(e) =>
                                                                textAction(
                                                                  e,
                                                                  coKey,
                                                                  ""
                                                                )
                                                              }
                                                            >
                                                              +
                                                            </Link>
                                                          </p>
                                                          <TableContainer>
                                                            <Table aria-label="simple table action-table">
                                                              <TableHead>
                                                                <TableRow>
                                                                  <TableCell>
                                                                    Title
                                                                  </TableCell>
                                                                  <TableCell>
                                                                    Scheduled
                                                                  </TableCell>
                                                                  <TableCell>
                                                                    Action
                                                                  </TableCell>
                                                                </TableRow>
                                                              </TableHead>
                                                              <TableBody className="action-body">
                                                                {conditions[
                                                                  coKey
                                                                ].textAction.map(
                                                                  (
                                                                    item,
                                                                    key
                                                                  ) => (
                                                                    <TableRow
                                                                      key={key}
                                                                    >
                                                                      <TableCell>
                                                                        <Link
                                                                          to="#"
                                                                          onClick={(
                                                                            e
                                                                          ) =>
                                                                            textAction(
                                                                              e,
                                                                              coKey,
                                                                              key
                                                                            )
                                                                          }
                                                                        >
                                                                          {
                                                                            item.description
                                                                          }
                                                                        </Link>
                                                                      </TableCell>
                                                                      <TableCell>
                                                                        <span>
                                                                          {item.scheduled ===
                                                                          "Y"
                                                                            ? `Execute ${
                                                                                item.scheduledNo
                                                                              } ${
                                                                                abbreviation
                                                                                  .schedule[
                                                                                  item
                                                                                    .scheduledType
                                                                                ]
                                                                                  .short
                                                                              } AfterRule Trigger Time`
                                                                            : "-"}
                                                                        </span>
                                                                      </TableCell>
                                                                      <TableCell>
                                                                        <Link
                                                                          to="#"
                                                                          onClick={(
                                                                            e
                                                                          ) =>
                                                                            actionRemove(
                                                                              e,
                                                                              coKey,
                                                                              key,
                                                                              "textAction"
                                                                            )
                                                                          }
                                                                          className="action-delete-icn"
                                                                        >
                                                                          <img
                                                                            src={common.loadImg(
                                                                              "delete-icn.svg"
                                                                            )}
                                                                            alt="Delete"
                                                                          />
                                                                        </Link>
                                                                      </TableCell>
                                                                    </TableRow>
                                                                  )
                                                                )}
                                                                {conditions[
                                                                  coKey
                                                                ].textAction
                                                                  .length ===
                                                                  0 && (
                                                                  <TableRow>
                                                                    <TableCell
                                                                      colSpan={
                                                                        3
                                                                      }
                                                                    >
                                                                      No Action
                                                                      Found
                                                                    </TableCell>
                                                                  </TableRow>
                                                                )}
                                                              </TableBody>
                                                            </Table>
                                                          </TableContainer>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                    <div className="action-wrap">
                                                      <h3>Actions</h3>
                                                      <ul className="actions-list">
                                                        <li>
                                                          <p className="action-list-title">
                                                            <span className="action-head">
                                                              Task
                                                            </span>{" "}
                                                            <Link
                                                              to="#"
                                                              className="add-rule-link add-plus-icon"
                                                              onClick={(e) =>
                                                                taskAction(
                                                                  e,
                                                                  coKey,
                                                                  ""
                                                                )
                                                              }
                                                            >
                                                              +
                                                            </Link>
                                                          </p>
                                                          <TableContainer>
                                                            <Table aria-label="simple table action-table">
                                                              <TableHead>
                                                                <TableRow>
                                                                  <TableCell>
                                                                    Title
                                                                  </TableCell>
                                                                  <TableCell>
                                                                    Scheduled
                                                                  </TableCell>
                                                                  <TableCell>
                                                                    Action
                                                                  </TableCell>
                                                                </TableRow>
                                                              </TableHead>
                                                              <TableBody className="action-body">
                                                                {conditions[
                                                                  coKey
                                                                ].taskAction.map(
                                                                  (
                                                                    item,
                                                                    key
                                                                  ) => (
                                                                    <TableRow
                                                                      key={key}
                                                                    >
                                                                      <TableCell>
                                                                        <Link
                                                                          to="#"
                                                                          onClick={(
                                                                            e
                                                                          ) =>
                                                                            taskAction(
                                                                              e,
                                                                              coKey,
                                                                              key
                                                                            )
                                                                          }
                                                                        >
                                                                          {
                                                                            item.actionName
                                                                          }
                                                                        </Link>
                                                                      </TableCell>
                                                                      <TableCell>
                                                                        <span>
                                                                          {item.scheduled ===
                                                                          "Y"
                                                                            ? `Execute ${
                                                                                item.scheduledNo
                                                                              } ${
                                                                                abbreviation
                                                                                  .schedule[
                                                                                  item
                                                                                    .scheduledType
                                                                                ]
                                                                                  .short
                                                                              } AfterRule Trigger Time`
                                                                            : "-"}
                                                                        </span>
                                                                      </TableCell>
                                                                      <TableCell>
                                                                        <Link
                                                                          to="#"
                                                                          onClick={(
                                                                            e
                                                                          ) =>
                                                                            actionRemove(
                                                                              e,
                                                                              coKey,
                                                                              key,
                                                                              "taskAction"
                                                                            )
                                                                          }
                                                                          className="action-delete-icn"
                                                                        >
                                                                          <img
                                                                            src={common.loadImg(
                                                                              "delete-icn.svg"
                                                                            )}
                                                                            alt="Delete"
                                                                          />
                                                                        </Link>
                                                                      </TableCell>
                                                                    </TableRow>
                                                                  )
                                                                )}
                                                                {conditions[
                                                                  coKey
                                                                ].taskAction
                                                                  .length ===
                                                                  0 && (
                                                                  <TableRow>
                                                                    <TableCell
                                                                      colSpan={
                                                                        3
                                                                      }
                                                                    >
                                                                      No Action
                                                                      Found
                                                                    </TableCell>
                                                                  </TableRow>
                                                                )}
                                                              </TableBody>
                                                            </Table>
                                                          </TableContainer>
                                                        </li>

                                                        <li>
                                                          <p className="action-list-title">
                                                            <span className="action-head">
                                                              Field Update
                                                            </span>{" "}
                                                            <Link
                                                              to="#"
                                                              className="add-rule-link add-plus-icon"
                                                              onClick={(e) =>
                                                                updateAction(
                                                                  e,
                                                                  coKey,
                                                                  ""
                                                                )
                                                              }
                                                            >
                                                              +
                                                            </Link>
                                                          </p>
                                                          <TableContainer>
                                                            <Table aria-label="simple table action-table">
                                                              <TableHead>
                                                                <TableRow>
                                                                  <TableCell>
                                                                    Title
                                                                  </TableCell>
                                                                  <TableCell>
                                                                    Field
                                                                  </TableCell>
                                                                  <TableCell>
                                                                    Value
                                                                  </TableCell>
                                                                  <TableCell>
                                                                    Scheduled
                                                                  </TableCell>
                                                                  <TableCell>
                                                                    Action
                                                                  </TableCell>
                                                                </TableRow>
                                                              </TableHead>
                                                              <TableBody className="action-body">
                                                                {conditions[
                                                                  coKey
                                                                ].updateAction.map(
                                                                  (
                                                                    item,
                                                                    key
                                                                  ) => (
                                                                    <TableRow
                                                                      key={key}
                                                                    >
                                                                      <TableCell>
                                                                        <Link
                                                                          to="#"
                                                                          onClick={(
                                                                            e
                                                                          ) =>
                                                                            updateAction(
                                                                              e,
                                                                              coKey,
                                                                              key
                                                                            )
                                                                          }
                                                                        >
                                                                          {
                                                                            item.description
                                                                          }
                                                                        </Link>
                                                                      </TableCell>
                                                                      <TableCell>
                                                                        {
                                                                          columns[
                                                                            item
                                                                              .actionAttribute
                                                                          ]
                                                                            .label
                                                                        }
                                                                      </TableCell>
                                                                      <TableCell>
                                                                        {getLabel(
                                                                          item.actionUpdateValue
                                                                        )}
                                                                      </TableCell>
                                                                      <TableCell>
                                                                        <span>
                                                                          {item.scheduled ===
                                                                          "Y"
                                                                            ? `Execute ${
                                                                                item.scheduledNo
                                                                              } ${
                                                                                abbreviation
                                                                                  .schedule[
                                                                                  item
                                                                                    .scheduledType
                                                                                ]
                                                                                  .short
                                                                              } AfterRule Trigger Time`
                                                                            : "-"}
                                                                        </span>
                                                                      </TableCell>
                                                                      <TableCell>
                                                                        <Link
                                                                          to="#"
                                                                          onClick={(
                                                                            e
                                                                          ) =>
                                                                            actionRemove(
                                                                              e,
                                                                              coKey,
                                                                              key,
                                                                              "updateAction"
                                                                            )
                                                                          }
                                                                          className="action-delete-icn"
                                                                        >
                                                                          <img
                                                                            src={common.loadImg(
                                                                              "delete-icn.svg"
                                                                            )}
                                                                            alt="Delete"
                                                                          />
                                                                        </Link>
                                                                      </TableCell>
                                                                    </TableRow>
                                                                  )
                                                                )}
                                                                {conditions[
                                                                  coKey
                                                                ].updateAction
                                                                  .length ===
                                                                  0 && (
                                                                  <TableRow>
                                                                    <TableCell
                                                                      colSpan={
                                                                        5
                                                                      }
                                                                    >
                                                                      No Action
                                                                      Found
                                                                    </TableCell>
                                                                  </TableRow>
                                                                )}
                                                              </TableBody>
                                                            </Table>
                                                          </TableContainer>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </li>
                                        );
                                      })}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </Box>
                        </div>
                        <div className="automation-footer-wrap">
                          {step === 1 ? (
                            // <button
                            //   type="submit"
                            //   className="saveBtn btn btn-primary"
                            //   onClick={onModuleSubmit}
                            //   disabled={loading}
                            // >
                            //   {loading ? "Processing..." : "Continue"}
                            // </button>
                            <Button
                              className="automation-footer-btn"
                              onClick={onModuleSubmit}
                              disabled={loading}
                            >
                              {loading ? "Processing..." : "Continue"}
                            </Button>
                          ) : (
                            // <button
                            //   type="submit"
                            //   className="saveBtn btn btn-primary"
                            //   onClick={onSubmit}
                            //   disabled={loading}
                            // >
                            //   {loading ? "Processing" : "Submit"}
                            // </button>
                            <Button
                              className="automation-footer-btn"
                              onClick={onSubmit}
                              disabled={loading}
                            >
                              {loading ? "Processing..." : "Submit"}
                            </Button>
                          )}
                          <Link
                            className="automation-cancel-btn"
                            to="/setting/configure/automation"
                          >
                            Cancel
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Modal className="action-req-modal" show={actionModal}>
                    <Modal.Header className="required-popup-header">
                      <Modal.Title className="notify-title">
                        Required!
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      You cannot have a Workflow condition without any action.
                      Please associate instant actions for the conditions which
                      are left without any action.
                    </Modal.Body>
                    <Modal.Footer className="jc-center">
                      <button
                        type="submit"
                        className="automation-footer-btn btn-primary"
                        onClick={() => setActionModal(false)}
                      >
                        OK, got it!
                      </button>
                    </Modal.Footer>
                  </Modal>
                  {/* All Modals */}
                  {action.type === "email" ? (
                    <EmailModal
                      module={fields.module}
                      columns={emailColumns}
                      subEMailList={subEMailList}
                      data={action.fields}
                      onSuccess={(data) => onEmailAction(data)}
                      onCancel={actionCancel}
                    />
                  ) : action.type === "text" ? (
                    <TextModal
                      module={fields.module}
                      columns={phoneColumns}
                      data={action.fields}
                      onSuccess={(data) => onTextAction(data)}
                      onCancel={actionCancel}
                    />
                  ) : action.type === "task" ? (
                    <TaskModal
                      data={action.fields}
                      onSuccess={(data) => onTaskAction(data)}
                      onCancel={actionCancel}
                    />
                  ) : (
                    action.type === "fieldUpdate" && (
                      <div>
                        <FieldUpdateModal
                          columns={columns}
                          data={action.fields}
                          onSuccess={(data) => onUpdateAction(data)}
                          onCancel={actionCancel}
                        />
                      </div>
                    )
                  )}
                </Box>
              )}
            </>
          )}
        </div>
      )}
    </React.Fragment>
  );
}

export default WorkflowCrud;
