import { common } from "helpers";
import { Container, Row, Col } from "react-bootstrap";

const StatusBar = (props) => {
    return (
        props.status == "process" ? (
        <Container
          fluid
          className="loader-wrap py-5 d-flex justify-content-center align-items-center"
        >
          <Row>
            <Col lg={12} className="text-center">
              <img
                src={common.loadImg("crm-loader_1.gif")}
                className="loader-img"
                alt="application-icn"
              />
              <h3 className="nodata-found-txt pt-2">Loading data please wait</h3>
            </Col>
          </Row>
        </Container>
      ) : props.status == "empty" ? (
        <Container
          fluid
          className="loader-wrap py-5 d-flex justify-content-center align-items-center"
        >
          <Row>
            <Col lg={12} className="text-center nodata-found-position">
              <img
                src={common.loadImg("noRecordFound.svg")}
                className="error-img"
                alt="application-icn"
              />
              <h3 className="nodata-found-txts ps-3">No Data Found!</h3>
            </Col>
          </Row>
        </Container>
      ) : (
        props.status == "error" && (
          <Container
            fluid
            className="loader-wrap py-5 d-flex justify-content-center align-items-center"
          >
            <Row>
              <Col lg={12} className="text-center nodata-found-position">
                <img
                  src={common.loadImg("noRecordFound.svg")}
                  className="error-img"
                  alt="application-icn"
                />
                <h3 className="nodata-found-txts ps-3">Something went wrong</h3>
              </Col>
            </Row>
          </Container>
        )
      )
    )
}

export default StatusBar