import React, { memo } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Divider, Hidden, IconButton, Paper, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { common } from "helpers";
import KanbanView from "elements/KanbanView";
// import OsmoTable from "elements/OsmoTable";

const CrmView = memo(() => {
  let { type } = useParams();
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const LinkTab = (props) => {
    return (
      <Tab
        component="a"
        onClick={(event) => {
          event.preventDefault();
        }}
        {...props}
      />
    );
  };

  return (
    <div className="default-bg">
      <Box
        sx={{ width: "100%", typography: "body1" }}
        className="primary-header-container table-view-width"
      >
        {false && (
          <Paper variant="container" square>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", lg: "row" },
                justifyContent: { xs: "center", lg: "space-between" },
                itemsCenter: { xs: "center" },
                py: { xs: 5, lg: 0 },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", lg: "row" },
                  justifyContent: { xs: "center", lg: "space-between" },
                  itemsCenter: { xs: "center" },
                }}
                alignItems="center"
                px={3}
                className="tabs"
              >
                <Box display="flex" alignItems="center">
                  <Box>
                    <img
                      src={common.loadImg("outlineCRM-chart.svg")}
                      alt="img"
                    />
                  </Box>
                  &nbsp;&nbsp;
                  <Typography className="header-crm">
                    CRM &nbsp;
                    <img src={common.loadImg("InfoCircle.svg")} alt="info" />
                  </Typography>
                  <Hidden smDown>
                    <Box className="vertical-divider ml-4">
                      <Divider orientation="vertical" />
                    </Box>
                  </Hidden>
                </Box>

                <TabContext value={value}>
                  <Box>
                    <TabList
                      onChange={handleChange}
                      className="ml-2 tabs-list-ph"
                      aria-label="lab API tabs example"
                    >
                      <LinkTab label="Campaigns" href="/drafts" />
                      <LinkTab label="Enquiry" href="/dashboard/index" />
                      <LinkTab label="Leads" href="/task/index" />
                      <LinkTab label="Deals" href="/dashboard/index" />
                      <LinkTab label="Quotes" href="/dashboard/index" />
                      <LinkTab label="Sales Order" href="/dashboard/index" />
                      <LinkTab label="Invoices" href="/dashboard/index" />
                      <LinkTab label="Contacts" href="/dashboard/index" />
                    </TabList>
                  </Box>
                </TabContext>
              </Box>
              <Box
                className="plus"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: { xs: "center", lg: "start" },
                }}
              >
                <Box py={1} px={4}>
                  <IconButton size="large">
                    <img src={common.loadImg("plus.svg")} alt="plus" />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Paper>
        )}
        <TabContext value={value}>
          {value === "1" && (
            <TabPanel value="1">
              {type === "kanban" ? (
                <Box px={2}>
                  <KanbanView />
                </Box>
              ) : (
                <Box width="90vw">
                  {/* <OsmoMaterialTable
                    module="lead"
                    add="Add New"
                    import="Mass Import"
                    update="Mass Update"
                    convert="Mass Convert"
                    //reload={reload}
                    sort="CreatedOn:desc"
                    data="OpportunityConverted,LeadName"
                    onAction={(data) => this.onAction(data)}
                  /> */}
                  {/* <OsmoTable /> */}
                </Box>
              )}
            </TabPanel>
          )}
        </TabContext>
        {/* <TabPanel value="1">
          {type === "kanban" ? (
            <Box px={2}>
              <KanbanView />
            </Box>
          ) : (
            <Box width="90vw">
              <OsmoMaterialTable
                module="lead"
                add="Add New"
                import="Mass Import"
                update="Mass Update"
                convert="Mass Convert"
                //reload={reload}
                sort="CreatedOn:desc"
                data="OpportunityConverted,LeadName"
                onAction={(data) => this.onAction(data)}
              />
            </Box>
          )}
        </TabPanel>
        <TabPanel value="2">Item Two</TabPanel>
        <TabPanel value="3">Item Three</TabPanel> */}
      </Box>
    </div>
  );
});

export default CrmView;
