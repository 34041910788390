import React from "react";
import Paper from "@mui/material/Paper";
import AccessDenied from "elements/AccessDenied";
import { common } from "helpers";
import { useOutletContext } from "react-router-dom";
import StaticTale from "elements/staticTable";
import automation from "assets/files/static/automation.json";


function Automation() {
  /***   for subheader fixed */
  React.useEffect(() => {
    var isMobileVersion = document.getElementsByClassName("rotate-arrow");
    if (isMobileVersion.length > 0) {
      const automationWrap = document.getElementById("automation-list-wrap");
      if(automationWrap){
        automationWrap.classList.add("sm-automation-wrap");
        automationWrap.classList.remove("lg-automation-wrap");
      }
      
    } else {
      const automationWrap = document.getElementById("automation-list-wrap");
      if(automationWrap){
        automationWrap.classList.remove("sm-automation-wrap");
        automationWrap.classList.add("lg-automation-wrap");
      }
    }
  });
  /***   for subheader fixed */
  const [permission] = useOutletContext();
  return (
    <div className="default-bg">
      <div style={{ padding: "20px" }}>
      {common.givenPermission(permission,'configure:automation','read') ? <AccessDenied /> :  <Paper
          sx={{ width: "100%", mb: 2 }}
          className={`container responsive-table p-0`}
          id="automation-list-wrap"
        >
          <StaticTale module={automation}
          add={!common.givenPermission(permission,'configure:automation','add')} 
          update={!common.givenPermission(permission,'configure:automation','update')}
          delete={!common.givenPermission(permission,'configure:automation','delete')}
          safariclassName={"safari-style"}
          />
        </Paper> }
       
      </div>
    </div>
  );
}

export default Automation;
