import React, { useState } from "react";
import { Box, Grid, Typography, Divider } from "@mui/material";
import { Link, useOutletContext } from "react-router-dom";
import { common, envConfig } from "helpers";
import TreeChart from "./treeChart";
import AccessDenied from "elements/AccessDenied";

let ModNameChange = envConfig.ModNameChange;

const MIN_ZOOM_LEVEL = 60;
const MAX_ZOOM_LEVEL = 130;
const INCREMENT_VALUE = 10;

const DepartmentList = () => {
  const [zoomLevel, setZoomLevel] = useState(100);
  const [permission] = useOutletContext();

  const handleZoomLevel = (type) => {
    if (zoomLevel > MIN_ZOOM_LEVEL && zoomLevel < MAX_ZOOM_LEVEL) {
      setZoomLevel(
        type === "up"
          ? zoomLevel + INCREMENT_VALUE
          : zoomLevel - INCREMENT_VALUE
      );
    } else if (zoomLevel === MIN_ZOOM_LEVEL && type === "up") {
      setZoomLevel(zoomLevel + INCREMENT_VALUE);
    } else if (zoomLevel === MAX_ZOOM_LEVEL && type === "down") {
      setZoomLevel(zoomLevel - INCREMENT_VALUE);
    }
  };

  return (
    <React.Fragment>
      {common.givenPermission(permission, "hrms:department", "read") ? (
        <AccessDenied />
      ) : (
        <Box className="template-box-border p-4 department-div">
          <div className="row">
            <div className="col-12">
              <div className="card border-color-F2F9FD">
                <div className="card-body header-border-padding p-0">
                  <Box alignItems="center" justifyContent="center" className="">
                    <Box pt={4} pb={1}>
                      <Grid
                        container
                        spacing={3}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item className="detail-head">
                          <Grid container spacing={4} alignItems="center" className="detail-head">
                            <Grid item  className="detail-head">
                              <Grid container alignItems="center" className="detail-head">
                                <Grid item>
                                  <Link to="/setting/view/hrms">
                                    <img
                                      alt={"back"}
                                      className="mx-2"
                                      src={common.loadImg(
                                        "iconly-light-arrow-down-circle.svg"
                                      )}
                                    />
                                  </Link>
                                </Grid>
                                <Grid item>
                                  <Typography className="page-title">
                                    {ModNameChange ? "Roles" : "Departments"}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box>
                      <Divider />
                    </Box>

                    <div className="row p-4">
                      <div className="col-lg-12 text-center chart-wrap tree-scroll-parent">
                        <div className="tree-scroll-child">
                          <div className="zoomer-wrap fixed-zoom-btn">
                            <div
                              className="zoom-in pointer"
                              onClick={() => handleZoomLevel("up")}
                            >
                              <img
                                src={common.loadImg("plus-icn.svg")}
                                alt="document"
                              />
                            </div>
                            <div
                              className="zoom-out pointer"
                              onClick={() => handleZoomLevel("down")}
                            >
                              <img
                                src={common.loadImg("minus-icn.svg")}
                                alt="document"
                              />
                            </div>
                          </div>
                          <div className="treechart-wrap">
                            <TreeChart zoomLevel={zoomLevel} />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* TODO: Temporary hide for check UI design */}
                    {/* <div className="chart-start">
                      <div className="chart-parent-wrap">
                        <div className="chart-card chart-parent">
                          <div className="add-left">
                            <IconButton className="more-icon">
                              <img
                                src={common.loadImg("plus-icn.svg")}
                                alt="add"
                                className="add-left-btn"
                              />
                            </IconButton>
                          </div>
                          <div className="add-right">
                            <IconButton className="more-icon">
                              <img
                                src={common.loadImg("plus-icn.svg")}
                                alt="add"
                                className="add-right-btn"
                              />
                            </IconButton>
                          </div>
                          <p className="title">Management</p>
                          <label className="label">Novelwall</label>
                          <div className="chart-footer">
                            <div>
                              <img
                                src={common.loadImg("users-icn.svg")}
                                alt="document"
                                className="pe-2"
                              />
                              <label className="count">06</label>
                            </div>
                            <div>
                              <IconButton
                                className="more-icon"
                                onClick={handleClickExport}
                              >
                                <MoreHorizIcon />
                              </IconButton>

                              <Popover
                                disableScrollLock
                                id={id}
                                open={openNotification}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                              >
                                <Typography>
                                  <Button
                                    variant=""
                                    sx={{ px: 2 }}
                                    className="more-options"
                                  >
                                    Option 1
                                  </Button>
                                </Typography>
                                <Typography>
                                  <Button
                                    variant=""
                                    sx={{ px: 2 }}
                                    className="more-options"
                                  >
                                    Option 2
                                  </Button>
                                </Typography>
                              </Popover>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="chart-child-wrap">
                        <div>
                          <div className="chart-child-row">
                            <div className="chart-row-col">
                              <div className="second-step-wrap">
                                <div className="chart-parent-wrap">
                                  <div className="chart-card">
                                    <div className="add-right">
                                      <IconButton className="more-icon">
                                        <img
                                          src={common.loadImg("plus-icn.svg")}
                                          alt="add"
                                          className="add-right-btn"
                                        />
                                      </IconButton>
                                    </div>
                                    <p className="title">Accounting</p>
                                    <label className="label">Novelwall</label>
                                    <div className="chart-footer">
                                      <div>
                                        <img
                                          src={common.loadImg("users-icn.svg")}
                                          alt="document"
                                          className="pe-2"
                                        />
                                        <label className="count">04</label>
                                      </div>
                                      <div>
                                        <IconButton
                                          className="more-icon"
                                          onClick={handleClickExport}
                                        >
                                          <MoreHorizIcon />
                                        </IconButton>
                                        <Popover
                                          disableScrollLock
                                          id={id}
                                          open={openNotification}
                                          anchorEl={anchorEl}
                                          onClose={handleClose}
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                          }}
                                        >
                                          <Typography>
                                            <Button
                                              variant=""
                                              sx={{ px: 2 }}
                                              className="more-options"
                                            >
                                              Option 1
                                            </Button>
                                          </Typography>
                                          <Typography>
                                            <Button
                                              variant=""
                                              sx={{ px: 2 }}
                                              className="more-options"
                                            >
                                              Option 2
                                            </Button>
                                          </Typography>
                                        </Popover>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="chart-sub-row">
                                <div className="sub-col">
                                  <div className="chart-card">
                                    <div className="add-right">
                                      <IconButton className="more-icon">
                                        <img
                                          src={common.loadImg("plus-icn.svg")}
                                          alt="add"
                                          className="add-right-btn"
                                        />
                                      </IconButton>
                                    </div>
                                    <p className="title">EDU</p>
                                    <label className="label">Novelwall</label>
                                    <div className="chart-footer">
                                      <div>
                                        <img
                                          src={common.loadImg("users-icn.svg")}
                                          alt="document"
                                          className="pe-2"
                                        />
                                        <label className="count">06</label>
                                      </div>
                                      <div>
                                        <IconButton
                                          className="more-icon"
                                          onClick={handleClickExport}
                                        >
                                          <MoreHorizIcon />
                                        </IconButton>

                                        <Popover
                                          disableScrollLock
                                          id={id}
                                          open={openNotification}
                                          anchorEl={anchorEl}
                                          onClose={handleClose}
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                          }}
                                        >
                                          <Typography>
                                            <Button
                                              variant=""
                                              sx={{ px: 2 }}
                                              className="more-options"
                                            >
                                              Option 1
                                            </Button>
                                          </Typography>
                                          <Typography>
                                            <Button
                                              variant=""
                                              sx={{ px: 2 }}
                                              className="more-options"
                                            >
                                              Option 2
                                            </Button>
                                          </Typography>
                                        </Popover>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="sub-col">
                                  <div className="chart-card group-card">
                                    <div className="add-right">
                                      <IconButton className="more-icon">
                                        <img
                                          src={common.loadImg("plus-icn.svg")}
                                          alt="add"
                                          className="add-right-btn"
                                        />
                                      </IconButton>
                                    </div>
                                    <p className="title">Group</p>
                                    <label className="label">Novelwall</label>
                                    <div className="chart-footer">
                                      <div>
                                        <img
                                          src={common.loadImg("users-icn.svg")}
                                          alt="document"
                                          className="pe-2"
                                        />
                                        <label className="count">03</label>
                                      </div>
                                      <div>
                                        <IconButton
                                          className="more-icon"
                                          onClick={handleClickExport}
                                        >
                                          <MoreHorizIcon />
                                        </IconButton>

                                        <Popover
                                         disableScrollLock
                                          id={id}
                                          open={openNotification}
                                          anchorEl={anchorEl}
                                          onClose={handleClose}
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                          }}
                                        >
                                          <Typography>
                                            <Button
                                              variant=""
                                              sx={{ px: 2 }}
                                              className="more-options"
                                            >
                                              Option 1
                                            </Button>
                                          </Typography>
                                          <Typography>
                                            <Button
                                              variant=""
                                              sx={{ px: 2 }}
                                              className="more-options"
                                            >
                                              Option 2
                                            </Button>
                                          </Typography>
                                        </Popover>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="chart-row-col">
                              <div className="second-step-wrap">
                                <div className="chart-parent-wrap">
                                  <div className="chart-card hr-card">
                                    <div className="add-right">
                                      <IconButton className="more-icon">
                                        <img
                                          src={common.loadImg("plus-icn.svg")}
                                          alt="add"
                                          className="add-right-btn"
                                        />
                                      </IconButton>
                                    </div>
                                    <p className="title">HR</p>
                                    <label className="label">Novelwall</label>
                                    <div className="chart-footer">
                                      <div>
                                        <img
                                          src={common.loadImg("users-icn.svg")}
                                          alt="document"
                                          className="pe-2"
                                        />
                                        <label className="count">02</label>
                                      </div>
                                      <div>
                                        <IconButton
                                          className="more-icon"
                                          onClick={handleClickExport}
                                        >
                                          <MoreHorizIcon />
                                        </IconButton>

                                        <Popover
                                         disableScrollLock
                                          id={id}
                                          open={openNotification}
                                          anchorEl={anchorEl}
                                          onClose={handleClose}
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                          }}
                                        >
                                          <Typography>
                                            <Button
                                              variant=""
                                              sx={{ px: 2 }}
                                              className="more-options"
                                            >
                                              Option 1
                                            </Button>
                                          </Typography>
                                          <Typography>
                                            <Button
                                              variant=""
                                              sx={{ px: 2 }}
                                              className="more-options"
                                            >
                                              Option 2
                                            </Button>
                                          </Typography>
                                        </Popover>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="chart-sub-row">
                                <div className="sub-col">
                                  <div className="chart-card resources-card">
                                    <div className="add-right">
                                      <IconButton className="more-icon">
                                        <img
                                          src={common.loadImg("plus-icn.svg")}
                                          alt="add"
                                          className="add-right-btn"
                                        />
                                      </IconButton>
                                    </div>
                                    <p className="title">Resources</p>
                                    <label className="label">Novelwall</label>
                                    <div className="chart-footer">
                                      <div>
                                        <img
                                          src={common.loadImg("users-icn.svg")}
                                          alt="document"
                                          className="pe-2"
                                        />
                                        <label className="count">06</label>
                                      </div>
                                      <div>
                                        <IconButton
                                          className="more-icon"
                                          onClick={handleClickExport}
                                        >
                                          <MoreHorizIcon />
                                        </IconButton>

                                        <Popover
                                         disableScrollLock
                                          id={id}
                                          open={openNotification}
                                          anchorEl={anchorEl}
                                          onClose={handleClose}
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                          }}
                                        >
                                          <Typography>
                                            <Button
                                              variant=""
                                              sx={{ px: 2 }}
                                              className="more-options"
                                            >
                                              Option 1
                                            </Button>
                                          </Typography>
                                          <Typography>
                                            <Button
                                              variant=""
                                              sx={{ px: 2 }}
                                              className="more-options"
                                            >
                                              Option 2
                                            </Button>
                                          </Typography>
                                        </Popover>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    {/* <div className="row p-4">
                  <div className="col-lg-12 text-center chart-wrap">
                    <TreeChart />
                  </div>
                </div> */}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </Box>
      )}
    </React.Fragment>
  );
};

export { DepartmentList };
