import {
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { common, tools } from "helpers";
import { Draggable } from "react-beautiful-dnd";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

export default function KanbanCard(props) {
  const { listItem, status, idx } = props;
  return (
    <Draggable
      key={listItem.id}
      draggableId={listItem.id.toString()}
      index={idx}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="mb-3"
        >
          <Grid item key={"_" + idx}>
            <Paper variant="outlined">
              <Box
                px={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Typography className="variant-title lineheight-title">
                    {common.textCapitalize(props.module)}
                  </Typography>
                </Box>
                <Box>
                  <IconButton className="more-icon ">
                    <MoreHorizIcon />
                  </IconButton>
                </Box>
              </Box>
              <Box>
                <Typography
                  onClick={(e) => (
                    e.preventDefault(), props.handleKanban(listItem.id)
                  )}
                  className="fb-title ms-3 mb-3"
                >
                  {listItem.name}
                </Typography>
              </Box>
              <Box className="vertical-divider-auto">
                <Divider />
              </Box>
              <Box display="flex" justifyContent="space-between" px={2} py={2}>
                <Typography className="variant-title">Created on</Typography>
                <Typography className="date-title">
                  {tools.utcToLocal(listItem.date, "ddd MMM YY")}
                </Typography>
              </Box>
              <Box className="vertical-divider-auto">
                <Divider />
              </Box>
              <Box display="flex" px={1}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Typography className="variant-title">Status</Typography>
                  </Grid>
                  <Grid item>
                    <Box
                      className="sts-card"
                      style={{ background: status.StatusColor }}
                      px={2}
                      py={0.5}
                    >
                      {status.StatusName}
                    </Box>
                  </Grid>
                </Grid>
                <Box className="vertical-divider" px={2}>
                  <Divider orientation="vertical" />
                </Box>

                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Typography className="variant-title">End Date</Typography>
                  </Grid>
                  <Grid item>
                    <Box className="date-title">
                      {tools.utcToLocal(listItem.date, "DD MM yyyy")}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </div>
      )}
    </Draggable>
  );
}
