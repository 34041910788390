import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Button,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Popover,
  Select,
} from "@mui/material";
import { common } from "helpers";

function createData(
  name,
  id,
  status,
  startDate,
  actualStartDate,
  endDate,
  activeCampaign,
  createdOn
) {
  return {
    name,
    id,
    status,
    startDate,
    actualStartDate,
    endDate,
    activeCampaign,
    createdOn,
  };
}

const rows = [
  createData(
    "Annual Cost Report",
    "X00001",
    "Complete",
    "20 Feb 2022",
    "Anjali M.",
    "20 Feb 2022",
    "20 Feb 2022",

    "20 Feb 2022"
  ),
  createData(
    "Work in progress",
    "X00001",
    "Complete",
    "20 Feb 2022",
    "Anjali M.",
    "20 Feb 2022",
    "20 Feb 2022",

    "20 Feb 2022"
  ),
  createData(
    "Team Attendence report",
    "X00001",
    "Complete",
    "20 Feb 2022",
    "Anjali M.",
    "20 Feb 2022",
    "20 Feb 2022",

    "20 Feb 2022"
  ),
  createData(
    "Travelling Report",
    "X00001",
    "Complete",
    "20 Feb 2022",
    "Anjali M.",
    "20 Feb 2022",
    "20 Feb 2022",

    "20 Feb 2022"
  ),
  createData(
    "Team Attendence report",
    "X00001",
    "Complete",
    "20 Feb 2022",
    "Anjali M.",
    "20 Feb 2022",
    "20 Feb 2022",

    "20 Feb 2022"
  ),
  createData(
    "Travelling Repor",
    "X00001",
    "Complete",
    "20 Feb 2022",
    "Anjali M.",
    "20 Feb 2022",
    "20 Feb 2022",

    "20 Feb 2022"
  ),
  createData(
    "Annual Cost Report",
    "X00001",
    "Complete",
    "20 Feb 2022",
    "Anjali M.",
    "20 Feb 2022",
    "20 Feb 2022",

    "20 Feb 2022"
  ),
  createData(
    "Annual Cost Report",
    "X00001",
    "Complete",
    "20 Feb 2022",
    "Anjali M.",
    "20 Feb 2022",
    "20 Feb 2022",

    "20 Feb 2022"
  ),
  createData(
    "Annual Cost Report",
    "X00001",
    "Complete",
    "20 Feb 2022",
    "Anjali M.",
    "20 Feb 2022",
    "20 Feb 2022",

    "20 Feb 2022"
  ),
  createData(
    "Cupcake",
    "X00001",
    "Complete",
    "20 Feb 2022",
    "Anjali M.",
    "20 Feb 2022",
    "20 Feb 2022",

    "20 Feb 2022"
  ),
  createData(
    "Cupcake",
    "X00001",
    "Complete",
    "20 Feb 2022",
    "Anjali M.",
    "20 Feb 2022",
    "20 Feb 2022",

    "20 Feb 2022"
  ),
  createData(
    "Cupcake",
    "X00001",
    "Complete",
    "20 Feb 2022",
    "Anjali M.",
    "20 Feb 2022",
    "20 Feb 2022",

    "20 Feb 2022"
  ),
  createData(
    "Cupcake",
    "X00001",
    "Complete",
    "20 Feb 2022",
    "Anjali M.",
    "20 Feb 2022",
    "20 Feb 2022",

    "20 Feb 2022"
  ),
  createData(
    "Cupcake",
    "X00001",
    "Complete",
    "20 Feb 2022",
    "Anjali M.",
    "20 Feb 2022",
    "20 Feb 2022",

    "20 Feb 2022"
  ),
  createData(
    "Cupcake",
    "X00001",
    "Complete",
    "20 Feb 2022",
    "Anjali M.",
    "20 Feb 2022",
    "20 Feb 2022",

    "20 Feb 2022"
  ),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    disablePadding: true,
    label: "Name",
  },
  {
    id: "calories",
    disablePadding: false,
    label: "ID",
  },
  {
    id: "fat",
    disablePadding: false,
    label: "Report Status",
  },
  {
    id: "carbs",
    disablePadding: false,
    label: "Description",
  },
  {
    id: "protein",
    disablePadding: false,
    label: "Created by",
  },
  {
    id: "end date",
    disablePadding: false,
    label: "Collaborators",
  },

  {
    id: "created on",
    disablePadding: false,
    label: "Created On",
  },
  {
    id: "action",
    disablePadding: false,
    label: "Action",
  },
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            className="crm-table-header"
          >
            <Grid container spacing={2}>
              {i === 0 && (
                <Grid item>
                  <input type="checkbox" className="checkbox-round-new" />
                </Grid>
              )}
              <Grid item>
                <Box>{headCell.label}</Box>
              </Grid>
            </Grid>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function TeamReports() {
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickExport = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openNotification = Boolean(anchorEl);
  const id = openNotification ? "simple-popover" : undefined;

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <Box sx={{ width: "100%" }} className="table-view-width">
      <Paper sx={{ width: "100%", mb: 2 }} className="kanban-paper-cont">
        <Box p={1} px={4}>
          <Grid
            container
            alignItems="center"
            justify="space-between"
            sx={{
              flexDirection: { xs: "column", lg: "row" },
              spacing: { xs: 3, lg: 0 },
            }}
          >
            <Grid item xs={2}>
              <Typography className="camp-title">
                Team Reports <span className="reports-count-label">04</span>
              </Typography>
            </Grid>

            <Grid item direction="row" justifyContent="center" xs={10}>
              <Grid
                container
                alignItems="center"
                justifyContent="end"
                spacing={2}
              >
                {/* <Grid item>
                  <FormControl
                    sx={{ m: 1, minWidth: { xs: 220, lg: 120 } }}
                    size="small"
                    className="dropdown"
                  >
                    <Select  displayEmpty value="10">
                      <MenuItem disabled value="" className="active-item">
                        <em className="filter-text">View all</em>
                      </MenuItem>
                      <MenuItem value={10} className="filter-text">
                        Ten
                      </MenuItem>
                      <MenuItem value={20} className="filter-text">
                        Twenty
                      </MenuItem>
                      <MenuItem value={30} className="filter-text">
                        Thirty
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid> */}
                {false && (
                  <Grid item>
                    <FormControl
                      sx={{ m: 1, minWidth: 220 }}
                      size="small"
                      className="dropdown"
                    >
                      <Select displayEmpty>
                        <MenuItem disabled value="">
                          <em className="filter-text">
                            Filter by Campaign Name
                          </em>
                        </MenuItem>
                        <MenuItem value={10} className="filter-text">
                          Ten
                        </MenuItem>
                        <MenuItem value={20} className="filter-text">
                          Twenty
                        </MenuItem>
                        <MenuItem value={30} className="filter-text">
                          Thirty
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                <Grid item>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                  >
                    <Grid item>
                      <IconButton>
                        <img
                          src={common.loadImg("documentkanban.svg")}
                          alt="document"
                        />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton>
                        <img
                          src={common.loadImg("chartKanban.svg")}
                          alt="document"
                        />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton>
                        <img
                          src={common.loadImg("filterkanban.svg")}
                          alt="document"
                        />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                  >
                    <Grid item>
                      <Box className="export">
                        <Button
                          variant="outlined"
                          className="flex align-items-center"
                          onClick={handleClickExport}
                        >
                          <Grid container alignItems="center" spacing={1}>
                            <Grid item>Action </Grid>
                            <Grid item>
                              <div className="crm-table-action">02</div>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Box className="more-icon ml-5">
                              <MoreHorizIcon />
                            </Box>
                          </Grid>
                        </Button>
                        <Popover
                          id={id}
                          open={openNotification}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          className="notify-pop"
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                        >
                          <Box className="notify-box" p={1}>
                            <Grid
                              container
                              justify="space-between"
                              alignItems="center"
                            >
                              <Grid item xs={10}>
                                <Typography
                                  component="div"
                                  className="flex align-items-center notify-title pl-2"
                                >
                                  Action &nbsp;{" "}
                                  <div className="crm-table-action">02</div>
                                </Typography>
                              </Grid>
                              <Grid item>
                                <IconButton onClick={handleClose}>
                                  <img
                                    src={common.loadImg("dateClosekanban.svg")}
                                    alt="close"
                                  />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Box>
                          <Box>
                            <Divider />
                          </Box>
                          <Box>
                            <Grid
                              container
                              direction="column"
                              className="pointer"
                            >
                              {[1, 2, 3].map((idx) => {
                                return (
                                  <Grid item key={idx}>
                                    <Box p={2} py={3}>
                                      <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        spacing={1}
                                      >
                                        <Grid item>
                                          <Typography className="task-assign ">
                                            Mass Update Selected
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                    <Box>
                                      <Divider />
                                    </Box>
                                  </Grid>
                                );
                              })}
                              <Grid item>
                                <Box p={2} py={3}>
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    spacing={1}
                                  >
                                    <Grid item>
                                      <Typography className="action-delete-txt">
                                        Mass Update Selected
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <img
                                        src={common.loadImg("actionDelete.svg")}
                                        alt="delete"
                                      />
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </Popover>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box className="export-imp">
                        <Button variant="outlined">
                          Import{" "}
                          <img
                            src={common.loadImg("exportkanban.svg")}
                            alt="document"
                            className="ml-5"
                          />
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box className="add-camp-btn">
                        <Button variant="outlined">
                          Add new Report{" "}
                          <img
                            src={common.loadImg("light-plus.svg")}
                            alt="document"
                            className="ml-5"
                          />
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <TableContainer sx={{ maxHeight: 400 }}>
          <Table
            stickyHeader
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
            className="osmo-crm-table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
            />

            <TableBody>
              {stableSort(rows, getComparator())
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        className="crm-table-name"
                      >
                        <Grid container spacing={2}>
                          <Grid item>
                            <input
                              type="checkbox"
                              className="checkbox-round-new ml-4"
                            />
                          </Grid>
                          <Grid item>
                            <Box> {row.name}</Box>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell
                        align="left"
                        className="crm-table-id cell_short"
                      >
                        {row.id}
                      </TableCell>
                      <TableCell align="left" className="cell_short">
                        <Box
                          height={20}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          className="crm-table-sts-new crm-table-sts-common"
                        >
                          {row.status}
                        </Box>
                      </TableCell>
                      <TableCell
                        align="left"
                        className=" crm-table-id cell_short"
                      >
                        {row.startDate}
                      </TableCell>
                      <TableCell
                        align="left"
                        className="crm-table-id cell_short"
                      >
                        {row.actualStartDate}
                      </TableCell>
                      <TableCell
                        align="left"
                        className="crm-table-id cell_short"
                      >
                        {row.endDate}
                      </TableCell>

                      {/* <TableCell align="center" className="cell_short">
                        <Box
                          component="span"
                          px={2}
                          className="crm-table-sts-new crm-table-sts-common"
                        >
                          {row.activeCampaign}
                        </Box>
                      </TableCell> */}
                      <TableCell
                        align="left"
                        className="crm-table-id cell_short"
                      >
                        {row.createdOn}
                      </TableCell>
                      <TableCell
                        align="center"
                        className="crm-table-id cell_short p-0"
                      >
                        <img
                          src={common.loadImg("activeForward.svg")}
                          alt="forward"
                          height={60}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
        <div className="pagination-wrap">
          <div>
            <select className="form-select row-per-page" aria-label="Default select example">
              <option selected>5/Page</option>
              <option value="10">10/Page</option>
              <option value="25">25/Page</option>
            </select>
          </div>
          <div>
            <nav aria-label="Page navigation">
              <ul className="pagination custom-pagination justify-content-end">
                <li className="page-item disabled">
                  <a
                    className="page-link"
                    href="#"
                    tabindex="-1"
                    aria-disabled="true"
                  >
                    Previous
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link active" href="#">
                    01
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    02
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    03
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    04
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    05
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    Next
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </Paper>
    </Box>
  );
}

// import * as React from 'react';
// import Paper from '@mui/material/Paper';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
// import TableRow from '@mui/material/TableRow';

// const columns = [
//   { id: 'name', label: 'Name', minWidth: 170 },
//   { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
//   {
//     id: 'population',
//     label: 'Population',
//     minWidth: 100,
//     align: 'right',
//     format: (value) => value.toLocaleString('en-US'),
//   },
//   {
//     id: 'size',
//     label: 'Size\u00a0(km\u00b2)',
//     minWidth: 100,
//     align: 'right',
//     format: (value) => value.toLocaleString('en-US'),
//   },
//   {
//     id: 'density',
//     label: 'Density',
//     minWidth: 100,
//     align: 'right',
//     format: (value) => value.toFixed(2),
//   },
// ];

// function createData(
//   name,
//   code,
//   population,
//   size,
// ) {
//   const density = population / size;
//   return { name, code, population, size, density };
// }

// const rows = [
//   createData('India', 'IN', 1324171354, 3287263),
//   createData('China', 'CN', 1403500365, 9596961),
//   createData('Italy', 'IT', 60483973, 301340),
//   createData('United States', 'US', 327167434, 9833520),
//   createData('Canada', 'CA', 37602103, 9984670),
//   createData('Australia', 'AU', 25475400, 7692024),
//   createData('Germany', 'DE', 83019200, 357578),
//   createData('Ireland', 'IE', 4857000, 70273),
//   createData('Mexico', 'MX', 126577691, 1972550),
//   createData('Japan', 'JP', 126317000, 377973),
//   createData('France', 'FR', 67022000, 640679),
//   createData('United Kingdom', 'GB', 67545757, 242495),
//   createData('Russia', 'RU', 146793744, 17098246),
//   createData('Nigeria', 'NG', 200962417, 923768),
//   createData('Brazil', 'BR', 210147125, 8515767),
// ];

// export default function OsmoTable() {
//   const [page, setPage] = React.useState(0);
//   const [rowsPerPage, setRowsPerPage] = React.useState(10);

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(+event.target.value);
//     setPage(0);
//   };

//   return (
//     <Paper sx={{ width: '100%', overflow: 'hidden' }}>
//       <TableContainer sx={{ maxHeight: 440 }}>
//         <Table stickyHeader aria-label="sticky table">
//           <TableHead>
//             <TableRow>
//               {columns.map((column) => (
//                 <TableCell
//                   key={column.id}
//                   align={column.align}
//                   style={{ minWidth: column.minWidth }}
//                 >
//                   {column.label}
//                 </TableCell>
//               ))}
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {rows
//               .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//               .map((row) => {
//                 return (
//                   <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
//                     {columns.map((column) => {
//                       const value = row[column.id];
//                       return (
//                         <TableCell key={column.id} align={column.align}>
//                           {column.format && typeof value === 'number'
//                             ? column.format(value)
//                             : value}
//                         </TableCell>
//                       );
//                     })}
//                   </TableRow>
//                 );
//               })}
//           </TableBody>
//         </Table>
//       </TableContainer>
//       <TablePagination
//         rowsPerPageOptions={[10, 25, 100]}
//         component="div"
//         count={rows.length}
//         rowsPerPage={rowsPerPage}
//         page={page}
//         onPageChange={handleChangePage}
//         onRowsPerPageChange={handleChangeRowsPerPage}
//       />
//     </Paper>
//   );
// }
//
