import React, { memo, useRef, useState } from "react";

import { common } from "helpers";
import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import {
  Divider,
  FormControl,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import Popover from "@mui/material/Popover";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";

const ActiveSwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 32,
  padding: 6,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 2,
    transform: "translateX(0px)",
    "&.Mui-checked": {
      color: "#29C745 !important",

      transform: "translateX(30px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 24 24" width="20"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,

        backgroundColor: "#f2f9fd",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    width: 25,
    height: 25,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",

      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 24 24" width="20"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/><path d="M0 0h24v24H0z" fill="none"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,

    backgroundColor: "#f2f9fd",
    borderRadius: 20 / 2,
  },
}));

const Role = memo(() => {
  const [rightAnchorEl, setRightAnchorEl] = React.useState(null);

  const detailsMoreClick = (event) => {
    setRightAnchorEl(event.currentTarget);
  };
  const detailsCloseClick = () => {
    setRightAnchorEl(null);
  };

  const rightMoreOpen = Boolean(rightAnchorEl);
  function createData(
    name,
    toread,
    toadd,
    toupdate,
    todelete,
    toimport,
    toexport
  ) {
    return { name, toread, toadd, toupdate, todelete, toimport, toexport };
  }

  const rows = [
    createData(
      "Campaigns",
      "yes-icon.png",
      "no-icon.svg",
      "yes-icon.png",
      "no-icon.svg",
      "no-icon.svg",
      "yes-icon.png"
    ),
    createData(
      "Enquiries",
      "yes-icon.png",
      "yes-icon.png",
      "yes-icon.png",
      "yes-icon.png",
      "yes-icon.png",
      "no-icon.svg"
    ),
    createData(
      "Lead",
      "yes-icon.png",
      "no-icon.svg",
      "yes-icon.png",
      "no-icon.svg",
      "no-icon.svg",
      "yes-icon.png"
    ),
    createData(
      "Enquiries",
      "yes-icon.png",
      "yes-icon.png",
      "yes-icon.png",
      "yes-icon.png",
      "yes-icon.png",
      "no-icon.svg"
    ),
  ];
  return (
    <Box px={5} mt={3}>
      <Grid container spacing={3}>
        <Grid item xs={4} className="mt-2">
          <div className="card border-color-F2F9FD left-side-card no-border">
            <div className="card-body p-0" position="static">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                className="py-3 px-3"
              >
                <div className="col-md-7">
                  <h5 className="d-flex align-items-center title mb-0">
                    <Link
                      to="/setting/view/configure"
                      className="text-decoration-none"
                    >
                      <img
                        alt={"back"}
                        className="mx-1"
                        src={common.loadImg("backArrow-.png")}
                        width={30}
                        height={30}
                      />
                    </Link>
                    <label className="d-flex align-items-center page-title">
                      Roles{" "}
                    </label>
                  </h5>
                </div>
                <div className="d-flex align-items-center">
                  <Link to="/setting/customer" className="text-decoration-none">
                    <span className="mx-2 add-new">Add New</span>
                  </Link>

                  <div className="add-new-button d-flex align-items-center justify-content-center">
                    <Link to="#" className="text-decoration-none">
                      <span className="d-flex align-items-center plus-icon">
                        +
                      </span>
                    </Link>
                  </div>
                </div>
              </Box>
              <div className="d-flex align-items-center bg-F2F9FD p-1">
                <Box className="filter-search" width="100%">
                  <FormControl sx={{ width: "100%" }}>
                    <OutlinedInput
                      id="role-search"
                      placeholder={"Search"}
                      endAdornment={
                        <InputAdornment position="end">
                          <img
                            src={common.loadImg("common-search.svg")}
                            alt="search"
                          />
                        </InputAdornment>
                      }
                      aria-describedby="outlined-weight-helper-text"
                    />
                  </FormControl>
                </Box>
              </div>

              <div className="bg-white location-user-list">
                {/* Profile */}
                <Grid container direction="column">
                  {[1, 2, 3].map((_, i) => {
                    return (
                      <Grid item>
                        <Box>
                          <Grid container alignItems="center">
                            <Grid item xs={2} className="pl-2">
                              <Box px={1} py={2}>
                                <Box className="img-box">AB</Box>
                              </Box>
                              {i === 0 ? (
                                <div className="active active-location"></div>
                              ) : null}
                            </Grid>
                            <Grid item xs={1}>
                              <Box display="flex" justifyContent="center">
                                <Box height="85px" width="2px" pl={3}>
                                  <Divider orientation="vertical" />
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={9}>
                              <Box py={0.6} px={2}>
                                <Grid
                                  container
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Grid item>
                                    <div className="mt-2 font-weight-bold">
                                      <label className="setting-card-title fz-16 font-weight-bold">
                                        Adam Brody
                                      </label>
                                      <p className="setting-card-content fz-14">
                                        Chief Design Officer
                                      </p>
                                    </div>
                                  </Grid>
                                  <div className="my-auto pe-4 ">
                                    <MoreHorizOutlinedIcon
                                      className="pointer"
                                      aria-describedby={"right-more-popover"}
                                      onClick={detailsMoreClick}
                                    />

                                    <Popover
                                      id={"right-more-popover"}
                                      open={rightMoreOpen}
                                      anchorEl={rightAnchorEl}
                                      onClose={detailsCloseClick}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      {["menu 1", "menu 2"].map((x) => (
                                        <Typography
                                          key={x}
                                          sx={{ p: 2 }}
                                          className="text-capitalize"
                                        >
                                          {x}
                                        </Typography>
                                      ))}
                                    </Popover>
                                  </div>
                                </Grid>
                              </Box>
                            </Grid>
                          </Grid>
                          <Box>
                            <Divider />
                          </Box>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={8} className="mt-2">
          <Box>
            <div className="role-border">
              <div className="permission-table">
                <div className="d-flex justify-content-between bottom-border-divider">
                  <h5 className="right-section-title my-3 ms-3">
                    Permissions{" "}
                  </h5>
                  <div className="my-auto pe-4 ">
                    <MoreHorizOutlinedIcon
                      className="pointer"
                      aria-describedby={"right-more-popover"}
                      onClick={detailsMoreClick}
                    />

                    <Popover
                      id={"right-more-popover"}
                      open={rightMoreOpen}
                      anchorEl={rightAnchorEl}
                      onClose={detailsCloseClick}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      {["menu 1", "menu 2"].map((x) => (
                        <Typography
                          key={x}
                          sx={{ p: 2 }}
                          className="text-capitalize"
                        >
                          {x}
                        </Typography>
                      ))}
                    </Popover>
                  </div>
                </div>
              </div>

              <TableContainer component={Paper} className="role-table">
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Module</TableCell>
                      <TableCell align="center">To Read</TableCell>
                      <TableCell align="center">To Add</TableCell>
                      <TableCell align="center">To Update</TableCell>
                      <TableCell align="center">To Delete</TableCell>
                      <TableCell align="center">To Import</TableCell>
                      <TableCell align="center">To Export</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow className="module-head">
                      <TableCell colSpan={7}>
                        <img
                          src={common.loadImg("minus-icon.svg")}
                          className="me-2"
                        />
                        CRM
                      </TableCell>
                    </TableRow>
                    {rows.map((row) => (
                      <TableRow key={row.name} sx={{ border: 0 }}>
                        <TableCell
                          component="th"
                          scope="row"
                          className="module-name"
                        >
                          {row.name}
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          <ActiveSwitch className="active-not" defaultChecked />
                        </TableCell>
                        <TableCell align="center">
                          <ActiveSwitch className="active-not" defaultChecked />
                        </TableCell>
                        <TableCell align="center">
                          <ActiveSwitch className="active-not" defaultChecked />
                        </TableCell>
                        <TableCell align="center">
                          <ActiveSwitch className="active-not" defaultChecked />
                        </TableCell>
                        <TableCell align="center">
                          <ActiveSwitch className="active-not" defaultChecked />
                        </TableCell>
                        <TableCell align="center">
                          {/* <ActiveSwitch className="active-not" defaultChecked /> */}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow className="module-title">
                      <TableCell colSpan={7}>
                        <img
                          src={common.loadImg("plus-icon.svg")}
                          className="me-2"
                        />
                        Configure
                      </TableCell>
                    </TableRow>
                    <TableRow className="module-title">
                      <TableCell colSpan={7}>
                        <img
                          src={common.loadImg("plus-icon.svg")}
                          className="me-2"
                        />
                        HRMS
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
});

export default Role;
