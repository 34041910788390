import React, { memo, useRef, useState } from "react";

import { common } from "helpers";
import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import {
  Divider,
  FormControl,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import Popover from "@mui/material/Popover";

const RolesResponsiblity = memo(() => {
  const [value, setValue] = React.useState(0);
  const [rightAnchorEl, setRightAnchorEl] = React.useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const detailsMoreClick = (event) => {
    setRightAnchorEl(event.currentTarget);
  };
  const detailsCloseClick = () => {
    setRightAnchorEl(null);
  };

  const rightMoreOpen = Boolean(rightAnchorEl);
  function createData(
    responsibility,
    area,
    shift,
    carry,
    report,
    duration,
    action
  ) {
    return { responsibility, area, shift, carry, report, duration, action };
  }

  const rows = [
    createData(
      "Business Development Manage",
      "Colombo",
      "Day",
      "Yes",
      "Daily",
      "Annual Days",
      "extra-dots.svg"
    ),
    createData(
      "Marketing",
      "HQ",
      "Night",
      "Yes",
      "Every other day",
      "2 Years",
      "extra-dots.svg"
    ),
    createData(
      "Sales and Advertising",
      "London",
      "Day",
      "Yes",
      "Weekdays",
      "N/A",
      "extra-dots.svg"
    ),
  ];
  return (
    <Box px={5} mt={3}>
      <Grid container spacing={3}>
        <Grid item xs={4} className="mt-2">
          <div className="card border-color-F2F9FD sidebar-card no-border">
            <div className="card-body p-0" position="static">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                className="py-3 px-3"
              >
                <div className="col-md-7">
                  <h5 className="d-flex align-items-center title mb-0">
                    <Link
                      to="/setting/view/hrms"
                      className="text-decoration-none"
                    >
                      <img
                        alt={"back"}
                        className="mx-1"
                        src={common.loadImg(
                          "iconly-light-arrow-down-circle.svg"
                        )}
                        width={30}
                        height={30}
                      />
                    </Link>
                    <label className="d-flex align-items-center page-title">
                      Roles{" "}
                    </label>
                  </h5>
                </div>
                <div className="d-flex align-items-center">
                  <Link to="/setting/customer" className="text-decoration-none">
                    <span className="mx-2 add-new">Add New</span>
                  </Link>

                  <div className="add-new-button d-flex align-items-center justify-content-center">
                    <Link
                      to="/setting/customer"
                      className="text-decoration-none"
                    >
                      <span className="d-flex align-items-center plus-icon">
                        +
                      </span>
                    </Link>
                  </div>
                </div>
              </Box>
              <div className="d-flex align-items-center bg-F2F9FD p-1">
                <Box className="filter-search" width="100%">
                  <FormControl sx={{ width: "100%" }}>
                    <OutlinedInput
                      id="role-respond-search"
                      placeholder={"Search"}
                      endAdornment={
                        <InputAdornment position="end">
                          <img
                            src={common.loadImg("common-search.svg")}
                            alt="search"
                          />
                        </InputAdornment>
                      }
                      aria-describedby="outlined-weight-helper-text"
                    />
                  </FormControl>
                </Box>
              </div>

              <div className="bg-white sidebar-height">
                <Grid container direction="column">
                  <Grid item xs={12} className="pl-4 py-4">
                    <Box px={2} py={2} className="sidebar-heading active-title">
                      Front Line Executives
                      <div className="active-role"></div>
                    </Box>
                  </Grid>
                  <Grid item xs={12} className="pl-4 py-4 ">
                    <Box px={2} py={2} className="sidebar-heading">
                      Top Level Management
                    </Box>
                  </Grid>
                  <Grid item xs={12} className="pl-4 ">
                    <Box px={2} py={2} className="sidebar-heading">
                      Middle Level Management
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={8} className="mt-2">
          <Box>
            <div className="role-border permission-table">
              <div className="d-flex justify-content-between bottom-border-divider">
                <h5 className="right-section-title my-3 ms-3 col-md-6">
                  Responsibilities{" "}
                </h5>
                <div className="col-md-6 d-flex">
                  <div className="col-md-6 me-4 d-flex align-self-center filter-roles">
                    <FormControl fullWidth>
                      <InputLabel id="filter-roles-label">
                        View all
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="filter-roles-select"
                        label="View all"
                      ></Select>
                    </FormControl>
                  </div>
                  <div className="col-md-6 ps-3 d-flex align-items-center left-border">
                    <Link
                      to="/setting/customer"
                      className="text-decoration-none"
                    >
                      <span className="mx-2 add-new">Add New</span>
                    </Link>

                    <div className="add-new-button d-flex align-items-center justify-content-center">
                      <Link
                        to="/setting/customer"
                        className="text-decoration-none"
                      >
                        <span className="d-flex align-items-center plus-icon">
                          +
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <TableContainer component={Paper} className="role-table role-response-table">
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className="respond-width">Responsibility</TableCell>
                      <TableCell>Area</TableCell>
                      <TableCell>Shift</TableCell>
                      <TableCell className="carry-width">Carrry Forward</TableCell>
                      <TableCell className="report-width">Reporting Frequency</TableCell>
                      <TableCell>Duration</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow key={row.responsibility} sx={{ border: 0 }}>
                        <TableCell
                          component="th"
                          scope="row"
                          className="module-respons-padd"
                        >
                          {row.responsibility}
                        </TableCell>
                        <TableCell>{row.area}</TableCell>
                        <TableCell>{row.shift}</TableCell>
                        <TableCell><div className="carry-bg">{row.carry}</div></TableCell>
                        <TableCell>{row.report}</TableCell>
                        <TableCell>{row.duration}</TableCell>
                        <TableCell align="center">
                          <div className="my-auto mx-auto ">
                            <MoreHorizOutlinedIcon
                              className="pointer"
                              aria-describedby={"right-more-popover"}
                              onClick={detailsMoreClick}
                            />

                            <Popover
                              id={"right-more-popover"}
                              open={rightMoreOpen}
                              anchorEl={rightAnchorEl}
                              onClose={detailsCloseClick}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              {["menu 1", "menu 2"].map((x) => (
                                <Typography
                                  key={x}
                                  sx={{ p: 2 }}
                                  className="text-capitalize"
                                >
                                  {x}
                                </Typography>
                              ))}
                            </Popover>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
});
export { RolesResponsiblity };
