import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route, Navigate } from "react-router-dom";
import { config } from "helpers";
// css
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/app.css";
//import "./assets/css/role.css";
// pages
// import ListViewPoc from "poc/listViewPoc";
// import DynamicFormPoc from "poc/dynamicFormPoc";
import FormInputPoc from "poc/formInputPoc";
// import MainLayout from "layouts/mainLayout";
// import RolePermissionPoc from "poc/rolePermissionPoc"
// import { RolesResponsiblity } from "pages/settings";
// import RolesResponsiblityPoc from "poc/roleRespondPoc";

// layouts
import AuthLayout from "./layouts/authLayout";
import MainLayout from "./layouts/mainLayout";

// pages
import {
  Signin,
  SignUp,
  OtpVerification,
  ResetPassword,
  ForgotPassword,
} from "./poc/conversions/auth";
import Dashboard from "poc/conversions/dashboard";
import { NotFound } from "poc/conversions/common";
import CrmView from "poc/conversions/crm/campaign/crmMainView";
import Setting from "poc/conversions/settings/setting";
import CampaignDetail from "poc/conversions/crm/campaign/campaignDetail";
import CampaignEdit from "poc/conversions/crm/campaign/campaignEdit";
import LeadEdit from "poc/conversions/crm/lead/leadEdit";
import TasksView from "poc/conversions/tasks";
import Target from "poc/conversions/target";
import Report from "poc/conversions/report";

// import DepartmentList from "poc/conversions/settings/hrms/department/list";
// import Location from "poc/conversions/settings/vendor/location";
// import { Employees } from "poc/conversions/settings/hrms/employee/list";
// import { RolesResponsiblity } from "poc/conversions/settings/hrms/rolesResponsiblity/list";
// import { Customer } from "pages/settings/vendor/customer";

import { Location } from "./poc/conversions/settings/vendor/location";
import { DepartmentList } from "./poc/conversions/settings/HRMS/department/list";
import { Employees } from "./poc/conversions/settings/HRMS/employee/index";
import { RolesResponsiblity } from "./poc/conversions/settings/HRMS/rolesResponsiblity/list";
import { Customer } from "poc/conversions/settings/vendor/customer";
import Template from "poc/conversions/settings/configure/template";
import SubEmail from "poc/conversions/settings/configure/subemail";
import Role from "poc/conversions/settings/configure/rolePermission";

//import { DepartmentList, Employees, RolesResponsiblity } from "poc/conversions/settings";
import ConversionMapping from "poc/conversions/settings/configure/conversionMapping";
import BasicInformation from "poc/conversions/report/basicInformation";
import ReportRepresentation from "poc/conversions/report/reportRepresentation";
import AddForm from "poc/conversions/report/addReports";

function Poc() {
  return (
    // <Router>
    //   <Routes>
    //     <Route path="/poc" element={<MainLayout  menu="setting" />}>
    //       <Route path="list" element={<ListViewPoc />} />
    //       <Route path="form" element={<DynamicFormPoc />} />
    //       <Route path="demo/form" element={<FormInputPoc />} />
    //       <Route path="role/permission" element={<RolePermissionPoc />} />
    //       <Route path="role/responsibility" element={<RolesResponsiblityPoc />} />
    //     </Route>
    //   </Routes>
    // </Router>
    <Router basename={config.base_url}>
      <Routes>
        <Route path="/poc">
          {/* <Route path="/" element={<Navigate to="/signin" replace />} /> */}
          {/* <Route path="/" element={<MainLayout menu="setting" />}>
            <Route path="demo/form" element={<FormInputPoc />} />
          </Route>
          <Route path="/" element={<AuthLayout />}>
            <Route index path="/signin" element={<Signin />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/forgot/password" element={<ForgotPassword />} />
            <Route path="/reset/password" element={<ResetPassword />} />
            <Route path="/otp/verification" element={<OtpVerification />} />
          </Route> */}

          {/* CRM */}
          <Route path="crm" element={<MainLayout menu="crm" />}>
            <Route path=":type/view" element={<CrmView />} />
            <Route path="view/:id" element={<CampaignDetail />} />
            <Route path="edit/:id" element={<CampaignEdit />} />
            <Route path="campaign/add" element={<CampaignEdit />} />
            <Route path="lead/add" element={<LeadEdit />} />
          </Route>
          {/* Dashboard */}
          <Route path="dashboard" element={<MainLayout menu="dashboard" />}>
            <Route path="index" element={<Dashboard />} />
          </Route>
          {/* Settings */}
          <Route path="setting" element={<MainLayout menu="setting" />}>
            <Route path="view/:tab" element={<Setting />} />
            <Route path="department" element={<DepartmentList />} />
            <Route path="employee" element={<Employees />} />
            <Route path="location" element={<Location />} />
            <Route path="rolesResponsiblity" element={<RolesResponsiblity />} />
            <Route path="conversionMapping" element={<ConversionMapping />} />
            <Route path="customer" element={<Customer />} />
            <Route path="template" element={<Template />} />
            <Route path="subemail" element={<SubEmail />} />
            <Route path="role" element={<Role />} />
          </Route>
          {/* Task */}
          <Route path="task" element={<MainLayout menu="task" />}>
            <Route path="index" element={<TasksView />} />
          </Route>
          {/* Target */}
          <Route path="target" element={<MainLayout menu="target" />}>
            <Route path="index" element={<Target />} />
          </Route>
          {/* Report */}
          {/* <Route path="/report" element={<MainLayout menu="report" />}>
          <Route path="index" element={<Report />} />
        </Route> */}
          <Route path="report" element={<MainLayout menu="report" />}>
            <Route path="index" element={<Report />} />
            <Route path="basicInformation" element={<BasicInformation />} />
            <Route path="representation" element={<ReportRepresentation />} />
            <Route path="addform" element={<AddForm />} />
            <Route path="representation" element={<ReportRepresentation />} />
            <Route path="form" element={<FormInputPoc />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default Poc;
