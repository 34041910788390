import React, { memo, useState } from "react";
import { Link } from "react-router-dom";

import FormControl from "@mui/material/FormControl";
import { Box, Button, Grid, Typography, Divider } from "@mui/material";
import { common } from "helpers";
function SubEmail(props) {
  const onSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="default-bg">
      <Box className="template-box-border p-4">
        <div className="row">
          <div className="col-12">
            <div className="card border-color-F2F9FD">
              <div className="card-body header-border-padding p-0">
                <Box alignItems="center" justifyContent="center">
                  <Box py={3}>
                    <Grid
                      container
                      spacing={3}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Grid container spacing={3} alignItems="center">
                          <Grid item>
                            <Grid container alignItems="center">
                              <Grid item px={2}>
                                <Link to="/setting/view/configure">
                                  <img
                                    alt={"back"}
                                    className="mx-2"
                                    src={common.loadImg(
                                      "iconly-light-arrow-down-circle.svg"
                                    )}
                                  />
                                </Link>
                              </Grid>
                              <Grid item>
                                <Typography className="page-title">
                                  Configure Sub Emails
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item className="close-icn-border ">
                        <Box className="revert-close revert-close1">
                          <Button className="save-note " type="button">
                            Revert and Close
                            <Link to="/setting/view/configure">
                              {" "}
                              <img
                                src={common.loadImg("closeKanban.svg")}
                                alt="close"
                              />
                            </Link>
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box>
                    <Divider />
                  </Box>

                  <div className="row p-4">
                    <div className="col-lg-6 ">
                      <FormControl
                        sx={{ m: 1, minWidth: { xs: 220, lg: 120 } }}
                        m-0
                        className="template-name-border w-100"
                      >
                        <div className="template-name-border">
                          <label className="template-label-font template-label-padding">
                            Sub Email 1
                          </label>
                          <input
                            type="text"
                            className="template-name w-100 p-100"
                            placeholder="Appointment Email"
                          ></input>
                        </div>
                      </FormControl>
                    </div>
                    <div className="col-lg-6 ">
                      <FormControl
                        sx={{ m: 1, minWidth: { xs: 220, lg: 120 } }}
                        m-0
                        className="template-name-border w-100"
                      >
                        <div className="template-name-border">
                          <label className="template-label-font template-label-padding">
                            Sub Email 2
                          </label>
                          <input
                            type="text"
                            className="template-name w-100 p-100"
                            placeholder="Appointment Email"
                          ></input>
                        </div>
                      </FormControl>
                    </div>
                  </div>

                  <Box>
                    <Divider />
                  </Box>

                  <div className="row p-4">
                    <div className="col-lg-12 text-center">
                      <Button
                        className="sign-in-btn "
                        onClick={(e) => onSubmit(e)}
                      >
                        Update and Save
                      </Button>
                    </div>
                  </div>
                  <Box>
                    <Divider />
                  </Box>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
}

export default SubEmail;
