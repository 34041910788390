import React, { memo } from "react";
import { Box, Grid, Typography } from "@mui/material";
import ListView from "elements/ListView";
import { common } from "helpers";

const ChildCampaigns = memo(({ handleExpanded, isExpand, id }) => {
  const onHandleDetails = (e, val, item) => {
    e.preventDefault();
  };
  return (
    <div className="relative pb-3">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box className="crm-submenu-wrap">
          <Grid
            container
            spacing={2}
            alignItems="center"
            className="subheader-elements"
          >
            <Grid item>
              {isExpand === "detail" ? (
                <Typography
                  className="expand pointer"
                  onClick={() => handleExpanded("tab")}
                >
                  Expand{" "}
                  <img src={common.loadImg("expandkanban.svg")} alt="expand" />
                </Typography>
              ) : (
                <Typography
                  className="expand pointer"
                  onClick={() => handleExpanded("detail")}
                >
                  Collapse{" "}
                  <img
                    src={common.loadImg("expandkanban.svg")}
                    alt="Collapse"
                  />
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>

      <ListView
        module={"campaign"}
        query={{
          ParentCampaignId: id,
        }}
        onHandleDetails={(e, val, item) => onHandleDetails(e, val, item)}
        sort="CreatedOn:desc"
        type={"subMenu"}
        add={false}
        className={"table-scroll-position-collapse"}
        isExpand={isExpand}
        id={id}
      />
    </div>
  );
});

export { ChildCampaigns };
