import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route, Navigate } from "react-router-dom";
import "./assets/css/app.css";
import "./assets/css/form-settings.css";
import "react-datepicker/dist/react-datepicker.css";
import { config } from "helpers";

// layouts
import AuthLayout from "./layouts/authLayout";
import MainLayout from "./layouts/mainLayout";

// pages
import Home from "./pages/home/index";
import {
  Signin,
  SignUp,
  OtpVerification,
  ResetPassword,
  ForgotPassword,
} from "./pages/auth";
import { FormSettings } from "pages/settings/configure/formSetting";
import CrmLayout from "pages/crm/crm";
import TasksView from "pages/task";
import Dashboard from "pages/dashboard";
import Dashboard1 from "pages/dashboard/index1";
import Target from "pages/target";
import Setting from "pages/settings";

import {
  Location,
  Customer,
  ProductServices,
  Party,
} from "pages/settings/vendor";
import {
  DepartmentList,
  Employees,
  RolesResponsibility,
} from "pages/settings/hrms";
import Template from "pages/settings/configure/emailTemplate";
import EditTemplate from "pages/settings/configure/emailTemplate/add";
import SubEmail from "pages/settings/configure/subemail";
import Role from "pages/settings/configure/rolePermission";
import Automation from "pages/settings/configure/automation";
import ConversionMapping from "pages/settings/configure/conversionMapping";
//report
import Report from "pages/report";
import BasicInformation from "pages/report/basicInformation";
import ReportRepresentation from "pages/report/reportRepresentation";
import AddForm from "pages/report/addReports";
import AddLocation from "pages/settings/vendor/location/add";
import AddCustomer from "pages/settings/vendor/customer/add";
import AddParty from "pages/settings/vendor/party/add";
import AddEmployee from "pages/settings/hrms/employee/add";
import AddProductservice from "pages/settings/vendor/productServices/add";
import AddFormTransaction from "pages/settings/configure/formTransaction/add";
import AddDepartment from "pages/settings/hrms/department/add";
import AddFormTransition from "pages/settings/configure/formTransition/add";
import WorkflowCrud from "pages/settings/configure/automation/WorkflowCrud";
import FormTransaction from "pages/settings/configure/formTransaction";

//Customer -> Sites
import CustomerSitesForm from "pages/settings/vendor/customer/sites/form";

import { ProtectedRoute } from "./ProtectedRoute";
import Profile from "pages/profile/profile";
import ChangePassword from "pages/profile/changePassword";

function App() {
  if (window.location.hostname == "uams.pickauni.international") {
    document.title = "UAMS - Pickauni";
  }

  return (
    <Router basename={config.base_url}>
      <Routes>
        <Route
          element={
            <ProtectedRoute auth={false} redirectPath="/crm/lead/list" />
          }
        >
          {/* <Route path="/" element={<Navigate to="/signin" replace />} /> */}
          <Route path="/" element={<Home />} />

          <Route path="/" element={<AuthLayout />}>
            <Route index path="/signin" element={<Signin />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/forgot/password" element={<ForgotPassword />} />
            <Route path="/reset/password" element={<ResetPassword />} />
            <Route path="/otp/verification" element={<OtpVerification />} />
          </Route>
        </Route>

        <Route element={<ProtectedRoute auth={true} />}>
          <Route path="setting" element={<MainLayout menu="setting" />}>
            <Route path="view/:tab" element={<Setting />} />
            {/* hrms */}
            <Route path="hrms/department" element={<DepartmentList />} />
            <Route
              path="hrms/department/add/:TerritoryId"
              element={<AddDepartment />}
            />
            <Route
              path="hrms/department/edit/:TerritoryId"
              element={<AddDepartment />}
            />

            <Route path="hrms/employee" element={<Employees />} />
            <Route path="hrms/employee/add" element={<AddEmployee />} />
            <Route path="hrms/employee/edit/:id" element={<AddEmployee />} />

            <Route
              path="hrms/rolesResponsiblity"
              element={<RolesResponsibility />}
            />
            {/* configure */}
            <Route path="configure/formSettings" element={<FormSettings />} />

            <Route
              path="configure/formTransaction"
              element={<FormTransaction />}
            />
            <Route
              path="configure/formTransaction/add/:pId"
              element={<AddFormTransaction />}
            />
            <Route
              path="configure/formTransaction/edit/:pId/:tId"
              element={<AddFormTransaction />}
            />

            <Route
              path="configure/conversionMapping"
              element={<ConversionMapping />}
            />

            <Route path="configure/template" element={<Template />} />
            <Route
              path="configure/template/:type/add"
              element={<EditTemplate />}
            />
            <Route
              path="configure/template/edit/:tId"
              element={<EditTemplate />}
            />

            <Route path="configure/subemail" element={<SubEmail />} />
            <Route path="configure/role" element={<Role />} />
            <Route path="configure/automation" element={<Automation />} />
            <Route
              path="configure/rule/edit/:ruleId"
              element={<WorkflowCrud />}
            />
            <Route path="configure/rule/add" element={<WorkflowCrud />} />

            <Route
              path="configure/formTransition/add"
              element={<AddFormTransition />}
            />
            {/* vendor */}
            <Route path="vendor/customer" element={<Customer />} />
            <Route path="vendor/customer/add" element={<AddCustomer />} />
            <Route path="vendor/customer/edit/:id" element={<AddCustomer />} />

            <Route
              path="vendor/customer/sites/add/:customerId"
              element={<CustomerSitesForm />}
            />
            <Route
              path="vendor/customer/sites/edit/:customerId/:id"
              element={<CustomerSitesForm />}
            />

            <Route path="vendor/location" element={<Location />} />
            <Route path="vendor/location/add" element={<AddLocation />} />
            <Route path="vendor/location/edit/:id" element={<AddLocation />} />
            <Route path="vendor/product" element={<ProductServices />} />
            <Route path="vendor/product/add" element={<AddProductservice />} />
            <Route
              path="vendor/product/edit/:pId"
              element={<AddProductservice />}
            />
            <Route path="vendor/party" element={<Party />} />
            <Route path="vendor/party/add" element={<AddParty />} />
            <Route path="vendor/party/edit/:id" element={<AddParty />} />
          </Route>

          <Route path="task" element={<MainLayout menu="task" />}>
            <Route path=":view" element={<TasksView />} />
            <Route path="add" element={<TasksView />} />
            <Route path="edit/:id" element={<TasksView />} />
          </Route>

          <Route path="report" element={<MainLayout menu="report" />}>
            <Route path="index" element={<Report />} />
            <Route path="basicInformation" element={<BasicInformation />} />
            <Route path="representation" element={<ReportRepresentation />} />
            <Route path="addform" element={<AddForm />} />
            <Route path="representation" element={<ReportRepresentation />} />
          </Route>
          {/* Dashboard */}
          <Route path="dashboard" element={<MainLayout menu="dashboard" />}>
            <Route path="index" element={<Dashboard1 />} />
            <Route path="index1" element={<Dashboard />} />
          </Route>
          {/* Target */}
          <Route path="target" element={<MainLayout menu="target" />}>
            <Route path="index" element={<Target />} />
          </Route>

          <Route path="/" element={<MainLayout menu="crm" />}>
            <Route path="crm/:mod/:type" element={<CrmLayout />} />
            <Route path="crm/:mod/:type/:id" element={<CrmLayout />} />
            <Route path="profile" element={<Profile />} />
            <Route
              path="profile/change/password"
              element={<ChangePassword />}
            />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
