import { Box } from "@mui/material";
import React from "react";
import Paper from "@mui/material/Paper";
import { useOutletContext } from "react-router-dom";
import StaticTable from "elements/staticTable";
import task from "assets/files/static/task.json";
import AccessDenied from "elements/AccessDenied";
import { common } from "helpers";

function ListView() {
  /***   for subheader fixed */
  React.useEffect(() => {
    var isMobileVersion = document.getElementsByClassName("rotate-arrow");
    if (isMobileVersion.length > 0) {
      const taskListView = document.getElementById("task-list-view-wrap");
      if (taskListView) {
        taskListView.classList.add("sm-tasklistview-wrap");
        taskListView.classList.remove("lg-tasklistview-wrap");
      }
      
    } else {
      const taskListView = document.getElementById("task-list-view-wrap");
      if (taskListView) {
        taskListView.classList.remove("sm-tasklistview-wrap");
        taskListView.classList.add("lg-tasklistview-wrap");
      }
    }
  });
  /***   for subheader fixed */
  const [permission] = useOutletContext();
  return (
    <Box px={2} className="list-view">
       {common.givenPermission(permission,'task','read') ? <AccessDenied /> :   <Paper
        sx={{ width: "100%", mb: 2 }}
        className={`container responsive-table p-0`}
        id="task-list-view-wrap"
        
      >
        <StaticTable module={task}
        add={!common.givenPermission(permission,'task','add')} 
        update={!common.givenPermission(permission,'task','update')}
        delete={!common.givenPermission(permission,'task','delete')}
        safariclassName={"safari-style"}
         />
      </Paper> }
    
    </Box>
  );
}

export default ListView;
