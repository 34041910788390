import React from "react";
import { common } from "helpers";
import { Container, Row, Col } from "react-bootstrap";

function RecordNotFound() {
  return (
    <Container
      fluid
      className="loader-wrap py-5 d-flex justify-content-center align-items-center"
    >
      <Row>
        <Col lg={12} className="text-center">
          <img
            src={common.loadImg("noRecordFound.svg")}
            className="error-img"
            alt="application-icn"
          />
          <h3 className="nodata-found-txts ps-3">No Data Found!</h3>
        </Col>
      </Row>
    </Container>
  );
}
export default RecordNotFound;
