import { Box, Grid, Paper } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { common, envConfig } from "helpers";
let ModNameChange = envConfig.ModNameChange;
const ConfigureTab = () => {
  return (
    <>
      <Box p={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <Paper variant="contained" square className="main-content-wrap">
              <Link
                to="/setting/configure/formTransaction"
                className="link-text"
              >
                <Box className="contents-padding activate-arrow">
                  <Grid container>
                    <Grid item xs={11}>
                      <Box>
                        <label className="setting-config-tab-titles">
                          Form Transactions
                        </label>
                        <p className="setting-card-content">
                          Add or remove transactions related to your business or
                          company
                        </p>
                      </Box>
                    </Grid>
                    <Grid item xs={1} className="padding-zero changeArrow">
                      <img
                        alt="next"
                        src={common.loadImg(
                          "iconly-light-arrow-next-circle.svg"
                        )}
                      />
                      <img
                        alt="next"
                        src={common.loadImg("next-activeArrow.svg")}
                        className="arrowActive"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Link>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Paper variant="contained" square className="main-content-wrap">
              <Link to="/setting/configure/formSettings" className="link-text">
                <Box className="contents-padding activate-arrow">
                  <Grid container>
                    <Grid item xs={11}>
                      <Box>
                        <Grid item>
                          <label className="setting-config-tab-titles">
                            Form Settings
                          </label>
                          <p className="setting-card-content">
                            Add or remove form settings related to your business
                            or company
                          </p>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item xs={1} className="padding-zero changeArrow">
                      <img
                        alt="next"
                        src={common.loadImg(
                          "iconly-light-arrow-next-circle.svg"
                        )}
                      />
                      <img
                        alt="next"
                        src={common.loadImg("next-activeArrow.svg")}
                        className="arrowActive"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Link>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Paper variant="contained" square className="main-content-wrap">
              <Link
                to="/setting/configure/conversionMapping"
                className="link-text"
              >
                <Box className="contents-padding activate-arrow">
                  <Grid container>
                    <Grid item xs={11}>
                      <Box>
                        <label className="setting-config-tab-titles">
                          Conversion Mapping
                        </label>
                        <p className="setting-card-content">
                          Add or remove mapping fields related to your business
                          or company
                        </p>
                      </Box>
                    </Grid>
                    <Grid item xs={1} className="padding-zero changeArrow">
                      <img
                        alt="next"
                        src={common.loadImg(
                          "iconly-light-arrow-next-circle.svg"
                        )}
                      />
                      <img
                        alt="next"
                        src={common.loadImg("next-activeArrow.svg")}
                        className="arrowActive"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Link>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Paper variant="contained" square className="main-content-wrap">
              <Link to="/setting/configure/role" className="link-text">
                <Box className="contents-padding activate-arrow">
                  <Grid container>
                    <Grid item xs={11}>
                      <Box>
                        <label className="setting-config-tab-titles">
                          {ModNameChange ? "Permissions" : "Roles"}
                        </label>
                        <p className="setting-card-content">
                          Add {ModNameChange ? "permissions" : "roles"} related
                          to your business or company
                        </p>
                      </Box>
                    </Grid>
                    <Grid item xs={1} className="padding-zero changeArrow">
                      <img
                        alt="next"
                        src={common.loadImg(
                          "iconly-light-arrow-next-circle.svg"
                        )}
                      />
                      <img
                        alt="next"
                        src={common.loadImg("next-activeArrow.svg")}
                        className="arrowActive"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Link>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Paper variant="contained" square className="main-content-wrap">
              <Link to="/setting/configure/template" className="link-text">
                <Box className="contents-padding activate-arrow">
                  <Grid container>
                    <Grid item xs={11}>
                      <Box>
                        <label className="setting-config-tab-titles">
                          Templates
                        </label>
                        <p className="setting-card-content">
                          Add or remove templates related to your business or
                          company
                        </p>
                      </Box>
                    </Grid>
                    <Grid item xs={1} className="padding-zero changeArrow">
                      <img
                        alt="next"
                        src={common.loadImg(
                          "iconly-light-arrow-next-circle.svg"
                        )}
                      />
                      <img
                        alt="next"
                        src={common.loadImg("next-activeArrow.svg")}
                        className="arrowActive"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Link>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Paper variant="contained" square className="main-content-wrap">
              <Link to="/setting/configure/automation" className="link-text">
                <Box className="contents-padding activate-arrow">
                  <Grid container>
                    <Grid item xs={11}>
                      <Box>
                        <label className="setting-config-tab-titles">
                          Automation
                        </label>
                        <p className="setting-card-content">
                          Add or remove rules related to your business or
                          company
                        </p>
                      </Box>
                    </Grid>
                    <Grid item xs={1} className="padding-zero changeArrow">
                      <img
                        alt="next"
                        src={common.loadImg(
                          "iconly-light-arrow-next-circle.svg"
                        )}
                      />
                      <img
                        alt="next"
                        src={common.loadImg("next-activeArrow.svg")}
                        className="arrowActive"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Link>
            </Paper>
          </Grid>
          {/* <Grid item xs={12} sm={12} md={6}>
            <Paper variant="contained" square className="main-content-wrap">
              <Link to="/setting/configure/subemail" className="link-text">
                <Box className="contents-padding  activate-arrow ">
                  <Grid container>
                    <Grid item xs={11}>
                      <Box>
                        <label className="setting-config-tab-titles">
                          Sub Email
                        </label>
                        <p className="setting-card-content">
                          Add or remove subemail related to your business or
                          company
                        </p>
                      </Box>
                    </Grid>
                    <Grid item xs={1} className="padding-zero changeArrow">
                      <Link to="/setting/configure/subemail">
                        <img
                          alt="next"
                          src={common.loadImg(
                            "iconly-light-arrow-next-circle.svg"
                          )}
                        />
                        <img
                          alt="next"
                          src={common.loadImg("next-activeArrow.svg")}
                          className="arrowActive"
                        />
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </Link>
            </Paper>
          </Grid> */}
        </Grid>
      </Box>
    </>
  );
};

export default ConfigureTab;
