import React, { memo } from "react";
import { common } from "helpers";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import Popover from "@mui/material/Popover";
import {
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const ActiveSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    backgroundColor: "#F3F4F6 !important",
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
  },
  "& .Mui-checked": {
    boxShadow: "none",
    color: "#29C745 !important",
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
    backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
      theme.palette.getContrastText(theme.palette.primary.main)
    )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
  },
}));

const Location = memo(() => {
  const [rightAnchorEl, setRightAnchorEl] = React.useState(null);

  const detailsMoreClick = (event) => {
    setRightAnchorEl(event.currentTarget);
  };
  const detailsCloseClick = () => {
    setRightAnchorEl(null);
  };

  const rightMoreOpen = Boolean(rightAnchorEl);
  return (
    <Box px={5} mt={3} style={{}} className="template-box-border">
      <div className="row">
        {true && (
          <div className="col-md-5 mt-2 ">
            <div className="card border-color-F2F9FD">
              <div className="card-body p-0">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  className="p-3"
                >
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item>
                      <h5 className="d-flex align-items-center title">
                        <Link to="/setting/view/vendor">
                          <img
                            alt={"back"}
                            className="mx-2"
                            src={common.loadImg(
                              "iconly-light-arrow-down-circle.svg"
                            )}
                            width={30}
                            height={30}
                          />
                        </Link>
                        <label className="d-flex align-items-center location-detail-title">
                          Locations{" "}
                          <span className="location-count-label">02</span>
                        </label>
                      </h5>
                    </Grid>
                    <Grid item>
                      <Box className="flex" alignItems="center">
                        <Link to="#" className="text-none">
                          <span className="mx-2 add-new">Add New</span>
                        </Link>

                        <div className="add-new-button d-flex align-items-center justify-content-center">
                          <Link to="#" className="text-none">
                            <span className="d-flex align-items-center plus-icon">
                              +
                            </span>
                          </Link>
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <div className="d-flex bg-F2F9FD p-1">
                  <Box className="filter-search" width="100%">
                    <FormControl sx={{ width: "100%" }}>
                      <OutlinedInput
                        id="search"
                        placeholder={"Search"}
                        endAdornment={
                          <InputAdornment position="end">
                            <img
                              src={common.loadImg("common-search.svg")}
                              alt="search"
                            />
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                      />
                    </FormControl>
                  </Box>
                </div>

                <div className="bg-white location-user-list">
                  {/* Profile */}
                  <Grid container direction="column">
                    {[1, 2, 3].map((_, i) => {
                      return (
                        <Grid item>
                          <Box>
                            <Grid container alignItems="center">
                              <Grid item xs={2} className="pl-2">
                                <Box px={2} py={2}>
                                  <Box className="img-box">AB</Box>
                                </Box>
                                {i === 0 ? (
                                  <div className="active active-location"></div>
                                ) : null}
                              </Grid>
                              <Grid item xs={1}>
                                <Box display="flex" justifyContent="center">
                                  <Box height="85px" width="2px">
                                    <Divider orientation="vertical" />
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item xs={9}>
                                <Box py={0.6} px={2}>
                                  <Grid
                                    container
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <Grid item>
                                      <div className="mt-2 font-weight-bold">
                                        <label className="setting-card-title fz-16 font-weight-bold">
                                          Adam Brody
                                        </label>
                                        <p className="setting-card-content fz-14">
                                          Chief Design Officer
                                        </p>
                                      </div>
                                    </Grid>
                                    <div className="my-auto pe-4 ">
                                      <MoreHorizOutlinedIcon
                                        className="pointer"
                                        aria-describedby={"right-more-popover"}
                                        onClick={detailsMoreClick}
                                      />

                                      <Popover
                                        id={"right-more-popover"}
                                        open={rightMoreOpen}
                                        anchorEl={rightAnchorEl}
                                        onClose={detailsCloseClick}
                                        anchorOrigin={{
                                          vertical: "bottom",
                                          horizontal: "right",
                                        }}
                                        transformOrigin={{
                                          vertical: "top",
                                          horizontal: "right",
                                        }}
                                      >
                                        {["menu 1", "menu 2"].map((x) => (
                                          <Typography
                                            key={x}
                                            sx={{ p: 2 }}
                                            className="text-capitalize"
                                          >
                                            {x}
                                          </Typography>
                                        ))}
                                      </Popover>
                                    </div>
                                  </Grid>
                                </Box>
                              </Grid>
                            </Grid>
                            <Box>
                              <Divider />
                            </Box>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        )}
        {true && (
          <div className="col-md-7 mt-2 location-user-details card-custom">
            {[1, 2, 3].map((term) => (
              <div key={term} className="row location-paper">
                <div className="">
                  <div className="card-body p-0">
                    <div className="d-flex justify-content-between p-3">
                      <label className="location-detail-title">
                        Location Information{" "}
                      </label>

                      <div className="my-auto pe-4">
                        <MoreHorizOutlinedIcon
                          className="pointer"
                          aria-describedby={"right-more-popover"}
                          onClick={detailsMoreClick}
                        />

                        <Popover
                          id={"right-more-popover"}
                          open={rightMoreOpen}
                          anchorEl={rightAnchorEl}
                          onClose={detailsCloseClick}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          {["menu 1", "menu 2"].map((x) => (
                            <Typography
                              key={x}
                              sx={{ p: 2 }}
                              className="text-capitalize"
                            >
                              {x}
                            </Typography>
                          ))}
                        </Popover>
                      </div>
                    </div>
                  </div>
                </div>
                <Box p={0}>
                  <Divider />
                </Box>
                <div className="settings-secondary" />
                <div></div>
                <div className=" bg-F2F9FD"></div>
                <Paper
                  variant="contained"
                  style={{ padding: 0, boxShadow: "none !important" }}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      className="border-dynamic"
                    >
                      <Box px={3} pt={3}>
                        <h6 className="setting-card-content">Location Code</h6>
                        <p className="setting-card-title fz-14">001</p>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      className="border-dynamic"
                    >
                      <Box px={3} pt={3}>
                        <h6 className="setting-card-content">Location Name</h6>
                        <p className="setting-card-title fz-14">HQ</p>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      className="border-dynamic"
                    >
                      <Box px={3} pt={3}>
                        <h6 className="setting-card-content">Start Date</h6>
                        <p className="setting-card-title fz-14">NA</p>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      className="border-dynamic"
                    >
                      <Box px={3} pt={3}>
                        <h6 className="setting-card-content">End Date</h6>
                        <p className="setting-card-title fz-14">NA</p>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      className={`border-dynamic ${
                        term !== 3 ? "" : "customer-tab-li"
                      }`}
                    >
                      <Box px={3} pt={3}>
                        <h6 className="setting-card-content">Description</h6>
                        <p className="setting-card-title fz-14">NA</p>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      className={`border-dynamic ${
                        term !== 3 ? "" : "customer-tab-li"
                      }`}
                    >
                      <Box px={3} pt={3}>
                        <h6 className="setting-card-content">Active</h6>
                        <p className="setting-card-title fz-14">
                          {" "}
                          <ActiveSwitch defaultChecked />
                        </p>
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            ))}
          </div>
        )}
      </div>
    </Box>
  );
});

export { Location };
