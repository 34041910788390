import React, { memo, useState, useEffect } from "react";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Grid } from "@mui/material";
import ListView from "./ListView";
import { useParams } from "react-router-dom";
import { common } from "helpers";

const ContactLinks = memo(({ handleExpanded, isExpand, id }) => {
  let { mod } = useParams();
  const [objectId, setObjectId] = useState(id);
  const [objectType, setObjectType] = useState(mod);
  const [value, setValue] = React.useState("1");

  useEffect(() => {
    if (id) {
      setObjectId(id);
      setObjectType(mod);
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onHandleDetails = (e, val, item) => {
    e.preventDefault();
  };

  return (
    <Box sx={{ width: "100%" }} className="contact-tabs">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box className="crm-submenu-wrap">
          <Grid
            container
            spacing={2}
            alignItems="center"
            className="subheader-elements"
          >
            <Grid item>
              {isExpand === "detail" ? (
                <Typography
                  className="expand pointer"
                  onClick={() => handleExpanded("tab")}
                >
                  Expand{" "}
                  <img src={common.loadImg("expandkanban.svg")} alt="expand" />
                </Typography>
              ) : (
                <Typography
                  className="expand pointer"
                  onClick={() => handleExpanded("detail")}
                >
                  Collapse{" "}
                  <img
                    src={common.loadImg("expandkanban.svg")}
                    alt="Collapse"
                  />
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Linked Contacts" value="1" />
              <Tab label="Contacts" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <ListView
              module={"contactsLink"}
              onHandleDetails={(e, val, item) => onHandleDetails(e, val, item)}
              query={{
                ObjectId: objectId,
                ObjectType: common.getObjectNameByPage(objectType),
              }}
              sort="CreatedOn:desc"
              type={"subMenu"}
              add={false}
              className={"table-scroll-position-contacts"}
              isExpand={isExpand}
              id={id}
            />
          </TabPanel>
          <TabPanel value="2">
            <ListView
              module={"contacts"}
              onHandleDetails={(e, val, item) => onHandleDetails(e, val, item)}
              sort="CreatedOn:desc"
              type={"subMenu"}
              add={false}
              className={"table-scroll-position-contacts"}
              isExpand={isExpand}
              tabChange={(e) => handleChange(e, "1")}
              id={id}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
});
export { ContactLinks };
