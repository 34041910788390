import { Box, Grid, Typography } from "@mui/material";
import { common } from "helpers";
import React from "react";

const Footer = ({open}) => {
  return (
    <footer className="footer">
      <Box
        sx={{
          flexDirection: { xs: "column", lg: "row" },
        }}
        display="flex"
        justifyContent="space-between"
        px={{ xs: 1, lg: 4 }}
        py={2}
        ml={open ? 25 :10}
      >
        <Box>
          <Grid container alignItems="center" spacing={2} className="pointer">
            <Grid item>
              <img src={common.loadImg("logo_footer.svg")} alt="goto-website" />
            </Grid>
            <Grid item>
              <Typography className="footer-content">
                OSMO 2021 All Rights Reserved
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box py={1}>
          <Grid container alignItems="center" spacing={2} className="pointer">
            <Grid item>
              <Typography className="footer-content">
                Product by Novelwall (PVT) Ltd 2021
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </footer>
  );
};

export default Footer;
