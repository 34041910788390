import React, { useEffect, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { Box, Button, Grid, Typography, Divider } from "@mui/material";
import Select from "react-select";
import FormControl from "@mui/material/FormControl";
import StatusBar from "elements/StatusBar";
import AccessDenied from "elements/AccessDenied";
import {
  CHOLA_CUSTOM_MAPPING_HDR,
  CRM_ESERVICE_HDR,
  CHOLA_CUSTOM_MAPPING_DTL,
} from "helpers/service";
import * as Services from "helpers/service";

import serviceList from "assets/files/serviceList.json";
import { api, common, envConfig } from "helpers";

const SAVE_SUCCESS = "Mapping saved successfully.";
const SAVE_FAILED = "Mapping save failed.";
const BACK_URL = "/setting/view/configure";

let modules = [];
Object.entries(serviceList).map(([key, item]) => {
  if (item.conversion === true) {
    modules.push({
      label: item.conversionName,
      value: item.conversionObject,
      api: item.api,
      code: item.code,
      mapping: item.conversionMapping,
      name: item.conversionName,
    });
  }
});

function ConversionMapping() {
  const [permission] = useOutletContext();
  const [selectedModule, setSelectedModule] = useState(modules[0]);
  const [mappedData, setMappedData] = useState([]);
  const [srcData, setSrcData] = useState([]);
  const [desData, setDesData] = useState([]);
  const [callApi, setCallAPI] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [AttrMappingId, setAttrMappingId] = useState(0);
  const [status, setStatus] = useState("empty");
  const userData = common.userInfo();

  useEffect(() => {
    if (selectedModule) {
      getMappingInfo(modules[0]);
    }
  }, []);

  useEffect(() => {
    setStatus("process");
    setMappedData([]);
    setSrcData([]);
    setDesData([]);
    getMappingInfo(selectedModule);
  }, [selectedModule]);

  useEffect(() => {
    if (callApi === 1) {
      getColumnsData(true, selectedModule?.code || modules[0].code);
    } else if (callApi === 2) {
      getColumnsData(false, selectedModule?.mapping || modules[0].mapping);
    } else if (callApi === 3) {
      mapData();
    }
  }, [callApi]);

  const onDefaultOption = (e, item) => {
    let data = common.reParse(srcData);
    let index = data.findIndex((sData) => sData.key == item.key);

    if (index >= 0) {
      item.SourceColumnName = item.key;
      item.MappingColumnName = e?.key || "";
      data[index] = item;
      setSrcData(data);
    }
  };

  const setOption = (item) => {
    let obj = item.SrcMappingList?.find((o) => o.key == item.MappingColumnName);
    return obj;
  };

  const getMappingInfo = (module) => {
    let filter = `?q=SourceObject=${
      Services[module.api]
    }&expand=CholaAttrMappingDtlView&limit=100&offset=0`;
    let url = envConfig.BASE_API + CHOLA_CUSTOM_MAPPING_HDR + filter;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };

    api.call(
      data,
      (response) => {
        if (response.status === 200) {
          const result = response.data.items[0];
          setMappedData(result.CholaAttrMappingDtlView);
          setAttrMappingId(result.AttrMappingId);
          setCallAPI(1);
        }
      },
      (error) => {
        setStatus("error");
      },
      (final) => {}
    );
  };

  const getColumnsData = (isSrc, code) => {
    let filter = `?q=EserviceCode=${code}`;
    let url = envConfig.BASE_API + CRM_ESERVICE_HDR + filter;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };

    api.call(
      data,
      (response) => {
        if (response.status === 200) {
          const result = JSON.parse(response.data.items[0]?.FormFields);
          let fList = result?.form?.fields;

          let srcResult = [];
          fList.map((obj) => {
            if (
              obj.key.includes("Clattr") ||
              obj.key.includes("Cattr") ||
              obj.key.includes("Dattr") ||
              obj.key.includes("Nattr") ||
              obj.key.includes("Eattr")
            ) {
              srcResult.push(obj);
            }
          });

          if (isSrc) {
            setSrcData(srcResult);
            setCallAPI(2);
          } else {
            setDesData(srcResult);
            setCallAPI(3);
          }
        }
      },
      (error) => {
        setStatus("error");
      },
      (final) => {}
    );
  };

  const mapData = () => {
    let pData = common.reParse(srcData);
    if (mappedData.length > 0 && srcData.length > 0) {
      //let pData = common.reParse(srcData);
      pData.map((sData) => {
        let fmData = mappedData.filter(
          (item) => item.SourceColumnName == sData.key
        );

        sData.SourceColumnName =
          fmData.length > 0 ? fmData[0].SourceColumnName : null;

        sData.AttrMappingDtlId =
          fmData.length > 0 ? fmData[0].AttrMappingDtlId : 0;
        sData.MappingColumnName =
          fmData.length > 0 ? fmData[0].MappingColumnName : null;
        let mapFieldsFilter = desData.filter((item) => item.type == sData.type);
        mapFieldsFilter.map((obj)=> { obj.value = obj.key});
        mapFieldsFilter.push({ label: "None", key: "" });
        sData.SrcMappingList = mapFieldsFilter;
      });
    } else if (srcData.length > 0) {
      pData.map((sData) => {
        sData.SourceColumnName = null;
        sData.AttrMappingDtlId = 0;
        sData.MappingColumnName = null;
        let mapFieldsFilter = desData.filter((item) => item.type == sData.type);
        mapFieldsFilter.map((obj)=> { obj.value = obj.key});
        mapFieldsFilter.push({ label: "None", key: "" });
        sData.SrcMappingList = mapFieldsFilter;
      });
    }
    setSrcData(pData);
    setStatus("success");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    let inputData = [];

    srcData.map((smData) => {
      let fields = {
        AttrMappingId: AttrMappingId,
        SourceColumnName: smData.SourceColumnName,
        MappingColumnName: smData.MappingColumnName,
        Active: "Y",
        TenantId: userData.pTenantId,
        OrgId: userData.pOrgId,
      };

      if (smData.SourceColumnName != null && smData.AttrMappingDtlId > 0) {
        //update
        inputData.push({
          id: "part" + (inputData.length + 1),
          path: "/" + CHOLA_CUSTOM_MAPPING_DTL + "/" + smData.AttrMappingDtlId,
          operation: "update",
          payload: fields,
        });
      } else if (
        smData.SourceColumnName != null &&
        smData.AttrMappingDtlId == 0
      ) {
        //create new
        inputData.push({
          id: "part" + (inputData.length + 1),
          path: "/" + CHOLA_CUSTOM_MAPPING_DTL,
          operation: "create",
          payload: fields,
        });
      } else if (
        smData.SourceColumnName == null &&
        smData.AttrMappingDtlId > 0
      ) {
        //delete existing
        inputData.push({
          id: "part" + (inputData.length + 1),
          path: "/" + CHOLA_CUSTOM_MAPPING_DTL + "/" + smData.AttrMappingDtlId,
          operation: "delete",
          payload: {},
        });
      }
    });
    if (inputData.length > 0) {
      let url = encodeURI(envConfig.BASE_API);
      let data = {
        url: url,
        type: "dynamic",
        method: "POST",
        auth: "token",
        body: JSON.stringify({ parts: inputData }),
        cType: 5,
      };
      api.call(
        data,
        (response) => {
          if (response != null) {
            setIsLoading(false);
            common.snack("S", SAVE_SUCCESS);
          }
        },
        (error) => {
          common.snack("E", error || SAVE_FAILED);
        },
        (final) => {}
      );
    }
  };
  return (
    <React.Fragment>
      {common.givenPermission(permission, "configure:conversion mapping", "basic") ? (
        <AccessDenied />
      ) : (
        <Box className="template-box-border p-4">
          <div className="row">
            <div className="col-12 mt-2 ">
              <div className="card border-color-F2F9FD">
                <div className="card-body header-border-padding  p-0">
                  <Box alignItems="center" justifyContent="center" className="">
                    <Box py={3}>
                      <Grid
                        container
                        spacing={3}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item className="detail-head">
                          <Grid container spacing={3} alignItems="center" className="detail-head">
                            <Grid item>
                              <Grid container alignItems="center">
                                <Grid item>
                                  <Link to={BACK_URL}>
                                    <img
                                      alt={"back"}
                                      className="mx-2"
                                      src={common.loadImg(
                                        "iconly-light-arrow-down-circle.svg"
                                      )}
                                    />
                                  </Link>
                                </Grid>
                                <Grid item>
                                  <Typography className="page-title">
                                    Conversion Mappings
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item>
                              <FormControl
                                sx={{ m: 1, minWidth: { xs: 220, lg: 120 } }}
                                m-0="true"
                                className="dropdown w-100"
                              >
                                <Select
                                  className="conversion-mapping-setting w-100 p-100"
                                  defaultValue={selectedModule}
                                  onChange={(e) => setSelectedModule(e)}
                                  options={modules}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item className="close-icn-border ">
                          <Box className="revert-close revert-close1">
                            <Button className="save-note" type="button">
                              Revert and Close
                              <Link to={BACK_URL}>
                                {" "}
                                <img
                                  src={common.loadImg("closeKanban.svg")}
                                  alt="close"
                                />
                              </Link>
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box>
                      <Divider />
                    </Box>
                    {status !== "success" ? (
                      <StatusBar status={status} />
                    ) : (
                      <>
                        <div className="row p-4">
                          {srcData.length == 0 && (
                            <p>No custom variable to mapping</p>
                          )}
                          {srcData.map((item, key) => (
                            <div className="col-lg-6" key={key}>
                              <Box mt={3}>
                                <FormControl className="dropdown  w-100">
                                  <label className="template-label-font template-label-position">
                                    {item.label}
                                  </label>
                                  <Select
                                    // isClearable={true}
                                    key={"select_" + key}
                                    className="conversion-list w-100 p-100"
                                    options={item?.SrcMappingList || []}
                                    value={setOption(item)}
                                    //defaultValue={item.MappingColumnName}
                                    onChange={(e) => onDefaultOption(e, item)}
                                  />
                                </FormControl>
                              </Box>
                            </div>
                          ))}
                        </div>
                        {srcData.length > 0 && (
                          <div className="row p-4">
                            <div className="col-lg-12 text-center">
                              <Button
                                className="sign-in-btn "
                                onClick={(e) => onSubmit(e)}
                                disabled={isLoading}
                              >
                                {isLoading ? "Processing..." : "Update"}
                              </Button>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </Box>
      )}
    </React.Fragment>
  );
}

export default ConversionMapping;
