import React, { memo } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { common } from "helpers";

const CallLog = memo(({ handleExpanded, isExpand }) => {
  function createData(type, venue, date) {
    return { type, venue, date };
  }

  const rows = [
    createData("Online", "We Are Designers", "20 Aug 2021"),
    createData("Online", "We Are Designers", "20 Aug 2021"),
  ];
  return (
    <div className="relative pb-3">
      <Divider />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center" className="ps-3">
          
          <Typography className="notes-header">&nbsp;Call Logs</Typography>
        </Box>
        <Box>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Box className="vertical-divider">
                <Divider orientation="vertical" />
              </Box>
            </Grid>
            <Grid item>
              <IconButton>
                <img
                  src={common.loadImg("filterCallkanban.svg")}
                  alt="meeting"
                />
              </IconButton>
            </Grid>

            <Grid item>
              <Box className="vertical-divider">
                <Divider orientation="vertical" />
              </Box>
            </Grid>
            <Grid item>
              {isExpand === "detail" ? (
                <Typography
                  className="expand pointer"
                  onClick={() => handleExpanded("tab")}
                >
                  Expand{" "}
                  <img src={common.loadImg("expandkanban.svg")} alt="expand" />
                </Typography>
              ) : (
                <Typography
                  className="expand pointer"
                  onClick={() => handleExpanded("detail")}
                >
                  Collapse{" "}
                  <img src={common.loadImg("expandkanban.svg")} alt="Collapse" />
                </Typography>
              )}
            </Grid>
            <Grid item>
              <Box className="vertical-divider">
                <Divider orientation="vertical" />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box>
        <Divider />
      </Box>

      <Box className="meet-table calllog-table">
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center" className="header">
                  Call Type
                </TableCell>

                <TableCell align="center" className="header">
                  Summary
                </TableCell>
                <TableCell align="center" className="header">
                  Created on
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.type}
                  sx={{ "&:last-child, &:last-child": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    align="center"
                    className="body-th"
                  >
                    {row.type}
                  </TableCell>
                  <TableCell align="center" className="body-th">
                    {row.venue}
                  </TableCell>

                  <TableCell align="center" className="body-th">
                    <Grid
                      container
                      justifyContent="flex-start"
                      alignItems={"center"}
                      spacing={1}
                    >
                      <Grid item>{row.date}</Grid>
                      <Grid item>
                        <IconButton>
                          <img
                            src={common.loadImg("roundArrokanban.svg")}
                            alt="arrow"
                          />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box mt={2} className="add-call-log">
        <Button className="save-note">
          Add call log
          <img src={common.loadImg("light-plus.svg")} alt="plus" />
        </Button>
      </Box>
    </div>
  );
});

export { CallLog };
