import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  FormControl,
  Button,
  Grid,
  Popover,
  Divider,
  MenuItem,
  Select,
} from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import Pagination from "react-js-pagination";
// dialog
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Link } from "react-router-dom";
import StatusBar from "elements/StatusBar";
// api
import * as Services from "helpers/service";
import { common, tools, envConfig, api } from "helpers";
// import { Select } from "@material-ui/core";

const formList = [
  { label: "Campaign", value: envConfig.CAMPAIGN_TRANS_TYPE_ID },
  { label: "Enquiry", value: envConfig.LIST_TRANS_TYPE_ID },
  { label: "Lead", value: envConfig.LEAD_TRANS_TYPE_ID },
  { label: "Deal", value: envConfig.DEAL_TRANS_TYPE_ID },
];

function SubMenuStaticTable(props) {
  const filterClose = () => {
    setOpenFilter(false);
  };

  let localTransID = localStorage.getItem("tyCode");
  let transId = common.getTransactionTypeId(
    localTransID || envConfig.CAMPAIGN_TRANS_TYPE_ID
  );
  let module =
    localTransID & (props.module?.module == true)
      ? formList.filter((i) => i.value == localTransID)[0]
      : formList[0];
  const navigate = useNavigate();
  const [transModule, setTransModule] = useState(module);
  const [transTypeId, setTransTypeId] = useState(transId);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selected, setSelected] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const propsData = props.data ? props.data.split(",") : [];
  const [status, setStatus] = useState("process");
  const [hidedColumns, setHidedColumns] = React.useState([]);
  const [isLoader, setIsLoader] = useState("");
  const [isReload, setIsReload] = useState(false);
  const [service, setService] = React.useState(props.module);
  const openNotification = Boolean(anchorEl);
  const id = openNotification ? "simple-popover" : undefined;
  const [isDialog, setDialog] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [openFilter, setOpenFilter] = useState(false);
  const userInfo = common.authInfo();

  const [result, setResult] = useState({
    columns: [],
    data: [],
    totalResults: 0,
    totalRecords: 0,
  });
  const [sort, setSort] = useState({
    field: props.sort ? props.sort.split(":")[0] : "",
    order: props.sort ? props.sort.split(":")[1] : "",
    default: "asc",
  });
  const [paging, setPaging] = useState({ page: 1, limit: 10 });
  const [isInit, setInit] = useState(false);

  useEffect(() => {
    setPaging({ page: 1, limit: 10 });
    loadData();
  }, [props.id]);

  useEffect(() => {
    setIsReload(true);
    setInit(true);
    setTransTypeId(common.getTransactionTypeId(transModule.value));
    localStorage.setItem("tyCode", transModule.value);
  }, [transModule]);

  useEffect(() => {
    if (isInit && isReload) {
      loadData();
    }
  }, [isReload]);

  useEffect(() => {
    if (isInit && deleteId) {
      setDialog("delete");
    }
  }, [deleteId]);

  const loadData = () => {
    setSelected([]);

    if (isInit) {
      setStatus("process");
      setIsReload(false);
    }
    let query = `?q=TenantId=${userInfo.TenantId}&OrgId=${
      userInfo.DefaultOrgId
    }&totalResults=true&limit=${paging.limit}&offset=${getPaging(
      "offset"
    )}&orderBy=CreatedOn:desc`;

    if (props.objectType && props.objectId) {
      let filterSummary = "";
      if (props.objectType == "lead") {
        query = `?q=LeadId=${
          props.objectId
        } AND UPPER(ItemName) LIKE '*${filterSummary}*'&totalResults=true&limit=${
          paging.limit
        }&offset=${getPaging("offset")}&orderBy=CreatedOn:desc`;
      } else {
        query = `?q=OpportunityId=${
          props.objectId
        } AND UPPER(ItemName) LIKE '*${filterSummary}*'&totalResults=true&limit=${
          paging.limit
        }&offset=${getPaging("offset")}&orderBy=CreatedOn:desc`;
      }
    }
    
    let orderBy = ``;
    if (sort.field) {
      orderBy = `&orderBy=${sort.field}:${sort.order}`;
    }

    let url = `${envConfig.BASE_API}${Services[service.api]}${query}${orderBy}`;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
      moreHead: { rfv: 2 },
      cType: 4,
    };
    api.call(
      data,
      (response) => {
        if (response) {
          setResult((prev) => ({
            ...prev,
            columns: service.attributes,
            data: response.data.items,
            totalResults: response.data.totalResults,
            totalRecords: isInit
              ? prev.totalRecords
              : response.data.totalResults,
          }));
          setStatus("success");
          if (response.data.items && response.data.items.length == 0) {
            setStatus("empty");
          }
        } else {
          setStatus("error");
        }
        setInit(true);
      },
      (error) => {
        setStatus("error");
        setInit(true);
      },
      (final) => {}
    );
  };
  // support
  const getPaging = (type) => {
    if (type == "offset") {
      return (paging.page - 1) * paging.limit;
    } else if (type == "serial") {
      return (paging.page - 1) * paging.limit;
    }
  };

  const changeLimit = (event) => {
    let limit = event.target.value;
    if (paging.limit != limit) {
      setPaging({ page: 1, limit });
      setIsReload(true);
    }
  };

  const handleClickExport = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const selectAll = (e) => {
    const { checked } = e.target;
    setSelected((pData) => {
      let nData = common.reParse(pData);
      if (checked) {
        result.data.map((i) => {
          if (!nData.includes(i[service.id])) {
            nData.push(i[service.id]);
          }
        });
      } else {
        result.data.map((i) => {
          nData.splice(nData.indexOf(i[service.id]), 1);
        });
      }
      return nData;
    });

    // selected data
    setSelectedData((pData) => {
      let nData = common.reParse(pData);
      if (checked) {
        result.data.map((i) => {
          let res = {};
          propsData.map((j) => {
            res[j] = i[j];
          });
          nData[i[service.id]] = res;
        });
      } else {
        result.data.map((i) => {
          delete nData[i[service.id]];
        });
      }
      return nData;
    });
  };

  const selectAllCheck = () => {
    let i = 0;
    result.data.map((item) => {
      if (selected.includes(item[service.id])) {
        ++i;
      }
    });
    return i > 0 && result.data.length === i;
  };

  // select
  const selectChange = (e, item) => {
    const { checked, value } = e.target;
    setSelected((pData) => {
      let nData = common.reParse(pData);
      if (checked) {
        nData.push(+value);
      } else {
        nData.splice(nData.indexOf(+value), 1);
      }
      return nData;
    });
    // selected data
    setSelectedData((pData) => {
      let nData = common.reParse(pData);
      if (checked) {
        let res = {};
        propsData.map((i) => {
          res[i] = item[i];
        });
        nData[+value] = res;
      } else {
        delete nData[+value];
      }
      return nData;
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePageChange = (pageNumber) => {
    if (paging.page != pageNumber) {
      setPaging({ ...paging, page: pageNumber });
      setIsReload(true);
      setStatus("process");
    }
  };

  const handleSortChange = (field) => {
    setSort({
      field,
      order:
        sort.field === field
          ? sort.order === "asc"
            ? "desc"
            : "asc"
          : sort.default,
      default: sort.default,
    });
    setIsReload(true);
    setStatus("process");
  };

  // delete
  const deleteData = (e) => {
    e.preventDefault();
    if (selected.length === 0) {
      common.snack("E", "Please select atleast one item");
    } else {
      setDialog("delete");
    }
  };
  const deleteNo = (e) => {
    e.preventDefault();
    setDialog("");
    setDeleteId("");
  };

  const deleteYes = (e) => {
    e.preventDefault();
    setAnchorEl(null);
    setDialog("");
    setIsLoader("delete");
    setDeleteId("");

    let result = [];
    selected.map((id, key) => {
      result.push({
        id: tools.randomString() + "_" + id,
        path: "/" + Services[service.api] + "/" + id,
        operation: "delete",
        payload: {},
      });
    });

    // api call
    var formAttr = { parts: result };
    let url = encodeURI(envConfig.BASE_API);
    let data = {
      url: url,
      type: "dynamic",
      method: "POST",
      auth: "token",
      body: JSON.stringify(formAttr),
      cType: 5,
    };

    api.call(
      data,
      (response) => {
        if (response) {
          common.snack("S", "Selected Item has been deleted successfully");
          setIsReload(true);
        }
        setIsLoader("");
        setSelected([]);
        setSelectedData({});
      },
      (error) => {
        common.snack("E", error);
        setIsLoader("");
      },
      (final) => {}
    );
  };

  const addNew = (e, idx) => {
    e.preventDefault();
    if (props.hasOwnProperty("menuType")) {
      props.openForm();
    } else {
      let url = idx === 0 ? service.addUrl : service.addUrl1;
      if (service.query) {
        url += service.query;
      }
      if (service.module) {
        url = `${url + "/" + transTypeId}`;
      }
      navigate(url);
    }
  };

  const openForm = (e, item) => {
    e.preventDefault();
    props.openForm(item);
  };
  return (
    <>
      {((props.menuType == "subMenu" && props.isExpand == "tab") ||
        !props.menuType) && (
        <Box p={1}>
          <Grid
            container
            alignItems="center"
            justify="space-between"
            sx={{
              flexDirection: { xs: "column", lg: "row" },
              spacing: { xs: 3, lg: 0 },
            }}
          >
            <Grid item display="flex" alignItems="center" xs={5}>
              <Grid>
                {props.menuType &&
                  props.menuType != "subMenu" &&
                  service.backUrl && (
                    <Link to={service.backUrl}>
                      <img
                        alt={"back"}
                        className="mx-2"
                        src={common.loadImg(
                          "iconly-light-arrow-down-circle.svg"
                        )}
                      />
                    </Link>
                  )}
              </Grid>
              <Grid>
                <Typography className="camp-title">{service.name}</Typography>
              </Grid>
            </Grid>
            <Grid item justifyContent="end" xs={7}>
              <Grid
                container
                alignItems="center"
                justifyContent="end"
                spacing={2}
                direction="row"
              >
                {service?.module == true && (
                  <Grid item>
                    {" "}
                    <FormControl
                      sx={{ m: 1, minWidth: { xs: 220, lg: 120 } }}
                      size="small"
                      className="dropdown"
                    >
                      <Select
                        inputProps={{
                          MenuProps: { disableScrollLock: true },
                        }}
                        value={transModule}
                        onChange={(e) => {
                          setTransModule(e.target.value);
                        }}
                      >
                        {formList.map((item, key) => {
                          return (
                            <MenuItem value={item} key={key}>
                              {item.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {status == "success" && (
                  <Grid item>
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      justifyContent="center"
                    >
                      <Grid item>
                        <IconButton onClick={() => setOpenFilter(true)}>
                          <img
                            src={common.loadImg("filterkanban.svg")}
                            alt="document"
                          />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid item>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                  >
                    {status == "success" && (
                      <Grid item>
                        <Box className="export">
                          <Button
                            variant="outlined"
                            className="flex align-items-center"
                            onClick={handleClickExport}
                          >
                            <Grid container alignItems="center" spacing={1}>
                              <Grid item>Action </Grid>
                              <Grid item>
                                <div className="crm-table-action">
                                  {selected.length}
                                </div>
                              </Grid>
                            </Grid>
                            <Grid item>
                              <Box className="more-icon ml-5">
                                <MoreHorizIcon />
                              </Box>
                            </Grid>
                          </Button>
                          <Popover
                            disableScrollLock
                            id={id}
                            open={openNotification}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                            className="notify-pop"
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                          >
                            <Box className="notify-box" p={1}>
                              <Grid
                                container
                                justify="space-between"
                                alignItems="center"
                              >
                                <Grid item xs={10}>
                                  <Typography
                                    component="div"
                                    className="flex align-items-center notify-title pl-2"
                                  >
                                    Action &nbsp;{" "}
                                    <div className="crm-table-action">
                                      {selected.length}
                                    </div>
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <IconButton onClick={handleClose}>
                                    <img
                                      src={common.loadImg(
                                        "dateClosekanban.svg"
                                      )}
                                      alt="close"
                                    />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </Box>
                            <Box>
                              <Divider />
                            </Box>
                            <Box>
                              <Grid
                                container
                                direction="column"
                                className="pointer"
                              >
                                <Grid item>
                                  <Box p={2} py={2}>
                                    <Grid
                                      container
                                      direction="row"
                                      justifyContent="space-between"
                                      spacing={1}
                                      onClick={(e) => deleteData(e)}
                                    >
                                      <Grid item>
                                        <Typography className="task-assign ">
                                          Mass Delete
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                  <Box>
                                    <Divider />
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Popover>
                        </Box>
                      </Grid>
                    )}
                    <Grid item>
                      <Box className="add-camp-btn">
                        <Button
                          variant="outlined"
                          onClick={(e) => addNew(e, 0)}
                        >
                          {service.addBtnText}
                          <img
                            src={common.loadImg("light-plus.svg")}
                            alt="document"
                            className="ml-5"
                          />
                        </Button>
                        {service.addBtnText1 && (
                          <Button
                            variant="outlined"
                            className="add-email-temp-btn"
                            onClick={(e) => addNew(e, 1)}
                          >
                            {service.addBtnText1}
                            <img
                              src={common.loadImg("light-plus.svg")}
                              alt="document"
                              className="ml-5"
                            />
                          </Button>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}

      <StatusBar status={status} />
      {status == "success" && (
        <>
          <TableContainer sx={{ maxHeight: 400 }} className="relative-position">
            <Table
              stickyHeader
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"medium"}
              className="osmo-crm-table "
            >
              <TableHead>
                <TableRow>
                  {result &&
                    result.columns &&
                    result.columns.length > 0 &&
                    result.columns
                      .filter((i) => !hidedColumns.includes(i.key))
                      .map((item, i) => (
                        <Fragment key={"fr" + i}>
                          {i == 0 && (
                            <TableCell
                              key={"col" + i}
                              align={"left"}
                              className="crm-table-header table-cell-checkbox"
                            >
                              <Grid item>
                                <input
                                  type="checkbox"
                                  className="checkbox-round-new"
                                  checked={selectAllCheck()}
                                  onChange={(e) => selectAll(e)}
                                  inputprops={{ "aria-label": "Select" }}
                                />
                              </Grid>
                            </TableCell>
                          )}
                          <TableCell
                            key={"cl" + i}
                            align={"left"}
                            className="crm-table-header"
                            onClick={() => handleSortChange(item.key)}
                          >
                            <Grid container spacing={2}>
                              <Grid item>
                                <Box className="table-head">
                                  {item.label}
                                  {/* <img
                                  src={common.loadImg("down-arrow-icon.svg")}
                                  className="ms-2"
                                /> */}
                                </Box>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </Fragment>
                      ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {result &&
                  result.data &&
                  result.data.length > 0 &&
                  result.data.map((item, i) => {
                    const labelId = `enhanced-table-checkbox-${i}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={"head" + i}
                        className=""
                      >
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          className="crm-table-name table-cell-checkbox"
                        >
                          <Grid item>
                            <input
                              type="checkbox"
                              className="checkbox-round-new ml-4"
                              value={item[service.id]}
                              checked={
                                selected.includes(item[service.id])
                                  ? true
                                  : false
                              }
                              inputprops={{ "aria-labelledby": "" }}
                              onChange={(e) => selectChange(e, item)}
                            />
                          </Grid>
                        </TableCell>
                        {result &&
                          result.columns &&
                          result.columns.length > 0 &&
                          result.columns
                            .filter((i) => !hidedColumns.includes(i.key))
                            .map((cItem, cKey) => {
                              const statusClass =
                                item[cItem.key] == "STARTED"
                                  ? "th-sts-feedback"
                                  : item[cItem.key] == "INPROGRESS"
                                  ? "th-sts-in-prog"
                                  : item[cItem.key] == "COMPLETED"
                                  ? "th-sts-closed"
                                  : "th-sts-pending";
                              return (
                                <TableCell
                                  align="left"
                                  className={
                                    cItem.type == "status"
                                      ? `${"crm-table-id " + statusClass}`
                                      : "crm-table-id"
                                  }
                                  key={cKey}
                                >
                                  {cItem.type == "unlink" ? (
                                    <Link
                                      to="#"
                                      onClick={(e) => openForm(e, item)}
                                    >
                                      {item[cItem.key] || "-"}
                                    </Link>
                                  ) : cItem.type === "date" ||
                                    cItem.type === "dateTime" ? (
                                    item[cItem.key] ? (
                                      moment(item[cItem.key]).format(
                                        "YYYY-MM-DD"
                                      )
                                    ) : (
                                      "-"
                                    )
                                  ) : cItem.type === "checkbox" ? (
                                    <div
                                      component="span"
                                      className={
                                        item[cItem.key] === "Y"
                                          ? "crm-table-sts-new"
                                          : "crm-table-sts-no"
                                      }
                                    >
                                      {item[cItem.key] === "Y" ? "Yes" : "No"}
                                    </div>
                                  ) : cItem.type === "lookup" ? (
                                    item[`${cItem.key}Desc`] ?? item[cItem.key]
                                  ) : cItem.type === "priority" ? (
                                    <Box
                                      component="span"
                                      px={2}
                                      className={`crm-table-sts-common ${
                                        item[cItem.key] == "1"
                                          ? "crm-table-sts-new"
                                          : item[cItem.key] == "2"
                                          ? "crm-table-sts-end"
                                          : "crm-table-sts-pending"
                                      }`}
                                    >
                                      {item[cItem.key] == "1"
                                        ? "Low"
                                        : item[cItem.key] == "2"
                                        ? "Medium"
                                        : "High"}
                                    </Box>
                                  ) : cItem.type === "multiLookup" ? (
                                    item[cItem.display.name] ??
                                    item[cItem.display.name]
                                  ) : (
                                    item[cItem.key] ?? "-"
                                  )}
                                </TableCell>
                              );
                            })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="table-footer-wrap">
            <div className="row-count">
              <p>
                Showing {(paging.page - 1) * paging.limit + 1} to{" "}
                {(paging.page - 1) * paging.limit +
                  (result.data ? result.data.length : 0)}{" "}
                of {result.totalResults} entries
              </p>
            </div>
            {props.isExpand == "tab" && (
              <div>
                <div className="pagination-wrap table-pagination-wrap">
                  <div>
                    <select
                      className="form-select row-per-page"
                      aria-label="Default select example"
                      onChange={(e) => changeLimit(e)}
                      value={paging.limit}
                    >
                      <option value="10">10/Page</option>
                      <option value="25">25/Page</option>
                      <option value="50">50/Page</option>
                      <option value="100">100/Page</option>
                    </select>
                  </div>
                  <div>
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={paging.page}
                      itemsCountPerPage={paging.limit}
                      totalItemsCount={result.totalResults}
                      pageRangeDisplayed={5}
                      prevPageText="Prev"
                      nextPageText="Next"
                      itemClassPrev="custom-prev-btn"
                      itemClassNext="custom-next-btn"
                      onChange={(pageNumber) => handlePageChange(pageNumber)}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* Delete Multiple Dialog */}
          <Dialog
            open={isDialog === "delete"}
            onClose={(e) => deleteNo(e)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableEnforceFocus
            disableScrollLock
          >
            <DialogTitle id="alert-dialog-title">
              {"Delete Confirmation"}
            </DialogTitle>
            <Box>
              <Divider />
            </Box>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this record(s)?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={(e) => deleteNo(e)}
                color="primary"
                className="automation-cancel-btn"
              >
                No
              </Button>
              <Button
                onClick={(e) => deleteYes(e)}
                color="primary"
                className="automation-footer-btn"
                autoFocus
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>

          {/* Filter Popup */}
          <Dialog
            //onClose={filterClose}
            open={openFilter}
            className="filter-dialog"
            disableEnforceFocus
            disableScrollLock
          >
            <DialogTitle id="alert-dialog-title">
              {/* Filter */}
              <Grid container display="flex" justifyContent="space-between">
                <Grid item className="notify-title">
                  {"Filter"}
                </Grid>
                <Grid item>
                  <img
                    src={common.loadImg("filterCloseIcon.svg")}
                    alt="close"
                    onClick={filterClose}
                  />
                </Grid>
              </Grid>
            </DialogTitle>
            <Box>
              <Divider />
            </Box>
            <DialogContent>
              {service &&
                service.attributes.map((field, idx) => {
                  return <div key={idx}>{field.label}</div>;
                })}
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                className="automation-footer-btn"
                autoFocus
              >
                Apply Filter
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}

export default SubMenuStaticTable;
