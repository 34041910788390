import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  // Select,
} from "@mui/material";
import Select, { components } from "react-select";
import FormControl from "@mui/material/FormControl";
import DynamicForm from "elements/DynamicForm";

import AccessDenied from "elements/AccessDenied";

import { common } from "helpers";
import serviceList from "assets/files/serviceList.json";

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};
const { ValueContainer, Placeholder } = components;

export default function QuickForm(props) {
  // const
  const authInfo = common.authInfo();

  const [module, setModule] = useState("");

  const isEduTech = common.isEduTech();

  const authData = {
    label: authInfo?.EmployeeName,
    value: authInfo?.EmpId.toString(),
  };

  const moduleList = [
    {
      code: "campaign",
      name: "Campaign",
      permission: "crm:campaigns",
      propsData: {
        data: { value1: "" },
        default: { CampaignOwnerId: authData },
      },
    },
    {
      code: "enquiry",
      name: "Enquiry",
      permission: "crm:enquiry",
      propsData: { data: { value1: "", value2: "" } },
    },
    {
      code: "lead",
      name: "Lead",
      permission: "crm:lead",
      propsData: {
        data: { value1: "", value2: "" },
        default: { SalespersonId: authData },
      },
      isdisabled: true,
    },
    {
      code: "deal",
      name: "Deal",
      permission: "crm:deal",
      propsData: {
        data: { value1: "", value2: "" },
        default: { SalesPersonId: authData },
      },
      isdisabled: true,
    },
    {
      code: "contacts",
      name: "Contacts",
      permission: "contact",
      propsData: {
        data: { value1: "" },
        default: { CampaignOwnerId: authData },
      },
    },
  ];

  const quickcreateModules = moduleList.map((item) => ({
    value: item.code,
    label: item.name,
    disable: isEduTech ? item.isdisabled : false,
  }));

  const onAction = () => {
    props.closeQuickCreate();
    setModule("");
  };

  // Select box style
  const selectStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    container: (provided, state) => ({
      ...provided,
      marginTop: 0,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      overflow: "visible",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      position: "absolute",
      top: state.hasValue || state.selectProps.inputValue ? -6 : "17%",
      transition: "top 0.1s, font-size 0.1s",
      fontSize: state.hasValue || state.selectProps.inputValue ? 11 : 13,
      color: "#9daabb",
    }),
  };

  return (
    <Dialog
      open={props.openQuickCreate}
      aria-labelledby="responsive-dialog-title"
      maxWidth={"sm"}
      fullWidth={true}
      className="target-modal-wrap add-action-popup"
      disableScrollLock
      style={{ maxHeight: "auto" }}
      // disableBackdropClick={true}
    >
      <DialogTitle
        id="responsive-dialog-title"
        className="popup-title quick-create"
      >
        <div display="flex" alignitems="center">
          <span className="notify-title pe-3">Quick Create</span>
          <FormControl
            fullWidth
            sx={{ maxWidth: 200, minHeight: 40 }}
            className="select-float-label"
          >
            <Select
              className="text-box custom-react-select py-1"
              labelId="select"
              id="select"
              label="Module"
              menuPortalTarget={document.body}
              isClearable={false}
              options={quickcreateModules}
              components={{
                ValueContainer: CustomValueContainer,
              }}
              // onChange={(data) => setModule(data.value)}
              onChange={(data) => {
                setModule(data.value);
              }}
              placeholder="Module"
              styles={selectStyles}
              // isOptionDisabled={(quickcreateModules) =>
              //   !isEduTech ? "" : quickcreateModules.disable
              // }
              isOptionDisabled={(quickcreateModules) =>
                quickcreateModules.disable
              }
            ></Select>
          </FormControl>
        </div>
      </DialogTitle>
      <DialogContent className="quick-create-form-wrap">
        {module === "" ? (
          <Typography py={4} textAlign="center">
            Please select the module above and create a quick one
          </Typography>
        ) : (
          <>
            {common.givenPermission(
              props.permission,
              `${
                common.ArrayJsonFind("code", module, moduleList, true)
                  .permission
              }`,
              "add"
            ) ? (
              <AccessDenied />
            ) : (
              <DynamicForm
                module={
                  common.ArrayJsonFind("code", module, moduleList, true).code
                }
                quick={true}
                {...moduleList[
                  common.ArrayJsonFind("code", module, moduleList, true).index
                ].propsData}
                onSave={() => onAction()}
                onCancel={() => onAction()}
              />
            )}
          </>
        )}
      </DialogContent>
      <DialogActions className="popup-footer justify-content-center">
        <div>
          {module == "" ? (
            <Button
              className="automation-cancel-btn"
              onClick={() => {
                props.closeQuickCreate();
                setModule("");
              }}
              type="button"
            >
              Cancel
            </Button>
          ) : null}
        </div>
      </DialogActions>
    </Dialog>
  );
}
