import React, { memo, useEffect, useState, useRef } from "react";
import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import { useParams, useOutletContext } from "react-router-dom";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import serviceList from "assets/files/serviceList.json";
import * as Services from "helpers/service";
import { common, envConfig, api, eduTechSerivces } from "helpers";
import StatusBar from "elements/StatusBar";
import { Form } from "react-bootstrap";

const Notes = memo(({ handleExpanded, isExpand }) => {
  const [permission] = useOutletContext();
  let { mod, id } = useParams();
  const userInfo = common.userInfo();
  const listInnerRef = useRef();
  const [formSubmit, setFormSubmit] = useState(false);
  const [status, setStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageLimit, setPageLimit] = useState(5);
  const [commentReponse, setCommentReponse] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [userList, setUserList] = useState([]);
  const [fields, setFields] = useState({
    TenantId: userInfo.pTenantId,
    OrgId: userInfo.pOrgId,
    ObjectId: 0,
    ObjectType: "",
    CommentType: "GENERAL",
    CommentContent: EditorState.createEmpty(),
  });
  const [errors, setErrors] = useState({
    CommentContent: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(null);
  const [isCommentLoading, setIsCommentLoading] = useState(false);
  const userData = common.authInfo();
  const accAdd = true; //!common.givenPermission(permission, common.ModToKey(mod), "add");
  const accDelete = true; //!common.givenPermission(permission, common.ModToKey(mod), "delete")
  const isEdutechAcc =
    common.isEduTech() && ["lead", "deal"].includes(mod) ? true : false;

  useEffect(() => {
    fields["ObjectId"] = id;
    fields["ObjectType"] = serviceList[mod].code;
    setFields(fields);
    getComments(0, "init");
    getUserList();
  }, []);

  const getUserList = () => {
    let url = `${envConfig.BASE_API}${Services.CRM_PAY_EMPLOYEE}?fields=Email&q=TenantId=${userData.TenantId} AND WorkingStatus='ACTIVE'&limit=1000&offset=0`;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
      moreHead: { rfv: 2 },
      cType: 4,
    };
    api.call(
      data,
      (response) => {
        if (response.status == 200) {
          let list = [];
          response.data.items.map((obj) => {
            list.push({
              text: obj.Email, //obj.FirstName + " " + obj.LastName,
              value: obj.Email,
              url: "#",
            });
          });
          setUserList(list);
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  const onEditorStateChange = function (editorState) {
    //removeUserHtml();
    setEditorState(editorState);
  };

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight && hasMore) {
        getComments(currentPage + 1, "scroll");
      }
    }
  };

  /**
   * Get all comments from DB
   */
  const getComments = (pageNo, type) => {
    setCurrentPage(pageNo);
    if (type && type == "scroll") {
      setIsCommentLoading("process");
    } else {
      setStatus("process");
    }
    let filter = `?q=ObjectId=${
      fields["ObjectId"]
    };&totalResults=true&orderBy=CreatedOn:desc&limit=${pageLimit}&offset=${
      pageNo * pageLimit
    }`;

    let url = encodeURI(envConfig.BASE_API + Services.CRM_COMMENTS + filter);
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };
    api.call(
      data,
      (response) => {
        if (response.status == 200) {
          setHasMore(response.data.hasMore);
          if (type && type == "scroll") {
            setCommentReponse((oldData) => {
              return [...new Set([...oldData, ...response.data.items])];
            });
          } else {
            setCommentReponse(response.data.items);
          }
          if (response.data.items.length > 0) {
            // setCommentReponse(response.data.items);
            setStatus("success");
            setIsCommentLoading("success");
          } else {
            setStatus("empty");
          }
        }
      },
      (error) => {
        setStatus("error");
      },
      (final) => {}
    );
  };

  /**
   * Delete comment
   */
  const deleteItem = (id) => {
    common.confirmDelete(true, (response) => {
      // setStatus("process");
      handleDeleteBlock(id);
    });
  };

  const handleDeleteBlock = (id) => {
    let url = envConfig.BASE_API + Services.CRM_COMMENTS + "/" + id;

    let data = {
      url: url,
      type: "dynamic",
      method: "DELETE",
      auth: "token",
    };
    api.call(
      data,
      (response) => {
        if (response.status == 204) {
          let responseList = commentReponse;
          let updateData = [];
          if (responseList != null && responseList != null) {
            updateData = responseList.filter((comment) => {
              return id !== comment.CommentId;
            });
            responseList = updateData;
          }
          setCommentReponse(responseList);
          if (responseList.length == 0) {
            setStatus("empty");
          }
          common.snack("S", "Selected item deleted successfully.");
          // getComments();
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  const htmlToString = (str) => {
    str = str.replace("<p>", "");
    str = str.replace("</p>", "");
    return str.replace(/<[^>]*>?/gm, "").trim();
  };

  /**
   * Form Submit Handler
   */
  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmit(true);

    fields["CommentContent"] = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setFields(fields);
    let htmlData = htmlToString(fields["CommentContent"]);
    if (htmlData.length > 0) {
      setIsLoading(true);
      let str = htmlData.split(" ");
      let users = [];
      str.map((item, i) => {
        if (item[0] == "@") {
          users.push(item.substring(1));
        }
      });
      let sendData = {};
      //sent tagging mail to mentioned users
      if (users.length > 0) {
        let htmlContent = `<table><tr><td>Hi!</td></tr><tr><td>${
          fields["CommentContent"]
        }</td></tr><tr><td><a href="${
          isEdutechAcc ? envConfig.UMAS_URL : envConfig.CRM_URL
        }/crm/${mod}/details/${
          fields["ObjectId"]
        }">Click here to view records</a></td></tr></table>`;
        console.log(str, users);
        sendData = {
          from: envConfig.SENDER_EMAIL,
          to: users.join(","),
          subject: "OSMO CRM - You are tagged in " + serviceList[mod].label,
          content: htmlContent,
        };
        CreateComment(sendData);
      } else {
        CreateComment(sendData);
      }
    } else {
      errors.CommentContent = "Please enter note";
      setErrors(errors);
    }
  };

  /**
   * Create comment method
   */
  const CreateComment = (sendData) => {
    let url = envConfig.BASE_API + Services.CRM_COMMENTS;
    let data = {
      url: url,
      type: "dynamic",
      method: "POST",
      auth: "token",
      body: JSON.stringify(fields),
      cType: 4,
    };
    // return
    api.call(
      data,
      (response) => {
        if (response.status == 201) {
          setStatus("success");
          common.snack("S", "Notes saved successfully");
          if (sendData && sendData.to) {
            eduTechSerivces.sendUserTagMail(sendData);
          }
          let data = commentReponse;
          if (data.length == 0) {
            data.push(response.data);
          } else {
            data.unshift(response.data);
          }
          fields.CommentContent = EditorState.createEmpty();
          errors.CommentContent = "";
          setEditorState(EditorState.createEmpty());
          setFields(fields);
          setErrors(errors);
          setFormSubmit(false);
          setIsLoading(false);
          setCommentReponse(data);
          // getComments();
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  return (
    <div>
      {accAdd && (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography className="notes-header">Your Notes</Typography>
            {isExpand === "detail" ? (
              <Typography
                className="expand pointer"
                onClick={() => handleExpanded("tab")}
              >
                Expand{" "}
                <img src={common.loadImg("expandkanban.svg")} alt="expand" />
              </Typography>
            ) : (
              <Typography
                className="expand pointer"
                onClick={() => handleExpanded("detail")}
              >
                Collapse{" "}
                <img src={common.loadImg("expandkanban.svg")} alt="Collapse" />
              </Typography>
            )}
          </Box>
          <Box>
            <Divider />
          </Box>

          <Box mt={2} className="notes-editor-wrap">
            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={onEditorStateChange}
              mention={{
                separator: " ",
                trigger: "@",
                suggestions: userList,
              }}
            />
            {errors.CommentContent !== "" && formSubmit === true && (
              <Form.Text className="error-txt">
                {errors.CommentContent}
              </Form.Text>
            )}
          </Box>
          <Box mt={2}>
            <Button className="save-note" onClick={(e) => handleSubmit(e)}>
              {isLoading ? "Processing..." : "Save note"}{" "}
              <img src={common.loadImg("light-plus.svg")} alt="plus" />
            </Button>
          </Box>
        </>
      )}
      <Box mt={2}>
        <Divider />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={2}
      >
        <Typography className="notes-header">Saved Notes</Typography>
      </Box>
      <Box mt={2}>
        <Divider />
      </Box>
      <StatusBar status={status} />
      <div className="notes-wrap-list" onScroll={onScroll} ref={listInnerRef}>
        {status == "success" &&
          commentReponse &&
          commentReponse.length > 0 &&
          commentReponse.map((item, i) => {
            return (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt={2}
                key={"note_" + i}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="ms-2">
                    <div className="d-flex align-items-center">
                      <div className="me-3">
                        <img
                          src={
                            envConfig.IMG_SERVLET_URL +
                            "?imageId=" +
                            item.UserId +
                            "&imageType=USER"
                          }
                          className="notes-imgs"
                        />
                      </div>
                      <div className="meeting-time">
                        {item?.FirstName} {item?.LastName} -{" "}
                        {common.formatDateAMPM(item.CreatedOn, "MMM DD", true)}
                      </div>
                    </div>
                    <div className="meeting-notes">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: item.CommentContent,
                        }}
                      ></p>
                    </div>
                  </div>
                </Box>
                {accDelete && (
                  <Box>
                    <IconButton onClick={() => deleteItem(item.CommentId)}>
                      <img
                        src={common.loadImg("blue-delete-icon.svg")}
                        alt="download"
                      />
                    </IconButton>
                  </Box>
                )}
              </Box>
            );
          })}
        <div id="userList"></div>
        <StatusBar status={isCommentLoading} />
      </div>
    </div>
  );
});

export { Notes };
