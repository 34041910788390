import React, { memo, useState } from "react";
import { Link } from "react-router-dom";

import {
  Box,
  Button,
  IconButton,
  Grid,
  Typography,
  Divider,
  InputAdornment,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  InputLabel,
} from "@mui/material";
import { common } from "helpers";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CircleChecked from "@material-ui/icons/CheckCircleOutline";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import { blue } from "@material-ui/core/colors";
import TextField from "@mui/material/TextField";

// import Radio from "@mui/material/Radio";
// import RadioGroup from "@mui/material/RadioGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";

// import FormLabel from "@mui/material/FormLabel";

const BasicInformation = memo(() => {
  const onSubmit = (e) => {
    e.preventDefault();
  };

  const [checked, setChecked] = useState(true);
  const handleRadio = () => {
    setChecked(false);
  };
  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <Box px={5} mt={3} className="template-box-border">
      <div className="row">
        <div className="col-12 mt-2 ">
          <div className="card border-color-F2F9FD">
            <div className="card-body header-border-padding  p-0">
              <Box alignItems="center" justifyContent="center" className="">
                <Box py={3}>
                  <Grid
                    container
                    spacing={3}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item>
                          <Typography className="page-title add-report-title mx-3">
                            Create New Report
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item marginRight={5}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                      >
                        <Grid item className="report-count-active-font">
                          <Grid container spacing={4}>
                            <Grid item className="">
                              <span className=" report-count-active-2 report-radius">
                                01
                              </span>
                              <span className=" report-count-active-2 ">
                                {" "}
                                02{" "}
                              </span>
                              <span className="report-count-active-2 report-radius-2">
                                03
                              </span>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item className="close-icn-border ">
                      <Box className="revert-close revert-close1">
                        <Button className="save-note" type="button">
                          Close
                          <Link to="#">
                            {" "}
                            <img
                              src={common.loadImg("closeKanban.svg")}
                              alt="close"
                            />
                          </Link>
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Divider />
                </Box>
                <Box py={3}>
                  <Grid
                    container
                    spacing={3}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography className="module-report-title mt-3 mb-3 mx-3">
                        Basic Information
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Divider />
                </Box>
                <Box mt={2} className="camp-edit meet-table">
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableBody>
                        <TableRow
                          sx={{ "&:last-child, &:last-child": { border: 0 } }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            className="body-th"
                            style={{ width: "50%" }}
                          >
                            <Box className="filled-box">
                              <TextField
                                id="filled-basic"
                                variant="filled"
                                label="Report Name"
                              />
                            </Box>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            className="body-th"
                            style={{ width: "50%" }}
                          >
                            <Box className="filled-box">
                              <TextField
                                id="filled-basic"
                                label="Report Tag"
                                variant="filled"
                              />
                            </Box>
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{ "&:last-child, &:last-child": { border: 0 } }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            className="body-th"
                            style={{ width: "50%" }}
                          >
                            <Box className="text-box custom-select">
                              <FormControl
                                fullWidth
                                className="select-float-label represent-select"
                              >
                                <InputLabel
                                  id="select"
                                  className="floating-label"
                                >
                                  Report Type
                                </InputLabel>
                                <Select
                                  labelId="select"
                                  id="select"
                                  value={age}
                                  label="Campaign Option"
                                  onChange={handleChange}
                                >
                                  <MenuItem value={10}>Option 1</MenuItem>
                                  <MenuItem value={20}>Option 2</MenuItem>
                                  <MenuItem value={30}>Option 3</MenuItem>
                                </Select>
                              </FormControl>
                            </Box>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            className="body-th"
                            style={{ width: "50%" }}
                          >
                            <Box className="filled-box">
                              <TextField
                                id="filled-basic"
                                label="Description"
                                variant="filled"
                              />
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
});

export default BasicInformation;
