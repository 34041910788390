import React, { Fragment, useEffect, useState } from "react";

import InfiniteScroll from "react-infinite-scroll-component";

import { api, common, envConfig } from "helpers";
import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import { Link, useOutletContext } from "react-router-dom";
import {
  Divider,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";

import AccessDenied from "elements/AccessDenied";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";

import StatusBar from "elements/StatusBar";

import { useDebounce } from "helpers/debounce";
import { formatInitialText } from "helpers/tools";
import {
  CRM_DEFAULT_GROUPS_1,
  CRM_DEFAULT_GROUPS_2,
  CRM_DEFAULT_GROUPS_3,
  CRM_DEFAULT_GROUPS_4,
  ROLES,
} from "helpers/service";
import Permission from "../../../../assets/files/permission";
import { findIsSubModule } from "helpers/components/roles";
import { OptionPopover } from "./optionPopover";
let ModNameChange = envConfig.ModNameChange;
const DATE_FORMAT = "DD-MM-YYYY";
const LIST_LIMIT = 10;
const TIMER_INTERVAL = 1000;
const EXTENDED_PERMISSIONS_ROLES = ["lead", "deal"];
const MODULE_OPTIONS = ["r", "a", "u", "d", "i", "e"];

const ROLE_ADD_SUCCESS = ModNameChange
  ? "Permission added successfully"
  : "Role added successfully";
const ROLE_ADD_FAILED = ModNameChange
  ? "Permission save failed"
  : "Role save failed";
const ROLE_UPDATE_SUCCESS = ModNameChange
  ? "Permission updated successfully"
  : "Role updated successfully";
const ROLE_UPDATE_FAILED = ModNameChange
  ? "Permission update failed"
  : "Role update failed";

function Role(props) {
  const [permission] = useOutletContext();
  const [rightAnchorEl, setRightAnchorEl] = useState(null);
  const [editAnchorEl, setEditAnchorEl] = useState(null);

  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [resultList, setResultList] = useState([]);
  const [total, setTotal] = useState(null);
  const [hasMore, setHasMore] = useState(null);
  const [fullPageLoading, setFullPageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchWord, setSearchWord] = useState("");
  const [roleName, setRoleName] = useState("");
  const [error, setError] = useState({});
  const [editedRole, setEditedRole] = useState(null);
  const [expandedModules, setExpandedModules] = useState([0]);
  const [disabledUpdateBtn, setDisabledUpdateBtn] = useState(true);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [roleSaveLoading, setRoleSaveLoading] = useState(false);
  const [expand, setExpand] = useState(false);
  const [index, setIndex] = useState();

  useEffect(() => {
    setFullPageLoading(true);
  }, []);

  const debouncedSearchTerm = useDebounce(searchWord, TIMER_INTERVAL);
  useEffect(() => {
    setIsLoading(true);
    loadCustomersList(0, debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  const loadCustomersList = (pageNo, searchText) => {
    setPage(pageNo);
    let userData = common.authInfo();

    let filter = `?q=GroupDesc NOT IN ('${CRM_DEFAULT_GROUPS_1}','${CRM_DEFAULT_GROUPS_2}','${CRM_DEFAULT_GROUPS_3}','${CRM_DEFAULT_GROUPS_4}') AND TenantId=${
      userData.TenantId
    } AND UPPER(GroupDesc) LIKE '*${searchText}*' &limit=${LIST_LIMIT}&offset=${
      pageNo * LIST_LIMIT
    }&finder=FindParentGroups&orderBy=CreatedOn:desc&totalResults=true`;

    let url = encodeURI(envConfig.AUTH_REST_URL + ROLES + filter);

    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "basic",
      moreHead: { rfv: 2 },
      cType: 4,
    };

    api.call(
      data,
      (response) => {
        if (response) {
          let result = response.data;
          setResultList((oldData) => {
            if (pageNo === 0) {
              return result.items;
            } else {
              return [...new Set([...oldData, ...result.items])];
            }
          });

          if (result.items?.length > 0) {
            if (pageNo === 0) {
              handleSelectItem(result.items[0]);
            }
          } else {
            handleSelectItem(null);
          }
          setTotal(result.totalResults);
          setHasMore(result.hasMore);
        }

        setFullPageLoading(false);
        setIsLoading(false);
      },
      (error) => {
        setFullPageLoading(false);
        setIsLoading(false);
      },
      (final) => {
        setFullPageLoading(false);
        setIsLoading(false);
      }
    );
  };

  const onSubmit = () => {
    if (!roleName) {
      setError((oldData) => {
        return {
          ...oldData,
          roleName: "This field is required",
        };
      });
    } else {
      registerRole();
    }
  };
  const handleExpand = () => {
    setExpand(true);
  };
  const handleExpand1 = () => {
    setExpand(false);
  };

  const registerRole = () => {
    setRoleSaveLoading(true);
    let userData = common.authInfo();
    let url = encodeURI(envConfig.AUTH_REST_URL + ROLES);

    let params = {
      GroupDesc: roleName,
      GroupName: common.uniqueKey(),
      TenantId: userData.TenantId,
      UserAccess: common.stringify(Permission),
      LdapSync: "Y",
    };

    if (editedRole) {
      url = `${url}/${editedRole.GroupId}`;

      params = {
        ...params,
        GroupName: editedRole.GroupName,
        TenantId: editedRole.TenantId,
        UserAccess: editedRole.UserAccess,
      };
    }

    let data = {
      url: url,
      type: "dynamic",
      method: `${editedRole ? "PATCH" : "POST"}`,
      auth: "basic",
      body: JSON.stringify(params),
      cType: 4,
    };

    api.call(
      data,
      (response) => {
        setRoleSaveLoading(false);
        if (response && (response.status === 201 || response.status === 200)) {
          let msg = ROLE_ADD_SUCCESS;

          if (editedRole) {
            msg = ROLE_UPDATE_SUCCESS;
          }
          common.snack("S", msg);
          handleRoleEditClose();
          handleClose();
          setIsLoading(true);
          loadCustomersList(0, searchWord);
        } else {
          let msg = ROLE_ADD_FAILED;
          if (editedRole) {
            msg = ROLE_UPDATE_FAILED;
          }
          common.snack("E", msg);
        }
      },
      (error) => {
        setRoleSaveLoading(false);
        let msg = ROLE_ADD_FAILED;
        if (editedRole) {
          msg = ROLE_UPDATE_FAILED;
        }
        common.snack("E", msg);
      },
      (final) => {
        setRoleSaveLoading(false);
      }
    );
  };

  const updatePermissions = () => {
    if (!isUpdateLoading && selectedItem) {
      setIsUpdateLoading(true);
      let url = encodeURI(envConfig.AUTH_REST_URL + ROLES);
      url = `${url}/${selectedItem.GroupId}`;

      let params = {
        GroupDesc: selectedItem.GroupDesc,
        GroupName: selectedItem.GroupName,
        TenantId: selectedItem.TenantId,
        UserAccess: common.stringify(selectedItem.UserAccess),
        LdapSync: "Y",
      };

      let data = {
        url: url,
        type: "dynamic",
        method: `${"PATCH"}`,
        auth: "basic",
        body: JSON.stringify(params),
        cType: 4,
      };

      api.call(
        data,
        (response) => {
          setIsUpdateLoading(false);
          if (
            response &&
            (response.status === 201 || response.status === 200)
          ) {
            common.snack("S", ROLE_UPDATE_SUCCESS);
            setDisabledUpdateBtn(true);
            setIsLoading(true);
            loadCustomersList(0, searchWord);
          } else {
            common.snack("S", ROLE_UPDATE_FAILED);
          }
        },
        (error) => {
          setIsUpdateLoading(false);
          common.snack("S", ROLE_UPDATE_FAILED);
        },
        (final) => {
          setIsUpdateLoading(false);
        }
      );
    }
  };

  const handleError = (key, value) => {
    setError((oldData) => {
      return {
        ...oldData,
        [key]: value ? value : "",
      };
    });
  };

  const handleSearchChange = (event) => {
    setSearchWord(event.target.value);
  };

  const handleSelectUser = (item, index) => {
    if (!editAnchorEl) {
      setSelectedIndex(index);
      handleSelectItem(item);
    }
  };

  const handleSelectItem = (item) => {
    if (item && typeof item.UserAccess === "string") {
      item.UserAccess = common.parseOnly(item.UserAccess);
    }
    setSelectedItem(item);
    setDisabledUpdateBtn(true);
  };

  const handleClickOpen = (isEdit) => {
    setOpen(true);

    setRoleName(isEdit ? editedRole.GroupDesc : "");
    if (!isEdit) {
      setEditedRole(null);
    }
    setError({});
  };
  const handleClose = () => {
    setOpen(false);
    const taskListView1 = document.getElementById("customer-user-list");
    if (taskListView1) {
      taskListView1.classList.remove("sm-customer-user-list");
    }
  };

  const handleSelectModule = (index) => {
    let expandedModulesCopy = [...expandedModules];
    if (isModuleExpended(index)) {
      expandedModulesCopy = expandedModulesCopy?.filter((x) => x !== index);
    } else {
      expandedModulesCopy.push(index);
    }

    setExpandedModules(expandedModulesCopy);
  };
  const [isOpen, setIsOpen] = useState(true);
  const clickAwayHandler = () => {
    setIsOpen(false);
    const taskListView1 = document.getElementById("customer-user-list");
    if (taskListView1) {
      taskListView1.classList.remove("sm-customer-user-list");
    }
  };
  const handleRoleEditOpen = (event, item, i) => {
    setIsOpen(true);
    setEditAnchorEl(event?.currentTarget);
    setEditedRole(item ? item : null);
    setIndex(i);
    const taskListView1 = document.getElementById("customer-user-list");
    if (taskListView1) {
      taskListView1.classList.add("sm-customer-user-list");
    }
  };
  const handleRoleEditClose = () => {
    setEditAnchorEl(null);
  };

  const detailsMoreClick = (event) => {
    setRightAnchorEl(event.currentTarget);
  };
  const detailsCloseClick = () => {
    setRightAnchorEl(null);
  };

  const handleSelectChange = (value, key, option, row) => {
    const selectedItemCopy = common.reParse(selectedItem);
    const userAccess = selectedItemCopy.UserAccess;
    if (row) {
      userAccess[key][row][option] = value || "1";
    } else {
      userAccess[key][option] = value || "1";
    }
    selectedItemCopy.UserAccess = userAccess;
    setSelectedItem(selectedItemCopy);

    if (disabledUpdateBtn) {
      setDisabledUpdateBtn(false);
    }
  };

  const getRolesList = (subModule) => {
    let list = envConfig.permissions;
    // if (EXTENDED_PERMISSIONS_ROLES.includes(subModule.toLowerCase())) {
    if (EXTENDED_PERMISSIONS_ROLES.includes(subModule)) {
      list = envConfig.allPermissions;
    }

    return list;
  };

  const isModuleExpended = (index) => {
    const rowIndex = expandedModules?.findIndex((x) => x === index);
    return rowIndex > -1;
  };

  const rightMoreOpen = Boolean(rightAnchorEl);
  const editMoreOpen = Boolean(editAnchorEl);

  return (
    <React.Fragment>
      {common.givenPermission(permission, "configure:roles", "read") ? (
        <AccessDenied />
      ) : (
        <Box className="template-box-border p-4 ">
          <div className="row ">
            {/* <div className="col-md-4"> */}

            <div className={expand === true ? "expand-role-names" : "col-md-4"}>
              <div className="card border-color-F2F9FD">
                <div className="card-body p-0">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      className="p-2"
                    >
                      <Grid item>
                        <h5 className="d-flex align-items-center title mb-0">
                          <Link
                            to="/setting/view/configure"
                            className="text-decoration-none"
                          >
                            <img
                              alt={"back"}
                              src={common.loadImg(
                                "iconly-light-arrow-down-circle.svg"
                              )}
                              width={30}
                              height={30}
                            />
                          </Link>
                          <label className="d-flex align-items-center page-title">
                            Permissions
                          </label>
                        </h5>
                      </Grid>
                      <Grid item>
                        {!common.givenPermission(
                          permission,
                          "configure:roles",
                          "add"
                        ) ? (
                          <Box className="flex" alignItems="center">
                            <Link
                              to="#"
                              className="text-decoration-none"
                              onClick={() => handleClickOpen()}
                            >
                              <span className="mx-2 add-new">Add New</span>
                            </Link>

                            <div className="add-new-button d-flex align-items-center justify-content-center">
                              <Link
                                to="#"
                                className="text-decoration-none"
                                onClick={() => handleClickOpen()}
                              >
                                <span className="d-flex align-items-center plus-icon">
                                  +
                                </span>
                              </Link>
                            </div>
                          </Box>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Box>
                  {fullPageLoading ? (
                    <StatusBar status="process" />
                  ) : (
                    <Fragment>
                      <div className="d-flex bg-F2F9FD ">
                        <Box className="filter-search" width="100%">
                          <FormControl sx={{ width: "100%" }}>
                            <OutlinedInput
                              id="role-search"
                              placeholder={"Search"}
                              value={searchWord}
                              onChange={handleSearchChange}
                              endAdornment={
                                <InputAdornment position="end">
                                  <img
                                    src={common.loadImg("common-search.svg")}
                                    alt="search"
                                  />
                                </InputAdornment>
                              }
                              aria-describedby="outlined-weight-helper-text"
                            />
                          </FormControl>
                        </Box>
                      </div>

                      <div
                        className="bg-white customer-user-list"
                        id="customer-user-list"
                      >
                        {/* Profile */}
                        <Grid container direction="column">
                          <InfiniteScroll
                            scrollThreshold={0.6}
                            next={() => {
                              loadCustomersList(page + 1, searchWord);
                            }}
                            hasMore={hasMore}
                            loader={
                              !isLoading ? <StatusBar status="process" /> : null
                            }
                            dataLength={resultList?.length || 0}
                          >
                            {isLoading ? (
                              <StatusBar status="process" />
                            ) : resultList?.length ? (
                              resultList?.map((item, i) => {
                                const initial = formatInitialText(
                                  item.GroupDesc
                                );

                                return (
                                  <Grid key={`${i}_${item.GroupId}`} item>
                                    <Box>
                                      <Grid
                                        container
                                        alignItems="center"
                                        className="pointer color-divider"
                                        onClick={() =>
                                          handleSelectUser(item, i)
                                        }
                                      >
                                        <Grid item xs={2} mt={0.5}>
                                          <Box px={2} py={1}>
                                            <Box className="img-box uppercase">
                                              {initial}
                                            </Box>
                                          </Box>
                                          {i === selectedIndex ? (
                                            <div className="active active-location"></div>
                                          ) : null}
                                        </Grid>
                                        {/* <Grid item xs={1}>
                                      <Box
                                        display="flex"
                                        justifyContent="center"
                                      >
                                        <Box height="85px" width="2px" pl={3}>
                                          <Divider orientation="vertical" />
                                        </Box>
                                      </Box>
                                    </Grid> */}
                                        <Grid
                                          item
                                          xs={9}
                                          className="divider-color"
                                        >
                                          <Box px={2}>
                                            <Grid
                                              container
                                              justifyContent="space-between"
                                              alignItems="center"
                                            >
                                              <Grid item>
                                                <div className="mt-2 font-weight-bold">
                                                  <label
                                                    className={
                                                      i === selectedIndex
                                                        ? "location-names-active"
                                                        : "location-names"
                                                    }
                                                  >
                                                    {item.GroupDesc || ""}
                                                  </label>
                                                  <p className="setting-card-content fz-14">
                                                    {item.GroupDesc || ""}
                                                  </p>
                                                </div>
                                              </Grid>
                                              <div className="my-auto pe-4 ">
                                                {item.GroupDesc.toLowerCase() !==
                                                  "osmo default" &&
                                                !common.givenPermission(
                                                  permission,
                                                  "configure:roles",
                                                  "update"
                                                ) ? (
                                                  <>
                                                    <MoreHorizOutlinedIcon
                                                      className="pointer"
                                                      aria-describedby={`role-edit-popover_${i}`}
                                                      onClick={(event) => {
                                                        event.stopPropagation();
                                                        handleRoleEditOpen(
                                                          event,
                                                          item,
                                                          i
                                                        );
                                                      }}
                                                    />

                                                    {isOpen && (
                                                      <OptionPopover
                                                        item={item}
                                                        rowIndex={i}
                                                        editMoreOpen={
                                                          index === i
                                                            ? editMoreOpen
                                                            : false
                                                        }
                                                        editAnchorEl={
                                                          editAnchorEl
                                                        }
                                                        handleClickOpen={
                                                          handleClickOpen
                                                        }
                                                        handleRoleEditClose={
                                                          handleRoleEditClose
                                                        }
                                                        clickAwayHandler={
                                                          clickAwayHandler
                                                        }
                                                      />
                                                    )}
                                                  </>
                                                ) : null}
                                              </div>
                                            </Grid>
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </Grid>
                                );
                              })
                            ) : (
                              <StatusBar status="empty" />
                            )}
                          </InfiniteScroll>
                        </Grid>
                      </div>
                    </Fragment>
                  )}
                  {!fullPageLoading && (
                    <Box
                      sx={{ width: 1 }}
                      px={1}
                      py={1}
                      className="location-footer"
                    >
                      {"Showing" +
                        " " +
                        `${resultList?.length ? 1 : 0} to ${
                          resultList?.length || 0
                        } of ${total || 0}`}
                    </Box>
                  )}
                </div>
              </div>
            </div>

            <div className={expand === true ? "col-md-12 " : "col-md-8"}>
              <div className="card-custom">
                <div className=" permission-table">
                  <div className="card-body p-0">
                    <div className="d-flex justify-content-between bottom-border-divider">
                      <h5 className="right-section-title my-3">Permissions</h5>

                      <div className="d-flex justify-content-center align-items-center my-auto pe-4">
                        {selectedItem?.GroupDesc.toLowerCase() !==
                          "osmo default" &&
                        !common.givenPermission(
                          permission,
                          "configure:roles",
                          "update"
                        ) ? (
                          <>
                            {isLoading || disabledUpdateBtn ? (
                              <Button
                                variant="contained"
                                disabled
                                className="disabled-btn save-change-btn me-4"
                              >
                                Save Changes
                              </Button>
                            ) : (
                              <Button
                                className="automation-footer-btn me-4"
                                type="button"
                                onClick={updatePermissions}
                              >
                                {isUpdateLoading
                                  ? "Processing"
                                  : "Save Changes"}
                              </Button>
                            )}
                          </>
                        ) : null}

                        {expand == false ? (
                          <Typography
                            className="expand pointer"
                            onClick={handleExpand}
                          >
                            Expand{" "}
                            <img
                              src={common.loadImg("expandkanban.svg")}
                              alt="expand"
                              className="me-3"
                            />
                          </Typography>
                        ) : (
                          <Typography
                            className="expand pointer"
                            onClick={handleExpand1}
                          >
                            Collapse{" "}
                            <img
                              src={common.loadImg("expandkanban.svg")}
                              alt="expand"
                              className="me-3"
                            />
                          </Typography>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="settings-secondary" /> */}

                <TableContainer
                  component={Paper}
                  className="role-table role-permission-items-table "
                >
                  <div className="table-scroll-position-collapse role-permission-opacity ">
                    <Table aria-label="simple table ">
                      <TableHead>
                        <TableRow>
                          <TableCell>Module</TableCell>
                          <TableCell align="center">To Read</TableCell>
                          <TableCell align="center">To Add</TableCell>
                          <TableCell align="center">To Update</TableCell>
                          <TableCell align="center">To Delete</TableCell>
                          <TableCell align="center">To Import</TableCell>
                          <TableCell align="center">To Export</TableCell>
                        </TableRow>
                      </TableHead>

                      {!isLoading && selectedItem?.UserAccess ? (
                        <TableBody>
                          {common
                            .objectKeysArray(selectedItem.UserAccess)
                            ?.map((key, index) => {
                              if (key !== "VersionNumber") {
                                const isSubModule = findIsSubModule(
                                  selectedItem.UserAccess,
                                  key
                                );
                                const rowData = selectedItem.UserAccess[key];
                                return (
                                  <Fragment key={`${key}_${index}`}>
                                    <TableRow
                                      className="module-head"
                                      onClick={() => handleSelectModule(index)}
                                    >
                                      <TableCell colSpan={7}>
                                        {isModuleExpended(index) ? (
                                          <img
                                            src={common.loadImg(
                                              "minus-icon.svg"
                                            )}
                                            className="me-2 pointer"
                                            alt="minus"
                                          />
                                        ) : (
                                          <img
                                            src={common.loadImg(
                                              "plus-icon.svg"
                                            )}
                                            className="me-2 pointer"
                                            alt="plus"
                                          />
                                        )}
                                        {key || ""}
                                      </TableCell>
                                    </TableRow>

                                    {isModuleExpended(index) ? (
                                      isSubModule ? (
                                        common
                                          .objectKeysArray(rowData)
                                          .map((row) => {
                                            return (
                                              <TableRow
                                                key={row}
                                                sx={{ border: 0 }}
                                              >
                                                <TableCell
                                                  component="th"
                                                  scope="row"
                                                  className="module-name"
                                                >
                                                  {row}
                                                </TableCell>
                                                {MODULE_OPTIONS?.map(
                                                  (option) => (
                                                    <TableCell
                                                      key={`${row}_${option}`}
                                                      align="center"
                                                      className="permission-selectbox"
                                                    >
                                                      <FormControl
                                                        sx={{
                                                          m: 1,
                                                          minWidth: {
                                                            xs: 220,
                                                            lg: 120,
                                                          },
                                                        }}
                                                        size="small"
                                                        className="dropdown"
                                                      >
                                                        <Select
                                                          className="permission-font"
                                                          labelId="demo-simple-select-label"
                                                          id="demo-simple-select"
                                                          label=""
                                                          name={option}
                                                          value={
                                                            rowData[row][
                                                              option
                                                            ] || "2"
                                                          }
                                                          onChange={(event) =>
                                                            handleSelectChange(
                                                              event?.target
                                                                ?.value,
                                                              key,
                                                              option,
                                                              row
                                                            )
                                                          }
                                                          MenuProps={{
                                                            disableScrollLock: true,
                                                          }}
                                                        >
                                                          {getRolesList(
                                                            row
                                                          )?.map((item) => {
                                                            return (
                                                              <MenuItem
                                                                key={`${row}_${option}_${item.value}`}
                                                                value={
                                                                  item.value
                                                                }
                                                              >
                                                                {item.label}
                                                              </MenuItem>
                                                            );
                                                          })}
                                                        </Select>
                                                      </FormControl>
                                                    </TableCell>
                                                  )
                                                )}
                                              </TableRow>
                                            );
                                          })
                                      ) : (
                                        <TableRow>
                                          <TableCell
                                            component="td"
                                            scope="row"
                                            className="module-name permission-selectbox"
                                          >
                                            {" "}
                                          </TableCell>
                                          {MODULE_OPTIONS?.map((option) => (
                                            <TableCell
                                              key={option}
                                              align="center"
                                              className="permission-selectbox"
                                            >
                                              <FormControl
                                                sx={{
                                                  m: 1,
                                                  minWidth: {
                                                    xs: 220,
                                                    lg: 120,
                                                  },
                                                }}
                                                size="small"
                                                className="dropdown"
                                              >
                                                <Select
                                                  className="permission-font"
                                                  labelId="demo-simple-select-label"
                                                  id="demo-simple-select"
                                                  label="Permissions"
                                                  name={option}
                                                  value={rowData[option] || "2"}
                                                  onChange={(event) =>
                                                    handleSelectChange(
                                                      event?.target?.value,
                                                      key,
                                                      option
                                                    )
                                                  }
                                                  MenuProps={{
                                                    disableScrollLock: true,
                                                  }}
                                                >
                                                  {getRolesList()?.map(
                                                    (item) => {
                                                      return (
                                                        <MenuItem
                                                          key={item.value}
                                                          value={item.value}
                                                        >
                                                          {item.label}
                                                        </MenuItem>
                                                      );
                                                    }
                                                  )}
                                                </Select>
                                              </FormControl>
                                            </TableCell>
                                          ))}
                                        </TableRow>
                                      )
                                    ) : null}
                                  </Fragment>
                                );
                              }
                            })}
                        </TableBody>
                      ) : (
                        <TableBody>
                          <TableRow className="module-head roles-list-loading">
                            <TableCell colSpan={7}>
                              <StatusBar
                                status={isLoading ? "process" : "empty"}
                              />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </div>
                </TableContainer>
              </div>
            </div>
          </div>
          <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            className="popup-index"
            disableScrollLock
          >
            <div className="role-wrap">
              <DialogTitle>{`${editedRole ? "EDIT" : "ADD"} ${
                ModNameChange ? "PERMISSIONS" : "ROLE"
              }`}</DialogTitle>
              <Divider />
              <DialogContent>
                <div className="row">
                  <div className="col-md-12 mb-4 body-th common-input-style mb-4 mandatoryclass">
                    <Box className="filled-box">
                      <TextField
                        id="filled-basic"
                        label={ModNameChange ? "Permission Name" : "Role Name"}
                        variant="filled"
                        name="role-name"
                        value={roleName}
                        onChange={(e) => {
                          handleError("roleName");
                          setRoleName(e.target.value);
                        }}
                      />
                      {error.roleName ? (
                        <p className="error-txt">{error.roleName}</p>
                      ) : null}
                    </Box>
                  </div>
                  {/* <div className="col-md-12 mb-4">
                <FormControlLabel
                  className="dynamic-checkbox ms-0 checkbox-space"
                  control={<Checkbox />}
                  label="LDAP Sync"
                  value=""
                  labelPlacement="end"
                />
              </div> */}
                </div>
              </DialogContent>
              <Divider />
              <DialogActions className="justify-content-start pt-4">
                <div className="botBtn pl-3">
                  <div>
                    <Button
                      className="automation-footer-btn"
                      type="button"
                      onClick={() => !roleSaveLoading && onSubmit()}
                    >
                      {roleSaveLoading
                        ? "PROCESSING"
                        : editedRole
                        ? "UPDATE"
                        : "ADD"}
                    </Button>
                    <Button
                      className="automation-cancel-btn"
                      onClick={handleClose}
                      type="button"
                    >
                      CANCEL
                    </Button>
                  </div>
                </div>
              </DialogActions>
            </div>
          </Dialog>
        </Box>
      )}
    </React.Fragment>
  );
}

export default Role;
