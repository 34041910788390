import React, { memo, useEffect, useState } from "react";
import {
  Box,
  Divider,
  Grid,
  Hidden,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import moment from "moment";
import AccessDenied from "elements/AccessDenied";
import { common, envConfig, api, tools } from "helpers";
import * as Services from "helpers/service";
import CampaignsSubMenu from "./crmSubMenuView";
import CrmGeneralView from "./crmGeneralView";
import serviceList from "assets/files/serviceList.json";
import Loader from "elements/Loader";
import DealList from "elements/DealList";
import { Col, Row } from "react-bootstrap";

const CrmDetails = memo((props) => {
  let { mod } = useParams();
  const search = useLocation().search;
  const querySearch = new URLSearchParams(search).get("search");
  const convertSearch = new URLSearchParams(search).get("convert");
  const navigate = useNavigate();
  const [isExpand, setIsExpanded] = useState("detail");
  const [ids, setIds] = useState(props.id);
  const [data, setData] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const service = serviceList[props.module];
  const [status, setStatus] = useState("process");
  const [module, setModule] = useState(props.module);
  const [userStatus, setUserStatus] = useState("");
  const [name, setName] = useState("");
  const [disablePrev, setDisablePrev] = useState(false);
  const [disableNext, setDisableNext] = useState(false);
  const [isReload, setIsReload] = useState(false);
  const [result, setResult] = useState(null);
  const isEdutechAcc =
    common.isEduTech() && ["lead", "deal"].includes(mod) ? true : false;
  const [isLoading, setIsLoading] = React.useState(false);

  const authInfo = common.authInfo();
  const authData = () => {
    return { label: authInfo.EmployeeName, value: authInfo.EmpId.toString() };
  };
  const propsDefault = {
    CampaignOwnerId: authData(),
  };
  const propsDisabled = {};

  useEffect(() => {
    if (props.status == "success") {
      setIsReload(false);
      setModule(props.module);
      setStatus("process");
      if (props.id > 0) {
        if (mod != "contacts") {
          getCRMStatus();
        }
        getColumnsData();
        checkPaging();
      }
    }
  }, [props]);

  useEffect(() => {
    setIds(props.id);
  }, [props.id]);

  const getColumnsData = () => {
    let filter = `?q=EserviceCode=${service.code}`;
    let url = envConfig.BASE_API + Services.CRM_ESERVICE_HDR + filter;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };
    api.call(
      data,
      (response) => {
        if (response) {
          let formFields = JSON.parse(response.data.items[0].FormFields);
          let filtered = formFields.form.fields;
          if (isEdutechAcc) {
            if (mod == "lead") {
              filtered = formFields.form.fields.filter(
                (e) => !["Cattr15", "Cattr40"].includes(e.key)
              );
            } else {
              filtered = formFields.form.fields.filter(
                (e) => !["Eattr3", "Eattr4"].includes(e.key)
              );
            }
          }
          formFields.form.fields = filtered;
          setData(formFields.form);
          getEditData();
          setIsReload(true);
        } else {
          setStatus("error");
        }
      },
      (error) => {
        setStatus("error");
      },
      (final) => {}
    );
  };

  // edit
  const getEditData = () => {
    let url = envConfig.BASE_API + Services[service.api] + "/" + props.id;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };
    api.call(
      data,
      (response) => {
        if (response.status == 200) {
          // set value
          let item = response.data;
          setResult(item);
          if (module == "campaign") {
            setIds(item.CampaignId);
            setName(item.CampaignName);
            setUserStatus(item.CampaignStatus);
          } else if (module == "enquiry") {
            setIds(item.ListId);
            setName(item.ListName);
            setUserStatus(item.ListStatus);
          } else if (module == "lead") {
            setIds(item.LeadId);
            setName(item.LeadName);
            setUserStatus(item.LeadStatus);
          } else if (module == "deal") {
            setIds(item.OpportunityId);
            setName(item.OpportunityName);
            setUserStatus(item.ApprStatus);
          } else if (module == "contacts") {
            setIds(item.ContactId);
            setName(item.FirstName);
            setUserStatus("");
          }
          setData((oldData) => {
            let newData = reParse(oldData);
            newData &&
              newData.fields &&
              newData.fields.length > 0 &&
              newData.fields.map((item) => {
                let value = response.data[item.key];
                value = value === null ? "" : value;
                if (item.type === "pickList") {
                  value = value != null ? value : "";
                } else if (item.type === "lookup") {
                  if (
                    item.key == "CustAccountId" &&
                    ["lead"].includes(module)
                  ) {
                    let isNum = /^\d+$/.test(response.data["CustAccountId"]);
                    if (!isNum) {
                      value = response.data["LeadPicPath"] || "";
                    } else {
                      value =
                        value !== null
                          ? response.data[`${item.key}Desc`] || String(value)
                          : "";
                    }
                  } else {
                    value =
                      value !== null
                        ? response.data[`${item.key}Desc`] || String(value)
                        : "";
                  }
                } else if (
                  item.type === "multiSelect" ||
                  item.type === "multiLookup"
                ) {
                  if (item.display) {
                    let dField = item.display;
                    if (
                      dField.storeType == "multiple" &&
                      response.data[dField.name]
                    ) {
                      value = value ? value.split(",").map((i, idx) => i) : "";
                    }
                  } else {
                    value = value ? value.split(",").map((i) => i) : "";
                  }
                } else if (item.type === "currency") {
                  value = tools
                    .onlyFloat(String(value))
                    .replace(/(\d)(?=(\d{3})+\b)/g, "$1,");
                } else if (item.type === "date") {
                  value = value ? moment(value).format("YYYY-MM-DD") : "-";
                } else if (item.type == "dateTime") {
                  value = value
                    ? moment(value).format("MM/DD/YYYY hh:mm A")
                    : "-";
                } else if (item.type == "checkbox") {
                  value = value == "Y" ? "Yes" : "No";
                }

                if (!value && item.key in propsDefault) {
                  value = propsDefault[item.key];
                }
                item.value = value;
                if (item.key in propsDisabled) {
                  item.disabled = propsDisabled[item.key];
                }
              });
            return newData;
          });
          setTimeout(() => {
            setStatus("success");
          }, 2000);
        }
      },
      (error) => {
        setStatus("error");
      },
      (final) => {}
    );
  };

  const getCRMStatus = () => {
    let userData = common.authInfo();
    let type =
      module == "campaign"
        ? common.getTransactionTypeId(envConfig.CAMPAIGN_TRANS_TYPE_ID)
        : module == "enquiry"
        ? common.getTransactionTypeId(envConfig.LIST_TRANS_TYPE_ID)
        : module == "lead"
        ? common.getTransactionTypeId(envConfig.LEAD_TRANS_TYPE_ID)
        : common.getTransactionTypeId(envConfig.DEAL_TRANS_TYPE_ID);
    let params =
      `?q=TransactionTypeId=${type};OrgId=` +
      `${userData.DefaultOrgId};TenantId=` +
      `${userData.TenantId};Active=Y` +
      `&limit=100&offset=0&orderBy=OrderNo:asc`;
    let url = envConfig.BASE_API + Services.TRANSACTION_STATUS + params;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };
    api.call(
      data,
      (response) => {
        if (response.status == 200) {
          setStatusList(response.data.items);
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  const reParse = (data) => {
    return JSON.parse(JSON.stringify(data));
  };

  const getFormFields = (sKey) => {
    let nData = reParse(data);
    let sId = data.sections[sKey].id;
    let quickList = [true, false];
    if (props.quick) {
      quickList = [true];
    }
    return nData.fields.filter(
      (item) =>
        item.layout === sId &&
        item.status === "use" &&
        item.visibility &&
        //item.store &&
        quickList.includes(item.quick)
    );
  };

  const handleExpanded = (expandSection) => {
    setIsExpanded(expandSection);
  };

  //Prev&Next Handling
  const goto = (e, type) => {
    e.preventDefault();
    let detailRecords = localStorage.getItem("dpHdr")
      ? JSON.parse(localStorage.getItem("dpHdr"))
      : null;
    if (detailRecords) {
      let cIdx = detailRecords.ids.indexOf(parseInt(props.id));

      if (type == "prev") {
        let prevId = detailRecords.ids[cIdx - 1];
        navigate("/crm/" + props.module + "/details/" + prevId);
      } else {
        let nextId = detailRecords.ids[cIdx + 1];
        navigate("/crm/" + props.module + "/details/" + nextId);
      }
    }
  };

  const checkPaging = () => {
    setDisableNext(false);
    setDisablePrev(false);
    if (querySearch == "true" || convertSearch == "true") {
      setDisableNext(true);
      setDisablePrev(true);
    } else {
      let detailRecords = localStorage.getItem("dpHdr")
        ? JSON.parse(localStorage.getItem("dpHdr"))
        : null;
      if (detailRecords) {
        let cIdx = detailRecords.ids.indexOf(parseInt(props.id));

        if (cIdx == 0) {
          setDisablePrev(true);
        } else if (cIdx == detailRecords.ids.length - 1) {
          setDisableNext(true);
        }
        if (cIdx == 0 && detailRecords.total == 1) {
          setDisablePrev(true);
          setDisableNext(true);
        } else if (
          cIdx == 0 &&
          detailRecords.page > 1 &&
          detailRecords.total > detailRecords.ids.length
        ) {
          callNextRecords(true, detailRecords.url);
        } else if (
          cIdx == detailRecords.limit - 1 &&
          detailRecords.total > detailRecords.ids.length
        ) {
          callNextRecords(false, detailRecords.url);
        }
      } else {
        setDisableNext(true);
        setDisablePrev(true);
      }
    }
  };

  const callNextRecords = (isPrev, url) => {
    let detailRecords = localStorage.getItem("dpHdr")
      ? JSON.parse(localStorage.getItem("dpHdr"))
      : null;
    if (url) {
      let firstUrlStr = url.substring(0, url.indexOf("offset=") + 7);
      let lasUrlStr = url.substring(url.indexOf("&orderBy"), url.length);
      let offset =
        parseInt(detailRecords.page - 1) * parseInt(detailRecords.limit);

      if (isPrev) {
        offset =
          parseInt(detailRecords.page - 2) * parseInt(detailRecords.limit);
      } else {
        offset = parseInt(detailRecords.page) * parseInt(detailRecords.limit);
      }

      let fUrl = firstUrlStr + offset + lasUrlStr;
      fUrl = fUrl + "&fields=" + service.id;
      let data = {
        url: fUrl,
        type: "dynamic",
        method: "GET",
        auth: "token",
        moreHead: { rfv: 2 },
        cType: 4,
      };
      api.call(
        data,
        (response) => {
          if (response.status == 200) {
            const ids = response.data.items.map(function (data) {
              return data[service.id];
            });

            if (detailRecords) {
              let old = detailRecords.ids;
              const array3 = isPrev ? [...ids, ...old] : [...old, ...ids];
              localStorage.setItem(
                "dpHdr",
                JSON.stringify({
                  url: detailRecords.url,
                  ids: array3,
                  total: response.data.totalResults,
                })
              );
              setTimeout(() => {
                checkPaging();
              }, 3000);
            }
          }
        },
        (error) => {
          setStatus("error");
        },
        (final) => {}
      );
    }
  };

  const permissionView = (read) => {
    let empIds = [];
    let access = "r";
    if (!read) {
      access = "u";
    }
    if (props.userAccess[access] == "1") {
      return false;
    }
    if (props.userAccess[access] == "3") {
      empIds.push(authInfo.EmpId);
    } else if (props.userAccess[access] == "4") {
      empIds = authInfo.employeeList.active;
    } else if (props.userAccess[access] == "5") {
      empIds = authInfo.employeeList.sub;
    }
    if (props.module == "lead" && empIds.includes(result.SalespersonId)) {
      return false;
    } else if (
      props.module == "deal" &&
      empIds.includes(result.SalesPersonId)
    ) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <React.Fragment>
      {status == "success" && permissionView(true) ? (
        <AccessDenied />
      ) : !(mod == "lead" && result?.OpportunityConverted == "Y") ? (
        <Box px={4} mb={10}>
          <Box
            py={2}
            sx={{
              mt: { xs: 3, lg: 0 },
            }}
          >
            <Paper variant="contained" square>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item className="detail-head">
                  <Grid
                    container
                    alignItems="center"
                    spacing={1}
                    className="detail-head"
                  >
                    <Grid item onClick={(e) => props.closeDetails(e, false)}>
                      <Box py={1} px={1} className="icon-back">
                        <IconButton>
                          <img
                            src={common.loadImg("backArrowKanban.svg")}
                            alt="back"
                          />
                        </IconButton>
                      </Box>
                    </Grid>
                    {status == "success" && (
                      <Grid item>
                        <Typography className="detail-title">
                          {name ? common.textCapitalize(name) : ""}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                {status == "success" && (
                  <Grid item>
                    <Box px={2}>
                      <Grid
                        container
                        alignItems="center"
                        spacing={2}
                        sx={{
                          justifyContent: { xs: "center", lg: "start" },
                        }}
                      >
                        <Grid item>
                          <Hidden smDown>
                            <Box className="verical-divider">
                              <Divider orientation="vertical" />
                            </Box>
                          </Hidden>
                        </Grid>
                        <Grid item>
                          <Box
                            className={
                              disablePrev
                                ? "disable-campaign-box safari-style"
                                : "active-campaign-box safari-style"
                            }
                          >
                            <Grid
                              container
                              spacing={2}
                              alignItems="center"
                              className="pointer safari-style"
                              onClick={(e) => goto(e, "prev")}
                            >
                              <Grid item>
                                <img
                                  src={common.loadImg("arrowBackKanban.svg")}
                                  alt="back"
                                />
                              </Grid>
                              <Grid item>
                                <Typography className="pre-text">
                                  Prev {module}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Hidden smDown>
                            <Box className="verical-divider">
                              <Divider orientation="vertical" />
                            </Box>
                          </Hidden>
                        </Grid>
                        <Grid item>
                          <Box
                            className={
                              disableNext
                                ? "disable-campaign-box safari-style"
                                : "active-campaign-box safari-style"
                            }
                          >
                            <Grid
                              container
                              spacing={2}
                              alignItems="center"
                              className="pointer safari-style"
                              onClick={(e) => goto(e, "next")}
                            >
                              <Grid item>
                                <Typography className="pre-text">
                                  Next {module}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <img
                                  src={common.loadImg("arrowForwardKanban.svg")}
                                  alt="back"
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Hidden smDown>
                            <Box className="verical-divider">
                              <Divider orientation="vertical" />
                            </Box>
                          </Hidden>
                        </Grid>
                        <Grid item className="safari-style">
                          {!permissionView(false) && (
                            <a
                              style={{ textDecoration: "none" }}
                              onClick={() => props.changeForm(true)}
                              className="safari-style"
                            >
                              <Grid
                                container
                                spacing={2}
                                alignItems="center"
                                className="pointer safari-style"
                              >
                                <Grid item>
                                  <Typography className="edit-campaign">
                                    Edit
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <img
                                    src={common.loadImg("editIconkanban.svg")}
                                    alt="back"
                                  />
                                </Grid>
                              </Grid>
                            </a>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Box>
          {mod != "contacts" && status == "success" && !permissionView() && (
            <div className="scrollmenu" id="scroll-menu-wrap">
              {statusList &&
                statusList.length > 0 &&
                statusList.map((item, i) => {
                  return (
                    <div
                      className={
                        userStatus?.toLowerCase() ==
                        item.StatusName.toLowerCase()
                          ? "arrow-pointer"
                          : "arrow-pointer-clip"
                      }
                      key={i}
                    >
                      <Grid container spacing={1} className="inner-box">
                        <Grid item>
                          <Box className="active-count">{i + 1}</Box>
                        </Grid>
                        <Grid item>
                          <Box>
                            <Typography className="active-count-text">
                              {item.StatusName}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </div>
                  );
                })}
            </div>
          )}

          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={isExpand === "detail" ? (mod !== "contacts" ? 8 : 12) : 1}
              xl={isExpand === "detail" ? (mod !== "contacts" ? 8 : 12) : 1}
            >
              {isExpand === "detail" ? (
                <CrmGeneralView
                  handleExpanded={handleExpanded}
                  isExpand={isExpand}
                  data={data}
                  getFormFields={(val) => getFormFields(val)}
                  status={status}
                  module={mod}
                />
              ) : (
                <Paper
                  variant="contained"
                  className="collapse-paper pointer"
                  onClick={() => handleExpanded("detail")}
                  style={{ height: "100vh" }}
                >
                  <Box className="collapse-camp">
                    {service?.label} Information
                  </Box>
                  <img
                    src={common.loadImg("arrowForwardKanban.svg")}
                    alt="arrow"
                  />
                </Paper>
              )}
            </Grid>
            {mod != "contacts" && status == "success" && (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={isExpand === "tab" ? 11 : 4}
                xl={isExpand === "tab" ? 11 : 4}
              >
                {!isReload ? (
                  <Loader />
                ) : (
                  ids > 0 && (
                    <CampaignsSubMenu
                      id={props.id}
                      handleExpanded={handleExpanded}
                      isExpand={isExpand}
                      module={mod}
                      status={status}
                    />
                  )
                )}
              </Grid>
            )}
          </Grid>
          {!isLoading && props.module == "contacts" && (
            <Paper variant="contained" square>
              {/* <Box pb={1}>
                <Box p={3}>
                  <Typography className="camp-sub-title">Deals</Typography>
                </Box>
                <Box>
                  <Divider />
                </Box>
              </Box> */}
              <Box mt={2} className="camp-edit meet-table">
                <Row className="m-0">
                  <Col xs={12} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box>
                          {!isLoading && props.id && (
                            <div className="row">
                              <DealList module="Contact" id={props.id} />
                            </div>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                </Row>
              </Box>
            </Paper>
          )}
        </Box>
      ) : (
        <Box px={4} mb={10}>
          <Box
            py={2}
            sx={{
              mt: { xs: 3, lg: 0 },
            }}
          >
            <Paper variant="contained" square>
              <Grid item className="detail-head">
                <Grid
                  container
                  alignItems="center"
                  spacing={1}
                  className="detail-head"
                >
                  <Grid item onClick={(e) => props.closeDetails(e, false)}>
                    <Box py={1} px={1} className="icon-back">
                      <IconButton>
                        <img
                          src={common.loadImg("backArrowKanban.svg")}
                          alt="back"
                        />
                      </IconButton>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography className="detail-title">
                      Conversion details
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            <Grid item xs={12} sm={12} md={12}>
              <div>
                <Paper variant="contained" square>
                  <Box pb={1}>
                    <Box p={3}>
                      <Typography className="camp-sub-title">
                        The Lead <strong>{result.LeadName}</strong> has been
                        already converted.
                      </Typography>
                    </Box>
                    <Box>
                      <Divider />
                    </Box>

                    <Box mt={2} className="camp-edit meet-table">
                      <Row className="m-0">
                        <Col xs={12} className="body-th">
                          <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Box>
                                <div className="camp-sub-name">Account</div>
                                <div className="camp-sub-des mt-2">
                                  {result.CustAccountIdDesc ? (
                                    <Link
                                      to={
                                        "/setting/vendor/customer/edit/" +
                                        result.CustAccountId
                                      }
                                    >
                                      {result.CustAccountIdDesc}
                                    </Link>
                                  ) : (
                                    "-"
                                  )}
                                </div>
                              </Box>
                            </Grid>
                          </Grid>
                        </Col>
                        <Col xs={12} className="body-th">
                          <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Box>
                                <div className="camp-sub-name">Contact</div>
                                <div className="camp-sub-des mt-2">
                                  {result.Nattr10 ? (
                                    <Link
                                      to={
                                        "/crm/contacts/details/" +
                                        result.Nattr10
                                      }
                                    >
                                      {result.Nattr10Desc || result.Nattr10}
                                    </Link>
                                  ) : (
                                    "-"
                                  )}
                                </div>
                              </Box>
                            </Grid>
                          </Grid>
                        </Col>
                        {result.Nattr9 && (
                          <Col xs={12} className="body-th">
                            <Grid container>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <Box>
                                  <div className="camp-sub-name">Deal</div>
                                  <div className="camp-sub-des mt-2">
                                    {result.Nattr9 ? (
                                      <Link
                                        to={
                                          "/crm/deal/details/" + result.Nattr9
                                        }
                                      >
                                        {result.Nattr9Desc || result.Nattr9}
                                      </Link>
                                    ) : (
                                      "-"
                                    )}
                                  </div>
                                </Box>
                              </Grid>
                            </Grid>
                          </Col>
                        )}
                      </Row>
                    </Box>
                  </Box>
                </Paper>
              </div>
            </Grid>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
});

export default CrmDetails;
