import React, { memo, useState } from "react";
import { Link } from "react-router-dom";

import {
  Box,
  Button,
  IconButton,
  Grid,
  Typography,
  Divider,
  Popover,
  InputAdornment,
  OutlinedInput,
  Paper,
  Hidden,
  InputLabel,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { common } from "helpers";
import TextField from "@mui/material/TextField";
// import Select from "react-select";
import { Theme, useTheme } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { blue } from "@material-ui/core/colors";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";

import TableRow from "@mui/material/TableRow";
const ReportRepresentation = memo(() => {
  const [value, setValue] = React.useState("1");
  const [value1, setValue1] = React.useState("1");
  const [age, setAge] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showSelect, setShowSelect] = React.useState(false);

  /* Multiselect box mui */

  const MenuProps = {
    PaperProps: {
      style: {
        width: 250,
      },
    },
  };
  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const names = [
    "Status:Complete",
    "Status:in Progress",
    "Status:Pending",
    "Deadline",
  ];

  const allocations = [
    "Lead by primary email",
    "Lead by firstname",
    "Campaign by date",
    "Referred by customer",
  ];

  // </FormControl>

  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleMultiselect = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  /* Multiselect box mui */
  const handleFilters = (event) => {
    setAge(event.target.value);
  };
  const onSubmit = (e) => {
    e.preventDefault();
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleTab2 = (event, newValue) => {
    setValue1(newValue);
  };

  function createData(Columns, Sum, Average, LowestValue, HighestValue) {
    return { Columns, Sum, Average, LowestValue, HighestValue };
  }
  const rows = [
    createData("Campaign Created"),
    createData("Campaign Created : Creator Name or Users"),
    createData("Campaign Customers"),
    createData("Campaign progress "),
  ];

  const handleClickExport = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openNotification = Boolean(anchorEl);
  const id = openNotification ? "simple-popover" : undefined;

  return (
    <Box px={5} mt={3} mb={10} className="template-box-border">
      <div className="row">
        <div className="col-12 mt-2 ">
          <div className="card border-color-F2F9FD">
            <div className="card-body header-border-padding  p-0">
              <Box alignItems="center" justifyContent="center" className="">
                <Box py={3}>
                  <Grid
                    container
                    spacing={3}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item>
                          <Typography className="page-title add-report-title mx-3">
                            Create New Report
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item marginRight={5}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                      >
                        <Grid item className="report-count-active-font">
                          <Grid container spacing={4}>
                            <Grid item className="">
                              <span className=" report-count-active-2 report-radius">
                                01
                              </span>
                              <span className=" report-count-active-2 report-radius-2">
                                {" "}
                                02{" "}
                              </span>
                            </Grid>

                            <Grid item className="">
                              {" "}
                              <span className="">03</span>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item className="close-icn-border ">
                      <Box className="revert-close revert-close1">
                        <Button className="save-note" type="button">
                          Close
                          <Link to="#">
                            {" "}
                            <img
                              src={common.loadImg("closeKanban.svg")}
                              alt="close"
                            />
                          </Link>
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Divider />
                </Box>

                <Box
                  sx={{ width: "100%", typography: "body1" }}
                  className="primary-header-container "
                  p={0}
                >
                  <TabContext value={value}>
                    <Paper variant="container" square>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: { xs: "column", lg: "row" },
                          justifyContent: { xs: "center", lg: "space-between" },
                          itemsCenter: { xs: "center" },
                          py: { xs: 5, lg: 0 },
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: { xs: "column", lg: "row" },
                            justifyContent: {
                              xs: "center",
                              lg: "space-between",
                            },
                            itemsCenter: { xs: "center" },
                          }}
                          alignItems="center"
                          px={3}
                          className="sub-tabs"
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            spacing={3}
                            justifyContent="space-between"
                          >
                            <Typography className="camp-sub-title">
                              Reports Representation
                            </Typography>
                            <Hidden smDown>
                              <Box className="vertical-divider-2 ml-4">
                                <Divider orientation="vertical" />
                              </Box>
                            </Hidden>
                          </Box>
                          <TabList onChange={handleChange} className="ml-5 ">
                            <Tab label="Columns" value="1" />
                            <Tab label="Columns to total" value="2" />
                          </TabList>
                        </Box>
                      </Box>
                      <Box className="divider-margin">
                        <Divider />
                      </Box>
                    </Paper>

                    <TabPanel value="1">
                      <Box mt={3}>
                        <div className="row">
                          <div className="col-md-5 mt-2 report-card-border mx-auto">
                            <div className="card  ">
                              <div className="card-body  p-0 ">
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  className="px-3 py-1"
                                >
                                  <Grid
                                    container
                                    alignItems="center"
                                    justifyContent="space-between"
                                  >
                                    <Grid item>
                                      <Typography className="avatar-sub-text">
                                        Available Columns
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </div>
                              <Box>
                                <Divider />
                              </Box>
                              <div className="card-body card-report-height report-scroll">
                                <Box>
                                  <Grid item py={1}>
                                    <input
                                      type="radio"
                                      className="radio-button ml-4"
                                      value="campaign"
                                      name="module"
                                    />

                                    <label
                                      className="radio-label px-3"
                                      htmlFor="campaign"
                                    >
                                      Data Created
                                    </label>
                                  </Grid>
                                  <Grid item py={1}>
                                    <input
                                      type="radio"
                                      className="radio-button ml-4"
                                      value="leads"
                                      name="module"
                                    />
                                    <label
                                      className="radio-label px-3"
                                      htmlFor="campaign"
                                    >
                                      Data modified
                                    </label>
                                  </Grid>
                                  <Grid item py={1}>
                                    <input
                                      type="radio"
                                      className="radio-button ml-4"
                                      value="tasks"
                                      name="module"
                                    />
                                    <label
                                      className="radio-label px-3"
                                      htmlFor="campaign"
                                    >
                                      Total Items No.
                                    </label>
                                  </Grid>
                                  <Grid item py={1}>
                                    <input
                                      type="radio"
                                      className="radio-button ml-4"
                                      value="reports"
                                      name="module"
                                    />
                                    <label
                                      className="radio-label px-3"
                                      htmlFor="campaign"
                                    >
                                      Creator
                                    </label>
                                  </Grid>
                                  <Grid item py={1}>
                                    <input
                                      type="radio"
                                      className="radio-button ml-4"
                                      value="meetings"
                                      name="module"
                                    />
                                    <label
                                      className="radio-label px-3"
                                      htmlFor="campaign"
                                    >
                                      Tasks
                                    </label>
                                  </Grid>
                                  <Grid item py={1}>
                                    <input
                                      type="radio"
                                      className="radio-button ml-4"
                                      value="meetings"
                                      name="module"
                                    />
                                    <label
                                      className="radio-label px-3"
                                      htmlFor="campaign"
                                    >
                                      Progress:Completed
                                    </label>
                                  </Grid>
                                </Box>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-1 pointer my-auto ">
                            <Grid item className="center">
                              <img src={common.loadImg("orangePlus.svg")}></img>
                            </Grid>
                            <Grid item className="add-font center me-2">
                              Add
                            </Grid>
                          </div>

                          <div className="col-md-5 mt-2 report-card-border mx-auto">
                            <div className="card ">
                              <div className="card-body p-0">
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  className="px-3 py-1"
                                >
                                  <Grid
                                    container
                                    alignItems="center"
                                    justifyContent="space-between"
                                  >
                                    <Grid item>
                                      <Typography className="avatar-sub-text">
                                        Select Columns
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Box>
                                <Box>
                                  <Divider />
                                </Box>
                              </div>
                              <div className="card-body report-scroll  card-report-height">
                                <Box>
                                  <Grid item py={1}>
                                    <input
                                      type="radio"
                                      className="radio-button ml-4"
                                      value="meetings"
                                      name="module"
                                    />
                                    <label
                                      className="radio-label px-3"
                                      htmlFor="campaign"
                                    >
                                      Leads
                                    </label>
                                  </Grid>
                                  <Grid item py={1}>
                                    <input
                                      type="radio"
                                      className="radio-button ml-4"
                                      value="meetings"
                                      name="module"
                                    />
                                    <label
                                      className="radio-label px-3"
                                      htmlFor="campaign"
                                    >
                                      Description
                                    </label>
                                  </Grid>
                                  <Grid item py={1}>
                                    <input
                                      type="radio"
                                      className="radio-button ml-4"
                                      value="meetings"
                                      name="module"
                                    />
                                    <label
                                      className="radio-label px-3"
                                      htmlFor="campaign"
                                    >
                                      Audience
                                    </label>
                                  </Grid>
                                  <Grid item py={1}>
                                    <input
                                      type="radio"
                                      className="radio-button ml-4"
                                      value="meetings"
                                      name="module"
                                    />
                                    <label
                                      className="radio-label px-3"
                                      htmlFor="campaign"
                                    >
                                      Tasks
                                    </label>
                                  </Grid>
                                </Box>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Box>
                    </TabPanel>
                    <TabPanel value="2" className="p-0">
                      <Table
                        // sx={{ minWidth: 650 }}
                        className="column-total-table mb-4 mt-1"
                      >
                        <TableHead className="column-background">
                          <TableRow>
                            <TableCell align="left">Columns</TableCell>
                            <TableCell align="center">Sum</TableCell>
                            <TableCell align="center">Average</TableCell>
                            <TableCell align="center">LowestValue</TableCell>
                            <TableCell align="center">HighestValue</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row) => (
                            <TableRow key={row.Columns}>
                              <TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                {row.Columns}
                              </TableCell>
                              <TableCell align="center">
                                <label className="custom-checkbox">
                                  <input type="checkbox" />
                                  <span className="checkmark checkmark-pos-center"></span>
                                </label>
                              </TableCell>
                              <TableCell align="center">
                                {" "}
                                <label className="custom-checkbox">
                                  <input type="checkbox" />
                                  <span className="checkmark checkmark-pos-center"></span>
                                </label>{" "}
                              </TableCell>
                              <TableCell align="right">
                                {" "}
                                <label className="custom-checkbox">
                                  <input type="checkbox" />
                                  <span className="checkmark checkmark-pos-center"></span>
                                </label>
                              </TableCell>
                              <TableCell align="right">
                                {" "}
                                <label className="custom-checkbox">
                                  <input type="checkbox" />
                                  <span className="checkmark checkmark-pos-center"></span>
                                </label>{" "}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TabPanel>
                  </TabContext>
                </Box>

                <Box>
                  <Divider />
                </Box>

                <Box
                  sx={{ width: "100%", typography: "body1" }}
                  className="primary-header-container  "
                  p={0}
                >
                  <TabContext value={value1}>
                    <Paper variant="container" square>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: { xs: "column", lg: "row" },
                          justifyContent: { xs: "center", lg: "space-between" },
                          itemsCenter: { xs: "center" },
                          py: { xs: 5, lg: 0 },
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: { xs: "column", lg: "row" },
                            justifyContent: {
                              xs: "center",
                              lg: "space-between",
                            },
                            itemsCenter: { xs: "center" },
                          }}
                          alignItems="center"
                          px={3}
                          className="sub-tabs"
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            spacing={3}
                            justifyContent="space-between"
                          >
                            <Typography
                              className="camp-sub-title"
                              marginRight={4}
                            >
                              Filters
                            </Typography>
                            <Hidden smDown>
                              <Box className="vertical-divider-2 ml-4">
                                <Divider orientation="vertical" />
                              </Box>
                            </Hidden>
                          </Box>
                          <TabList onChange={handleTab2} className="ml-5">
                            <Tab label="Standard" value="1" />
                            <Tab label="Advanced" value="2" />
                          </TabList>
                        </Box>
                      </Box>
                    </Paper>
                    <Box className="divider-margin">
                      <Divider />
                    </Box>
                    <TabPanel value="1">
                      <Box mt={3} mb={3}>
                        <Grid
                          container
                          alignItems="center"
                          justify="space-between"
                          sx={{
                            flexDirection: { xs: "column", lg: "row" },
                            spacing: { xs: 3, lg: 0 },
                          }}
                          spacing={2}
                        >
                          <Grid item xs={4}>
                            <Box className="text-box custom-select">
                              <FormControl
                                fullWidth
                                className="select-float-label represent-select"
                              >
                                <InputLabel
                                  id="select"
                                  className="floating-label"
                                >
                                  Lead Allocated On
                                </InputLabel>
                                <Select
                                  inputProps={{
                                    MenuProps: { disableScrollLock: true },
                                  }}
                                  open={showSelect}
                                  onOpen={() => setShowSelect(true)}
                                  onClose={() => setShowSelect(false)}
                                  labelId="demo-multiple-checkbox-label"
                                  id="demo-multiple-checkbox"
                                  multiple
                                  value={personName}
                                  onChange={handleMultiselect}
                                  input={<OutlinedInput label="Tag" />}
                                  renderValue={(selected) =>
                                    selected.join(", ")
                                  }
                                  MenuProps={MenuProps}
                                >
                                  <Box className="notify-box2" p={1}>
                                    <Grid
                                      container
                                      justify="space-between"
                                      alignItems="center"
                                    >
                                      <Grid item xs={10}>
                                        <Typography
                                          component="div"
                                          className="flex align-items-center notify-title pl-2"
                                        >
                                          Lead Allocated on
                                        </Typography>
                                      </Grid>
                                      <Grid item ml={2}>
                                        <IconButton
                                          onClick={() => {
                                            setShowSelect(false);
                                          }}
                                        >
                                          <img
                                            src={common.loadImg(
                                              "dateClosekanban.svg"
                                            )}
                                            alt="close"
                                          />
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                  <div className="d-flex bg-F2F9FD ">
                                    <Box
                                      className="filter-search2"
                                      width="100%"
                                    >
                                      <FormControl sx={{ width: "100%" }}>
                                        <OutlinedInput
                                          id="search"
                                          placeholder={"Search"}
                                          endAdornment={
                                            <InputAdornment position="end">
                                              <img
                                                src={common.loadImg(
                                                  "common-search.svg"
                                                )}
                                                alt="search"
                                              />
                                            </InputAdornment>
                                          }
                                          aria-describedby="outlined-weight-helper-text"
                                        />
                                      </FormControl>
                                    </Box>
                                  </div>
                                  {allocations.map((name) => (
                                    <MenuItem
                                      key={name}
                                      value={name}
                                      style={getStyles(name, personName, theme)}
                                      sx={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        padding: "15px 0 15px 15px",
                                        gap: 3,
                                        borderBottom: "0.25px solid rgba(200, 200, 200, 0.4)",
                                      }}
                                    >
                                      <input
                                        type="checkbox"
                                        className="radio-button ml-2"
                                        name="module"
                                        checked={personName.indexOf(name) > -1}
                                      />
                                      <ListItemText primary={name} />
                                    </MenuItem>
                                  ))}

                                  <Grid item>
                                    <Box p={1} py={1}>
                                      <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        spacing={1}
                                      >
                                        <Grid item>
                                          <Button
                                            className="run-btn"
                                            onClick={(e) => onSubmit(e)}
                                          >
                                            Select
                                          </Button>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </Grid>
                                </Select>
                              </FormControl>
                            </Box>
                          </Grid>
                          {/* <Grid item xs={4}>
                            <Box className="text-box custom-select">
                              <FormControl
                                fullWidth
                                className="select-float-label represent-select"
                              >
                                <InputLabel
                                  id="select"
                                  className="floating-label"
                                >
                                  Lead Allocated On
                                </InputLabel>

                                <Select
                                  labelId="select"
                                  id="select"
                                  value={age}
                                  label="Campaign Option"
                                  onChange={handleFilters}
                                  // onClick={handleClickExport}
                                  className="filter-label"
                                >
                                  <MenuItem
                                    value={10}
                                    onClick={handleClickExport}
                                  >
                                    Referred by last open date
                                  </MenuItem>
                                </Select>
                                <Popover
                                 disableScrollLock
                                  id={id}
                                  open={openNotification}
                                  anchorEl={anchorEl}
                                  onClose={handleClose}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                  }}
                                  className="notify-pop"
                                  // transformOrigin={{
                                  //   vertical: "top",
                                  //   horizontal: "right",
                                  // }}
                                >
                                  <Box className="notify-box2" p={1}>
                                    <Grid
                                      container
                                      justify="space-between"
                                      alignItems="center"
                                    >
                                      <Grid item xs={10}>
                                        <Typography
                                          component="div"
                                          className="flex align-items-center notify-title pl-2"
                                        >
                                          Lead Allocated on
                                        </Typography>
                                      </Grid>
                                      <Grid item ml={2}>
                                        <IconButton
                                          onClick={() => {
                                            setShowSelect(false);
                                          }}
                                        >
                                          <img
                                            src={common.loadImg(
                                              "dateClosekanban.svg"
                                            )}
                                            alt="close"
                                          />
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                  <div className="d-flex bg-F2F9FD ">
                                    <Box
                                      className="filter-search2"
                                      width="100%"
                                    >
                                      <FormControl sx={{ width: "100%" }}>
                                        <OutlinedInput
                                          id="search"
                                          placeholder={"Search"}
                                          endAdornment={
                                            <InputAdornment position="end">
                                              <img
                                                src={common.loadImg(
                                                  "common-search.svg"
                                                )}
                                                alt="search"
                                              />
                                            </InputAdornment>
                                          }
                                          aria-describedby="outlined-weight-helper-text"
                                        />
                                      </FormControl>
                                    </Box>
                                  </div>
                                  <Box>
                                    <Grid
                                      container
                                      direction="column"
                                      className="pointer"
                                    >
                                      {[1, 2, 3].map((idx) => {
                                        return (
                                          <Grid item key={idx}>
                                            <Box p={2} py={2}>
                                              <Grid
                                                container
                                                direction="row"
                                                spacing={2}
                                                alignItems="center"
                                              >
                                                <Grid item>
                                                  <input
                                                    type="radio"
                                                    className="radio-button ml-2"
                                                    value="campaign"
                                                    name="module"
                                                  />
                                                </Grid>
                                                <Grid item>
                                                  <Typography className="lead-by-font">
                                                    Lead by primary email
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Box>
                                            <Box>
                                              <Divider />
                                            </Box>
                                          </Grid>
                                        );
                                      })}
                                      <Grid item>
                                        <Box p={1} py={1}>
                                          <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            spacing={1}
                                          >
                                            <Grid item>
                                              <Button
                                                className="run-btn"
                                                onClick={(e) => onSubmit(e)}
                                              >
                                                Select
                                              </Button>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </Popover>
                              </FormControl>
                            </Box>
                          </Grid> */}
                          <Grid item className="campReport-flex" xs={2.3}>
                            <Box className="text-box custom-select">
                              <FormControl
                                fullWidth
                                className="select-float-label represent-select"
                              >
                                <InputLabel
                                  id="select"
                                  className="floating-label"
                                >
                                  Age in Days
                                </InputLabel>
                                <Select
                                  inputProps={{
                                    MenuProps: { disableScrollLock: true },
                                  }}
                                  labelId="select"
                                  id="select"
                                  value={age}
                                  label="Age in Days"
                                  onChange={handleFilters}
                                  className="filter-label"
                                >
                                  <MenuItem value={10}>Option</MenuItem>
                                  <MenuItem value={20}>Option 2</MenuItem>
                                  <MenuItem value={30}>Option 3</MenuItem>
                                </Select>
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid item className="campReport-flex" xs={1.7}>
                            <Box className="text-box custom-select">
                              <FormControl
                                fullWidth
                                className="select-float-label represent-select"
                              >
                                <InputLabel
                                  id="select"
                                  className="floating-label"
                                >
                                  Type
                                </InputLabel>
                                <Select
                                  inputProps={{
                                    MenuProps: { disableScrollLock: true },
                                  }}
                                  labelId="select"
                                  id="select"
                                  value={age}
                                  label="Type"
                                  onChange={handleFilters}
                                  className="filter-label"
                                >
                                  <MenuItem value={10}>Option 1</MenuItem>
                                  <MenuItem value={20}>Option 2</MenuItem>
                                  <MenuItem value={30}>Option 3</MenuItem>
                                </Select>
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid item className="campReport-flex" xs={3.5}>
                            <Box
                              className="filled-box text-field-filter"
                              ml={4}
                            >
                              <TextField id="filled-basic" variant="filled" />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </TabPanel>
                    <TabPanel value="2">
                      <Box className="advanced-background">
                        <Box mt={3} mb={2} p={2}>
                          <Grid
                            container
                            alignItems="center"
                            justify="space-between"
                            sx={{
                              flexDirection: { xs: "column", lg: "row" },
                              spacing: { xs: 3, lg: 0 },
                            }}
                            spacing={2}
                          >
                            <Grid item xs={12} mb={4}>
                              <Grid
                                container
                                alignItems="center"
                                spacing={2}
                                mt={2}
                                sx={{
                                  flexDirection: { xs: "column", lg: "row" },
                                  spacing: { xs: 3, lg: 0 },
                                }}
                              >
                                <Grid item className="count-grid m-auto">
                                  <Typography
                                    className="count-font-01 ms-1"
                                    alignItems="center"
                                    justifyContent="center"
                                  >
                                    {" "}
                                    01{" "}
                                  </Typography>
                                  <div className="count-vertical-line"></div>
                                </Grid>

                                <Grid
                                  item
                                  xs={3}
                                  className="inp-field-bg  px-2
                                  py-2"
                                >
                                  <Box className="text-box custom-select">
                                    <FormControl
                                      fullWidth
                                      className="select-float-label represent-select"
                                    >
                                      <InputLabel
                                        id="select"
                                        className="floating-label"
                                      >
                                        Allocated On
                                      </InputLabel>

                                      <Select
                                        labelId="select"
                                        id="select"
                                        value={age}
                                        label="Campaign Option"
                                        onChange={handleFilters}
                                        // onClick={handleClickExport}
                                        className="filter-label"
                                      >
                                        <MenuItem
                                          value={10}
                                          // onClick={handleClickExport}
                                        >
                                          Lead Source
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                  </Box>
                                </Grid>

                                <Grid
                                  item
                                  xs={1.5}
                                  className="inp-field-bg  px-2
                                  py-2"
                                >
                                  <Box className="text-box custom-select">
                                    <FormControl
                                      fullWidth
                                      className="select-float-label represent-select"
                                    >
                                      <InputLabel
                                        id="select"
                                        className="floating-label"
                                      >
                                        Is
                                      </InputLabel>
                                      <Select
                                        labelId="select"
                                        id="select"
                                        value={age}
                                        label="Age in Days"
                                        onChange={handleFilters}
                                        className="filter-label"
                                      >
                                        <MenuItem value={10}>Option</MenuItem>
                                        <MenuItem value={20}>Option 2</MenuItem>
                                        <MenuItem value={30}>Option 3</MenuItem>
                                      </Select>
                                    </FormControl>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={5}
                                  className="inp-field-bg  px-2
                                  py-2 "
                                >
                                  <Box className=" text-box custom-select text-field-position">
                                    <FormControl
                                      fullWidth
                                      className="  select-float-label represent-select"
                                    >
                                      <InputLabel
                                        id="select"
                                        className="floating-label"
                                      >
                                        Criteria
                                      </InputLabel>

                                      <Select
                                        open={showSelect}
                                        onOpen={() => setShowSelect(true)}
                                        onClose={() => setShowSelect(false)}
                                        labelId="demo-multiple-chip-label"
                                        id="demo-multiple-chip"
                                        multiple
                                        value={personName}
                                        onChange={handleMultiselect}
                                        input={
                                          <OutlinedInput
                                            id="select-multiple-chip"
                                            label="Chip"
                                            className="menu-multiselect"
                                          />
                                        }
                                        renderValue={(selected) => (
                                          <Box
                                            sx={{
                                              display: "flex",
                                              flexWrap: "wrap",
                                              gap: 0.5,
                                            }}
                                            className="chip-background"
                                          >
                                            {selected.map((value) => (
                                              <Chip key={value} label={value} />
                                            ))}
                                          </Box>
                                        )}
                                        MenuProps={MenuProps}
                                      >
                                        <Box>
                                          <Grid
                                            container
                                            justify="space-between"
                                            alignItems="center"
                                          >
                                            <Grid
                                              item
                                              className="d-flex bg-F2F9FD "
                                              xs={10.7}
                                            >
                                              <Box
                                                className="filter-search2"
                                                width="100%"
                                              >
                                                <FormControl
                                                  sx={{ width: "100%" }}
                                                >
                                                  <OutlinedInput
                                                    id="search"
                                                    placeholder={"Search"}
                                                    endAdornment={
                                                      <InputAdornment position="end">
                                                        <img
                                                          src={common.loadImg(
                                                            "common-search.svg"
                                                          )}
                                                          alt="search"
                                                        />
                                                      </InputAdornment>
                                                    }
                                                    aria-describedby="outlined-weight-helper-text"
                                                  />
                                                </FormControl>
                                              </Box>
                                            </Grid>
                                            <Grid item>
                                              <IconButton
                                                onClick={() => {
                                                  setShowSelect(false);
                                                }}
                                              >
                                                <img
                                                  src={common.loadImg(
                                                    "dateClosekanban.svg"
                                                  )}
                                                  alt="close"
                                                />
                                              </IconButton>
                                            </Grid>
                                          </Grid>
                                        </Box>

                                        {names.map((name) => (
                                          <MenuItem
                                            key={name}
                                            value={name}
                                            style={getStyles(
                                              name,
                                              personName,
                                              theme
                                            )}
                                            sx={{
                                              display: "flex",
                                              flexWrap: "wrap",
                                              padding: "15px 0 15px 15px",
                                              gap: 3,
                                              borderBottom:
                                                "0.25px solid rgba(200, 200, 200, 0.4)",
                                            }}
                                          >
                                            <input
                                              type="checkbox"
                                              name="status"
                                              value={personName}
                                              className="radio-button ml-2"
                                              checked={
                                                personName.indexOf(name) > -1
                                              }
                                            />{" "}
                                            {name}
                                          </MenuItem>
                                        ))}
                                        <Grid item>
                                          <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            spacing={1}
                                            mt={1}
                                          >
                                            <Grid item>
                                              <Button
                                                className="run-btn"
                                                onClick={(e) => onSubmit(e)}
                                              >
                                                Add
                                              </Button>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Select>
                                    </FormControl>
                                    <span className="plus-bg-blue pointer ">
                                      <img
                                        src={common.loadImg("plusBlue.svg")}
                                        alt="plus"
                                        onClick={() => {
                                          setShowSelect(true);
                                        }}
                                      ></img>
                                    </span>
                                  </Box>
                                </Grid>
                                <Grid item xs={1} className="px-3 py-2">
                                  <Grid item>
                                    <img
                                      src={common.loadImg("minimizeRed.svg")}
                                      alt="minimize"
                                    ></img>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                alignItems="center"
                                spacing={2}
                                mt={2}
                                sx={{
                                  flexDirection: { xs: "column", lg: "row" },
                                  spacing: { xs: 3, lg: 0 },
                                }}
                              >
                                <Grid item className="count-grid m-auto">
                                  <Typography
                                    className="count-font-01 ms-1"
                                    alignItems="center"
                                    justifyContent="center"
                                  >
                                    02
                                  </Typography>
                                  <div className="count-vertical-line2"></div>
                                </Grid>
                                <Grid
                                  item
                                  xs={3}
                                  className="inp-field-bg  px-2
                                  py-2"
                                >
                                  <Box className="text-box custom-select">
                                    <FormControl
                                      fullWidth
                                      className="select-float-label represent-select"
                                    >
                                      <InputLabel
                                        id="select"
                                        className="floating-label"
                                      >
                                        Allocated On
                                      </InputLabel>

                                      <Select
                                        labelId="select"
                                        id="select"
                                        value={age}
                                        label="Campaign Option"
                                        onChange={handleFilters}
                                        // onClick={handleClickExport}
                                        className="filter-label"
                                      >
                                        <MenuItem
                                          value={10}
                                          // onClick={handleClickExport}
                                        >
                                          Lead Source
                                        </MenuItem>
                                        {/* <MenuItem value={20}>Option 2</MenuItem>
                                  <MenuItem value={30}>Option 3</MenuItem> */}
                                      </Select>
                                    </FormControl>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={1.5}
                                  className="inp-field-bg  px-2
                                  py-2"
                                >
                                  <Box className="text-box custom-select">
                                    <FormControl
                                      fullWidth
                                      className="select-float-label represent-select"
                                    >
                                      <InputLabel
                                        id="select"
                                        className="floating-label"
                                      >
                                        Is
                                      </InputLabel>
                                      <Select
                                        labelId="select"
                                        id="select"
                                        value={age}
                                        label="Age in Days"
                                        onChange={handleFilters}
                                        className="filter-label"
                                      >
                                        <MenuItem value={10}>Option</MenuItem>
                                        <MenuItem value={20}>Option 2</MenuItem>
                                        <MenuItem value={30}>Option 3</MenuItem>
                                      </Select>
                                    </FormControl>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={5}
                                  className="inp-field-bg  px-2
                                  py-2 "
                                >
                                  <Box className=" text-box custom-select text-field-position">
                                    <FormControl
                                      fullWidth
                                      className="  select-float-label represent-select"
                                    >
                                      <InputLabel
                                        id="select"
                                        className="floating-label"
                                      >
                                        Criteria
                                      </InputLabel>

                                      <Select
                                        open={showSelect}
                                        onOpen={() => setShowSelect(true)}
                                        onClose={() => setShowSelect(false)}
                                        labelId="demo-multiple-chip-label"
                                        id="demo-multiple-chip"
                                        multiple
                                        value={personName}
                                        onChange={handleMultiselect}
                                        input={
                                          <OutlinedInput
                                            id="select-multiple-chip"
                                            label="Chip"
                                            className="menu-multiselect"
                                          />
                                        }
                                        renderValue={(selected) => (
                                          <Box
                                            sx={{
                                              display: "flex",
                                              flexWrap: "wrap",
                                              gap: 0.5,
                                            }}
                                            className="chip-background"
                                          >
                                            {selected.map((value) => (
                                              <Chip key={value} label={value} />
                                            ))}
                                          </Box>
                                        )}
                                        MenuProps={MenuProps}
                                      >
                                        <Box>
                                          <Grid
                                            container
                                            justify="space-between"
                                            alignItems="center"
                                          >
                                            <Grid
                                              item
                                              className="d-flex bg-F2F9FD "
                                              xs={10.7}
                                            >
                                              <Box
                                                className="filter-search2"
                                                width="100%"
                                              >
                                                <FormControl
                                                  sx={{ width: "100%" }}
                                                >
                                                  <OutlinedInput
                                                    id="search"
                                                    placeholder={"Search"}
                                                    endAdornment={
                                                      <InputAdornment position="end">
                                                        <img
                                                          src={common.loadImg(
                                                            "common-search.svg"
                                                          )}
                                                          alt="search"
                                                        />
                                                      </InputAdornment>
                                                    }
                                                    aria-describedby="outlined-weight-helper-text"
                                                  />
                                                </FormControl>
                                              </Box>
                                            </Grid>
                                            <Grid item>
                                              <IconButton
                                                onClick={() => {
                                                  setShowSelect(false);
                                                }}
                                              >
                                                <img
                                                  src={common.loadImg(
                                                    "dateClosekanban.svg"
                                                  )}
                                                  alt="close"
                                                />
                                              </IconButton>
                                            </Grid>
                                          </Grid>
                                        </Box>

                                        {names.map((name) => (
                                          <MenuItem
                                            key={name}
                                            value={name}
                                            style={getStyles(
                                              name,
                                              personName,
                                              theme
                                            )}
                                            sx={{
                                              display: "flex",
                                              flexWrap: "wrap",
                                              padding: "15px 0 15px 15px",
                                              gap: 3,
                                              borderBottom:
                                                "0.25px solid rgba(200, 200, 200, 0.4)",
                                            }}
                                          >
                                            <input
                                              type="checkbox"
                                              name="status"
                                              value={personName}
                                              className="radio-button ml-2"
                                              checked={
                                                personName.indexOf(name) > -1
                                              }
                                            />{" "}
                                            {name}
                                          </MenuItem>
                                        ))}
                                        <Grid item>
                                          <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            spacing={1}
                                            mt={1}
                                          >
                                            <Grid item>
                                              <Button
                                                className="run-btn"
                                                onClick={(e) => onSubmit(e)}
                                              >
                                                Add
                                              </Button>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Select>
                                    </FormControl>
                                    <span className="plus-bg-blue pointer ">
                                      <img
                                        src={common.loadImg("plusBlue.svg")}
                                        alt="plus"
                                        onClick={() => {
                                          setShowSelect(true);
                                        }}
                                      ></img>
                                    </span>
                                  </Box>
                                </Grid>

                                <Grid item xs={1} className="px-3 py-2 pointer">
                                  <Grid item>
                                    <img
                                      src={common.loadImg("minimizeRed.svg")}
                                      alt="minimize"
                                    ></img>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                alignItems="center"
                                spacing={2}
                                mt={2}
                                sx={{
                                  flexDirection: { xs: "column", lg: "row" },
                                  spacing: { xs: 3, lg: 0 },
                                }}
                              >
                                <Grid item xs={1} className="center left-space" ml={3}>
                                  <img
                                    src={common.loadImg("orangePlus.svg")}
                                    alt="plus"
                                    width={40}
                                    height={40}
                                  ></img>
                                </Grid>

                                <Grid item xs={7} className=" mx-auto">
                                  <Grid
                                    container
                                    className="inp-field-bg inp-field-border"
                                    justifyContent="space-between"
                                  >
                                    <Grid item>
                                      <Grid container>
                                        <Grid item className="px-2 py-2">
                                          <Typography className=" criteria-font ">
                                            Criteria Pattern
                                          </Typography>
                                        </Grid>
                                        <Box className="vertical-divider1 ml-4">
                                          <Divider orientation="vertical" />
                                        </Box>

                                        <Grid item className="px-2 py-2 crieteria-color">
                                          <span className="criteria-count">
                                            01
                                          </span>
                                          <span>and</span>
                                          <span className="criteria-count">
                                            02
                                          </span>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item>
                                      <Grid container>
                                        <Box className="vertical-divider1 ml-4">
                                          <Divider orientation="vertical" />
                                        </Box>
                                        <Grid item className="px-2 py-2">
                                          <Typography>
                                            <a
                                              href="#"
                                              className="edit-font me-2"
                                            >
                                              Edit
                                            </a>
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </TabPanel>
                  </TabContext>
                </Box>
                <Box>
                  <Divider />
                </Box>
                <Box py={3} px={3}>
                  <Grid
                    container
                    spacing={3}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item>
                          <Grid container alignItems="center">
                            <Grid item>
                              <Link to="/report/addform">
                                <img
                                  alt={"back"}
                                  className="mx-2"
                                  src={common.loadImg(
                                    "iconly-light-arrow-down-circle.svg"
                                  )}
                                />
                              </Link>
                            </Grid>
                            <Grid item>
                              <Typography className="camp-sub-name ">
                                Back
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid container spacing={2}>
                        <Grid item>
                          <Box>
                            <Button
                              className="run-btn mt-3 "
                              onClick={(e) => onSubmit(e)}
                            >
                              Run
                            </Button>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box>
                            <Link
                              to="/report/basicInformation"
                              className="text-none"
                            >
                              {" "}
                              <Button className="next-btn mt-3 ">Next</Button>
                            </Link>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
});

export default ReportRepresentation;
