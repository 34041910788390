import React, { memo } from "react";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import { common } from "helpers";

const History = memo(({ handleExpanded, isExpand }) => {
  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <div className="history-count">
              <Typography className="notes-header">History</Typography>
              <div className="tasks-count ms-2">08</div>
        </div>
        <div className="history-search">
          <div className="d-flex"><img src={common.loadImg("search-icn.svg")} /></div>
        {isExpand === "detail" ? (
          <Typography
            className="expand pointer"
            onClick={() => handleExpanded("tab")}
          >
            Expand <img src={common.loadImg("expandkanban.svg")} alt="expand" />
          </Typography>
        ) : (
          <Typography
            className="expand pointer"
            onClick={() => handleExpanded("detail")}
          >
            Collapse{" "}
            <img src={common.loadImg("expandkanban.svg")} alt="Collapse" />
          </Typography>
        )}
        </div>
      </Box>
      <Box>
        <Divider />
      </Box>
      <Box mt={2} className="history-bg" px={3} py={3}>
        <div className="flex flex-col">
          <div className="flex  items-center my-3">
            <span className="dots relative w-2 h-2 mt-50 rounded-full un-active-bg"></span>
            <div className="ml-2 history-tab">
              <Paper variant="contained">
                <Box px={2} py={2}>
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <Typography className="history-email">
                        Updated by Gayashan X (Gayashan@novelwall.com)
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className="history-email">
                        Time :{" "}
                        <Typography component="span" className="history-date">
                          Yesterday 10:15pm
                        </Typography>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </div>
          </div>
          <div className="flex  items-center my-3">
            <span className="dots relative w-2 h-2 mt-50 rounded-full un-active-bg"></span>
            <div className="ml-2 history-tab">
              <Paper variant="contained">
                <Box px={2} py={2}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Typography className="history-email">
                        Updated by Gayashan X (Gayashan@novelwall.com)
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className="history-email">
                        Time :{" "}
                        <Typography component="span" className="history-date">
                          Yesterday 10:15pm
                        </Typography>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </div>
          </div>
          <div className="flex  items-center my-3">
            <span className="dots relative w-2 h-2 mt-50 rounded-full active-bg"></span>
            <div className="ml-2 history-tab">
              <Paper variant="contained">
                <Box px={2} py={2}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Typography className="history-email">
                        Updated by Gayashan X (Gayashan@novelwall.com)
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className="history-email">
                        Time :{" "}
                        <Typography component="span" className="history-date">
                          Yesterday 10:15pm
                        </Typography>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </div>
          </div>
          <div className="flex  items-center my-3">
            <span className="relative w-2 h-2 mt-50 rounded-full un-active-bg"></span>
            <div className="ml-2 history-tab">
              <Paper variant="contained">
                <Box px={2} py={2}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Typography className="history-email">
                        Updated by Gayashan X (Gayashan@novelwall.com)
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className="history-email">
                        Time :{" "}
                        <Typography component="span" className="history-date">
                          Yesterday 10:15pm
                        </Typography>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
});

export { History };
