import {
  Box,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { api, common, envConfig } from "helpers";
import { CRM_CREATE_USER } from "helpers/service";

function OtpVerification() {
  // const[message, setMessage] = useState("");
  // const handleChange = event => {
  //   setMessage(event.target.value);
  // };
  const [otp, setOtp] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [error, setError] = useState(null);
  const handleOtpChange = (val) => {
    setOtp(val);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (otp !== "") {
      setIsLoader(true);
      setError("");
      let params = JSON.stringify({
        name: "validateOtp",
        parameters: [
          {
            username: common.localGet("email"),
          },
          {
            otp: otp,
          },
        ],
      });
      let url = `${envConfig.AUTH_REST_URL}${CRM_CREATE_USER}`;
      let data = {
        url: url,
        type: "dynamic",
        method: "POST",
        auth: "none",
        cType: 3,
        body: params,
      };

      api.call(
        data,
        (res) => {
          console.log("res", res);
          if (res.status === 200 && res.data.result === "S") {
            common.snack("S", "OTP verified successfully!");
            setTimeout(() => {
              window.location.href = common.url("/reset/password");
            }, 500);
          } else {
            common.snack("E", "Please check the OTP entered!");
          }
        },
        (error) => {
          common.snack("E", "Please check the OTP entered!");
          setIsLoader(false);
        },
        (final) => {
          setIsLoader(false);
        }
      );
    } else {
      setIsLoader(false);
      setError("OTP is required!");
    }
  };
  return (
    <Paper className="login-card" elevation={3}>
      <Container maxWidth="md">
        <Box p={2} px={{ xs: 1, sm: 2, md: 3, lg: 5, xl: 5 }}>
          <img src={common.loadImg("login_logo.svg")} alt="logo" />
        </Box>
      </Container>
      <Divider />
      <Container maxWidth="md">
        <Box px={{ xs: 1, sm: 2, md: 3, lg: 5, xl: 5 }}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Box mt={2}>
                <Typography className="login-title">
                  Otp Verification
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          mt={2}
          className="otp-wrap"
          px={{ xs: 1, sm: 2, md: 3, lg: 5, xl: 5 }}
        >
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Box className="body-th common-input-style">
                <OtpInput
                  value={otp}
                  // onChange={handleChange}
                  onChange={(e) => handleOtpChange(e)}
                  numInputs={6}
                  isInputNum={true}
                  separator={""}
                  className="otp-wrap-input"
                  inputStyle={{
                    width: "3rem",
                    height: "3rem",
                    margin: "0 8px",
                  }}
                />
              </Box>
              <p className="error-txt show form-text">{error}</p>
            </Grid>
          </Grid>
        </Box>
        <Box mt={2} px={{ xs: 1, sm: 2, md: 3, lg: 5, xl: 5 }}>
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
              <Button className="sign-in-btn mb-2" onClick={(e) => onSubmit(e)}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Box mt={1}>
        <Divider />
      </Box>
      <Container maxWidth="md">
        <Box p={2} px={{ xs: 1, sm: 2, md: 3, lg: 5, xl: 5 }}>
          <Typography className="forgot-text">
            <a href="/signin" className="new-acc-text" component="span">
              Click here to Login{" "}
              <img
                src={common.loadImg("arrowBlack.svg")}
                alt={"arrow"}
                className="arrow-icon"
              />
            </a>
          </Typography>
        </Box>
      </Container>
    </Paper>
  );
}

export { OtpVerification };
