import { useState, useEffect, useRef, React } from "react";
import { Button, IconButton, Typography, Box, Divider } from "@mui/material";
import { common, envConfig } from "helpers";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import Modal from "@mui/material/Modal";
import StatusBar from "elements/StatusBar";
import { Link, useOutletContext } from "react-router-dom";
import { formatNumber } from "helpers/tools";

let ModNameChange = envConfig.ModNameChange;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #ffffff",
  boxShadow: 24,
  p: 4,
};

const PARENT_NODE_EDIT = false;

const ParentCard = (props) => {
  const { data, handleCollapse } = props;
  const [permission] = useOutletContext();
  const [showEdit, setShowEdit] = useState(false);
  let editRef = useRef();

  const [selectedCard, setSelectedCard] = useState(false);
  const handleOpenEmployeeList = (data) => setSelectedCard(data);
  const handleCloseEmployeeList = () => setSelectedCard(null);

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    let handler = (e) => {
      if (!editRef?.current?.contains(e.target)) {
        setShowEdit(false);
      }
    };
    document.addEventListener("mousedown", handler);
  });

  const handleClickExport = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openNotification = Boolean(anchorEl);
  const id = openNotification ? "simple-popover" : undefined;

  const plusIcon = common.loadImg("plus-icn.svg");
  const minusIcon = common.loadImg("minus-icn.svg");

  const [isChecked, setIsChecked] = useState(false);

  const toggleCheck = () => {
    setIsChecked(!isChecked);
  };

  const checkbox = isChecked ? plusIcon : minusIcon;

  return (
    <>
      <div className="chart-parent-wrap">
        <div className="chart-card chart-parent">
          <div className="add-left">
            <button className="more-icon btn-plus" onClick={handleCollapse}>
              <img
                src={checkbox}
                alt="add"
                className="add-left-btn"
                onClick={toggleCheck}
              />
            </button>
          </div>
          <div className="add-right">
            {!common.givenPermission(permission, "hrms:department", "add") ? (
              <Link to={`/setting/hrms/department/add/${data.TerritoryId}`}>
                <IconButton className="more-icon">
                  <img
                    src={common.loadImg("plus-white-icn.svg")}
                    alt="add"
                    className="add-right-btn"
                  />
                </IconButton>
              </Link>
            ) : null}
          </div>
          <p className="title">{data.TerritoryName || ""}</p>
          <label className="label">{data.TerritoryCode || ""}</label>
          <div className="chart-footer">
            <div
              className="pointer"
              onClick={() => handleOpenEmployeeList(data)}
            >
              <img
                src={common.loadImg("users-icn.svg")}
                alt="document"
                className="pe-2"
              />
              <label className="count cpoint">
                {formatNumber(data.TeamCount)}
              </label>
            </div>
            {PARENT_NODE_EDIT ? (
              <>
                {!common.givenPermission(
                  permission,
                  "hrms:department",
                  "update"
                ) ? (
                  <div className="dept-child-more-icn">
                    <IconButton
                      className="more-icon"
                      onClick={handleClickExport}
                    >
                      <MoreHorizIcon />
                    </IconButton>
                    {showEdit && (
                      <div className="edit-wrap" ref={editRef}>
                        <Link
                          to={`/setting/hrms/department/edit/${data.TerritoryId}`}
                          className="edit-icon-department"
                        >
                          EDIT
                          <img
                            src={common.loadImg("editIconkanban.svg")}
                            className="pl-3"
                          />
                        </Link>
                      </div>
                    )}
                  </div>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
      </div>

      <Modal
        open={!!selectedCard}
        onClose={handleCloseEmployeeList}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="chart-head"
          >
            {`${selectedCard?.TerritoryCode} ${
              ModNameChange ? "USER" : "EMPLOYEE"
            } LIST`}
          </Typography>
          <Divider />
          <Box id="modal-modal-description" sx={{ mt: 2 }}>
            {selectedCard?.CholaCrmTerritoryTeamView?.map((employee) => (
              <div key={employee?.EmployeeId} className="chart-wrapper row">
                <div className="chart-wrap-content col-md-10">
                  <div className="row">
                    <div className="col-md-2 align-self-center">
                      <img
                        src={common.loadImg("users-icn.svg")}
                        alt="document"
                        className="pe-2"
                      />
                    </div>
                    <div className="col-md-10">
                      <label>{employee?.EmployeeName || ""}</label>
                      <span>{employee?.EmployeeCode || ""}</span>
                    </div>
                  </div>
                </div>
                {!common.givenPermission(
                  permission,
                  "hrms:department",
                  "update"
                ) ? (
                  <div className="edit-content col-md-2">
                    <img src={common.loadImg("edit-icon.svg")} alt="edit" />
                  </div>
                ) : null}
              </div>
            ))}

            {selectedCard?.CholaCrmTerritoryTeamView?.length === 0 ? (
              <StatusBar status="empty" />
            ) : null}
            <Divider />
          </Box>
          <Box>
            <Button
              className="save-note mt-3"
              type="submit"
              disabled={false}
              onClick={handleCloseEmployeeList}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ParentCard;
