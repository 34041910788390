import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  FormControl,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Select, { components } from "react-select";
import { Link } from "react-router-dom";
import { common, api, envConfig } from "helpers";
import csc from "countries-states-cities";
import AmlHashForm from "aml-hash-form";
import { useNavigate } from "react-router-dom";

import { CRM_CREATE_USER } from "helpers/service";
function SignUp() {
  const formFields = {
    companyName: {
      validate: [
        "req#Company is required!",
        "min:3#Company Name should be minimum 3 character",
      ],
    },
    firstName: {
      validate: ["req#First name is required!"],
    },
    lastName: {
      validate: ["req#last name is required!"],
    },
    address: {
      validate: ["req#Address is required!"],
    },
    country: {
      validate: ["objValReq#Country is required!"],
      obj: "value",
    },
    state: {
      validate: ["objValReq#State is required!"],
      obj: "value",
    },
    city: {
      validate: ["objValReq#City is required!"],
      obj: "value",
    },
    postalCode: {
      validate: ["req#Postal code is required!"],
    },
    timezoneCode: {
      validate: ["objValReq#Timezone Code is required!"],
      obj: "value",
    },
    mobileNo: {
      validate: ["req#Mobile no is required!"],
    },
    email: {
      validate: [
        "req#Email is required!",
        "email#Please enter a valid email address",
      ],
    },
    password: {
      validate: [
        "req#Password is required!",
        "min:6#Password should be minimum 6 Digits",
        "max:20#Password should be maximum 15 Digits",
      ],
    },
    currencyCode: {
      validate: ["req#Currency code is required!"],
    },
  };
  const {
    fields,
    errors,
    setValue,
    setMultiValue,
    handleChange,
    handleSubmit,
  } = AmlHashForm(formFields);
  // Floating React select
  const navigate = useNavigate();
  const { ValueContainer, Placeholder } = components;
  const [isLoader, setIsLoader] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [timezoneData, setTimezoneData] = useState([]);

  useEffect(() => {
    getCountryList();
  }, []);

  const getCountryList = () => {
    let result = common.getOptionsData(
      csc.getAllCountries(),
      "name",
      "id",
      "Country"
    );
    setCountryData(result);
  };
  const getStateList = (item) => {
    let result = [];
    if (item !== null) {
      result = common.getOptionsData(
        csc.getStatesOfCountry(item.value),
        "name",
        "id",
        "State"
      );
    }
    setStateData(result);
  };
  const getCityList = (item) => {
    let result = [];
    if (item !== null) {
      result = common.getOptionsData(
        csc.getCitiesOfState(item.value),
        "name",
        "id",
        "City"
      );
    }
    setCityData(result);
  };

  const getTimezoneList = (item) => {
    let result,
      timeZoneList = [];
    if (item !== null) {
      result = csc.getCountryById(item.value);
      timeZoneList = common.getOptionsData(
        result.timezones,
        "zoneName",
        "zoneName",
        "Timezone"
      );
      setMultiValue({ country: item, currencyCode: result.currency });
    } else {
      setMultiValue({ country: "", countryCode: "" });
    }
    setTimezoneData(timeZoneList);
  };
  const onSubmit = (e) => {
    e.preventDefault();

    let isValid = handleSubmit();

    if (isValid) {
      setIsLoader(true);

      let params = JSON.stringify({
        name: "createTenant",
        parameters: [
          {
            TenantCode: fields.companyName.toUpperCase(),
          },
          {
            TenantName: fields.companyName,
          },
          {
            EmailId: fields.email,
          },
          {
            Password: fields.password,
          },
          {
            FirstName: fields.firstName,
          },
          {
            LastName: fields.lastName,
          },
          {
            Address: fields.address,
          },
          {
            Country: fields.country.label,
          },
          {
            State: fields.state.label,
          },
          {
            City: fields.city.label,
          },
          {
            PostalCode: fields.postalCode,
          },
          {
            Locale: "en-IN",
          },
          {
            TimezoneCode: fields.timezoneCode.label,
          },
          {
            MobileNo: fields.mobileNo,
          },
          {
            CurrencyCode: fields.currencyCode,
          },
          {
            ParentTenantId: envConfig.PARENT_TENANT_ID,
          },
        ],
      });
      
      let data = {
        url: "cholaCreateUser",
        method: "POST",
        auth: "",
        cType: 3,
        body: params,
      };

      api.call(
        data,
        (res) => {
          if (res.status === 200) {
            let data = res.data;
            
            let tenantId = parseInt(data.result);
            if (tenantId > 0) {
              createTenantEntity(tenantId);
              common.snack("S", "Registered Successfully.");
            } else {
              common.snack(
                "E",
                "Error occurred in configuration. Please contact administrator."
              );
              setIsLoader(false);
            }
          } else {
            let errorText = "";
            let text = res.data;
            
            if (text) {
              if (text.includes("Error while creating the Work Location")) {
                errorText = "Please enter valid postal code";
              } else if (text.includes("unique constraint")) {
                errorText =
                  "Already registered this company name or email Address";
              } else {
                errorText = "Something went wrong!";
              }
            } else {
              errorText = "Something went wrong!";
            }

            common.snack("E", errorText);
            setIsLoader(false);
          }
        },
        (error) => {
          common.snack("E", "Something went wrong!");
          setIsLoader(false);
        },
        (final) => {
        }
      );
    } else {
      setIsLoader(false);
    }
  };

  const createTenantEntity = (tenantId) => {
    var createParams = JSON.stringify({
      name: "createTenantEntity",
      parameters: [
        { TenantId: tenantId },
        { ParentTenantId: envConfig.PARENT_TENANT_ID },
      ],
    });
    let url = `${envConfig.AUTH_REST_URL}${CRM_CREATE_USER}`;
    let data = {
      url: url,
      type: "dynamic",
      method: "POST",
      auth: "basic",
      cType: 3,
      createParams,
    };

    api.call(
      data,
      (res) => {
        
        common.snack("S", "Account created Successfully!");
        if (res.status == 200 || res?.data?.result == "S") {
          common.snack("S", "Registeration Successfully completed");
          navigate("/signin");
          setIsLoader(false);
        } else {
          common.snack("E", res.data);
          setIsLoader(false);
        }
      },
      (error) => {
        common.snack("E", "Something went wrong!");
        setIsLoader(false);
      },
      (final) => {
      }
    );
  };

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };
  return (
    <Paper className="login-card" elevation={3}>
      <Container maxWidth="md">
        <Box p={2} px={{ xs: 1, sm: 2, md: 3, lg: 7, xl: 7 }}>
          <img src={common.loadImg("login_logo.svg")} alt="logo" />
        </Box>
      </Container>
      <Divider />
      <Container maxWidth="md">
        <Box px={{ xs: 1, sm: 2, md: 3, lg: 8, xl: 8 }}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Box mt={2}>
                <Typography className="login-title">Welcome,</Typography>
                <Typography className="login-title">
                  Register to Your Account
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box mt={2} className="register-wrap">
          <Row className="m-0">
            <Col xs={6} className="body-th common-input-style">
              <Box className="filled-box">
                <TextField
                  label="Company Name"
                  variant="filled"
                  name="companyName"
                  onChange={handleChange}
                  value={fields.companyName}
                />
              </Box>
              <p className="error-txt show form-text">{errors.companyName}</p>
            </Col>
            <Col xs={6} className="body-th common-input-style">
              <Box className="filled-box">
                <TextField
                  label="First Name"
                  variant="filled"
                  name="firstName"
                  onChange={handleChange}
                  value={fields.firstName}
                />
              </Box>
              <p className="error-txt show form-text">{errors.firstName}</p>
            </Col>
            <Col xs={6} className="body-th common-input-style">
              <Box className="filled-box">
                <TextField
                  label="Last Name"
                  variant="filled"
                  name="lastName"
                  onChange={handleChange}
                  value={fields.lastName}
                />
              </Box>
              <p className="error-txt show form-text">{errors.lastName}</p>
            </Col>
            <Col xs={6} className="body-th common-input-style">
              <Box className="filled-box">
                <TextField
                  label="Email"
                  variant="filled"
                  name="email"
                  onChange={handleChange}
                  value={fields.email}
                />
              </Box>
              <p className="error-txt show form-text">{errors.email}</p>
            </Col>
            <Col xs={6} className="body-th common-input-style">
              <Box className="filled-box">
                <TextField
                  label="Password"
                  variant="filled"
                  name="password"
                  type="password"
                  onChange={handleChange}
                  value={fields.password}
                />
              </Box>
              <p className="error-txt show form-text">{errors.password}</p>
            </Col>
            <Col xs={6} className="body-th common-input-style">
              <Box className="filled-box">
                <TextField
                  label="Address"
                  variant="filled"
                  name="address"
                  onChange={handleChange}
                  value={fields.address}
                />
              </Box>
              <p className="error-txt show form-text">{errors.address}</p>
            </Col>
            <Col xs={6} className="body-th common-input-style">
              <Box className="filled-box">
                <FormControl
                  fullWidth
                  className="select-float-label common-padding-zero-select"
                >
                  <Select
                    className="text-box custom-react-select"
                    labelId="select"
                    id="select"
                    label="Select Country"
                    menuPortalTarget={document.body}
                    name="country"
                    isClearable={true}
                    options={countryData}
                    onChange={(data) => {
                      getStateList(data);
                      getTimezoneList(data);
                    }}
                    value={fields.country}
                    components={{
                      ValueContainer: CustomValueContainer,
                    }}
                    placeholder="Select Country"
                    styles={{
                      container: (provided, state) => ({
                        ...provided,
                        marginTop: 0,
                      }),
                      valueContainer: (provided, state) => ({
                        ...provided,
                        overflow: "visible",
                      }),
                      placeholder: (provided, state) => ({
                        ...provided,
                        position: "absolute",
                        top:
                          state.hasValue || state.selectProps.inputValue
                            ? -15
                            : "10%",
                        transition: "top 0.1s, font-size 0.1s",
                        fontSize:
                          state.hasValue || state.selectProps.inputValue
                            ? 11
                            : 13,
                        color: "#9daabb",
                      }),
                    }}
                  ></Select>
                </FormControl>
              </Box>
              <p className="error-txt show form-text">{errors.country}</p>
            </Col>
            <Col xs={6} className="body-th common-input-style">
              <Box className="filled-box">
                <FormControl
                  fullWidth
                  className="select-float-label common-padding-zero-select"
                >
                  <Select
                    className="text-box custom-react-select"
                    labelId="select"
                    id="select"
                    name="state"
                    label="Select State"
                    menuPortalTarget={document.body}
                    isClearable={true}
                    options={stateData}
                    onChange={(data) => {
                      setValue("state", data);
                      getCityList(data);
                    }}
                    value={fields.state}
                    components={{
                      ValueContainer: CustomValueContainer,
                    }}
                    placeholder="Select State"
                    styles={{
                      container: (provided, state) => ({
                        ...provided,
                        marginTop: 0,
                      }),
                      valueContainer: (provided, state) => ({
                        ...provided,
                        overflow: "visible",
                      }),
                      placeholder: (provided, state) => ({
                        ...provided,
                        position: "absolute",
                        top:
                          state.hasValue || state.selectProps.inputValue
                            ? -15
                            : "10%",
                        transition: "top 0.1s, font-size 0.1s",
                        fontSize:
                          state.hasValue || state.selectProps.inputValue
                            ? 11
                            : 13,
                        color: "#9daabb",
                      }),
                    }}
                  ></Select>
                </FormControl>
              </Box>
              <p className="error-txt show form-text">{errors.state}</p>
            </Col>
            <Col xs={6} className="body-th common-input-style">
              <Box className="filled-box">
                <FormControl
                  fullWidth
                  className="select-float-label common-padding-zero-select"
                >
                  <Select
                    className="text-box custom-react-select"
                    labelId="select"
                    id="select"
                    label="Select City"
                    menuPortalTarget={document.body}
                    isClearable={true}
                    options={cityData}
                    onChange={(data) => {
                      setValue("city", data);
                    }}
                    value={fields.city}
                    components={{
                      ValueContainer: CustomValueContainer,
                    }}
                    placeholder="Select City"
                    styles={{
                      container: (provided, state) => ({
                        ...provided,
                        marginTop: 0,
                      }),
                      valueContainer: (provided, state) => ({
                        ...provided,
                        overflow: "visible",
                      }),
                      placeholder: (provided, state) => ({
                        ...provided,
                        position: "absolute",
                        top:
                          state.hasValue || state.selectProps.inputValue
                            ? -15
                            : "10%",
                        transition: "top 0.1s, font-size 0.1s",
                        fontSize:
                          state.hasValue || state.selectProps.inputValue
                            ? 11
                            : 13,
                        color: "#9daabb",
                      }),
                    }}
                  ></Select>
                </FormControl>
              </Box>
              <p className="error-txt show form-text">{errors.city}</p>
            </Col>
            <Col xs={6} className="body-th common-input-style">
              <Box className="filled-box">
                <TextField
                  label="Postal Code"
                  variant="filled"
                  name="postalCode"
                  onChange={handleChange}
                  value={fields.postalCode}
                />
              </Box>
              <p className="error-txt show form-text">{errors.postalCode}</p>
            </Col>
            <Col xs={6} className="body-th common-input-style">
              <Box className="filled-box">
                <FormControl
                  fullWidth
                  className="select-float-label common-padding-zero-select"
                >
                  <Select
                    className="text-box custom-react-select"
                    labelId="select"
                    id="select"
                    label="Select TimeZone"
                    menuPortalTarget={document.body}
                    isClearable={true}
                    options={timezoneData}
                    onChange={(data) => {
                      setValue("timezoneCode", data);
                    }}
                    value={fields.timezoneCode}
                    components={{
                      ValueContainer: CustomValueContainer,
                    }}
                    placeholder="Select TimeZone"
                    styles={{
                      container: (provided, state) => ({
                        ...provided,
                        marginTop: 0,
                      }),
                      valueContainer: (provided, state) => ({
                        ...provided,
                        overflow: "visible",
                      }),
                      placeholder: (provided, state) => ({
                        ...provided,
                        position: "absolute",
                        top:
                          state.hasValue || state.selectProps.inputValue
                            ? -15
                            : "10%",
                        transition: "top 0.1s, font-size 0.1s",
                        fontSize:
                          state.hasValue || state.selectProps.inputValue
                            ? 11
                            : 13,
                        color: "#9daabb",
                      }),
                    }}
                  ></Select>
                </FormControl>
              </Box>
              <p className="error-txt show form-text">{errors.timezoneCode}</p>
            </Col>
            <Col xs={6} className="body-th common-input-style">
              <Box className="filled-box">
                <TextField
                  label="Mobile No"
                  variant="filled"
                  name="mobileNo"
                  onChange={handleChange}
                  value={fields.mobileNo}
                />
              </Box>
              <p className="error-txt show form-text">{errors.mobileNo}</p>
            </Col>
            <Col xs={6} className="body-th common-input-style">
              <Box className="filled-box">
                <TextField
                  label="Currency Code"
                  variant="filled"
                  name="currencycode"
                  // onChange={handleChange}
                  value={fields.currencyCode}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Box>
              <p className="error-txt show form-text">{errors.currencyCode}</p>
            </Col>
          </Row>
        </Box>
        <Box mt={2}>
          <Grid container>
            <Grid item xs={12}>
              <Typography>
                By signing up you are agreeing to our Subscription Agreement.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box mt={2}>
          <Grid container>
            <Grid item xs={12} className="signup-btn">
              <Button
                onClick={(e) => onSubmit(e)}
                className="sign-in-btn signupBtn"
                //disabled={isLoader}
              >
                {isLoader ? "Processing" : "Sign Up"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Box mt={1}>
        <Divider />
      </Box>
      <Container maxWidth="md">
        <Box p={2} px={{ xs: 1, sm: 2, md: 3, lg: 7, xl: 7 }}>
          <Typography className="forgot-text">
            <Link to="/signin" className="new-acc-text" component="span">
              Click here to Login{" "}
              <img
                src={common.loadImg("arrowBlack.svg")}
                alt={"arrow"}
                className="arrow-icon"
              />
            </Link>
          </Typography>
        </Box>
      </Container>
    </Paper>
  );
}

export { SignUp };
