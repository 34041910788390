import React, { memo, useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { common } from "helpers";
import ListView from "elements/ListView";
import { useParams, useOutletContext } from "react-router-dom";
import DynamicForm from "./DynamicForm";

const CallLog = memo(({ handleExpanded, isExpand, id }) => {
  let { mod } = useParams();
  const [isForm, setIsForm] = useState(false);
  const [objectId, setObjectId] = useState(id);
  const [callLogId, setCallLogId] = useState(0);
  const [objectType, setObjectType] = useState(mod);
  const [permission] = useOutletContext();

  useEffect(() => {
    if (id) {
      setObjectId(id);
      setObjectType(mod);
    }
  }, [callLogId]);

  const changeFormType = (val, type, data, ids) => {
    if (data) {
      setCallLogId(data.CallLogId);
    } else {
      setCallLogId(0);
    }
    setIsForm(val);
  };

  const ModToKey = (modArg) => {
    let key = {
      campaign: "crm:campaigns",
      enquiry: "crm:enquiry",
      lead: "crm:lead",
      deal: "crm:deal",
    };
    return ["contacts"].includes(modArg) ? "contact" : key[modArg];
  };

  return (
    <div className="relative pb-3">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box className="crm-submenu-wrap">
          <Grid
            container
            spacing={2}
            alignItems="center"
            className="subheader-elements"
          >
            <Grid item>
              {isExpand === "detail" ? (
                <Typography
                  className="expand pointer"
                  onClick={() => handleExpanded("tab")}
                >
                  Expand{" "}
                  <img src={common.loadImg("expandkanban.svg")} alt="expand" />
                </Typography>
              ) : (
                <Typography
                  className="expand pointer"
                  onClick={() => handleExpanded("detail")}
                >
                  Collapse{" "}
                  <img
                    src={common.loadImg("expandkanban.svg")}
                    alt="Collapse"
                  />
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
      {!isForm && (
        <ListView
          module={"callLogs"}
          changeForm={(val, type, item) => changeFormType(val, type, item)}
          query={{
            ObjectId: objectId,
            ObjectType: common.getObjectNameByPage(objectType),
          }}
          accAdd={true}
          accExport={true}
          accDelete={true}
          accUpdate={true}
          accImport={true}
          // accAdd={!common.givenPermission(permission,ModToKey(mod),'add')}
          // accExport={!common.givenPermission(permission,ModToKey(mod),'export')}
          // accDelete={!common.givenPermission(permission,ModToKey(mod),'delete')}
          // accUpdate={!common.givenPermission(permission,ModToKey(mod),'update')}
          // accImport={!common.givenPermission(permission,ModToKey(mod),'import')}
          sort="CreatedOn:desc"
          type={"subMenu"}
          className={"table-scroll-position-collapse"}
          isExpand={isExpand}
          id={id}
        />
      )}
      {isForm && (
        <DynamicForm
          module={"callLogs"}
          data={{
            value1: callLogId > 0 ? callLogId : "",
            value2: objectId,
            value3: common.getObjectNameByPage(objectType),
          }}
          closeForm={(val, type, data, id) =>
            changeFormType(val, type, data, id)
          }
          accAdd={false}
          accUpdate={false}
          // accAdd={common.givenPermission(permission, ModToKey(mod), "add")}
          // accUpdate={common.givenPermission(
          //   permission,
          //   ModToKey(mod),
          //   "update"
          // )}
        />
      )}
    </div>
  );
});

export { CallLog };
