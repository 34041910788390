import {
  Box,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import AmlHashForm from "aml-hash-form";
import { api, common, envConfig } from "helpers";
import { CRM_CREATE_USER } from "helpers/service";

function ForgotPassword() {
  const formFields = {
    email: {
      validate: [
        "req#Email is required!",
        // "email#Please enter a valid email address",
      ],
    },
  };
  const { fields, errors, handleChange, handleSubmit } =
    AmlHashForm(formFields);
  const [isLoader, setIsLoader] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    let isValid = handleSubmit();
    if (isValid) {
      setIsLoader(true);
      let params = JSON.stringify({
        name: "forgotPassword",
        parameters: [
          {
            username: fields.email,
          },
        ],
      });
      let url = `${envConfig.AUTH_REST_URL}${CRM_CREATE_USER}`;
      let data = {
        url: url,
        type: "dynamic",
        method: "POST",
        auth: "none",
        cType: 3,
        body: params,
      };

      api.call(
        data,
        (res) => {
          console.log("res", res);
          if (res.status === 200 && res.data.result === "S") {
            common.localSet("email", fields.email);
            common.snack(
              "S",
              "Verification code has been sent to your Email please check."
            );
            setTimeout(() => {
              window.location.href = common.url("/otp/verification");
            }, 500);
          } else {
            common.snack("E", "Please enter valid Email");
          }
        },
        (error) => {
          common.snack("E", "Please enter valid Email");
          setIsLoader(false);
        },
        (final) => {
          setIsLoader(false);
        }
      );
    } else {
      setIsLoader(false);
    }
  };
  return (
    <Paper className="login-card" elevation={3}>
      <Container maxWidth="md">
        <Box p={2} px={{ xs: 1, sm: 2, md: 3, lg: 7, xl: 7 }}>
          <img src={common.loadImg("login_logo.svg")} alt="logo" />
        </Box>
      </Container>
      <Divider />
      <Container maxWidth="md">
        <Box px={{ xs: 1, sm: 2, md: 3, lg: 8, xl: 8 }}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Box mt={2}>
                <Typography className="login-title">Forgot Password</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          mt={2}
          className="otp-wrap"
          px={{ xs: 1, sm: 2, md: 3, lg: 8, xl: 8 }}
        >
          <Row className="m-0">
            <Col xs={12} className="body-th common-input-style mb-3 ps-0">
              <Box className="filled-box">
                <TextField
                  id="filled-basic"
                  label="Email"
                  variant="filled"
                  name="email"
                  type="email"
                  onChange={handleChange}
                  value={fields.email}
                />
              </Box>
              <p className="error-txt show form-text">{errors.email}</p>
            </Col>
          </Row>
        </Box>
        <Box mt={2} px={{ xs: 1, sm: 2, md: 3, lg: 8, xl: 8 }}>
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
              <Button className="sign-in-btn mb-2" onClick={(e) => onSubmit(e)}>
                {isLoader ? "Processing" : "Submit"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Box mt={1}>
        <Divider />
      </Box>
      <Container maxWidth="md">
        <Box p={2} px={{ xs: 1, sm: 2, md: 3, lg: 7, xl: 7 }}>
          <Typography className="forgot-text">
            <Link to="/signin" className="new-acc-text" component="span">
              Click here to Login{" "}
              <img
                src={common.loadImg("arrowBlack.svg")}
                alt={"arrow"}
                className="arrow-icon"
              />
            </Link>
          </Typography>
        </Box>
      </Container>
    </Paper>
  );
}

export { ForgotPassword };
