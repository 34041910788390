import React, { memo, Fragment, useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import {
  FormControl,
  Grid,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import * as Services from "helpers/service";
import { common, envConfig, api } from "helpers";
import StatusBar from "./StatusBar";
import ListView from "elements/ListView";
import { useNavigate, useParams } from "react-router-dom";
import DynamicForm from "./DynamicForm";

const EnquiryList = memo(({ handleExpanded, isExpand, id }) => {
  let { mod } = useParams();
  const listInnerRef = useRef();
  const userInfo = common.userInfo();
  const [isForm, setIsForm] = useState(false);
  const [paging, setPaging] = useState({
    page: 1,
    size: 5,
    total: 1,
  });

  const [totalRecords, setTotalRecords] = useState(0);
  const [listId, setListId] = useState(0);
  const [campaignId, setCampaignId] = useState(id);
  const [listResponse, setListResponse] = useState([]);
  const [filterListName, setFilterListName] = useState("");
  const [memberListResponse, setMemberListResponse] = useState({});
  const [listName, setListName] = useState("");
  const [campaignData, setCampaignData] = useState({});
  const [formType, setFormType] = useState("add");
  const [module, setModule] = useState("");
  const [selectedEnquiry, setSelectedEnquiry] = useState("");
  const [enquiryStatus, setEnquiryStatus] = useState("");
  const [reload, setReload] = useState(new Date());
  const [listDetailsId, setListDetailsId] = useState("");
  const [hasMore, setHasMore] = React.useState(null);

  useEffect(() => {
    getAllMarketingLists();
    getCampaignDetail();
  }, []);

  /**
   * Get Campaign Details by ID
   */
  const getCampaignDetail = () => {
    let url = envConfig.BASE_API + Services.CRM_CAMPAIGNS + "/" + id;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
      moreHead: { rfv: 2 },
      cType: 4,
    };
    api.call(
      data,
      (response) => {
        if (response.status == 200) {
          setCampaignData(response.data);
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  /**
   * Get all Marketing Lists from DB
   */
  const getAllMarketingLists = (pageNo, queryParam = false) => {
    if (pageNo) {
      paging["page"] = pageNo;
      setPaging(paging);
    } else {
      setEnquiryStatus("process");
    }

    let tempList = [];
    if (!queryParam && paging.page == 1) {
      setListResponse([]);
      setMemberListResponse({ items: [], totalResults: 0 });
    } else {
      listResponse.map((item) => {
        tempList.push(item);
      });
    }

    let filter = `?q=TenantId=${userInfo.pTenantId} AND OrgId=${
      userInfo.pOrgId
    } AND CampaignId=${
      campaignId ? campaignId : ""
    } AND UPPER(ListName) LIKE '*${filterListName}*' &totalResults=true&orderBy=CreatedOn:desc&offset=${
      (paging.page - 1) * paging.size
    }&limit=${paging.size}`;

    if (queryParam) {
      filter = `?q=TenantId=${userInfo.pTenantId} AND OrgId=${
        userInfo.pOrgId
      } AND CampaignId=${
        campaignId ? campaignId : ""
      } AND UPPER(ListName) LIKE '*${filterListName}*' &totalResults=true&orderBy=CreatedOn:desc&offset=0&limit=${
        paging.page * paging.size
      }`;
    }

    let url = encodeURI(envConfig.BASE_API + Services.CRM_LISTS + filter);
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
      moreHead: { rfv: 2 },
      cType: 4,
    };
    api.call(
      data,
      (response) => {
        if (response.status == 200) {
          let SelectedListId = listId;

          if (
            response &&
            response.data.items &&
            response.data.items.length > 0
          ) {
            // paging
            paging.total = Math.ceil(response.totalResults / paging.size);

            response.data.items.map((item, key) => {
              tempList.push(item);
            });

            if (!queryParam && paging.page === 1) {
              SelectedListId = tempList[0].ListId;
            }
            setHasMore(response.data.hasMore);
            setTotalRecords(response.totalResults);
            setListResponse(tempList);
            setPaging(paging);
            setListId(SelectedListId);
            setListName(tempList.length > 0 ? tempList[0].ListName : "");
            setEnquiryStatus("success");
          } else {
            if (!response.data.hasMore) {
              setListResponse(tempList);
              setMemberListResponse({
                items: [],
                totalResults: 0,
              });
              setEnquiryStatus("empty");
            }
          }
        }
      },
      (error) => {
        setEnquiryStatus("error");
      },
      (final) => {}
    );
  };

  const openForm = (type, module) => {
    setFormType(type);
    setIsForm(true);
    setModule(module);
    detailsCloseClick();
  };

  const changeFormType = (val, type, data, ids) => {
    if (data) {
      setListDetailsId(data.ListDetailId);
    } else {
      setListDetailsId(0);
    }
    setModule("enquiryMember");
    setIsForm(val);
    setFormType(type);
    if (!val) {
      getAllMarketingLists();
    }
  };

  const deleteSelectedItem = () => {
    detailsCloseClick();
    common.confirmDelete(true, (reponse) => {
      deleteEnquiryList(selectedEnquiry.ListId);
    });
  };

  const deleteEnquiryList = (listId) => {
    let url = envConfig.BASE_API + Services.CRM_LISTS + "/" + listId;
    let data = {
      url: url,
      type: "dynamic",
      method: "DELETE",
      auth: "token",
    };
    api.call(
      data,
      (response) => {
        //page reset
        paging.page = 1;
        setPaging(paging);
        getAllMarketingLists();
        common.snack("S", "Enquiry List deleted successfully.");
      },
      (error) => {
        common.snack("E", error);
      }
    );
  };

  const searchFilter = (e) => {
    paging.page = 1;
    setFilterListName(e.target.value);
    setPaging(paging);
    // setEnquiryStatus("process");
    // setTimeout(() => {
    //   getAllMarketingLists();
    // }, 5000);
  };

  const handleClickMList = (event, row) => {
    event.preventDefault();
    setListId(row.ListId);
    setListName(row.ListName);
    setReload(new Date());
  };

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight && hasMore) {
        getAllMarketingLists(paging.page + 1);
      }
    }
  };

  const [rightAnchorEl, setRightAnchorEl] = React.useState(null);
  const detailsMoreClick = (event, item, i) => {
    setRightAnchorEl(event.currentTarget);
    setSelectedEnquiry(item);
    setListId(item.ListId);
  };
  const detailsCloseClick = () => {
    setRightAnchorEl(null);
  };
  const rightMoreOpen = Boolean(rightAnchorEl);

  return (
    <Box className="template-box-border">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box className="crm-submenu-wrap">
          <Grid
            container
            spacing={2}
            alignItems="center"
            className="subheader-elements"
          >
            <Grid item>
              {isExpand === "detail" ? (
                <Typography
                  className="expand pointer"
                  onClick={() => handleExpanded("tab")}
                >
                  Expand{" "}
                  <img src={common.loadImg("expandkanban.svg")} alt="expand" />
                </Typography>
              ) : (
                <Typography
                  className="expand pointer"
                  onClick={() => handleExpanded("detail")}
                >
                  Collapse{" "}
                  <img
                    src={common.loadImg("expandkanban.svg")}
                    alt="Collapse"
                  />
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
      {!isForm && (
        <div className="row">
          <div className={isExpand === "detail" ? "col-md-12" : "col-md-4"}>
            <div className="enq-border-color">
              <div className="card-body p-0">
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    className="p-2"
                  >
                    <Grid item>
                      <h5 className="d-flex align-items-center title">
                        <label className="d-flex align-items-center location-detail-title">
                          Enquiry List{" "}
                        </label>
                      </h5>
                    </Grid>
                    {isExpand === "tab" && (
                      <Grid item>
                        <Box
                          className="flex"
                          alignItems="center"
                          onClick={() => openForm("add", "enquiry")}
                        >
                          <div className="text-none pointer">
                            <span className="mx-2 add-new">Add New</span>
                          </div>

                          <div className="add-new-button d-flex align-items-center justify-content-center">
                            <div className="text-none pointer">
                              <span className="d-flex align-items-center plus-icon">
                                +
                              </span>
                            </div>
                          </div>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Box>
                <Fragment>
                  <div className="d-flex bg-F2F9FD ">
                    <Box className="filter-search" width="100%">
                      <FormControl sx={{ width: "100%" }}>
                        <OutlinedInput
                          id="search"
                          value={filterListName}
                          placeholder={"Search"}
                          endAdornment={
                            <InputAdornment
                              position="end"
                              onClick={() => getAllMarketingLists()}
                            >
                              <img
                                src={common.loadImg("common-search.svg")}
                                alt="search"
                                className="crm-cp"
                              />
                            </InputAdornment>
                          }
                          aria-describedby="outlined-weight-helper-text"
                          onChange={(e) => searchFilter(e)}
                        />
                      </FormControl>
                    </Box>
                  </div>

                  <div
                    className="bg-white location-user-list"
                    onScroll={onScroll}
                    ref={listInnerRef}
                  >
                    {enquiryStatus == "success" && (
                      <Grid container direction="column">
                        {listResponse.length > 0 &&
                          listResponse.map((item, i) => {
                            return (
                              <Grid item key={i}>
                                <Box
                                  className={
                                    listId == item.ListId
                                      ? "pointer color-divider active-list"
                                      : "pointer color-divider"
                                  }
                                >
                                  <Grid container alignItems="center">
                                    <Grid
                                      item
                                      xs={9}
                                      className="enquiry-list-item"
                                    >
                                      <ul
                                        className="list-name-location mb-0"
                                        onClick={(e) =>
                                          handleClickMList(e, item)
                                        }
                                      >
                                        <li className="location-names-active">
                                          {item.ListName}
                                        </li>
                                        <li className="setting-card-content mt-1">
                                          {item.ListType ? (
                                            item.ListType
                                          ) : (
                                            <p className="empty-p"></p>
                                          )}
                                        </li>
                                      </ul>
                                    </Grid>
                                    <Grid item xs={3} mt={0.5}>
                                      <MoreHorizOutlinedIcon
                                        className="pointer"
                                        aria-describedby={"right-more-popover"}
                                        onClick={(e) =>
                                          detailsMoreClick(e, item, i)
                                        }
                                      />
                                      <Popover
                                        open={rightMoreOpen}
                                        anchorEl={rightAnchorEl}
                                        onClose={detailsCloseClick}
                                        disableScrollLock
                                        anchorOrigin={{
                                          vertical: "bottom",
                                          horizontal: "left",
                                        }}
                                        transformOrigin={{
                                          vertical: "top",
                                          horizontal: "right",
                                        }}
                                      >
                                        <Typography
                                          sx={{ p: 1 }}
                                          className="menu-item crm-cp"
                                          onClick={() =>
                                            openForm("edit", "enquiry")
                                          }
                                        >
                                          Edit
                                        </Typography>
                                        <Typography
                                          sx={{ p: 1 }}
                                          className="menu-item crm-cp"
                                          onClick={() => deleteSelectedItem()}
                                        >
                                          Delete
                                        </Typography>
                                      </Popover>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Grid>
                            );
                          })}
                      </Grid>
                    )}
                    <StatusBar status={enquiryStatus} />
                  </div>
                </Fragment>
              </div>
            </div>
          </div>
          {isExpand == "tab" && (
            <div className={isExpand === "detail" ? "col-md-12" : "col-md-8"}>
              <Grid item>
                <Box>
                  <div className="role-border permission-table">
                    {listId > 0 && (
                      <ListView
                        module={"enquiryMember"}
                        changeForm={(val, type, item) =>
                          changeFormType(val, type, item)
                        }
                        query={{ ListId: listId }}
                        sort="CreatedOn:desc"
                        reload={reload}
                        type={"subMenu"}
                        pModule={mod}
                        data="LeadConverted,FirstName,LastName,CompanyName,Email"
                        className={"table-scroll-position-collapse"}
                        isExpand={isExpand}
                        accImport={true}
                        id={id}
                      />
                    )}
                  </div>
                </Box>
              </Grid>
            </div>
          )}
        </div>
      )}
      {isForm && (
        <DynamicForm
          module={module}
          data={
            module == "enquiry"
              ? {
                  // value1: formType == "edit" && selectedEnquiry && selectedEnquiry > 0 ? selectedEnquiry.ListId : "",
                  value1:
                    formType == "edit" &&
                    selectedEnquiry &&
                    selectedEnquiry.ListId > 0
                      ? selectedEnquiry.ListId
                      : "",
                }
              : {
                  value1:
                    formType == "edit" && listDetailsId > 0
                      ? listDetailsId
                      : "",
                  value2: listId > 0 ? listId.toString() : "",
                }
          }
          default={{
            CampaignId: {
              label: campaignData.CampaignName,
              value: campaignId,
            },
          }}
          // disabled={{ LeadConverted: true }}
          disabled={{ CampaignId: true }}
          closeForm={(val, type, data, id) =>
            changeFormType(val, type, data, id)
          }
        />
      )}
    </Box>
  );
});
export { EnquiryList };
