import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
  Checkbox,
  FormControlLabel,
  FormControl,
  TextField,
} from "@mui/material";
import AmlHashForm from "aml-hash-form";

import { common, envConfig, api } from "helpers";
import FieldLookup from "elements/FieldLookup";
import StatusBar from "elements/StatusBar";
import { ITEM_MATSER } from "helpers/service";

const SAVE_SUCCESS = "Product details saved successfully.";
const SAVE_FAILED = "Product save failed.";
const UPDATE_FAILED = "Product update failed.";
const BACK_URL = "/setting/vendor/product";

function AddProductservice() {
  // render
  const { pId } = useParams();
  const ProductId = pId;
  const userData = common.userInfo();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(pId ? "process" : "success");

  const formFields = {
    ItemNumber: { validate: ["req#Product Number is required"] },
    ItemName: { validate: ["req#Product Name is required"] },
    Description: { validate: ["req#Description is required"] },
    ItemType: { validate: [] },
    PrimaryUom: { validate: [] },
    SecondaryUom: { validate: [] },
    Active: { value: false },
  };
  const {
    fields,
    errors,
    setValue,
    handleChange,
    handleSubmit,
    setMultiValue,
  } = AmlHashForm(formFields);

  useEffect(() => {
    if (ProductId) {
      getProductInfo();
    }
  }, []);

  const getProductInfo = () => {
    let url = `${envConfig.BASE_API}${ITEM_MATSER}/${ProductId}`;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };

    api.call(
      data,
      (response) => {
        if (response.status === 200) {
          const result = response.data;
          if (result) {
            setMultiValue({
              ItemNumber: result.ItemNumber || "",
              ItemName: result.ItemName || "",
              Description: result.Description || "",
              ItemType: result.ItemType
                ? {
                    label: result.ItemType,
                    value: result.ItemType,
                  }
                : null,
              PrimaryUom: result.PrimaryUom
                ? {
                    label: result.PrimaryUom,
                    value: result.PrimaryUom,
                  }
                : null,
              SecondaryUom: result.SecondaryUom
                ? {
                    label: result.SecondaryUom,
                    value: result.SecondaryUom,
                  }
                : null,
              Active: result.Active === "Y",
            });
          }
        }
        setStatus("success");
      },
      (error) => {
        setStatus("error");
      },
      (final) => {}
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let isValid = handleSubmit();

    if (isValid) {
      save();
    }
  };
  const save = () => {
    setIsLoading(true);

    let params = {
      ItemNumber: fields.ItemNumber,
      ItemName: fields.ItemName,
      Description: fields.Description,
      ItemType: fields.ItemType?.value,
      PrimaryUom: fields.PrimaryUom?.value,
      SecondaryUom: fields.SecondaryUom?.value,
      Active: fields.Active ? "Y" : "N",
      TenantId: userData.pTenantId,
      OrgId: userData.pOrgId,
    };

    let url = `${envConfig.BASE_API}${ITEM_MATSER}`;
    if (ProductId) {
      url = `${envConfig.BASE_API}${ITEM_MATSER}/${ProductId}`;
    }
    let data = {
      url: url,
      type: "dynamic",
      method: ProductId ? "PATCH" : "POST",
      body: JSON.stringify(params),
      auth: "token",
      cType: 4,
    };
    api.call(
      data,
      (res) => {
        if (res.data) {
          if (ProductId) {
            common.snack("S", SAVE_SUCCESS);
          } else {
            common.snack("S", SAVE_SUCCESS);
          }
          navigate(BACK_URL);
        } else if (
          res?.status === 500 ||
          res?.status === 400 ||
          res?.status === 404
        ) {
          const error = res?.data?.error;
          if (ProductId) {
            common.snack("E", error || UPDATE_FAILED);
          } else {
            common.snack("E", error || SAVE_FAILED);
          }
        }
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
        common.snack("E", SAVE_FAILED);
      },
      (final) => {
        setIsLoading(false);
      }
    );
  };
  return (
    <div style={{ padding: "20px" }}>
      <Paper
        sx={{ width: "100%", mb: 2 }}
        className={`container responsive-table p-0`}
      >
        <Box>
          <Paper variant="contained dynamic-form-wrap" square>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item display="flex" alignItems="center" xs={5} className="detail-head">
                <Grid py={1} px={1} className="icon-back icon-buton">
                  <Link to={BACK_URL}>
                    <img
                      src={common.loadImg("backArrowKanban.svg")}
                      alt="back"
                    />
                  </Link>
                </Grid>
                <Grid className="df-main-head">
                  <Typography className="detail-title">
                    {`${ProductId ? "Edit" : "Add"}`} Product
                  </Typography>
                </Grid>
              </Grid>
              <Grid item className="safari-style">
                <Box px={2} className="safari-style">
                  <Grid container alignItems="center" spacing={2} className="safari-style">
                    <Grid item>
                      <Box className="verical-divider">
                        <Divider orientation="vertical" />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box>
                        {status == "success" && (
                          <Button
                            className="save-note"
                            fullWidth
                            disabled={isLoading}
                            onClick={(e) => onSubmit(e)}
                          >
                            {isLoading ? "Processing..." : "Save Changes"}
                          </Button>
                        )}
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box className="vertical-divider">
                        <Divider orientation="vertical" />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box className="revert-close">
                        <Button className="save-note pe-0">
                          <Link to={BACK_URL}>
                            Revert and Close
                            <img
                              src={common.loadImg("closeKanban.svg")}
                              alt="close"
                            />
                          </Link>
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Box>
              <Divider />
            </Box>
            {status !== "success" ? (
              <StatusBar status={status} />
            ) : (
              <Box mt={2} className="camp-edit meet-table">
                <Row className="m-0">
                  <Col
                    xs={6}
                    className="body-th common-input-style mandatoryclass"
                  >
                    <Box className="filled-box">
                      <TextField
                        id="filled-basic"
                        label="Product Number"
                        variant="filled"
                        name="ItemNumber"
                        value={fields.ItemNumber}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      {errors.ItemNumber ? (
                        <p className="error-txt">{errors.ItemNumber}</p>
                      ) : null}
                    </Box>
                  </Col>
                  <Col
                    xs={6}
                    className="body-th common-input-style mandatoryclass"
                  >
                    <Box className="filled-box">
                      <TextField
                        id="filled-basic"
                        label="Product Name"
                        variant="filled"
                        name="ItemName"
                        value={fields.ItemName}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      {errors.ItemName ? (
                        <p className="error-txt">{errors.ItemName}</p>
                      ) : null}
                    </Box>
                  </Col>
                  <Col
                    xs={6}
                    className="body-th common-input-style mandatoryclass"
                  >
                    <Box className="filled-box">
                      <TextField
                        id="filled-basic"
                        label="Description"
                        variant="filled"
                        name="Description"
                        value={fields.Description}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      {errors.Description ? (
                        <p className="error-txt">{errors.Description}</p>
                      ) : null}
                    </Box>
                  </Col>
                  <Col xs={6} className="body-th common-input-style">
                    <Box className="">
                      <FormControl
                        fullWidth
                        className="select-float-label common-select-border"
                      >
                        <FieldLookup
                          isRender={true}
                          name="ItemType"
                          type="CHOLA_ITEM_GROUP"
                          lookup={true}
                          value={fields.ItemType}
                          onSelect={(data) => setValue("ItemType", data)}
                          placeholder="Product Type"
                        />
                      </FormControl>
                    </Box>
                  </Col>
                  <Col xs={6} className="body-th common-input-style">
                    <Box className="">
                      <FormControl
                        fullWidth
                        className="select-float-label common-select-border"
                      >
                        <FieldLookup
                          isRender={true}
                          name="PrimaryUom"
                          type="UOM"
                          lookup={true}
                          value={fields.PrimaryUom}
                          onSelect={(data) => setValue("PrimaryUom", data)}
                          placeholder="Primary UOM"
                        />
                      </FormControl>
                    </Box>
                  </Col>
                  <Col xs={6} className="body-th common-input-style">
                    <Box className="">
                      <FormControl
                        fullWidth
                        className="select-float-label common-select-border"
                      >
                        <FieldLookup
                          isRender={true}
                          name="SecondaryUom"
                          type="UOM"
                          lookup={true}
                          value={fields.SecondaryUom}
                          onSelect={(data) => setValue("SecondaryUom", data)}
                          placeholder="Secondary UOM"
                        />
                      </FormControl>
                    </Box>
                  </Col>
                  <Col xs={6} className="body-th common-input-style">
                    <FormControlLabel
                      className="dynamic-checkbox ms-0 checkbox-space"
                      control={<Checkbox />}
                      label="Active"
                      checked={fields.Active}
                      labelPlacement="end"
                      name="Active"
                      value=""
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </Col>
                </Row>
              </Box>
            )}
          </Paper>
        </Box>
      </Paper>
    </div>
  );
}

export default AddProductservice;
