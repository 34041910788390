import React from "react";
import {
    Navigate,
    Outlet,
  } from 'react-router-dom';


//check Auth
function checkAuth(auth) {
    let token = localStorage.getItem("userToken") ? localStorage.getItem("userToken") : null;
    let redirect = false;
    if (auth === true && token === null) {
      setTimeout(() => {
        localStorage.clear();
      }, 1000);
      redirect = true;
    } else if (!auth && token != null) {
      redirect = true;
    }
    return redirect;
}


//ProtectedRoute
export const ProtectedRoute = ({ auth, redirectPath = '/signin' }) => {
    if (checkAuth(auth)) {
      return <Navigate to={redirectPath} replace />;
    }
    return <Outlet />;
};