import React, { useState, useEffect, useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import Select, { components } from "react-select";
import { common, envConfig, api } from "helpers";
import AmlHashForm from "aml-hash-form";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
  FormControl,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { CRM_TERRITORIES, LOOKUPS_LOV } from "helpers/service";
import {
  Link,
  useParams,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import FieldSelect from "elements/FieldSelect";
import StatusBar from "elements/StatusBar";
import AccessDenied from "elements/AccessDenied";

let ModNameChange = envConfig.ModNameChange;
const DEPARTMENT_UPDATE_SUCCESS_MESSAGE = ModNameChange
  ? "Role updated successfully."
  : "Department updated successfully.";
const DEPARTMENT_SAVE_SUCCESS_MESSAGE = ModNameChange
  ? "Role saved successfully."
  : "Department saved successfully.";
const DEPARTMENT_UPDATE_FAILED = ModNameChange
  ? "Role update failed."
  : "Department update failed.";
const DEPARTMENT_SAVE_FAILED = ModNameChange
  ? "Role save failed."
  : "Department save failed.";
const SAME_DEPARTMENT_VALIDATION = ModNameChange
  ? "Can't select same role."
  : "Can't select same department.";

const COMMON_LIST_OFFSET = 0;
const COMMON_LIST_LIMIT = 1000;

const departmentStatus = [
  { value: "Select", label: "Select" },
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

const DEPARTMENT_EDIT = "edit";

function AddDepartment() {
  const location = useLocation();
  const useParamsCopy = useParams;
  const { TerritoryId } = useParamsCopy();
  const [permission] = useOutletContext();
  const navigate = useNavigate();

  const userData = common.authInfo();
  const userInfo = common.userInfo();
  const isDepartmentEdit = location.pathname.includes(DEPARTMENT_EDIT);

  // Local state
  const [selectedParent, setSelectedParent] = useState(null);
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [departmentTypesList, setDepartmentTypesList] = useState([]);
  const [TerritoryType, setTerritoryType] = useState({});
  const [TerritoryStatus, setTerritoryStatus] = useState({});
  const [ownerName, setOwnerName] = useState("");

  // Form field
  const formFields = {
    TerritoryCode: {
      validate: ModNameChange
        ? ["req#Role Code is required"]
        : ["req#Territory Code is required"],
    },
    TerritoryName: {
      validate: ModNameChange
        ? ["req#Role Name is required"]
        : ["req#Territory Name is required"],
    },
    Description: { validate: [] },
  };

  // Main Form
  const {
    fields,
    errors,
    handleChange,
    handleSubmit,
    setValue,
    setMultiValue,
  } = AmlHashForm(formFields);

  const { ValueContainer, Placeholder } = components;

  const loadPageData = useCallback(() => {
    setTerritoryStatus({ value: "Select", label: "Select" });

    if (isDepartmentEdit) {
      loadDepartmentDetails();
    } else {
      setOwnerName(`${userData.FirstName || ""} ${userData.LastName || ""}`);
      loadDepartmentTypesList();
      loadParentDepartment(TerritoryId);
    }
  }, [TerritoryId]);

  useEffect(() => {
    if (TerritoryId) {
      setIsPageLoading(true);
      loadPageData();
    }
  }, [TerritoryId, loadPageData]);

  const loadDepartmentDetails = () => {
    const url = `${envConfig.BASE_API}${CRM_TERRITORIES}/${TerritoryId}`;

    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };

    api.call(
      data,
      (response) => {
        if (response) {
          setMultiValue({
            TerritoryCode: response?.data?.TerritoryCode,
            TerritoryName: response?.data?.TerritoryName,
            Description: response?.data?.Description,
          });
          loadDepartmentTypesList(response?.data?.TerritoryType);
          loadParentDepartment(response?.data?.ParentId);
          setOwnerName(response.data?.OwnerName || "");

          if (response?.data?.TerritoryStatus === "Y") {
            setTerritoryStatus({ value: "Yes", label: "Yes" });
          } else if (response?.data?.TerritoryStatus === "N") {
            setTerritoryStatus({ value: "No", label: "No" });
          }
        }
        setIsPageLoading(false);
      },
      (error) => {
        console.log("error", error);
      },
      (final) => {
        setIsPageLoading(false);
      }
    );
  };

  const loadDepartmentTypesList = (id) => {
    const url = `${envConfig.BASE_API}${LOOKUPS_LOV}?finder=FindLookupVals;pLookupCode=TERRITORY_TYPE&totalResults=true&limit=${COMMON_LIST_LIMIT}&offset=${COMMON_LIST_OFFSET}`;

    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };

    api.call(
      data,
      (response) => {
        if (response) {
          const typesList = [];
          response?.data?.items?.forEach((item) => {
            if (item.Code === id) {
              setTerritoryType({
                value: item.Code,
                label: item.Name,
              });
            }
            typesList.push({
              value: item.Code,
              label: item.Name,
            });
          });
          setDepartmentTypesList(typesList);
        }
        setIsPageLoading(false);
      },
      (error) => {
        setDepartmentTypesList([]);
      },
      (final) => {
        setIsPageLoading(false);
      }
    );
  };

  const loadParentDepartment = (id) => {
    let url = `${envConfig.BASE_API}${CRM_TERRITORIES}/${id}`;

    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
      moreHead: { rfv: 2 },
      cType: 4,
    };

    api.call(
      data,
      (response) => {
        if (response) {
          const result = response.data;
          setSelectedParent({
            value: result.TerritoryId.toString(),
            label: result.TerritoryName,
          });
        }
        setIsPageLoading(false);
      },
      (error) => {
        setIsLoading(false);
      },
      (final) => {
        setIsLoading(false);
        setIsPageLoading(false);
      }
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let isValid = handleSubmit();
    const customErrorArr = Object.entries(error).filter(([key, value]) => {
      return value !== "";
    });

    if (!TerritoryStatus?.value || !TerritoryType?.value) {
      isValid = false;
      setError({
        TerritoryStatus: !TerritoryStatus?.value
          ? ModNameChange
            ? "Role Status is required"
            : "Territory Status is required"
          : "",
        TerritoryType: !TerritoryType?.value
          ? ModNameChange
            ? "Role Type is required"
            : "Territory Type is required"
          : "",
      });
    }

    if (!selectedParent) {
      isValid = false;
      setError({
        ...error,
        selectedParent: ModNameChange
          ? "Parent Role is required"
          : "Parent Department is required",
      });
    }

    if (isValid && customErrorArr?.length === 0) {
      registerDepartment();
    }
  };

  const registerDepartment = () => {
    setIsLoading(true);

    let params = {
      TerritoryCode: fields.TerritoryCode,
      TerritoryName: fields.TerritoryName,
      TerritoryType: TerritoryType?.value,
      Description: fields.Description,
      TenantId: parseInt(userData.TenantId),
      OrgId: parseInt(userInfo.pOrgId),
      ParentId: parseInt(selectedParent?.value),
      TerritoryStatus:
        (TerritoryStatus?.value).toLowerCase() === "yes" ? "Y" : "N",
      OwnerId: parseInt(userData?.EmpId),
    };

    if (isDepartmentEdit) {
      params.TerritoryId = parseInt(TerritoryId);
    }

    let saveLocationUrl = `${envConfig.BASE_API}${CRM_TERRITORIES}`;
    if (isDepartmentEdit) {
      saveLocationUrl = `${saveLocationUrl}/${TerritoryId}`;
    }
    let data = {
      url: saveLocationUrl,
      type: "dynamic",
      method: isDepartmentEdit ? "PATCH" : "POST",
      body: JSON.stringify(params),
      auth: "token",
      moreHead: { rfv: 2 },
      cType: 4,
    };

    api.call(
      data,
      (res) => {
        if (res.status === 200 || res.status === 201) {
          if (isDepartmentEdit) {
            common.snack("S", DEPARTMENT_UPDATE_SUCCESS_MESSAGE);
          } else {
            common.snack("S", DEPARTMENT_SAVE_SUCCESS_MESSAGE);
          }
          navigate("/setting/hrms/department");
        } else if (
          res?.status === 500 ||
          res?.status === 400 ||
          res?.status === 404
        ) {
          if (isDepartmentEdit) {
            common.snack("E", DEPARTMENT_UPDATE_FAILED);
          } else {
            common.snack("E", DEPARTMENT_SAVE_FAILED);
          }
        }
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
        common.snack("E", DEPARTMENT_SAVE_FAILED);
      },
      (final) => {
        setIsLoading(false);
      }
    );
  };

  const onHandleSelect = (data) => {
    if (
      !isDepartmentEdit ||
      (isDepartmentEdit && data?.value !== TerritoryId)
    ) {
      handleSelect("selectedParent");
      setSelectedParent(data);
    } else {
      common.snack("E", SAME_DEPARTMENT_VALIDATION);
    }
  };

  const handleSelect = (key) => {
    setError({ ...error, [key]: "" });
  };

  const toInputUppercase = (e) => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

  return (
    <div style={{ padding: "20px" }}>
      {common.givenPermission(
        permission,
        "hrms:department",
        "basic",
        TerritoryId
      ) ? (
        <AccessDenied />
      ) : (
        <React.Fragment>
          <Paper
            sx={{ width: "100%", mb: 2 }}
            className={`container responsive-table p-0`}
          >
            <Box>
              <Paper variant="contained dynamic-form-wrap" square>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item className="detail-head">
                    <Grid container alignItems="center" spacing={2} className="detail-head">
                      <Grid item>
                        <Box py={1} px={1} className="icon-back icon-buton">
                          <IconButton>
                            <Link to="/setting/hrms/department">
                              <img
                                src={common.loadImg("backArrowKanban.svg")}
                                alt="back"
                              />
                            </Link>
                          </IconButton>
                        </Box>
                      </Grid>
                      <Grid item className="df-main-head">
                        <Typography className="detail-title">
                          {`${isDepartmentEdit ? "Edit" : "Add"} `}
                          {ModNameChange ? "Role" : "Department"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item className="safari-style">
                    <Box px={2} className="safari-style">
                      <Grid container alignItems="center" spacing={2} className="safari-style">
                        <Grid item>
                          <Box className="verical-divider">
                            <Divider orientation="vertical" />
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box>
                            <Button
                              className="save-note"
                              type="submit"
                              disabled={isLoading}
                              onClick={(e) => onSubmit(e)}
                            >
                              {isLoading ? "Processing..." : "Save Changes"}
                            </Button>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box className="vertical-divider">
                            <Divider orientation="vertical" />
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box className="revert-close">
                            <Button className="save-note pe-0">
                              <Link to="/setting/hrms/department">
                                Revert and Close
                                <img
                                  src={common.loadImg("closeKanban.svg")}
                                  alt="close"
                                />
                              </Link>
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
                <Box>
                  <Divider />
                </Box>
                <Box p={2} px={5} className="form-wrap">
                  {/* <Typography className="info-title dynamic-form-title mt-3">
                       DEPARTMENT INFORMATION
                     </Typography>
                     <Box mt={3}>
                       <Divider />
                     </Box> */}
                  {isPageLoading ? (
                    <StatusBar status="process" />
                  ) : (
                    <Box mt={2} className="camp-edit meet-table">
                      <Row className="m-0">
                        <Col
                          xs={6}
                          className="body-th common-input-style mandatoryclass"
                        >
                          <Box className="filled-box">
                            <TextField
                              id="filled-basic"
                              label={
                                ModNameChange ? "Role Code" : "Department Code"
                              }
                              variant="filled"
                              name="TerritoryCode"
                              value={fields.TerritoryCode}
                              onInput={toInputUppercase}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            />
                            {errors.TerritoryCode ? (
                              <p className="error-txt">
                                {errors.TerritoryCode}
                              </p>
                            ) : null}
                          </Box>
                        </Col>
                        <Col
                          xs={6}
                          className="body-th common-input-style mandatoryclass"
                        >
                          <Box className="filled-box">
                            <TextField
                              id="filled-basic"
                              label={
                                ModNameChange ? "Role Name" : "Department Name"
                              }
                              variant="filled"
                              name="TerritoryName"
                              value={fields.TerritoryName}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            />
                            {errors.TerritoryName ? (
                              <p className="error-txt">
                                {errors.TerritoryName}
                              </p>
                            ) : null}
                          </Box>
                        </Col>

                        <Col
                          xs={6}
                          className="body-th common-input-style  common-padding-zero-select mandatoryclass"
                        >
                          <Box className="">
                            <FormControl
                              fullWidth
                              className="select-float-label"
                            >
                              <Select
                                className="text-box custom-react-select"
                                labelId="select"
                                id="select"
                                label={
                                  ModNameChange
                                    ? "Role Type"
                                    : "Department Type"
                                }
                                placeholder={
                                  ModNameChange
                                    ? "Role Type"
                                    : "Department Type"
                                }
                                isClearable={true}
                                options={departmentTypesList}
                                name="TerritoryType"
                                value={TerritoryType}
                                onChange={(data) => {
                                  handleSelect("TerritoryType");
                                  setTerritoryType(data);
                                }}
                                components={{
                                  ValueContainer: CustomValueContainer,
                                }}
                                styles={{
                                  container: (provided, state) => ({
                                    ...provided,
                                    marginTop: 0,
                                  }),
                                  valueContainer: (provided, state) => ({
                                    ...provided,
                                    overflow: "visible",
                                  }),
                                  placeholder: (provided, state) => ({
                                    ...provided,
                                    position: "absolute",
                                    top:
                                      state.hasValue ||
                                      state.selectProps.inputValue
                                        ? -15
                                        : "10%",
                                    transition: "top 0.1s, font-size 0.1s",
                                    fontSize:
                                      state.hasValue ||
                                      state.selectProps.inputValue
                                        ? 11
                                        : 13,
                                    color: "#9daabb",
                                  }),
                                }}
                              ></Select>
                            </FormControl>
                            {error.TerritoryType ? (
                              <p className="error-txt">{error.TerritoryType}</p>
                            ) : null}
                          </Box>
                        </Col>
                        <Col
                          xs={6}
                          className="body-th common-input-style  common-padding-zero-select mandatoryclass"
                        >
                          <Box className="">
                            <FormControl
                              fullWidth
                              className="select-float-label"
                            >
                              <Select
                                className="text-box custom-react-select"
                                labelId="select"
                                id="select"
                                label="Department Status"
                                placeholder={
                                  ModNameChange
                                    ? "Role Status"
                                    : "Department Status"
                                }
                                isClearable={true}
                                options={departmentStatus}
                                name="TerritoryStatus"
                                value={TerritoryStatus}
                                onChange={(data) => {
                                  handleSelect("TerritoryStatus");
                                  setTerritoryStatus(data);
                                }}
                                menuPortalTarget={document.body}
                                components={{
                                  ValueContainer: CustomValueContainer,
                                }}
                                styles={{
                                  container: (provided, state) => ({
                                    ...provided,
                                    marginTop: 0,
                                  }),
                                  valueContainer: (provided, state) => ({
                                    ...provided,
                                    overflow: "visible",
                                  }),
                                  placeholder: (provided, state) => ({
                                    ...provided,
                                    position: "absolute",
                                    top:
                                      state.hasValue ||
                                      state.selectProps.inputValue
                                        ? -15
                                        : "10%",
                                    transition: "top 0.1s, font-size 0.1s",
                                    fontSize:
                                      state.hasValue ||
                                      state.selectProps.inputValue
                                        ? 11
                                        : 13,
                                    color: "#9daabb",
                                  }),
                                }}
                              ></Select>
                            </FormControl>
                            {error.TerritoryStatus ? (
                              <p className="error-txt">
                                {error.TerritoryStatus}
                              </p>
                            ) : null}
                          </Box>
                        </Col>
                        <Col
                          xs={6}
                          className="body-th common-input-style common-select-border common-padding-zero mandatoryclass"
                        >
                          {/*<Box className="filled-box">Parent Department*</Box> */}
                          <FieldSelect
                            api="CRM_TERRITORIES"
                            get="TerritoryName"
                            set="TerritoryId"
                            value={selectedParent}
                            isMulti={false}
                            isRender={true}
                            onSelect={(data) => onHandleSelect(data)}
                            placeholder={
                              ModNameChange
                                ? "Parent Role"
                                : "Parent Department"
                            }
                          />
                          {error.selectedParent ? (
                            <p className="error-txt">{error.selectedParent}</p>
                          ) : null}
                        </Col>
                        <Col xs={6} className="body-th common-input-style">
                          <Box className="filled-box">
                            <TextField
                              disabled={false}
                              id="filled-basic"
                              label="Owner"
                              variant="filled"
                              className="text-disable"
                              value={ownerName}
                            />
                          </Box>
                        </Col>
                        <Col xs={6} className="body-th common-input-style">
                          <Box>
                            <div className="form-floating">
                              <textarea
                                className="form-control textarea"
                                id="floatingTextarea"
                                name="Description"
                                value={
                                  fields.Description == null
                                    ? ""
                                    : fields.Description
                                }
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                              />
                              <label
                                htmlFor="floatingTextarea"
                                className="floating-label"
                              >
                                Description
                              </label>
                            </div>
                          </Box>
                        </Col>
                      </Row>
                    </Box>
                  )}
                </Box>
              </Paper>
            </Box>
          </Paper>
        </React.Fragment>
      )}
    </div>
  );
}

export default AddDepartment;
