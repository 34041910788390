import React, { useState, useEffect, forwardRef, Component } from "react";
import { Form } from "react-bootstrap";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
// api
import { common, tools, envConfig, api } from "helpers";
import * as Services from "helpers/service";
// json
import lookupList from "assets/files/lookupList.json";

const ContactSelect = forwardRef((props, ref) => {
  const authInfo = common.authInfo();
  const { field, fieldIndex, data } = props;
  const lookup = lookupList[field.attr.lookup];
  const [mount, setMount] = useState(false);
  const [isLoading, setIsLoading] = useState(props.isRender);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [isRender, setIsRender] = useState(props.isRender);
  const [resultList, setResultList] = useState([]);
  const [userInfo] = useState(common.userInfo());
  const [hasMore, setHasMore] = useState(false);
  const [limit] = useState(15);
  const [offset, setOffset] = useState(0);
  var delayTimer;
  let empIdList = [];
  //permission from lead and deal to control access for other department, responsible person
  const userPermissions = props.userPermissions
    ? props.userPermissions
    : { r: "1", a: "1", u: "1", d: "1", i: "1", e: "1", a: "1" };

  let isAdmin = authInfo["roleId"] === 0;
  let deptEmployess = authInfo["employeeList"];
  if (!isAdmin && ["lead", "deal"].includes(props.module)) {
    if (props.recordId == "") {
      if (userPermissions.a == "3") {
        empIdList.push(authInfo.EmpId);
      } else if (userPermissions.a == "4") {
        empIdList.push(...deptEmployess.active);
      } else if (userPermissions.a == "5") {
        empIdList.push(...deptEmployess.active); //ToDo cross check
        empIdList.push(...deptEmployess.sub);
      }
    } else {
      if (userPermissions.u == "3") {
        empIdList.push(authInfo.EmpId);
      } else if (userPermissions.u == "4") {
        empIdList.push(...deptEmployess.active);
      } else if (userPermissions.u == "5") {
        empIdList.push(...deptEmployess.active); //ToDo cross check
        empIdList.push(...deptEmployess.sub);
      }
    }
  }

  // effect
  useEffect(() => {
    if (isRender) {
      if (checkDependent()) {
        loadData();
      } else {
        setMount(true);
        setResultList([]);
        setIsLoading(false);
      }
    }
  }, [isRender]);

  useEffect(() => {
    if (mount) {
      loadData();
    }
  }, [offset]);

  useEffect(() => {
    let dependent = field.attr.dependent;
    if (mount && fieldIndex !== null && dependent !== null) {
      let activeField = data.fields[fieldIndex];
      let isExist = activeField.key in dependent;
      if (isExist) {
        let value = activeField.value;
        if (typeof value == "object") {
          value = value.value;
        }
        onChange("");
        setOffset(0);
        setResultList([]);
        if (value !== "") {
          setIsLoading(true);
          loadData();
        }
      }
    }
  }, [field]);

  // support
  const checkDependent = () => {
    let dependent = field.attr.dependent;
    let allow = true;
    if (dependent !== null) {
      Object.keys(dependent).map((item) => {
        let dependField = data.fields.filter((fItem) => fItem.key === item);
        let value = dependField[0].value;
        if (typeof value == "object") {
          value = value.value;
        }
        if (value === "") {
          allow = false;
        }
      });
    }
    return allow;
  };

  const dependentData = () => {
    let dependent = field.attr.dependent;
    let result = "";
    if (dependent !== null) {
      Object.keys(dependent).map((item) => {
        let dependField = data.fields.filter((fItem) => fItem.key === item);
        let value = dependField[0].value;
        if (typeof value == "object") {
          value = value.value;
        }
        if (value !== "") {
          result += ` AND ${dependent[item]}=${value}`;
        }
      });
    }
    return result;
  };

  const getUrl = () => {
    let url = `${envConfig.BASE_API}${Services[lookup.name]}`;
    let fields = `?fields=${lookup.get},${lookup.set}`;
    let trasaction = `&q=${
      lookup.name == "TRANSACTION_STATUS"
        ? `TransactionTypeId=${common.getTransactionTypeId(
            envConfig[field.attr.data1]
          )} AND Active='Y' AND OrgId=${userInfo.pOrgId} AND `
        : ""
    }`;
    let vTenant = `${
      lookup.name == "CRM_EMPLOYEES" ? "TenantId" : "TenantId"
    }=${userInfo.pTenantId}`;

    let vEmp = `${
      lookup.name == "CRM_EMPLOYEES"
        ? empIdList.length > 0
          ? " AND WorkingStatus='ACTIVE' AND EmployeeId IN (" +
            empIdList.join(",") +
            ")"
          : " AND WorkingStatus='ACTIVE'"
        : ""
    }`;

    let search = ` AND UPPER(${lookup.get}) LIKE '*{SEARCH}*'`;
    let orderBylimit = `&orderBy=${lookup.get}:asc&limit=${limit}&offset=${
      offset * limit
    }&totalResults=true`;
    let query = dependentData();
    return (
      url + fields + trasaction + vTenant + vEmp + search + query + orderBylimit
    );
  };

  // api
  const loadData = () => {
    let url = getUrl().replace("{SEARCH}", "");
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
      moreHead: { rfv: 2 },
      cType: 4,
    };
    api.call(
      data,
      (response) => {
        if (response) {
          let data = common.getOptionsOnlyData(
            response.data.items,
            lookup.get,
            lookup.set,
            true
          );
          if (offset == 0) {
            setResultList(data);
          } else {
            setResultList((oldData) => {
              return [...new Set([...oldData, ...data])];
            });
          }
          setMount(true);
          setIsLoadingMore(false);
          setHasMore(response.data.hasMore);
          if (!props.isRender) {
            setIsMenuOpen(true);
            setIsFocus(true);
          }
        }
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  // load
  const loadOptions = async (inputValue) => {
    var url = getUrl().replace("{SEARCH}", inputValue);
    let result = await new Promise((resolve, reject) => {
      clearTimeout(delayTimer);
      delayTimer = setTimeout(() => {
        let data = {
          url: url,
          type: "dynamic",
          method: "GET",
          auth: "token",
          moreHead: { rfv: 2 },
          cType: 4,
        };
        api.call(
          data,
          (response) => {
            if (response) {
              let data = common.getOptionsOnlyData(
                response.data.items,
                lookup.get,
                lookup.set,
                true
              );
              if (data.length == 0) {
                data.push({
                  label: 'create "' + inputValue + '"',
                  value: inputValue,
                  disabled: false,
                });
              }
              resolve(data);
            } else {
              resolve([]);
            }
          },
          (error) => {
            resolve([]);
          },
          (final) => {}
        );
      }, 1000);
    });
    return result;
  };

  // handler
  const onChange = (value) => {
    if (value && value.label.match("create ")) {
      value.label = value.label.substring(8, value.label.length - 1);
    }
    props.onSelect(value, props.name);
  };

  const onFocus = () => {
    if (!isRender) {
      setIsLoading(true);
      setIsRender(true);
    }
  };

  const loadMore = () => {
    if (hasMore && !isLoadingMore) {
      setIsLoadingMore(true);
      setOffset((prev) => ++prev);
    }
  };

  // Floating React select
  const { ValueContainer, Placeholder } = components;

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

  return isLoading ? (
    <Form.Control
      type="text"
      defaultValue={"Loading..."}
      readOnly={true}
      className="loading-text-form-control"
    />
  ) : (
    <AsyncSelect
      ref={ref}
      isMulti={props.isMulti}
      isClearable={true}
      value={field.value}
      defaultOptions={resultList}
      loadOptions={loadOptions}
      onChange={onChange}
      onFocus={onFocus}
      menuIsOpen={isMenuOpen}
      onMenuOpen={() => setIsMenuOpen(true)}
      onMenuClose={() => setIsMenuOpen(false)}
      autoFocus={isFocus}
      onMenuScrollToBottom={() => loadMore()}
      isLoading={isLoadingMore}
      isDisabled={!checkDependent() || props.disabled}
      menuPortalTarget={document.body}
      className="text-box custom-react-select common-padding-zero-select"
      placeholder={field.label}
      // placeholder="Status"
      components={{
        ValueContainer: CustomValueContainer,
      }}
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        container: (provided, state) => ({
          ...provided,
          marginTop: 0,
        }),
        valueContainer: (provided, state) => ({
          ...provided,
          overflow: "visible",
        }),
        placeholder: (provided, state) => ({
          ...provided,
          position: "absolute",
          top: state.hasValue || state.selectProps.inputValue ? -15 : "10%",
          transition: "top 0.1s, font-size 0.1s",
          fontSize: state.hasValue || state.selectProps.inputValue ? 11 : 13,
          color: "#9daabb",
        }),
      }}
    />
  );
});

export default ContactSelect;
