import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import React, { memo } from "react";

const CrmGeneralView = memo(() => {
  return (
    <div>
      <Paper variant="contained" square>
        {[1, 2].map((_, i) => {
          return (
            <Box pb={1}>
              <Box p={3}>
                <Typography className="camp-sub-title">
                  Campaign Information
                </Typography>
              </Box>
              <Box>
                <Divider />
              </Box>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Box p={3}>
                    <Typography className="camp-sub-name">
                      Campaign Name
                    </Typography>
                    <Typography className="camp-sub-des mt-2">
                      Facebook Campaign 2021
                    </Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Divider orientation="vertical" />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                  <Box p={3}>
                    <Typography className="camp-sub-name">
                      Campaign Status
                    </Typography>
                    <Typography className="camp-sub-des mt-2 green-bg">
                      New
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Box>
                <Divider />
              </Box>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Box p={3}>
                    <Typography className="camp-sub-name">
                      Campaign Channel
                    </Typography>
                    <Typography className="camp-sub-des mt-2">
                      Facebook
                    </Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Divider orientation="vertical" />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                  <Box p={3}>
                    <Typography className="camp-sub-name">
                      Campaign Type
                    </Typography>
                    <Typography className="camp-sub-des mt-2">
                      Social Media - Facebook
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Box>
                <Divider />
              </Box>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Box p={3}>
                    <Typography className="camp-sub-name">
                      Campaign Description
                    </Typography>
                    <Typography className="camp-sub-des mt-2">
                      Facebook Campaign for the Year 2021 to target new students
                      and potential leads
                    </Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Divider orientation="vertical" />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={5} xl={5}></Grid>
              </Grid>
              <Box>
                <Divider />
              </Box>
            </Box>
          );
        })}
      </Paper>
    </div>
  );
});

export default CrmGeneralView;
