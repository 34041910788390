import { Box, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { common, envConfig, api } from "helpers";
import * as Services from "helpers/service";
import { DragDropContext } from "react-beautiful-dnd";

import { useParams, useOutletContext } from "react-router-dom";
import AccessDenied from "elements/AccessDenied";
import CrmHeader from "elements/crmHeader";
import StatusBar from "elements/StatusBar";
import KanbanList from "./KanbanList";

export default function KanbanView(props) {
  const [permission] = useOutletContext();
  const myRefs = useRef({});
  let { mod } = useParams();
  const [status, setStatus] = useState("process");
  const [statusList, setStatusList] = useState([
    { StatusCode: "STARTED", StatusName: "Started", StatusColor: "#605bd2" },
    { StatusCode: "PENDING", StatusName: "Pending", StatusColor: "#e44949" },
    {
      StatusCode: "INPROGRESS",
      StatusName: "In Progress",
      StatusColor: "#ff9e1e",
    },
    {
      StatusCode: "COMPLETED",
      StatusName: "Completed",
      StatusColor: "#34c157",
    },
  ]);

  // onScroll Hide headers
  React.useEffect(() => {
    if (window.location.pathname == "/crm/campaign/kanban") {
      const listenToScroll = () => {
        const appHeader = document.querySelector(".kanban-appbar-header");
        const drawerHeader = document.querySelector(".custom-header");
        const primaryHeader = document.querySelector(".kanban-primary-header");
        const crmHeader = document.querySelector(".crm-header-wrap");
        const crmFooter = document.querySelector(".footer");
        // const reportHeader = document.querySelector(".report-header");
        if (window.scrollY >= 1) {
          appHeader.classList?.add("hide-onscroll");
          drawerHeader.classList?.add("hide-onscroll");
          primaryHeader.classList?.add("hide-onscroll");
          crmHeader.classList?.add("hide-onscroll");
          crmFooter.classList?.add("hide-footer");
          // reportHeader.classList?.add("hide-onscroll");
        } else {
          appHeader.classList?.remove("hide-onscroll");
          drawerHeader.classList?.remove("hide-onscroll");
          primaryHeader.classList?.remove("hide-onscroll");
          crmHeader.classList?.remove("hide-onscroll");
          crmFooter.classList?.remove("hide-footer");
          // reportHeader.classList?.remove("hide-onscroll");
        }
      };

      window.addEventListener("scroll", listenToScroll);
      return () => window.removeEventListener("scroll", listenToScroll);
    }
  }, []);

  React.useEffect(() => {
    var isMobileVersion = document.getElementsByClassName("rotate-arrow");
    if (isMobileVersion.length > 0) {
      const kanbanWrap = document.getElementById("kanban-column-wrap");
      if (kanbanWrap) {
        kanbanWrap.classList.add("sm-kanban-wrap");
        kanbanWrap.classList.remove("lg-kanban-wrap");
      }
    } else {
      const kanbanWrap = document.getElementById("kanban-column-wrap");
      if (kanbanWrap) {
        kanbanWrap.classList.add("lg-kanban-wrap");
        kanbanWrap.classList.remove("sm-kanban-wrap");
      }
    }
  });

  const getTransactionTypeId = (_) => {
    const { module } = props;
    return new Promise((resolve, reject) => {
      if (module === "campaign") {
        resolve(common.getTransactionTypeId(envConfig.CAMPAIGN_TRANS_TYPE_ID));
      } else if (module === "enquiry") {
        resolve(common.getTransactionTypeId(envConfig.LIST_TRANS_TYPE_ID));
      } else if (module === "lead") {
        resolve(common.getTransactionTypeId(envConfig.LEAD_TRANS_TYPE_ID));
      } else if (module === "deal") {
        resolve(common.getTransactionTypeId(envConfig.DEAL_TRANS_TYPE_ID));
      } else if (module === "mylead") {
        resolve(common.getTransactionTypeId(envConfig.LEAD_TRANS_TYPE_ID));
      } else if (module === "mydeal") {
        resolve(common.getTransactionTypeId(envConfig.DEAL_TRANS_TYPE_ID));
      } else if (module === "quote") {
        resolve(common.getTransactionTypeId(envConfig.QUOTE_TRANS_TYPE_ID));
      } else if (module === "invoice") {
        resolve(common.getTransactionTypeId(envConfig.INVOICE_TRANS_TYPE_ID));
      } else if (module === "sales") {
        resolve(
          common.getTransactionTypeId(envConfig.SALESORDER_TRANS_TYPE_ID)
        );
      }
      resolve("");
    });
  };

  const getCRMStatusList = async () => {
    const { module } = props;
    const userData = common.authInfo();
    const campaignTypeId = await getTransactionTypeId();
    let queryParams = "";
    let url = "";
    if (module === "task") {
      queryParams = `?finder=FindLookupVals;`;
      queryParams += `pLookupCode=TASK_STATUS`;
      queryParams += `&totalResults=true`;
      queryParams += `&limit=100`;
      queryParams += `&offset=0`;
      url = envConfig.BASE_API + Services.LOOKUPS_LOV + queryParams;
    } else {
      queryParams = `?q=TransactionTypeId=${campaignTypeId};`;
      queryParams += `OrgId=${userData.DefaultOrgId};`;
      queryParams += `TenantId=${userData.TenantId};`;
      queryParams += `Active=Y`;
      queryParams += `&limit=100`;
      queryParams += `&offset=0`;
      queryParams += `&orderBy=OrderNo:asc`;
      url = envConfig.BASE_API + Services.TRANSACTION_STATUS + queryParams;
    }
    const data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };
    api.call(
      data,
      (response) => {
        if (response) {
          if (response?.data?.items?.length) {
            if (module === "task") {
              response.data.items.sort((a, b) => {
                return a.OrderNo > b.OrderNo ? 1 : -1;
              });
            }
            setStatusList(response.data.items);
          }
          setStatus("success");
        } else {
          setStatus("error");
        }
      },
      (error) => {
        setStatus("error");
      },
      (final) => {}
    );
  };

  useEffect(() => {
    if (props.module != "task") {
      getCRMStatusList();
    } else {
      setStatus("success");
    }
  }, [props]);

  const onDragEnd = (result) => {
    const { module } = props;
    if (!result.destination) {
      return;
    }
    if (result.source.droppableId == result.destination.droppableId) {
      return;
    }
    // state
    // drag
    let id = result.draggableId;
    let source = result.source.droppableId.split("_");
    let destination = result.destination.droppableId.split("_");
    // check access
    if (module == "lead" || module == "deal") {
      //TODO - Give all access to super admin
      // if (
      //   !common.validPermission(
      //     sourceData.accessId,
      //     "update",
      //     userPermissions,
      //     deptEmployees,
      //     sourceData.createdBy
      //   )) {
      //   common.snack("E", "Sorry! You dont have access to update status.");
      //   return;
      // }
    } else if (
      module == "campaign" ||
      module == "enquiry" ||
      module == "task"
    ) {
      // if (!userPermissions.update) {
      //   // common.snack("E", "Sorry! You dont have access to update status.");
      //   // return;
      // }
    }
    //TODO: now work/mydeal & work/mylead allowed to delete
    // set data
    const listItem = myRefs.current[source[1]].getData(result.source.index);
    myRefs.current[source[1]].removeData(result.source.index);
    myRefs.current[destination[1]].setData(result.destination.index, listItem);

    // status update
    let url = "";
    var formAttr = {};
    if (module == "campaign") {
      url = encodeURI(envConfig.BASE_API + Services.CRM_CAMPAIGNS + "/" + id);
      formAttr = { CampaignStatus: destination[1] };
    } else if (module == "enquiry") {
      url = encodeURI(envConfig.BASE_API + Services.CRM_LISTS + "/" + id);
      formAttr = { ListStatus: destination[1] };
    } else if (module == "lead" || module == "mylead") {
      url = encodeURI(envConfig.BASE_API + Services.CRM_LEADS + "/" + id);
      formAttr = { LeadStatus: destination[1] };
    } else if (module == "deal" || module == "mydeal") {
      url = encodeURI(
        envConfig.BASE_API + Services.CRM_DEAL_OPPORTUNITY + "/" + id
      );
      formAttr = { ApprStatus: destination[1] };
    } else if (module == "task") {
      url = encodeURI(envConfig.BASE_API + Services.TASKS + "/" + id);
      formAttr = { Status: destination[1] };
    } else if (module == "quote") {
      url = encodeURI(envConfig.BASE_API + Services.CRM_QUOTES + "/" + id);
      formAttr = { ApprStatus: destination[1] };
    }
    const data = {
      url: url,
      type: "dynamic",
      method: "PATCH",
      auth: "token",
      body: JSON.stringify(formAttr),
      cType: 4,
    };
    api.call(
      data,
      (response) => {
        if (response != null) {
          common.snack("S", "Successfully updated!");
        }
      },
      (error) => {
        // setLoading(loader, false);
        // setStatus("error");
      },
      (final) => {}
    );
  };

  const providePermission = (argMod, type) => {
    const action = {
      read: "r",
      update: "u",
      export: "e",
      import: "i",
      add: "a",
      delete: "d",
    };

    if (["lead", "deal"].includes(argMod)) {
      if (
        ["1", "2"].includes(
          permission?.crm?.[common.ModToKey(argMod, true)]?.[action[type]]
        )
      ) {
        return common.givenPermission(permission, common.ModToKey(argMod), type);
      } else {
        return false;
      }
    } else {
      return common.givenPermission(permission, common.ModToKey(argMod), type);
    }
  };

  return (
    <div className="kanban">
      <DragDropContext onDragEnd={onDragEnd}>
        {props.module != "task" && (
          <CrmHeader
            changeView={(val) => props.changeView(val)}
            changeForm={(val) => props.changeForm(true)}
            pageName={mod}
            buttonName={mod}
            add={true}
            type={props.type ? props.type : "menu"}
            view={"kanban"}
          />
        )}
        {providePermission(mod, "read") ? (
          <AccessDenied />
        ) : (
          <Box className="kanban-paper">
            <Grid container spacing={2} id="kanban-column-wrap">
              <StatusBar status={status} />
              {status == "success" &&
                statusList.map((status, index) => (
                  <Grid
                    item
                    className="kanban-items-wrap"
                    key={"_" + status.StatusCode}
                  >
                    <KanbanList
                      ref={(el) => {
                        return (myRefs.current[status.StatusCode] = el);
                      }}
                      changeForm={props.changeForm}
                      module={props.module}
                      idx={index}
                      status={status}
                    />
                  </Grid>
                ))}
            </Grid>
          </Box>
        )}
      </DragDropContext>
    </div>
  );
}
