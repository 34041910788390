// LOGIN API
export const SIGN_IN = "restauth/v1";
// Leads API URLs
export const CRM_DEALS = "CholaCrmOpportunity";
export const CRM_LEADS = "CholaCrmLeads";
export const TRANSACTION_STATUS = "CholaTransStatus";
export const STATUS_TRANSITION = "CholaStatusTransition";
export const LOOKUPS_LOV = "CholaLookupsLov";
export const CRM_CAMPAIGNS = "CholaCrmCampaigns";
export const CRM_LISTS = "CholaCrmLists";
export const CRM_LIST_DTLS = "CholaCrmListDtls";
export const CRM_ENQUIRYS = "CholaCrmLists";
export const CRM_ENQUIRY_DTLS = "CholaCrmListDtls";
export const CRM_ESERVICE_HDR = "CholaEserviceHdr";
export const CRM_EMPLOYEES = "CholaPayemployee";
export const CRM_GET_USER_INFO_V01 = "GetUserInfoVO1";
export const CRM_GET_TARGETS = "CholaCrmRscTargets";
export const CRM_ROLES_HDR = "CholaRolesHdr";
export const CRM_ROLES_DTL = "CholaRolesDtl";
export const CRM_PAY_EMPLOYEE = "CholaPayemployee";
export const CRM_CALL_LOGS = "CholaCallLogs";
export const CRM_MEETINGS = "CholaEvents";
export const CRM_CONTACTS = "CholaContacts";
export const CRM_COMMENTS = "CholaComments";
export const CRM_FILES = "CholaFiles";
export const CRM_USERS = "CholaUsers";
export const CRM_SALES_TEAM = "CholaCrmLeadsTeam";
export const CRM_DEAL_SALES_TEAM = "CholaCrmOppTeam";
export const CRM_CUSTOMER = "CholaCustomer";
export const CRM_LEADS_PRODUCTS = "CholaLeadsPro";
export const CRM_DEALS_PRODUCTS = "CholaOpportunityPro";
export const CRM_ITEM_DETAILS = "CholaItemDetails";
export const CRM_DEAL_OPPORTUNITY = "CholaCrmOpportunity";
// Deals API URLs

// Employee API URLs
export const HRMS_SUB_DIVISION = "CholaSubDivision";
export const HRMS_SECTIONS = "CholaSections";
export const HRMS_DESIGNATIONS = "CholaDesigMaster";
export const HRMS_DEPARTMENTS = "CholaDeptMaster";
export const CRM_CREATE_USER = "CholaCreateUser";

// Division
export const HRMS_DIVISION = "CholaDivisionMaster";
export const CONTACTS_LINKS = "CholaContactLinks";

// Geography resuorces
export const CRM_COUNTRIES = "BasCountries";
export const CRM_STATES = "BasStates";
export const CRM_CITIES = "BasCities";

// Territories API
export const CRM_TERRITORIES = "CholaCrmTerritories";
export const CRM_TERRITORY_TEAM = "CholaCrmTerritoryTeam";
export const CRM_COVERAGE_DIMS = "CholaTerrCovgDims";
export const CRM_COVERAGE_DETAILS = "CholaTerrCovgDtls";
export const CRM_INCEXC_CUST = "CholaTerrIncExcCust";
export const CRM_TERRITORY_EMPLOYEES = "CholaTerritoryEmployees";

// Quotes API
export const CRM_QUOTES = "CholaCustQuotEntry";
export const CRM_QUOTE_DETAILS = "CholaCustQuotEntryDtls";
export const CRM_CUSTOMER_SITE = "CholaCustomerSites";
export const CRM_ANNEXURES = "CholaCustQuoteAnnexures";

// Sales Order
export const CRM_SALES_ORDER = "CholaCustomerOrder";
export const CRM_SALES_ORDER_DETAILS = "CholaCustomerOrderDtl";

//Invoice API
export const CRM_INVOICE = "CholaSalesInvoice1";
export const CRM_INVOICE_DETAILS = "CholaSalesInvoiceDtls";

//Party API
export const CRM_PARTY_HDR = "ClBasPartyHdr";
export const CRM_LOCATIONS = "CholaLocations";
export const CRM_PARTY_SITES = "ClBasPartySites";
export const CRM_PARTY_CLASSIFICATION = "CholaPartyClass";

export const CRM_MYLEADS = "CholaMyLeads";
export const CRM_MYDEALS = "CholaMyOppts";

//HRMS Service API
//Leave Calender
export const HRMS_LEAVE_CALENDER = "CholaLeaveCal";
export const HRMS_LEAVE_CAL_DTLS = "CholaLeaveCaldtl";
//Leave Settings
export const HRMS_LEAVE_SETTINGS = "CholaLeaveSetting";

// Roles
export const ROLES = "CholaGroups";
export const USER_ROLE_GROUPS = "CholaUserGroups";
export const CHOLA_USER_VIEW = "CholaUsersView";
export const CHOLA_GROUP_VIEW = "CholaGroupsView";

//Organization
export const BASE_ORGANIZATION = "BasOrg";

export const CRM_DEFAULT_GROUPS_1 = "employee-mobile-user";
export const CRM_DEFAULT_GROUPS_2 = "adminuser";
export const CRM_DEFAULT_GROUPS_3 = "appuser";
export const CRM_DEFAULT_GROUPS_4 = "reports-role";

//Tasks
export const TASKS = "CholaTasks";
export const TASKS_VIEW = "CholaTasksView";

//Target
export const CONFIGURE_TARGET = "CholaCrmTargets";
export const EMPLOYEE_TARGET = "CholaCrmRscTargets";
export const CAMPAIGN_REPORT = "CholaCrmCpgnDboardView";

// Email
export const CHOLA_NOTIFICATIONS = "CholaNotifications";
//Email Template
export const CHOLA_EMAIL_TEMPLATE = "BasEmailTemplates";

//Automation
export const CHOLA_AUTOMATION = "CholaAutomationRules";
export const CHOLA_AUTOMATION_CONDITION_GROUPS =
  "CholaAutomationConditionGroups";
export const CHOLA_TABLE_COLUMNS_LIST = "CholaTableColumnsList";

// task countS
export const CHOLA_TASK_COUNTS = "CholaTaskCounts";

//Item Master
export const ITEM_MATSER = "CholaMasterItems";

//Reports
export const REPORTS = "CholaReports";
export const CHOLA_REPORT_PARAMS = "CholaReportParams";
export const CHOLA_REPORT_DOWNLOAD = "cholareportservlet";

//CholaTransactionTypes
export const CHOLA_TRANS_TYPE = "CholaTransactionTypes";

//Lead Import
export const LEAD_IMPORT_HDR = "CholaCrmLeadsHdrIntf";
export const LEAD_IMPORT_DTL = "CholaCrmLeadsDtlIntf";

// null attribute
export const NULL_ATTR = "CholaNullExtrAttr";
export const CUSTOM_ATTR = "CholaCustomAttributes";
export const CHOLA_ENTITIES = "CholaEntities";
export const CHOLA_ENTITY_DETAILS = "CholaEntityDtls";

//Conversion
export const CHOLA_CUSTOM_CONVERSION = "CholaCustomConversion";
//Conversion Mapping
export const CHOLA_CUSTOM_MAPPING_HDR = "CholaAttrMappingHdr";
export const CHOLA_CUSTOM_MAPPING_DTL = "CholaAttrMappingDtl";

//User custom filter
export const CHOLA_CUSTOM_FILTER = "CholaCustomUserFilters";

export const CHOLA_TENANTS = "CholaTenants";

export const CHOLA_USER_REPORTS = "CholaUserReports";

export const CHOLA_COMMON_SEARCH = "CholaCommonSearch";
