import { Box, Grid, Paper, Item } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";
import { common, envConfig } from "helpers";
let ModNameChange = envConfig.ModNameChange;

const HrmsTab = () => {
  return (
    <>
      <Box p={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <Paper variant="contained" square className="main-content-wrap">
              <Link to="/setting/hrms/department" className="link-text">
                <Box className="contents-padding activate-arrow ">
                  <Grid container>
                    <Grid item xs={11}>
                      <label className="setting-config-tab-titles">
                        {ModNameChange ? "Role" : "Department"}
                      </label>
                      <p className="setting-card-content">
                        Add or remove {ModNameChange ? "roles" : "departments"}{" "}
                        related to your business or company
                      </p>
                    </Grid>
                    <Grid item xs={1} className="padding-zero changeArrow">
                      <img
                        alt="next"
                        src={common.loadImg(
                          "iconly-light-arrow-next-circle.svg"
                        )}
                      />
                      <img
                        alt="next"
                        src={common.loadImg("next-activeArrow.svg")}
                        className="arrowActive"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Link>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Paper variant="contained" square className="main-content-wrap">
              <Link to="/setting/hrms/employee" className="link-text">
                <Box className="contents-padding activate-arrow">
                  <Grid container>
                    <Grid item xs={11}>
                      <Box>
                        <label className="setting-config-tab-titles">
                          {ModNameChange ? "Users" : "Employees"}
                        </label>
                        <p className="setting-card-content">
                          Add or remove {ModNameChange ? "users" : "employees"}{" "}
                          related to your business or company
                        </p>
                      </Box>
                    </Grid>
                    <Grid item xs={1} className="padding-zero changeArrow">
                      <img
                        alt="next"
                        src={common.loadImg(
                          "iconly-light-arrow-next-circle.svg"
                        )}
                      />
                      <img
                        alt="next"
                        src={common.loadImg("next-activeArrow.svg")}
                        className="arrowActive"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Link>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Paper variant="contained" square className="main-content-wrap">
              <Link to="/setting/hrms/rolesResponsiblity" className="link-text">
                <Box className="contents-padding activate-arrow">
                  <Grid container>
                    <Grid item xs={11}>
                      <Box>
                        <label className="setting-config-tab-titles">
                          {ModNameChange
                            ? "Work Allocations"
                            : "Roles and Responsibilities"}
                        </label>
                        <p className="setting-card-content">
                          Add or remove{" "}
                          {ModNameChange
                            ? "work allocations"
                            : "roles and responsibilities"}{" "}
                          related to your business or company
                        </p>
                      </Box>
                    </Grid>
                    <Grid item xs={1} className="padding-zero changeArrow">
                      <img
                        alt="next"
                        src={common.loadImg(
                          "iconly-light-arrow-next-circle.svg"
                        )}
                      />
                      <img
                        alt="next"
                        src={common.loadImg("next-activeArrow.svg")}
                        className="arrowActive"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Link>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default HrmsTab;
