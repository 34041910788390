import React, { useState } from "react";

import AmlHashForm from "aml-hash-form";

import { Divider, Checkbox, FormControlLabel } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FieldSelect from "elements/FieldSelect";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { makeStyles } from "@material-ui/core/styles";

import { api, common, envConfig } from "helpers";
import { CRM_ROLES_DTL } from "helpers/service";

const useStyles = makeStyles({
  paperFullWidth: {
    overflowY: "visible",
  },
  dialogContentRoot: {
    overflowY: "visible",
  },
});

const RESPONSIBILITY_SAVE_SUCCESS = "Responsibility saved successfully";
const RESPONSIBILITY_SAVE_FAILED = "Responsibility save failed";

const AddResponsibility = (props) => {
  const { responsibilityOpen, handleResClose, roleInfo, Reload } = props;

  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const [state, setState] = useState({
    carryForward: false,
    shift: "",
    reportFrequency: "",
  });

  const closeMd = () => {
    handleResClose();
    setState({
      carryForward: false,
      shift: "",
      reportFrequency: "",
    });
    setMultiValue({
      Responsibilities: "",
      Area: "",
      Duration: "",
    });
    handleReset2();
    setError({})
  };

  const formFields = {
    Responsibilities: {
      validate: ["req#Responsibility Name Code is required"],
    },
    Area: { validate: ["req#Area is required"] },
    Duration: { validate: ["req#Duration is required"] },
  };

  const {
    fields,
    errors,
    handleChange,
    handleSubmit,
    handleReset: handleReset2,
    setMultiValue,
  } = AmlHashForm(formFields);

  const onSubmit = (event) => {
    event.preventDefault();
    const isValid = handleSubmit();
    const isLocalValid = handleValidateData();

    if (isValid && isLocalValid) {
      setIsLoading(true);
      let url = "";
      //   TODO: Temp hide
      url = `${envConfig.BASE_API}${CRM_ROLES_DTL}`;

      const payload = fields ? fields : {};
      payload.Shift = state.shift ? state.shift.value : null;
      payload.ReportingFreq = state.reportFrequency
        ? state.reportFrequency?.value
        : null;
      payload.CarryForward = state.carryForward ? "Y" : "N";
      payload.Active = "Y";
      payload.RoleHdrId = roleInfo?.RoleHdrId;
      payload.TenantId = roleInfo?.TenantId;
      payload.GroupId = roleInfo?.GroupId;

      api.call(
        {
          url: url,
          type: "dynamic",
          method: "POST",
          auth: "token",
          cType: 4,
          body: JSON.stringify(payload),
        },
        async (response) => {
          if (response.status === 200 || response.status === 201) {
            setIsLoading(false);
            Reload();
            closeMd()
            common.snack("S", RESPONSIBILITY_SAVE_SUCCESS);
          } else {
            setIsLoading(false);
            common.snack("E", RESPONSIBILITY_SAVE_FAILED);
          }
        },
        (error) => {
          setIsLoading(false);
          common.snack("E", RESPONSIBILITY_SAVE_FAILED);
        },
        (final) => {}
      );
      closeMd()
      props.Reload(true);
    }
  };

  const handleValidateData = () => {
    let isLocalValid = true;
    const errorObj = error;
    const { shift, reportFrequency } = state;

    if (!shift) {
      isLocalValid = false;
      errorObj.shift = "Shift field is required";
    }
    if (!reportFrequency) {
      isLocalValid = false;
      errorObj.reportFrequency = "Report Frequency field is required";
    }

    setError(errorObj);
    return isLocalValid;
  };

  const handleSelect = (key, value) => {
    setState((oldData) => {
      return {
        ...oldData,
        [key]: value,
      };
    });
  };

  return (
    <div>
      <Dialog
        disableScrollLock
        open={responsibilityOpen}
        onClose={closeMd}
        fullWidth={true}
        classes={{
          paperFullWidth: classes.paperFullWidth,
        }}
      >
        <div className="role-wrap">
          <DialogTitle>ADD RESPONSIBILITY</DialogTitle>
          <Divider />
          <DialogContent
            classes={{
              root: classes.dialogContentRoot,
            }}
          >
            <div className="row response-wrap">
              <div className="col-md-12 body-th common-input-style mb-4 mandatoryclass">
                <Box className="filled-box">
                  <TextField
                    id="filled-basic"
                    label="Responsibility Name"
                    variant="filled"
                    name="Responsibilities"
                    value={fields.Responsibilities}
                    onChange={(event) => handleChange(event)}
                  />

                  {errors?.Responsibilities ? (
                    <p className="error-txt">{errors?.Responsibilities}</p>
                  ) : null}
                </Box>
              </div>
              <div className="col-md-12 body-th common-input-style mb-4 mandatoryclass">
                <Box className="filled-box">
                  <TextField
                    id="filled-basic"
                    label="Area"
                    variant="filled"
                    name="Area"
                    value={fields.Area}
                    onChange={(event) => handleChange(event)}
                  />
                  {errors?.Area ? (
                    <p className="error-txt">{errors?.Area}</p>
                  ) : null}
                </Box>
              </div>
              <div className="col-md-12 mb-4">
                <FormControlLabel
                  className="dynamic-checkbox ms-0 checkbox-space"
                  control={<Checkbox />}
                  label="Carry Forward"
                  value={state.carryForward}
                  labelPlacement="end"
                  onChange={(event) =>
                    handleSelect("carryForward", event.target.checked)
                  }
                />
              </div>
              <div className="col-md-12 mb-4 responsibility-field-select  common-select-border mandatoryclass">
                <FieldSelect
                  api="LOOKUPS_LOV"
                  get="Name"
                  set="Code"
                  value={state.shift}
                  isMulti={false}
                  params={`&finder=FindLookupVals;pLookupCode=SHIFT_TYPE`}
                  isRender={true}
                  onSelect={(data) => handleSelect("shift", data)}
                  placeholder="Shift"
                />
                {error?.shift ? (
                  <p className="error-txt">{error?.shift}</p>
                ) : null}
              </div>
              <div className="col-md-12 mb-4 responsibility-field-select  common-select-border mandatoryclass">
                <FieldSelect
                  api="LOOKUPS_LOV"
                  get="Name"
                  set="Code"
                  value={state.reportFrequency}
                  isMulti={false}
                  params={`&finder=FindLookupVals;pLookupCode=CHOLA_DAILY_TASK_FREQ`}
                  isRender={true}
                  onSelect={(data) => handleSelect("reportFrequency", data)}
                  placeholder="Reporting Frequency"
                />
                {error?.reportFrequency ? (
                  <p className="error-txt">{error?.reportFrequency}</p>
                ) : null}
              </div>
              <div className="col-md-12 mb-4 mandatoryclass">
                <Box className="filled-box">
                  <TextField
                    id="filled-basic"
                    label="Duration"
                    variant="filled"
                    name="Duration"
                    value={fields.Duration}
                    onChange={(event) => handleChange(event)}
                  />
                  {errors?.Duration ? (
                    <p className="error-txt">{errors?.Duration}</p>
                  ) : null}
                </Box>
              </div>
            </div>
          </DialogContent>
          <Divider />
          <DialogActions className="justify-content-start py-4">
            <div className="botBtn pl-3">
              <div>
                <Button
                  className="automation-footer-btn"
                  type="submit"
                  onClick={(event) => !isLoading && onSubmit(event)}
                >
                  {isLoading ? "Processing..." : "Submit"}
                </Button>
                <Button
                  className="automation-cancel-btn"
                  onClick={closeMd}
                  type="button"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default AddResponsibility;
