import { common } from "./common";
import { envConfig } from "./config";

let userData = common.authInfo();
// const
let upload_url =
  (userData?.TenantId == "75"
    ? envConfig.DEV_ETECH_COMM_URL
    : envConfig.LIV_ETECH_COMM_URL) + "common/upload";
let sendemail_url =
  (userData?.TenantId == "75"
    ? envConfig.DEV_EDUTECH_URL
    : envConfig.LIV_EDUTECH_URL) + "/application/mail";
let services = {
  common_upload_direct: upload_url,
  send_email: sendemail_url,
};

export const eduTechSerivces = {
  async sendUserTagMail(data) {
    let URL = `${envConfig.LIV_ETECH_COMM_URL}/common/sendMail`;

    let result = await fetch(URL, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          return result.data;
        },
        (error) => {
          common.snack("E", error);
          return [];
        }
      );
    return result;
  },

  // get courses by university
  async getCoursesbyUniversity(uId) {
    let URL = `${this.fetchEduURL()}/course/${uId}?query={"search":""}&limit=10000&offset=1`;

    let result = await fetch(URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + envConfig.ED_CRM_TOKEN,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          return result.data;
        },
        (error) => {
          common.snack("E", error);
          return [];
        }
      );
    return result;
  },

  async getUniversityCourses(uId = "", search = "", limit = 10, offset = 1) {
    let URL = `${this.fetchEduURL()}/course?query={"search":"${search}","university":"${uId}"}&limit=${limit}&offset=${offset}`;

    let result = await fetch(URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + envConfig.ED_CRM_TOKEN,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          return result;
        },
        (error) => {
          common.snack("E", error);
          return [];
        }
      );
    return result;
  },

  //get all universities
  async getAllUniversities(search, limit = 100000, offset = 1) {
    let URL = `${this.fetchEduURL()}/university?query={"search":"${search}"}&limit=${limit}&offset=${offset}`;

    let result = await fetch(URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + envConfig.ED_CRM_TOKEN,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          return result;
        },
        (error) => {
          console.log("error", error);
          // common.snack("E", error);
          return [];
        }
      );
    return result;
  },

  //get all universities
  async getUniversities() {
    let URL = `${this.fetchEduURL()}/university?limit=10000&offset=1`;

    let result = await fetch(URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + envConfig.ED_CRM_TOKEN,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          return result.data;
        },
        (error) => {
          console.log("error", error);
          // common.snack("E", error);
          return [];
        }
      );
    return result;
  },

  //get all universities
  async getApplicationInfo(appId) {
    let URL = `${this.fetchEduURL()}/application/${appId}`;

    let result = await fetch(URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + envConfig.ED_CRM_TOKEN,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          return result;
        },
        (error) => {
          // common.snack("E", error);
          return [];
        }
      );
    return result;
  },

  //update lead info
  async updateApplicationInfo(appId, data) {
    let URL = `${this.fetchEduURL()}/application/${appId}`;

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + envConfig.ED_CRM_TOKEN,
      },
      body: JSON.stringify(data),
    };

    let result = await fetch(URL, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          return result.data;
        },
        (error) => {
          console.log("error", error);
          // common.snack("E", error);
          return [];
        }
      );
    return result;
  },

  //mass update fields
  async massUpdateApp(data) {
    let URL = `${this.fetchEduURL()}/application`;

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + envConfig.ED_CRM_TOKEN,
      },
      body: JSON.stringify(data),
    };

    let result = await fetch(URL, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          return result.data;
        },
        (error) => {
          console.log("error", error);
          // common.snack("E", error);
          return [];
        }
      );
    return result;
  },

  //get application email history
  async getAppEmailHistory(appId) {
    let URL = `${this.fetchEduURL()}/application/mail/${appId}`;

    let result = await fetch(URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + envConfig.ED_CRM_TOKEN,
      },
    })
      .then((response) =>
        response.json().then((data) => ({
          status: response.status,
          data: data.data,
        }))
      )
      //.then((res) => res.json())
      .then(
        (result) => {
          return result;
        },
        (error) => {
          console.log("error", error);
          // common.snack("E", error);
          return [];
        }
      );
    return result;
  },

  //send email to application owner
  async sendApplicationEmail(appId, data) {
    let URL = `${this.fetchEduURL()}/application/mail/${appId}`;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + envConfig.ED_CRM_TOKEN,
      },
      body: JSON.stringify(data),
    };

    let result = await fetch(URL, requestOptions)
      .then((response) =>
        response.json().then((data) => ({
          status: response.status,
          data: data.data,
        }))
      )
      //.then((res) => res.json())
      .then(
        (result) => {
          return result;
        },
        (error) => {
          console.log("error", error);
          // common.snack("E", error);
          return [];
        }
      );
    return result;
  },

  async call(data = {}, response, final) {
    getData(data);
    // server side rendering
    if (data.ssr) {
      let myPromise = new Promise(function (resolve, reject) {
        fetch(data.url, getOptions(data))
          .then((response) =>
            response.text().then((data) => ({
              status: response.status,
              data: data,
            }))
          )
          .then((result) => {
            if (validateData(result)) {
              try {
                result.data = JSON.parse(result.data);
              } catch (e) {
                result.data = {
                  error: e.toString(),
                };
              }
              notify(data, result);
              resolve(result);
            }
          })
          .catch((err) => {
            let result = {
              status: 404,
              data: {
                error: err.toString(),
              },
            };
            notify(data, result);
            resolve(result);
          });
      });
      return myPromise;
    } else {
      // client side rendering
      fetch(data.url, getOptions(data))
        .then((response) =>
          response.text().then((data) => ({
            status: response.status,
            data: data,
          }))
        )
        .then((result) => {
          if (validateData(result)) {
            try {
              result.data = JSON.parse(result.data);
            } catch (e) {
              result.data = {
                error: e.toString(),
              };
            }
            notify(data, result);
            response(result);
          }
        })
        .catch((err) => {
          let result = {
            status: 404,
            data: {
              error: err.toString(),
            },
          };
          notify(data, result);
          response(result);
        })
        .finally(() => {
          if (typeof final !== "undefined") {
            final();
          }
        });
    }
  },

  fetchEduURL() {
    if (userData.TenantId == "75") {
      return envConfig.DEV_EDUTECH_URL;
    } else {
      return envConfig.LIV_EDUTECH_URL;
    }
  },
};

// support
const getData = (data) => {
  data.repeat = data.repeat || false;
  if (!data.repeat) {
    data.auth = data.auth || "token";
    data.type = data.type || "standard";
    data.method = data.method || "POST";
    data.cType = data.cType || 1;
    data.query = data.query || "";
    data.body = data.body || "";
    data.keyQuery = data.keyQuery || "";
    data.notify = data.notify ?? true;
    // set url
    if (data.type == "third") {
      data.url = data.url + data.query;
    } else {
      data.url = services[data.url] + data.query;
      if (data.keyQuery != "") {
        for (var key in data.keyQuery) {
          data.url = data.url.replace(`:${key}`, data.keyQuery[key]);
        }
      }
    }
    // set body
    if (data.body) {
      if (data.cType == 1) {
        data.body = data.body ? JSON.stringify(data.body) : "";
      } else if (data.cType == 2) {
        let bodyParam = [];
        for (var property in data.body) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(data.body[property]);
          bodyParam.push(encodedKey + "=" + encodedValue);
        }
        data.body = bodyParam.join("&");
      }
    }
  }
};

function getOptions(data) {
  let reqOptions = {
    method: data.method,
    headers: getHeaders(data),
  };

  if (data.body) {
    reqOptions.body = data.body;
  }
  return reqOptions;
}

function getHeaders(data) {
  // default
  let headers = {};

  // content types
  let contentTypes = {
    1: "application/json",
    2: "application/x-www-form-urlencoded",
    3: "application/vnd.oracle.adf.action+json",
    4: "application/vnd.oracle.adf.resourceitem+json",
    5: "application/vnd.oracle.adf.batch+json",
    6: "multipart/form-data",
  };
  if (data.cType !== 6) {
    headers["Content-Type"] = contentTypes[data.cType];
  }

  // extra content types
  let moreHeaderList = {
    rfv: "REST-Framework-Version",
  };
  if (data.moreHead) {
    for (var item in data.moreHead) {
      headers[moreHeaderList[item]] = data.moreHead[item];
    }
  }

  // authentication
  if (data.auth == "token") {
    let adminAuthData = common.getAuth();
    headers.Authorization = "Bearer " + adminAuthData?.token; //.token;
  } else if (data.auth == "temp") {
    let adminTempData = common.adminTempDataGet();
    headers.Authorization = "Bearer " + adminTempData?.token;
  } else if (data.auth == "basic") {
    headers.Authorization =
      "Basic " + btoa(data.credentials.param1 + ":" + data.credentials.param2);
  } else if (data.auth == "config") {
    headers.Authorization = "Bearer " + envConfig.ED_CRM_TOKEN;
  }

  return headers;
}

const validateData = (result) => {
  let isExpired = 401;
  if (isExpired != "" && result.status == isExpired) {
    /* localStorage.clear();
    common.redirect(); */
    return false;
  } else {
    return true;
  }
};

const notify = (data, result) => {
  if (data.notify) {
    if (![200, 500, 400, 401].includes(result.status)) {
      common.snack("E", result.data.error);
    }
  }
};
