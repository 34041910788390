import { api, common, envConfig } from "helpers";
import * as Services from "helpers/service";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

export default function useInfiniteScrolling(module, page, key) {
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  // const [userPermissions, setUserPermission] = useState({});
  const [permission] = useOutletContext();
  const authInfo = common.authInfo();

  // support
  const getPriority = (code) => {
    let priority = "";
    // sData.taskPriority.map(item => {
    //   if (item.Code == code) {
    //     priority = item.Name;
    //   }
    // })
    return priority;
  };

  const getUrl = (statusCode, limit, userId, userName) => {
    let url = "",
      filter = "";
    if (module == "campaign") {
      filter = `?q=CampaignStatus=${statusCode}&orderBy=CreatedOn:desc&limit=${limit}&offset=${
        page * limit
      }`;
      url = envConfig.BASE_API + Services.CRM_CAMPAIGNS + filter;
    } else if (module == "enquiry") {
      filter = `?q=ListStatus=${statusCode}&orderBy=CreatedOn:desc&limit=${limit}&offset=${
        page * limit
      }`;
      url = envConfig.BASE_API + Services.CRM_LISTS + filter;
    } else if (module == "task") {
      filter = `?finder=FindTaskByReporter;pAssignee=${userId},pCreatedBy=${userName},pSearchCriteria=,pStatus=${statusCode}&orderBy=Status:desc&limit=${limit}&offset=${
        page * limit
      }`;
      url = envConfig.BASE_API + Services.TASKS_VIEW + filter;
    } else if (module == "lead") {
      console.log("permission?.crm?", permission?.crm?.lead.r);
      filter = `?q=LeadStatus=${statusCode}&totalResults=true&orderBy=CreatedOn:desc&limit=${limit}&offset=${
        page * limit
      }`;
      //Filter based on department (territory)
      if (permission?.crm?.lead.r == "3") {
        filter = `?q=SalespersonId=${
          authInfo?.EmpId
        };LeadStatus=${statusCode}&totalResults=true&orderBy=CreatedOn:desc&limit=${limit}&offset=${
          page * limit
        }`;
      } else if (permission?.crm?.lead.r == "4") {
        filter = `?q=LeadStatus IN ('${statusCode}') AND OrgId=${authInfo.DefaultOrgId} AND TenantId=${authInfo.TenantId} AND TerritoryId IN (${
          authInfo?.territory.length > 0 ? authInfo?.territory.join(",") : 0
        }) &totalResults=true&limit=${limit}&offset=${
          page * limit
        }&orderBy=CreatedOn:desc`;
      } else if (permission?.crm?.lead.r == "5") {
        let empIds = authInfo.employeeList.sub || [];
        filter = `?q=SalespersonId IN (${
          empIds.length > 0 ? empIds.join(",") : 0
        }) AND LeadStatus=${statusCode}&totalResults=true&orderBy=CreatedOn:desc&limit=${limit}&offset=${
          page * limit
        }`;
      }
      url = encodeURI(envConfig.BASE_API + Services.CRM_LEADS + filter);
    } else if (module == "deal") {
      filter = `?q=ApprStatus=${statusCode}&totalResults=true&orderBy=CreationDate:desc&limit=${limit}&offset=${
        page * limit
      }`;
      console.log("permission?.crm?", permission?.crm?.deal.r);
      //Filter based on department (territory)
      if (permission?.crm?.deal.r == "3") {
        filter = `?q=SalesPersonId=${
          authInfo?.EmpId
        };ApprStatus=${statusCode}&totalResults=true&orderBy=CreationDate:desc&limit=${limit}&offset=${
          page * limit
        }`;
      } else if (permission?.crm?.deal.r == "4") {
        filter = `?q=TerritoryId IN (${
          authInfo?.territory || 0
        }) AND ApprStatus IN ('${statusCode}') &totalResults=true&orderBy=CreationDate:desc&limit=${limit}&offset=${
          page * limit
        }`;
      } else if (permission?.crm?.deal.r == "5") {
        let empIds = authInfo.employeeList.sub || [];
        filter = `?q=SalesPersonId IN (${
          empIds.length > 0 ? empIds.join(",") : 0
        }) AND ApprStatus=${statusCode}&totalResults=true&orderBy=CreationDate:desc&limit=${limit}&offset=${
          page * limit
        }`;
      }
      url = encodeURI(envConfig.BASE_API + Services.CRM_DEALS + filter);
    } else if (module == "mylead") {
      filter = `?q=SalespersonId=${userId};LeadStatus=${statusCode}&orderBy=CreatedOn:desc&limit=${limit}&offset=${
        page * limit
      }`;
      url = envConfig.BASE_API + Services.CRM_LEADS + filter;
    } else if (module == "mydeal") {
      filter = `?q=SalesPersonId=${userId};ApprStatus=${statusCode}&orderBy=CreationDate:desc&limit=${limit}&offset=${
        page * limit
      }`;
      url = envConfig.BASE_API + Services.CRM_DEALS + filter;
    } else if (module == "quote") {
      filter = `?q=ApprStatus=${statusCode}&orderBy=CreatedOn:desc&limit=${limit}&offset=${
        page * limit
      }`;
      url = envConfig.BASE_API + Services.CRM_QUOTES + filter;
    }
    return url;
  };

  const getStatusData = (_) => {
    return new Promise((resolve, reject) => {
      const userData = common.authInfo();
      let url = getUrl(key, 5, userData.UserId, userData.UserName);
      const data = {
        url: url,
        type: "dynamic",
        method: "GET",
        auth: "token",
        //moreHead: { rfv: 2 },
        //cType: 4,
      };
      if(url.match("IN ")){
        data.moreHead = { rfv: 2 };
        data.cType = 4;
      }
      api.call(
        data,
        async (response) => {
          if (response?.data?.items?.length) {
            const list = [];
            response.data.items.forEach((item) => {
              let data = {};
              if (module == "campaign") {
                data = {
                  id: item.CampaignId,
                  name: item.CampaignName,
                  date: item.CreatedOn,
                  description: item.Description,
                  code: item.CampaignCode,
                  // delete: userPermissions.delete,
                };
              } else if (module == "enquiry") {
                data = {
                  id: item.ListId,
                  name: item.ListName,
                  date: item.CreatedOn,
                  description: item.Description,
                  code: item.ListType,
                  // delete: userPermissions.delete,
                };
              } else if (module == "lead" || module == "mylead") {
                data = {
                  id: item.LeadId,
                  name: item.LeadName,
                  date: item.CreatedOn,
                  description: item.SalesPersonName,
                  code: item.LeadSource,
                  accessId: item.SalespersonId,
                  createdBy: item.CreatedBy,
                };
              } else if (module == "deal" || module == "mydeal") {
                data = {
                  id: item.OpportunityId,
                  name: item.OpportunityName,
                  date: item.CreationDate,
                  description: item.SalesPersonName,
                  code: item.OpportunityType,
                  accessId: item.SalesPersonId,
                  createdBy: item.CreatedBy,
                };
              } else if (module == "task") {
                data = {
                  id: item.TaskId,
                  name: item.TaskName,
                  date: item.CreatedOn,
                  description: item.Description,
                  code: getPriority(item.Priority),
                  // delete: userPermissions.delete,
                };
              } else if (module == "quote") {
                data = {
                  id: item.CustQuotId,
                  name: item.Remarks,
                  date: item.CreatedOn,
                  description: item.PaymentTerms,
                  code: item.ApprStatus,
                  // delete: userPermissions.delete,
                };
              }
              list.push(data);
            });
            resolve({ list, hasMore: response?.data?.hasMore || false });
          } else {
            resolve({ list: [], hasMore: false });
          }
        },
        async (error) => {},
        (final) => {}
      );
    });
  };

  const getResultData = (index) => {
    return results[index];
  };

  const setResultData = (index, data) => {
    results.splice(index, 0, data);
    setResults((prev) => results);
  };

  const removeResultData = (index) => {
    results.splice(index, 1);
    setResults((prev) => results);
  };

  useEffect(() => {
    setResults([]);
  }, [module]);

  useEffect(() => {
    const getResults = async (_) => {
      setLoading(true);
      const { list, hasMore } = await getStatusData();
      setResults((prevResults) => {
        return [...new Set([...prevResults, ...list])];
      });
      setHasMore(hasMore);
      setLoading(false);
    };
    getResults();
  }, [page, module, key]);
  return {
    loading,
    results,
    hasMore,
    getResultData,
    setResultData,
    removeResultData,
  };
}
