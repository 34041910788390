import { Box, Grid, IconButton, Paper, Typography } from "@mui/material";
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { common } from "helpers";
import { Droppable } from "react-beautiful-dnd";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import { useNavigate } from "react-router-dom";
import useInfiniteScrolling from "./useInfinteScrolling";
import KanbanCard from "./KanbanCard";
import Loader from "./Loader";

const KanbanList = forwardRef((props, ref) => {
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#2A3543",
      color: "rgba(255,255,255, 0.5)",
      maxWidth: 300,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
      padding: 10,
    },
  }));

  const { module, status, idx } = props;
  const [pageNumber, setPageNumber] = useState(0);
  const isEduTech =
    common.isEduTech() && ["lead", "deal"].includes(props.module)
      ? true
      : false;

  const {
    results,
    hasMore,
    loading,
    getResultData,
    setResultData,
    removeResultData,
  } = useInfiniteScrolling(module, pageNumber, status.StatusCode);
  useImperativeHandle(ref, () => ({
    getData(index) {
      const result = getResultData(index);
      return result;
    },
    setData(index, data) {
      setResultData(index, data);
    },
    removeData(index) {
      removeResultData(index);
    },
  }));

  const navigate = useNavigate();

  const handleKanban = (id) => {
    if (props.module === "task") {
      navigate(`/task/edit/${id}?view=cardview`);
    } else {
      navigate(`/crm/${props.module}/details/${id}`);
    }
  };

  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );
  return (
    <Droppable
      droppableId={`${idx + "_" + status.StatusCode}`}
      key={status.StatusCode}
    >
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          <Paper variant="contained">
            <Box
              p={1}
              px={2}
              display="flex"
              justifyContent={"space-between"}
              style={{ borderBottom: "3px solid " + status.StatusColor }}
            >
              <Box display="flex">
                {/* <Typography className="header-title">
                  {status.StatusName}
                </Typography> */}
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography component={"span"}>
                        {status.StatusName}
                      </Typography>
                    </React.Fragment>
                  }
                >
                  <Typography className="header-title">
                    {status.StatusName}
                  </Typography>
                </HtmlTooltip>
                &nbsp;
                <span className="count">{results?.length || 0}</span>
              </Box>
              {!isEduTech && props?.add != false && (
                <IconButton
                  className="kanban-plus"
                  onClick={() => props.changeForm(true)}
                >
                  <img src={common.loadImg("addKanban.svg")} alt="add" />
                </IconButton>
              )}
            </Box>
            <Box p={2} className="kanban-column-body">
              <Grid container direction="column">
                {pageNumber === 0 && loading && <Loader />}
                {results?.length > 0 &&
                  results?.map((listItem, i) => {
                    if (results?.length === i + 1) {
                      return (
                        <div key={i} ref={lastElementRef}>
                          <KanbanCard
                            listItem={listItem}
                            module={props.module}
                            handleKanban={handleKanban}
                            idx={i}
                            status={status}
                          />
                        </div>
                      );
                    } else {
                      return (
                        <KanbanCard
                          key={i}
                          listItem={listItem}
                          module={props.module}
                          handleKanban={handleKanban}
                          idx={i}
                          status={status}
                        />
                      );
                    }
                  })}
                {results?.length === 0 && !loading && (
                  <p className="camp-sub-name mx-auto">No data found</p>
                )}
                {pageNumber > 0 && loading && <Loader />}
              </Grid>
            </Box>
          </Paper>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
});

export default KanbanList;
