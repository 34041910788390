import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import Select, { components } from "react-select";
// api
import { common, envConfig, api } from "helpers";
import * as Services from "helpers/service";

const FieldLookup = (props) => {
  const [isLoading, setIsLoading] = useState(props.isRender);
  const [isRender, setIsRender] = useState(props.isRender);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [resultList, setResultList] = useState([]);
  const [userInfo] = useState(common.userInfo());
  const finderList = {
    Lookup: {
      url: `${envConfig.BASE_API}${Services.LOOKUPS_LOV}?fields=Code,Name&finder=FindLookupVals;pLookupCode=${props.type}`,
      query: ``,
      limitOffset: `&limit=10000&offset=0&totalResults=true`,
      filter: ["Name", "Code"],
    },
  };
  // init
  useEffect(() => {
    if (isRender) {
      let find = props.lookup ? "Lookup" : props.type;
      let finder = finderList[find];
      let url = `${finder.url}${finder.limitOffset}`;
      let data = {
        url: url,
        type: "dynamic",
        method: "GET",
        auth: "token",
        moreHead: { rfv: 2 },
        cType: 4,
      };
      api.call(
        data,
        (response) => {
          if (response) {
            setResultList(
              common.getOptionsOnlyData(
                response.data.items,
                finder.filter[0],
                finder.filter[1]
              )
            );
            if(props?.setParent){
              props?.setParent(common.getOptionsOnlyData(
                response.data.items,
                finder.filter[0],
                finder.filter[1]
              ))
            }
            if (!props.isRender) {
              setIsMenuOpen(true);
              setIsFocus(true);
            }
          }
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(false);
        },
        (final) => {}
      );
    }
  }, [isRender]);

  // handler
  const onSelect = (data) => {
    props.onSelect(data, props.name);
  };

  const onFocus = () => {
    if (!isRender) {
      setIsLoading(true);
      setIsRender(true);
    }
  };
  // Floating React select
  const { ValueContainer, Placeholder } = components;

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };
  return isLoading ? (
    <Form.Control type="text" defaultValue={"Loading..."} readOnly={true} className="loading-text-form-control"/>
  ) : (
    <Select
      isClearable={true}
      options={resultList}
      value={props.value}
      onChange={(data) => onSelect(data)}
      onFocus={onFocus}
      menuIsOpen={isMenuOpen}
      onMenuOpen={() => setIsMenuOpen(true)}
      onMenuClose={() => setIsMenuOpen(false)}
      autoFocus={isFocus}
      placeholder={props.placeholder}
      components={{
        ValueContainer: CustomValueContainer,
      }}
      styles={{
        container: (base, state) => ({
          ...base,
          marginTop: 0,
        }),
        valueContainer: (base, state) => ({
          ...base,
          overflow: "visible",
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        placeholder: (base, state) => ({
          ...base,
          position: "absolute",
          top: state.hasValue || state.selectProps.inputValue ? -15 : "10%",
          transition: "top 0.1s, font-size 0.1s",
          fontSize: state.hasValue || state.selectProps.inputValue ? 11 : 13,
          color: "#9daabb",
        }),
        
      }}
    />
  );
};
export default FieldLookup;
