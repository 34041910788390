import React, { memo } from "react";
import Paper from "@mui/material/Paper";
import { useOutletContext } from "react-router-dom";
import AccessDenied from "elements/AccessDenied";
import StaticTale from "elements/staticTable";
import party from "assets/files/static/party.json";
import { common } from "helpers";

const Party = memo(() => {
  const [permission] = useOutletContext();
  return (
    <div className="default-bg">
      <div style={{ padding: "20px" }}>
        {common.givenPermission(permission,'vendor:parties','read') ? <AccessDenied /> : <Paper
          sx={{ width: "100%", mb: 2 }}
          className={`container responsive-table p-0`}
        >
          <StaticTale module={party} 
          add={!common.givenPermission(permission,'vendor:parties','add')} 
          update={!common.givenPermission(permission,'vendor:parties','update')}
          delete={!common.givenPermission(permission,'vendor:parties','delete')}
          safariclassName={"safari-style"}
          />
        </Paper>}
        
      </div>
    </div>
  );
});

export { Party };
