import { useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Checkbox,
  RadioGroup,
  FormControlLabel,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
//import Select from "react-select";
import { common } from "helpers";
import React from "react";
import { Radio } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
//import { DatePicker } from '@mui/x-date-pickers/DatePicker';

function FormInputPoc() {
  const navigate = useNavigate();
  //Goto previous page
  const goto = (e) => {
    e.preventDefault();
    navigate("/crm/view/5");
  };

  // Multidatepicker
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [values, setValues] = useState([today, tomorrow]);
  const [age, setAge] = React.useState("");
  const [isActive, setIsActive] = useState(false);
  const [isActive1, setIsActive1] = useState(false);
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const [value, setValue] = React.useState(null);
  return (
    <Box px={5} py={4}>
      <Paper variant="contained dynamic-form-wrap" square>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Box py={1} px={1} className="icon-back">
                  <IconButton>
                    <img
                      src={common.loadImg("backArrowKanban.svg")}
                      alt="back"
                    />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item>
                <Typography className="detail-title">Add Form</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Box px={2}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <Box className="verical-divider">
                    <Divider orientation="vertical" />
                  </Box>
                </Grid>
                <Grid item>
                  <Box>
                    <Button className="save-note" onClick={(e) => goto(e)}>
                      Save Changes
                    </Button>
                  </Box>
                </Grid>
                <Grid item>
                  <Box className="verical-divider">
                    <Divider orientation="vertical" />
                  </Box>
                </Grid>
                <Grid item>
                  <Box className="revert-close">
                    <Button className="save-note" onClick={(e) => goto(e)}>
                      Revert and Close
                      <img
                        src={common.loadImg("closeKanban.svg")}
                        alt="close"
                      />
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Box>
          <Divider />
        </Box>
        <Box p={2} px={5} className="form-wrap">
          <Typography className="info-title mt-3">
            Campaign Information
          </Typography>
          <Box mt={3}>
            <Divider />
          </Box>
          <Box mt={2} className="camp-edit meet-table">
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child, &:last-child": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className="body-th"
                      style={{ width: "50%" }}
                    >
                      <Box className="filled-box">
                        <TextField
                          id="filled-basic"
                          label="Campaign Name"
                          variant="filled"
                        />
                      </Box>
                    </TableCell>
                    <TableCell className="body-th" style={{ width: "50%" }}>
                      <Box>
                        <div>
                          <div className="form-floating">
                            <textarea
                              className="form-control textarea"
                              placeholder="Leave a comment here"
                              id="floatingTextarea"
                            ></textarea>
                            <label
                              for="floatingTextarea"
                              className="floating-label"
                            >
                              Campaign Information
                            </label>
                          </div>
                        </div>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child, &:last-child": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className="body-th"
                      style={{ width: "50%" }}
                    >
                      <Box className="text-box custom-select">
                        <FormControl fullWidth className="select-float-label">
                          <InputLabel id="select" className="floating-label">
                            Campaign Option
                          </InputLabel>
                          <Select
                            labelId="select"
                            id="select"
                            value={age}
                            label="Campaign Option"
                            onChange={handleChange}
                          >
                            <MenuItem value={10}>Option 1</MenuItem>
                            <MenuItem value={20}>Option 2</MenuItem>
                            <MenuItem value={30}>Option 3</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="body-th"
                      style={{ width: "50%" }}
                    >
                      <Box className="text-box" id="float-label">
                        <label  className={ isActive ? "Active text-label ml-4" : "text-label ml-4"}>Campaign Date</label>
                        <Box className="ml-1 input-date-picker">
                          <DatePicker
                            value="{value}"
                            format="MM/DD/YYYY"
                            render={<InputIcon />}
                            onChange={setIsActive}
                          />
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child, &:last-child": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className="body-th"
                      sx={{ width: { xs: "50%", lg: "50%" } }}
                    >
                      <Box className="text-box"  id="float-label1">
                      <label  className={ isActive1 ? "Active text-label ml-4" : "text-label ml-4"}>Campaign Time</label>
                        <Box className="ml-1 input-date-picker">
                          <DatePicker
                            value="{value}"
                            format="MM/DD/YYYY HH:mm A"
                            plugins={[
                              <TimePicker
                                hideSeconds
                                position="bottom"
                                className="ml-3"
                              />,
                            ]}
                            render={<InputIcon />}
                            onChange={setIsActive1}
                          />
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="body-th"
                      sx={{ width: { xs: "50%", lg: "50%" } }}
                    >
                      <Box className="text-box">
                        <label className="text-label ml-4">
                          Campaign Country
                        </label>
                        <PhoneInput country={"lk"} className="ml-3" />
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child, &:last-child": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className="body-th"
                      style={{ width: "50%" }}
                    >
                      <FormControlLabel
                        className="dynamic-checkbox"
                        value="female"
                        control={<Checkbox />}
                        label="Keep me signed in"
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="body-th"
                      style={{ width: "50%" }}
                    >
                      <FormControlLabel
                        className="dynamic-checkbox"
                        value="female"
                        control={<Radio />}
                        label="Male"
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}

export default FormInputPoc;
