import { Box } from "@mui/material";
import React, { memo } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import Document from "../../assets/img/kanban/lightDoc.svg";
// import ActiveForward from "../../assets/img/tasks/a.svg";
import { common } from "helpers";

const ListView = memo(() => {
  return (
    <Box px={2} className="list-view">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="default-left">Status</TableCell>
              <TableCell align="left">Task</TableCell>
              <TableCell align="center">Description</TableCell>
              <TableCell align="left">Assigned for</TableCell>
              <TableCell align="left">Priority</TableCell>
              <TableCell align="left">Deadline</TableCell>
              <TableCell align="left">created on<img src={common.loadImg("down-arrow-icon.svg")} className="ms-2" /></TableCell>
              <TableCell align="left">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": {
                  border: "solid 0.3px #ebf0f4",
                },
              }}
            >
              <TableCell scope="row" className="th-sts-pending">
                Pending
              </TableCell>
              <TableCell align="left" className="th-task">
                Contact the agency regarding the campaign
              </TableCell>
              <TableCell align="center">
                <img src={common.loadImg("lightDoc.svg")} alt="documents" />
              </TableCell>
              <TableCell align="left" className="th-assign">
                today
              </TableCell>
              <TableCell align="left">
                <Box className="th-priority minor">Minor</Box>
              </TableCell>
              <TableCell align="left" className="th-assign">
                Aug 21
              </TableCell>
              <TableCell align="left" className="th-assign">
                20 Feb 2022
              </TableCell>
              <TableCell align="center">
                <Box>
                    <img
                      src={common.loadImg("activeForward.svg")}
                      alt="forward"
                    />
                </Box>
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": {
                  border: "solid 0.3px #ebf0f4",
                },
              }}
            >
              <TableCell scope="row" className="th-sts-in-prog">
                In Progress
              </TableCell>
              <TableCell align="left" className="th-task">
                Contact the agency regarding the campaign
              </TableCell>
              <TableCell align="center">
                <img src={common.loadImg("lightDoc.svg")} alt="documents" />
              </TableCell>
              <TableCell align="left" className="th-assign">
                today
              </TableCell>
              <TableCell align="left">
                <Box className="th-priority in-prog">Minor</Box>
              </TableCell>
              <TableCell align="left" className="th-assign">
                Aug 21
              </TableCell>
              <TableCell align="left" className="th-assign">
                20 Feb 2022
              </TableCell>
              <TableCell align="center">
                <Box>
                  
                    <img
                      src={common.loadImg("activeForward.svg")}
                      alt="forward"
                    />
                </Box>
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": {
                  border: "solid 0.3px #ebf0f4",
                },
              }}
            >
              <TableCell scope="row" className="th-sts-feedback">
                In Progress
              </TableCell>
              <TableCell align="left" className="th-task">
                Contact the agency regarding the campaign
              </TableCell>
              <TableCell align="center">
                <img src={common.loadImg("lightDoc.svg")} alt="documents" />
              </TableCell>
              <TableCell align="left" className="th-assign">
                today
              </TableCell>
              <TableCell align="left">
                <Box className="th-priority high">Minor</Box>
              </TableCell>
              <TableCell align="left" className="th-assign">
                Aug 21
              </TableCell>
              <TableCell align="left" className="th-assign">
                20 Feb 2022
              </TableCell>
              <TableCell align="center">
                <Box>
                    <img
                      src={common.loadImg("activeForward.svg")}
                      alt="forward"
                    />
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row" className="th-sts-closed">
                In Progress
              </TableCell>
              <TableCell align="left" className="th-task">
                Contact the agency regarding the campaign
              </TableCell>
              <TableCell align="center">
                <img src={common.loadImg("lightDoc.svg")} alt="documents" />
              </TableCell>
              <TableCell align="left" className="th-assign">
                today
              </TableCell>
              <TableCell align="left">
                <Box className="th-priority high">Minor</Box>
              </TableCell>
              <TableCell align="left" className="th-assign">
                Aug 21
              </TableCell>
              <TableCell align="left" className="th-assign">
                20 Feb 2022
              </TableCell>
              <TableCell align="center">
                <Box>
                 
                    <img
                      src={common.loadImg("activeForward.svg")}
                      alt="forward"
                    />
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
});

export default ListView;
