import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Typography,
  Popover,
} from "@mui/material";
import React, { memo } from "react";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { common } from "helpers";

const OverView = memo(() => {
  const [age, setAge] = React.useState("10");

  const handleChangeItem = (event) => {
    setAge(event.target.value);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickExport = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const openNotification = Boolean(anchorEl);
  const id = openNotification ? "simple-popover" : undefined;
  return (
    <div className="tasks">
      <Box>
        <Container maxWidth="lg" className="relative task">
          <Box className="fliter-box">
            <Button className="filter">
              Filter &nbsp;
              <img
                src={common.loadImg("filterkanban.svg")}
                alt="filter"
                className="pl-4"
              />
            </Button>
          </Box>
          <Grid container direction="column" spacing={4}>
            {[1, 2, 3].map(() => {
              return (
                <Grid item>
                  <Box px={1}>
                    <Grid container spacing={2} justifyContent="space-between">
                      <Grid item>
                        <Typography className="over-view-date">
                          For Today&nbsp;
                          <Typography
                            component="span"
                            className="over-view-count"
                          >
                            03
                          </Typography>
                        </Typography>
                      </Grid>
                      <Grid item></Grid>
                    </Grid>
                    <Box mt={2}>
                      <Grid container direction="column" spacing={2}>
                        <Grid item>
                          <Box className="tasks-paper">
                            <Paper variant="outlined">
                              <Box>
                                <Grid container>
                                  <Grid item>
                                    <Box px={2} py={1} pr={10}>
                                      <p className="task">Task</p>
                                      <Typography className="task-detail mt-3">
                                        Contact the agency regarding the
                                        campaign
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Box className="vertical-divider-auto">
                                      <Divider orientation="vertical" />
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Box
                                      px={2}
                                      py={1}
                                      width="150px"
                                      display="flex"
                                      flexDirection="column"
                                      justifyContent="center"
                                      alignItems="center"
                                    >
                                      <p className="task center">Priority</p>
                                      <Box
                                        className="sts-over-view-high"
                                        px={4}
                                        py={0.5}
                                        width="86px"
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                      >
                                        High
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Box className="vertical-divider-auto">
                                      <Divider orientation="vertical" />
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Box px={2} py={1}>
                                      <p className="task center">Deadline</p>
                                      <Box
                                        className="deadline-date"
                                        px={4}
                                        py={0.5}
                                        mt={1}
                                      >
                                        Wed Aug 26
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Box className="vertical-divider-auto">
                                      <Divider orientation="vertical" />
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Box px={4} py={1} pr={8}>
                                      <p className="task">Status</p>
                                      <Box className="deadline-date">
                                        <FormControl
                                          sx={{
                                            minWidth: { xs: 220, lg: 162 },
                                          }}
                                          size="small"
                                          className="dropdown-tasks"
                                        >
                                          <Select
                                            value={age}
                                            displayEmpty
                                            onChange={handleChangeItem}
                                          >
                                            <MenuItem
                                              value={10}
                                              className="filter-text"
                                            >
                                              <p className="in-prog-sts" /> In
                                              Progress
                                            </MenuItem>
                                            <MenuItem
                                              value={20}
                                              className="filter-text"
                                            >
                                              <p className="in-prog-sts" /> Hold
                                            </MenuItem>
                                            <MenuItem
                                              value={30}
                                              className="filter-text"
                                            >
                                              <p className="in-prog-sts" />{" "}
                                              Closed
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Box className="vertical-divider-auto">
                                      <Divider orientation="vertical" />
                                    </Box>
                                  </Grid>
                                  <Grid item className="flex-grow">
                                    <Box
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                      className="more-place"
                                    >
                                      <IconButton className="more-icon">
                                        <MoreHorizIcon />
                                      </IconButton>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Paper>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box className="tasks-paper">
                            <Paper variant="outlined">
                              <Box>
                                <Grid container>
                                  <Grid item>
                                    <Box px={2} py={1} pr={10}>
                                      <p className="task">Task</p>
                                      <Typography className="task-detail mt-3">
                                        Contact the agency regarding the
                                        campaign
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Box className="vertical-divider-auto">
                                      <Divider orientation="vertical" />
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Box
                                      px={2}
                                      py={1}
                                      width="150px"
                                      display="flex"
                                      flexDirection="column"
                                      justifyContent={"center"}
                                      alignItems="center"
                                    >
                                      <p className="task center">Priority</p>
                                      <Box
                                        className="sts-over-view-mid"
                                        px={4}
                                        py={0.5}
                                        width="86px"
                                        display="flex"
                                        justifyContent="center"
                                      >
                                        Mid
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Box className="vertical-divider-auto">
                                      <Divider orientation="vertical" />
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Box px={2} py={1}>
                                      <p className="task center">Deadline</p>
                                      <Box
                                        className="deadline-date"
                                        px={4}
                                        py={0.5}
                                        mt={1}
                                      >
                                        Wed Aug 26
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Box className="vertical-divider-auto">
                                      <Divider orientation="vertical" />
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Box px={4} py={1} pr={8}>
                                      <p className="task">Status</p>
                                      <Box className="deadline-date">
                                        <FormControl
                                          sx={{
                                            minWidth: { xs: 220, lg: 162 },
                                          }}
                                          size="small"
                                          className="dropdown-tasks"
                                        >
                                          <Select
                                            value={age}
                                            displayEmpty
                                            onChange={handleChangeItem}
                                          >
                                            <MenuItem
                                              value={10}
                                              className="filter-text"
                                            >
                                              <p className="feedback-sts" />{" "}
                                              Feedback
                                            </MenuItem>
                                            <MenuItem
                                              value={20}
                                              className="filter-text"
                                            >
                                              <p className="feedback-sts" />{" "}
                                              Hold
                                            </MenuItem>
                                            <MenuItem
                                              value={30}
                                              className="filter-text"
                                            >
                                              <p className="feedback-sts" />{" "}
                                              Closed
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Box className="vertical-divider-auto">
                                      <Divider orientation="vertical" />
                                    </Box>
                                  </Grid>
                                  <Grid item className="flex-grow">
                                    <Box
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                      className="more-place"
                                    >
                                      <IconButton className="more-icon">
                                        <MoreHorizIcon />
                                      </IconButton>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Paper>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box className="tasks-paper">
                            <Paper variant="outlined">
                              <Box>
                                <Grid container>
                                  <Grid item>
                                    <Box px={2} py={1} pr={10}>
                                      <p className="task">Task</p>
                                      <Typography className="task-detail mt-3">
                                        Contact the agency regarding the
                                        campaign
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Box className="vertical-divider-auto">
                                      <Divider orientation="vertical" />
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Box
                                      px={2}
                                      py={1}
                                      width="150px"
                                      display="flex"
                                      flexDirection={"column"}
                                      justifyContent="center"
                                      alignItems="center"
                                    >
                                      <p className="task center">Priority</p>
                                      <Box
                                        className="sts-over-view-urgent"
                                        px={4}
                                        py={0.5}
                                        width="86px"
                                        display="flex"
                                        justifyContent="center"
                                      >
                                        Urgent
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Box className="vertical-divider-auto">
                                      <Divider orientation="vertical" />
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Box px={2} py={1}>
                                      <p className="task center">Deadline</p>
                                      <Box
                                        className="deadline-date"
                                        px={4}
                                        py={0.5}
                                        mt={1}
                                      >
                                        Wed Aug 26
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Box className="vertical-divider-auto">
                                      <Divider orientation="vertical" />
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Box px={4} py={1} pr={8}>
                                      <p className="task">Status</p>
                                      <Box className="deadline-date">
                                        <FormControl
                                          sx={{
                                            minWidth: { xs: 220, lg: 162 },
                                          }}
                                          size="small"
                                          className="dropdown-tasks"
                                        >
                                          <Select
                                            value={age}
                                            displayEmpty
                                            onChange={handleChangeItem}
                                          >
                                            <MenuItem
                                              value={10}
                                              className="filter-text"
                                            >
                                              <p className="feedback-sts" />{" "}
                                              Feedback
                                            </MenuItem>
                                            <MenuItem
                                              value={20}
                                              className="filter-text"
                                            >
                                              <p className="feedback-sts" />{" "}
                                              Hold
                                            </MenuItem>
                                            <MenuItem
                                              value={30}
                                              className="filter-text"
                                            >
                                              <p className="feedback-sts" />{" "}
                                              Closed
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Box className="vertical-divider-auto">
                                      <Divider orientation="vertical" />
                                    </Box>
                                  </Grid>
                                  <Grid item className="flex-grow">
                                    <Box
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                      className="more-place"
                                    >
                                      <IconButton className="more-icon" onClick={handleClickExport}>
                                        <MoreHorizIcon />
                                      </IconButton>
                                      <Popover
                          id={id}
                          open={openNotification}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          className="notify-pop"
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                        >
                          <Box className="task-notify-box" p={1}>
                            <Grid
                              container
                              justifyContent="flex-end"
                            >
                              
                              <Grid item>
                                <IconButton onClick={handleClose}>
                                  <img
                                    src={common.loadImg("dateClosekanban.svg")}
                                    alt="close"
                                  />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Box>
                          <Box>
                            <Divider />
                          </Box>
                          <Box>
                            <Grid
                              container
                              direction="column"
                              className="pointer"
                            >
                              
                                  <Grid item>
                                    <Box p={2} py={3}>
                                      <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        spacing={1}
                                      >
                                        <Grid item>
                                          <Typography className="task-assign ">
                                            <img src={common.loadImg("view-icon.svg")} className="ms-2 me-2"/>
                                          </Typography>
                                        </Grid>
                                        <Grid item>
                                          <Typography className="task-assign ">
                                            View
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                      
                                    </Box>
                                    <Box>
                                      <Divider />
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Box p={2} py={3}>
                                      <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        spacing={1}
                                      >
                                        <Grid item>
                                          <Typography className="task-assign ">
                                            <img src={common.loadImg("edit-icon.svg")} className="ms-2 me-1" />
                                          </Typography>
                                        </Grid>
                                        <Grid item>
                                          <Typography className="task-assign ">
                                            Edit
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                      
                                    </Box>
                                    <Box>
                                      <Divider />
                                    </Box>
                                  </Grid>
                                  <Grid item >
                                    <Box p={2} py={3}>
                                      <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        spacing={1}
                                      >
                                        <Grid item>
                                          <Typography className="task-assign ">
                                            <img src={common.loadImg("del-icon.svg")}  className="ms-2 me-2"/>
                                          </Typography>
                                        </Grid>
                                        <Grid item>
                                          <Typography className="task-assign ">
                                            Delete
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                      
                                    </Box>
                                    <Box>
                                      <Divider />
                                    </Box>
                                  </Grid>
                               
                              
                            </Grid>
                          </Box>
                        </Popover>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Paper>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </Box>
    </div>
  );
});

export default OverView;
