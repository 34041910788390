import React from "react";
import Cropper from "react-easy-crop";
import { Button, Modal } from "react-bootstrap";
import { getCroppedImg } from "./utilities";

class CropIt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModal: false,
      isLoading: false,
      isImgLoading: false,
      // cropper
      imageSrc: "",
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 4 / 3,
      cropSize: { width: 150, height: 150 },
      initialCropSize: { width: 150, height: 150, x: 100, y: 100 },
      croppedAreaPixels: null,
      croppedImage: "",
    };
  }

  onFileChange = async (e, cropSize) => {
    const { initialCropSize } = this.state;
    if (e.target.files && e.target.files.length > 0) {
      this.setState({
        isModal: true,
        isImgLoading: true,
      });
      const file = e.target.files[0];
      const fileSize = await this.getSize(file);
      let imageDataUrl = await this.readFile(file);
      initialCropSize.width = cropSize.width;
      initialCropSize.height = cropSize.height;
      initialCropSize.x = fileSize.width / 2;
      initialCropSize.y = fileSize.height / 2;

      this.setState({
        imageSrc: imageDataUrl,
        cropSize,
        initialCropSize,
        isImgLoading: false,
      });
    }
  };

  showModal(status) {
    this.setState({
      isModal: status,
    });
  }

  showLoader(status) {
    this.setState({
      isLoading: status,
    });
  }

  showImgLoader(status) {
    this.setState({
      isImgLoading: status,
    });
  }

  // cropper props
  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({
      croppedAreaPixels,
    });
  };

  cropImage = async () => {
    const { imageSrc, croppedAreaPixels, rotation } = this.state;
    this.setState({ isLoading: true });
    const croppedImage = await getCroppedImg(
      imageSrc,
      croppedAreaPixels,
      rotation
    );
    let data = {
      status: "success",
      croppedImage,
    };
    this.props.onComplete(data);
    this.setState({
      isModal: false,
      isLoading: false,
    });
  };

  readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  getSize(file) {
    return new Promise((resolve) => {
      let img = new Image();
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
    });
  }

  dataURLtoFile(dataurl, filename = "file.jpeg") {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    return croppedImage;
  }

  cancelCrop() {
    this.setState({
      isModal: false,
      isLoading: false,
      isImgLoading: false,
      // cropper
      imageSrc: "",
    });
    this.props.onComplete({ status: "cancel", croppedImage: "" });
  }

  render() {
    const {
      imageSrc,
      cropSize,
      initialCropSize,
      isModal,
      isLoading,
      isImgLoading,
    } = this.state;
    return (
      <>
        <Modal
          size="xl"
          show={isModal}
          onHide={() => this.cancelCrop(false)}
          className="mt-5"
        >
          <Modal.Header closeButton>
            <Modal.Title>Upload Image</Modal.Title>
          </Modal.Header>
          <Modal.Body className="cropApp">
            {isImgLoading}
            {imageSrc != "" && isImgLoading == false && (
              <div className="cropContainer" height={200}>
                <Cropper
                  image={this.state.imageSrc}
                  crop={this.state.crop}
                  zoom={this.state.zoom}
                  aspect={this.state.aspect}
                  cropSize={cropSize}
                  cropShape={this.props.shape}
                  initialCroppedAreaPixels={initialCropSize}
                  zoomWithScroll={false}
                  onCropChange={this.onCropChange}
                  onCropComplete={this.onCropComplete}
                  onZoomChange={this.onZoomChange}
                />
              </div>
            )}
          </Modal.Body>
          <div className="d-flex justify-content-center">
            <input
              type="range"
              value={this.state.zoom}
              min={1}
              max={6}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e) => {
                this.setState({ ...this.state, ["zoom"]: e.target.value });
              }}
              className="zoom-range"
            />
          </div>
          <Modal.Footer className="cropFooter">
            <Button
              className="config-btn"
              onClick={this.cropImage}
              disabled={isLoading}
            >
              {isLoading ? "Cropping..." : "Crop Image"}
            </Button>
            <Button
              className="config-cancel"
              disabled={isLoading}
              onClick={() => this.cancelCrop(false)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export { CropIt };
