import React from "react";
import { common } from "helpers";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import Popover from "@mui/material/Popover";
import {
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }} className="p-2">
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Employees = () => {
  const [value, setValue] = React.useState(0);
  const [rightAnchorEl, setRightAnchorEl] = React.useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const detailsMoreClick = (event) => {
    setRightAnchorEl(event.currentTarget);
  };
  const detailsCloseClick = () => {
    setRightAnchorEl(null);
  };

  const rightMoreOpen = Boolean(rightAnchorEl);

  return (
    <Box px={5} mt={3} className=" template-box-border">
      <div className="row">
        {true && (
          <div className="col-md-5 mt-2 ">
            <div className="card border-color-F2F9FD">
              <div className="card-body p-0">
                <div className="row p-3">
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Link to="/setting/view/hrms">
                        <img
                          alt={"back"}
                          className="mx-2"
                          src={common.loadImg(
                            "iconly-light-arrow-down-circle.svg"
                          )}
                        />
                      </Link>
                    </Grid>
                    <Grid item>
                      <Typography className="location-detail-title">
                        Employees
                      </Typography>
                    </Grid>
                    <Grid item>
                      <span className="location-count-label">04</span>
                    </Grid>
                    <Grid item>
                      <Box display="flex" alignItems="center">
                        <Link to="#" className="text-none">
                          <span className="mx-2 add-new">Add New</span>
                        </Link>

                        <div className="add-new-button d-flex align-items-center justify-content-center">
                          <Link to="#" className="text-none">
                            <span className="d-flex align-items-center plus-icon">
                              +
                            </span>
                          </Link>
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                </div>
                <div className="d-flex bg-F2F9FD p-1">
                  <Box className="filter-search" width="100%">
                    <FormControl sx={{ width: "100%" }}>
                      <OutlinedInput
                        id="search"
                        placeholder={"Search"}
                        endAdornment={
                          <InputAdornment position="end">
                            <img
                              src={common.loadImg("common-search.svg")}
                              alt="search"
                            />
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                      />
                    </FormControl>
                  </Box>
                  <img
                    src={common.loadImg("emp-filter.svg")}
                    alt={"filter"}
                    className="my-auto mr-3"
                    height={34}
                  />
                </div>
                <div className="bg-white">
                  {/* Profile */}
                  <Grid container direction="column">
                    {[1, 2, 3].map((_, i) => {
                      return (
                        <Grid item>
                          <Box className="pointer">
                            <Grid container alignItems="center">
                              <Grid item xs={2} className="pl-2">
                                <Box px={2} py={2}>
                                  <Box className="img-box">AB</Box>
                                </Box>
                                {i === 0 ? (
                                  <div className="active active-location"></div>
                                ) : null}
                              </Grid>
                              <Grid item xs={1}>
                                <Box display="flex" justifyContent="center">
                                  <Box height="85px" width="2px">
                                    <Divider orientation="vertical" />
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item xs={9}>
                                <Box py={0.6} px={2}>
                                  <Grid
                                    container
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <Grid item>
                                      <div className="mt-2 font-weight-bold">
                                        <h6 className="setting-card-title fz-16 font-weight-bold">
                                          Adam Brody
                                        </h6>
                                        <p className="setting-card-content fz-14">
                                          Chief Design Officer
                                        </p>
                                      </div>
                                    </Grid>
                                    <Grid item>
                                      {/* <Box> */}
                                      {/* <IconButton className="more-icon"> */}
                                      <div className="my-auto pe-4 ">
                                        <MoreHorizOutlinedIcon
                                          className="pointer"
                                          aria-describedby={
                                            "right-more-popover"
                                          }
                                          onClick={detailsMoreClick}
                                        />

                                        <Popover
                                          id={"right-more-popover"}
                                          open={rightMoreOpen}
                                          anchorEl={rightAnchorEl}
                                          onClose={detailsCloseClick}
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right",
                                          }}
                                          transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                          }}
                                        >
                                          {["menu 1", "menu 2"].map((x) => (
                                            <Typography
                                              key={x}
                                              sx={{ p: 2 }}
                                              className="text-capitalize"
                                            >
                                              {x}
                                            </Typography>
                                          ))}
                                        </Popover>
                                      </div>
                                      {/* </IconButton>
                                      </Box> */}
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Grid>
                            </Grid>
                            <Box>
                              <Divider />
                            </Box>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        )}
        {true && (
          <div className="col-md-7 mt-2 ">
            <div className="card border-color-F2F9FD">
              <div>
                <Box p={2}>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Typography className="single-emp-title">
                        Adam Brody’s Settings{" "}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <div className="my-auto pe-4">
                        <MoreHorizOutlinedIcon
                          className="pointer"
                          aria-describedby={"right-more-popover"}
                          onClick={detailsMoreClick}
                        />

                        <Popover
                          id={"right-more-popover"}
                          open={rightMoreOpen}
                          anchorEl={rightAnchorEl}
                          onClose={detailsCloseClick}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          {["menu 1", "menu 2"].map((x) => (
                            <Typography
                              key={x}
                              sx={{ p: 2 }}
                              className="text-capitalize"
                            >
                              {x}
                            </Typography>
                          ))}
                        </Popover>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Divider />
                </Box>

                <Box sx={{ width: "100%" }} py={2} className="pb-0 emp-tabs">
                  <Box>
                    <Tabs
                      TabIndicatorProps={{
                        style: {
                          objectFit: "contain",
                          boxShadow: "-2px 2px 6px 0 rgba(54, 172, 227, 0.47)",
                          backgroundColor: "#36ace3",
                          fontWeight: "bold",
                          height: "4px",
                          marginBottom: 0,
                        },
                      }}
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example "
                    >
                      <Tab
                        label="Details"
                        {...a11yProps(0)}
                        className={"emp-tab-text"}
                      />
                      <Tab
                        label="Permission"
                        {...a11yProps(1)}
                        className={"emp-tab-text"}
                      />
                      <Tab
                        label="Performance"
                        {...a11yProps(2)}
                        className={"emp-tab-text"}
                      />
                      <Tab
                        label="Financials"
                        {...a11yProps(2)}
                        className={"emp-tab-text"}
                      />
                      <Tab
                        label="Documents"
                        {...a11yProps(2)}
                        className={"emp-tab-text"}
                      />
                    </Tabs>
                  </Box>
                </Box>
              </div>
            </div>
            <div className="settings-secondary" />
            <div>
              <TabPanel value={value} index={0}>
                <div
                  className="row p-0 bottom-all-side-divider"
                  style={{ backgroundColor: "white" }}
                >
                  <div className="col-md-6  pt-3 pb-3 bottom-border-divider">
                    <h6 className="setting-card-content">Employee Number</h6>
                    <p className="setting-card-title fz-14">19485620</p>
                  </div>
                  <div className="col-md-6  pt-3 pb-3 bottom-border-divider bottom-left-side-divider">
                    <h6 className="setting-card-content mb-3">Status</h6>
                    <span
                      style={{ backgroundColor: "#B6E9C5", color: "#131b23" }}
                      className="status-oval sts-active fz-16"
                    >
                      Working - Active{" "}
                    </span>
                  </div>
                  <div className="col-md-6  pt-3 pb-3 bottom-border-divider">
                    <h6 className="setting-card-content">First Name</h6>
                    <p className="setting-card-title fz-14">Adam</p>
                  </div>
                  <div className="col-md-6  pt-3 pb-3 bottom-border-divider bottom-left-side-divider">
                    <h6 className="setting-card-content">Last Name</h6>
                    <p className="setting-card-title fz-14">Brody</p>
                  </div>
                  <div className="col-md-6  pt-3 pb-3 bottom-border-divider">
                    <h6 className="setting-card-content">Gender</h6>
                    <p className="setting-card-title fz-14">Male</p>
                  </div>
                  <div className="col-md-6  pt-3 pb-3 bottom-border-divider bottom-left-side-divider">
                    <h6 className="setting-card-content">Email Address</h6>
                    <a href="#0" className="a-link fz-14">
                      Adam@novelwall.com
                    </a>
                  </div>
                  <div className="col-md-6  pt-3 pb-3 bottom-border-divider">
                    <h6 className="setting-card-content">Mobile Number</h6>
                    <a href="#0" className="a-link fz-14">
                      +00 0000 0000
                    </a>
                  </div>
                  <div className="col-md-6  pt-3 pb-3 bottom-border-divider bottom-left-side-divider">
                    <h6 className="setting-card-content">Joined on </h6>
                    <p className="setting-card-title fz-14">Aug 25 2009</p>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                Item Two
              </TabPanel>
              <TabPanel value={value} index={2}>
                Item Three
              </TabPanel>
            </div>
          </div>
        )}
      </div>
    </Box>
  );
};

export { Employees };
