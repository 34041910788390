import { Box, Grid, Paper } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { common } from "helpers";

const ConfigureTab = () => {
  return (
    <>
      <Box mt={5} px={5}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <Paper variant="contained" square className="main-content-wrap">
              <Link to="#" className="link-text">
                <Box className="contents-padding activate-arrow">
                  <Grid container>
                    <Grid item xs={11}>
                      <Box>
                        <label className="setting-config-tab-titles">
                          Form Trasactions
                        </label>
                        <p className="setting-card-content">
                          Add or remove transactions related to your business or
                          company
                        </p>
                      </Box>
                    </Grid>
                    <Grid item xs={1} className="padding-zero changeArrow">
                          <Link to="#">
                            <img
                              alt="next"
                              src={common.loadImg(
                                "iconly-light-arrow-next-circle.svg"
                              )}
                            />
                            <img
                              alt="next"
                              src={common.loadImg("next-activeArrow.svg")}
                              className="arrowActive"
                            />
                          </Link>
                       
                    </Grid>
                  </Grid>
                </Box>
              </Link>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Paper variant="contained" square className="main-content-wrap">
              <Link to="" className="link-text">
                <Box className="contents-padding activate-arrow">
                  <Grid container>
                    <Grid item xs={11}>
                      <Box>
                        <Grid item>
                          <label className="setting-config-tab-titles">
                            Form Settings
                          </label>
                          <p className="setting-card-content">
                            Add or remove form settings related to your business
                            or company
                          </p>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item xs={1} className="padding-zero changeArrow">
                     
                          <Link to="#">
                            <img
                              alt="next"
                              src={common.loadImg(
                                "iconly-light-arrow-next-circle.svg"
                              )}
                            />
                            <img
                              alt="next"
                              src={common.loadImg("next-activeArrow.svg")}
                              className="arrowActive"
                            />
                          </Link>
                        
                    </Grid>
                  </Grid>
                </Box>
              </Link>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Paper variant="contained" square className="main-content-wrap">
              <Link to="/setting/conversionMapping" className="link-text">
                <Box className="contents-padding activate-arrow">
                  <Grid container>
                    <Grid item xs={11}>
                      <Box>
                        <label className="setting-config-tab-titles">
                          Conversion Mapping
                        </label>
                        <p className="setting-card-content">
                          Add or remove mapping fields related to your business
                          or company
                        </p>
                      </Box>
                    </Grid>
                    <Grid item xs={1} className="padding-zero changeArrow">
                         <Link to="/setting/conversionMapping">
                            <img
                              alt="next"
                              src={common.loadImg(
                                "iconly-light-arrow-next-circle.svg"
                              )}
                            />
                            <img
                              alt="next"
                              src={common.loadImg("next-activeArrow.svg")}
                              className="arrowActive"
                            />
                          </Link>
                        
                    </Grid>
                  </Grid>
                </Box>
              </Link>
            </Paper>
          </Grid>
          <Grid item  xs={12} sm={12} md={6}>
            <Paper variant="contained" square className="main-content-wrap">
              <Link to="/setting/role" className="link-text">
                <Box className="contents-padding activate-arrow">
                  <Grid container>
                    <Grid item xs={11}>
                      <Box>
                        <label className="setting-config-tab-titles">
                          Roles
                        </label>
                        <p className="setting-card-content">
                          Add roles related to your business or company
                        </p>
                      </Box>
                    </Grid>
                    <Grid item xs={1} className="padding-zero changeArrow">
                     
                          <Link to="/setting/role">
                            <img
                              alt="next"
                              src={common.loadImg(
                                "iconly-light-arrow-next-circle.svg"
                              )}
                            />
                            <img
                              alt="next"
                              src={common.loadImg("next-activeArrow.svg")}
                              className="arrowActive"
                            />
                          </Link>
                        </Grid>
                    
                  </Grid>
                </Box>
              </Link>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Paper variant="contained" square className="main-content-wrap">
              <Link to="/setting/template" className="link-text">
                <Box className="contents-padding activate-arrow">
                  <Grid container>
                    <Grid item xs={11}>
                      <Box>
                        <label className="setting-config-tab-titles">
                          Templates
                        </label>
                        <p className="setting-card-content">
                          Add or remove templates related to your business or
                          company
                        </p>
                      </Box>
                    </Grid>
                    <Grid item xs={1} className="padding-zero changeArrow">
                          <Link to="/setting/template">
                            <img
                              alt="next"
                              src={common.loadImg(
                                "iconly-light-arrow-next-circle.svg"
                              )}
                            />
                            <img
                              alt="next"
                              src={common.loadImg("next-activeArrow.svg")}
                              className="arrowActive"
                            />
                          </Link>
                        
                    </Grid>
                  </Grid>
                </Box>
              </Link>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Paper variant="contained" square  className="main-content-wrap">
              <Link to="" className="link-text">
                <Box className="contents-padding activate-arrow">
                  <Grid container>
                    <Grid item xs={11}>
                      <Box>
                        <label className="setting-config-tab-titles">
                          Automation
                        </label>
                        <p className="setting-card-content">
                          Add or remove rules related to your business or
                          company
                        </p>
                      </Box>
                    </Grid>
                    <Grid item xs={1} className="padding-zero changeArrow">
                          <Link to="#">
                            <img
                              alt="next"
                              src={common.loadImg(
                                "iconly-light-arrow-next-circle.svg"
                              )}
                            />
                            <img
                              alt="next"
                              src={common.loadImg("next-activeArrow.svg")}
                              className="arrowActive"
                            />
                          </Link>
                    </Grid>
                  </Grid>
                </Box>
              </Link>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Paper variant="contained" square  className="main-content-wrap">
              <Link to="/setting/subemail" className="link-text">
                <Box className="contents-padding  activate-arrow ">
                  <Grid container>
                    <Grid item xs={11}>
                      <Box>
                        <label className="setting-config-tab-titles">
                          Sub Email
                        </label>
                        <p className="setting-card-content">
                          Add or remove subemail related to your business or
                          company
                        </p>
                      </Box>
                    </Grid>
                    <Grid item xs={1} className="padding-zero changeArrow">
                          <Link to="/setting/subemail">
                            <img
                              alt="next"
                              src={common.loadImg(
                                "iconly-light-arrow-next-circle.svg"
                              )}
                            />
                            <img
                              alt="next"
                              src={common.loadImg("next-activeArrow.svg")}
                              className="arrowActive"
                            />
                          </Link>
                        </Grid>
                      
                  </Grid>
                </Box>
              </Link>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ConfigureTab;
