import React, { memo, useRef, useState } from "react";

import { common } from "helpers";
import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import {
  Divider,
  FormControl,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";

const Customer = memo(() => {
  const fileUploadRef = useRef(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [rightAnchorEl, setRightAnchorEl] = React.useState(null);

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const detailsMoreClick = (event) => {
    setRightAnchorEl(event.currentTarget);
  };
  const detailsCloseClick = () => {
    setRightAnchorEl(null);
  };

  const [menuList] = useState([
    {
      id: "1",
      name: "Sites",
    },
    {
      id: "2",
      name: "Tax Identification",
    },
    {
      id: "3",
      name: "Contacts",
    },
    {
      id: "4",
      name: "Attachements",
    },
  ]);
  const [selectedMenu, setSelectedMenu] = useState(0);
  const [selectedUser, setSelectUser] = useState(0);

  const handleChange = (idx) => {
    setSelectedMenu(idx);
  };

  const handleFileUpload = () => {
    if (fileUploadRef?.current) {
      fileUploadRef?.current?.click();
    }
  };

  const onFileChange = (event) => {
    const file = event.targe.files[0];
    console.log("file", file);
  };

  const handleSelectUser = (fileIndex) => {
    console.log("fileIndex", fileIndex);
    setSelectUser(fileIndex);
  };

  const open = Boolean(anchorEl);
  const rightMoreOpen = Boolean(rightAnchorEl);
  return (
    <Box px={5} mt={3} className="template-box-border">
      <Grid container spacing={5}>
        {true && (
          <Grid item xs={5} className="mt-2">
            <div className="card border-color-F2F9FD left-side-card">
              <div className="card-body p-0" position="static">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  className="py-3 px-3"
                >
                  <div className="col-md-8">
                    <h5 className="d-flex align-items-center title mb-0">
                      <Link
                        to="/setting/view/vendor"
                        className="text-decoration-none"
                      >
                        <img
                          alt={"back"}
                          className="mx-1"
                          src={common.loadImg(
                            "iconly-light-arrow-down-circle.svg"
                          )}
                          width={30}
                          height={30}
                        />
                      </Link>
                      <label className="d-flex align-items-center page-title">
                        Customers{" "}
                        <span className="location-count-label">02</span>
                      </label>
                    </h5>
                  </div>
                  <div className="d-flex align-items-center">
                    <Link
                      to="/setting/customer"
                      className="text-decoration-none"
                    >
                      <span className="mx-2 add-new">Add New</span>
                    </Link>

                    <div className="add-new-button d-flex align-items-center justify-content-center">
                      <Link
                        to="/setting/customer"
                        className="text-decoration-none"
                      >
                        <span className="d-flex align-items-center plus-icon">
                          +
                        </span>
                      </Link>
                    </div>
                  </div>
                </Box>
                <div className="d-flex align-items-center bg-F2F9FD p-1">
                  <Box className="filter-search" width="100%">
                    <FormControl sx={{ width: "100%" }}>
                      <OutlinedInput
                        id="search"
                        placeholder={"Search"}
                        endAdornment={
                          <InputAdornment position="end">
                            <img
                              src={common.loadImg("common-search.svg")}
                              alt="search"
                            />
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                      />
                    </FormControl>
                  </Box>
                  <img
                    alt={"options"}
                    className="my-auto filter-icon"
                    src={common.loadImg("search-filter.svg")}
                    height={24}
                  />
                </div>

                <div className="bg-white location-user-list">
                  <Grid container direction="column">
                    {[1, 2, 3].map((_, i) => {
                      return (
                        <Grid item>
                          <Box>
                            <Grid container alignItems="center">
                              <Grid item xs={2} className="pl-2">
                                <Box px={2} py={2}>
                                  <Box className="img-box">AB</Box>
                                </Box>
                                {i === 0 ? (
                                  <div className="active active-location"></div>
                                ) : null}
                              </Grid>
                              <Grid item xs={1}>
                                <Box display="flex" justifyContent="center">
                                  <Box height="85px" width="2px">
                                    <Divider orientation="vertical" />
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item xs={9}>
                                <Box py={0.6} px={2}>
                                  <Grid
                                    container
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <Grid item>
                                      <div className="mt-2 font-weight-bold">
                                        <h6 className="setting-card-title fz-16 font-weight-bold">
                                          Adam Brody
                                        </h6>
                                        <p className="setting-card-content fz-14">
                                          Chief Design Officer
                                        </p>
                                      </div>
                                    </Grid>
                                    <Grid item>
                                      {/* <Box> */}
                                      {/* <IconButton className="more-icon"> */}
                                      <div className="my-auto pe-4 ">
                                        <MoreHorizOutlinedIcon
                                          className="pointer"
                                          aria-describedby={
                                            "right-more-popover"
                                          }
                                          onClick={detailsMoreClick}
                                        />

                                        <Popover
                                          id={"right-more-popover"}
                                          open={rightMoreOpen}
                                          anchorEl={rightAnchorEl}
                                          onClose={detailsCloseClick}
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right",
                                          }}
                                          transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                          }}
                                        >
                                          {["menu 1", "menu 2"].map((x) => (
                                            <Typography
                                              key={x}
                                              sx={{ p: 2 }}
                                              className="text-capitalize"
                                            >
                                              {x}
                                            </Typography>
                                          ))}
                                        </Popover>
                                      </div>
                                      {/* </IconButton>
                                      </Box> */}
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Grid>
                            </Grid>
                            <Box>
                              <Divider />
                            </Box>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                  {false &&
                    [
                      {
                        initial: "AB",
                        name: "Adam Brody",
                        designation: "Chief Design Officer",
                      },
                      {
                        initial: "AS",
                        name: "Amanda Silva",
                        designation: "General Manager",
                      },
                      {
                        initial: "AS",
                        name: "Bryan D Salgado",
                        designation: "Marketing Manager",
                      },
                      {
                        initial: "AB",
                        name: "Adam Brody",
                        designation: "Chief Design Officer",
                      },
                      {
                        initial: "AS",
                        name: "Amanda Silva",
                        designation: "General Manager",
                      },
                      {
                        initial: "AS",
                        name: "Bryan D Salgado",
                        designation: "Marketing Manager",
                      },
                    ].map((user, index) => (
                      <div key={index}>
                        <Grid
                          container
                          spacing={1}
                          className="align-items-center justify-content-between bottom-border-divider px-4 mt-4 pb-4"
                        >
                          <Grid item className="mx-2">
                            <Grid
                              container
                              spacing={6}
                              className="align-items-center"
                            >
                              <Grid item className="bottom-right-side-divider">
                                <span
                                  style={{ backgroundColor: "#2A3543" }}
                                  className="bg-round p-3 fz-19 text-white pointer"
                                >
                                  {user.initial}
                                </span>
                              </Grid>

                              <Grid
                                item
                                className="font-weight-bold pointer align-items-center"
                                onClick={() => handleSelectUser(index)}
                              >
                                <h6
                                  className={`fz-16 mb-0 ${
                                    index === selectedUser
                                      ? "selected-user-name"
                                      : "setting-card-title"
                                  }
                                `}
                                >
                                  {user.name}
                                </h6>
                                <p className="setting-card-content fz-14 mb-0">
                                  {user.designation}
                                </p>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item className="mx-2">
                            <MoreHorizOutlinedIcon
                              className="pointer"
                              aria-describedby={"more-popover"}
                              onClick={handleMoreClick}
                            />

                            <Popover
                              id={"more-popover"}
                              open={open}
                              anchorEl={anchorEl}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              {["menu 1", "menu 2"].map((x) => (
                                <Typography
                                  key={x}
                                  sx={{ p: 2 }}
                                  className="text-capitalize"
                                >
                                  {x}
                                </Typography>
                              ))}
                            </Popover>
                          </Grid>
                        </Grid>

                        <div
                          className={"active-location "}
                          style={{
                            visibility:
                              index === selectedUser ? "visible" : "hidden",
                          }}
                        ></div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </Grid>
        )}

        {true && (
          <Grid item xs={7} className="mt-2">
            <div className="row card-custom">
              <div className=" p-0">
                <div className="card-body p-0">
                  <div className="d-flex justify-content-between bottom-border-divider">
                    <h5 className="right-section-title my-3">Details </h5>
                    <div className="my-auto pe-4">
                      <MoreHorizOutlinedIcon
                        className="pointer"
                        aria-describedby={"right-more-popover"}
                        onClick={detailsMoreClick}
                      />

                      <Popover
                        id={"right-more-popover"}
                        open={rightMoreOpen}
                        anchorEl={rightAnchorEl}
                        onClose={detailsCloseClick}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        {["menu 1", "menu 2"].map((x) => (
                          <Typography
                            key={x}
                            sx={{ p: 2 }}
                            className="text-capitalize"
                          >
                            {x}
                          </Typography>
                        ))}
                      </Popover>
                    </div>
                  </div>

                  <div className="customer-tab-list">
                    <ul className="flex submenu-ul ps-0">
                      {menuList?.map((menu, index) => {
                        return (
                          <li
                            key={menu?.id}
                            className={`pointer ${
                              index === selectedMenu
                                ? "customer-active-li"
                                : "customer-tab-li"
                            }`}
                            onClick={() => handleChange(index)}
                          >
                            {menu?.name}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="settings-secondary" />
              <div className="">
                <Box className="row" style={{ backgroundColor: "white" }}>
                  <div className="upload-section-box my-4">
                    <div
                      className="upload-section"
                      onClick={() => handleFileUpload()}
                    >
                      <div className="d-flex justify-content-between">
                        <div>
                          <div className="upload-or-attach-file">
                            Upload or Attach a File
                          </div>
                          <div className="mt-1 drag-nd-drop-file">
                            Drag and drop a file you wish to upload
                          </div>
                        </div>

                        <img
                          alt={"back"}
                          className="mx-1"
                          src={common.loadImg("attachement-upload.svg")}
                          width={30}
                          height={30}
                        />
                      </div>
                    </div>

                    <input
                      ref={fileUploadRef}
                      type="file"
                      className="d-none"
                      onChange={(e) => onFileChange(e)}
                    />
                  </div>

                  <div className="d-flex align-items-center justify-content-center bottom-border-divider pb-3">
                    <label className="customer-file-header">
                      <b>Files</b>{" "}
                      <span className="location-count-label">02</span>
                    </label>
                  </div>

                  {[0, 1, 2, 3, 4].map((item) => (
                    <div
                      key={item}
                      className="d-flex align-items-center justify-content-center file-list bottom-border-divider mt-4 pb-4"
                    >
                      <div className="customer-file-header">
                        <Grid container direction="row" spacing={1}>
                          <Grid item xs={6}>
                            <Box display="flex" flexDirection="column">
                              <u className="file-name-border">
                                <span className="file-name">
                                  ScreenShot01.png
                                </span>
                              </u>
                              <span className="file-timeline">
                                Jan 20 9:20am
                              </span>
                            </Box>
                          </Grid>

                          <Grid
                            item
                            xs={3}
                            className="d-flex align-items-center justify-content-around"
                          >
                            <div className="view-file-text">View File</div>
                            <img
                              alt={"view"}
                              className="mx-1 pointer"
                              src={common.loadImg("view-file-img.svg")}
                              width={23}
                              height={20}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            className="d-flex align-items-center justify-content-around"
                          >
                            <div className="view-file-text">Download</div>
                            <img
                              alt={"download"}
                              className="mx-1 pointer"
                              src={common.loadImg(
                                "attachment-download-img.svg"
                              )}
                              width={23}
                              height={20}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  ))}
                </Box>
              </div>
            </div>
          </Grid>
        )}
      </Grid>
    </Box>
  );
});

export { Customer };
