import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
  FormControl,
} from "@mui/material";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import moment from "moment";
import Select, { components } from "react-select";
import { Link, useNavigate, useSearchParams,useOutletContext } from "react-router-dom";
import AmlHashForm from "aml-hash-form";
import AccessDenied from "elements/AccessDenied";

import { LOOKUPS_LOV, TASKS_VIEW, TASKS } from "helpers/service";
import FieldSelect from "elements/FieldSelect";
import FieldLookup from "elements/FieldLookup";
import StatusBar from "elements/StatusBar";
import { api, common, envConfig } from "helpers";

const relateModAttr = [
  {
    api: "CholaCrmCampaigns",
    name: "CRM_CAMPAIGNS",
    get: "CampaignName",
    set: "CampaignId",
    value: "CHOLA_CRM_CAMPAIGNS_ALL",
    label: "Campaign",
  },
  {
    api: "CholaCrmLists",
    name: "CRM_LISTS",
    get: "ListName",
    set: "ListId",
    value: "CHOLA_CRM_LISTS_ALL",
    label: "Enquiry",
  },
  {
    api: "CholaCrmOpportunity",
    name: "CRM_DEALS",
    get: "OpportunityName",
    set: "OpportunityId",
    value: "CHOLA_CRM_OPPORTUNITY_ALL",
    label: "Deal",
  },
  {
    api: "CholaCrmLeads",
    name: "CRM_LEADS",
    get: "LeadName",
    set: "LeadId",
    value: "CHOLA_CRM_LEADS_ALL",
    label: "Lead",
  },
];

const SAVE_SUCCESS = "Task saved successfully.";
const UPDATE_SUCCESS = "Task updated successfully.";
const SAVE_FAILED = "Task save failed.";
const UPDATE_FAILED = "Task update failed.";
const BACK_URL = "/task/";

function TaskAdd(props) {
  const navigate = useNavigate();
  const [permission] = useOutletContext();
  const tId = props.id || 0;
  const userData = common.userInfo();
  let [searchParams, setSearchParams] = useSearchParams()
  const view = searchParams.get("view")
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(props.id ? "process" : "success");
  const [moduleList, setModuleList] = useState([]);
  const [sModule, setSModule] = useState(null);
  // Form field
  const formFields = {
    TaskName: { validate: ["req#Task Name is required"] },
    Priority: { validate: ["req#Priority is required"] },
    Description: { validate: ["req#Description is required"] },
    Status: { validate: ["req#Status is required"] },
    StartDate: { validate: [] },
    EndDate: { validate: [] },
    TaskTable: { validate: [] },
    TaskPk: { validate: [] },
    Assignees: { validate: [] },
    Active: { value: true },
    PercentageComplete: { value: 1 },
  };

  // Main Form
  const {
    fields,
    errors,
    handleChange,
    handleSubmit,
    setValue,
    setMultiValue,
  } = AmlHashForm(formFields);

  useEffect(() => {
    loadModuleList();
    if (tId) {
      getTaskInfo();
    }
  }, []);

  const getTaskInfo = () => {
    const url = `${envConfig.BASE_API}${TASKS_VIEW}/${tId}`;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };

    api.call(
      data,
      (response) => {
        if (response) {
          let result = response.data;
          let relatedModule = relateModAttr.filter(
            (e) => e.value == result.TaskTable
          );
          setSModule(relatedModule.length > 0 ? relatedModule[0] : null);
          if (result.TaskPk) {
            getRelatedRecordName(result, relatedModule);
          } else {
            setMultiValue({
              TaskName: result.TaskName,
              Priority: result.Priority
                ? { label: result.PriorityName, value: result.Priority }
                : null,
              Description: result.Description,
              Status: result.Status
                ? { label: result.StatusName, value: result.Status }
                : null,
              Active: result.Active === "Y",
              PercentageComplete: result.PercentageComplete,
              TaskTable: relatedModule.length > 0 ? relatedModule[0] : null,
              TaskPk: result.TaskPk,
              Assignees: result.Assignees
                ? { label: result.EmployeeName, value: result.Assignees }
                : null,
              StartDate: result.StartDate || null,
              EndDate: result.EndDate || null,
            });
            setStatus("success");
          }
        }
      },
      (error) => {
        setStatus("error");
      },
      (final) => {}
    );
  };

  const getRelatedRecordName = (result, relatedModule) => {
    let url = `${envConfig.BASE_API}/${relatedModule[0].api}/${result.TaskPk}`;

    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };

    api.call(
      data,
      (response) => {
        if (response) {
          let res = response.data;
          let relatedRecordName = res[relatedModule[0].get];
          setMultiValue({
            TaskName: result.TaskName,
            Priority: result.Priority
              ? { label: result.PriorityName, value: result.Priority }
              : null,
            Description: result.Description,
            Status: result.Status
              ? { label: result.StatusName, value: result.Status }
              : null,
            Active: result.Active === "Y",
            PercentageComplete: result.PercentageComplete,
            TaskTable: relatedModule.length > 0 ? relatedModule[0] : null,
            TaskPk: result.TaskPk,
            Assignees: result.Assignees
              ? { label: result.EmployeeName, value: result.Assignees }
              : null,
            StartDate: result.StartDate || null,
            EndDate: result.EndDate || null,
            TaskPk: { label: relatedRecordName, value: result.TaskPk },
          });
          setStatus("success");
        }
      },
      (error) => {
        setStatus("error");
      },
      (final) => {}
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let isValid = handleSubmit();
    if (isValid) {
      setIsLoading(true);

      let params = {
        TaskName: fields.TaskName,
        Description: fields.Description,
        Priority: fields.Priority.value,
        Status: fields.Status.value,
        StartDate: fields.StartDate ? common.formatDate(fields.StartDate, "YYYY-MM-DDTHH:mm:ssZ") : null,
        EndDate: fields.EndDate ? common.formatDate(fields.EndDate, "YYYY-MM-DDTHH:mm:ssZ") : null,
        DueDate: fields.EndDate ? common.formatDate(fields.EndDate, "YYYY-MM-DDTHH:mm:ssZ") : null,
        TaskTable: fields.TaskTable?.value,
        TaskPk: fields.TaskPk?.value,
        Assignees: fields.Assignees?.value || "",
        Active: fields.Active ? "Y" : "N",
        TenantId: userData.pTenantId,
        OrgId: userData.pOrgId,
        PercentageComplete: 1,
      };

      let url = `${envConfig.BASE_API}${TASKS}`;
      if (tId) {
        url = `${envConfig.BASE_API}${TASKS}/${tId}`;
      }
      let data = {
        url: url,
        type: "dynamic",
        method: tId ? "PATCH" : "POST",
        body: JSON.stringify(params),
        auth: "token",
        cType: 4,
      };
      api.call(
        data,
        (res) => {
          if (res.data) {
            if (tId) {
              common.snack("S", UPDATE_SUCCESS);
            } else {
              common.snack("S", SAVE_SUCCESS);
            }
            navigate(BACK_URL+view);
          } else if (
            res?.status === 500 ||
            res?.status === 400 ||
            res?.status === 404
          ) {
            const error = res?.data?.error;
            if (tId) {
              common.snack("E", error || UPDATE_FAILED);
            } else {
              common.snack("E", error || SAVE_FAILED);
            }
          }
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(false);
          common.snack("E", SAVE_FAILED);
        },
        (final) => {
          setIsLoading(false);
        }
      );
    }
  };

  const goBack = (e) => {
    e.preventDefault();
    navigate(BACK_URL+view);
  }

  const onHandleSelect = (data) => {
    setSModule(null);
    setMultiValue({
      TaskTable: data,
      TaskPk: null,
    });
    if (data) {
      let sResult = relateModAttr.filter((e) => e.api == data.Extattr1);
      setSModule(sResult.length > 0 ? sResult[0] : null);
    }
  };

  const loadModuleList = () => {
    const url = `${envConfig.BASE_API}${LOOKUPS_LOV}?finder=FindLookupVals;pLookupCode=CHOLA_AUTOMATION_RULE_OBJECT&totalResults=true&limit=10&offset=0`;

    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };

    api.call(
      data,
      (response) => {
        if (response) {
          let rData = [];
          response?.data?.items?.forEach((item) => {
            item.label = item.Name;
            item.value = item.Code;
            rData.push(item);
          });
          setModuleList(rData);
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  // Floating React select
  const { ValueContainer, Placeholder } = components;

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

  const reactStyle = {
    container: (base, state) => ({
      ...base,
      marginTop: 0,
    }),
    valueContainer: (base, state) => ({
      ...base,
      overflow: "visible",
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    placeholder: (base, state) => ({
      ...base,
      position: "absolute",
      top: state.hasValue || state.selectProps.inputValue ? -15 : "10%",
      transition: "top 0.1s, font-size 0.1s",
      fontSize: state.hasValue || state.selectProps.inputValue ? 11 : 13,
      color: "#9daabb",
    }),
    
  };

  return (
    <div style={{ padding: "20px" }}>
       {   common.givenPermission(permission,'task','basic',tId) ? (
        <AccessDenied />
      ) : <Paper
      sx={{ width: "100%", mb: 2 }}
      className={`container responsive-table p-0`}
    >
      <Box>
        <Paper variant="contained dynamic-form-wrap" square>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item className="detail-head">
              <Grid container alignItems="center" spacing={2} className="detail-head">
                <Grid item>
                  <Box py={1} px={1} className="icon-back icon-buton">
                    <IconButton>
                      <Link to="#" onClick={(e)=> goBack(e)}>
                        <img
                          src={common.loadImg("backArrowKanban.svg")}
                          alt="back"
                        />
                      </Link>
                    </IconButton>
                  </Box>
                </Grid>
                <Grid item className="df-main-head">
                  <Typography className="detail-title">
                    {`${props.id ? "Edit" : "Add"} Task`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item className="safari-style">
              <Box px={2} className="safari-style">
                <Grid container alignItems="center" spacing={2} className="safari-style">
                  <Grid item>
                    <Box className="verical-divider">
                      <Divider orientation="vertical" />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box>
                      {status == "success" && (
                        <Button
                          className="save-note"
                          type="submit"
                          disabled={isLoading}
                          onClick={(e) => onSubmit(e)}
                        >
                          {isLoading ? "Processing..." : "Save Changes"}
                        </Button>
                      )}
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box className="vertical-divider">
                      <Divider orientation="vertical" />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box className="revert-close">
                      <Button className="save-note pe-0">
                        <Link to="#" onClick={(e)=> goBack(e)}>
                          Revert and Close
                          <img
                            src={common.loadImg("closeKanban.svg")}
                            alt="close"
                          />
                        </Link>
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Box>
            <Divider />
          </Box>
          <Box p={2} px={5} className="form-wrap">
            {status !== "success" ? (
              <StatusBar status={status} />
            ) : (
              <Box mt={2} className="camp-edit meet-table">
                <Row className="m-0">
                  <Col xs={6} className="body-th common-input-style">
                    <Box className="filled-box">
                      <TextField
                        id="filled-basic"
                        label="Task Name*"
                        variant="filled"
                        name="TaskName"
                        value={fields.TaskName}
                        maxLength={150}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      {errors.TaskName ? (
                        <p className="error-txt">{errors.TaskName}</p>
                      ) : null}
                    </Box>
                  </Col>
                  <Col xs={6} className="body-th common-input-style">
                    <Box>
                      <div className="form-floating">
                        <textarea
                          className="form-control textarea"
                          id="floatingTextarea"
                          name="Description"
                          maxLength={4000}
                          value={fields.Description}
                          rows={2}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                        <label
                          htmlFor="floatingTextarea"
                          className="floating-label"
                        >
                          Description*
                        </label>
                        {errors.Description ? (
                          <p className="error-txt">{errors.Description}</p>
                        ) : null}
                      </div>
                    </Box>
                  </Col>
                  <Col xs={6} className="body-th common-input-style">
                    <Box className="">
                      <FormControl
                        fullWidth
                        className="select-float-label common-select-border"
                      >
                        <FieldLookup
                          isRender={true}
                          name="Priority"
                          type="TASK_PRIORITY"
                          lookup={true}
                          value={fields.Priority}
                          onSelect={(data) => setValue("Priority", data)}
                          placeholder="Priority*"
                        />
                      </FormControl>
                      {errors.Priority ? (
                        <p className="error-txt">{errors.Priority}</p>
                      ) : null}
                    </Box>
                  </Col>
                  <Col xs={6} className="body-th common-input-style">
                    <Box className="">
                      <FormControl
                        fullWidth
                        className="select-float-label common-select-border"
                      >
                        <FieldLookup
                          isRender={true}
                          name="Status"
                          type="TASK_STATUS"
                          lookup={true}
                          value={fields.Status}
                          onSelect={(data) => setValue("Status", data)}
                          placeholder="Status*"
                        />
                      </FormControl>
                      {errors.Status ? (
                        <p className="error-txt">{errors.Status}</p>
                      ) : null}
                    </Box>
                  </Col>

                  <Col
                    xs={6}
                    className="body-th common-input-style common-select-border common-padding-zero"
                  >
                    <FieldSelect
                      api="CRM_EMPLOYEES"
                      get="EmployeeName"
                      set="Email"
                      value={fields.Assignees}
                      isMulti={false}
                      isRender={true}
                      onSelect={(data) => setValue("Assignees", data)}
                      placeholder="Responsible Person"
                    />
                  </Col>
                  <Col xs={3} className="body-th common-input-style">
                    <Box className="">
                      <FormControl
                        fullWidth
                        className="select-float-label common-select-border"
                      >
                        <Select
                          menuPlacement="auto"
                          label="Related Module"
                          placeholder="Related Module"
                          menuPortalTarget={document.querySelector("body")}
                          components={{
                            ValueContainer: CustomValueContainer,
                          }}
                          style={reactStyle}
                          isClearable={true}
                          options={moduleList}
                          value={fields.TaskTable}
                          onChange={(e) => {
                            onHandleSelect(e);
                          }}
                          styles={{
                            container: (provided, state) => ({
                              ...provided,
                              marginTop: 0,
                            }),
                            valueContainer: (provided, state) => ({
                              ...provided,
                              overflow: "visible",
                            }),
                            placeholder: (provided, state) => ({
                              ...provided,
                              position: "absolute",
                              top:
                                state.hasValue || state.selectProps.inputValue
                                  ? -15
                                  : "10%",
                              transition: "top 0.1s, font-size 0.1s",
                              fontSize:
                                state.hasValue || state.selectProps.inputValue
                                  ? 11
                                  : 13,
                              color: "#9daabb",
                            }),
                            
                          }}
                        />
                      </FormControl>
                    </Box>
                  </Col>
                  {sModule && (
                    <Col xs={3} className="body-th common-input-style">
                      <Box className="">
                        <FormControl
                          fullWidth
                          className="select-float-label common-select-border"
                        >
                          <FieldSelect
                            api={sModule.name}
                            get={sModule.get}
                            set={sModule.set}
                            value={fields.TaskPk}
                            isMulti={false}
                            isRender={true}
                            onSelect={(data) => setValue("TaskPk", data)}
                            placeholder="Related To"
                          />
                        </FormControl>
                      </Box>
                    </Col>
                  )}
                  <Col xs={6} className="body-th common-input-style">
                    <Box id="float-label">
                      <label
                        className={
                          fields.StartDate
                            ? "Active text-label ml-4 camp-time-label"
                            : "text-label ml-4 camp-time-label"
                        }
                      >
                        Start Date
                      </label>

                      <Box className="ml-1 input-date-picker">
                        <DatePicker
                          format="MM/DD/YYYY hh:mm A"
                          plugins={[
                            <TimePicker
                              hideSeconds
                              position="bottom"
                              className="ml-3"
                            />,
                          ]}
                          render={<InputIcon />}
                          placeholder="Start Date*"
                          name="Start Date"
                          maxDate={
                            fields.EndDate
                              ? moment(fields.EndDate).toDate()
                              : ""
                          }
                          value={
                            fields.StartDate
                              ? moment(fields.StartDate).toDate()
                              : ""
                          }
                          onChange={(e) => {
                            setValue("StartDate", e?.toDate() || "");
                          }}
                        />
                      </Box>
                    </Box>
                  </Col>
                  <Col xs={6} className="body-th common-input-style">
                    <Box id="float-label">
                      <label
                        className={
                          fields.EndDate
                            ? "Active text-label ml-4 camp-time-label"
                            : "text-label ml-4 camp-time-label"
                        }
                      >
                        End Date
                      </label>

                      <Box className="ml-1 input-date-picker">
                        <DatePicker
                          format="MM/DD/YYYY hh:mm A"
                          plugins={[
                            <TimePicker
                              hideSeconds
                              position="bottom"
                              className="ml-3"
                            />,
                          ]}
                          render={<InputIcon />}
                          placeholder="End Date*"
                          name="EndDate"
                          minDate={
                            fields.StartDate
                              ? moment(fields.StartDate).toDate()
                              : ""
                          }
                          value={
                            fields.EndDate
                              ? moment(fields.EndDate).toDate()
                              : ""
                          }
                          onChange={(e) => {
                            setValue("EndDate", e?.toDate() || "");
                          }}
                        />
                      </Box>
                    </Box>
                  </Col>
                </Row>
              </Box>
            )}
          </Box>
        </Paper>
      </Box>
    </Paper>}
      
    </div>
  );
}

export default TaskAdd;
