import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import { Link, useNavigate, useParams } from "react-router-dom";
import "react-phone-input-2/lib/style.css";

import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
  Checkbox,
  FormControlLabel,
  FormControl,
  TextField,
} from "@mui/material";
import InputIcon from "react-multi-date-picker/components/input_icon";
import DatePicker from "react-multi-date-picker";
import FieldLookup from "elements/FieldLookup";
import StatusBar from "elements/StatusBar";
import AmlHashForm from "aml-hash-form";
import FieldSelect from "elements/FieldSelect";
import { CRM_PARTY_HDR, CRM_LOCATIONS, CRM_PARTY_SITES } from "helpers/service";
import moment from "moment";
import { common, envConfig, api } from "helpers";

const SAVE_SUCCESS = "Party details saved successfully.";
const SAVE_FAILED = "Party save failed.";
const UPDATE_FAILED = "Party update failed.";
const BACK_URL = "/setting/vendor/party";

function AddParty() {
  // render
  const { id } = useParams();
  const PartygetId = id;
  const userData = common.userInfo();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [disabled, setDisabled] = React.useState(true);
  const [status, setStatus] = useState(id ? "process" : "success");

  const formFields = {
    PartyCode: { validate: ["req#Party Code is required"] },
    PartyName: { validate: ["req#Party Name is required"] },
    PartyType: { validate: ["req#Party Type is required"] },
    StartDate: { validate: ["req#Start Date is required"] },
    EndDate: { validate: [] },
    Description: { validate: [] },
    Active: { value: false },
    AddressId: { validate: ["req#Address Id is required"] },
    SiteName: { validate: [] },
    SiteCode: { validate: [] },
    PrimaryFlag: { value: false },
    BillTo: { value: false },
    ShipTo: { value: false },
    ServiceTo: { value: false },
    AddressLine1: { validate: [] },
    AddressLine2: { validate: [] },
    CountryCode: { validate: [] },
    State: { validate: [] },
    City: { validate: [] },
    PostalCode: { validate: [] },
  };

  const {
    fields,
    errors,
    setValue,
    handleChange,
    handleSubmit,
    setMultiValue,
  } = AmlHashForm(formFields);

  useEffect(() => {
    if (PartygetId) {
      getPartyInfo();
      getPartysite();
    }
  }, []);
  const getPartyInfo = () => {
    let url = `${envConfig.BASE_API}${CRM_PARTY_HDR}/${PartygetId}`;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };

    api.call(
      data,
      (response) => {
        if (response.status === 200) {
          const result = response.data;
          if (result) {
            setMultiValue({
              PartyCode: result.PartyCode || "",
              PartyName: result.PartyName || "",
              PartyType: result.PartyType
                ? {
                    label: result.PartyType,
                    value: result.PartyType,
                  }
                : null,
              StartDate: result.StartDate || "",
              EndDate: result.EndDate || "",
              Description: result.Description || "",
              Active: result.Active === "Y",
            });
          }
        }
        setStatus("success");
      },
      (error) => {
        setStatus("error");
      },
      (final) => {}
    );
  };
  const onAddressChange = (data) => {
    setValue("AddressId", data);
    getLocationInfo(data.value);
  };

  const getLocationInfo = (LocationId) => {
    let url = `${envConfig.BASE_API}${CRM_LOCATIONS}/${LocationId}`;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };

    api.call(
      data,
      (response) => {
        if (response.status === 200) {
          const result = response.data;

          if (result) {
            setMultiValue({
              // AddressId: result.LocationName || "",
              AddressId: result.LocationId
                ? {
                    label: result.LocationName,
                    value: result.LocationId,
                  }
                : null,
              SiteName: result.LocationName || "",
              SiteCode: result.LocationCode || "",
              PrimaryFlag: result.PrimaryFlag == "Y" ? true : false,
              BillTo: result.BillTo == "Y" ? true : false,
              ShipTo: result.ShipTo == "Y" ? true : false,
              ServiceTo: result.ServiceTo == "Y" ? true : false,
              AddressLine1: result.AddressLine1 || "",
              AddressLine2: result.AddressLine2 || "",
              CountryCode: result.CountryCode || "",
              State: result.State || "",
              City: result.City || "",
              PostalCode: result.PostalCode || "",
            });
          }
        }
        setStatus("success");
      },
      (error) => {
        setStatus("error");
      },
      (final) => {}
    );
  };

  const getPartysite = () => {
    let url = `${envConfig.BASE_API}${CRM_PARTY_SITES}?q=PartyId=${PartygetId}`;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };

    api.call(
      data,
      (response) => {
        if (response.status === 200) {
          const result =
            response.data.items.length > 0 ? response.data.items[0] : null;
          if (result) {
            setMultiValue({
              AddressId: result.LocationId
                ? {
                    label: result.LocationName,
                    value: result.LocationId,
                  }
                : null,

              SiteName: result.SiteName || "",
              SiteCode: result.SiteCode || "",
              PrimaryFlag: result.PrimaryFlag == "Y" ? true : false,
              BillTo: result.BillTo == "Y" ? true : false,
              ShipTo: result.ShipTo == "Y" ? true : false,
              ServiceTo: result.ServiceTo == "Y" ? true : false,
              AddressLine1: result.AddressLine1 || "",
              AddressLine2: result.AddressLine2 || "",
              CountryCode: result.CountryCode || "",
              State: result.State || "",
              City: result.City || "",
              PostalCode: result.PostalCode || "",
            });
          }
        }
        setStatus("success");
      },
      (error) => {
        setStatus("error");
      },
      (final) => {}
    );
  };
  const onSubmit = (e) => {
    e.preventDefault();
    let isValid = handleSubmit();

    if (isValid) {
      save();
    }
  };

  const save = () => {
    setIsLoading(true);
    let params = {
      PartyCode: fields.PartyCode,
      PartyName: fields.PartyName,
      PartyType: fields.PartyType?.value,
      StartDate: fields.StartDate
        ? common.formatDate(fields.StartDate, "YYYY-MM-DDTHH:mm:ssZ")
        : null,
      EndDate: fields.EndDate
        ? common.formatDate(fields.EndDate, "YYYY-MM-DDTHH:mm:ssZ")
        : null,
      Description: fields.Description,
      Active: fields.Active ? "Y" : "N",
      TenantId: userData.pTenantId,
      OrgId: userData.pOrgId,
    };

    let url = `${envConfig.BASE_API}${CRM_PARTY_HDR}`;

    if (PartygetId) {
      url = `${envConfig.BASE_API}${CRM_PARTY_HDR}/${PartygetId}`;
    }
    let data = {
      url: url,
      type: "dynamic",
      method: PartygetId ? "PATCH" : "POST",
      body: JSON.stringify(params),
      auth: "token",
      cType: 4,
    };

    api.call(
      data,
      (res) => {
        if (res.data) {
          if (PartygetId) {
            common.snack("S", SAVE_SUCCESS);
          } else {
            common.snack("S", SAVE_SUCCESS);
          }
          navigate(BACK_URL);
        } else if (
          res?.status === 500 ||
          res?.status === 400 ||
          res?.status === 404
        ) {
          const error = res?.data?.error;
          if (PartygetId) {
            common.snack("E", error || UPDATE_FAILED);
          } else {
            common.snack("E", error || SAVE_FAILED);
          }
        }
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
        common.snack("E", SAVE_FAILED);
      },
      (final) => {
        setIsLoading(false);
      }
    );
  };

  return (
    <div style={{ padding: "20px" }}>
      <Paper
        sx={{ width: "100%", mb: 2 }}
        className={`container responsive-table p-0`}
      >
        <Box>
          <Paper variant="contained dynamic-form-wrap" square>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item display="flex" alignItems="center" xs={5} className="detail-head">
                <Grid py={1} px={1} className="icon-back icon-buton">
                  <Link to={BACK_URL}>
                    <img
                      src={common.loadImg("backArrowKanban.svg")}
                      alt="back"
                    />
                  </Link>
                </Grid>
                <Grid className="df-main-head">
                  <Typography className="detail-title">
                    {PartygetId ? "Edit Party" : "Add Party"}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item className="safari-style">
                <Box px={2} className="safari-style">
                  <Grid container alignItems="center" spacing={2}  className="safari-style">
                    <Grid item>
                      <Box className="verical-divider">
                        <Divider orientation="vertical" />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box>
                        {status == "success" && (
                          <Button
                            className="save-note"
                            type="submit"
                            disabled={isLoading}
                            onClick={(e) => onSubmit(e)}
                          >
                            {isLoading ? "Processing..." : "Save Changes"}
                          </Button>
                        )}
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box className="vertical-divider">
                        <Divider orientation="vertical" />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box className="revert-close">
                        <Button className="save-note pe-0">
                          <Link to={BACK_URL}>
                            Revert and Close
                            <img
                              src={common.loadImg("closeKanban.svg")}
                              alt="close"
                            />
                          </Link>
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Box>
              <Divider />
            </Box>
            {status !== "success" ? (
              <StatusBar status={status} />
            ) : (
              <Box>
                <Box p={2} px={5} className="form-wrap">
                  <Typography className="info-title dynamic-form-title mt-3">
                    PARTY INFORMATION
                  </Typography>
                  <Box mt={3}>
                    <Divider />
                  </Box>

                  <Box mt={2} className="camp-edit meet-table">
                    <Row className="m-0">
                      <Col
                        xs={6}
                        className="body-th common-input-style mandatoryclass"
                      >
                        <Box className="filled-box">
                          <TextField
                            id="filled-basic"
                            label="Party Code"
                            variant="filled"
                            name="PartyCode"
                            value={fields.PartyCode}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                          {errors.PartyCode ? (
                            <p className="error-txt">{errors.PartyCode}</p>
                          ) : null}
                        </Box>
                      </Col>
                      <Col
                        xs={6}
                        className="body-th common-input-style mandatoryclass"
                      >
                        <Box className="filled-box">
                          <TextField
                            id="filled-basic"
                            label="Party Name"
                            variant="filled"
                            name="PartyName"
                            value={fields.PartyName}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                          {errors.PartyName ? (
                            <p className="error-txt">{errors.PartyName}</p>
                          ) : null}
                        </Box>
                      </Col>
                      <Col
                        xs={6}
                        className="body-th common-input-style common-select-border mandatoryclass"
                      >
                        <Box className="">
                          <FormControl
                            fullWidth
                            className="select-float-label "
                          >
                            <FieldLookup
                              isRender={true}
                              name="PartyType"
                              type="CHOLA_PARTY_TYPE"
                              lookup={true}
                              value={fields.PartyType}
                              onSelect={(data) => setValue("PartyType", data)}
                              placeholder="Party Type"
                            />
                          </FormControl>
                          {errors.PartyType ? (
                            <p className="error-txt">{errors.PartyType}</p>
                          ) : null}
                        </Box>
                      </Col>
                      <Col xs={6} className="body-th common-input-style">
                        <Box>
                          <div className="form-floating">
                            <textarea
                              className="form-control textarea"
                              id="floatingTextarea"
                              name="Description"
                              value={fields.Description}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            />
                            <label
                              htmlFor="floatingTextarea"
                              className="floating-label"
                            >
                              Description
                            </label>
                          </div>
                        </Box>
                      </Col>
                      <Col
                        xs={6}
                        className="body-th common-input-style mandatoryclass"
                      >
                        <Box id="float-label">
                          <label
                            className={
                              fields.StartDate
                                ? "Active text-label ml-4 camp-time-label"
                                : "text-label ml-4 camp-time-label"
                            }
                          >
                            Start Date
                          </label>

                          <Box className="ml-1 input-date-picker">
                            <DatePicker
                              format="MM/DD/YYYY"
                              render={<InputIcon />}
                              placeholder="Start Date*"
                              name="StartDate"
                              value={
                                fields.StartDate
                                  ? moment(fields.StartDate).toDate()
                                  : ""
                              }
                              onChange={(e) => {
                                setMultiValue({
                                  StartDate: e?.toDate() || "",
                                });
                              }}
                            />
                          </Box>
                        </Box>
                        {errors.StartDate ? (
                          <p className="error-txt">{errors.StartDate || ""}</p>
                        ) : null}
                      </Col>
                      <Col xs={6} className="body-th common-input-style">
                        <Box id="float-label1">
                          <label
                            className={
                              fields.EndDate
                                ? "Active text-label ml-4 camp-time-label"
                                : "text-label ml-4 camp-time-label"
                            }
                          >
                            End Date
                          </label>

                          <Box className="ml-1 input-date-picker">
                            <DatePicker
                              format="MM/DD/YYYY"
                              render={<InputIcon />}
                              placeholder="End Date*"
                              name="EndDate"
                              value={
                                fields.EndDate
                                  ? moment(fields.EndDate).toDate()
                                  : ""
                              }
                              onChange={(e) => {
                                setMultiValue({
                                  EndDate: e?.toDate() || "",
                                });
                              }}
                            />
                          </Box>
                        </Box>
                      </Col>

                      <Col xs={6} className="body-th common-input-style">
                        <FormControlLabel
                          className="dynamic-checkbox ms-0 checkbox-space"
                          control={<Checkbox />}
                          label="Active"
                          checked={fields.Active}
                          labelPlacement="end"
                          name="Active"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                      </Col>
                    </Row>
                  </Box>
                </Box>
                <Box p={2} px={5} className="form-wrap">
                  <Typography className="info-title dynamic-form-title mt-3">
                    PARTY SITE INFORMATION
                  </Typography>
                  <Box mt={3}>
                    <Divider />
                  </Box>

                  <Box mt={2} className="camp-edit meet-table">
                    <Row className="m-0">
                      <Col
                        xs={6}
                        className="body-th common-input-style common-select-border mandatoryclass"
                        id="float-label"
                      >
                        <Box className="filled-box">
                          <FormControl
                            fullWidth
                            className="select-float-label "
                          >
                            <FieldSelect
                              api="CRM_LOCATIONS"
                              get="LocationName"
                              set="LocationId"
                              //query={`LocationId=${fields?.State?.value}`}
                              value={fields.AddressId}
                              isMulti={false}
                              isRender={true}
                              name="AddressId"
                              // onSelect={(data) => setValue("AddressId", data)}
                              onSelect={(data) => onAddressChange(data)}
                              placeholder="Address Search"
                            />
                          </FormControl>
                          {errors.AddressId ? (
                            <p className="error-txt">{errors.AddressId}</p>
                          ) : null}
                        </Box>
                      </Col>
                      <Col xs={6} className="body-th common-input-style">
                        <Box className="filled-box">
                          <TextField
                            id="filled-basic"
                            label="Site Code"
                            variant="filled"
                            name="SiteCode"
                            value={fields.SiteCode}
                          />
                        </Box>
                      </Col>
                      <Col xs={6} className="body-th common-input-style">
                        <Box className="filled-box">
                          <TextField
                            id="filled-basic"
                            label="Site Name"
                            variant="filled"
                            name="SiteName"
                            value={fields.SiteName}
                          />
                        </Box>
                      </Col>

                      <Col xs={6} className="body-th common-input-style">
                        <FormControlLabel
                          className="dynamic-checkbox ms-0 checkbox-space"
                          control={<Checkbox />}
                          label="Primary"
                          value=""
                          labelPlacement="end"
                          name="PrimaryFlag"
                          checked={fields.PrimaryFlag}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                      </Col>
                      <Col xs={6} className="body-th common-input-style">
                        <FormControlLabel
                          className="dynamic-checkbox ms-0 checkbox-space"
                          control={<Checkbox />}
                          label="Bill To"
                          value=""
                          labelPlacement="end"
                          name="BillTo"
                          checked={fields.BillTo}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                      </Col>
                      <Col xs={6} className="body-th common-input-style">
                        <FormControlLabel
                          className="dynamic-checkbox ms-0 checkbox-space"
                          control={<Checkbox />}
                          label="Service To"
                          value=""
                          labelPlacement="end"
                          name="ServiceTo"
                          checked={fields.ServiceTo}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                      </Col>
                      <Col xs={6} className="body-th common-input-style">
                        <FormControlLabel
                          className="dynamic-checkbox ms-0 checkbox-space"
                          control={<Checkbox />}
                          label="Ship To"
                          // value=""
                          labelPlacement="end"
                          name="ShipTo"
                          checked={fields.shipTo}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                      </Col>
                    </Row>
                  </Box>
                </Box>
                <Box p={2} px={5} className="form-wrap">
                  <Typography className="info-title dynamic-form-title mt-3">
                    ADDRESS INFORMATION
                  </Typography>
                  <Box mt={3}>
                    <Divider />
                  </Box>

                  <Box mt={2} className="camp-edit meet-table">
                    <Row className="m-0">
                      <Col xs={6} className="body-th common-input-style">
                        <Box className="filled-box">
                          <TextField
                            id="filled-basic"
                            label="Address 1"
                            variant="filled"
                            disabled={disabled}
                            className="text-disable"
                            name="AddressLine1"
                            value={fields.AddressLine1}
                          />
                        </Box>
                      </Col>
                      <Col xs={6} className="body-th common-input-style">
                        <Box className="filled-box">
                          <TextField
                            id="filled-basic"
                            label="Address 2"
                            variant="filled"
                            disabled={disabled}
                            className="text-disable"
                            name="AddressLine2"
                            value={fields.AddressLine2}
                          />
                        </Box>
                      </Col>
                      <Col xs={6} className="body-th common-input-style">
                        <Box className="filled-box">
                          <TextField
                            id="filled-basic"
                            label="Country"
                            variant="filled"
                            disabled={disabled}
                            className="text-disable"
                            name="CountryCode"
                            value={fields.CountryCode}
                          />
                        </Box>
                      </Col>
                      <Col xs={6} className="body-th common-input-style">
                        <Box className="filled-box">
                          <TextField
                            id="filled-basic"
                            label="State"
                            variant="filled"
                            disabled={disabled}
                            className="text-disable"
                            name="State"
                            value={fields.State}
                          />
                        </Box>
                      </Col>
                      <Col xs={6} className="body-th common-input-style">
                        <Box className="filled-box">
                          <TextField
                            id="filled-basic"
                            label="City"
                            variant="filled"
                            disabled={disabled}
                            className="text-disable"
                            name="City"
                            value={fields.City}
                          />
                        </Box>
                      </Col>
                      <Col xs={6} className="body-th common-input-style">
                        <Box className="filled-box">
                          <TextField
                            disabled={disabled}
                            id="filled-basic"
                            label="Postal Code"
                            variant="filled"
                            className="text-disable"
                            name="PostalCode"
                            value={fields.PostalCode}
                          />
                        </Box>
                      </Col>
                    </Row>
                  </Box>
                </Box>
              </Box>
            )}
          </Paper>
        </Box>
      </Paper>
    </div>
  );
}

export default AddParty;
