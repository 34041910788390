import React, { useCallback, useEffect, useState } from "react";
import {
  Link,
  useOutletContext,
  useParams,
  useNavigate,
} from "react-router-dom";
import moment from "moment";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import { common, envConfig, api } from "helpers";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
  Checkbox,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import AccessDenied from "elements/AccessDenied";
import TextField from "@mui/material/TextField";
import InputIcon from "react-multi-date-picker/components/input_icon";
import DatePicker from "react-multi-date-picker";
import StatusBar from "elements/StatusBar";
import AmlHashForm from "aml-hash-form";
import FieldSelect from "elements/FieldSelect";
import FieldLookup from "elements/FieldLookup";
import {
  CRM_PARTY_HDR,
  CRM_CUSTOMER,
  CRM_PARTY_SITES,
  CRM_LOCATIONS,
  CRM_CUSTOMER_SITE
} from "helpers/service";

const SAVE_SUCCESS = "Customer Sites details saved successfully.";
const SAVE_FAILED = "Customer Sites save failed.";
const UPDATE_FAILED = "Customer Sites update failed.";
const BACK_URL = "/setting/vendor/customer";

function AddSites(props) {
  //init
  const [permission] = useOutletContext();
  // render
  const { customerId, id } = useParams();
  const userData = common.userInfo();
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [isActive1, setIsActive1] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cusTy, setCusTy] = useState([]);
  const [addressInfo, setAddressInfo] = useState({});
  const [partyList,setPartyList] = useState([]);

  const [status, setStatus] = useState(id ? "process" : "success");

  const formFields = {
    SiteName: { validate: ["req#Site Name is required"] },
    SiteCode: { validate: ["req#Site Code is required"] },
  };

  const {
    fields,
    errors,
    setValue,
    handleChange,
    handleSubmit,
    setMultiValue,
  } = AmlHashForm(formFields);
  useEffect(() => {
    if (id) {
      // getCustomerInfo();
      // getCustomersite();
      getCustomerSiteInfo();
    }
  }, []);
  // const onPartyChange = (data) => {
  //   setValue("PartyId", data);
  //   getPartyInfo(data.value);
  // };
  const handleRating = (e, rate) => {
    fields.CustomerRating = rate;
    setValue("Rating", rate);
  };

  const findJsonArray = (arrayArg, key, val) => {
    let response = {};
    arrayArg.map((item) => {
      if (item[key] === val) {
        response = item;
      }
    });
    return response;
  };

  

  const getPartySiteInfo = (partyId) => {
    let url = `${envConfig.BASE_API}${CRM_PARTY_SITES}/${partyId}`;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };

    api.call(
      data,
      (response) => {
        if (response.status === 200) {
          const result = response.data;
          if(!id){
            setMultiValue({
              SiteName: result?.SiteName,
              SiteCode: result?.SiteCode,
            });
          }
         

          getLocationInfo(result?.AddressId);
        }
        setStatus("success");
      },
      (error) => {
        setStatus("error");
      },
      (final) => {}
    );
  };

  const getLocationInfo = (locationId) => {
    let url = `${envConfig.BASE_API}${CRM_LOCATIONS}/${locationId}`;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };

    api.call(
      data,
      (response) => {
        if (response.status === 200) {
          const result = response.data;
          console.log({ result });
          setAddressInfo(oldPrev => ({...oldPrev,
            PartySiteId:oldPrev?.PartySiteId,
            Address1: result?.AddressLine1 ? result?.AddressLine1 : null,
            Address2: result?.AddressLine2 ? result?.AddressLine2 : null,
            Country: result?.CountryCode ? result?.CountryCode : null,
            State: result?.State ? result?.State : null,
            City: result?.City ? result?.City : null,
            PostalCode: result?.PostalCode ? result?.PostalCode : null,
          }));
        }
        setStatus("success");
      },
      (error) => {
        setStatus("error");
      },
      (final) => {}
    );
  };


  const getCustomerSiteInfo = () => {
    let url = `${envConfig.BASE_API}${CRM_CUSTOMER_SITE}/${id}`;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
      cType: 4,
    };

    api.call(
      data,
      (res) => {
        if (res.data) {
          console.log(res.data)
          setMultiValue({
            SiteCode:res.data?.SiteCode,
            SiteName:res.data?.SiteName,
            PrimaryFlag:res.data?.PrimaryFlag,
            ShiptoFlag:res.data?.ShiptoFlag,
            BilltoFlag:res.data?.BilltoFlag,
          })
          setAddressInfo({
            PartySiteId:{label:res.data?.SiteName,value:res.data?.PartySiteId},
          })
          getPartySiteInfo(res.data?.PartySiteId)
          setStatus("success")
        } else if (
          res?.status === 500 ||
          res?.status === 400 ||
          res?.status === 404
        ) {
          const error = res?.data?.error;
          // common.snack("E", error || UPDATE_FAILED);
        }
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
        // common.snack("E", SAVE_FAILED);
      },
      (final) => {
        setIsLoading(false);
      }
    );
  }

  const onSubmit = (e) => {
    e.preventDefault();
    let isValid = handleSubmit();

    if (isValid) {
      save();
    }
  };



  const save = () => {
    setIsLoading(true);

    let params = {
      SiteName: fields.SiteName,
      SiteCode: fields.SiteCode,
      PartySiteId: addressInfo?.PartySiteId?.value,
      CustomerId: customerId,
      PrimaryFlag:fields?.PrimaryFlag !== undefined ? fields?.PrimaryFlag : "N",
      ShiptoFlag:fields?.ShiptoFlag !== undefined ? fields?.ShiptoFlag : "N",
      BilltoFlag:fields?.BilltoFlag !== undefined ? fields?.BilltoFlag : "N",
      OrgId: userData?.pOrgId,
      TenantId: userData?.pTenantId,
    };
    console.log({ params });

    let url = id ? `${envConfig.BASE_API}${CRM_CUSTOMER_SITE}/${id}` : `${envConfig.BASE_API}${CRM_CUSTOMER_SITE}`;

    let data = {
      url: url,
      type: "dynamic",
      method: id ? "PATCH" : "POST",
      body: JSON.stringify(params),
      auth: "token",
      cType: 4,
    };

    api.call(
      data,
      (res) => {
        if (res.data) {
          common.snack("S", SAVE_SUCCESS);
          navigate(BACK_URL);
        } else if (
          res?.status === 500 ||
          res?.status === 400 ||
          res?.status === 404
        ) {
          const error = res?.data?.error;
          common.snack("E", error || UPDATE_FAILED);
        }
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
        common.snack("E", SAVE_FAILED);
      },
      (final) => {
        setIsLoading(false);
      }
    );
  };
  return (
    <div style={{ padding: "20px" }}>
      <React.Fragment>
        {common.givenPermission(permission, "vendor:customers", "basic", 1) ? (
          <AccessDenied />
        ) : (
          <Paper
            sx={{ width: "100%", mb: 2 }}
            className={`container responsive-table p-0`}
          >
            <Box>
              <Paper variant="contained dynamic-form-wrap" square>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item display="flex" alignItems="center" xs={5} className="detail-head">
                    <Grid py={1} px={1} className="icon-back icon-buton">
                      <Link to={BACK_URL}>
                        <img
                          src={common.loadImg("backArrowKanban.svg")}
                          alt="back"
                        />
                      </Link>
                    </Grid>
                    <Grid className="df-main-head">
                      <Typography className="detail-title">
                        {id
                          ? "Edit Customer Site"
                          : "Add Customer Site"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item className="safari-style">
                    <Box px={2} className="safari-style">
                      <Grid container alignItems="center" spacing={2} className="safari-style">
                        <Grid item>
                          <Box className="verical-divider">
                            <Divider orientation="vertical" />
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box>
                            {status == "success" && (
                              <Button
                                className="save-note"
                                type="submit"
                                disabled={isLoading}
                                onClick={(e) => onSubmit(e)}
                              >
                                {isLoading ? "Processing..." : "Save Changes"}
                              </Button>
                            )}
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box className="vertical-divider">
                            <Divider orientation="vertical" />
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box className="revert-close">
                            <Button className="save-note pe-0">
                              <Link to={BACK_URL}>
                                Revert and Close
                                <img
                                  src={common.loadImg("closeKanban.svg")}
                                  alt="close"
                                />
                              </Link>
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
                <Box>
                  <Divider />
                </Box>
                <Box mt={2} className="camp-edit meet-table">
                  <Row className="m-0">
                    <Col
                      xs={6}
                      className="body-th common-input-style common-select-border mandatoryclass"
                    >
                      <Box className="filled-box">
                        {/* <TextField
                          id="filled-basic"
                          label="Party Name"
                          variant="filled"
                        /> */}
                        <FormControl fullWidth className="select-float-label ">
                          <FieldSelect
                            api="CRM_PARTY_SITES"
                            get="SiteName"
                            set="PartySiteId"
                            value={addressInfo?.PartySiteId}
                            isMulti={false}
                            isRender={true}
                            name="PartySiteId"
                            onSelect={(data) => {
                              getPartySiteInfo(data.value);
                              setAddressInfo((prev) => ({
                                ...addressInfo,
                                PartySiteId: data,
                              }));
                              setValue("PartySiteId", data);
                            }}
                            placeholder="Location Name"
                          />
                        </FormControl>
                        {errors.PartyId ? (
                          <p className="error-txt">{errors.PartyId}</p>
                        ) : null}
                      </Box>
                    </Col>
                    <Col xs={6} className="body-th common-input-style ">
                      <Box className="filled-box">
                        <TextField
                          id="filled-basic"
                          label="Site Code"
                          name="SiteCode"
                          variant="filled"
                          value={fields.SiteCode}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          disabled
                        />
                        {errors.SiteCode ? (
                          <p className="error-txt">{errors.SiteCode}</p>
                        ) : null}
                      </Box>
                    </Col>
                    <Col xs={6} className="body-th common-input-style ">
                      <Box className="filled-box">
                        <TextField
                          id="filled-basic"
                          label="Site Name"
                          name="SiteName"
                          variant="filled"
                          value={fields.SiteName}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                        {errors.SiteName ? (
                          <p className="error-txt">{errors.SiteName}</p>
                        ) : null}
                      </Box>
                    </Col>
                    <Col xs={6} className="body-th common-input-style"></Col>

                    <Col xs={6} className="body-th common-input-style">
                      
                      <FormControlLabel
                        className="dynamic-checkbox ms-0 checkbox-space"
                        control={<Checkbox />}
                        label="Primary"
                        checked={fields?.PrimaryFlag == "Y" ? true : false}
                        value={"Y"}
                        labelPlacement="start"
                        name="PrimaryFlag"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    </Col>
                    <Col xs={6} className="body-th common-input-style">
                      <FormControlLabel
                        className="dynamic-checkbox ms-0 checkbox-space"
                        control={<Checkbox />}
                        label="Bill To"
                        checked={fields?.BilltoFlag == "Y" ? true : false}
                        value="Y"
                        labelPlacement="start"
                        name="BilltoFlag"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    </Col>
                   {/* <Col xs={6} className="body-th common-input-style">
                      <FormControlLabel
                        className="dynamic-checkbox ms-0 checkbox-space"
                        control={<Checkbox />}
                        label="Service To"
                        checked={fields?.ServiceFlag == "Y" ? true : false}
                        value="Y"
                        labelPlacement="start"
                        name="ServiceFlag"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      </Col> */}
                    <Col xs={6} className="body-th common-input-style">
                      <FormControlLabel
                        className="dynamic-checkbox ms-0 checkbox-space"
                        control={<Checkbox />}
                        label="Ship To"
                        checked={fields?.ShiptoFlag == "Y" ? true : false}
                        labelPlacement="start"
                        name="ShiptoFlag"
                        value="Y"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    </Col>
                    <Col xs={6} className="body-th common-input-style">
                      
                    </Col>
                    <Col xs={6} className="body-th common-input-style">
                      <Box className="filled-box">
                        <TextField
                          id="filled-basic"
                          label="Address 1"
                          name="Address1"
                          variant="filled"
                          value={addressInfo?.Address1}
                          disabled
                        />
                      </Box>
                    </Col>
                    <Col xs={6} className="body-th common-input-style">
                      <Box className="filled-box">
                        <TextField
                          id="filled-basic"
                          label="Address 2"
                          name="Address2"
                          variant="filled"
                          value={addressInfo?.Address2}
                          disabled
                        />
                      </Box>
                    </Col>

                    <Col xs={6} className="body-th common-input-style">
                      <Box className="filled-box">
                        <TextField
                          id="filled-basic"
                          label="Country"
                          name="Country"
                          variant="filled"
                          value={addressInfo?.Country}
                          disabled
                        />
                        {errors.Country ? (
                          <p className="error-txt">{errors.Country}</p>
                        ) : null}
                      </Box>
                    </Col>

                    <Col xs={6} className="body-th common-input-style">
                      <Box className="filled-box">
                        <TextField
                          id="filled-basic"
                          label="State"
                          name="State"
                          variant="filled"
                          value={addressInfo?.State}
                          disabled
                        />
                        {errors.State ? (
                          <p className="error-txt">{errors.State}</p>
                        ) : null}
                      </Box>
                    </Col>

                    <Col xs={6} className="body-th common-input-style">
                      <Box className="filled-box">
                        <TextField
                          id="filled-basic"
                          label="City"
                          name="City"
                          variant="filled"
                          value={addressInfo?.City}
                          disabled
                        />
                      </Box>
                    </Col>
                    <Col xs={6} className="body-th common-input-style">
                      <Box className="filled-box">
                        <TextField
                          id="filled-basic"
                          label="Postal Code"
                          name="PostalCode"
                          variant="filled"
                          value={addressInfo?.PostalCode}
                          disabled
                        />
                        {errors.PostalCode ? (
                          <p className="error-txt">{errors.PostalCode}</p>
                        ) : null}
                      </Box>
                    </Col>
                  </Row>
                </Box>
              </Paper>
            </Box>
          </Paper>
        )}
      </React.Fragment>
    </div>
  );
}

export default AddSites;
