import React, { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Grid,
  Typography,
  Divider,
} from "@mui/material";
import { Link } from "react-router-dom";
import { common } from "helpers";
import Select from "react-select";
import FormControl from "@mui/material/FormControl";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Popover from "@mui/material/Popover";

const DepartmentList = () => {
  const options = [{ value: "View All", label: "View All" }];
  const [settingOption, setSettingOption] = useState(options[0]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickExport = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openNotification = Boolean(anchorEl);
  const id = openNotification ? "simple-popover" : undefined;

  return (
    <Box px={5} mt={3} className="template-box-border">
      <div className="row">
        <div className="col-12 mt-2 ">
          <div className="card border-color-F2F9FD">
            <div className="card-body header-border-padding p-0">
              <Box alignItems="center" justifyContent="center" className="">
                <Box pt={4} pb={1}>
                  <Grid
                    container
                    spacing={3}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Grid container spacing={4} alignItems="center">
                        <Grid item>
                          <Grid container alignItems="center">
                            <Grid item>
                              <Link to="/setting/view/hrms">
                                <img
                                  alt={"back"}
                                  className="mx-2"
                                  src={common.loadImg(
                                    "iconly-light-arrow-down-circle.svg"
                                  )}
                                />
                              </Link>
                            </Grid>
                            <Grid item>
                              <Typography className="page-title">
                                Departments
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Divider />
                </Box>
                <div className="row p-4">
                  <div className="col-lg-12 text-center chart-wrap">
                    <div className="zoomer-wrap">
                      <div className="zoom-in">
                        <img
                          src={common.loadImg("plus-icn.svg")}
                          alt="document"
                        />
                      </div>
                      <div className="zoom-out">
                        <img
                          src={common.loadImg("minus-icn.svg")}
                          alt="document"
                        />
                      </div>
                    </div>
                    <div className="chart-start">
                      <div className="chart-parent-wrap">
                        <div className="chart-card chart-parent">
                          <div className="add-left">
                            <IconButton className="more-icon">
                              <img
                                src={common.loadImg("plus-icn.svg")}
                                alt="add"
                                className="add-left-btn"
                              />
                            </IconButton>
                          </div>
                          <div className="add-right">
                            <IconButton className="more-icon">
                              <img
                                src={common.loadImg("plus-icn.svg")}
                                alt="add"
                                className="add-right-btn"
                              />
                            </IconButton>
                          </div>
                          <p className="title">Management</p>
                          <label className="label">Novelwall</label>
                          <div className="chart-footer">
                            <div>
                              <img
                                src={common.loadImg("users-icn.svg")}
                                alt="document"
                                className="pe-2"
                              />
                              <label className="count">06</label>
                            </div>
                            <div>
                              <IconButton
                                className="more-icon"
                                onClick={handleClickExport}
                              >
                                <MoreHorizIcon />
                              </IconButton>

                              <Popover
                                id={id}
                                open={openNotification}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                              >
                                <Typography>
                                  <Button
                                    variant=""
                                    sx={{ px: 2 }}
                                    className="more-options"
                                  >
                                    Option 1
                                  </Button>
                                </Typography>
                                <Typography>
                                  <Button
                                    variant=""
                                    sx={{ px: 2 }}
                                    className="more-options"
                                  >
                                    Option 2
                                  </Button>
                                </Typography>
                              </Popover>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="chart-child-wrap">
                        <div>
                          <div className="chart-child-row">
                            <div className="chart-row-col">
                              <div className="second-step-wrap">
                                <div className="chart-parent-wrap">
                                  <div className="chart-card">
                                    <div className="add-right">
                                      <IconButton className="more-icon">
                                        <img
                                          src={common.loadImg("plus-icn.svg")}
                                          alt="add"
                                          className="add-right-btn"
                                        />
                                      </IconButton>
                                    </div>
                                    <p className="title">Accounting</p>
                                    <label className="label">Novelwall</label>
                                    <div className="chart-footer">
                                      <div>
                                        <img
                                          src={common.loadImg("users-icn.svg")}
                                          alt="document"
                                          className="pe-2"
                                        />
                                        <label className="count">04</label>
                                      </div>
                                      <div>
                                        <IconButton
                                          className="more-icon"
                                          onClick={handleClickExport}
                                        >
                                          <MoreHorizIcon />
                                        </IconButton>
                                        <Popover
                                          id={id}
                                          open={openNotification}
                                          anchorEl={anchorEl}
                                          onClose={handleClose}
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                          }}
                                        >
                                          <Typography>
                                            <Button
                                              variant=""
                                              sx={{ px: 2 }}
                                              className="more-options"
                                            >
                                              Option 1
                                            </Button>
                                          </Typography>
                                          <Typography>
                                            <Button
                                              variant=""
                                              sx={{ px: 2 }}
                                              className="more-options"
                                            >
                                              Option 2
                                            </Button>
                                          </Typography>
                                        </Popover>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="chart-sub-row">
                                <div className="sub-col">
                                  <div className="chart-card">
                                    <div className="add-right">
                                      <IconButton className="more-icon">
                                        <img
                                          src={common.loadImg("plus-icn.svg")}
                                          alt="add"
                                          className="add-right-btn"
                                        />
                                      </IconButton>
                                    </div>
                                    <p className="title">EDU</p>
                                    <label className="label">Novelwall</label>
                                    <div className="chart-footer">
                                      <div>
                                        <img
                                          src={common.loadImg("users-icn.svg")}
                                          alt="document"
                                          className="pe-2"
                                        />
                                        <label className="count">06</label>
                                      </div>
                                      <div>
                                        <IconButton
                                          className="more-icon"
                                          onClick={handleClickExport}
                                        >
                                          <MoreHorizIcon />
                                        </IconButton>

                                        <Popover
                                          id={id}
                                          open={openNotification}
                                          anchorEl={anchorEl}
                                          onClose={handleClose}
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                          }}
                                        >
                                          <Typography>
                                            <Button
                                              variant=""
                                              sx={{ px: 2 }}
                                              className="more-options"
                                            >
                                              Option 1
                                            </Button>
                                          </Typography>
                                          <Typography>
                                            <Button
                                              variant=""
                                              sx={{ px: 2 }}
                                              className="more-options"
                                            >
                                              Option 2
                                            </Button>
                                          </Typography>
                                        </Popover>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="sub-col">
                                  <div className="chart-card group-card">
                                    <div className="add-right">
                                      <IconButton className="more-icon">
                                        <img
                                          src={common.loadImg("plus-icn.svg")}
                                          alt="add"
                                          className="add-right-btn"
                                        />
                                      </IconButton>
                                    </div>
                                    <p className="title">Group</p>
                                    <label className="label">Novelwall</label>
                                    <div className="chart-footer">
                                      <div>
                                        <img
                                          src={common.loadImg("users-icn.svg")}
                                          alt="document"
                                          className="pe-2"
                                        />
                                        <label className="count">03</label>
                                      </div>
                                      <div>
                                        <IconButton
                                          className="more-icon"
                                          onClick={handleClickExport}
                                        >
                                          <MoreHorizIcon />
                                        </IconButton>

                                        <Popover
                                          id={id}
                                          open={openNotification}
                                          anchorEl={anchorEl}
                                          onClose={handleClose}
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                          }}
                                        >
                                          <Typography>
                                            <Button
                                              variant=""
                                              sx={{ px: 2 }}
                                              className="more-options"
                                            >
                                              Option 1
                                            </Button>
                                          </Typography>
                                          <Typography>
                                            <Button
                                              variant=""
                                              sx={{ px: 2 }}
                                              className="more-options"
                                            >
                                              Option 2
                                            </Button>
                                          </Typography>
                                        </Popover>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="chart-row-col">
                              <div className="second-step-wrap">
                                <div className="chart-parent-wrap">
                                  <div className="chart-card hr-card">
                                    <div className="add-right">
                                      <IconButton className="more-icon">
                                        <img
                                          src={common.loadImg("plus-icn.svg")}
                                          alt="add"
                                          className="add-right-btn"
                                        />
                                      </IconButton>
                                    </div>
                                    <p className="title">HR</p>
                                    <label className="label">Novelwall</label>
                                    <div className="chart-footer">
                                      <div>
                                        <img
                                          src={common.loadImg("users-icn.svg")}
                                          alt="document"
                                          className="pe-2"
                                        />
                                        <label className="count">02</label>
                                      </div>
                                      <div>
                                        <IconButton
                                          className="more-icon"
                                          onClick={handleClickExport}
                                        >
                                          <MoreHorizIcon />
                                        </IconButton>

                                        <Popover
                                          id={id}
                                          open={openNotification}
                                          anchorEl={anchorEl}
                                          onClose={handleClose}
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                          }}
                                        >
                                          <Typography>
                                            <Button
                                              variant=""
                                              sx={{ px: 2 }}
                                              className="more-options"
                                            >
                                              Option 1
                                            </Button>
                                          </Typography>
                                          <Typography>
                                            <Button
                                              variant=""
                                              sx={{ px: 2 }}
                                              className="more-options"
                                            >
                                              Option 2
                                            </Button>
                                          </Typography>
                                        </Popover>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="chart-sub-row">
                                <div className="sub-col">
                                  <div className="chart-card resources-card">
                                    <div className="add-right">
                                      <IconButton className="more-icon">
                                        <img
                                          src={common.loadImg("plus-icn.svg")}
                                          alt="add"
                                          className="add-right-btn"
                                        />
                                      </IconButton>
                                    </div>
                                    <p className="title">Resources</p>
                                    <label className="label">Novelwall</label>
                                    <div className="chart-footer">
                                      <div>
                                        <img
                                          src={common.loadImg("users-icn.svg")}
                                          alt="document"
                                          className="pe-2"
                                        />
                                        <label className="count">06</label>
                                      </div>
                                      <div>
                                        <IconButton
                                          className="more-icon"
                                          onClick={handleClickExport}
                                        >
                                          <MoreHorizIcon />
                                        </IconButton>

                                        <Popover
                                          id={id}
                                          open={openNotification}
                                          anchorEl={anchorEl}
                                          onClose={handleClose}
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                          }}
                                        >
                                          <Typography>
                                            <Button
                                              variant=""
                                              sx={{ px: 2 }}
                                              className="more-options"
                                            >
                                              Option 1
                                            </Button>
                                          </Typography>
                                          <Typography>
                                            <Button
                                              variant=""
                                              sx={{ px: 2 }}
                                              className="more-options"
                                            >
                                              Option 2
                                            </Button>
                                          </Typography>
                                        </Popover>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export { DepartmentList };
