import React, { useEffect, useCallback, useState } from "react";

import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import { Link, useOutletContext } from "react-router-dom";
import Typography from "@mui/material/Typography";
import InfiniteScroll from "react-infinite-scroll-component";
import { FormControl, InputAdornment, OutlinedInput } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import Popover from "@mui/material/Popover";
import AccessDenied from "elements/AccessDenied";
import AddRole from "./addRole";
import AddResponsibility from "./addResponsibility";
import ModalConfirm from "elements/ModalConfirm";

import { api, common, envConfig } from "helpers";
import { CRM_ROLES_HDR, CRM_ROLES_DTL } from "helpers/service";
import { useDebounce } from "helpers/debounce";
import Loader from "elements/Loader";
import StatusBar from "elements/StatusBar";
let ModNameChange = envConfig.ModNameChange;

const LIST_LIMIT = 10;
const TIMER_INTERVAL = 1000;
const RESPONSIBILITY_REMOVED_SUCCESS = "record removed success";

const RolesResponsibility = () => {
  const [permission] = useOutletContext();

  const [open, setOpen] = useState(false);
  const [userInfo] = useState(common.authInfo());
  const [responsibilityOpen, setResponsibilityOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [resultList, setResultList] = useState([]);
  const [total, setTotal] = useState(0);
  const [hasMore, setHasMore] = useState(null);
  const [fullPageLoading, setFullPageLoading] = useState(false);
  const [responsibilityList, setResponsibilityList] = useState([]);
  const [isLoading, setIsLoading] = useState({
    a: false,
    b: false,
  });
  const [deleteID, setDeleteID] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchWord, setSearchWord] = useState("");

  const debouncedSearchTerm = useDebounce(searchWord, TIMER_INTERVAL);
  const [roleInfo, setRoleInfo] = useState({
    RoleHdrId: null,
    GroupId: null,
    TenantId: userInfo?.TenantId,
  });

  const [rightAnchorEl, setRightAnchorEl] = useState(null);

  useEffect(() => {
    setFullPageLoading(true);
  }, []);

  const loadRoleRespList = useCallback((pageNo, searchText) => {
    setPage(pageNo);

    let userData = common.authInfo();
    let url = `${envConfig.BASE_API}${CRM_ROLES_HDR}?q=TenantId=${userData.TenantId}`;

    if (searchText) {
      url = `${url} AND UPPER(GroupDesc) LIKE '*${searchText}*'`;
    }
    url = `${url}&expand=all&totalResults=true&orderBy=CreatedOn:desc&offset=${
      pageNo * LIST_LIMIT
    }&limit=${LIST_LIMIT}`;

    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
      moreHead: { rfv: 2 },
      cType: 4,
    };

    api.call(
      data,
      (response) => {
        if (response) {
          let result = response.data;

          setResultList((oldData) => {
            if (pageNo === 0) {
              return result.items;
            } else {
              return [...new Set([...oldData, ...result.items])];
            }
          });

          if (result.items?.length > 0) {
            if (pageNo === 0) {
              setSelectedItem(result.items[0]);
              setRoleInfo((oldState) => ({
                ...oldState,
                GroupId: result.items[0].GroupId,
                RoleHdrId: result.items[0].RoleHdrId,
              }));
              Responsibilities(result.items[0].RoleHdrId);
            } else {
              setSelectedItem(result.items[selectedIndex]);
            }
          } else {
            setSelectedItem(null);
          }
          setTotal(result.totalResults);
          setHasMore(result.hasMore);
        }

        setFullPageLoading(false);
        setIsLoading((prev) => ({ ...prev, a: false, b: false }));
      },
      (error) => {
        setFullPageLoading(false);

        setIsLoading((prev) => ({ ...prev, a: false, b: false }));
      },
      (final) => {
        setFullPageLoading(false);
        setIsLoading((prev) => ({ ...prev, a: false, b: false }));
      }
    );
  }, []);

  const Responsibilities = (RoleHdrId) => {
    setIsLoading((prev) => ({ ...prev, b: true }));
    api.call(
      {
        url: `${envConfig.BASE_API}${CRM_ROLES_DTL}?q=RoleHdrId=${RoleHdrId}`,
        type: "dynamic",
        method: "GET",
        auth: "token",
        moreHead: { rfv: 2 },
        cType: 4,
      },
      (response) => {
        if (response.status === 200) {
          setResponsibilityList(response?.data?.items);
          setIsLoading((prev) => ({ ...prev, b: false }));
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  useEffect(() => {
    setIsLoading((prev) => ({ ...prev, a: true, b: true }));
    loadRoleRespList(0, debouncedSearchTerm);
  }, [loadRoleRespList, debouncedSearchTerm]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickResOpen = () => {
    setResponsibilityOpen(true);
  };
  const handleResClose = () => {
    setResponsibilityOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
    // Reloads();
  };
  const handleSearchChange = (event) => {
    setSearchWord(event.target.value);
    setPage(1);
  };

  const handleSelect = (item, index) => {
    setSelectedItem(item);
    setSelectedIndex(index);
    setRoleInfo((oldState) => ({
      ...oldState,
      GroupId: item.GroupId,
      RoleHdrId: item.RoleHdrId,
    }));
    Responsibilities(item.RoleHdrId);
  };

  const detailsMoreClick = (event) => {
    setRightAnchorEl(event.currentTarget);
  };
  const detailsCloseClick = () => {
    setRightAnchorEl(null);
  };

  const ReloadsB = useCallback(() => {
    setPage(0);
    setFullPageLoading(true);
    setIsLoading((prev) => ({ ...prev, b: true }));
    setTimeout(() => {
      loadRoleRespList(0, debouncedSearchTerm);
    }, 4000);
  }, []);

  const ReloadsA = (child = false) => {
    Responsibilities(roleInfo?.RoleHdrId);
  };

  const rightMoreOpen = Boolean(rightAnchorEl);

  const deleteData = (id) => {
    //
    setIsLoading((prev) => ({ ...prev, b: true }));
    api.call(
      {
        url: `${envConfig.BASE_API}${CRM_ROLES_DTL}/${id}`,
        type: "dynamic",
        method: "DELETE",
        auth: "token",
        moreHead: { rfv: 2 },
        cType: 4,
      },
      (response) => {
        if (response.status === 204 || response.status === 200) {
          Responsibilities(roleInfo?.RoleHdrId);
          common.snack("S", RESPONSIBILITY_REMOVED_SUCCESS);
        }
      },
      (error) => {},
      (final) => {}
    );
    setDeleteID(null);
  };

  return (
    <React.Fragment>
      {common.givenPermission(
        permission,
        "hrms:roles & responsibilities",
        "read"
      ) ? (
        <AccessDenied />
      ) : (
        <Box p={3}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <div className="card border-color-F2F9FD sidebar-card no-border">
                <div className="card-body p-0">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    className="py-2"
                  >
                    <div className="col-md-7">
                      <h5 className="d-flex align-items-center title mb-0">
                        <Link
                          to="/setting/view/hrms"
                          className="text-decoration-none"
                        >
                          <img
                            alt={"back"}
                            src={common.loadImg(
                              "iconly-light-arrow-down-circle.svg"
                            )}
                            width={30}
                            height={30}
                          />
                        </Link>
                        <label className="d-flex align-items-center page-title">
                          {ModNameChange ? "Permissions" : "Roles"}
                        </label>
                      </h5>
                    </div>
                    {!common.givenPermission(
                      permission,
                      "hrms:roles & responsibilities",
                      "add"
                    ) ? (
                      <div className="d-flex align-items-center">
                        <Link
                          to="#"
                          onClick={handleClickOpen}
                          className="text-decoration-none"
                        >
                          <span className="mx-2 add-new">Add New</span>
                        </Link>

                        <div className="add-new-button d-flex align-items-center justify-content-center">
                          <Link
                            to="#"
                            className="text-decoration-none"
                            onClick={handleClickOpen}
                          >
                            <span className="d-flex align-items-center plus-icon">
                              +
                            </span>
                          </Link>
                        </div>
                      </div>
                    ) : null}
                  </Box>
                  <div className="d-flex align-items-center bg-F2F9FD p-1">
                    <Box className="filter-search" width="100%">
                      <FormControl sx={{ width: "100%" }}>
                        <OutlinedInput
                          id="search"
                          value={searchWord}
                          onChange={(event) => handleSearchChange(event)}
                          placeholder={"Search"}
                          endAdornment={
                            <InputAdornment position="end">
                              <img
                                src={common.loadImg("common-search.svg")}
                                alt="search"
                              />
                            </InputAdornment>
                          }
                          aria-describedby="outlined-weight-helper-text"
                        />
                      </FormControl>
                    </Box>
                  </div>

                  <div
                    className="bg-white sidebar-height employee-user-list"
                    id="scrollBottom"
                  >
                    {fullPageLoading ? (
                      <StatusBar status="process" />
                    ) : (
                      <Grid container direction="column">
                        <InfiniteScroll
                          // scrollThreshold={0.6}
                          next={() => loadRoleRespList(page + 1, searchWord)}
                          hasMore={hasMore}
                          loader={!isLoading.a ? <Loader /> : null}
                          dataLength={resultList?.length || 0}
                          scrollableTarget="scrollBottom"
                        >
                          {isLoading.a ? (
                            <StatusBar status="process" />
                          ) : resultList?.length ? (
                            resultList?.map((item, index) => {
                              const nameArr =
                                item.GroupDesc?.split(" ") || "Role";
                              const initial =
                                nameArr?.length > 1
                                  ? `${
                                      nameArr[0] ? nameArr[0].slice(0, 1) : ""
                                    }${
                                      nameArr[1] ? nameArr[1].slice(0, 1) : ""
                                    }`
                                  : item.GroupDesc?.slice(0, 2);

                              return (
                                <Grid item key={index}>
                                  <Box
                                    className="pointer color-divider"
                                    onClick={() => handleSelect(item, index)}
                                  >
                                    <Grid container alignItems="center">
                                      <Grid item xs={2} mt={0.5}>
                                        <Box px={2} py={1}>
                                          <Box className="img-box">
                                            {(initial || "").toUpperCase()}
                                          </Box>
                                        </Box>
                                        {index === selectedIndex ? (
                                          <div className="active active-location"></div>
                                        ) : null}
                                      </Grid>

                                      <Grid
                                        item
                                        xs={9}
                                        className="divider-color"
                                      >
                                        <Grid
                                          container
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          <Grid item>
                                            <ul className="list-name-location mb-0">
                                              <li
                                                className={
                                                  index === selectedIndex
                                                    ? "location-names-active "
                                                    : "location-names "
                                                }
                                              >
                                                {common.textCapitalize(
                                                  item.GroupDesc
                                                    ? item.GroupDesc
                                                    : "Role"
                                                )}
                                              </li>

                                              <li className="setting-card-content mt-1">
                                                {common.textCapitalize(
                                                  item.EmployeeCode
                                                )}
                                              </li>
                                            </ul>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </Grid>
                              );
                            })
                          ) : (
                            <StatusBar status="empty" />
                          )}
                        </InfiniteScroll>
                      </Grid>
                    )}
                  </div>
                </div>
                {!fullPageLoading ? (
                  <Box
                    sx={{ width: 1 }}
                    px={1}
                    py={1}
                    className="location-footer"
                  >
                    {!isLoading?.a && total
                      ? "Showing" +
                        " " +
                        `${resultList?.length ? 1 : 0} to ${
                          resultList?.length || 0
                        } of ${total || 0}`
                      : null}
                  </Box>
                ) : null}
              </div>
            </Grid>
            <Grid item xs={8}>
              <Box>
                <div className="role-border permission-table">
                  <div className="d-flex justify-content-between bottom-border-divider">
                    <h5 className="right-section-title my-3 ms-3 col-md-6">
                      Responsibilities{" "}
                    </h5>
                    <div className="col-md-6 d-flex">
                      <div className="col-md-6 me-4 d-flex align-self-center filter-roles">
                        {/* <FormControl className=" w-100"> */}
                        {/* <InputLabel id="filter-roles-label">View all</InputLabel> */}
                        {/* <Select
                        className="text-box "
                        labelId="demo-simple-select-label"
                        placeholder="View all"
                      ></Select>
                    </FormControl> */}
                      </div>
                      {!common.givenPermission(
                        permission,
                        "hrms:roles & responsibilities",
                        "add"
                      ) ? (
                        <div className="col-md-5 ps-3 d-flex align-items-center left-border">
                          <Link
                            to="#"
                            onClick={handleClickResOpen}
                            className="text-decoration-none"
                          >
                            <span className="mx-2 add-new">Add New</span>
                          </Link>

                          <div className="add-new-button d-flex align-items-center justify-content-center">
                            <Link
                              to="#"
                              className="text-decoration-none"
                              onClick={handleClickResOpen}
                            >
                              <span className="d-flex align-items-center plus-icon">
                                +
                              </span>
                            </Link>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <TableContainer
                    component={Paper}
                    className="role-table role-response-table"
                  >
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell className="respond-width">
                            Responsibility
                          </TableCell>
                          <TableCell>Area</TableCell>
                          <TableCell>Shift</TableCell>
                          <TableCell className="carry-width">
                            Carrry Forward
                          </TableCell>
                          <TableCell className="report-width">
                            Reporting Frequency
                          </TableCell>
                          <TableCell>Duration</TableCell>
                          <TableCell align="center">Action</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {isLoading.b ? (
                          <TableRow sx={{ border: 0 }}>
                            <TableCell colSpan={7} component="td">
                              <StatusBar status="process" />
                            </TableCell>
                          </TableRow>
                        ) : responsibilityList?.length === 0 ? (
                          <TableRow sx={{ border: 0 }}>
                            <TableCell colSpan={7} component="td">
                              <StatusBar status="empty" />
                            </TableCell>
                          </TableRow>
                        ) : (
                          responsibilityList?.map((row, idx) => (
                            <TableRow key={idx} sx={{ border: 0 }}>
                              <TableCell
                                component="th"
                                scope="row"
                                className="module-respons-padd"
                              >
                                {common.textCapitalize(
                                  row.Responsibilities || ""
                                )}
                              </TableCell>
                              <TableCell>
                                {common.textCapitalize(row.Area || "")}
                              </TableCell>
                              <TableCell>{row.Shift}</TableCell>
                              <TableCell>
                                <div className="carry-bg">
                                  {row.CarryForward == "Y" ? "Yes" : "No"}
                                </div>
                              </TableCell>
                              <TableCell>{row.ReportingFreq}</TableCell>
                              <TableCell>{row.Duration}</TableCell>
                              <TableCell align="center">
                                <div className="my-auto mx-auto ">
                                  {!common.givenPermission(
                                    permission,
                                    "hrms:roles & responsibilities",
                                    "delete"
                                  ) ? (
                                    <MoreHorizOutlinedIcon
                                      className="pointer"
                                      aria-describedby={"right-more-popover"}
                                      onClick={detailsMoreClick}
                                    />
                                  ) : null}

                                  {!common.givenPermission(
                                    permission,
                                    "hrms:roles & responsibilities",
                                    "delete"
                                  ) ? (
                                    <Popover
                                      id={"right-more-popover"}
                                      open={rightMoreOpen}
                                      anchorEl={rightAnchorEl}
                                      onClose={detailsCloseClick}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      disableScrollLock
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                      onClick={() => {
                                        setDeleteID(row?.RoleDtlId);
                                        detailsCloseClick();
                                      }}
                                    >
                                      <Typography
                                        sx={{ p: 2 }}
                                        className="text-capitalize menu-item crm-cp"
                                      >
                                        Delete
                                      </Typography>
                                    </Popover>
                                  ) : null}
                                </div>
                              </TableCell>
                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Box>
            </Grid>

            <AddRole
              open={open}
              handleClose={handleClose}
              onReload={ReloadsB}
            />
            <AddResponsibility
              responsibilityOpen={responsibilityOpen}
              handleResClose={handleResClose}
              roleInfo={roleInfo}
              Reload={ReloadsA}
            />
            {deleteID ? (
              <ModalConfirm
                onYes={() => deleteData(deleteID)}
                onNo={() => setDeleteID(null)}
              />
            ) : null}
          </Grid>
        </Box>
      )}
    </React.Fragment>
  );
};
export { RolesResponsibility };
