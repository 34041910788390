import React, {useEffect} from "react";
import { common } from "helpers";
import { Link, useNavigate } from "react-router-dom";

let isPickauniDomain = window.location.hostname == 'uams.pickauni.international';

function Home() {
  const navigate = useNavigate();

  useEffect(() => {
    if(isPickauniDomain){
      navigate("/signin");
    }
  }, []);

  return (
    <div className="container-fluid landing-wrap">
      <div className="row">
        <div className="col-12 py-2 landing-header">
          <Link to="/signin" className="sign-in-btn">
            Login
          </Link>
          <Link to="/signup" className="sign-up-btn">
            Sign Up
          </Link>
        </div>
      </div>
      <div className="container landing-hero-sec">
        <div className="row">
            <div className="col-md-4">
                <img src={common.loadImg("crm-full-logo.png")} alt="crm-logo" />
                <h1 className="crm-tagline pt-4">A simple and smart tool that will help grow you business</h1>
                <p className="crm-desc pt-4">OSMO CRM helps to automates you daily workflow, coordicates your team and save you loads of time.</p>
            </div>
            <div className="col-md-8">
                <img src={common.loadImg("crm-img.png")} className="w-75" alt="crm-logo" />
            </div>
        </div>
      </div>
    </div>
  );
}
export default Home;
