import React from "react";
import { common } from "helpers";
import { Container, Row, Col } from "react-bootstrap";

function AccessDenied() {
  return (
    <Container
      fluid
      className="loader-wrap py-5 d-flex justify-content-center align-items-center"
    >
      <Row>
        <Col lg={12} className="text-center">
          <img
            src={common.loadImg("accessdenied.png")}
            alt="Access Denied"
            className="loader-img"
          />
          <h5>Access Denied</h5>
          <p>Sorry, But you don't have permission to access this page</p>
        </Col>
      </Row>
    </Container>
  );
}
export default AccessDenied;
