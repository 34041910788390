import React, { useCallback, useEffect, useState } from "react";
import {
  Link,
  useOutletContext,
  useParams,
  useNavigate,
} from "react-router-dom";
import moment from "moment";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import { common, envConfig, api } from "helpers";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
  Checkbox,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import AccessDenied from "elements/AccessDenied";
import TextField from "@mui/material/TextField";
import InputIcon from "react-multi-date-picker/components/input_icon";
import DatePicker from "react-multi-date-picker";
import StatusBar from "elements/StatusBar";
import AmlHashForm from "aml-hash-form";
import FieldSelect from "elements/FieldSelect";
import FieldLookup from "elements/FieldLookup";
import { CRM_PARTY_HDR, CRM_CUSTOMER } from "helpers/service";

const SAVE_SUCCESS = "Customer details saved successfully.";
const SAVE_FAILED = "Customer save failed.";
const UPDATE_FAILED = "Customer update failed.";
const BACK_URL = "/setting/vendor/customer";

function AddCustomer(props) {
  //init
  const [permission] = useOutletContext();
  // render
  const { id } = useParams();
  const userData = common.userInfo();
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [isActive1, setIsActive1] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cusTy, setCusTy] = useState([]);

  const [status, setStatus] = useState(id ? "process" : "success");
  const CustomerId = id;
  const formFields = {
    TenantId: userData.pTenantId,
    OrgId: userData.pOrgId,

    PartyId: { validate: ["objValReq#Party Name is required"], obj: "value" },
    StartDate: { validate: ["req#Start Date is required"] },
    Active: { value: false },
    EndDate: { validate: [] },
    CustomerName: { validate: ["req#Customer Name is required"] },
    CustomerCode: { validate: ["req#Customer Code is required"] },
    //CustomerRating: { value: true },

    CustomerType: {
      validate: ["objValReq#Customer Type is required"],
      obj: "value",
    },
  };

  const {
    fields,
    errors,
    setValue,
    handleChange,
    handleSubmit,
    setMultiValue,
  } = AmlHashForm(formFields);
  useEffect(() => {
    if (CustomerId) {
      getCustomerInfo();
      // getCustomersite();
    }
  }, []);
  // const onPartyChange = (data) => {
  //   setValue("PartyId", data);
  //   getPartyInfo(data.value);
  // };
  const handleRating = (e, rate) => {
    fields.CustomerRating = rate;
    setValue("Rating", rate);
  };

  const findJsonArray = (arrayArg, key, val) => {
    let response = {};
    arrayArg.map((item) => {
      if (item[key] === val) {
        response = item;
      }
    });
    return response;
  };

  useEffect(() => {
    if (CustomerId) {
      if (fields?.CustomerType && cusTy.length) {
        setValue(
          "CustomerType",
          findJsonArray(cusTy, "value", fields?.CustomerType)
        );
      }
    }
  }, [cusTy]);

  const getPartyInfo = (PartyId, cusInfo) => {
    let url = `${envConfig.BASE_API}${CRM_PARTY_HDR}/${PartyId}`;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };

    api.call(
      data,
      (response) => {
        if (response.status === 200) {
          const result = response.data;
          setMultiValue({
            PartyId: result.PartyId
              ? {
                  label: result.PartyName,
                  value: String(cusInfo.PartyId),
                }
              : null,
            CustomerCode: cusInfo.CustomerCode || "",
            CustomerName: cusInfo.CustomerName || "",
            CustomerType: cusInfo.CustomerType
              ? {
                  label: cusInfo.CustomerType,
                  value: String(cusInfo.CustomerType),
                }
              : null,
            CustomerRating: cusInfo.CustomerRating || "",
            StartDate: cusInfo.StartDate || "",
            EndDate: cusInfo.EndDate || "",
            Active: cusInfo.Active === "Y",
            TenantId: cusInfo.pTenantId || "",
            OrgId: cusInfo.OrgId || "",
          });
          //cusInfo
          if (result) {
            // setMultiValue({
            //   PartyCode: result.PartyCode || "",
            //   PartyName: result.PartyName || "",
            // });
          }
        }
        setStatus("success");
      },
      (error) => {
        setStatus("error");
      },
      (final) => {}
    );
  };

  const getCustomerInfo = () => {
    let url = `${envConfig.BASE_API}${CRM_CUSTOMER}/${CustomerId}`;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };

    api.call(
      data,
      (response) => {
        if (response.status === 200) {
          const result = response.data;
          if (result) {
            getPartyInfo(result.PartyId, result);
          }
        }
        setStatus("success");
      },
      (error) => {
        setStatus("error");
      },
      (final) => {}
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let isValid = handleSubmit();

    if (isValid) {
      save();
    }
  };

  const save = () => {
    setIsLoading(true);
    let params = {
      PartyId: fields.PartyId?.value,
      CustomerCode: fields.CustomerCode,
      CustomerName: fields.CustomerName,
      CustomerType: fields.CustomerType?.value,
      // CustomerType: fields.CustomerType,
      CustomerRating: fields.CustomerRating,
      StartDate: fields.StartDate
        ? common.formatDate(fields.StartDate, "YYYY-MM-DDTHH:mm:ssZ")
        : null,
      EndDate: fields.EndDate
        ? common.formatDate(fields.EndDate, "YYYY-MM-DDTHH:mm:ssZ")
        : null,

      Active: fields.Active ? "Y" : "N",
      TenantId: userData?.pTenantId,
      OrgId: userData?.pOrgId,
    };

    let url = `${envConfig.BASE_API}${CRM_CUSTOMER}`;
    if (CustomerId) {
      url = `${envConfig.BASE_API}${CRM_CUSTOMER}/${CustomerId}`;
    }
    let data = {
      url: url,
      type: "dynamic",
      method: CustomerId ? "PATCH" : "POST",
      body: JSON.stringify(params),
      auth: "token",
      cType: 4,
    };

    api.call(
      data,
      (res) => {
        if (res.data) {
          if (CustomerId) {
            common.snack("S", SAVE_SUCCESS);
          } else {
            common.snack("S", SAVE_SUCCESS);
          }
          navigate(BACK_URL);
        } else if (
          res?.status === 500 ||
          res?.status === 400 ||
          res?.status === 404
        ) {
          const error = res?.data?.error;
          if (CustomerId) {
            common.snack("E", error || UPDATE_FAILED);
          } else {
            common.snack("E", error || SAVE_FAILED);
          }
        }
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
        common.snack("E", SAVE_FAILED);
      },
      (final) => {
        setIsLoading(false);
      }
    );
  };
  return (
    <div style={{ padding: "20px" }}>
      <React.Fragment>
        {common.givenPermission(permission, "vendor:customers", "basic", 1) ? (
          <AccessDenied />
        ) : (
          <Paper
            sx={{ width: "100%", mb: 2 }}
            className={`container responsive-table p-0`}
          >
            <Box>
              <Paper variant="contained dynamic-form-wrap" square>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item display="flex" alignItems="center" xs={5} className="detail-head">
                    <Grid py={1} px={1} className="icon-back icon-buton">
                      <Link to={BACK_URL}>
                        <img
                          src={common.loadImg("backArrowKanban.svg")}
                          alt="back"
                        />
                      </Link>
                    </Grid>
                    <Grid className="df-main-head">
                      <Typography className="detail-title">
                        {CustomerId ? "Edit Customer" : "Add Customer"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item className="safari-style">
                    <Box px={2} className="safari-style">
                      <Grid container alignItems="center" spacing={2} className="safari-style">
                        <Grid item>
                          <Box className="verical-divider">
                            <Divider orientation="vertical" />
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box>
                            {status == "success" && (
                              <Button
                                className="save-note"
                                type="submit"
                                disabled={isLoading}
                                onClick={(e) => onSubmit(e)}
                              >
                                {isLoading ? "Processing..." : "Save Changes"}
                              </Button>
                            )}
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box className="vertical-divider">
                            <Divider orientation="vertical" />
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box className="revert-close">
                            <Button className="save-note pe-0">
                              <Link to={BACK_URL}>
                                Revert and Close
                                <img
                                  src={common.loadImg("closeKanban.svg")}
                                  alt="close"
                                />
                              </Link>
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
                <Box>
                  <Divider />
                </Box>
                <Box mt={2} className="camp-edit meet-table">
                  <Row className="m-0">
                    <Col
                      xs={6}
                      className="body-th common-input-style common-select-border mandatoryclass"
                    >
                      <Box className="filled-box">
                        {/* <TextField
                          id="filled-basic"
                          label="Party Name"
                          variant="filled"
                        /> */}
                        <FormControl fullWidth className="select-float-label ">
                          <FieldSelect
                            api="CRM_PARTY_HDR"
                            get="PartyName"
                            set="PartyId"
                            value={fields.PartyId}
                            isMulti={false}
                            isRender={true}
                            name="PartyId"
                            onSelect={(data) => setValue("PartyId", data)}
                            placeholder="Party Name"
                          />
                        </FormControl>
                        {errors.PartyId ? (
                          <p className="error-txt">{errors.PartyId}</p>
                        ) : null}
                      </Box>
                    </Col>
                    <Col
                      xs={6}
                      className="body-th common-input-style mandatoryclass"
                    >
                      <Box className="filled-box"></Box>{" "}
                      <Box className="filled-box">
                        <TextField
                          id="filled-basic"
                          label="Customer Code"
                          name="CustomerCode"
                          variant="filled"
                          value={fields.CustomerCode}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                        {errors.CustomerCode ? (
                          <p className="error-txt">{errors.CustomerCode}</p>
                        ) : null}
                      </Box>
                    </Col>
                    <Col
                      xs={6}
                      className="body-th common-input-style mandatoryclass"
                    >
                      <Box className="filled-box">
                        <TextField
                          id="filled-basic"
                          label="Customer Name"
                          name="CustomerName"
                          variant="filled"
                          value={fields.CustomerName}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                        {errors.CustomerName ? (
                          <p className="error-txt">{errors.CustomerName}</p>
                        ) : null}
                      </Box>
                    </Col>
                    <Col
                      xs={6}
                      className="body-th common-input-style mandatoryclass"
                    >
                      <Box id="float-label">
                        <label
                          className={
                            fields.StartDate
                              ? "Active text-label ml-4 camp-time-label"
                              : "text-label ml-4 camp-time-label"
                          }
                        >
                          Start Date
                        </label>

                        <Box className="ml-1 input-date-picker">
                          <DatePicker
                            format="MM/DD/YYYY"
                            render={<InputIcon />}
                            name="StartDate"
                            placeholder="Start Date"
                            value={
                              fields.StartDate
                                ? moment(fields.StartDate).toDate()
                                : ""
                            }
                            onChange={(e) => {
                              setMultiValue({
                                StartDate: e?.toDate() || "",
                              });
                            }}
                          />
                        </Box>
                      </Box>
                      {errors.StartDate ? (
                        <p className="error-txt">{errors.StartDate || ""}</p>
                      ) : null}
                    </Col>
                    <Col xs={6} className="body-th common-input-style ">
                      <Box id="float-label1">
                        <label
                          className={
                            fields.EndDate
                              ? "Active text-label ml-4 camp-time-label"
                              : "text-label ml-4 camp-time-label"
                          }
                        >
                          End Date
                        </label>

                        <Box className="ml-1 input-date-picker">
                          <DatePicker
                            format="MM/DD/YYYY"
                            render={<InputIcon />}
                            placeholder="End Date*"
                            name="EndDate"
                            value={
                              fields.EndDate
                                ? moment(fields.EndDate).toDate()
                                : ""
                            }
                            onChange={(e) => {
                              setMultiValue({
                                EndDate: e?.toDate() || "",
                              });
                            }}
                          />
                        </Box>
                      </Box>
                    </Col>
                    <Col xs={6} className="body-th common-input-style">
                      <FormControlLabel
                        className="dynamic-checkbox ms-0 checkbox-space"
                        control={<Checkbox />}
                        label="Active"
                        checked={fields.Active}
                        labelPlacement="end"
                        name="Active"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    </Col>
                    <Col xs={6} className="body-th common-input-style">
                      <Box className="">
                        <div className="rating-title">Rating</div>
                        <div className="rating-status">
                          <ul className="rating-status">
                            <li
                              className={
                                fields.CustomerRating == 1 ? "active" : ""
                              }
                            >
                              <a onClick={(e) => handleRating(e, 1)}>1.0</a>
                            </li>
                            <li
                              className={
                                fields.CustomerRating == 2 ? "active" : ""
                              }
                            >
                              <a onClick={(e) => handleRating(e, 2)}>2.0</a>
                            </li>
                            <li
                              className={
                                fields.CustomerRating == 3 ? "active" : ""
                              }
                            >
                              <a onClick={(e) => handleRating(e, 3)}>3.0</a>
                            </li>
                            <li
                              className={
                                fields.CustomerRating == 4 ? "active" : ""
                              }
                            >
                              <a onClick={(e) => handleRating(e, 4)}>4.0</a>
                            </li>
                            <li
                              className={
                                fields.CustomerRating == 5 ? "active" : ""
                              }
                            >
                              <a to="#;" onClick={(e) => handleRating(e, 5)}>
                                5.0
                              </a>
                            </li>
                          </ul>
                        </div>
                      </Box>
                    </Col>
                    <Col
                      xs={6}
                      className="body-th common-input-style common-select-border mandatoryclass"
                    >
                      <Box className="">
                        <FormControl fullWidth className="select-float-label ">
                          <FieldLookup
                            isRender={true}
                            name="CustomerType"
                            type="CUSTOMER_TYPE"
                            lookup={true}
                            value={fields.CustomerType}
                            onSelect={(data) => setValue("CustomerType", data)}
                            placeholder="Customer Type"
                            setParent={setCusTy}
                          />
                        </FormControl>
                        {errors.CustomerType ? (
                          <p className="error-txt">{errors.CustomerType}</p>
                        ) : null}
                      </Box>
                    </Col>
                  </Row>
                </Box>
              </Paper>
            </Box>
          </Paper>
        )}
      </React.Fragment>
    </div>
  );
}

export default AddCustomer;
