import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import {
  Link,
  useNavigate,
  useParams,
  useLocation,
  useOutletContext,
} from "react-router-dom";
import AmlHashForm from "aml-hash-form";
import { Col, Row } from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import FieldSelect from "elements/FieldSelect";
import FieldLookup from "elements/FieldLookup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
  FormControl,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import InputIcon from "react-multi-date-picker/components/input_icon";
import DatePicker from "react-multi-date-picker";

//Components
import Loader from "elements/Loader";
import AccessDenied from "elements/AccessDenied";

import {
  USER_ROLE_GROUPS,
  CRM_TERRITORY_TEAM,
  CRM_CREATE_USER,
  CRM_EMPLOYEES,
  CRM_PAY_EMPLOYEE,
  CRM_USERS,
  ROLES,
} from "helpers/service";
import { common, envConfig, api } from "helpers";
let ModNameChange = envConfig.ModNameChange;

const formFields = {
  Title: { validate: ["req#Please select the Title"] },
  EmployeeCode: {
    validate: ModNameChange
      ? ["req#User Code is required"]
      : ["req#Employee Code is required"],
  },
  FirstName: { validate: ["req#First Name is required"] },
  LastName: { validate: ["req#Last Name is required"] },
  EmailAddress: { validate: ["req#Email is required"] },
  Password: { validate: ["req#Password is required"] },
  MobileNo: { validate: [] },
  DateOfJoin: { validate: ["req#Hired Date is required"] },
  Gender: { validate: ["req#Gender is required"] },
  EmployementStatus: {
    validate: ModNameChange
      ? ["req#Please select the User type"]
      : ["req#Please select the Employee type"],
  },
  TerritoryId: {
    validate: ModNameChange
      ? ["req#Please select the Roles"]
      : ["req#Please select the Department type"],
  },
  WorkingStatus: { validate: ["req#Please select work status"] },
};

const SAVE_SUCCESS = ModNameChange
  ? "User saved successfully."
  : "Employee saved successfully.";
const SAVE_FAILED = ModNameChange
  ? "User save failed."
  : "Employee save failed.";
const UPDATE_FAILED = ModNameChange
  ? "User updated successfully."
  : "Employee updated successfully.";
const BACK_URL = "/setting/hrms/employee";

function AddEmployee() {
  // init
  const { id } = useParams();
  const { search } = useLocation();
  const userData = common.authInfo();
  const navigate = useNavigate();
  const [permission] = useOutletContext();

  //states
  const [defaultPermission, setDefaultPermission] = useState({});
  const [department, setDepartment] = useState([]);
  const [userId, setUserId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setErrors] = useState("");
  const [universalLoader, setUniversalLoader] = useState(true);

  const {
    fields,
    errors,
    setValue,
    setMultiValue,
    setError,
    handleChange,
    handleSubmit,
  } = AmlHashForm(formFields);

  //Effects
  useEffect(() => {
    //id present edit
    if (id) {
      getEmpByEmpId(id);
    } else {
      setUniversalLoader(false);
      getDefaultGroupId();
    }
  }, []);

  const JsonToArray = (jsonObj) => {
    let array = [];
    for (const obj in jsonObj) {
      array.push({ [obj]: jsonObj[obj] });
    }
    return array;
  };

  const formObject = (label, value) => {
    return { label, value };
  };

  const formPayload = (needFields, allFields) => {
    let payload = {};
    needFields?.map((item) => {
      if (allFields.hasOwnProperty(item)) {
        if (allFields[item]?.label) {
          payload[item] = allFields[item].value;
        } else if (allFields[item] !== null) {
          payload[item] = allFields[item];
        }
      }
    });
    return payload;
  };

  const formTerritoryPayload = (Territory, EmpId) => {
    let payload = [];
    //TerritoryTeamId
    let TerritoryTeamIdRemove = [];

    Territory?.map((item, index) => {
      let OldObject = common.ArrayJsonFind(
        "TerritoryId",
        item.value,
        department,
        true
      );
      if (!Object.keys(OldObject).length) {
        payload.push({
          id: `part${index}`,
          path: `/${CRM_TERRITORY_TEAM}`,
          operation: "create",
          payload: {
            ResourceType: "SALESPERSON",
            ResourceId: EmpId,
            TenantId: userData?.TenantId,
            OrgId: userData?.DefaultOrgId,
            Owner: "N",
            TerritoryId: item.value,
          },
        });
      } else {
        TerritoryTeamIdRemove.push(item.value);
      }
    });

    department.map((item) => {
      if (!TerritoryTeamIdRemove.includes(item.TerritoryId)) {
        payload.push({
          id: `part${item.TerritoryTeamId}`,
          path: `/${CRM_TERRITORY_TEAM}/${item.TerritoryTeamId}`,
          operation: "delete",
          payload: {},
        });
      }
    });

    return payload;
  };

  //Submit
  const onSubmit = (e) => {
    e.preventDefault();

    if (fields?.WorkingStatus?.value === "RESIGNED") {
      if (!fields.ResignationDate || fields.ResignationDate === undefined) {
        setErrors("Please select date");
      } else {
        setErrors("");
      }
    }

    let isValid = handleSubmit();

    if (isValid && !error) {
      if (id) {
        setLoading(true);
        updateEmployee(id, fields);
        updateUser(userId, fields);
      } else {
        createUser();
      }
    }
  };

  const getDefaultGroupId = () => {
    let url = `${envConfig.AUTH_REST_URL}${ROLES}?q=GroupDesc=${envConfig.DEFAULT_ROLE_GROUP};TenantId=${userData?.TenantId}&fields=GroupId,GroupDesc`;
    api.call(
      { url: url, type: "dynamic", method: "GET", auth: "basic", cType: 4 },
      (response) => {
        if (response.status === 200) {
          getDefaultPermission(response.data.items[0].GroupId);
        }
      },
      (error) => {},
      (final) => {}
    );
  };
  //Default permission
  const getDefaultPermission = (GroupId) => {
    // USER_ROLE_GROUPS
    let url = `${envConfig.AUTH_REST_URL}${ROLES}/${GroupId}`;
    api.call(
      { url: url, type: "dynamic", method: "GET", auth: "basic", cType: 4 },
      (response) => {
        if (response.status === 200) {
          setDefaultPermission(response.data);
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  //CreateUser
  const createUser = () => {
    let filterFields = [
      "EmployeeCode",
      "FirstName",
      "LastName",
      "EmailAddress",
      "Password",
    ];
    let currentField = fields;
    let getFields = formPayload(filterFields, fields);
    getFields.TenantId = userData?.TenantId;
    getFields.GroupId = userData?.GroupId;
    getFields.OrgId = userData?.DefaultOrgId;
    getFields.WorkLocId = userData?.WorkLocId;
    getFields.CreatedBy = userData?.UserName;
    getFields.DefaultGroup = "employee-mobile-user";
    let payload = {
      name: "createEmployeeUser",
      parameters: JsonToArray(getFields),
    };
    setLoading(true);
    let url = `${envConfig.AUTH_REST_URL}${CRM_CREATE_USER}`;
    api.call(
      {
        url: url,
        type: "dynamic",
        method: "POST",
        auth: "basic",
        cType: 3,
        body: JSON.stringify(payload),
      },
      async (response) => {
        if (response.status === 200 || response.status === 201) {
          let result = response.data.result.split(",");
          let EmpId = result[0].split(":")[1].trim();
          let userId = result[1].split(":")[1].trim();
          EmpId = EmpId ? parseInt(EmpId) : EmpId;
          userId = userId ? parseInt(userId) : userId;
          updateEmployee(EmpId, currentField);
          updateUser(userId, currentField);
        } else {
          setLoading(false);
          common.snack("E", SAVE_FAILED);
        }
      },
      (error) => {
        setLoading(false);
        common.snack("E", SAVE_FAILED);
      },
      (final) => {}
    );
  };

  //Update Employee
  const updateEmployee = (EmpId, currentField) => {
    let filterFields = [
      "FirstName",
      "MiddleName",
      "LastName",
      "Title",
      "EmployeeCode",
      "Gender",
      "MobileNo",
      "PersonType",
      "EmployementStatus",
      "DateOfBirth",
      "DateOfJoin",
      "DateOfConfirm",
      "DateOfNotice",
      "PfEnrollmentDate",
      "WorkingStatus",
      "ResignationDate",
      "FatherName",
      "SpouseName",
      "MaritalStatus",
    ];
    let getFields = formPayload(filterFields, currentField);
    getFields.PersonType = "EMPLOYEE";
    getFields.EmployeeName = `${getFields?.FirstName} ${getFields?.LastName}`;

    let url = `${envConfig.BASE_API}${CRM_EMPLOYEES}/${EmpId}`;
    api.call(
      {
        url: url,
        type: "dynamic",
        method: "PATCH",
        auth: "token",
        cType: 4,
        body: JSON.stringify(getFields),
      },
      (response) => {
        if (response.status === 200 || response.status === 201) {
          updateTerritory(EmpId, currentField);
        } else {
          setLoading(false);
          common.snack("E", SAVE_FAILED);
        }
      },
      (error) => {
        setLoading(false);
        common.snack("E", SAVE_FAILED);
      },
      (final) => {}
    );
  };

  //Update Territory
  const updateTerritory = (EmpId, currentField) => {
    let payload = formTerritoryPayload(fields.TerritoryId, EmpId);
    let url = `${envConfig.BASE_API}`;
    if (payload.length) {
      api.call(
        {
          url: url,
          type: "dynamic",
          method: "POST",
          auth: "token",
          cType: 5,
          body: JSON.stringify({ parts: payload }),
        },
        (response) => {
          if (response.status === 200 || response.status === 201) {
            //pass
          } else {
            setLoading(false);
            common.snack("E", SAVE_FAILED);
          }
        },
        (error) => {
          setLoading(false);
          common.snack("E", SAVE_FAILED);
        },
        (final) => {}
      );
    }
  };

  //Update User
  const updateUser = (userId, currentField) => {
    let filterFields = [
      "FirstName",
      "LastName",
      "Gender",
      "MobileNo",
      "Password",
    ];
    let getFields = formPayload(filterFields, currentField);
    let url = `${envConfig.BASE_API}${CRM_USERS}/${userId}`;
    api.call(
      {
        url: url,
        type: "dynamic",
        method: "PATCH",
        auth: "token",
        cType: 4,
        body: JSON.stringify(getFields),
      },
      (response) => {
        if (response.status === 200 || response.status === 201) {
          if (id) {
            //if its Edit Navigate Back after success
            setLoading(false);
            common.snack("S", SAVE_SUCCESS);
            let URL_BACK = "/setting/hrms/employee" + search;
            navigate(URL_BACK);
          } else {
            //if its New user create permission
            createPermission(userId);
          }
        } else {
          setLoading(false);
          common.snack("E", SAVE_FAILED);
        }
      },
      (error) => {
        setLoading(false);
        common.snack("S", SAVE_FAILED);
      },
      (final) => {}
    );
  };

  //Create permission
  const createPermission = (UserId) => {
    let payload = {
      UserId,
      GroupId: defaultPermission?.GroupId,
      GroupActive: "Y",
      LdapSync: "Y",
      TenantId: userData?.TenantId,
      UserAccess: defaultPermission?.UserAccess,
    };
    let url = `${envConfig.AUTH_REST_URL}${USER_ROLE_GROUPS}`;
    api.call(
      {
        url: url,
        type: "dynamic",
        method: "POST",
        auth: "basic",
        cType: 4,
        body: JSON.stringify(payload),
      },
      (response) => {
        if (response.status === 200 || response.status === 201) {
          common.snack("S", SAVE_SUCCESS);

          let URL_BACK = "/setting/hrms/employee" + search;
          navigate(URL_BACK);
        } else {
          setLoading(false);
          common.snack("E", SAVE_FAILED);
        }
      },
      (error) => {
        setLoading(false);
        common.snack("S", SAVE_FAILED);
      },
      (final) => {}
    );
  };

  //get Employee
  const getEmpByEmpId = (EmpId) => {
    let url = `${envConfig.BASE_API}${CRM_PAY_EMPLOYEE}/${EmpId}`;
    api.call(
      { url: url, type: "dynamic", method: "GET", auth: "token" },
      (response) => {
        if (response.status === 200) {
          let filterFields = [
            "FirstName",
            "MiddleName",
            "LastName",
            "Title",
            "Email",
            "EmployeeCode",
            "Gender",
            "MobileNo",
            "PersonType",
            "EmployementStatus",
            "DateOfBirth",
            "DateOfJoin",
            "DateOfConfirm",
            "DateOfNotice",
            "PfEnrollmentDate",
            "WorkingStatus",
            "ResignationDate",
            "MiddleName",
            "FatherName",
            "SpouseName",
            "MaritalStatus",
          ];
          let setFields = formPayload(filterFields, response.data);
          ["Title", "EmployementStatus", "WorkingStatus"].map((item) => {
            if (setFields[item] !== null) {
              setFields[item] = formObject(setFields[item], setFields[item]);
            }
          });
          setFields.EmailAddress = setFields.Email;
          // setMultiValue(setFields);
          getUserByEmail(setFields.Email, setFields);
          getEmpTerritoryFromSalesTeam(EmpId, setFields);
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  //get Users
  const getUserByEmail = (email, currentField) => {
    let url = `${envConfig.BASE_API}${CRM_USERS}?q=TenantId=${userData?.TenantId} AND UPPER(UserName) LIKE '*${email}*'`;
    api.call(
      {
        url: url,
        type: "dynamic",
        method: "GET",
        auth: "token",
        cType: 4,
        moreHead: { rfv: 2 },
      },
      (response) => {
        if (response.status === 200) {
          setUserId(response.data.items[0].UserId);
          let filterFields = ["Password"];
          // let setFields = formPayload(filterFields, response.data.items[0])
          // let payload = { ...currentField, ...setFields };
          currentField.Password = response.data.items[0].Password;
          setMultiValue(currentField);
          setUniversalLoader(false);
        } else {
          setMultiValue(currentField);
          setUniversalLoader(false);
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  const getEmpTerritoryFromSalesTeam = useCallback((EmpId, currentField) => {
    let url = `${envConfig.BASE_API}${CRM_TERRITORY_TEAM}?q=ResourceId=${EmpId}`;
    api.call(
      { url: url, type: "dynamic", method: "GET", auth: "token", cType: 4 },
      (response) => {
        if (response.status === 200) {
          let setFields = [];
          setDepartment([]);
          if (response?.data?.items?.length) {
            response?.data?.items.map((item) => {
              setFields.push(formObject(item.TerritoryName, item.TerritoryId));
              setDepartment((oldState) => [
                ...oldState,
                {
                  TerritoryId: item.TerritoryId,
                  TerritoryTeamId: item.TerritoryTeamId,
                },
              ]);
            });
            let appendFields = currentField;
            appendFields.TerritoryId = setFields;
            setMultiValue(appendFields);
          }
        }
      },
      (error) => {},
      (final) => {}
    );
  }, []);

  return (
    <div style={{ padding: "20px" }}>
      {common.givenPermission(permission, "hrms:employee", "basic", id) ? (
        <AccessDenied />
      ) : (
        <React.Fragment>
          {universalLoader ? (
            <Loader />
          ) : (
            <Paper
              sx={{ width: "100%", mb: 2 }}
              className={`container responsive-table p-0`}
            >
              <Box>
                <Paper variant="contained dynamic-form-wrap" square>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item display="flex" alignItems="center" xs={5} className="detail-head">
                      <Grid py={1} px={1} className="icon-back icon-buton">
                        <Link to="/setting/hrms/employee">
                          <img
                            src={common.loadImg("backArrowKanban.svg")}
                            alt="back"
                          />
                        </Link>
                      </Grid>
                      <Grid item className="df-main-head">
                        <Typography className="detail-title">
                          Add {ModNameChange ? "User" : "Employee"}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item className="safari-style">
                      <Box px={2} className="safari-style">
                        <Grid container alignItems="center" spacing={2} className="safari-style">
                          <Grid item>
                            <Box className="verical-divider">
                              <Divider orientation="vertical" />
                            </Box>
                          </Grid>
                          <Grid item>
                            <Box>
                              <Button
                                className="save-note"
                                type="submit"
                                disabled={loading}
                                onClick={onSubmit}
                              >
                                {loading ? "Processing..." : "Save Changes"}
                              </Button>
                            </Box>
                          </Grid>
                          <Grid item>
                            <Box className="vertical-divider">
                              <Divider orientation="vertical" />
                            </Box>
                          </Grid>
                          <Grid item>
                            <Box className="revert-close">
                              <Button className="save-note pe-0">
                                <Link to="/setting/hrms/employee">
                                  Revert and Close
                                  <img
                                    src={common.loadImg("closeKanban.svg")}
                                    alt="close"
                                  />
                                </Link>
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box>
                    <Divider />
                  </Box>
                  <Box p={2} px={5} className="form-wrap">
                    <Typography className="info-title dynamic-form-title mt-3">
                      {ModNameChange ? "USER" : "EMPLOYEE"} INFORMATION
                    </Typography>
                    <Box mt={3}>
                      <Divider />
                    </Box>

                    <Box mt={2} className="camp-edit meet-table">
                      <Row className="m-0">
                        <Col
                          xs={6}
                          className="body-th common-input-style common-select-border"
                        >
                          <Box className="">
                            <FormControl
                              fullWidth
                              className="select-float-label "
                            >
                              <FieldLookup
                                isRender={true}
                                name="Title"
                                type="SALUTATION"
                                lookup={true}
                                placeholder="Title"
                                value={fields.Title}
                                onSelect={(data) => setValue("Title", data)}
                              />
                            </FormControl>
                            {errors.Title ? (
                              <p className="error-txt">{errors.Title}</p>
                            ) : null}
                          </Box>
                        </Col>
                        <Col
                          xs={6}
                          className="body-th common-input-style mandatoryclass"
                        >
                          <Box className="filled-box">
                            <TextField
                              id="filled-basic "
                              label="First Name"
                              variant="filled"
                              name={"FirstName"}
                              value={fields.FirstName}
                              onChange={handleChange}
                              helperText={errors.FirstName}
                              error
                            />
                          </Box>
                        </Col>
                        <Col
                          xs={6}
                          className="body-th common-input-style mandatoryclass"
                        >
                          <Box className="filled-box ">
                            <TextField
                              id="filled-basic "
                              label="Middle Name"
                              variant="filled"
                              name={"MiddleName"}
                              value={fields.MiddleName}
                              onChange={handleChange}
                            />
                          </Box>
                        </Col>

                        <Col
                          xs={6}
                          className="body-th common-input-style mandatoryclass"
                        >
                          <Box className="filled-box">
                            <TextField
                              id="filled-basic"
                              label="Last Name"
                              variant="filled"
                              name={"LastName"}
                              value={fields.LastName}
                              onChange={handleChange}
                              helperText={errors.LastName}
                              error
                            />
                          </Box>
                        </Col>
                        <Col
                          xs={6}
                          className="body-th common-input-style mandatoryclass"
                        >
                          <Box className="filled-box">
                            <TextField
                              id="filled-basic"
                              label="Email Address"
                              variant="filled"
                              name={"EmailAddress"}
                              value={fields.EmailAddress}
                              onChange={handleChange}
                              helperText={errors.EmailAddress}
                              error
                            />
                          </Box>
                        </Col>
                        <Col
                          xs={6}
                          className="body-th common-input-style mandatoryclass"
                        >
                          <Box className="filled-box">
                            <TextField
                              id="filled-basic"
                              label={
                                ModNameChange ? "User Code" : "Employee Code"
                              }
                              variant="filled"
                              name={"EmployeeCode"}
                              value={fields.EmployeeCode}
                              onChange={handleChange}
                              helperText={errors.EmployeeCode}
                              error
                            />
                          </Box>
                        </Col>
                        <Col
                          xs={6}
                          className="body-th common-input-style common-select-border"
                        >
                          <Box className="">
                            <FormControl
                              fullWidth
                              className="select-float-label"
                            >
                              <FieldLookup
                                isRender={true}
                                name="WorkingStatus"
                                type="WORK_STATUS"
                                lookup={true}
                                placeholder="Work Status"
                                value={fields.WorkingStatus}
                                onSelect={(data) => {
                                  setValue("WorkingStatus", data);
                                  setErrors("");
                                }}
                              />
                            </FormControl>
                            {errors.WorkingStatus ? (
                              <p className="error-txt">
                                {errors.WorkingStatus}
                              </p>
                            ) : null}
                          </Box>
                        </Col>
                        <Col xs={6} className="body-th common-input-style">
                          <Box className="filled-box">
                            <div className="rating-title mb-3">
                              Gender<span className="mandtory-class">*</span>
                            </div>
                            <input
                              type="radio"
                              className="radio-button ml-4"
                              id="MALE"
                              value="MALE"
                              name="Gender"
                              checked={fields.Gender === "MALE"}
                              onChange={handleChange}
                            />
                            <label className="radio-label px-3" htmlFor="MALE">
                              Male
                            </label>
                            <input
                              type="radio"
                              className="radio-button ml-4"
                              id="FEMALE"
                              value="FEMALE"
                              name="Gender"
                              checked={fields.Gender === "FEMALE"}
                              onChange={handleChange}
                            />
                            <label
                              className="radio-label px-3"
                              htmlFor="FEMALE"
                            >
                              Female
                            </label>
                            {errors.Gender ? (
                              <p className="error-txt">{errors.Gender}</p>
                            ) : null}
                          </Box>
                        </Col>
                        {fields?.WorkingStatus?.value === "RESIGNED" && (
                          <Col
                            xs={6}
                            className="body-th common-input-style mandatoryclass"
                          >
                            <Box className="text-box" id="float-label1">
                              <label
                                className={
                                  fields.ResignationDate
                                    ? "Active text-label ml-4 camp-time-label"
                                    : "text-label ml-4 camp-time-label"
                                }
                              >
                                Resignation Date
                              </label>

                              <Box className="ml-1 custom-date-picker">
                                <DatePicker
                                  format="MM/DD/YYYY"
                                  render={<InputIcon />}
                                  placeholder="Hire Date"
                                  value={
                                    fields.ResignationDate
                                      ? moment(fields.ResignationDate).toDate()
                                      : null
                                  }
                                  name={"ResignationDate"}
                                  onChange={(e) => {
                                    setMultiValue({
                                      ResignationDate: e?.toDate() || "",
                                    });
                                    setErrors("");
                                  }}
                                />
                              </Box>
                              {error ? (
                                <p className="error-txt ">{error}</p>
                              ) : null}
                            </Box>
                          </Col>
                        )}

                        <Col xs={6} className="body-th common-input-style">
                          <Box className="filled-box">Profile Image</Box>
                        </Col>
                        <Col xs={6} className="body-th common-input-style">
                          <Box className="filled-box">
                            {/* <TextField
                        id="filled-basic"
                        label="Mobile"
                        variant="filled"
                        name={"MobileNo"}
                        value={fields.MobileNo}
                        onChange={handleChange}
                        helperText={errors.MobileNo}
                        error
                      /> */}
                            <div className="template-name-border flag-pic py-1 px-2">
                              <label className="template-label-font template-label-padding">
                                Mobile
                              </label>
                              <PhoneInput
                                country={"in"}
                                value={String(fields.MobileNo)}
                                name={"MobileNo"}
                                onChange={(phone) =>
                                  setValue("MobileNo", phone)
                                }
                              />
                            </div>
                          </Box>
                        </Col>
                      </Row>
                    </Box>
                  </Box>
                  <Box p={2} px={5} className="form-wrap">
                    <Typography className="info-title dynamic-form-title mt-3">
                      USERNAME AND PASSWORD
                    </Typography>
                    <Box mt={3}>
                      <Divider />
                    </Box>

                    <Box mt={2} className="camp-edit meet-table">
                      <Row className="m-0">
                        <Col
                          xs={6}
                          className="body-th common-input-style mandatoryclass"
                        >
                          <Box className="filled-box">
                            <TextField
                              id="filled-basic"
                              label="Username"
                              variant="filled"
                              disabled={true}
                              value={fields.EmailAddress}
                              className="text-disable"
                            />
                          </Box>
                        </Col>
                        <Col
                          xs={6}
                          className="body-th common-input-style mandatoryclass"
                        >
                          <Box className="filled-box">
                            <TextField
                              type="password"
                              id="filled-basic"
                              label="Password"
                              variant="filled"
                              name={"Password"}
                              value={fields.Password}
                              onChange={handleChange}
                              helperText={errors.Password}
                              error
                            />
                          </Box>
                        </Col>
                      </Row>
                    </Box>
                  </Box>
                  <Box p={2} px={5} className="form-wrap">
                    <Typography className="info-title dynamic-form-title mt-3">
                      DATES
                    </Typography>
                    <Box mt={3}>
                      <Divider />
                    </Box>

                    <Box mt={2} className="camp-edit meet-table">
                      <Row className="m-0">
                        <Col xs={6} className="body-th common-input-style">
                          <Box className="text-box" id="float-label">
                            <label
                              className={
                                fields.DateOfBirth
                                  ? "Active text-label ml-4 camp-time-label"
                                  : "text-label ml-4 camp-time-label"
                              }
                            >
                              Date Of Birth
                            </label>

                            <Box className="ml-1 custom-date-picker">
                              <DatePicker
                                format="MM/DD/YYYY"
                                render={<InputIcon />}
                                placeholder="Date Of Birth"
                                value={
                                  fields.DateOfBirth
                                    ? moment(fields.DateOfBirth).toDate()
                                    : ""
                                }
                                name={"DateOfBirth"}
                                onChange={(e) => {
                                  setMultiValue({
                                    DateOfBirth: e?.toDate() || "",
                                  });
                                }}
                              />
                            </Box>
                          </Box>
                        </Col>
                        <Col
                          xs={6}
                          className="body-th common-input-style mandatoryclass"
                        >
                          <Box className="text-box" id="float-label1">
                            <label
                              className={
                                fields.DateOfJoin
                                  ? "Active text-label ml-4 camp-time-label"
                                  : "text-label ml-4 camp-time-label"
                              }
                            >
                              Hire Date
                            </label>

                            <Box className="ml-1 custom-date-picker">
                              <DatePicker
                                format="MM/DD/YYYY"
                                render={<InputIcon />}
                                placeholder="Hire Date"
                                value={
                                  fields.DateOfJoin
                                    ? moment(fields.DateOfJoin).toDate()
                                    : ""
                                }
                                name={"DateOfJoin"}
                                onChange={(e) => {
                                  setMultiValue({
                                    DateOfJoin: e?.toDate() || "",
                                  });
                                }}
                              />
                              {errors.DateOfJoin ? (
                                <p className="error-txt mt-1">
                                  {errors.DateOfJoin}
                                </p>
                              ) : null}
                            </Box>
                          </Box>
                        </Col>
                        <Col xs={6} className="body-th common-input-style">
                          <Box className="text-box" id="float-label">
                            <label
                              className={
                                fields.DateOfConfirm
                                  ? "Active text-label ml-4 camp-time-label"
                                  : "text-label ml-4 camp-time-label"
                              }
                            >
                              Date Of Confirmation
                            </label>

                            <Box className="ml-1 custom-date-picker">
                              <DatePicker
                                format="MM/DD/YYYY"
                                render={<InputIcon />}
                                placeholder="Date Of Confirmation"
                                value={
                                  fields.DateOfConfirm
                                    ? moment(fields.DateOfConfirm).toDate()
                                    : ""
                                }
                                name={"DateOfConfirm"}
                                onChange={(e) => {
                                  setMultiValue({
                                    DateOfConfirm: e?.toDate() || "",
                                  });
                                }}
                              />
                            </Box>
                          </Box>
                        </Col>
                        <Col xs={6} className="body-th common-input-style">
                          <Box className="text-box" id="float-label1">
                            <label
                              className={
                                fields.DateOfNotice
                                  ? "Active text-label ml-4 camp-time-label"
                                  : "text-label ml-4 camp-time-label"
                              }
                            >
                              Date of Notice
                            </label>

                            <Box className="ml-1 custom-date-picker">
                              <DatePicker
                                format="MM/DD/YYYY"
                                render={<InputIcon />}
                                placeholder="Date Of Notice"
                                value={
                                  fields.DateOfNotice
                                    ? moment(fields.DateOfNotice).toDate()
                                    : ""
                                }
                                name={"DateOfNotice"}
                                onChange={(e) => {
                                  setMultiValue({
                                    DateOfNotice: e?.toDate() || "",
                                  });
                                }}
                              />
                            </Box>
                          </Box>
                        </Col>
                      </Row>
                    </Box>
                  </Box>
                  <Box p={2} px={5} className="form-wrap">
                    <Typography className="info-title dynamic-form-title mt-3">
                      GENERAL DETAILS
                    </Typography>
                    <Box mt={3}>
                      <Divider />
                    </Box>

                    <Box mt={2} className="camp-edit meet-table">
                      <Row className="m-0">
                        <Col xs={6} className="body-th common-input-style">
                          <Box className="filled-box">
                            <div className="rating-title mb-3">
                              Marital Status
                            </div>
                            <input
                              type="radio"
                              className="radio-button ml-4"
                              id="MARRIED"
                              value="MARRIED"
                              name="MaritalStatus"
                              onChange={handleChange}
                              checked={fields.MaritalStatus == "MARRIED"}
                            />
                            <label
                              className="radio-label px-3"
                              htmlFor="MARRIED"
                            >
                              Married
                            </label>
                            <input
                              type="radio"
                              className="radio-button ml-4"
                              id="SINGLE"
                              value="SINGLE"
                              name="MaritalStatus"
                              onChange={handleChange}
                              checked={fields.MaritalStatus == "SINGLE"}
                            />
                            <label
                              className="radio-label px-3"
                              htmlFor="SINGLE"
                            >
                              Single
                            </label>
                            {errors.MaritalStatus ? (
                              <p className="error-txt">
                                {errors.MaritalStatus}
                              </p>
                            ) : null}
                          </Box>
                        </Col>
                        <Col xs={6} className="body-th common-input-style">
                          <Box className="filled-box">
                            <TextField
                              // InputLabelProps={{
                              //   shrink: shrink,
                              // }}

                              id="filled-basic"
                              label="Father's Name"
                              variant="filled"
                              name={"FatherName"}
                              value={fields.FatherName}
                              onChange={handleChange}
                            />
                          </Box>
                        </Col>
                        <Col xs={6} className="body-th common-input-style">
                          <Box className="filled-box">
                            <TextField
                              // InputLabelProps={{
                              //   shrink: shrink,
                              // }}
                              id="filled-basic"
                              label="Name Of Spouse"
                              variant="filled"
                              name={"SpouseName"}
                              value={fields.SpouseName}
                              onChange={handleChange}
                            />
                          </Box>
                        </Col>
                        {/* Nationality */}
                        {/* <Col xs={6} className="body-th common-input-style">
                    <Box className="">
                      <FormControl fullWidth className="select-float-label">
                        <Select
                          className="text-box custom-react-select"
                          labelId="select"
                          id="select"
                          label="Nationality"
                          placeholder="Nationality"
                        ></Select>
                      </FormControl>
                    </Box>
                  </Col> */}
                        {/* ./Nationality */}
                        {/* <Col
                    xs={6}
                    className="body-th common-input-style common-select-border"
                  >
                    <Box className="">
                      <FormControl fullWidth className="select-float-label">
                        <FieldLookup
                          isRender={true}
                          name="PersonType"
                          type="EMP_STATUS"
                          lookup={true}
                          placeholder="Person Type*"
                          value={fields.PersonType}
                          onSelect={(data) => setValue("PersonType", data)}
                        />
                      </FormControl>
                    </Box>
                  </Col> */}
                        <Col
                          xs={6}
                          className="body-th common-input-style common-select-border mandatoryclass"
                        >
                          <Box className="">
                            <FormControl
                              fullWidth
                              className="select-float-label"
                            >
                              <FieldLookup
                                isRender={true}
                                name="EmployementStatus"
                                type="EMP_STATUS"
                                lookup={true}
                                placeholder={
                                  ModNameChange ? "User Type" : "Employee Type"
                                }
                                value={fields.EmployementStatus}
                                onSelect={(data) =>
                                  setValue("EmployementStatus", data)
                                }
                              />
                            </FormControl>
                            {errors.EmployementStatus ? (
                              <p className="error-txt">
                                {errors.EmployementStatus}
                              </p>
                            ) : null}
                          </Box>
                        </Col>
                      </Row>
                    </Box>
                  </Box>
                  <Box p={2} px={5} className="form-wrap">
                    <Typography className="info-title dynamic-form-title mt-3">
                      ASSIGNMENTS
                    </Typography>
                    <Box mt={3}>
                      <Divider />
                    </Box>

                    <Box mt={2} className="camp-edit meet-table">
                      <Row className="m-0">
                        <Col
                          xs={6}
                          className="body-th common-input-style mandatoryclass"
                        >
                          <Box className=" filled-box input-border-style common-select-border common-padding-zero">
                            <FieldSelect
                              api="CRM_TERRITORIES"
                              get="TerritoryName"
                              set="TerritoryId"
                              value={fields.TerritoryId}
                              isMulti={true}
                              isRender={true}
                              placeholder={
                                ModNameChange ? "Roles" : "Department Name"
                              }
                              onSelect={(data) => setValue("TerritoryId", data)}
                            />
                            {errors.TerritoryId ? (
                              <p className="error-txt">{errors.TerritoryId}</p>
                            ) : null}
                          </Box>
                        </Col>
                        <Col xs={6} className="body-th common-input-style">
                          {/* <Box className=" filled-box input-border-style common-select-border common-padding-zero">
                      <FieldSelect
                        api="CRM_EMPLOYEES"
                        get="EmployeeName"
                        set="Email"
                        value={fields.toUser}
                        isMulti={false}
                        isRender={true}
                        placeholder="SupervisorId"
                        onSelect={(data) => setValue("SupervisorId", data)}
                      />
                    </Box> */}
                        </Col>
                      </Row>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Paper>
          )}
        </React.Fragment>
      )}
    </div>
  );
}

export default AddEmployee;
