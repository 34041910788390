import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useParams,useOutletContext } from "react-router-dom";

import AccessDenied from "elements/AccessDenied";
import CardsView from "./cardsView";
import OverView from "./overView";
import ListView from "./listView";
import TaskAdd from "./add";
import SubMenu from "layouts/subMenu";
import { common } from "helpers";

function TasksView() {
  const [permission] = useOutletContext();
  let { view, id } = useParams();
  const [formView, setFormView] = useState(!view ? true : false);

  // effect
  useEffect(() => {
    setFormView(!view ? true : false);
  }, [view, id]);

  
  React.useEffect(() => {
    var isMobileVersion = document.getElementsByClassName("rotate-arrow");
   
    if (isMobileVersion.length > 0) {
       const fixedHeader = document.getElementById("fixed-header");
       if(fixedHeader){
          fixedHeader.classList.add("menu-shrink");
          fixedHeader.classList.remove("menu-expand");
       }
    } else {
      const fixedHeader = document.getElementById("fixed-header");
      if(fixedHeader){
          fixedHeader.classList.remove("menu-shrink");
          fixedHeader.classList.add("menu-expand");
      }
    }
  });
  return (
    <div className="default-bg">
      {common.givenPermission(permission,'task','read') ? <AccessDenied /> :
      <Box
        sx={{ width: "100%", typography: "body1" }}
        className="primary-header-container"
      >
        <SubMenu menu={"task"} />
        {view == "overview" && (
          <Box px={2} py={2}>
            <OverView />
          </Box>
        )}
        {view == "cardview" && (
          <Box px={2} py={2}>
            <CardsView />
          </Box>
        )}
        {view == "listview" && (
          <Box px={2} py={2}>
            <ListView />
          </Box>
        )}
        {formView && <TaskAdd id={id ? id : 0} />}
      </Box> }
    </div>
  );
}

export default TasksView;
