import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Typography,
  Popover,
} from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import moment from "moment";

import { TASKS, TASKS_VIEW } from "helpers/service";
import { api, common, envConfig } from "helpers";

const priority = {
  1: "Low",
  2: "Medium",
  3: "High",
};
const UPDATE_SUCCESS = "Status updated successfully.";
const UPDATE_FAILED = "Status update failed.";

function OverView() {
  var currentDate = new Date();
  const [permission] = useOutletContext();
  currentDate.setDate(currentDate.getDate() + 1);
  let tomoDate = common.formatDate(currentDate, "YYYY-MM-DD");
  const todayDate = common.formatDate(new Date(), "YYYY-MM-DD");
  const userData = common.authInfo();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [todayTask, setTodayTask] = useState({
    items: [],
    totalResults: 0,
    initLoading: true,
    moreLoading: false,
    offset: 1,
    limit: 5,
  });

  const [tomoTask, setTomoTask] = useState({
    items: [],
    totalResults: 0,
    initLoading: true,
    moreLoading: false,
    offset: 1,
    limit: 5,
  });

  useEffect(() => {
    getTodayTask();
    getTomoTask();
  }, []);

  useEffect(() => {
    if (todayTask.moreLoading) {
      getTodayTask();
    }
    if (tomoTask.moreLoading) {
      getTomoTask();
    }
  }, [todayTask, tomoTask]);

  const getTodayTask = () => {
    let timeZone = (moment().format()).split("+")[1]
    let startDate = moment(`${todayDate}T00:00:00+${timeZone}`).utc().format("YYYY-MM-DDTHH:mm:ss")
    let endDate = moment(`${todayDate}T24:00:00+${timeZone}`).utc().format("YYYY-MM-DDTHH:mm:ss")
    let todayStDate = startDate;
    let todayEdDate = endDate;

    let url = `${envConfig.BASE_API + TASKS_VIEW}?q=EmployeeId=${
      userData.EmpId
    } and CreatedBy='${
      userData.UserName
    }' and EndDate BETWEEN '${todayStDate}' and '${todayEdDate}' &totalResults=true&limit=${
      todayTask.limit
    }&offset=${(todayTask.offset - 1) * todayTask.limit}`;

    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
      moreHead: { rfv: 2 },
    };

    api.call(
      data,
      (response) => {
        if (response) {
          let result = response.data.items;
          setTodayTask((prev) => ({
            ...prev,
            items: [...new Set([...prev.items, ...result])],
            totalResults: response.data.totalResults,
            initLoading: false,
            moreLoading: false,
          }));
        }
      },
      (error) => {
        // setStatus("error");
      },
      (final) => {}
    );
  };

  const getTomoTask = () => {
    let timeZone = (moment().format()).split("+")[1]
    let startDate = moment(`${tomoDate}T00:00:00+${timeZone}`).utc().format("YYYY-MM-DDTHH:mm:ss")
    let endDate = moment(`${tomoDate}T24:00:00+${timeZone}`).utc().format("YYYY-MM-DDTHH:mm:ss")
    let todayStDate = startDate;
    let todayEdDate = endDate;

    let url = `${envConfig.BASE_API + TASKS_VIEW}?q=EmployeeId=${
      userData.EmpId
    } and CreatedBy='${
      userData.UserName
    }' and EndDate BETWEEN '${todayStDate}' and '${todayEdDate}' &totalResults=true&limit=${
      tomoTask.limit
    }&offset=${(tomoTask.offset - 1) * tomoTask.limit}`;

    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
      moreHead: { rfv: 2 },
    };

    api.call(
      data,
      (response) => {
        if (response) {
          let result = response.data.items;
          setTomoTask((prev) => ({
            ...prev,
            items: [...new Set([...prev.items, ...result])],
            totalResults: response.data.totalResults,
            initLoading: false,
            moreLoading: false,
          }));
        }
      },
      (error) => {
        // setStatus("error");
      },
      (final) => {}
    );
  };

  const viewMore = (e, isToday) => {
    e.preventDefault();
    if (isToday) {
      setTodayTask((prev) => ({
        ...prev,
        offset: prev.offset + 1,
        initLoading: false,
        moreLoading: true,
      }));
    } else {
      setTomoTask((prev) => ({
        ...prev,
        offset: prev.offset + 1,
        initLoading: false,
        moreLoading: true,
      }));
    }
  };

  const gotoAdd = (e) => {
    e.preventDefault();
    navigate("/task/add?view=overview");
  };

  const handleChangeItem = (event, isToday, taskId, idx) => {
    let sValue = event.target.value;
    if (isToday && sValue) {
      let tData = common.reParse(todayTask);
      let fTask = tData.items.filter((e) => e.TaskId == taskId)?.[0];
      if (fTask) {
        tData.items[idx].Status = sValue;
        setTodayTask(tData);
        updateTaskStatus(taskId, sValue);
      }
    } else {
      let tData = common.reParse(tomoTask);
      let fTask = tData.items.filter((e) => e.TaskId == taskId)?.[0];
      if (fTask) {
        tData.items[idx].Status = sValue;
        setTomoTask(tData);
        updateTaskStatus(taskId, sValue);
      }
    }
  };

  const updateTaskStatus = (taskId, status) => {
    let params = { Status: status };
    let data = {
      url: `${envConfig.BASE_API}${TASKS}/${taskId}`,
      type: "dynamic",
      method: "PATCH",
      body: JSON.stringify(params),
      auth: "token",
      cType: 4,
    };
    api.call(
      data,
      (res) => {
        if (res.data) {
          common.snack("S", UPDATE_SUCCESS);
        }
      },
      (error) => {
        common.snack("E", UPDATE_FAILED);
      },
      (final) => {}
    );
  };

  const handleClickExport = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getClassName = (priority) => {
    if (priority === "Low" || priority == 1) {
      return "task-priority low";
    } else if (priority === "Medium" || priority == 2) {
      return "task-priority medium";
    } else if (priority === "High" || priority == 3) {
      return "task-priority high";
    }
  };

  const editItem = (taskId) => {
    navigate(`/task/edit/${taskId}?view=overview`);
  };

  const openNotification = Boolean(anchorEl);
  const id = openNotification ? "simple-popover" : undefined;

  return (
    <div className="tasks">
      <Box>
        <Container maxWidth="lg" className="relative task">
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <Box px={1}>
                <Grid container spacing={2} justifyContent="space-between">
                  <Grid item>
                    <Typography className="over-view-date">
                      For Today&nbsp;
                      {todayTask.totalResults > 0 && (
                        <Typography
                          component="span"
                          className="over-view-count"
                        >
                          {todayTask.totalResults || 0}
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Box className="add-camp-btn">
                      {!common.givenPermission(permission, "task", "add") ? (
                        <Button variant="outlined" onClick={(e) => gotoAdd(e)}>
                          Add New Task
                          <img
                            src={common.loadImg("light-plus.svg")}
                            alt="document"
                            className="ml-5"
                          />
                        </Button>
                      ) : null}
                    </Box>
                  </Grid>
                </Grid>
                <Box mt={2}>
                  <Grid container direction="column" spacing={2}>
                    {!todayTask.initLoading &&
                      !todayTask.moreLoading &&
                      todayTask.totalResults == 0 && (
                        <p className="no-result-found camp-sub-name my-3 ">
                          No task found for today
                        </p>
                      )}
                    {todayTask.items.length > 0 &&
                      todayTask.items.map((item, i) => (
                        <Grid item key={"t_1" + i}>
                          <Box className="tasks-paper">
                            <Paper variant="outlined">
                              <Box>
                                <Grid container>
                                  <Grid item>
                                    <Box px={2} py={1} pr={10}>
                                      <p className="task">Task</p>
                                      <Typography className="task-detail mt-3">
                                        {item.TaskName}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Box className="vertical-divider-auto">
                                      <Divider orientation="vertical" />
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Box
                                      px={2}
                                      py={1}
                                      width="150px"
                                      display="flex"
                                      flexDirection="column"
                                      justifyContent="center"
                                      alignItems="center"
                                    >
                                      <p className="task center">Priority</p>
                                      <Box
                                        className={getClassName(
                                          item.PriorityName || item.Priority
                                        )}
                                        px={4}
                                        py={0.5}
                                        width="86px"
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                      >
                                        {item.PriorityName ||
                                          priority[item.Priority]}
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Box className="vertical-divider-auto">
                                      <Divider orientation="vertical" />
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Box px={2} py={1}>
                                      <p className="task center">Deadline</p>
                                      <Box
                                        className="deadline-date"
                                        px={4}
                                        py={0.5}
                                        mt={1}
                                      >
                                        {common.formatDate(
                                          item.EndDate,
                                          "MMM DD YYYY"
                                        ) || "-"}
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Box className="vertical-divider-auto">
                                      <Divider orientation="vertical" />
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Box px={4} py={1} pr={8}>
                                      <p className="task">Status</p>
                                      <Box className="deadline-date">
                                        <FormControl
                                          sx={{
                                            minWidth: { xs: 220, lg: 162 },
                                          }}
                                          size="small"
                                          className="dropdown-tasks"
                                        >
                                          <Select
                                            value={item.Status}
                                            displayEmpty
                                            inputProps={{
                                              MenuProps: {
                                                disableScrollLock: true,
                                              },
                                            }}
                                            className="task-status-menu"
                                            onChange={(e) =>
                                              handleChangeItem(
                                                e,
                                                true,
                                                item.TaskId,
                                                i
                                              )
                                            }
                                          >
                                            <MenuItem
                                              value="STARTED"
                                              className="task-status-list"
                                            >
                                              <p className="task-status started" />{" "}
                                              Started
                                            </MenuItem>
                                            <MenuItem
                                              value="INPROGRESS"
                                              className="task-status-list"
                                            >
                                              <p className="task-status inprogress" />{" "}
                                              In Progress
                                            </MenuItem>
                                            <MenuItem
                                              value="PENDING"
                                              className="task-status-list"
                                            >
                                              <p className="task-status pending" />
                                              Pending
                                            </MenuItem>
                                            <MenuItem
                                              value="COMPLETED"
                                              className="task-status-list"
                                            >
                                              <p className="task-status completed" />
                                              Completed
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Box className="vertical-divider-auto">
                                      <Divider orientation="vertical" />
                                    </Box>
                                  </Grid>
                                  <Grid item className="flex-grow">
                                    <Box
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                      className="more-place"
                                    >
                                      {!common.givenPermission(
                                        permission,
                                        "task",
                                        "export"
                                      ) ? (
                                        <IconButton
                                          className="more-icon"
                                          onClick={handleClickExport}
                                        >
                                          <MoreHorizIcon />
                                        </IconButton>
                                      ) : null}

                                      {!common.givenPermission(
                                        permission,
                                        "task",
                                        "update"
                                      ) ? (
                                        <Popover
                                          id={id}
                                          open={openNotification}
                                          anchorEl={anchorEl}
                                          onClose={handleClose}
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "center",
                                          }}
                                          className="notify-pop"
                                          transformOrigin={{
                                            vertical: "top",
                                            horizontal: "center",
                                          }}
                                          disableScrollLock
                                        >
                                          <Box
                                            className="task-notify-box"
                                            p={1}
                                          >
                                            <Grid
                                              container
                                              justifyContent="flex-end"
                                            >
                                              <Grid item>
                                                <IconButton
                                                  onClick={handleClose}
                                                >
                                                  <img
                                                    src={common.loadImg(
                                                      "dateClosekanban.svg"
                                                    )}
                                                    alt="close"
                                                  />
                                                </IconButton>
                                              </Grid>
                                            </Grid>
                                          </Box>
                                          <Box>
                                            <Divider />
                                          </Box>
                                          <Box>
                                            <Grid
                                              container
                                              direction="column"
                                              className="pointer"
                                            >
                                              <Grid item>
                                                {!common.givenPermission(
                                                  permission,
                                                  "task",
                                                  "update"
                                                ) ? (
                                                  <Box p={2} py={3}>
                                                    <Grid
                                                      container
                                                      direction="row"
                                                      justifyContent="flex-start"
                                                      spacing={1}
                                                      onClick={(e) =>
                                                        editItem(item.TaskId)
                                                      }
                                                    >
                                                      <Grid item>
                                                        <Typography className="task-assign ">
                                                          <img
                                                            src={common.loadImg(
                                                              "edit-icon.svg"
                                                            )}
                                                            className="ms-2 me-1"
                                                          />
                                                        </Typography>
                                                      </Grid>
                                                      <Grid item>
                                                        <Typography className="task-assign ">
                                                          Edit
                                                        </Typography>
                                                      </Grid>
                                                    </Grid>
                                                  </Box>
                                                ) : null}

                                                <Box>
                                                  <Divider />
                                                </Box>
                                              </Grid>
                                            </Grid>
                                          </Box>
                                        </Popover>
                                      ) : null}
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Paper>
                          </Box>
                        </Grid>
                      ))}
                    {(todayTask.moreLoading || todayTask.initLoading) && (
                      <p className="loading-msg camp-sub-name my-3">
                        Loading more records... Please wait..
                      </p>
                    )}
                  </Grid>
                  {todayTask.totalResults > 0 && (
                    <Grid container>
                      <Grid item py={4}>
                        Showing 1 to {todayTask.items.length} of{" "}
                        {todayTask.totalResults}
                      </Grid>
                    </Grid>
                  )}
                  {todayTask.totalResults > todayTask.items.length && (
                    <Grid container justifyContent="flex-end">
                      <Grid item py={4}>
                        <Button
                          variant="text"
                          onClick={(e) => viewMore(e, true)}
                        >
                          View All
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Box>
              </Box>
            </Grid>

            <Grid item>
              <Box px={1}>
                <Grid container spacing={2} justifyContent="space-between">
                  <Grid item>
                    <Typography className="over-view-date">
                      For Tomorrow&nbsp;
                      {tomoTask.totalResults > 0 && (
                        <Typography
                          component="span"
                          className="over-view-count"
                        >
                          {tomoTask.totalResults || 0}
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Box mt={2}>
                  <Grid container direction="column" spacing={2}>
                    {!tomoTask.initLoading &&
                      !tomoTask.moreLoading &&
                      tomoTask.totalResults == 0 && (
                        <p className="no-result-found camp-sub-name my-3 ">
                          No task found for tomorrow
                        </p>
                      )}
                    {tomoTask.items.length > 0 &&
                      tomoTask.items.map((item, i) => (
                        <Grid item key={"td_1" + i}>
                          <Box className="tasks-paper">
                            <Paper variant="outlined">
                              <Box>
                                <Grid container>
                                  <Grid item>
                                    <Box px={2} py={1} pr={10}>
                                      <p className="task">Task</p>
                                      <Typography className="task-detail mt-3">
                                        {item.TaskName}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Box className="vertical-divider-auto">
                                      <Divider orientation="vertical" />
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Box
                                      px={2}
                                      py={1}
                                      width="150px"
                                      display="flex"
                                      flexDirection="column"
                                      justifyContent="center"
                                      alignItems="center"
                                    >
                                      <p className="task center">Priority</p>
                                      <Box
                                        className="task-priority medium"
                                        px={4}
                                        py={0.5}
                                        width="86px"
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                      >
                                        {item.PriorityName || item.Priority}
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Box className="vertical-divider-auto">
                                      <Divider orientation="vertical" />
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Box px={2} py={1}>
                                      <p className="task center">Deadline</p>
                                      <Box
                                        className="deadline-date"
                                        px={4}
                                        py={0.5}
                                        mt={1}
                                      >
                                        {common.formatDate(
                                          item.EndDate,
                                          "MMM DD YYYY"
                                        ) || "-"}
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Box className="vertical-divider-auto">
                                      <Divider orientation="vertical" />
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Box px={4} py={1} pr={8}>
                                      <p className="task">Status</p>
                                      <Box className="deadline-date">
                                        <FormControl
                                          sx={{
                                            minWidth: { xs: 220, lg: 162 },
                                          }}
                                          size="small"
                                          className="dropdown-tasks"
                                        >
                                          <Select
                                            value={item.Status}
                                            displayEmpty
                                            inputProps={{
                                              MenuProps: {
                                                disableScrollLock: true,
                                              },
                                            }}
                                            className="task-status-menu"
                                            onChange={(e) =>
                                              handleChangeItem(
                                                e,
                                                false,
                                                item.TaskId,
                                                i
                                              )
                                            }
                                          >
                                            <MenuItem
                                              value="STARTED"
                                              className="task-status-list"
                                            >
                                              <p className="task-status started" />{" "}
                                              Started
                                            </MenuItem>
                                            <MenuItem
                                              value="INPROGRESS"
                                              className="task-status-list"
                                            >
                                              <p className="task-status inprogress" />{" "}
                                              In Progress
                                            </MenuItem>
                                            <MenuItem
                                              value="PENDING"
                                              className="task-status-list"
                                            >
                                              <p className="task-status pending" />
                                              Pending
                                            </MenuItem>
                                            <MenuItem
                                              value="COMPLETED"
                                              className="task-status-list"
                                            >
                                              <p className="task-status completed" />
                                              Completed
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Box className="vertical-divider-auto">
                                      <Divider orientation="vertical" />
                                    </Box>
                                  </Grid>
                                  <Grid item className="flex-grow">
                                    <Box
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                      className="more-place"
                                    >
                                      <IconButton
                                        className="more-icon"
                                        onClick={handleClickExport}
                                      >
                                        <MoreHorizIcon />
                                      </IconButton>
                                      <Popover
                                        id={id}
                                        open={openNotification}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                          vertical: "bottom",
                                          horizontal: "center",
                                        }}
                                        className="notify-pop"
                                        transformOrigin={{
                                          vertical: "top",
                                          horizontal: "center",
                                        }}
                                        disableScrollLock
                                      >
                                        <Box className="task-notify-box" p={1}>
                                          <Grid
                                            container
                                            justifyContent="flex-end"
                                          >
                                            <Grid item>
                                              <IconButton onClick={handleClose}>
                                                <img
                                                  src={common.loadImg(
                                                    "dateClosekanban.svg"
                                                  )}
                                                  alt="close"
                                                />
                                              </IconButton>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                        <Box>
                                          <Divider />
                                        </Box>
                                        <Box>
                                          <Grid
                                            container
                                            direction="column"
                                            className="pointer"
                                          >
                                            <Grid item>
                                              <Box p={2} py={3}>
                                                <Grid
                                                  container
                                                  direction="row"
                                                  justifyContent="flex-start"
                                                  spacing={1}
                                                  onClick={(e) =>
                                                    editItem(item.TaskId)
                                                  }
                                                >
                                                  <Grid item>
                                                    <Typography className="task-assign ">
                                                      <img
                                                        src={common.loadImg(
                                                          "edit-icon.svg"
                                                        )}
                                                        className="ms-2 me-1"
                                                      />
                                                    </Typography>
                                                  </Grid>
                                                  <Grid item>
                                                    <Typography className="task-assign ">
                                                      Edit
                                                    </Typography>
                                                  </Grid>
                                                </Grid>
                                              </Box>
                                              <Box>
                                                <Divider />
                                              </Box>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      </Popover>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Paper>
                          </Box>
                        </Grid>
                      ))}
                    {(tomoTask.moreLoading || tomoTask.initLoading) && (
                      <p className="loading-msg camp-sub-name my-3">
                        Loading more records... Please wait..
                      </p>
                    )}
                  </Grid>
                  {tomoTask.totalResults > 0 && (
                    <Grid container>
                      <Grid item py={4}>
                        Showing 1 to {tomoTask.items.length} of{" "}
                        {tomoTask.totalResults}
                      </Grid>
                    </Grid>
                  )}
                  {tomoTask.totalResults > tomoTask.items.length && (
                    <Grid container justifyContent="flex-end">
                      <Grid item py={4}>
                        <Button
                          variant="text"
                          onClick={(e) => viewMore(e, false)}
                        >
                          View All
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
}

export default OverView;
