import {
  Box,
  Grid,
} from "@mui/material";
import React from "react";
import KanbanView from "elements/KanbanView";
import { useNavigate } from "react-router-dom";

function CardsView() {
  const navigate = useNavigate();

  React.useEffect(() => {
    if(window.location.pathname == '/task/cardview'){
    const listenToScroll = () => {
      const appHeader = document.querySelector(".kanban-appbar-header");
      const primaryHeader = document.querySelector(".kanban-primary-header");
      const crmFooter = document.querySelector(".footer");
      if (window.scrollY >= 1) {
        appHeader.classList?.add("hide-onscroll");
        primaryHeader.classList?.add("hide-onscroll");
        crmFooter.classList?.add("hide-footer");
      } else {
        appHeader.classList?.remove("hide-onscroll");
        primaryHeader.classList?.remove("hide-onscroll");
        crmFooter.classList?.remove("hide-footer");
      }
    };
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }
  }, []);

  const changeFormType = (val, type) => {
    navigate("/task/add?view=cardview");
  }
 
  return (
    <div className="mt-2 tasks">
      <Box className="kanban-paper">
        <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
          <KanbanView
            changeForm={(val) => changeFormType(val, "add")}
            module={"task"}
            sort={"CreatedOn:desc"}
          /></Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default CardsView;
