import { Box } from "@mui/material";
import React, { memo } from "react";
import { useParams } from "react-router-dom";

import HrmsTab from "./HrmsTab";
import ConfigureTab from "./ConfigureTab";
import VendorTab from "./VendorTab";

const Setting = memo(() => {
  let { tab } = useParams();
  return (
    <div>
      <Box className="setting">
        {tab == "hrms" ? (
          <HrmsTab />
        ) : tab == "configure" ? (
          <ConfigureTab />
        ) : (
          <VendorTab />
        )}
      </Box>
    </div>
  );
});

export default Setting;
